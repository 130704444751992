/* eslint-disable react/prop-types */
/* eslint-disable no-irregular-whitespace */
import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { formatFixedDecimalAndNumb, formatPrice } from 'Utils/Numbers';

interface Props {
  date: string;
  orderNumber: string;
  prevPaidAmount: number;
  purchaseDate: string;
  purchasePeriod: string;
  tax: number;
  totalPaidAmount: number;
  totalPrice: number;
  totalPriceAfterApplyBuyingRate: number;
  preiceWithheldThisTime: number;
  totalRoyalty: number;
}

export default function PurchaseSummaryDetailBox({ props }: { props: Props }): JSX.Element {
  const totalPrice = useMemo(() => Math.round(props?.totalPrice / 11) ?? 0, [props?.totalPrice]);
  const totalRoyalty = useMemo(
    () => Math.round(props?.totalRoyalty / 11) ?? 0,
    [props?.totalRoyalty],
  );
  return (
    <Row className="shadow-sm border mt-4 ml-30px mr-30px">
      <Col className="border-right-ec mt-8px mb-8px" sm={4}>
        <div className="border-bottom-ec mr-6px pb-6px">
          対象期間　{props?.purchasePeriod.replace('-', '　～　')}
        </div>
        <div className="d-flex flex-column align-items-center mt-2">
          <div>
            <div>
              <div className="mb-8px">支払日</div>
              <div className="fs-18 font-bold mb-8px">{props?.purchaseDate}</div>
            </div>
            <div>
              <div>支払金額</div>
              <div className="fs-26 font-bold d-inline border-bottom-2px-ec pl-12px pr-12px ml-minus-12px">
                ¥{props?.totalPaidAmount !== null && props?.totalPaidAmount?.toLocaleString()}
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col className="border-right-ec mt-8px mb-8px pl-40px pr-40px" sm={4}>
        <div className="border-bottom-1px-ec mb-4px">
          <div className="space-between mb-4px">
            <p>仕入金額合計（10％対象）</p>
            <p>
              {props?.totalPrice !== null &&
                formatPrice(formatFixedDecimalAndNumb(props?.totalPrice, 2))}
            </p>
          </div>
          <div className="space-between mb-4px">
            <p className="ms-5">内消費税</p>
            <p>{props?.totalPrice !== null && formatPrice(totalPrice)}</p>
          </div>
          <div className="space-between mb-4px">
            <p>― 著作権使用料控除合計（10％対象）</p>
            <p>
              {props?.totalRoyalty !== null &&
                formatPrice(formatFixedDecimalAndNumb(props?.totalRoyalty, 2))}
            </p>
          </div>
          <div className="space-between mb-4px">
            <p className="ms-5">内消費税</p>
            <p>{props?.totalRoyalty !== null && formatPrice(totalRoyalty)}</p>
          </div>
          <div className="space-between mb-4px custom-hidden">
            <p>.</p>
            <p></p>
          </div>
          <div className="space-between mb-4px">
            <p>前回までの保留額</p>
            <p>
              {props?.prevPaidAmount !== null &&
                formatPrice(formatFixedDecimalAndNumb(props?.prevPaidAmount, 2))}
            </p>
          </div>
        </div>
        <div className="space-between mb-4px">
          <p>お支払金額（税込）</p>
          <p>
            {props?.totalPaidAmount !== null &&
              formatPrice(formatFixedDecimalAndNumb(props?.totalPaidAmount, 2))}
          </p>
        </div>
        <div className="space-between mb-4px">
          <p className="ms-5">内消費税</p>
          <p>{formatPrice(totalPrice - totalRoyalty)}</p>
        </div>
        <div className="space-between">
          <p>今回保留額</p>
          <p>
            {props?.preiceWithheldThisTime !== null &&
              formatPrice(formatFixedDecimalAndNumb(props?.preiceWithheldThisTime, 2))}
          </p>
        </div>
      </Col>
      <Col sm={4}></Col>
    </Row>
  );
}
