import React from 'react';
import BoxForm from 'Components/Common/Form/BoxForm';
import { Col, Row } from 'react-bootstrap';
import { formatCurrency } from 'Utils/Numbers';

interface Props {
  title: string;
  content: CardInfo[];
  description1?: string;
  description2?: string;
  bottomTextRight1?: string;
  bottomTextRight2?: string;
}

interface CardInfo {
  title: string;
  price?: number | null;
  percent?: number | null;
}
const MarketingCard = ({
  title,
  content,
  description1,
  description2,
  bottomTextRight1,
  bottomTextRight2,
}: Props): JSX.Element => {
  return (
    <BoxForm classBox="card-header-center" title={title.toUpperCase()}>
      <Row>
        <Col md="6">
          <div className="text-center mb-1">
            <span className="fw-bold">{content[0].title.toUpperCase()}</span>
          </div>
          <BoxForm classBox="inner-card" title={formatCurrency(content[0]?.price || 0) || '0'}>
            <span>{content[0].percent}%</span>
          </BoxForm>
          <div className="d-flex flex-column mt-2 fs-12">
            <span>
              {content[0].title}:{description1}
            </span>
            <span>
              {content[1].title}:{description2}
            </span>
          </div>
        </Col>
        <Col md="6">
          <div className="text-center mb-1">
            <span className="fw-bold">{content[1].title.toUpperCase()}</span>
          </div>
          <BoxForm classBox="inner-card" title={formatCurrency(content[1]?.price || 0) || '0'}>
            <span>{content[1].percent}%</span>
          </BoxForm>
          <div className="d-flex flex-column mt-2 fs-12">
            <span>{`${bottomTextRight1}`}</span>
            <span>{`${bottomTextRight2}`}</span>
          </div>
        </Col>
      </Row>
    </BoxForm>
  );
};

export default MarketingCard;
