/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import { isArray, pick } from 'lodash';
import { parseUrl, stringify } from 'query-string';
import UsingStatus from './UsingStatus';
import Carousel from 'react-bootstrap/Carousel';
import { UsingStatusApi } from 'Datasource/UsingStatus';
import { useLocation, useHistory } from 'react-router-dom';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';
import { TYPE_TICKETS } from 'Constant/WebApp';
import { PREFIX_WEB_APP } from 'Constant';

import RELOAD_ICON from 'Static/Images/reload.svg';

// the required distance between touchStart and touchEnd to be detected as a swipe
const minSwipeDistance = 70;

const CarouselUsingStatus = (): JSX.Element => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const touchStart = useRef(null);
  const touchEnd = useRef(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState({});
  const { state }: { state: any } = useLocation<any>();
  const history = useHistory();
  const { openMessageError } = useMessage();
  const [allowAccess, setIsAllowAccess] = useState(true);
  const [visibleSoldOut, setVisibleSoldOut] = useState(false);

  useEffect(() => {
    try {
      if (!state?.listId || (isArray(state?.listId) && state?.listId?.length === 0)) {
        history.push({
          search: stringify(
            pick(parseUrl(window?.location?.href)?.query, [
              'artist',
              'artistId',
              'date',
              'page',
              'place',
            ]),
          ),
          pathname: `${PREFIX_WEB_APP}/search`,
        });
        return;
      }
      const { id, type } = state?.listId[currentIndex];
      if (type === TYPE_TICKETS.ENTRANCE) {
        fetchDataEntrance(id);
      } else {
        fetchDataTokuten(id);
      }
      checkRoleUpdatePrize(id);
      handleCheckSoldOut(id);
    } catch (error) {
      openMessageError(error);
    }
  }, []);

  useEffect(() => {
    const prevBtn = document.querySelector('.carousel-control-prev');
    const nextBtn = document.querySelector('.carousel-control-next');
    if (currentIndex === 0) {
      prevBtn?.classList?.add('carousel-control-disabled');
    } else {
      prevBtn?.classList?.remove('carousel-control-disabled');
    }

    if (currentIndex === state?.listId?.length - 1) {
      nextBtn?.classList?.add('carousel-control-disabled');
    } else {
      nextBtn?.classList?.remove('carousel-control-disabled');
    }
  }, [currentIndex, state?.listId]);

  const checkRoleUpdatePrize = async (prizedId?: number | null) => {
    try {
      const {
        data: { data },
      } = await UsingStatusApi.checkRoleUpdatePrize(prizedId);
      setIsAllowAccess({ data } as any);
    } catch (error) {
      openMessageError(error);
    }
  };

  const handleCheckSoldOut = async (prizedId: number) => {
    try {
      const {
        data: { data },
      } = await UsingStatusApi.checkSoldOut(prizedId);
      setVisibleSoldOut(data);
      return data;
    } catch (error) {
      openMessageError(error);
    }
  };

  const fetchDataEntrance = async (prizeId?: number | null) => {
    try {
      setLoading(true);
      const {
        data: { data, time },
      } = await UsingStatusApi.getDataUsingStatusTicketIn(prizeId);
      setData({ ...data, time });
      setLoading(false);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataTokuten = async (prizeId?: number | null) => {
    try {
      setLoading(true);
      const {
        data: { data, time },
      } = await UsingStatusApi.getDataUsingStatusTokuten(prizeId);
      setData({ ...data, time });
      setLoading(false);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = async (selectedIndex: any) => {
    setCurrentIndex(selectedIndex);
    if (state?.listId.length > 0) {
      const { id, type } = state?.listId[selectedIndex];
      if (type === TYPE_TICKETS.ENTRANCE) {
        await fetchDataEntrance(id);
      } else {
        await fetchDataTokuten(id);
      }

      handleCheckSoldOut(id);
    }
  };

  const onTouchStart = (e: any) => {
    touchEnd.current = null; // otherwise the swipe is fired even with usual touch events
    touchStart.current = e.targetTouches[0].clientX;
  };

  const onTouchMove = (e: any) => (touchEnd.current = e.targetTouches[0].clientX);

  const onTouchEnd = async () => {
    let selectedIndex = currentIndex;
    if (!touchStart?.current || !touchEnd?.current) return;
    const distance = touchStart?.current - touchEnd?.current;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      if (isLeftSwipe) {
        //swipe left and fetch data
        if (selectedIndex < state?.listId.length - 1) {
          selectedIndex = ++selectedIndex;
          handleSelect(selectedIndex);
        } else {
          selectedIndex = state?.listId.length - 1;
          setCurrentIndex(selectedIndex);
        }
      } else {
        //swipe right and fetch data
        if (selectedIndex > 0) {
          selectedIndex = --selectedIndex;
          handleSelect(selectedIndex);
        } else {
          selectedIndex = 0;
          setCurrentIndex(selectedIndex);
        }
      }
    }
  };

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <div
        className="carousel-using-status"
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <Carousel interval={null} onSelect={handleSelect} activeIndex={currentIndex} touch={false}>
          {state?.listId?.map((e: any) => (
            <Carousel.Item key={e?.id}>
              <UsingStatus
                onCheckSoldOut={handleCheckSoldOut}
                visibleSoldOut={visibleSoldOut}
                allowAccess={allowAccess}
                dataProps={data}
                loadingParent={loading}
              />
            </Carousel.Item>
          ))}
        </Carousel>
        <div
          onClick={() => handleSelect(currentIndex)}
          className={loading ? 'position-fixed btn-rotate pe-none' : 'position-fixed btn-rotate'}
        >
          <img src={RELOAD_ICON} width="100%" height="auto" alt="reload-icon" />
        </div>
      </div>
    </>
  );
};

export default CarouselUsingStatus;
