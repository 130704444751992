import useMessage from './useMessage';

export default function useErrorBlob(): any {
  const { openMessage } = useMessage();

  const openErrorBlob = async (error: any) => {
    const msgError = JSON.parse(await error?.response?.data?.text())?.message?.[0];
    if (msgError === 'Please select record company') {
      openMessage({
        variant: 'error',
        message: 'レコード会社を選択してください。',
      });
    } else {
      openMessage({
        variant: 'error',
        message: msgError,
      });
    }
  };

  return { openErrorBlob };
}
