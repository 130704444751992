import MessageError from 'Components/Common/MessageError';
import React, { PropsWithChildren } from 'react';
import { Col, Row } from 'react-bootstrap';

interface FormTextGroupProps {
  label?: string;
  value?: any;
  className?: string;
  mdLable?: number;
  mdValue?: number;
  errorContent?: any;
  onClickError?: () => void;
  labelClass?: string;
  errorClass?: string;
}

export default function GroupTextProps({
  label,
  value,
  className,
  mdLable = 3,
  mdValue = 7,
  errorContent,
  onClickError,
  labelClass,
  errorClass,
}: PropsWithChildren<FormTextGroupProps>): JSX.Element {
  return (
    <Row className={`mt-2 ${className}`}>
      <Col md={mdLable} className={`${labelClass ? labelClass : ''}`}>
        {label}
      </Col>
      <Col md={mdValue}>
        {value || <p className="text-cherry d-flex align-items-center">【登録されていません】</p>}
        <div onClick={onClickError} onKeyDown={onClickError}>
          <MessageError
            message={errorContent?.message}
            classWrapper={errorClass ? errorClass : ''}
          />
        </div>
      </Col>
    </Row>
  );
}
