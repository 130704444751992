import React, { useEffect } from 'react';
import CardWrapper from 'Components/Common/Card/Card';
import ViewOnlyField from 'Components/Page/Campaign/RegisterCampaign/Common/ViewOnlyField';
import SuccessActionButtons from 'Components/Page/Campaign/RegisterCampaign/Common/SuccessActionButtons';
import { useAppSelector, useAppDispatch } from 'App/Store';
import { updateMailList } from 'App/Features/MailRecipientList';

const StepSuccess = (): JSX.Element => {
  const { mailList } = useAppSelector((state) => state.mailRecipientList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(updateMailList({ mailList: [], isSuccess: false }));
    };
  }, []);

  return (
    <>
      <CardWrapper title="登録が完了しました" className="mb-3">
        <div className="mb-3">以下アーティスト担当者へ登録通知メールを送信しました。</div>
        <div className="mx-3">
          {mailList?.map((info: any, key: number) => (
            <ViewOnlyField
              key={key}
              labelSize={2}
              valueSize={2}
              label={info?.section}
              value={info?.managerName}
            />
          ))}
        </div>
      </CardWrapper>
      <SuccessActionButtons />
    </>
  );
};

export default StepSuccess;
