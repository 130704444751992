import React, { useCallback, useMemo } from 'react';
import { TableGroup, TableColumns } from 'Components/Common/Table/TableGroup';

import { Row } from 'react-bootstrap';
import { IRecordCompanyOptions } from 'Types/Page/CostInformation';
import { formatCurrency } from 'Utils/Numbers';
const TableCost = ({ dataTable }: { dataTable: IRecordCompanyOptions[] }): JSX.Element => {
  const fixedText = useCallback((value: string) => {
    return String(parseFloat(value).toFixed(1)) || '';
  }, []);
  const headerTables = useMemo(
    () =>
      [
        {
          columns: [
            {
              text: '収録タイプ',
              rowSpan: 2,
              width: 250,
              dataIndex: 'titleType',
            },

            {
              text: 'DPコンテンツ',
              colSpan: 4,
            },
            {
              text: '費用',
              colSpan: 2,
            },
          ],
        },
        {
          columns: [
            {
              text: '収録曲数上限\n（映像の収録曲を含む）',
              width: 80,
              dataIndex: 'maxSongCount',
              render: ({ item }) => {
                return (
                  <div className="text-center">
                    <span>{item.maxSongCount}曲</span>
                  </div>
                );
              },
            },
            {
              text: '最低販売価格\n（税込）',
              width: 80,
              dataIndex: 'minPrice',
              render: ({ item }) => {
                return (
                  <div className="text-center">
                    <span>{formatCurrency(+item.minPrice)}円</span>
                  </div>
                );
              },
            },
            {
              text: 'DPデータ容量上限',
              width: 80,
              dataIndex: 'maxVariationStorage',
              render: ({ item }) => {
                return (
                  <div className="text-center">
                    <span>{item.maxVariationStorage}MB</span>
                  </div>
                );
              },
            },
            {
              text: 'キャンペーンコンテンツ',
              width: 80,
              dataIndex: 'limitTokutenStorage',
              render: ({ item }) => {
                return (
                  <div className="text-center">
                    <span>{item.limitTokutenStorage}MB</span>
                  </div>
                );
              },
            },
            {
              text: 'miim生成費',
              width: 80,
              dataIndex: 'cardBuyFee',
              render: ({ item }) => {
                return (
                  <div className="text-center">
                    <span>{formatCurrency(+item.cardBuyFee)}円</span>
                  </div>
                );
              },
            },
            {
              text: 'サーバ・トラフィック費',
              width: 80,
              dataIndex: 'taxFee',
              render: ({ item }: { item: IRecordCompanyOptions }) => {
                return (
                  <div className="text-center">
                    <span>{fixedText(item.taxFee)}%</span>
                  </div>
                );
              },
            },
          ],
        },
      ] as TableColumns[],
    [fixedText],
  );

  return (
    <Row className="px-1 pt-4">
      <TableGroup dataSource={dataTable} tableColumns={headerTables} />
    </Row>
  );
};

export default TableCost;
