import React, { useState, useEffect } from 'react';
import DatePicker from 'react-mobile-datepicker';
import { Form, Row, Col } from 'react-bootstrap';
import { DATE_FORMAT_1, DATE_FORMAT_7 } from 'Constant/Date';
import { useQueryParams, StringParam } from 'use-query-params';
import dayjs from 'dayjs';
import { KeyLocalStorage } from 'Page/WebApp/SearchEvent/Search';

const DATE_CONFIG = {
  year: {
    format: 'YYYY',
    caption: 'Year',
    step: 1,
  },
  month: {
    format: 'MM',
    caption: 'Mon',
    step: 1,
  },
  date: {
    format: 'DD',
    caption: 'Day',
    step: 1,
  },
};

const DatePickerMobileApp = ({
  label,
  placeholder,
  isDisabled,
}: {
  label: string;
  placeholder?: string;
  isDisabled?: boolean;
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [query, setQuery] = useQueryParams({
    date: StringParam,
    artist: StringParam,
  });

  useEffect(() => {
    if (isOpen) {
      document?.body?.classList?.add('webapp-disabled-scroll');
    } else {
      document?.body?.classList?.remove('webapp-disabled-scroll');
    }
  }, [isOpen]);

  const handleConfirm = (e: any) => {
    window?.localStorage?.removeItem(KeyLocalStorage.DataTickets);
    setQuery({ date: dayjs(e).startOf('day').format(DATE_FORMAT_7) });
    setIsOpen(false);
  };

  const onOpenDate = (e: any) => {
    e.preventDefault();
    setIsOpen(true);
  };

  return (
    <div className="date-picker-mobile-app fs-16">
      <Form.Group as={Row} className={`px-0`}>
        <Form.Label column md={12} className="px-0">
          {label}
        </Form.Label>
        <Col md={12} className="px-0">
          <div className="input-date-container">
            <Form.Control
              className="input-date ifs-16px"
              disabled={isDisabled}
              readOnly
              value={query.date ? dayjs(query.date).format(DATE_FORMAT_1) : ''}
              onClick={onOpenDate}
              style={isDisabled ? { backgroundColor: 'hsl(0, 0%, 95%)' } : {}}
              placeholder={placeholder}
            />
            <div className="calendar-icon">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1H12V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1H14C14.5304 1 15.0391 1.21071 15.4142 1.58579C15.7893 1.96086 16 2.46957 16 3V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V3C0 2.46957 0.210714 1.96086 0.585786 1.58579C0.960859 1.21071 1.46957 1 2 1H3V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0ZM1 4V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14V4H1Z"
                  fill="#BEBCBC"
                />
              </svg>
            </div>
          </div>
        </Col>
      </Form.Group>
      <DatePicker
        isOpen={isOpen}
        isPopup={true}
        value={query.date ? new Date(query.date) : new Date()}
        dateFormat={['DD', 'MM', 'YYYY']}
        confirmText="確認"
        cancelText="キャンセル"
        theme="android"
        headerFormat="YYYY/MM/DD"
        dateConfig={DATE_CONFIG}
        showHeader={true}
        showFooter={true}
        onSelect={handleConfirm}
        onCancel={() => setIsOpen(false)}
      />
    </div>
  );
};

export default DatePickerMobileApp;
