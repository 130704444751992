import * as yup from 'yup';
import { stringRequired, numberRequired } from 'Utils/Validation';
import { some } from 'lodash';
import { getBool } from 'Utils/Permission';

const CampaginOfficialSchema = yup.object().shape({
  artistId: stringRequired({}).nullable(),
  titleId: stringRequired({}).nullable(),
  name: stringRequired({}).nullable(),
  startDate: stringRequired({}).nullable(),
  endDate: stringRequired({}).nullable(),
  description: stringRequired({}).nullable(),
  courses: yup
    .array()
    .required('入力してください。')
    .of(
      yup.object().shape({
        name: stringRequired({}).trim().nullable(),
        option: numberRequired({}).nullable(),
        //* option === 0 (one to one)
        type: numberRequired({}).nullable(),
        //* all won
        receiveType: yup.number().when('type', {
          is: (type: number) => type === 0,
          then: numberRequired({}).nullable(),
        }),
        //* option === 1 (Standart)
        standardTime: yup
          .string()
          .when('type', {
            is: (type: number) => type === 1,
            then: stringRequired({}).trim().nullable(),
          })
          .nullable(),
        timeJoinStart: yup
          .string()
          .when('type', {
            is: (type: number) => type === 1 || type === 2,
            then: stringRequired({}).trim().nullable(),
          })
          .nullable(),
        timeJoinEnd: yup
          .string()
          .when('type', {
            is: (type: number) => type === 1 || type === 2,
            then: stringRequired({}).trim().nullable(),
          })
          .nullable(),
        //* option === 2 (Realtime)
        countJoinStart: yup
          .string()
          .when(['type', 'subType'], {
            is: (type: number, subType: number) => {
              return type === 2 && subType === 2;
            },
            then: stringRequired({}).trim().nullable(),
          })
          .nullable(),
        countJoinEnd: yup
          .string()
          .when(['type', 'subType'], {
            is: (type: number, subType: number) => type === 2 && subType === 2,
            then: stringRequired({}).trim().nullable(),
          })
          .nullable(),
        prizeQuantity: yup
          .string()
          .when(['type', 'subType'], {
            is: (type: number, subType: number) => type === 2 && subType === 0,
            then: stringRequired({}).nullable(),
          })
          .nullable(),
        //* END
        ticketPrice: yup
          .string()
          .when('option', {
            is: (option: number) => option === 1,
            then: stringRequired({}).trim().nullable(),
          })
          .nullable(),
        prizeOption: numberRequired({}).nullable(),
        prizes: yup
          .array()
          .of(
            yup.object().shape({
              seriesNumberMasterId: yup
                .string()
                .when('type', {
                  is: (type: number) => type === 2,
                  then: stringRequired({}).trim().nullable(),
                })
                .nullable(),
              winRate: yup
                .string()
                .test({
                  name: 'winRate',
                  message: '入力してください。',
                  test: (value, context: any) => {
                    const { from } = context;
                    const { type, subType } = from[1].value;
                    if (type === 2 && subType !== 2 && !value) {
                      return false;
                    }

                    return true;
                  },
                })
                .nullable(),
              startTime: yup
                .string()
                .when('type', {
                  is: (type: number) => type === 1,
                  then: stringRequired({}).trim().nullable(),
                })
                .nullable(),
              // type2: yup.object().when('type', {
              //   is: 1,
              //   then: yup.object({
              //     startTime: stringRequired({}).trim().nullable(),
              //   }),
              // }),
            }),
          )
          .min(1, '入力してください。'),
        blackListUsers: yup.array().when(['prizes'], {
          is: (prizes: any) => {
            const isAbandoned = some(prizes, (prize) => prize?.abandoned);
            return isAbandoned;
          },
          then: yup.array().min(1, '入力してください。'),
        }),
        courseInfos: yup
          .array()
          .when(['option'], {
            is: (option: number) => {
              return option === 2;
            },
            then: yup.array().of(
              yup.object().shape({
                enabled: yup.string().test('dont-cp', (value, { parent, createError, path }) => {
                  const index = parseInt(path?.split('[')[2].split(']')[0], 10);
                  let valid = true;
                  if (
                    getBool(value) &&
                    !parent.option1 &&
                    !parent.option2 &&
                    !parent.option3 &&
                    !parent.option4
                  ) {
                    valid = false;
                    return createError({
                      path,
                      message: `No${parent.index ?? index + 1}の条件マッピングを入力してください。`,
                    });
                  }
                  return valid;
                }),
              }),
            ),
            otherwise: yup.array().min(1, '入力してください。'),
          })
          // TODO
          // .test({
          //   name: 'exist-cp',
          //   message: '入力してください。',
          //   test: (value) => {
          //     let valid = true;
          //     const isExistCD = some(
          //       value,
          //       (info) => info.enabled && info?.type === TargetProduct.CD,
          //     );
          //     const isExistDP = some(
          //       value,
          //       (info) => info.enabled && info?.type === TargetProduct.DP,
          //     );
          //     if (isExistCD && !isExistDP) {
          //       valid = false;
          //     }
          //     return valid;
          //   },
          // })
          // TODO END
          .test({
            name: 'exist-1',
            message: '入力してください。',
            test: (value) => {
              const isExist1 = some(value, (info) => getBool(info?.enabled));
              return isExist1;
            },
          }),
      }),
    ),
});

export default CampaginOfficialSchema;
