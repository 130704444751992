import { setCoverImage } from 'App/Features/Title';
import { useAppDispatch } from 'App/Store';
import MessageError from 'Components/Common/MessageError';
import { TYPE_MEDIA } from 'Constant';
import { UploadMediaApi } from 'Datasource/UploadMedia';
import useMessage from 'Hooks/useMessage';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import { checkIfFilesAreCorrectType, checkImgSquareAndDimension } from 'Utils/File';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { toast } from 'react-toastify';

interface FilesMiim extends FileWithPath {
  preview?: string;
}

const BoxDropZone = ({
  maxFiles = 1,
  variationIndex = -1,
  coverImageUrl = '',
}: {
  onChangeFiles?: (files: FileList) => void;
  maxFiles?: number;
  variationIndex?: number;
  coverImageUrl?: string | undefined | null;
}): JSX.Element => {
  const [files, setFiles] = useState<FilesMiim[]>([]);
  const [error, setError] = useState<string>('');
  const { openMessageError } = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const uploadImage = async (image: Blob) => {
    try {
      setLoading(true);
      const formData: FormData = new FormData();
      formData.append('type', TYPE_MEDIA.VARIATIONS.toString());
      formData.append('file', image);
      const {
        data: { url },
      } = await UploadMediaApi.upLoadMedia(formData);

      return url;
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
    return '';
  };

  const handleOnDrop = async (acceptedFiles: File[]) => {
    if (error) setError('');
    if (acceptedFiles.length === 0) {
      toast('画像タイプはjpeg, pngまたはgifの画像を登録してください。', { type: 'error' });
      return;
    }
    const file = acceptedFiles[0];
    const isValidType = checkIfFilesAreCorrectType(file);
    if (!isValidType) {
      setError('画像タイプはjpeg, pngまたはfgifの画像を登録してください。');
      return;
    }
    const isSquareImg = await checkImgSquareAndDimension(file, 600);
    if (!isSquareImg) {
      setError('画像サイズは縦横６００ｘ６００ピクセルの画像を登録してください。');
      return;
    }

    const url = await uploadImage(file);
    setFiles([{ ...acceptedFiles[0], preview: url }]);
    dispatch(setCoverImage({ urlImage: url, variationIndex: variationIndex }));
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: 'image/jpeg,image/png,image/gif',
    onDrop: handleOnDrop,
  });

  const thumbs = files.map((file: FilesMiim) => {
    return (
      <div key={file.path} {...getRootProps({ className: 'file-box' })}>
        <input {...getInputProps()} />
        <img
          className="border rounded file-box"
          src={handleConnectStaticEndPoint(file?.preview || '')}
          alt=""
        />
      </div>
    );
  });

  const fileRejectionItems = fileRejections.map(({ errors }) => {
    return (
      <>
        {errors.map((e) =>
          e.code != 'file-invalid-type' ? <MessageError message={e.message} key={e.code} /> : <></>,
        )}
      </>
    );
  });

  return (
    <div>
      <SpinnerComponent isLoading={loading} />

      {maxFiles === 1 && isEmpty(files) && (
        <section>
          <div
            {...getRootProps({
              className:
                'dropzone file-box d-flex align-items-center justify-content-center border rounded',
            })}
          >
            {coverImageUrl ? (
              <>
                <img
                  className="border rounded file-box"
                  src={handleConnectStaticEndPoint(coverImageUrl || '')}
                  alt=""
                />
                <input {...getInputProps()} />
              </>
            ) : (
              <>
                <input {...getInputProps()} />
                <p className="gray">画像ファイルをアップロードしてください</p>
              </>
            )}
          </div>
        </section>
      )}
      {maxFiles === 1 && <div>{thumbs}</div>}

      {fileRejectionItems}
      <MessageError message={error} key={error} />
    </div>
  );
};

export default BoxDropZone;
