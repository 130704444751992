import React, { useMemo } from 'react';

import { TableGroup, TableColumns } from 'Components/Common/Table/TableGroup';
import dayjs from 'dayjs';

import { DATE_FORMAT_12, DATE_FORMAT_17 } from 'Constant/Date';
import 'dayjs/locale/ja';
import StastiticRow from 'Components/Page/Campaign/StatisticCampaign/StastiticRow';
import { IStandard } from 'Types/Page/Campaign';
interface TableProps {
  dataSource: IStandard[];
}
export default function StandardTable({ dataSource }: TableProps): JSX.Element {
  const dayOfMonth = useMemo(() => {
    const dateList = dataSource.map((item) => item.date);
    return dateList.map((day) => ({
      text: dayjs(day).format(DATE_FORMAT_17),
      colSpan: 1,
    }));
  }, [dataSource]);

  const getColClass = (day: string) => {
    let classCol = 'bg-smoke';
    if (day === '土') {
      classCol = 'satColor';
    }
    if (day === '日') {
      classCol = 'sunColor';
    }
    return classCol;
  };

  const dayOfWeek = useMemo(() => {
    const dateList = dataSource.map((item) => item.date);
    return dateList.map((day) => ({
      text: dayjs(day).locale('ja').format(DATE_FORMAT_12),
      dataIndex: day?.toString(),
      className: getColClass(dayjs(day).locale('ja').format(DATE_FORMAT_12)),
    }));
  }, [dataSource]);
  const columns = useMemo(
    () =>
      [
        {
          columns: [
            { rowSpan: 2, text: '日付', width: 80, className: 'standard-name' },
            ...dayOfMonth,
          ],
        },
        {
          columns: dayOfWeek,
        },
      ] as TableColumns[],
    [dayOfMonth, dayOfWeek],
  );
  return (
    <>
      <TableGroup tableColumns={columns} dataSource={dataSource} tableClass="standardTable">
        <StastiticRow title="応募数" dataIndex="applyNumber" dataTable={dataSource} />
        <StastiticRow title="当選数" dataIndex="winningNumer" dataTable={dataSource} />
        <StastiticRow title="利用数" dataIndex="usedNumber" dataTable={dataSource} />
      </TableGroup>
      <p>※利用数：デジタル特典はダウンロード数：入場券、特典券は使用数</p>
    </>
  );
}
