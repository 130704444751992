import React from 'react';
import { Form } from 'react-bootstrap';
import TableStep2 from 'Components/Page/Campaign/RegisterCampaign/Common/TableStep2';
import { TargetProduct } from 'Constant/Campaign';
import { CampaignObject } from 'Types/Page/Campaign/RegisterCampaign';
import { formatCurrency } from 'Utils/Numbers';
interface ColumnsTypes {
  className?: string;
  width?: number;
  text: string;
  dataIndex: string;
  rowSpan?: number;
  colSpan?: number;
  render?: (data: any) => void;
}

interface TableColumns {
  columns: ColumnsTypes[];
}

interface TableProps {
  dataSource: CampaignObject[];
  nestIndex?: number;
  isShowConditions?: boolean;
  titleName?: string;
}

interface CampaignObjectOpen extends CampaignObject {
  rIndex: number;
}

interface CheckboxType extends Omit<TableProps, 'dataSource' | 'nestIndex'> {
  item: CampaignObjectOpen;
  className: string;
  dataIndex: string;
}

const checkClassName = 'checkbox-no-label check-box-size check-box-ct';

const CheckBoxFalse = ({ className }: { className: string }) => {
  return (
    <div className="text-center">
      <Form.Check type="checkbox" defaultChecked={false} className={className} />
    </div>
  );
};

const CheckBox = ({ item, dataIndex, className }: CheckboxType) => {
  return (
    <div className="text-center">
      <Form.Check type="checkbox" defaultChecked={(item as any)[dataIndex]} className={className} />
    </div>
  );
};

const handleRenderCheckBox = ({ item, dataIndex, className }: CheckboxType) => {
  return (
    <>
      {item?.enabled ? (
        <CheckBox item={item} dataIndex={dataIndex} className={className} />
      ) : (
        <CheckBoxFalse className={className} />
      )}
    </>
  );
};

const TableCampaignView = ({
  dataSource,
  isShowConditions,
  titleName,
}: TableProps): JSX.Element => {
  let headerTables = [
    {
      columns: [
        {
          className: 'text-center align-middle',
          text: 'No',
          rowSpan: 2,
          width: 50,
          dataIndex: 'index',
          render: ({ item }) => {
            return <div className="text-center">{item.rIndex + 1}</div>;
          },
        },
        {
          className: 'text-center align-middle',
          text: '対象商品',
          rowSpan: 2,
          width: 500,
          dataIndex: 'variations',
          render: ({ item }) => {
            return (
              <div style={{ maxWidth: 600 }}>
                {titleName} {(item?.name || item?.variationName) ?? item?.cdName}&nbsp;
                {item?.type === TargetProduct.CD ? (
                  <span className="badge bg-secondary">CD</span>
                ) : (
                  ''
                )}
              </div>
            );
          },
        },
        {
          className: 'text-center align-middle',
          text: '対象選択',
          rowSpan: 2,
          width: 50,
          dataIndex: 'enabled',
          render: ({ item, dataIndex }) => {
            return <CheckBox item={item} dataIndex={dataIndex} className={checkClassName} />;
          },
        },
        {
          className: 'text-center align-middle',
          text: '種別',
          rowSpan: 2,
          width: 100,
          dataIndex: 'type',
          render: ({ item }) => {
            return (
              <div className="text-center">{item?.type === TargetProduct.CD ? 'CD' : 'DP'}</div>
            );
          },
        },
        {
          className: 'text-center align-middle',
          text: '品番',
          rowSpan: 2,
          width: 150,
          dataIndex: 'productCode',
          render: ({ item, dataIndex }) => {
            return <div className="text-center text-break">{item[dataIndex]}</div>;
          },
        },
        {
          className: 'text-center align-middle',
          text: '価格',
          rowSpan: 2,
          width: 100,
          dataIndex: 'price',
          render: ({ item, dataIndex }) => {
            return <div className="text-end">{`¥ ${formatCurrency(item[dataIndex])}`}</div>;
          },
        },
      ],
    },
  ] as TableColumns[];

  if (isShowConditions) {
    headerTables = [
      {
        columns: [
          ...headerTables[0].columns,
          {
            className: 'text-center align-middle',
            text: '条件マッピング',
            colSpan: 5,
            dataIndex: '',
          },
        ],
      },
      {
        columns: [
          {
            className: 'text-center align-middle',
            text: '条件1',
            width: 80,
            dataIndex: 'option1',
            render: ({ item, dataIndex }) => {
              return handleRenderCheckBox({
                item,
                dataIndex,
                className: checkClassName,
              });
            },
          },
          {
            className: 'text-center align-middle',
            text: '条件2',
            width: 80,
            dataIndex: 'option2',
            render: ({ item, dataIndex }) => {
              return handleRenderCheckBox({
                item,
                dataIndex,
                className: checkClassName,
              });
            },
          },
          {
            className: 'text-center align-middle',
            text: '条件3',
            width: 80,
            dataIndex: 'option3',
            render: ({ item, dataIndex }) => {
              return handleRenderCheckBox({
                item,
                dataIndex,
                className: checkClassName,
              });
            },
          },
          {
            className: 'text-center align-middle',
            text: '条件4',
            width: 80,
            dataIndex: 'option4',
            render: ({ item, dataIndex }) => {
              return handleRenderCheckBox({
                item,
                dataIndex,
                className: checkClassName,
              });
            },
          },
        ],
      },
    ];
  }

  return (
    <div className="pe-none user-select-none">
      <TableStep2 headerTables={headerTables} dataSource={dataSource} />
    </div>
  );
};

export default TableCampaignView;
