import CardContainer from 'Components/Common/CardContainer';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import TitleInformation from 'Components/Page/Title/Copm/TitleInformation';
import VariationInformation from 'Components/Page/Title/Copm/VariationInformation';
import { TitleApi } from 'Datasource/TitleApi';
import useMessage from 'Hooks/useMessage';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container } from 'react-bootstrap';
import { StringParam, useQueryParams } from 'use-query-params';
import { handleConvertTitleInfo } from 'Utils/Title';

export default function TitleReview(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [titleInfo, setTitleInfo] = useState<any>();
  const { openMessageError } = useMessage();

  const [query] = useQueryParams({
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
  });

  const handleGetTitle = async (id: string) => {
    const queryArr = Object.values(query);
    const arrLookupNoData = queryArr.filter((q: any) => {
      return !!q && q === 'no-data';
    });

    if (arrLookupNoData.length > 0) {
      return;
    }
    try {
      setLoading(true);
      const {
        data: { data },
      } = await TitleApi.getTitle({ id });
      setTitleInfo(handleConvertTitleInfo(data));
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    query?.titleIds && handleGetTitle(query.titleIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.titleIds]);

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      {titleInfo?.id && (
        <Container className="mb-2" id="review">
          <CardContainer title="タイトル情報" darkHeader className="mb-3 mt-2">
            <Col className="mx-4 mt-3">
              <span>登録状況</span>

              <Button
                className={`btn me-2 h-35px mw-35 ms-4 ${!titleInfo.status && 'btn-gradients'}`}
              >
                <p>{titleInfo.status ? '登録済' : '一時保存'}</p>
              </Button>
            </Col>

            <TitleInformation titleForm={titleInfo} />
            {titleInfo?.variations?.map((data: any, index: number) => (
              <VariationInformation
                data={data}
                key={index}
                numberOfVariations={titleInfo?.variations?.length}
              />
            ))}
          </CardContainer>
        </Container>
      )}
    </>
  );
}
