import brAxios from 'Api/Interceptors';
import { AxiosResponse } from 'axios';

interface QueryParamsArtists {
  page?: number;
  perPage?: number;
  query?: string | number;
  type?: string;
  screen?: string;
  artistIds?: string;
}

interface QueryParamsTitles extends Omit<QueryParamsArtists, 'screen' | 'artistIds'> {
  companyId?: string;
  artistId?: string;
  titleId?: string;
}

interface QueryParamsVariations extends Omit<QueryParamsArtists, 'screen' | 'artistIds'> {
  titleID?: number;
  artistId?: string;
  titleId?: string;
}

export const SampleMasterApi = {
  getAllSuggestionArtistSample: ({
    page,
    perPage,
    query,
    screen,
  }: QueryParamsArtists): Promise<AxiosResponse> => {
    const response = brAxios.get(
      `/suggestion/sample/artists?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }&screen=${screen ?? ''}`,
    );
    return response;
  },

  getAllSuggestionTitlesSample: ({
    page,
    perPage,
    query,
    companyId,
    artistId,
  }: QueryParamsTitles): Promise<AxiosResponse> => {
    const response = brAxios.get(
      `/suggestion/sample/titles?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }&companyId=${companyId ?? ''}&artistId=${artistId ?? ''}`,
    );
    return response;
  },

  getAllSuggestionVariationsSample: ({
    page,
    perPage,
    query,
    artistId,
    titleId,
  }: QueryParamsVariations): Promise<AxiosResponse> => {
    const response = brAxios.get(
      `/suggestion/sample/variations?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }&artistId=${artistId ?? ''}&titleId=${titleId ?? ''}`,
    );
    return response;
  },

  getListUserFromExcel: (file: FormData): Promise<AxiosResponse> => {
    const response = brAxios.post(
      `/record-company/titles/sample-master-dp/read-file/user-id`,
      file,
    );
    return response;
  },

  getListUserReleasedByVaritionId: ({
    variationId,
  }: {
    variationId: string;
  }): Promise<AxiosResponse> =>
    brAxios.get(
      `/record-company/titles/users-belong-to-sample-master/variation/${variationId ?? ''}`,
    ),

  createSampleMasterDP: (params: {
    variationId: number;
    userIds: number[] | string[];
  }): Promise<AxiosResponse> =>
    brAxios.post(`record-company/titles/sample-master-dp/create`, params),

  getUserByMiimID: (miimID: string): Promise<AxiosResponse> => {
    const response = brAxios.post(`/record-company/titles/sample-master-dp/get-user/user-id`, {
      miimID: miimID,
    });
    return response;
  },
};
