import React, { memo, CSSProperties } from 'react';
import dayjs from 'dayjs';
import { DATE_FORMAT_1 } from 'Constant/Date';
import MessageErrorWebApp from 'Components/Common/MessageErrorWebApp';

const RowContent = memo(
  ({
    title,
    content,
    styleTitle,
    styleContent,
    disableContentTruncate = false,
  }: {
    title: JSX.Element | string;
    content: JSX.Element | string;
    styleTitle?: CSSProperties;
    styleContent?: CSSProperties;
    disableContentTruncate?: boolean;
  }) => {
    return (
      <div className="py-2 w-100">
        <div style={styleTitle ? { ...styleTitle } : { opacity: 0.5 }} className="fs-11 fw-700">
          {title}
        </div>
        <div className="border-bottom"></div>
        <div
          className={`ps-2 ${disableContentTruncate ? '' : 'text-truncate'}`}
          style={styleContent ? { ...styleContent } : { fontSize: '16px' }}
        >
          {content}
        </div>
      </div>
    );
  },
);

const DateRange = memo(({ dateStart, dateEnd }: { dateStart: Date; dateEnd: Date }) => {
  if (dateEnd === dateStart) {
    return (
      <div className="d-flex">
        <div className="me-2">{dayjs(dateStart).format(DATE_FORMAT_1)}</div>
      </div>
    );
  }
  return (
    <div className="d-flex">
      <div className="me-2">{dayjs(dateStart).format(DATE_FORMAT_1)}</div>
      <div>~</div>
      <div className="ms-2">{dayjs(dateEnd).format(DATE_FORMAT_1)}</div>
    </div>
  );
});

const ComfirmStopSale = ({ data }: { data: any }): JSX.Element => {
  return (
    <div className="w-100">
      <RowContent title="アーティスト" content={data?.artistName} />
      <RowContent
        title="開催期間"
        content={<DateRange dateStart={data?.startDate} dateEnd={data?.endDate} />}
      />
      <RowContent title="会場" content={data?.placeName} />
      <RowContent title="特典券 表示名称" content={data?.name} />
      <RowContent
        title={<MessageErrorWebApp classWrapper="ms-0" message={'ご注意'} />}
        content={data?.note}
        styleTitle={{ opacity: 1 }}
        styleContent={{
          color: '#CC3636',
          fontSize: '13px',
        }}
        disableContentTruncate={true}
      />
    </div>
  );
};

export default memo(ComfirmStopSale);
