import _, { isEmpty } from 'lodash';
import React from 'react';
import { Table } from 'react-bootstrap';

const TableStep2 = ({
  headerTables,
  dataSource,
}: {
  headerTables: any;
  dataSource: any;
}): JSX.Element => {
  const headerFields = _.flatMap(headerTables, ({ columns }) =>
    _.filter(columns, (column) => !!column.dataIndex),
  );

  return (
    <div>
      <Table className="table-campaign mb-0" bordered responsive>
        <thead>
          {headerTables.map((row: any, rIndex: number) => {
            return (
              <tr key={rIndex}>
                {row.columns.map((column: any, cIndex: number) => {
                  return (
                    <th
                      key={cIndex}
                      colSpan={column.colSpan}
                      rowSpan={column.rowSpan}
                      className={column.className}
                      style={{ width: column.width }}
                    >
                      {column.text}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {isEmpty(dataSource) ? (
            <td className="text-center" colSpan={headerFields?.length}>
              【登録されていません】
            </td>
          ) : (
            dataSource.map((row: any, rIndex: number) => {
              return (
                <tr key={rIndex}>
                  {headerFields.map((cell, cellIndex) => {
                    return (
                      <td key={cellIndex}>
                        {cell.render
                          ? cell.render({
                              item: { ...row, rIndex },
                              rIndex,
                              dataIndex: cell.dataIndex,
                            })
                          : (row as any)[cell.dataIndex]}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableStep2;
