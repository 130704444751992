import TableCommon from 'Components/Common/Table/TableCommon';
import React, { useMemo } from 'react';
import { Cell } from 'react-table';
import { formatFixedDecimalAndNumb, formatPrice } from 'Utils/Numbers';

export default function PurchaseDetailTable({
  totalCurPrice,
  totalLicensePrice,
  totalPrevPrice,
  totalTotalPrice,
  totalTotalPriceAfterApplyBuyingRate,
}: {
  totalCurPrice: number;
  totalLicensePrice: number;
  totalPrevPrice: number;
  totalTotalPrice: number;
  totalTotalPriceAfterApplyBuyingRate: number;
}): JSX.Element {
  const purchaseData = [
    {
      totalCurPrice,
      totalLicensePrice,
      totalPrevPrice,
      totalTotalPrice,
      totalTotalPriceAfterApplyBuyingRate,
    },
  ];
  const column = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: '仕入金額',
            accessor: 'totalTotalPriceAfterApplyBuyingRate',
            width: '120',
            disableSortBy: true,
            className: 'lg-fs-12px lg-header-table-padding-3px',
            Cell: ({ row }: Cell<any>) => {
              return (
                <div className="text-truncate text-start payment-advice-padding text-right lg-fs-10px">
                  {row.original.totalTotalPriceAfterApplyBuyingRate &&
                    formatPrice(
                      formatFixedDecimalAndNumb(
                        row.original.totalTotalPriceAfterApplyBuyingRate,
                        2,
                      ),
                    )}
                </div>
              );
            },
          },
          {
            Header: '著作権使用料',
            accessor: 'totalLicensePrice',
            width: '120',
            disableSortBy: true,
            className: 'lg-fs-12px lg-header-table-padding-3px',
            Cell: ({ row }: Cell<any>) => {
              return (
                <div className="text-truncate text-start payment-advice-padding text-right lg-fs-10px">
                  {row.original?.totalLicensePrice &&
                    formatPrice(formatFixedDecimalAndNumb(row.original?.totalLicensePrice, 2))}
                </div>
              );
            },
          },
          {
            Header: '前月繰越金',
            accessor: 'totalPrevPrice',
            width: '120',
            disableSortBy: true,
            className: 'lg-fs-12px lg-header-table-padding-3px',
            Cell: ({ row }: Cell<any>) => {
              return (
                <div className="text-truncate text-start payment-advice-padding text-right lg-fs-10px">
                  {row.original.totalPrevPrice &&
                    formatPrice(formatFixedDecimalAndNumb(row.original.totalPrevPrice, 2))}
                </div>
              );
            },
          },
          {
            Header: '当月繰越金',
            accessor: 'totalCurPrice',
            width: '120',
            disableSortBy: true,
            className: 'lg-fs-12px lg-header-table-padding-3px',
            Cell: ({ row }: Cell<any>) => {
              return (
                <div className="text-truncate text-start payment-advice-padding text-right lg-fs-10px">
                  {row.original.totalCurPrice &&
                    formatPrice(formatFixedDecimalAndNumb(row.original.totalCurPrice, 2))}
                </div>
              );
            },
          },
          {
            Header: '支払額',
            accessor: 'totalTotalPrice',
            width: '120',
            disableSortBy: true,
            className: 'lg-fs-12px lg-header-table-padding-3px w-180px',
            Cell: ({ row }: Cell<any>) => {
              return (
                <div className="text-truncate text-start payment-advice-padding text-right lg-fs-10px">
                  {row.original.totalTotalPrice &&
                    formatPrice(formatFixedDecimalAndNumb(row.original.totalTotalPrice, 2))}
                </div>
              );
            },
          },
        ],
      },
    ],
    [],
  );

  return (
    <>
      <TableCommon
        // heightBody="calc(100vh - 250px)"
        columns={column}
        data={purchaseData}
        getCellProps={() => ({
          className: 'p-0',
        })}
        manualSortBy
        isNotShowSort={true}
        classTable="mt-3"
      />
    </>
  );
}
