import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Row, Col } from 'react-bootstrap';
import ja from 'date-fns/locale/ja';
import MessageError from 'Components/Common/MessageError';
import { DATE_FORMAT_FOR_DATE_PICKER, MAX_DATE } from 'Constant/Date';
import { DateRangePickerProps } from 'Components/Common/DateRangePicker/date-range';
import dayjs from 'dayjs';
registerLocale('ja', ja);

export default function DateRangePicker(props: DateRangePickerProps): JSX.Element {
  const {
    placeholderStart,
    placeholderEnd,
    registerStartDate,
    registerEndDate,
    onChangeStartDate,
    onChangeEndDate,
    minDate,
    startDate,
    endDate,
    errorStartDate,
    errorEndDate,
    checkUpdateRange,
    showTimeSelect = false,
    endDateDisabled = false,
    startDateDisabled = false,
    dateFormat = DATE_FORMAT_FOR_DATE_PICKER,
    minStartDate,
    maxStartDate,
    maxEndDate,
  } = props;

  const filterPassedTime = (time: Date) => {
    if (!checkUpdateRange) return true;
    let valid = true;
    const selectedDate = new Date(time);
    if (minStartDate && dayjs(minStartDate).isSame(dayjs(selectedDate), 'day')) {
      valid = new Date(minStartDate).getTime() - 30 * 60 < selectedDate.getTime();
    }
    if (!endDate && maxStartDate && dayjs(maxStartDate).isSame(dayjs(selectedDate), 'day')) {
      valid = selectedDate.getTime() < new Date(maxStartDate).getTime() - 30 * 60;
    }
    if (endDate && dayjs(endDate).isSame(dayjs(selectedDate), 'day')) {
      valid = selectedDate.getTime() < new Date(endDate).getTime() - 30 * 60;
    }
    return valid;
  };

  const filterPassedTimeEnd = (time: Date) => {
    if (!checkUpdateRange) return true;
    let valid = true;
    const selectedDate = new Date(time);
    if (startDate && dayjs(startDate).isSame(dayjs(selectedDate), 'day')) {
      valid = new Date(startDate).getTime() - 30 * 60 < selectedDate.getTime();
    }
    if (maxEndDate && dayjs(maxEndDate).isSame(dayjs(selectedDate), 'day')) {
      valid = selectedDate.getTime() < new Date(maxEndDate).getTime() - 30 * 60;
    }
    return valid;
  };

  return (
    <Row>
      <Col md="5" className="px-0 custom-date-picker">
        <div className="position-relative">
          <DatePicker
            {...registerStartDate}
            locale="ja"
            dateFormat={dateFormat}
            timeFormat="HH:mm"
            autoComplete="off"
            placeholderText={placeholderStart ?? ''}
            className="form-control custom-icon-date"
            showTimeSelect={showTimeSelect}
            filterTime={filterPassedTime}
            selected={startDate}
            onChange={(e: Date | null) => {
              onChangeStartDate && onChangeStartDate(e);
            }}
            minDate={minStartDate ? minStartDate : minDate}
            maxDate={maxStartDate || new Date(MAX_DATE)}
            disabled={startDateDisabled}
            strictParsing
          />
        </div>
        <MessageError message={errorStartDate} />
      </Col>
      <Col md="2">
        <div className="mx-3 fs-18 text-center">&#8764;</div>
      </Col>
      <Col md="5" className="px-0 custom-date-picker">
        <div className="position-relative">
          <DatePicker
            {...registerEndDate}
            locale="ja"
            dateFormat={dateFormat}
            timeFormat="HH:mm"
            autoComplete="off"
            placeholderText={placeholderEnd ?? ''}
            className="form-control custom-icon-date"
            showTimeSelect={showTimeSelect}
            filterTime={filterPassedTimeEnd}
            selected={endDate}
            onChange={(e: Date | null) => {
              onChangeEndDate && onChangeEndDate(e);
            }}
            minDate={startDate ? startDate : minDate}
            disabled={endDateDisabled}
            maxDate={maxEndDate ?? new Date(MAX_DATE)}
            strictParsing
          />
        </div>
        <MessageError message={errorEndDate} />
      </Col>
    </Row>
  );
}
