import { flatMap, filter, isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { Table, Button } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { UserSearchCampaign } from 'Types/Page/Campaign';

interface ColumnsTypes {
  className?: string;
  width?: number;
  text: string;
  dataIndex: string;
  render?: (data: any) => void;
}

interface TableColumns {
  columns: ColumnsTypes[];
}

interface Props {
  dataSource: UserSearchCampaign[];
  isBlackList?: boolean;
  onDelete?: (data: any) => void;
  onAdd?: (data: any) => void;
  viewOnly?: boolean;
}
export default function TableUserResult({
  dataSource,
  isBlackList,
  onAdd,
  onDelete,
  viewOnly,
}: Props): JSX.Element {
  const headerTables = useMemo(
    () =>
      [
        {
          columns: [
            {
              className: 'text-center align-middle',
              text: 'ユーザーID',
              dataIndex: 'userMiim',
              width: 80,
            },
            {
              className: 'text-center align-middle',
              text: '本名',
              dataIndex: 'realName',
              width: 80,
            },
            {
              className: 'text-center align-middle',
              text: 'メールアドレス',
              width: 80,
              dataIndex: 'email',
            },
            {
              className: 'text-center align-middle',
              text: '地域',
              width: 80,
              dataIndex: 'address',
            },
            {
              className: 'text-center align-middle',
              text: '',
              width: 80,
              dataIndex: 'userId',
              render: ({ item }) => {
                return (
                  <div className="ms-auto p-2">
                    {!viewOnly && (
                      <>
                        {isBlackList ? (
                          <Button variant="secondary" onClick={() => onDelete && onDelete(item)}>
                            <Trash size="14" />
                          </Button>
                        ) : (
                          <Button variant="primary" onClick={() => onAdd && onAdd(item)}>
                            追加
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                );
              },
            },
          ],
        },
      ] as TableColumns[],
    [isBlackList, onAdd, onDelete, viewOnly],
  );

  const headerFields = flatMap(headerTables, ({ columns }) =>
    filter(columns, (column) => !!column.dataIndex),
  );

  return (
    <div>
      <Table responsive className="table-user-result">
        <thead>
          {headerTables.map((row, rIndex) => {
            return (
              <tr key={rIndex} className="border-bottom">
                {row.columns.map((column, cIndex) => {
                  return (
                    <th key={cIndex} className={column.className} style={{ width: column.width }}>
                      {column.text}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {isEmpty(dataSource) ? (
            <td className="text-center align-middle" colSpan={headerFields?.length}>
              【登録されていません】
            </td>
          ) : (
            dataSource.map((row: any, rIndex: number) => {
              return (
                <tr key={rIndex}>
                  {headerFields.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={`${
                        cell.dataIndex === 'id' ? 'text-end' : 'text-center'
                      } pb-0 text-truncate align-middle`}
                      style={{ maxWidth: '80px' }}
                    >
                      {cell.render
                        ? cell.render({ item: { ...row, rIndex }, dataIndex: cell.dataIndex })
                        : (row as any)[cell.dataIndex]}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
    </div>
  );
}
