import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Table } from 'react-bootstrap';
import TableRow from 'Components/Page/Campaign/RegisterCampaign/Step3/TableRow';
import { campaignOptionsType, CourseTypes, subTypeOptions } from 'Constant/Campaign';
import { find, isEmpty, some, uniqBy } from 'lodash';
import { Prize } from 'Types/Page/Campaign/RegisterCampaign';
import useCampaign from 'Hooks/useCampaign';
import { updateQuery } from 'Utils/Search';
interface TableProps {
  handleShow: (item: any) => void;
  prizes: Prize[];
  courseIndex: number;
  isDisabledItem?: boolean;
  control: any;
  register: any;
  setValue: any;
  getValues: any;
  watch: any;
}

const arrayMoveMutate = (array: any, from: any, to: any) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

const arrayMove = (array: any, from: any, to: any) => {
  array = array.slice();
  arrayMoveMutate(array, from, to);
  return array;
};

const SortableCont = SortableContainer(({ children }: { children: any }) => {
  return <tbody className="table-drag-item-body">{children}</tbody>;
});

const SortableItem = SortableElement((props: any) => {
  return <TableRow {...props} />;
});

const TableAward = ({
  handleShow,
  prizes,
  courseIndex,
  isDisabledItem,
  getValues,
  setValue,
  control,
  watch,
  register,
}: TableProps): JSX.Element => {
  const { handleReplacePrizes } = useCampaign();
  const [items, setItems] = useState(prizes);
  const [mounted, setMounted] = useState(false);

  const prizeNotIncentive = prizes.filter((p: Prize) => !p.isIncentive);
  const prizeIncentive = prizes.filter((p: Prize) => p.isIncentive);
  const isDisableSort = useCallback(
    (index: number) => {
      const maxIndex = items.length - 1;
      const disableCourseType =
        watch(`courses.${courseIndex}.type`) !== CourseTypes.REALTIME
          ? !prizes[index]?.userQuantity
          : prizes[index]?.isIncentive;
      return (
        prizeNotIncentive.length > 0 &&
        prizeIncentive.length === 1 &&
        index === maxIndex &&
        disableCourseType
      );
    },
    [
      items.length,
      prizeNotIncentive.length,
      prizeIncentive.length,
      prizes,
      watch(`courses.${courseIndex}.type`),
    ],
  );

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (!isDisabledItem && !isDisableSort(newIndex)) {
        let items: any = [];
        setItems((oldItems: any) => {
          items = arrayMove(oldItems, oldIndex, newIndex);
          return items;
        });
        const prizes = [...items].map((item: any, index: number) => ({
          ...item,
          index,
        }));

        updateQuery(prizes, () => {
          handleReplacePrizes({ prizes, courseIndex });
        });
      }
    },
    [courseIndex, handleReplacePrizes, isDisableSort, isDisabledItem],
  );

  useEffect(() => {
    const duplicatePrizes = uniqBy(prizes, function (e) {
      return e;
    });
    setItems(duplicatePrizes);
  }, [prizes]);

  const showSlipRate = useMemo(() => {
    let show = false;
    if (getValues(`courses.${courseIndex}.subType`) === subTypeOptions[0].value) {
      show = items.filter((prize: Prize) => !prize.userQuantity).length === 0;
    }
    if (getValues(`courses.${courseIndex}.subType`) === subTypeOptions[1].value) {
      show = items.filter((prize: Prize) => prize.isIncentive).length === 0;
    }
    return show;
  }, [items, getValues(`courses.${courseIndex}.subType`)]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const getWidth = React.useMemo(() => {
    return getValues(`courses.${courseIndex}.type`) === 2 &&
      getValues(`courses.${courseIndex}.subType`) !== 2
      ? 300
      : 650;
  }, [courseIndex, getValues, mounted]);

  return (
    <div>
      <Table className="table-campaign table-award-campaign header-light-gray" responsive>
        <thead>
          <tr>
            <th
              style={{
                width: `${getWidth}px`,
              }}
            >
              賞の名称
            </th>
            <th>種別</th>
            <th>当選数上限</th>
            {getValues(`courses.${courseIndex}.type`) === CourseTypes.REALTIME &&
              getValues(`courses.${courseIndex}.subType`) !== subTypeOptions[2].value && (
                <>
                  <th>当選確率</th>
                </>
              )}

            {getValues(`courses.${courseIndex}.type`) === CourseTypes.REALTIME && (
              <>
                <th>参加賞</th>
              </>
            )}
            <th></th>
          </tr>
        </thead>
        <SortableCont
          onSortEnd={onSortEnd}
          axis="y"
          lockAxis="y"
          lockToContainerEdges={true}
          lockOffset={['30%', '50%']}
          helperClass="helperContainerClass"
          useDragHandle={true}
        >
          {items.map((value, index) => {
            const awardType = find(campaignOptionsType, (option) => option.value === value.type);
            const isEmptyFile = isEmpty(value.prizeDigitalTokutens)
              ? true
              : some(value.prizeDigitalTokutens, (prizeDT) => {
                  return !prizeDT.url;
                });
            return (
              <SortableItem
                key={`item-${index}`}
                index={index}
                first={value?.name}
                second={awardType?.label}
                third={value?.userQuantity ? `${value?.userQuantity} 名` : '上限なし'}
                isDisabled={isDisableSort(index) || isDisabledItem}
                isShowError={isEmptyFile && value.type === campaignOptionsType[3].value}
                handleEditItem={() => handleShow({ ...value, index })}
                courseIndex={courseIndex}
                prizeIndex={index}
                prize={value}
                {...{ control, setValue, getValues, watch, register }}
              />
            );
          })}
          {getValues(`courses.${courseIndex}.type`) === CourseTypes.REALTIME &&
            getValues(`courses.${courseIndex}.subType`) !== subTypeOptions[2].value &&
            showSlipRate && (
              <tr>
                <td>
                  <div className="d-block winRate-column text-right">不当選</div>
                </td>
                <td></td>
                <td></td>
                <td>
                  <div className={`d-block winRate-column-slipRate text-right`}>
                    {getValues(`courses.${courseIndex}.slipRate`) > 0
                      ? `${getValues(`courses.${courseIndex}.slipRate`)}%`
                      : '0%'}
                  </div>
                </td>
                <td></td>
                <td></td>
              </tr>
            )}
        </SortableCont>
      </Table>
    </div>
  );
};

export default TableAward;
