/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';
const getFormData = (object: any) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
};
export const CDApis = {
  getCDs: ({
    limit,
    page,
    artistId,
    titleId,
    cdId,
    query,
    companyId,
    sort,
    order,
  }: {
    limit?: number;
    page?: number;
    artistId?: null | string | undefined;
    titleId?: null | string | undefined;
    cdId?: null | string | undefined;
    companyId?: null | string | undefined;
    query?: string | undefined;
    sort?: string;
    order?: string;
  }) =>
    brAxios.get(
      `/record-company/serial-number/cds?page=${page ?? 1}&limit=${limit ?? 20}${
        artistId ? `&artistId=${artistId}` : ''
      }${titleId ? `&titleId=${titleId}` : ''}${cdId ? `&CDId=${cdId}` : ''}${
        companyId ? `&companyId=${companyId}` : ''
      }${query ? `&query=${query}` : ''}&sort=${sort || 'id'}&order=${order || 'DESC'}`,
    ),
  postCD: ({ params }: { params: any }) =>
    brAxios.postFormData('record-company/serial-number/create-cd', getFormData(params)),
  updateCD: ({ params }: { params: any }) =>
    brAxios.putFormData(`record-company/serial-number/update-cd`, getFormData(params)),

  deleteCD: ({ id }: { id: string | number | undefined }) =>
    brAxios.delete(`record-company/serial-number/delete-cd/${id}`),

  getCD: ({ id }: { id: string | number | undefined }) =>
    brAxios.get(`record-company/serial-number/cd/${id}`),
};
