import dayjs from 'dayjs';

const handleParseDate = ({ date, format }: { date: string | null; format: string }) => {
  if (!date) {
    return null;
  }
  return dayjs(date).format(`${format}`);
};

export { handleParseDate };
