import React, { ReactElement, createElement, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './tooltipsStyled.css';

interface TooltipsWithTruncateProps {
  children: React.ReactNode;
  tooltips: string | ReactElement;
  id?: string;
  overlayProps?: any;
  tooltipProps?: any;
  extraClientWidth?: number; // trừ thêm khi wrapper bên ngoài input quá rộng
  classCustom?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function TooltipsWithTruncate({
  children,
  tooltips,
  id,
  overlayProps = {},
  tooltipProps = {},
  extraClientWidth = 0,
  classCustom = '',
}: TooltipsWithTruncateProps) {
  const contentRef = useRef<any>(null);
  const originalRef = useRef<any>(null);

  const [isShow, setIsShow] = useState(true);

  const handleMouseOver = () => {
    if (
      originalRef?.current?.clientWidth >
      contentRef?.current?.clientWidth - (extraClientWidth ? extraClientWidth : 0)
    ) {
      setIsShow(true);
      return;
    }
  };

  const handleMouseLeave = () => {
    setIsShow(false);
  };

  return (
    <>
      <OverlayTrigger
        {...overlayProps}
        delay={0}
        overlay={
          isShow ? (
            <Tooltip
              id={id ? id : tooltips}
              className={`tooltipsWithTruncate ${classCustom}`}
              {...tooltipProps}
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {tooltips}
            </Tooltip>
          ) : (
            <></>
          )
        }
      >
        {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
        <div ref={contentRef} onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave}>
          {children}
        </div>
      </OverlayTrigger>

      {createElement('div', { className: 'origin-ref-content', ref: originalRef }, tooltips)}
    </>
  );
}

export default TooltipsWithTruncate;
