/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';
import { transformQuery } from 'Utils/Params';
import { ActionType } from '../Constant';

export interface ParamExcelStatistic {
  courseId?: string;
  startDate?: string;
  endDate?: string;
  countJoinEnd?: string;
}

interface queryCampaign {
  page?: number;
  perPage?: number;
  query?: string | number;
  type?: string;
  screen?: string;
  artistIds?: string;
  artistId?: string;
  titleId?: string;
  companyId?: string;
  action?: number;
}
interface QueryCourse {
  page?: number;
  perPage?: number;
  query?: string;
  campaignId?: string;
  courseId?: string;
}

export interface ParamExcelPrize {
  campaignId?: string;
  courseId?: string;
  startDate?: string;
  endDate?: string;
  prizeId?: string;
  sort?: string;
  order?: string;
}
export const CampaignApis = {
  getCampaigns: ({
    limit,
    page,
    artistId,
    titleId,
    sort,
    order,
  }: {
    limit?: number;
    page?: number;
    artistId?: null | string | undefined;
    titleId?: null | string | undefined;
    sort?: string;
    order?: string;
  }) =>
    brAxios.get(
      `/record-company/campaigns?page=${page ?? 1}&limit=${limit ?? 20}${
        artistId ? `&artistIds=${artistId}` : ''
      }${titleId ? `&titleIds=${titleId}` : ''}&sort=${sort || 'id'}&order=${order || 'DESC'}`,
    ),
  getCampaign: ({ id }: { id: string }) => brAxios.get(`/record-company/campaigns/${id}`),
  getStatistic: () => brAxios.get('/record-company/campaigns/statistic'),
  postOfficial: ({ params }: { params: any }) => brAxios.post('/record-company/campaigns', params),
  postDraft: ({ params }: { params: any }) =>
    brAxios.post('/record-company/campaign-draft', params),
  updateOfficial: ({ id, params }: { id: string; params: any }) =>
    brAxios.put(`/record-company/campaigns/${id}`, params),
  updateDraft: ({ id, params }: { id: string; params: any }) =>
    brAxios.put(`/record-company/campaign-draft/${id}`, params),

  getCampaignRelation: ({ id }: { id: string }) =>
    brAxios.get(`/record-company/campaigns/relation/${id}`),

  getStatisticData: ({
    campaignId,
    courseId,
    startDate,
    endDate,
    countJoinEnd,
  }: {
    campaignId: string;
    courseId: string;
    startDate: string;
    endDate: string;
    countJoinEnd?: string;
  }) =>
    brAxios.get(
      `/record-company/campaigns/statistics/${campaignId}?courseId=${courseId}&startDate=${startDate}&endDate=${endDate}${
        countJoinEnd ? `&countJoinEnd=${countJoinEnd}` : ''
      }`,
    ),
  exportStatisticExcel: ({
    campaignId,
    params,
  }: {
    campaignId: string;
    params: ParamExcelStatistic;
  }) => {
    const response = brAxios.get(
      `/record-company/campaigns/statistics/export-excel/${campaignId}`,
      { params, responseType: 'blob' },
    );
    return response;
  },

  getAllSuggestionCourses: ({ page, perPage, query, campaignId, courseId }: QueryCourse) => {
    const response = brAxios.get(
      `/suggestion/courses?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }&campaignId=${campaignId ?? ''}&courseId=${courseId ?? ''}`,
    );
    return response;
  },
  getAllSuggestionPrizes: ({ page, perPage, query, campaignId, courseId }: QueryCourse) => {
    const response = brAxios.get(
      `/suggestion/prizes?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }&campaignId=${campaignId ?? ''}&courseId=${courseId ?? ''}`,
    );
    return response;
  },

  getAllSuggestionCampaigns: ({
    page,
    perPage,
    query,
    screen,
    artistId,
    titleId,
    companyId,
    action,
  }: queryCampaign) => {
    const response = brAxios.get(
      `/suggestion/campaigns?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }&companyId=${companyId ?? ''}&artistId=${artistId ?? ''}&titleId=${titleId ?? ''}&screen=${
        screen ?? ''
      }&action=${action || ActionType.VIEW}`,
    );
    return response;
  },

  getPrizes: (params: any) => {
    const strParam = transformQuery(params);
    const response = brAxios.get(`/record-company/campaigns/prize${strParam}`);
    return response;
  },

  exportPrizeExcel: (params: ParamExcelPrize) => {
    const response = brAxios.get('/record-company/campaigns/prize/export/excel', {
      params,
      responseType: 'blob',
    });
    return response;
  },
  getVariations: ({ id, campaignId }: { id: string; campaignId: string }) =>
    brAxios.get(
      `/record-company/campaigns/course-info/${id}${campaignId ? `?campaignId=${campaignId}` : ''}`,
    ),

  getUsers: ({ query, page, perPage }: { query: string; page?: number; perPage?: number }) =>
    brAxios.get(
      `/record-company/campaign-draft/users?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }`,
    ),

  getDigitalConfig: ({ vIds, titleId }: { vIds: string; titleId: string }) =>
    brAxios.get(`/record-company/campaigns/campaign-data?variationIds=${vIds}&titleId=${titleId}`),
  getCSV: (params: FormData) => {
    const response = brAxios.post('/record-company/campaigns/read-file/user-id', params);
    return response;
  },
  deleteCampaign: ({ id }: { id: string | number }) =>
    brAxios.delete(`/record-company/campaigns/${id}`),

  updateLastDay: ({ id, params }: { id: string | number; params: any }) =>
    brAxios.put(`/record-company/campaigns/last-day/${id}`, params),

  getLotteryMedia: () => brAxios.get(`/record-company/setting-common`),
};
