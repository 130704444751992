/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';
import { ActionType } from 'Constant';
import { INCLUDE_COPY_RIGHT } from 'Constant/Title';
import { filter, isEmpty } from 'lodash';
import { InformationFeePayload, Song } from 'Types/App/title';
import { padNum } from 'Utils/Title';

interface QueryParams {
  page?: number;
  perPage?: number;
  query?: string;
  type?: string | number;
  artistIds?: string;
  titleIds?: string;
  artistID?: number | string;
}

interface QueryParamsAll {
  page?: number;
  perPage?: number;
  query?: string;
  type?: string;
  companyId?: string;
  artistId?: string;
  titleId?: string;
  action?: number;
}

interface IGetAllTitlesQueryParams {
  page?: number;
  limit?: number;
  query?: string | number;
  status?: number | string;
  companyId?: string;
  artistId?: string;
  titleId?: string;
}

const handleConvertSongs = (songs: any, isVideo: boolean, musicFormat?: number) => {
  const result = songs.map((song: Song, songIndex: number) => {
    if (song?.name) {
      const informationFee =
        song?.informationFee && song?.informationFee?.toString() !== '0.00'
          ? +song?.informationFee
          : 0;

      const lyricInformationFee =
        song?.lyricInformationFee && song?.lyricInformationFee?.toString() !== '0.00'
          ? +song?.lyricInformationFee
          : 0;

      let ctCode = padNum(songIndex + 1).toString();

      if (!isVideo) {
        ctCode = '000';
      }
      return {
        ...song,
        index: songIndex + 1,
        musicianFuriganaName: song?.musicianFuriganaName?.trim() || null,
        composerFuriganaName: song?.composerFuriganaName?.trim() || null,
        arrangerFuriganaName: song?.arrangerFuriganaName?.trim() || null,
        contentType: musicFormat === undefined ? 0 : 1,
        contentCode: ctCode,
        medleyType: 1,
        medleyCode: padNum(song.index).toString(),
        musicFormat: musicFormat,
        informationFee: informationFee,
        lyricInformationFee: lyricInformationFee,
        id: song?.id && song?.id?.toString()?.includes('uuid') ? null : song?.id,
      };
    }
  });
  return filter(result, 'name');
};

const handleConvertMedia = (medias: any) => {
  const result = medias.map((media: any, index: number) => ({
    ...media,
    type: +media.type,
    songs: handleConvertSongs(media?.songs || [], true, undefined),
    index,
    mediaInformationFee: media?.mediaInformationFee ? +media.mediaInformationFee : 0,
    id: media?.id && media?.id?.toString()?.includes('uuid') ? null : media?.id,
  }));
  return result;
};

const handleConvertBooklets = (booklets: any) => {
  const result = booklets.map((booklet: any, index: number) => ({
    ...booklet,
    index: index + 1,
    id: booklet?.id && booklet?.id?.toString()?.includes('uuid') ? null : booklet?.id,
  }));
  return result;
};

const handleConvertVariations = (variations: any) => {
  const result = variations.map((variation: any, index: number) => {
    const images = variation.images.map((image: any) => ({
      ...image,
      includeCopyRightSong: INCLUDE_COPY_RIGHT.NOT_INCLUDE,
    }));
    return {
      ...variation,
      name: index === 0 && isEmpty(variation.name) ? '通常盤' : variation.name,
      songs: handleConvertSongs(variation.songs, false, variation?.musicFormat),
      dpMedias: handleConvertMedia(variation.videos.concat(images)),
      optionId: variation?.option?.id,
      notiDownloadTime: variation.notiDownloadTime || null,
      booklets: handleConvertBooklets(variation?.booklets),
      id: variation?.id && variation?.id?.toString()?.includes('uuid') ? null : variation?.id,
    };
  });
  return result;
};

const handleConvertParams = (params: any) => {
  const result = {
    id: params.id,
    artistId: params.artistId,
    name: params.name,
    type: params.type,
    label: params.label,
    musicGenreId: params.musicGenreId,
    variations: handleConvertVariations(params.variations),
    isAuthor: params.isAuthor,
    status: params.status,
    furiganaName: params.furiganaName,
    sellIap: params.sellIap,
    globalName: params.globalName,
  };
  return result;
};

export const TitleApi = {
  getSuggestionTitles: ({ page, perPage, query }: QueryParams) => {
    const response = brAxios.get(
      `/suggestion/titles?page=${page ?? ''}&limit=${perPage ?? ''}&query=${query ?? ''}`,
    );
    return response;
  },

  getTitle: ({ id }: { id: string | number }) => brAxios.get(`/record-company/titles/${id}`),

  createOfficalTitle: (params: any) =>
    brAxios.post('/record-company/titles', handleConvertParams(params)),

  updateOfficalTitle: ({ id, params }: { id: string | number; params: any }) =>
    brAxios.put(`/record-company/titles/${id}`, handleConvertParams(params)),

  createDraftTitle: (params: any) =>
    brAxios.post('/record-company/title-draft', handleConvertParams(params)),

  updateDraftTitle: ({ id, params }: { id: string | number; params: any }) =>
    brAxios.put(`/record-company/title-draft/${id}`, handleConvertParams(params)),

  getAllTitleByArtistID: ({ artistID, page, perPage, query }: QueryParams) => {
    const response = brAxios.get(
      `/suggestion/title/${artistID}?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }`,
    );
    return response;
  },

  getMusicGenres: () => brAxios.get('/record-company/music-genres'),

  exportExcel: (params: {
    artistId?: string;
    titleId?: string;
    status?: string | number;
    gmt?: number;
  }) => {
    const response = brAxios.get('/record-company/titles/export/download', {
      params,
      responseType: 'blob',
    });
    return response;
  },
  getAllSuggestionTitles: ({
    page,
    perPage,
    query,
    companyId,
    artistId,
    action,
  }: QueryParamsAll) => {
    const response = brAxios.get(
      `/suggestion/titles?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }&companyId=${companyId ?? ''}&artistId=${artistId ?? ''}&action=${
        action || ActionType.VIEW
      }`,
    );
    return response;
  },
  getAllTitles: (params: IGetAllTitlesQueryParams) => {
    const response = brAxios.get(`/record-company/titles`, { params });
    return response;
  },

  getOptions: () => brAxios.get(`/record-company/titles/options`),

  isRoleArtistManager: ({ artistId }: QueryParamsAll) =>
    brAxios.get(`record-company/artists/check-role/${artistId}`),

  getUsedCapacityOfCampaign: ({ titleId }: QueryParamsAll) =>
    brAxios.get(`record-company/campaigns/data/${titleId}`),

  validateCode: (params: any) =>
    brAxios.post('/record-company/titles/validate-variation-code', params),
  caculateInformationFee: ({ variations }: { variations: InformationFeePayload[] }) =>
    brAxios.post('/record-company/titles/information-fee', { variations }),

  getJPRecordingAssociationGenre: () => {
    const response = brAxios.get(`/record-company/genres/riaj-genre`);
    return response;
  },

  getJmdMusicGenre: () => {
    const response = brAxios.get(`/record-company/genres/jmd-music-genre`);
    return response;
  },

  getJmdMusicGenreById: (id: number) => {
    const response = brAxios.get(`/record-company/genres/jmd-music-genre/${id}`);
    return response;
  },

  getJmdVideoGenre: () => {
    const response = brAxios.get(`/record-company/genres/jmd-video-genre`);
    return response;
  },

  getJmdVideoGenreById: (id: number) => {
    const response = brAxios.get(`/record-company/genres/jmd-video-genre/${id}`);
    return response;
  },
};
