/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useCallback } from 'react';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import { Button } from 'react-bootstrap';
import { useRole } from 'Hooks/useRole';

interface IData {
  name: string;
  value: number;
}

// const fakeData = [
//   { name: 'Group A', value: 400 },
//   { name: 'Group B', value: 300 },
//   { name: 'Group C', value: 300 },
//   { name: 'Group D', value: 200 },
// ];

const RADIAN = Math.PI / 180;
// const renderCustomizedLabel = (
//   { cx, cy, midAngle, innerRadius, outerRadius, percent }: any,
//   totalV: number,
// ) => {
//   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <text
//       x={x}
//       y={y}
//       fill="white"
//       textAnchor={x > cx ? 'start' : 'end'}
//       dominantBaseline="central"
//       style={{ fontSize: 32, fontWeight: 'bolder' }}
//     >
//       {`${(percent * totalV).toFixed(0)}`}
//     </text>
//   );
// };

const UsingChart = ({
  data,
  allowAccess,
  // visibleSoldOut,
  colors,
  totalV,
  title,
  dataOrigin,
  onShowPopup,
}: // isShowBtn,
{
  data: IData[];
  allowAccess: any;
  // visibleSoldOut?: boolean;
  colors: Array<string>;
  totalV: number;
  title: string;
  dataOrigin: any;
  onShowPopup: () => void;
  // isShowBtn: boolean;
}): JSX.Element => {
  const { isEC, isECCompEventApp } = useRole();

  const renderCustomizedLabel = useCallback(
    ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
          style={{ fontSize: 32, fontWeight: 'bolder' }}
        >
          {`${(percent * totalV).toFixed(0)}`}
        </text>
      );
    },
    [totalV],
  );

  return (
    <div className="position-relative">
      <div>
        <div className="m-bottom-layout-chart">
          <div className="text-center pt-2 pb-0 font-chart">
            {allowAccess &&
            allowAccess['data'] &&
            dataOrigin?.type === 1 &&
            // visibleSoldOut &&
            // isShowBtn &&
            !isEC &&
            !isECCompEventApp ? (
              <Button
                style={{
                  backgroundColor: dataOrigin?.isActive ? '#CC3636' : 'green',
                  borderStyle: 'none',
                }}
                className="px-4 py-1 fs-16 fw-700"
                onClick={onShowPopup}
              >
                {dataOrigin?.isActive ? `強制販売終了` : `販売再開`}
              </Button>
            ) : (
              <Button className="invisible"></Button>
            )}
          </div>
          <div className="text-center font-chart fw-700">{title}</div>
        </div>
        <div className="d-flex justify-content-center pie-chart-wrapper">
          <PieChart width={300} height={280}>
            <Pie
              data={data}
              startAngle={90}
              endAngle={-270}
              cx={150}
              cy={127}
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={100}
              fill="red"
              dataKey="value"
            >
              {data.map((entry, index) => {
                return <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />;
              })}
            </Pie>
            <Legend verticalAlign="bottom" align="center" />
          </PieChart>
        </div>
      </div>
    </div>
  );
};

export default memo(UsingChart);
