import React, { useMemo } from 'react';
import { find } from 'lodash';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import TableCommon from 'Components/Common/Table/TableCommon';
import { SerialTypeOptions } from 'Constant/SerialNumber';
import { SortMethod } from 'Components/Common/Table/table';
interface IListCD {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export default function SerialNumberTable({
  dataSource,
  onSort,
}: {
  dataSource: IListCD;
  onSort?: (sortingState: SortMethod[]) => void;
}): JSX.Element {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: 'ID',
            accessor: 'id',
            width: '100',
          },
          {
            Header: 'シリアルコードマスタ名',
            accessor: 'name',
            width: '400',
            Cell: ({ row }: Cell<IListCD>) => {
              return (
                <Link
                  to={{
                    pathname: `/serial-numbers/${row.original.id}`,
                    state: { id: row.original.id },
                  }}
                  className={`${!row.original.name && 'text-decoration-none text-black pe-none'}`}
                >
                  <span className="d-block text-truncate text-start">{row.original.name}</span>
                </Link>
              );
            },
          },
          {
            Header: '対象商品',
            accessor: 'nameCd',
            Cell: ({ row }: Cell<IListCD>) => {
              return (
                <>
                  <div>{row.original?.cd?.name ?? '-'}</div>
                </>
              );
            },
          },
          {
            Header: '品番',
            accessor: 'codeCd',
            Cell: ({ row }: Cell<IListCD>) => {
              return (
                <>
                  <div className="d-block text-break">{row.original?.cd?.productCode ?? '-'}</div>
                </>
              );
            },
          },
          {
            Header: '種別',
            accessor: 'type',
            Cell: ({ row }: Cell<IListCD>) => {
              const label =
                find(SerialTypeOptions, (opt) => opt.value === row.original?.type)?.label ?? '';
              return (
                <>
                  <div>{label ?? '-'}</div>
                </>
              );
            },
          },
          {
            Header: '数量',
            accessor: 'quantity',
          },
        ],
      },
    ],
    [],
  );

  return (
    <>
      <TableCommon
        classTable="table-serial-number"
        heightBody="calc(100vh - 200px)"
        data={dataSource}
        columns={columns}
        messageNoData="検索結果がありません。"
        manualSortBy
        onSort={onSort}
      />
    </>
  );
}
