import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { FormGroupSearchProps } from 'Components/Common/Form/form';
import MessageError from 'Components/Common/MessageError';
import SelectOptionComponent from 'Components/Common/Select/CustomSelect/SelectOptionComponent';

export default function FormSelectOption(props: FormGroupSearchProps): JSX.Element {
  const {
    onSearch,
    onSelect,
    placeholder,
    colMd,
    colLg,
    colXXL,
    colXL,
    rowClass,
    colClass,
    showIcon = false,
    showIconClear = false,
    onEnter,
    defaultValue,
    errorMessage,
    inputSearchString = '',
    onInputChange,
    isReview,
    disabled,
    onLoadMore,
    onMenuClose,
    isLoading,
    options,
    onMenuFocus,
    stylesListResult = {},
  } = props;
  return (
    <Form.Group as={Row} className={`${rowClass} ${isReview && 'pe-none'} `}>
      <Col md={colMd} lg={colLg} xl={colXL} xxl={colXXL} className={colClass}>
        <SelectOptionComponent
          onInputChange={onInputChange}
          defaultValue={defaultValue}
          onEnter={onEnter}
          placeholder={placeholder}
          onSearch={(keyword: string) => onSearch && onSearch(keyword)}
          onSelect={onSelect}
          showIcon={showIcon}
          showIconClear={showIconClear}
          inputSearchString={inputSearchString}
          disabled={disabled}
          onLoadMore={onLoadMore}
          onMenuClose={onMenuClose}
          isLoading={isLoading}
          options={options}
          onMenuFocus={onMenuFocus}
          stylesListResult={stylesListResult}
        />
        <MessageError message={errorMessage} />
      </Col>
    </Form.Group>
  );
}
