import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';
import BoxForm from 'Components/Common/Form/BoxForm';
import ModalComponent from 'Components/Common/Modal';
import FormAddSection from 'Components/Page/CompanyInfo/Section/FormAddSection';
import { sectionRequiredNameUpdate } from 'Utils/Validation';
import { RecordCompanyApi } from 'Datasource/RecordCompanyApi';
import useMessage from 'Hooks/useMessage';
import MessageError from 'Components/Common/MessageError';
import PermissionWrapper from 'Components/Common/Permission';
import SpinnerComponent from 'Components/Common/SpinnerComponent';

export interface SectionItem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
export interface ErrorMessage {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const createSchema = (data: SectionItem[]) => {
  const schema: SectionItem = [];
  for (let i = 0; i < data.length; i++) {
    schema[`section${i}`] = sectionRequiredNameUpdate({}, data);
  }

  return yup.object().shape(schema);
};

export default function SectionManage(): JSX.Element {
  const { openMessageError } = useMessage();
  const [isShowModal, toggleModal] = useState(false);
  const [sectionOptions, setSectionOptions] = useState<Array<SectionItem>>([]);
  const [listSectionUpdate, setListSectionUpdate] = useState<Array<SectionItem>>([]);
  const [listIdRemove, setListIdRemove] = useState<Array<number>>([]);

  const {
    handleSubmit,
    register,
    clearErrors,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createSchema(sectionOptions)),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const checkDuplicatesList = (array: Array<SectionItem>) => {
    const ids = array.map((item) => item.id);
    const filtered = array.filter(({ id }, index) => !ids.includes(id, index + 1));
    return filtered;
  };

  const onChangeInputData = (value: string, index: number) => {
    const dataSource = [...sectionOptions];
    dataSource[index].name = value;
    const listItemUpdate = [...listSectionUpdate, dataSource[index]];
    if (dataSource[index]?.id) setListSectionUpdate(checkDuplicatesList(listItemUpdate));
    setSectionOptions(dataSource);
  };

  const handleDeleteItem = (index: number) => {
    const dataSource = [...sectionOptions];
    if (dataSource[index]?.id) setListIdRemove([...listIdRemove, dataSource[index].id]);
    dataSource.splice(index, 1);
    setSectionOptions(dataSource);
    clearErrors();
    reset();
  };

  const onSubmit = () => handleUpdateSection();

  const handleUpdateSection = async () => {
    setIsLoading(true);
    // ADD
    const dataAdd = sectionOptions.filter((section: SectionItem) => !section?.id && section);

    if (!_.isEmpty(dataAdd)) {
      const paramAdd = dataAdd.map((item: SectionItem) => {
        const value = item.name;
        return { name: value };
      });
      try {
        await RecordCompanyApi.addSections({ sections: paramAdd });
      } catch (error) {
        openMessageError(error);
      }
    }

    // UPDATE
    const paramUpdate = listSectionUpdate.map((section: SectionItem) => {
      return {
        id: section.id,
        name: section.name,
      };
    });
    if (!_.isEmpty(paramUpdate)) {
      try {
        await RecordCompanyApi.updateSections({
          sections: paramUpdate,
        });
      } catch (error) {
        openMessageError(error);
      }
    }

    // DELETE
    if (!_.isEmpty(listIdRemove)) {
      const idRemove = listIdRemove.map((id: number) => {
        return { id };
      });
      try {
        await RecordCompanyApi.deleteSections({ sections: idRemove });
      } catch (error) {
        openMessageError(error);
      }
    }
    getSections();
    toggleModal(false);
    setListIdRemove([]);
    setListSectionUpdate([]);
    setIsLoading(false);
  };

  const getSections = async () => {
    setIsLoading(true);
    try {
      const res = await RecordCompanyApi.getSections();
      const { data } = res.data;
      setSectionOptions(data);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = async () => {
    const valid = await trigger();
    if (valid) {
      toggleModal(true);
    }
  };

  const handleAddSection = (value: string) => {
    const dataSource = [{ name: value }, ...sectionOptions];
    setSectionOptions(dataSource);
    clearErrors();
    reset();
  };

  const renderExistedSection = (sectionOptions: Array<SectionItem>) => {
    return sectionOptions.map((item: SectionItem, index: number) => {
      if (!item) return;
      return (
        <React.Fragment key={index}>
          <Row className="mt-2 d-flex justify-content-center">
            <Col md={8}>
              <Form.Control
                {...register(`section${index}`)}
                value={item.name}
                type="text"
                onChange={(e) => onChangeInputData(e.target.value, index)}
              />
              <MessageError message={errors[`section${index}`]?.message} />
            </Col>
            <Col md={1} className="d-flex justify-content-end">
              <Button
                className="btn btn-danger h-max-content"
                onClick={() => handleDeleteItem(index)}
              >
                削除
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    });
  };

  return (
    <PermissionWrapper>
      <Container className="mt-4">
        <SpinnerComponent isLoading={isLoading} />
        <Row>
          <Col md="12" className="px-0">
            <BoxForm title="セクション管理">
              <>
                <FormAddSection onAddSection={handleAddSection} listSection={sectionOptions} />
                <Form onSubmit={handleSubmit(onSubmit)} id="section">
                  <hr className="mt-5" />
                  <Row className="d-flex justify-content-center">
                    <Col md="9">登録済みセクション名を変更できます</Col>
                  </Row>
                  {renderExistedSection(sectionOptions)}
                </Form>
                <ModalComponent
                  show={isShowModal}
                  onCloseModal={() => toggleModal(false)}
                  classNameContent="d-flex align-items-center content-body-modal justify-content-center"
                  variantButtonSubmit="primary"
                  submitIdForm="section"
                >
                  <div>入力した内容で登録します。よろしいですか。</div>
                </ModalComponent>
              </>
            </BoxForm>
            <div className="d-flex justify-content-center mb-5">
              <Button
                className="mt-4 mb-4 btn-focus-none btn-curator-register"
                variant="primary"
                type="submit"
                onClick={() => submitForm()}
              >
                保存する
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </PermissionWrapper>
  );
}
