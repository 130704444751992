import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import useMessage from 'Hooks/useMessage';

const DropzoneDigitalPackage = ({
  acceptTypes,
  handleChange,
  messageError,
  title,
}: {
  acceptTypes: string;
  handleChange: (files: File[]) => void;
  messageError?: string;
  title: string;
}): JSX.Element => {
  const { openMessage } = useMessage();
  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: acceptTypes,
    onDrop: handleChange,
  });

  useEffect(() => {
    fileRejections.map(({ errors }) => {
      errors.map((error) => {
        if (error.code === 'file-invalid-type') {
          openMessage({
            variant: 'error',
            message: messageError,
          });
        }
      });
    });
  }, [fileRejections, openMessage, messageError]);

  return (
    <>
      <div {...getRootProps()} className="d-flex align-items-center justify-content-end py-3">
        <Button className="px-5">
          {title}
          <input {...getInputProps()} />
        </Button>
      </div>
    </>
  );
};

export default DropzoneDigitalPackage;
