import React, { memo, useMemo } from 'react';
import dayjs from 'dayjs';
import { DATE_FORMAT_1 } from 'Constant/Date';
import CheckMark from './CheckMark';
import {
  COLOR_ENTRANCES,
  COLOR_ENTRANCES_SELECTED,
  COLOR_TOKUTEN,
  COLOR_TOKUTEN_SELECTED,
  COLOR_TEXT,
  TYPE_TICKETS,
  NAME_TICKETS,
} from 'Constant/WebApp';

import ENTRANCE_ICON from 'Static/Images/entrance_icon_black.svg';
import TOKUTEN_ICON from 'Static/Images/tokuten_icon_black.svg';

interface IDataProps {
  eventName: string;
  typeTicket: number;
  startDate: Date | null;
  endDate: Date | null;
  time: Date | null;
  place: string;
  onClick: () => void;
  isUnSelected?: boolean | null;
}

const ItemsEvent = ({
  eventName,
  typeTicket,
  startDate,
  endDate,
  time,
  place,
  onClick,
  isUnSelected,
}: IDataProps): JSX.Element => {
  const detectBackgroundColor = (typeTicket: number, isUnSelected?: boolean | null) => {
    if (!isUnSelected) {
      if (typeTicket === TYPE_TICKETS.ENTRANCE) return COLOR_ENTRANCES_SELECTED;
      else return COLOR_TOKUTEN_SELECTED;
    } else {
      if (typeTicket === TYPE_TICKETS.ENTRANCE) return COLOR_ENTRANCES;
      else return COLOR_TOKUTEN;
    }
  };

  const startEndDate = useMemo(() => {
    if (startDate && endDate) {
      if (dayjs(startDate).format(DATE_FORMAT_1) === dayjs(endDate).format(DATE_FORMAT_1)) {
        return dayjs(startDate).format(DATE_FORMAT_1);
      } else {
        return `${dayjs(startDate).format(DATE_FORMAT_1)} ~ ${dayjs(endDate).format(
          DATE_FORMAT_1,
        )}`;
      }
    }

    return '-';
  }, [startDate, endDate]);

  return (
    <div className="rounded-3 mt-2 position-relative" onClick={onClick} onKeyDown={onClick}>
      <div
        style={{
          backgroundColor: detectBackgroundColor(typeTicket, isUnSelected)[0],
          color: !isUnSelected ? COLOR_TEXT[0] : COLOR_TEXT[1],
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
          border: '1px solid black',
          borderBottom: 'none',
        }}
        className="px-3 pt-2 pb-1 text-break d-flex justify-content-between align-items-center"
      >
        <span className="flex-fill text-break fs-16 fw-700">{eventName}</span>
        {!isUnSelected ? <CheckMark /> : null}
      </div>
      <div
        className="d-flex py-1 px-2"
        style={{
          backgroundColor: detectBackgroundColor(typeTicket, isUnSelected)[1],
          border: '1px solid black',
          borderTop: 'none',
          borderBottom: 'none',
        }}
      >
        <div className="w-25 fs-13 fw-700 title-list-result-search">チケット種別</div>
        <div className="px-4 w-75 fs-16 d-flex align-items-center text-break">
          <img
            className="icon-event-ticket me-2"
            src={typeTicket === 0 ? ENTRANCE_ICON : TOKUTEN_ICON}
            alt="entrance-icon"
          />
          {NAME_TICKETS[typeTicket]}
        </div>
      </div>
      <div
        className="d-flex py-1 px-2"
        style={{
          backgroundColor: detectBackgroundColor(typeTicket, isUnSelected)[1],
          border: '1px solid black',
          borderTop: 'none',
          borderBottom: 'none',
        }}
      >
        <div className="w-25 fs-13 fw-700 title-list-result-search">開催日</div>
        <div className="px-4 w-75 fs-16 text-break">{startEndDate}</div>
      </div>
      <div
        className="d-flex py-1 px-2"
        style={{
          backgroundColor: detectBackgroundColor(typeTicket, isUnSelected)[1],
          border: '1px solid black',
          borderTop: 'none',
          borderBottom: 'none',
        }}
      >
        <div className="w-25 fs-13 fw-700 title-list-result-search">開催時間</div>
        <div className="px-4 w-75 fs-16 text-break">{time ? dayjs(time).format('HH:mm') : '-'}</div>
      </div>
      <div
        className="d-flex py-1 px-2"
        style={{
          backgroundColor: detectBackgroundColor(typeTicket, isUnSelected)[1],
          borderBottomLeftRadius: 6,
          borderBottomRightRadius: 6,
          border: '1px solid black',
          borderTop: 'none',
        }}
      >
        <div className="w-25 fs-13 fw-700 title-list-result-search">会場名</div>
        <div className="px-4 w-75 fs-16 text-break">{place}</div>
      </div>
    </div>
  );
};

export default memo(ItemsEvent);
