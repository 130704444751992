/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import ECFormGroupTextArea from 'Components/Page/EC/Form/FormGroupTextArea';
import { useForm, FormProvider } from 'react-hook-form';

import { union, isEmpty } from 'lodash';

import { yupResolver } from '@hookform/resolvers/yup';
import ECSendBulkEmailFormSchema from './Schemas/ECSendBulkEmailFormSchema';
import useMessage from 'Hooks/useMessage';
import { validateEmailsStringWithSemicolons, maxMsg } from 'Utils/Validation';
import Modal from 'Components/Common/Modal';

import { ECApis } from 'Datasource/EC';
import { toast } from 'react-toastify';

const ECSendBulkEmailForm = ({ data, onCloseModal, setLoading, callback }: any) => {
  const mailContentRef = useRef<any>(null);

  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false);

  const handleAddNickNameToMailContent = () => {
    const nickNameContent = '[[__USER_NICKNAME__]]';
    const cursorPosition = mailContentRef.current?.selectionStart;
    const textBeforeCursorPosition = mailContentRef.current?.value.substring(0, cursorPosition);
    const textAfterCursorPosition = mailContentRef.current?.value.substring(
      cursorPosition,
      mailContentRef.current?.value.length,
    );

    const textAfterAdd = `${textBeforeCursorPosition}${nickNameContent}${textAfterCursorPosition}`;

    setValue('body', textAfterAdd);

    setTimeout(() => {
      mailContentRef.current?.focus();
      mailContentRef.current.selectionStart = cursorPosition + nickNameContent.length;
    }, 100);
  };

  const handleAddFooterToMailContent = () => {
    const footerContent = '[[__FOOTER__]]';
    const cursorPosition = mailContentRef.current?.selectionStart;
    const textBeforeCursorPosition = mailContentRef.current?.value.substring(0, cursorPosition);
    const textAfterCursorPosition = mailContentRef.current?.value.substring(
      cursorPosition,
      mailContentRef.current?.value.length,
    );

    const textAfterAdd = `${textBeforeCursorPosition}${footerContent}${textAfterCursorPosition}`;

    setValue('body', textAfterAdd);

    setTimeout(() => {
      mailContentRef.current?.focus();
      mailContentRef.current.selectionStart = cursorPosition + footerContent.length;
    }, 100);
  };

  const { openMessageError } = useMessage();

  const methods = useForm<any>({
    defaultValues: {
      subject: null,
      body: null,
      operators: null,
    },
    resolver: yupResolver(ECSendBulkEmailFormSchema),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
  } = methods;

  const onSendBlukTestMail = async (values: any) => {
    try {
      // if (!validateEmailsStringWithSemicolons(values.operators)) {
      //   setError('operators', {
      //     message: '「；」セミコロンで区切られた有効なメールアドレスを入力してください',
      //   });
      //   return;
      // }
      setLoading(true);
      const params = {
        subject: values.subject,
        body: values.body,
        operators: values.operators?.split(';'),
        isTest: true,
      };
      await ECApis.sendBlukEmail(params);
      // if (callback) {
      //   callback();
      // }
      // onCloseModal();
      toast('テストメールを送信しました。', { type: 'success' });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const onSendBlukMailCofirmModal = async () => {
    clearErrors();
    const Errors = [];
    if (!watch('subject')) {
      setError('subject', { type: 'required', message: '入力してください。' });
      Errors.push('subject');
    } else {
      if (watch('subject') && watch('subject').length > 100) {
        setError('subject', { type: 'required', message: maxMsg(100) });
        Errors.push('subject');
      } else {
        clearErrors('subject');
      }
    }

    if (!watch('body')) {
      setError('body', { type: 'required', message: '入力してください。' });
      Errors.push('body');
    } else {
      if (watch('body') && watch('body').length > 2000) {
        setError('body', { type: 'required', message: maxMsg(2000) });
        Errors.push('body');
      } else {
        clearErrors('body');
      }
    }

    // if (watch('operators')) {
    //   if (!validateEmailsStringWithSemicolons(watch('operators'))) {
    //     setError('operators', {
    //       type: 'custom',
    //       message: '「；」セミコロンで区切られた有効なメールアドレスを入力してください',
    //     });
    //     Errors.push('operators');
    //   } else {
    //     if (watch('operators').length > 500) {
    //       setError('operators', { type: 'required', message: maxMsg(500) });
    //       Errors.push('operators');
    //     } else {
    //       clearErrors('operators');
    //     }
    //   }
    // }

    if (Errors.length > 0) {
      return;
    }

    setIsShowConfirmModal(true);
  };

  const onSendBlukMail = async () => {
    try {
      const values = getValues();
      const operatorsData = data?.map((d: any) => d.email);

      //const operatorCombine: Array<string> = union(values.operators?.split(';'), operatorsData);

      const operatorUnique: Array<string> = union(operatorsData);

      setLoading(true);
      const params = {
        subject: values.subject,
        body: values.body,
        operators: operatorUnique,
        isTest: false,
      };
      await ECApis.sendBlukEmail(params);
      if (callback) {
        callback();
      }
      setIsShowConfirmModal(false);
      onCloseModal();
      toast('メールを一括送信しました。', { type: 'success' });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form>
      <div className="d-flex w-30 justify-content-between">
        <span>送信数</span> <span>{data.length}件</span>
      </div>

      <div className="mt-2">
        <FormGroupInput
          value={watch('subject')}
          onChange={(val: any) => {
            setValue('subject', val);
          }}
          maxLength={100}
          labelMd="12"
          colMd="12"
          label="メールタイトル"
          classForm="row-dpadding"
          errorMessage={errors.subject?.message}
        />
      </div>

      <div className="mt-2">
        <ECFormGroupTextArea
          value={watch('body')}
          onChange={(val: any) => setValue('body', val)}
          labelMd="12"
          colMd="12"
          ref={mailContentRef}
          label={
            <div className="d-flex flex-column">
              メール本文
              <div className="d-flex mt-2 mb-2">
                <Button
                  className="btn btn-dark d-flex border-radius-right-unset ec-border-none ec-mr-1"
                  onClick={handleAddNickNameToMailContent}
                >
                  購入者ニックネーム
                </Button>
                <Button
                  className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
                  onClick={handleAddFooterToMailContent}
                >
                  フッター
                </Button>
              </div>
            </div>
          }
          classForm="row-dpadding"
          rows={10}
          maxLength={2000}
          errorMessage={errors.body?.message}
          //   register={{ ...register('bankAccountNumber') }}
        />
      </div>
      <div className="mt-2 position-relative">
        <FormGroupInput
          value={watch('operators')}
          onChange={(val) => setValue('operators', val)}
          labelMd="12"
          colMd="12"
          maxLength={500}
          label="運営者へ同じメールを送る"
          classForm="row-dpadding mb-0"
          hintText="「；」セミコロンで区切り複数設定することが可能です"
          errorMessage={errors.operators?.message}
          //   register={{ ...register('bankAccountNumber') }}
        />
        <Button className="ec_send_bluk_mail-test_btn" onClick={handleSubmit(onSendBlukTestMail)}>
          テスト送信
        </Button>
      </div>
      <div className="d-flex justify-content-center mt-5">
        <Button className="me-4 btn btn-secondary w-200" onClick={onCloseModal}>
          もどる
        </Button>
        <Button className="w-200" onClick={onSendBlukMailCofirmModal}>
          送信する
        </Button>
      </div>
      <Modal
        show={isShowConfirmModal}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCloseModal={() => {
          setIsShowConfirmModal(false);
        }}
        dialogClassName="w-400px"
        footerClassName="d-none"
        modalClassName="ec-zIndex-9999"
        title="一括メール送信確認"
        // title={
        //   isReview
        //     ? `${
        //         (songList && songList.find((item: Song) => item.index === currentSong)?.name) ??
        //         '【楽曲が登録されていません】'
        //       }の詳細`
        //     : `${
        //         variationName ?? '名称未設定バリエーション'
        //       }の${currentSong}曲⽬のデータを登録します`
        // }
      >
        <div className="d-flex w-30 justify-content-between">
          <span>送信数</span> <span>{data.length}件</span>
        </div>
        <div className="mt-3">メールを送信します。よろしいですか。</div>
        <div className="d-flex justify-content-center mt-3">
          <Button
            className="me-4 btn btn-secondary w-200"
            onClick={() => {
              setIsShowConfirmModal(false);
            }}
          >
            もどる
          </Button>
          <Button onClick={onSendBlukMail} className="w-200">
            送信する
          </Button>
        </div>
      </Modal>
    </Form>
  );
};

export default ECSendBulkEmailForm;
