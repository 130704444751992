import React, { useCallback, useEffect, useState } from 'react';
import { FormGroupSearchProps } from 'Components/Common/Form/form';
import FormGroupSearch from 'Components/Common/Form/FormGroupSearch';
import useMessage from 'Hooks/useMessage';
import { PER_PAGE } from 'Constant';
import { SerialApis } from 'Datasource/Serial';
import { updateQuery } from 'Utils/Search';
export interface Props extends Omit<FormGroupSearchProps, 'onInputChange' | 'onSearch'> {
  onInput?: (keyword: string) => void;
}

export default function FormSelectSerial(props: Props): JSX.Element {
  const [page, setPage] = useState<number>(1);
  const [serial, setSerial] = useState<{ options: any[]; totalPages: number }>({
    options: [],
    totalPages: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const { openMessageError } = useMessage();

  const { onInput } = props;

  const handleSearchSerial = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await SerialApis.getSerials({
        page: page,
        limit: PER_PAGE,
        query: encodeURIComponent(searchKey),
        type: `1`,
        useCampaign: 1,
      });
      const { items } = data;
      const options = items.map(
        (item: { id: number; name: string; quantity: number; description: string }) => ({
          label: item.name,
          value: item.id,
          total: item.quantity,
          description: item.description,
        }),
      );
      setSerial({
        options: page === 1 ? options : [...serial.options, ...options],
        totalPages: data?.meta?.totalPages,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadmore = () => {
    if (page < serial.totalPages) {
      let p = page;
      p += 1;
      setPage(p);
    }
  };

  useEffect(() => {
    handleSearchSerial();
  }, [searchKey, page]);

  const debouceRequest = useCallback(
    (value: string) =>
      updateQuery(value, () => {
        setSearchKey(value);
        setPage(1);
        onInput && onInput(value);
      }),
    [onInput],
  );

  return (
    <FormGroupSearch
      onLoadMore={() => handleLoadmore()}
      onMenuClose={() => {
        setSearchKey('');
        setPage(1);
      }}
      isLoading={loading}
      options={serial.options || []}
      onInputChange={(keyword: string) => debouceRequest(keyword)}
      onMenuFocus={() => {
        setSerial({ options: [], totalPages: 0 });
        handleSearchSerial();
      }}
      showIconClear
      {...props}
    />
  );
}
