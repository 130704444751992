import React from 'react';
import { OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';
import { useAppSelector } from 'App/Store';
import { calcMb } from 'Utils/File';

const CapacityField = ({ data }: { data: any }): JSX.Element => {
  const { overview } = useAppSelector((state) => state.campaign);
  const dpCapacity = {
    ...data,
    using: overview.dataSize,
  };
  const handleCalculatePercent = (value: number) => {
    return (value / dpCapacity?.totalCapacity) * 100;
  };

  const renderTooltip = (props: any, text: string, tId: string) => {
    return (
      <Tooltip id={tId} {...props}>
        {text}
      </Tooltip>
    );
  };

  return (
    <>
      {dpCapacity.totalCapacity === 0 ? (
        <ProgressBar
          className="custom-progress-bar px-0 text-dark danger border rounded-start"
          variant="white"
          now={100}
          key={1}
          label={`残り${dpCapacity.used}MB`}
          isChild={true}
        />
      ) : (
        <ProgressBar className="custom-progress-bar px-0 fs-14">
          <OverlayTrigger
            overlay={(props) =>
              renderTooltip(props, `他のキャンペーン${calcMb(dpCapacity.used)}MB`, 'progress-bar-1')
            }
          >
            <ProgressBar
              className="text-white primary border rounded-start"
              now={handleCalculatePercent(dpCapacity?.used)}
              key={1}
              label={`他のキャンペーン${calcMb(dpCapacity.used)}MB`}
              isChild={true}
            />
          </OverlayTrigger>
          <OverlayTrigger
            overlay={(props) =>
              renderTooltip(
                props,
                `編集中のキャンペーン${calcMb(dpCapacity.using)}MB`,
                'progress-bar-1',
              )
            }
          >
            <ProgressBar
              className="text-white danger border"
              variant="danger"
              now={handleCalculatePercent(dpCapacity?.using)}
              key={2}
              label={`編集中のキャンペーン${calcMb(dpCapacity.using)}MB`}
              isChild={true}
            />
          </OverlayTrigger>
          <OverlayTrigger
            overlay={(props) =>
              renderTooltip(props, `残り${calcMb(dpCapacity?.notUsedYet)}MB`, 'progress-bar-2')
            }
          >
            <ProgressBar
              className="text-dark border"
              variant="white"
              now={handleCalculatePercent(dpCapacity?.notUsedYet)}
              key={3}
              label={`残り${calcMb(dpCapacity?.notUsedYet)}MB`}
              isChild={true}
            />
          </OverlayTrigger>
        </ProgressBar>
      )}
      <div className="fs-12">データ容量上限: {calcMb(dpCapacity?.totalCapacity)}MB</div>
    </>
  );
};

export default CapacityField;
