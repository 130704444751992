const SerialTypeOptions = [
  {
    label: '物販CD封入用',
    subLabel:
      'CDに封入するためのシリアルコードを新規に発行します。\n１コード発行につき1.0円のオプション料金が発生します。',
    checked: true,
    value: 0,
  },
  {
    label: '外部キャンペーン用',
    subLabel: `外部システムにて発行されたシリアルコードを取り込みます。\nDP商品の特典および、キャンペーンの抽選で配布することができます。`,
    checked: false,
    value: 1,
  },
];

export { SerialTypeOptions };
