import React, { useState, memo, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import UsingChart from './UsingChart';
import { BoxTicket } from './UsingBox';
import ModalComponent from 'Components/Common/Modal';
import FormConFirmStopSale from './ConfirmStopSale';
import { pick } from 'lodash';
import ENTRANCE_ICON from 'Static/Images/entrance_icon.png';
import TOKUTEN_ICON from 'Static/Images/tokuten_icon.png';
import { DATE_FORMAT_20, TIME_FORMAT } from 'Constant/Date';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { UsingStatusApi } from 'Datasource/UsingStatus';
import useMessage from 'Hooks/useMessage';
import { isEmpty } from 'lodash';

import ConfirmModalStatus from './ConfirmModalStatus';

// import { isGreaterThan48h } from 'Utils/DateTime';

const colors = ['#550f25', '#EA2C68'];

const colorsTokuten = ['#574110', '#F1B52D'];

const RenderLayoutTicketStatus = memo(({ data }: { data: any }) => {
  return (
    <>
      {data?.type === 0 ? (
        <div className="d-flex py-1 border-bottom border-2">
          <div className="w-50 d-flex justify-content-center">
            <BoxTicket amount={data?.used?.toString() || 0} title={`使用済枚数`} />
          </div>
          <div className="w-50 d-flex justify-content-center">
            <BoxTicket amount={data?.notUsed?.toString() || 0} title={`未使用枚数`} />
          </div>
        </div>
      ) : (
        <div className="d-flex py-1 border-bottom border-2">
          <div className="w-50 d-flex justify-content-center">
            <BoxTicket amount={data?.totalTokutenTickets?.toString() || 0} title={`販売枚数`} />
          </div>
          <div className="w-50 d-flex justify-content-center">
            <BoxTicket amount={data?.extraTickets?.toString() || '-'} title={`増加枚数`} />
          </div>
        </div>
      )}
      {data?.type === 1 ? (
        <div className="d-flex py-1">
          <div className="w-50 d-flex justify-content-center">
            <BoxTicket amount={data?.used?.toString() || 0} title={`使用済枚数`} />
          </div>
          <div className="w-50 d-flex justify-content-center">
            <BoxTicket amount={data?.notUsed?.toString() || 0} title={`未使用枚数`} />
          </div>
        </div>
      ) : null}
      {data?.type === 0 ? (
        <div className="d-flex py-1 justify-content-center">
          <BoxTicket amount={data?.totalEntranceTickets || 0} title={`配布枚数`} />
        </div>
      ) : null}
    </>
  );
});

const UsingStatus = ({
  dataProps,
  allowAccess,
  // visibleSoldOut,
  onCheckSoldOut,
  loadingParent,
}: {
  dataProps: any;
  allowAccess: boolean;
  visibleSoldOut: boolean;
  loadingParent: boolean;
  onCheckSoldOut: (prizedId: number) => Promise<boolean>;
}): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const [visibleSodlOutConfirmModal, setVisibleSoulOutConfirmModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmModal48hVisible, setConfirmModal48hVisible] = useState<boolean>(false);
  // const [isShowBtn, setIsShowBtn] = useState<boolean>(true);
  const [data, setData] = useState(dataProps);
  const { openMessageError, openMessage } = useMessage();
  const [_allowAccess, setIsAllowAccess] = useState(true);

  // const endDateStartTime = useMemo(() => {
  //   if (data?.endDate) {
  //     if (data?.startTime) {
  //       return `${dayjs(data?.endDate).format(DATE_FORMAT_1)} ${dayjs(data?.startTime).format(
  //         TIME_FORMAT,
  //       )}`;
  //     } else {
  //       return `${dayjs(data?.endDate).format(DATE_FORMAT_1)} 00:00`;
  //     }
  //   }
  //   return '';
  // }, [data?.endDate, data?.startTime]);

  useEffect(() => {
    setData(dataProps);
    setIsAllowAccess(_allowAccess);
  }, [dataProps]);

  // if sold out, open popup alert
  // else open popup confirm

  // if sold out, open popup alert
  // else update status card

  // useEffect(() => {
  //   if (isGreaterThan48h(endDateStartTime)) {
  //     setIsShowBtn(false);
  //   } else {
  //     setIsShowBtn(true);
  //   }
  // }, [endDateStartTime]);

  const stopOrRestartSell = async (prizeId?: number | null) => {
    setLoading(true);
    try {
      await UsingStatusApi.stopOrStartSell(prizeId, data?.isActive);
    } catch (error) {
      if (data?.isActive === null)
        openMessage({
          variant: 'error',
          message: 'キャンペーンに紐づく商品はない、または販売開始になっていません',
        });
      else openMessageError(error);
    }
    setLoading(false);
  };

  const formatDataChart = useMemo(() => {
    return [
      { name: '未使用枚数', value: data?.notUsed || 0 },
      { name: '使用済枚数', value: data?.used || 0 },
    ];
  }, [data]);

  // const handleShowConfirmModal48h = () => {
  //   setConfirmModal48hVisible(true);
  // };

  const handleCloseConfirmModal48h = () => {
    setConfirmModal48hVisible(false);
    // setIsShowBtn(false);
  };
  const handleSubmitModal = async () => {
    await stopOrRestartSell(data?.id);
    await reload(data?.id, data?.type);
    setVisible(false);
  };

  const handleCheckSoldOut = async () => {
    try {
      const dataSoldOut = await onCheckSoldOut(data?.id);
      if (!dataSoldOut) {
        setVisibleSoulOutConfirmModal(true);
        setVisible(false);
      } else {
        setVisibleSoulOutConfirmModal(false);
      }
      return dataSoldOut;
    } catch (error) {
      openMessageError(error);
    }
  };

  const handleShowPopup = async () => {
    try {
      // if (isGreaterThan48h(endDateStartTime)) {
      //   //check 48h
      //   handleShowConfirmModal48h();
      //   return;
      // }
      if (!data?.existCard) {
        openMessage({
          variant: 'error',
          message: 'キャンペーンに紐づく商品はない、または販売開始になっていません',
        });
        return;
      }
      const isNotSoldOut = await handleCheckSoldOut();
      if (isNotSoldOut) {
        setVisible(true);
      }
    } catch (error) {
      openMessageError(error);
    }
  };

  // const handleReload = async () => {
  //   await reload(data?.id, data?.type);
  // };

  const reload = async (id?: number, typeData?: number) => {
    if (typeData === 0) {
      await fetchDataEntrance(id);
    } else {
      await fetchDataTokuten(id);
    }
  };

  const fetchDataEntrance = async (prizeId?: number | null) => {
    try {
      setLoading(true);
      const {
        data: { data, time },
      } = await UsingStatusApi.getDataUsingStatusTicketIn(prizeId);
      setData({ ...data, time });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openMessageError(error);
    }
  };

  const fetchDataTokuten = async (prizeId?: number | null) => {
    try {
      setLoading(true);
      const {
        data: { data, time },
      } = await UsingStatusApi.getDataUsingStatusTokuten(prizeId);
      setData({ ...data, time });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openMessageError(error);
    }
  };

  const startEndDate = useMemo(() => {
    if (data?.startDate && data?.endDate) {
      if (
        dayjs(data?.startDate).format(DATE_FORMAT_20) ===
        dayjs(data?.endDate).format(DATE_FORMAT_20)
      ) {
        return dayjs(data?.startDate).format(DATE_FORMAT_20);
      } else {
        return `${dayjs(data?.startDate).format(DATE_FORMAT_20)}~${dayjs(data?.endDate).format(
          DATE_FORMAT_20,
        )}`;
      }
    }

    return '-';
  }, [data?.startDate, data?.endDate]);

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <div>
        <div className="px-2 py-2 text-white bg-header text-break d-flex justify-content-center">
          <div className="d-flex align-items-center">
            <img
              className="icon-event-name me-2"
              src={data?.type === 0 ? ENTRANCE_ICON : TOKUTEN_ICON}
              alt="entrance-icon"
            />
            <span className="fs-18 fw-700">{data?.type === 0 ? data?.eventName : data?.name}</span>
          </div>
        </div>
        <div className="bg-header">
          <hr className="mx-2 my-0 bg-white h-header" />
        </div>
        <div className="px-2 py-2 text-white text-start text-break bg-header fs-16">
          {data?.artistName} &nbsp; {startEndDate} &nbsp;
          {data?.startTime ? dayjs(data?.startTime).format(TIME_FORMAT) : null}
          <br />
          {data?.placeName}
        </div>
        <div
          className="px-2 py-1 style-header-2"
          style={
            data?.type === 0
              ? { backgroundColor: 'rgb(255, 234, 240)' }
              : { backgroundColor: 'rgb(249, 242, 226)' }
          }
        >
          <div className="d-flex justify-content-between fs-13">
            <div>
              確認コード : <span className="fw-bolder fs-16">{data?.idString}</span>
            </div>
            <div className="text-opacity fs-13">
              {`${dayjs(data?.time).format('YYYY/MM/DD HH:mm:ss')} 更新`}
            </div>
          </div>
          <RenderLayoutTicketStatus data={data} />
          {/* <div className="d-flex justify-content-center">
            <ModalComponent
              show={flagCheckOpenPopup && _visibleSodlOut}
              onCloseModal={() => {
                setVisible(false);
                setFlagCheckOpenPopup(false);
              }}
              classNameContent="d-flex align-items-center content-body-modal justify-content-center"
              footerClassName="d-flex text-nowrap modal-appmobile"
              dialogClassName="p-usingstatus-mapp"
              variantButtonSubmit="danger"
              onSubmitModal={() => {
                setVisibleSoulOut(false);
              }}
              classBtnSubmit={!data?.isActive ? 'btn-submit-resale' : 'btn-submit-stopsale'}
              title={''}
              confirmText={data?.isActive ? `停止する` : `販売再開する`}
              isReviewModal={false}
            >
              <div>キャンペーンに紐づく商品は販売終了となりました</div>
            </ModalComponent>
          </div> */}
          {data.type === 1 ? (
            <div className="d-flex justify-content-center">
              <ModalComponent
                show={visible}
                onCloseModal={() => {
                  setVisible(false);
                }}
                classNameContent="d-flex align-items-center content-body-modal justify-content-center"
                footerClassName="d-flex text-nowrap modal-appmobile"
                dialogClassName="p-usingstatus-mapp"
                variantButtonSubmit="danger"
                onSubmitModal={async () => {
                  // if (isGreaterThan48h(endDateStartTime)) {
                  //   setVisible(false);
                  //   handleShowConfirmModal48h();
                  //   return;
                  // }
                  const isNotSoldOut = await handleCheckSoldOut();
                  if (isNotSoldOut) {
                    handleSubmitModal();
                  }
                }}
                classBtnSubmit={!data?.isActive ? 'btn-submit-resale' : 'btn-submit-stopsale'}
                title={
                  data?.isActive
                    ? `次の特典券の販売を停止します。よろしいですか？`
                    : `次の特典券の販売を再開します。よろしいですか?
`
                }
                confirmText={data?.isActive ? `停止する` : `販売再開する`}
                isReviewModal={false}
                classTitle="fw-700 text-center"
                disableModalTitleTruncate={true}
              >
                <div>
                  <FormConFirmStopSale
                    data={{
                      ...pick(data, [
                        'artistName',
                        'startDate',
                        'endDate',
                        'placeName',
                        'eventName',
                        'name',
                      ]),
                      note: data?.isActive
                        ? `この特典券のキャンペーンと関連する商品すべてが販売停止となります。`
                        : `この特典券のキャンペーンと関連する商品すべてが 販売再開 となります。`,
                    }}
                  />
                </div>
              </ModalComponent>
            </div>
          ) : null}
        </div>
      </div>
      <div>
        {!isEmpty(data) && !loading && !loadingParent ? (
          <UsingChart
            allowAccess={allowAccess}
            // visibleSoldOut={visibleSoldOut}
            data={formatDataChart}
            colors={data?.type === 0 ? colors : colorsTokuten}
            totalV={
              data?.type === 0 ? data?.totalEntranceTickets || 0 : data?.totalTokutenTickets || 0
            }
            title={data?.type === 0 ? '入場券 使用状況' : '特典券 使用状況'}
            dataOrigin={data}
            // isShowBtn={isShowBtn}
            onShowPopup={() => handleShowPopup()}
          />
        ) : (
          <div style={{ height: '450px' }}></div>
        )}
      </div>
      {/* <div onClick={handleReload} className="position-fixed btn-rotate">
        <img src={RELOAD_ICON} width="100%" height="auto" alt="reload-icon" />
      </div> */}
      <ConfirmModalStatus
        content="販売期間外のため、この操作はできません。"
        visible={confirmModal48hVisible}
        handleCloseModal={handleCloseConfirmModal48h}
      />
      <ConfirmModalStatus
        content="キャンペーンに紐づく商品は販売終了となりました"
        visible={visibleSodlOutConfirmModal}
        handleCloseModal={() => {
          setVisibleSoulOutConfirmModal(false);
        }}
      />
    </>
  );
};

export default memo(UsingStatus);
