import { useAppDispatch, useAppSelector } from 'App/Store';
import MessageError from 'Components/Common/MessageError';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { ECApis } from 'Datasource/EC';
import useMessage from 'Hooks/useMessage';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { setHashPassword } from 'App/Features/HashPassword';
import { useHistory } from 'react-router-dom';
import PurchaseSummaryList from './PurchaseSummaryList';
import ModalComponent from 'Components/Common/Modal';

export default function PurchaseSummary(): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [messageErr, setMessageErr] = useState('');
  const { openMessageError } = useMessage();
  const [inputPassword, setInputPassword] = useState('');
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    hashPassword: { hashPassword },
  } = useAppSelector((state) => state);
  const [showModal, setShowModal] = useState(!hashPassword ? true : false);

  const onSubmitModal = async () => {
    if (inputPassword === '') {
      setMessageErr('入力してください。');
      return;
    } else {
      setMessageErr('');
    }
    try {
      setIsLoading(true);
      const res = await ECApis.EcValidatePassword(inputPassword);
      const { data } = res.data;
      dispatch(setHashPassword(data.hashPassword));
      setShowModal(false);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseModal = () => {
    history.goBack();
  };

  return (
    <>
      <SpinnerComponent isLoading={isLoading} />
      <ModalComponent
        title="会計パスワードが必要です"
        show={showModal}
        onCloseModal={onCloseModal}
        classNameContent="d-flex flex-column align-items-center content-body-modal justify-content-center permission-modal-body"
        variantButtonSubmit="primary"
        onSubmitModal={onSubmitModal}
        confirmText="表示する"
      >
        <div>
          この画面を表示するには<span className="text-brown">「会計パスワード」</span>が必要です。
        </div>
        <div>パスワードを入力してください。 </div>
        <div>（ご注意：ログインパスワードではございません）</div>
        <Form.Control
          className="mt-3"
          type="password"
          value={inputPassword}
          onChange={(e: any) => setInputPassword(e.target.value)}
        />
        {messageErr ? (
          <div className="w-100">
            <MessageError message={messageErr}></MessageError>
          </div>
        ) : (
          ''
        )}
      </ModalComponent>
      {hashPassword ? <PurchaseSummaryList /> : ''}
    </>
  );
}
