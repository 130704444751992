import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import MessageError from 'Components/Common/MessageError';
import { labelRequire } from 'Components/Common/Form/FormLabel';
import HintTextField from 'Components/Common/Form/HintTextField';
import BRDatePicker, {
  BRDatePickerTypes,
  BRDate,
} from 'Components/Common/NewDatePicker/DatePicker';
import { ColSpec } from 'Components/Common/Form/form';
import { InfoCircleFill } from 'react-bootstrap-icons';

interface FormGroupDateProps extends Omit<BRDatePickerTypes, 'onChangeDate'> {
  label?: string;
  subLabel?: string;
  classForm?: string;
  colClass?: string;
  labelMd: ColSpec;
  colMd: ColSpec;
  onChange: (date: BRDate) => void;
  selected: BRDate;
  colDP?: ColSpec;
  subContent?: string;
  classCol?: string;
  errorMessage?: string;
  hintText?: string;
  showTime?: boolean;
  relationDate?: BRDate;
  downloadTime?: BRDate | string | undefined;
}

const BRFormGroupDate = ({
  label,
  labelMd,
  colMd,
  classForm,
  onChange,
  classCol,
  errorMessage,
  selected,
  minDate,
  maxDate,
  disabled = false,
  showTimeSelectOnly = false,
  placeholder,
  dateFormat,
  colDP = 12,
  subContent,
  hintText = '',
  showTime = true,
  relationDate,
  downloadTime,
}: FormGroupDateProps): JSX.Element => {
  return (
    <Form.Group as={Row} className={`mb-3 ${classForm || ''}`}>
      <Form.Label column md={labelMd}>
        {label && labelRequire(label)}
      </Form.Label>
      <Col md={colMd} className={classCol}>
        <Row>
          <Col md={colDP} className="px-0">
            <BRDatePicker
              dateFormat={dateFormat}
              timeFormat="HH:mm"
              placeholder={placeholder}
              selected={selected}
              onChangeDate={onChange}
              minDate={minDate ? minDate : new Date()}
              maxDate={maxDate}
              disabled={disabled}
              showTimeSelectOnly={showTimeSelectOnly}
              showTimeSelect={showTime}
              relationDate={relationDate}
            />
            {subContent && <p className="fs-12 mt-1">{subContent}</p>}
          </Col>
          <HintTextField hintText={hintText} />
          {downloadTime && (
            <div className="d-flex align-items-center ec_form_date_range_tooltip-sale_product p-0">
              <InfoCircleFill style={{ marginRight: '3px' }} color="#005cd9" />
              ダウンロード期間: {downloadTime}
            </div>
          )}
          <MessageError classWrapper="ms-2 px-0" message={errorMessage} />
        </Row>
      </Col>
    </Form.Group>
  );
};

export default BRFormGroupDate;
