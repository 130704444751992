export const checkIfFilesGeneralAreCorrectType = (type: string, files: FileList): boolean => {
  let valid = true;
  const typeFile = files[0].type;
  const sizeFile = files[0].size / 1024 / 1024;
  switch (type) {
    case 'image':
      if (!['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(typeFile)) {
        valid = false;
      } else if (sizeFile > 2) {
        valid = false;
      }
      return valid;
    case 'audio':
      if (!['audio/mpeg3', 'audio/x-mpeg-3', 'audio/mpeg'].includes(typeFile)) {
        valid = false;
      } else if (sizeFile > 20 || sizeFile < 1.5) {
        valid = false;
      }
      return valid;
    case 'video':
      if (!['video/mp4'].includes(typeFile)) {
        valid = false;
      } else if (sizeFile > 200) {
        valid = false;
      }
      return valid;
    default:
      return valid;
  }
};
