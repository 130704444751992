import { filter, flatMap, isEmpty } from 'lodash';
import React from 'react';
import { Table } from 'react-bootstrap';

export interface ColumnsTypes {
  className?: string;
  width?: number;
  text: string;
  dataIndex: string;
  rowSpan?: number;
  colSpan?: number;
  render?: (data: any) => void;
}

export interface TableColumns {
  columns: ColumnsTypes[];
}

interface TableProps {
  dataSource: any[];
  tableColumns: TableColumns[];
  children?: React.ReactNode;
  tableClass?: string;
  noContentText?: string | null;
}

export const TableGroup = ({
  dataSource,
  tableColumns,
  children,
  tableClass,
  noContentText = '検索結果がありません。',
}: TableProps): JSX.Element => {
  const headerFields = flatMap(tableColumns, ({ columns }) =>
    filter(columns, (column) => !!column.dataIndex),
  );

  return (
    <div>
      {!isEmpty(dataSource) ? (
        <Table className={`table-group ${tableClass}`} bordered responsive>
          <thead>
            {tableColumns.map((row, rIndex) => {
              return (
                <tr key={rIndex}>
                  {row.columns.map((column, cIndex) => {
                    return (
                      <th
                        key={cIndex}
                        colSpan={column.colSpan}
                        rowSpan={column.rowSpan}
                        className={`text-white bg-dark text-center align-middle ${column.className}`}
                        style={{ width: column.width, whiteSpace: 'pre' }}
                      >
                        {column.text}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {dataSource.map((row, rIndex) => {
              return (
                <tr key={rIndex}>
                  {headerFields.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className="text-center bg-white text-dark"
                      style={{ height: '38px' }}
                    >
                      {cell.render
                        ? cell.render({ item: row, dataIndex: cell.dataIndex, index: rIndex })
                        : (row as any)[cell.dataIndex]}
                    </td>
                  ))}
                </tr>
              );
            })}
            {children}
          </tbody>
        </Table>
      ) : (
        <>
          {noContentText && (
            <div className="vh-100-final d-flex align-items-center justify-content-center">
              検索結果がありません。
            </div>
          )}
        </>
      )}
    </div>
  );
};
