import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
interface IFormInputs {
  firstName: string;
  age: number;
}

const schema = yup.object().shape({
  firstName: yup.string().required(),
  age: yup.number().positive().integer().required(),
});

export default function RegisterSection(): JSX.Element {
  const [input, setInput] = useState('');

  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data: IFormInputs) => console.log(data);

  return (
    <Container fluid>
      <Row>
        <Col xl="6" className="m-auto mt-5 mb-5">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroupInput
              value={input}
              onChange={setInput}
              labelMd="4"
              colMd="8"
              label="セクション①"
              errorMessage={errors.lastName?.message}
            />

            <FormGroupInput
              value={input}
              onChange={setInput}
              labelMd="4"
              colMd="8"
              label="セクション②"
              errorMessage={errors.lastName?.message}
            />

            <FormGroupInput
              value={input}
              onChange={setInput}
              labelMd="4"
              colMd="8"
              label="セクション③"
              errorMessage={errors.lastName?.message}
            />

            <FormGroupInput
              value={input}
              onChange={setInput}
              labelMd="4"
              colMd="8"
              label="セクション④"
              errorMessage={errors.lastName?.message}
            />

            <FormGroupInput
              value={input}
              onChange={setInput}
              labelMd="4"
              colMd="8"
              label="セクション⑤"
              errorMessage={errors.lastName?.message}
            />

            <FormGroupInput
              value={input}
              onChange={setInput}
              labelMd="4"
              colMd="8"
              label="セクション⑥"
              errorMessage={errors.lastName?.message}
            />

            <FormGroupInput
              value={input}
              onChange={setInput}
              labelMd="4"
              colMd="8"
              label="セクション⑦"
              errorMessage={errors.lastName?.message}
            />

            <FormGroupInput
              value={input}
              onChange={setInput}
              labelMd="4"
              colMd="8"
              label="セクション⑧"
              errorMessage={errors.lastName?.message}
            />

            <FormGroupInput
              value={input}
              onChange={setInput}
              labelMd="4"
              colMd="8"
              label="セクション⑨"
              errorMessage={errors.lastName?.message}
            />

            <FormGroupInput
              value={input}
              onChange={setInput}
              labelMd="4"
              colMd="8"
              label="セクション⑩"
              errorMessage={errors.lastName?.message}
            />

            <Form.Group as={Row} className="mb-2 mt-5">
              <Col xl="9" className="mt-3 text-center ms-auto">
                <Button className="w-100 rounded-0" variant="dark" type="submit">
                  プレビュー
                </Button>
              </Col>
              <Col xl="9" className="mt-3 text-center ms-auto">
                <Button className="w-100 rounded-0" variant="danger" type="submit">
                  保存
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
