/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import * as yup from 'yup';
import _, { find, isEmpty, toNumber } from 'lodash';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'App/Store';
import { IDatePicker, ICheckBox } from 'Types/Common';
import CardContainer from 'Components/Common/CardContainer';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import FormGroupDate from 'Components/Common/Form/FormGroupDate';
import { yupResolver } from '@hookform/resolvers/yup';
import { JmdDataGenre, setCaculateFee, setVariations } from 'App/Features/Title';
import GroupCheckBox from 'Components/Common/Form/GroupCheckBox';
import MessageError from 'Components/Common/MessageError';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { DATE_FORMAT_FOR_DATE_PICKER, TIME_FORMAT } from 'Constant/Date';
import ModalConfirm from 'Components/Page/Title/Variation/ModalConfirm';

import {
  convertPriceApple,
  extractIdsAndNamesGenreFromJSON,
  handleConvertTitleInfo,
  IGenreData,
  truncateText,
} from 'Utils/Title';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import {
  arrayRequiredValidation,
  englishAndDashValidation,
  numberRequired,
  stringRequired,
} from 'Utils/Validation';
import BoxDropZone from 'Components/Common/Dropzone/BoxDropZone';
import Booklet from 'Components/Common/Dropzone/Booklet';
import BoxSong from 'Components/Page/Title/Variation/BoxSong';
import BoxVideo from 'Components/Page/Title/Variation/BoxVideo';
import BoxImage from 'Components/Page/Title/Variation/BoxImage';
import CardCapacity from 'Components/Common/CardCapacity';
import { isAfterDownload, isReviewTitle } from 'Utils/File';
import { formatPrice } from 'Utils/Numbers';
import useMessage from 'Hooks/useMessage';
import { TitleApi } from 'Datasource/TitleApi';
import { replaceTimeOfDate } from 'Utils/DateTime';
import { MAX_INPUT } from 'Constant';
import { Song, Variation, Video } from 'Types/App/title';
import { MusicFormat } from 'Constant/Song';
import { INCLUDE_COPY_RIGHT, INCLUDE_JARSAC_CONTENT, LINK_JMD_TYPE_CONTENT } from 'Constant/Title';
import ModalComponent from 'Components/Common/Modal';
import FormGroupSelect from 'Components/Common/Form/FormGroupSelect';

export const schemaVariation = yup.object().shape({
  name: stringRequired({}),
  type: numberRequired({}).nullable(),
  includeJasracContent: numberRequired({}).nullable(),
  jmdCooperation: numberRequired({}).nullable(),
  musicFormat: numberRequired({}).nullable(),
  saleTokenDate: stringRequired({}).nullable(),
  notiDownloadTime: stringRequired({}).nullable(),
  downloadDate: stringRequired({}).nullable(),
  coverImageUrl: stringRequired({}).nullable(),
  productCode: englishAndDashValidation({}).nullable(),
  janCode: stringRequired({}).nullable(),
  // booklets: arrayRequiredValidation({}).min(1, '入力してください。'),
  price: yup
    .string()
    .required('入力してください。')
    .test({
      name: 'price',
      message: '100,000円以下の値を入力してください。',
      test: (value, context: any) => {
        const { createError, from, path } = context;
        const { option } = from[0].value;
        const price = value ? +value : 0;
        const { minPrice } = option;
        if (price < +minPrice) {
          return createError({
            message: `${+minPrice}円以上を入力してください。`,
            path,
          });
        } else {
          return price < 100000;
        }
      },
    })
    .nullable(),
  videos: yup
    .array()
    .when(['songs'], {
      is: (songs: any) => songs.length == 0,
      then: arrayRequiredValidation({}).min(
        1,
        '楽曲コンテンツ、または動画コンテンツを入力してください。',
      ),
    })
    .of(
      yup.object().shape({
        name: stringRequired({}),
        introEc: stringRequired({}),
        includeCopyRightSong: numberRequired({}).nullable(),
        videoType: yup
          .number()
          .when('includeCopyRightSong', {
            is: INCLUDE_COPY_RIGHT.INCLUDE,
            then: numberRequired({}).nullable(),
          })
          .nullable(),
        songs: yup.array().when(['includeCopyRightSong'], {
          is: (includeCopyRightSong: number) => includeCopyRightSong === INCLUDE_COPY_RIGHT.INCLUDE,
          then: arrayRequiredValidation({}).min(1, '著作権管理楽曲を登録してください'),
        }),
      }),
    ),
  images: yup.array().of(
    yup.object().shape({
      name: stringRequired({}),
      introEc: stringRequired({}),
    }),
  ),
  riajGenreId: yup
    .number()
    .when(['videos', 'songs', 'jmdCooperation'], {
      is: (videos: any[], songs: any[], jmdCooperation: number) =>
        ((videos && videos.length > 0) || (songs && songs.length > 0)) &&
        jmdCooperation === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER,
      then: numberRequired({}).nullable(),
    })
    .nullable(),
  jmdMusicGenreId: yup
    .number()
    .when(['songs', 'jmdCooperation'], {
      is: (songs: any[], jmdCooperation: number) =>
        songs && songs.length > 0 && jmdCooperation === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER,
      then: numberRequired({}).nullable(),
    })
    .nullable(),
  jmdVideoGenreId: yup
    .number()
    .when(['videos', 'jmdCooperation'], {
      is: (videos: any[], jmdCooperation: number) =>
        videos && videos.length > 0 && jmdCooperation === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER,
      then: numberRequired({}).nullable(),
    })
    .nullable(),
});

interface IDataError {
  index: number;
  message: string;
  name: any;
}
interface VariationProps {
  variation: any;
  variationIndex: number;
  dataError: Array<IDataError>;
  listError?: any;
  nameRef: any;
  jmdCooperationRef?: any;
  musicFormatRef: any;
  saleTokenDateRef: any;
  downloadDateRef: any;
  notiDownloadTimeRef: any;
  productCodeRef: any;
  janCodeRef: any;
  priceRef: any;
  coverImageUrlRef: any;
  bookletsRef: any;
  songsRef: any;
  videosRef: any;
  imagesRef: any;
  includeJasracContentRef: any;
  riajGenreIdRef: any;
  jmdMusicGenreRef: any;
}

export default function VariationC({
  variation,
  variationIndex,
  dataError,
  listError,
  nameRef,
  musicFormatRef,
  jmdCooperationRef,
  saleTokenDateRef,
  downloadDateRef,
  notiDownloadTimeRef,
  productCodeRef,
  janCodeRef,
  priceRef,
  coverImageUrlRef,
  bookletsRef,
  songsRef,
  videosRef,
  imagesRef,
  includeJasracContentRef,
  riajGenreIdRef,
  jmdMusicGenreRef,
}: VariationProps): JSX.Element {
  const { id }: { id: string } = useParams();
  const dispatch = useAppDispatch();
  const {
    titleForm: { variations, status, artistName, name },
    titleForm,
    currentStep,
  } = useAppSelector((state) => state.title);

  const { openMessageError } = useMessage();

  const { isValidate, isValidateSaveDraft } = useAppSelector((state) => state.title);
  const [dataErr, setDataErr] = useState(dataError);
  const [variationCurrent, setVariationCurrent] = useState<any>([]);
  const [listJmdMusicGenreData, setListJmdMusicGenreData] = useState<JmdDataGenre[]>([]);
  const [JPRecordingAssociationGenreOptions, setJPRecordingAssociationGenreOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [currentJmdMusicGenreIdValue, setCurrentJmdMusicGenreIdValue] = useState<number | null>(
    null,
  );
  const [currentJmdMusicGenreDetail, setCurrentJmdMusicGenreDetail] = useState<IGenreData | null>(
    null,
  );

  const initValueOption = {
    label: '',
    value: '',
  };

  const [linkJMDAudioValue, setLinkJMDAudioValue] = useState<{
    [key: string]: { [key: string]: string | number };
  }>({
    firstValue: initValueOption,
    secondValue: initValueOption,
    thirdValue: initValueOption,
    lastValue: initValueOption,
  });

  const {
    reset,
    register,
    getValues,
    trigger,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: variation.type,
      jmdCooperation: variation.jmdCooperation ?? LINK_JMD_TYPE_CONTENT.WORK_TOGETHER,
      musicFormat: variation.musicFormat,
      name: variation.name,
      saleTokenDate: variation.saleTokenDate,
      notiDownloadTime: variation.notiDownloadTime,
      downloadDate: variation.downloadDate,
      coverImageUrl: variation.coverImageUrl,
      productCode: variation.productCode,
      janCode: variation.janCode,
      price: variation.price,
      songs: variation.songs,
      isDisplayName: variation.isDisplayName,
      videos: variation.videos,
      images: variation.images,
      booklets: variation.booklets,
      option: variation.option,
      includeJasracContent: variation?.includeJasracContent,
      riajGenreId: variation?.riajGenreId,
      jmdMusicGenreId: variation?.jmdMusicGenreId,
      jmdVideoGenreId: variation?.jmdVideoGenreId,
    },
    resolver: yupResolver(schemaVariation),
  });

  useEffect(() => {
    reset({
      type: variation.type,
      jmdCooperation: variation.jmdCooperation ?? LINK_JMD_TYPE_CONTENT.WORK_TOGETHER,
      musicFormat: variation.musicFormat,
      name: variation.name,
      saleTokenDate: variation.saleTokenDate,
      notiDownloadTime: variation.notiDownloadTime,
      downloadDate: variation.downloadDate,
      coverImageUrl: variation.coverImageUrl,
      productCode: variation.productCode,
      janCode: variation.janCode,
      price: variation.price,
      songs: variation.songs,
      isDisplayName: variation.isDisplayName,
      videos: variation.videos,
      images: variation.images,
      booklets: variation.booklets,
      option: variation.option,
      includeJasracContent: variation?.includeJasracContent,
      riajGenreId: variation?.riajGenreId,
      jmdMusicGenreId: variation?.jmdMusicGenreId,
      jmdVideoGenreId: variation?.jmdVideoGenreId,
    });
    if (!currentJmdMusicGenreIdValue) {
      setCurrentJmdMusicGenreIdValue(variation?.jmdMusicGenreId);
    }
  }, [variation]);

  const setValueIntoForm = (data: { [key: string]: any }) => {
    let dataSource = [...variations];
    dataSource = dataSource.map((variation, key) =>
      key === variationIndex ? { ...variation, ...data } : { ...variation },
    );
    dispatch(setVariations({ variations: dataSource }));
  };

  const detailMusicJmd = async (id: number) => {
    const {
      data: { data },
    } = await TitleApi.getJmdMusicGenreById(id);
    setCurrentJmdMusicGenreDetail(data);
  };

  useEffect(() => {
    if (variation.jmdMusicGenreId && !currentJmdMusicGenreDetail) {
      detailMusicJmd(variation.jmdMusicGenreId);
    }
  }, [variation]);

  useEffect(() => {
    if (
      currentJmdMusicGenreDetail &&
      currentJmdMusicGenreDetail.id &&
      !linkJMDAudioValue.firstValue.value
    ) {
      const result = currentJmdMusicGenreDetail;
      setLinkJMDAudioValue({
        firstValue: {
          label: currentJmdMusicGenreDetail.genre1,
          value: currentJmdMusicGenreDetail.genre1,
        },
        ...(currentJmdMusicGenreDetail?.genre2 && {
          secondValue: {
            label: currentJmdMusicGenreDetail?.genre2,
            value: currentJmdMusicGenreDetail?.genre3
              ? currentJmdMusicGenreDetail?.genre2
              : currentJmdMusicGenreDetail.id,
          },
        }),
        ...(currentJmdMusicGenreDetail?.genre3 && {
          thirdValue: {
            label: currentJmdMusicGenreDetail?.genre3,
            value: currentJmdMusicGenreDetail?.genre4
              ? currentJmdMusicGenreDetail?.genre3
              : currentJmdMusicGenreDetail.id,
          },
        }),
        ...(currentJmdMusicGenreDetail?.genre4 && {
          lastValue: {
            label: currentJmdMusicGenreDetail?.genre4,
            value: currentJmdMusicGenreDetail.id,
          },
        }),
      });
    }
  }, [currentJmdMusicGenreDetail]);

  const jmdCooperationValue = useMemo(() => {
    return watch('jmdCooperation');
  }, [watch('jmdCooperation')]);

  const riajGenreIdValue = useMemo(() => {
    return watch('riajGenreId');
  }, [watch('riajGenreId')]);

  const getMinTimeStart = () => {
    let minTime = dayjs().endOf('date').toDate();
    if (variation?.downloadDate) {
      minTime = dayjs(variation.downloadDate).toDate();
    }
    return minTime;
  };

  const getMaxTime = () => {
    return dayjs().endOf('date').toDate();
  };

  const [musicFormat, setFormats] = useState<ICheckBox[]>([
    { label: 'MP3 192kbps', value: 0, checked: getValues().musicFormat === 0 },
    { label: 'WAV 24bit 96kHz  (ハイレゾ)', value: 1, checked: getValues().musicFormat === 1 },
  ]);

  const [jmdCooperationOptions, setLinkJmdTypeOptions] = useState<ICheckBox[]>([
    {
      label: '連携する',
      value: LINK_JMD_TYPE_CONTENT.WORK_TOGETHER,
      checked: getValues().jmdCooperation === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER,
    },
    {
      label: '連携しない',
      value: LINK_JMD_TYPE_CONTENT.NOT_WORK_TOGETHER,
      checked: getValues().jmdCooperation === LINK_JMD_TYPE_CONTENT.NOT_WORK_TOGETHER,
    },
  ]);

  const [visableModalDelete, setVisibleModalDelete] = useState<boolean>(false);

  const [includeJasracContents, setIncludeJasracContents] = useState<ICheckBox[]>([
    {
      label: 'バリエーション内の楽曲または動画に著作権管理コンテンツを含む',
      value: INCLUDE_JARSAC_CONTENT.INCLUDE,
      checked: getValues().includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE,
    },
    {
      label: '含まない',
      value: INCLUDE_JARSAC_CONTENT.NOT_INCLUDE,
      checked: getValues().includeJasracContent === INCLUDE_JARSAC_CONTENT.NOT_INCLUDE,
    },
  ]);

  const [isToggle, setIsToggle] = useState(
    !variation.id || (variation?.id && variation?.id?.toString()?.includes('uuid')) ? true : false,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [modalType, setModalType] = useState('');

  const handleChangeCheckBox = (options: any) => {
    setFormats(options);
    const { value } = find(options, (option) => option.checked) ?? options[0];
    setValue('musicFormat', value);
    setValueIntoForm({ musicFormat: value });
  };

  // Function for Miim-177
  const handleChangeJmdCooperationCheckBox = (options: any) => {
    setLinkJmdTypeOptions(options);
    const { value } = find(options, (option) => option.checked) ?? options[0];
    setValue('jmdCooperation', value);
    setValueIntoForm({ ...variation, jmdCooperation: value });
  };

  const handleCheckIncludeJarsac = (options: any) => {
    setIncludeJasracContents(options);
    const { value } = find(options, (option) => option.checked) ?? options[0];
    const songVideos = variation?.videos?.flatMap((video: Video) => video.songs);
    const songList = [...variation?.songs, ...songVideos];

    const InValidSwitch = songList.some(
      (song: Song) => song?.authorizedGroupType && [1, 2].includes(song?.authorizedGroupType),
    );

    if (+value === INCLUDE_JARSAC_CONTENT.NOT_INCLUDE && InValidSwitch) {
      setVisibleModalDelete(true);
      return;
    }
    setValue('includeJasracContent', +value);
    let dataSource = [...variations];
    dataSource = dataSource.map((variation, key) => {
      const newSongs: any[] =
        variation?.songs?.map((song: Song) => ({
          ...song,
          authorizedGroupType:
            getValues('includeJasracContent') === INCLUDE_JARSAC_CONTENT.NOT_INCLUDE &&
            song?.authorizedGroupType &&
            (+song?.authorizedGroupType === 1 || +song.authorizedGroupType === 2)
              ? null
              : song?.authorizedGroupType,
          informationFee: '',
          lyricInformationFee: '',
        })) || [];

      const videoSongs: any[] =
        variation?.videos?.map((video: Video) => {
          const songVideo: any[] =
            video?.songs?.map((song: Song) => ({
              ...song,
              authorizedGroupType:
                getValues('includeJasracContent') === INCLUDE_JARSAC_CONTENT.NOT_INCLUDE &&
                song?.authorizedGroupType &&
                (+song?.authorizedGroupType === 1 || +song.authorizedGroupType === 2)
                  ? null
                  : song?.authorizedGroupType,
              informationFee: '',
            })) || [];
          return { ...video, songs: songVideo, mediaInformationFee: 0 };
        }) || [];
      return key === variationIndex
        ? { ...variation, includeJasracContent: +value, songs: newSongs, videos: videoSongs }
        : { ...variation };
    });
    dispatch(setVariations({ variations: dataSource }));
    dispatch(setCaculateFee(true));
  };

  const onChangeDate = (date: any, type: any) => {
    let dataSource = [...variations];
    if (date) {
      const currentDate = dayjs();
      const selectedDate = dayjs(date);
      const isLessCurrentDate = selectedDate.diff(currentDate);
      date = isLessCurrentDate < 0 ? dayjs() : date;

      date = new Date(date.toString()).toISOString();
    } else {
      date = '';
    }

    if (type === 'saleTokenDate') {
      if (date >= variation.downloadDate) {
        dataSource = dataSource.map((variation, key) =>
          key === variationIndex
            ? {
                ...variation,
                [type]: date,
                downloadDate: null,
                notiDownloadTime: null,
                isEdit: true,
              }
            : { ...variation },
        );
        setValue(type, date);
        setValue('downloadDate', null);
        setValue('notiDownloadTime', null);
        dispatch(setVariations({ variations: dataSource }));
        return;
      }
    }

    if (type === 'downloadDate') {
      if (date < variation.saleTokenDate) {
        date = variation.saleTokenDate;
      }
      dataSource = dataSource.map((variation, key) =>
        key === variationIndex
          ? { ...variation, [type]: date, notiDownloadTime: null, isEdit: true }
          : { ...variation },
      );
      setValue(type, date);
      setValue('notiDownloadTime', null);
      dispatch(setVariations({ variations: dataSource }));
      return;
    }

    if (type === 'notiDownloadTime' && date !== null) {
      const dateNotification = new Date(date);
      const dateDownload = new Date(variation.downloadDate);

      if (dateNotification < dateDownload) {
        dataSource = dataSource.map((variation, key) =>
          key === variationIndex
            ? { ...variation, notiDownloadTime: null, isEdit: true }
            : { ...variation },
        );
        setValue('notiDownloadTime', null);
        dispatch(setVariations({ variations: dataSource }));
        return;
      }
    }

    dataSource = dataSource.map((variation, key) =>
      key === variationIndex ? { ...variation, [type]: date, isEdit: true } : { ...variation },
    );
    setValue(type, date);
    dispatch(setVariations({ variations: dataSource }));
  };

  const handleOnchangeInput = (
    value: string | number | undefined | boolean,
    type: string | any,
    isText = false,
  ) => {
    value = isText ? value : Number(value);
    let dataSource = [...variations];
    dataSource = dataSource.map((variation, key) =>
      key === variationIndex ? { ...variation, [type]: value } : { ...variation },
    );
    setValue(type, value);
    dispatch(setVariations({ variations: dataSource }));
  };

  const handlerConvertTime = (date: any) => {
    if (date?.length) {
      date = dayjs(date)?.toDate();
    } else {
      date = null;
    }
    return date;
  };

  const handleDeleteVariation = () => {
    let dataSource = [...variations].filter((item: Variation) => item.id !== variation.id);

    dataSource = dataSource.map((el: any, key: number) => {
      return {
        ...el,
        index: key,
      };
    });

    dispatch(setVariations({ variations: dataSource }));
  };

  const validateSongs = () => {
    _.forEach(variation.songs, (song, songIndex) => {
      if (!song.name || !song.sourceAudioUrl) {
        return setError(`songs.${+songIndex}`, {
          type: 'manual',
          message: '入力してください。',
        });
      }
    });
  };

  const validateSongType = () => {
    _.forEach(variation.songs, (song, songIndex) => {
      if (!song.sourceAudioUrl) {
        if (isValidateSaveDraft && !isValidate) clearErrors(`songs.${+songIndex}`);
      } else {
        if (variation.musicFormat === MusicFormat.MP3 && song?.sourceAudioUrl?.search('.mp3') < 0) {
          return setError(`songs.${+songIndex}`, {
            type: 'manual',
            message: '⾳源ファイルは.mp3の⾳源を登録してください。',
          });
        }

        if (variation.musicFormat === MusicFormat.WAV && song?.sourceAudioUrl?.search('.wav') < 0) {
          return setError(`songs.${+songIndex}`, {
            type: 'manual',
            message: '⾳源ファイルは.wavの⾳源を登録してください。',
          });
        }

        clearErrors(`songs.${+songIndex}`);
      }
    });
  };

  const handleValidateCode = async () => {
    if (!isEmpty(errors.songs)) return;
    try {
      setLoading(true);
      const paramValidateCodeTitle = titleForm.variations.map((va: Variation, index: number) => {
        return { productCode: va.productCode, janCode: va.janCode, index, id: va?.id };
      });

      //! FIXME: HAS CODE -> check duplicate producCode, janCode in status === 0
      const lookupProductCode = paramValidateCodeTitle.reduce((a: any, e: any) => {
        a[e.productCode] = ++a[e.productCode] || 0;
        return a;
      }, {});
      const lookupJanCode = paramValidateCodeTitle.reduce((a: any, e: any) => {
        a[e.janCode] = ++a[e.janCode] || 0;
        return a;
      }, {});

      const errProductCode = paramValidateCodeTitle.filter(
        (e: any) => lookupProductCode[e.productCode],
      );
      const errJanCode = paramValidateCodeTitle.filter((e: any) => lookupJanCode[e.janCode]);

      if (errProductCode.length > 0 || errJanCode.length > 0) {
        errProductCode.map((el: any) => {
          if (el.index === variation.index) {
            setError(`productCode`, {
              type: 'manual',
              message: '品番は既に存在しています。',
            });
          }
        });
        errJanCode.map((el: any) => {
          if (el.index === variation.index) {
            setError(`janCode`, {
              type: 'manual',
              message: 'JANコードは既に存在しています。',
            });
          }
        });
        return false;
      }
      //////////////////////////

      const paramValidateCode = [
        {
          productCode: variation.productCode,
          janCode: variation.janCode,
          index: 0,
          id: variation?.id && variation?.id?.toString()?.includes('uuid') ? null : variation.id,
        },
      ];
      const {
        data: { data },
      } = await TitleApi.validateCode({ variationValidationDtos: paramValidateCode, titleId: +id });
      if (data?.length > 0) {
        data.map((el: any) => {
          if (Object.keys(el)[0] === 'janCode') {
            setError(`janCode`, {
              type: 'manual',
              message: 'JANコードは既に存在しています。',
            });
          } else {
            setError(`productCode`, {
              type: 'manual',
              message: '品番は既に存在しています。',
            });
          }
        });
      }
    } catch (err) {
      openMessageError(err);
    } finally {
      setLoading(false);
    }
  };

  const validatePrice = () => {
    const firstPrice = variations[0]?.price ? +variations[0]?.price : 0;
    const variationPrice = getValues('price') ? +getValues('price') : 0;

    if (getValues('price')) {
      if (variationPrice < firstPrice) {
        setError(`price`, {
          type: 'manual',
          message: `${truncateText(titleForm?.artistName || '', 32)} / ${truncateText(
            titleForm?.name || '',
            32,
          )} / ${truncateText(variations[0]?.name || '', 32)} 以上の金額に設定してください。`,
        });
      }

      const minPrice = toNumber(variation?.option?.minPrice);
      if (variationPrice >= firstPrice && variationPrice >= minPrice) {
        clearErrors('price');
      }
    }
  };

  const handleValidation = useCallback(async () => {
    await trigger();

    validateSongs();
    validateSongType();
    handleValidateCode();
    validatePrice();
    if (variation?.coverImageUrl) clearErrors('coverImageUrl');
  }, [variation]);

  const handleValidationSaveDraft = useCallback(async () => {
    await trigger(['name', 'saleTokenDate', 'productCode', 'janCode', 'price', 'downloadDate']);
    validateSongType();
    validatePrice();
  }, [variation]);

  useEffect(() => {
    if (isValidate) handleValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidate]);

  useEffect(() => {
    if (isValidateSaveDraft) {
      handleValidationSaveDraft();
      handleValidateCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidateSaveDraft]);

  useEffect(() => {
    //clearErrors('name');
    clearErrors('janCode');
    clearErrors('productCode');
    const error = dataErr.filter((item: IDataError) => item.index === variationIndex);
    error.map((err: any) => {
      if (err) {
        setError(err.name, {
          type: 'manual',
          message: err.message,
        });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataErr]);

  useEffect(() => {
    setDataErr(dataError);
  }, [dataError]);

  useEffect(() => {
    if (!variation.downloadDate) return;
    const downloadDateToTime = new Date(variation.downloadDate).getTime();
    const saleDateToTime = new Date(variation.saleTokenDate).getTime();
    if (downloadDateToTime < saleDateToTime) {
      let dataSource = [...variations];
      dataSource = dataSource.map((variation, key) =>
        key === variationIndex
          ? { ...variation, downloadDate: handlerConvertTime(variation.saleTokenDate) }
          : { ...variation },
      );
      dispatch(setVariations({ variations: dataSource }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variation.saleTokenDate]);

  useEffect(() => {
    validateSongType();
  }, [getValues('musicFormat'), variation]);

  useEffect(() => {
    const songs = [...variation.songs];
    if (!songs.length) {
      clearErrors('songs');
    }
  }, [variation]);

  const getTitleDetail = async () => {
    try {
      const {
        data: { data },
      } = await TitleApi.getTitle({ id });
      const titleCurren: any = handleConvertTitleInfo(data);

      setVariationCurrent(titleCurren?.variations);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (variation?.id && id) {
      getTitleDetail();
    }
  }, [id, variation?.id]);

  const isDiabledDelete = () => {
    let isDiabled = false;
    // Disable Date now > date sale
    const dateNow = new Date();
    const dateBooking = new Date(variation?.saleTokenDate);
    if (variation?.saleTokenDate && dateNow > dateBooking) isDiabled = true;
    if (variation?.id && variation?.isUsedInCampaign) isDiabled = true;
    return isDiabled;
  };

  const isDiabledEdit = useCallback(() => {
    let isDiabled = false;
    const downloadDateToTime = new Date(variation.downloadDate).getTime();
    const nowToTime = new Date().getTime();
    if (
      !variation.isEdit &&
      variation.downloadDate &&
      variation.id &&
      downloadDateToTime < nowToTime &&
      status
    ) {
      isDiabled = true;
    }
    return isDiabled;
  }, [status, variation.downloadDate, variation.id, variation.isEdit]);

  useEffect(() => {
    const variationNames = variations.map((item: Variation) => item.name);
    const duplicates = variationNames.filter(
      (item: any) => item?.trim()?.toLowerCase() === getValues('name')?.trim()?.toLowerCase(),
    );

    if (errors?.name && !isEmpty(getValues('name')) && duplicates.length === 1) {
      clearErrors('name');
    }
  }, [variations, getValues('name'), errors?.name]);

  const handleRenderBoxSong = useMemo(
    () => {
      return (
        <div ref={songsRef}>
          <BoxSong
            key={`${variationIndex}`}
            variationIndex={variationIndex}
            variationName={variation.name ?? '通常盤'}
            errors={
              !isEmpty(listError) && !isEmpty(listError?.songs) ? listError?.songs : errors?.songs
            }
            isDiabledEditSong={isDiabledEdit()}
          />
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variationIndex, { ...errors.songs }, listError?.songs],
  );

  const handleRenderBoxVideo = useMemo(() => {
    const currentVariation = {
      ...variation,
      jmdCooperation: variation.jmdCooperation ?? LINK_JMD_TYPE_CONTENT.WORK_TOGETHER,
    };

    return (
      <div ref={videosRef}>
        <BoxVideo
          key={`${variationIndex}`}
          variationIndex={variationIndex}
          variationName={variation.name ?? '通常盤'}
          isDiabledEditSong={isDiabledEdit()}
          variation={currentVariation}
          listError={
            !isEmpty(listError) && !isEmpty(listError?.videos) ? listError?.videos : errors?.videos
          }
          jmdVideoGenreIdMsgError={
            !isEmpty(listError)
              ? listError.jmdVideoGenreId?.message
              : errors.jmdVideoGenreId?.message
          }
          onChangeValueJmdVideo={(value: number | null) =>
            setValueIntoForm({ jmdVideoGenreId: value })
          }
        />
      </div>
    );
  }, [errors?.videos, isDiabledEdit, listError, variation, variationIndex, videosRef]);

  const handleRenderBoxImage = () => {
    return (
      <div ref={imagesRef}>
        <BoxImage variationIndex={variationIndex} listError={listError?.images} />
      </div>
    );
  };

  const handleRenderBooklet = useMemo(
    () => {
      return <Booklet key={`${variationIndex}`} variationIndex={variationIndex} />;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variationIndex],
  );

  const handleRenderTitleHeader = useMemo(
    () => (
      <>
        <span className="ps-4">{artistName}</span>
        <span className="">{`${name ? ` / ${name}` : ''}`}</span>
        <span>{variation.name ? ` / ${variation.name}` : ''}</span>
      </>
    ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variation.name, variation.type, currentStep],
  );

  useEffect(() => {
    if ((isValidate || isValidateSaveDraft) && (!isEmpty(errors) || !isEmpty(listError))) {
      setIsToggle(true);
    }
  }, [errors, isValidate, isValidateSaveDraft, listError]);

  const getErrorMsg = (key: string) => {
    let err = '';
    if (
      !isEmpty(dataError) &&
      dataError[variationIndex]?.name === key &&
      dataError[variationIndex]?.index === variationIndex
    ) {
      err = dataError[variationIndex]?.message;
    }

    if (!isEmpty(listError) && !isEmpty(listError[key])) {
      err = listError[key]?.message;
    }

    return err;
  };

  const handleAutoCaculateFee = (price: number | string) => {
    //* only caculate informationFee when price

    if (price) dispatch(setCaculateFee(true));
  };

  const handleSubmitModalJarsac = () => {
    setVisibleModalDelete(false);
    setValue('includeJasracContent', INCLUDE_JARSAC_CONTENT.NOT_INCLUDE);
    let dataSource = [...variations];
    dataSource = dataSource.map((variation, key) => {
      const newSongs: any[] =
        variation?.songs?.map((song: Song) => ({
          ...song,
          authorizedGroupType:
            getValues('includeJasracContent') === INCLUDE_JARSAC_CONTENT.NOT_INCLUDE &&
            song?.authorizedGroupType &&
            (+song?.authorizedGroupType === 1 || +song.authorizedGroupType === 2)
              ? null
              : song?.authorizedGroupType,
          informationFee: '',
          lyricInformationFee: '',
        })) || [];

      const videoSongs: any[] =
        variation?.videos?.map((video: Video) => {
          const songVideo: any[] =
            video?.songs?.map((song: Song) => ({
              ...song,
              authorizedGroupType:
                getValues('includeJasracContent') === INCLUDE_JARSAC_CONTENT.NOT_INCLUDE &&
                song?.authorizedGroupType &&
                (+song?.authorizedGroupType === 1 || +song.authorizedGroupType === 2)
                  ? null
                  : song?.authorizedGroupType,
              informationFee: '',
            })) || [];
          return { ...video, songs: songVideo, mediaInformationFee: 0 };
        }) || [];
      return key === variationIndex
        ? {
            ...variation,
            includeJasracContent: INCLUDE_JARSAC_CONTENT.NOT_INCLUDE,
            songs: newSongs,
            videos: videoSongs,
          }
        : { ...variation };
    });
    dispatch(setVariations({ variations: dataSource }));
    setIncludeJasracContents([
      {
        label: 'バリエーション内の楽曲または動画に著作権管理コンテンツを含む',
        value: INCLUDE_JARSAC_CONTENT.INCLUDE,
        checked: false,
      },
      {
        label: '含まない',
        value: INCLUDE_JARSAC_CONTENT.NOT_INCLUDE,
        checked: true,
      },
    ]);
    dispatch(setCaculateFee(true));
  };

  const getListJmdMusicGenre = async () => {
    const {
      data: { data },
    } = await TitleApi.getJmdMusicGenre();

    if (data.length > 0) {
      setListJmdMusicGenreData(data);
    }
  };

  useEffect(() => {
    getListJmdMusicGenre();
  }, []);

  const janCodeLinkJMDOptions = useMemo(
    () =>
      listJmdMusicGenreData.map(({ child, ...rest }) => {
        return { label: rest.genre1, value: rest.genre1 };
      }),
    [listJmdMusicGenreData],
  );

  const janCodeLinkJMDSecond = useMemo(() => {
    const foundItem = listJmdMusicGenreData.find(
      ({ genre1 }) => linkJMDAudioValue.firstValue.value === genre1,
    );
    if (foundItem) {
      return foundItem.child;
    }
    return [];
  }, [linkJMDAudioValue.firstValue, listJmdMusicGenreData]);

  const janCodeLinkJMDOptionsSecond = useMemo(() => {
    if (janCodeLinkJMDSecond) {
      return janCodeLinkJMDSecond.map((childItem) => ({
        label: childItem.genre2,
        value: childItem.id || childItem.genre2,
      }));
    }
    return [];
  }, [janCodeLinkJMDSecond]);

  const janCodeLinkJMDThird = useMemo(() => {
    const foundItem =
      linkJMDAudioValue.secondValue &&
      janCodeLinkJMDSecond.find(({ genre2 }) => linkJMDAudioValue.secondValue.value === genre2);
    if (foundItem) {
      return foundItem.child;
    }
    return [];
  }, [janCodeLinkJMDSecond, linkJMDAudioValue.secondValue]);

  const janCodeLinkJMDOptionsThird = useMemo(() => {
    if (Array.isArray(janCodeLinkJMDThird)) {
      return janCodeLinkJMDThird.map((childItem: { genre3: string; id: number }) => ({
        label: childItem.genre3,
        value: childItem.id || childItem.genre3,
      }));
    }
    return [];
  }, [janCodeLinkJMDThird]);

  const janCodeLinkJMDLast = useMemo(() => {
    const foundItem: any =
      linkJMDAudioValue.thirdValue &&
      Array.isArray(janCodeLinkJMDThird) &&
      janCodeLinkJMDThird.find(({ genre3 }) => linkJMDAudioValue.thirdValue.value === genre3);
    if (foundItem) {
      return foundItem.child;
    }
    return [];
  }, [janCodeLinkJMDThird, linkJMDAudioValue.thirdValue]);

  const janCodeLinkJMDOptionsLast = useMemo(() => {
    if (Array.isArray(janCodeLinkJMDLast)) {
      return janCodeLinkJMDLast.map((childItem) => ({
        label: childItem.genre4,
        value: childItem.id || childItem.genre4,
      }));
    }
    return [];
  }, [janCodeLinkJMDLast]);

  const handleValueJmdMusic = (value: number | string) => {
    setCurrentJmdMusicGenreIdValue(typeof value === 'string' ? null : value);
  };

  useEffect(() => {
    setValue('jmdMusicGenreId', currentJmdMusicGenreIdValue);
    setValueIntoForm({ jmdMusicGenreId: currentJmdMusicGenreIdValue });
  }, [currentJmdMusicGenreIdValue, linkJMDAudioValue]);

  const getListJPRecordingAssociation = async () => {
    const {
      data: { data },
    } = await TitleApi.getJPRecordingAssociationGenre();
    if (data.length > 0) {
      const result = data.map((item: { nameJp: string; id: number }) => {
        return { label: item.nameJp, value: item.id };
      });
      setJPRecordingAssociationGenreOptions(result);
    }
  };

  useEffect(() => {
    getListJPRecordingAssociation();
  }, []);

  const currentRiajGenreIdOption = useMemo(() => {
    return JPRecordingAssociationGenreOptions.filter(
      (option) => option.value === riajGenreIdValue,
    )?.[0];
  }, [riajGenreIdValue, JPRecordingAssociationGenreOptions]);

  const isReviewJmdMusicGenreId = useMemo(
    () => isReviewTitle('jmdMusicGenreId', variation),
    [variation],
  );

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <CardContainer
        activated={isToggle}
        darkHeader
        eventKey={`${variationIndex}`}
        headerClass={variationIndex % 2 ? 'bg-gray-dark white' : ''}
        title="バリエーション情報"
        onCollapse={() => setIsToggle(!isToggle)}
        notCollapseBody
        className="mt-4"
        renderTitleHeader={handleRenderTitleHeader}
      >
        <form id={`variation-${variationIndex}`} className={`${isToggle ? 'visible' : 'd-none'}`}>
          <Row>
            <CardCapacity
              titleId={id}
              variation={variation}
              getValueOption={(option: any) => setValue('option', option)}
            />
            <Form.Group
              as={Row}
              className={`mb-3 ${isReviewTitle('name', variation) && 'pe-none'}`}
            >
              <Form.Label column md="2">
                バリエーション名
              </Form.Label>
              <Col md="10">
                <div className="d-flex align-items-baseline">
                  <div className="w-70" ref={nameRef}>
                    <Form.Control
                      {...register('name')}
                      value={getValues('name')}
                      onChange={(e) => handleOnchangeInput(e.target.value, 'name', true)}
                      maxLength={MAX_INPUT}
                      disabled={isReviewTitle('name', variation)}
                    />
                  </div>
                </div>
                <MessageError
                  message={
                    !isEmpty(getErrorMsg('name')) ? getErrorMsg('name') : errors?.name?.message
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className={'mb-3 align-items-baseline'}>
              <Form.Label column md="2">
                著作権管理
              </Form.Label>
              <Col md="10">
                <div ref={includeJasracContentRef}>
                  <GroupCheckBox
                    id={`includeJasracContent-${variationIndex}`}
                    name="includeJasracContent"
                    options={includeJasracContents}
                    colMd="12"
                    colClass="px-0"
                    classForm="align-items-center mb-0 h-100"
                    onChange={handleCheckIncludeJarsac}
                    errorMessage={errors?.includeJasracContent?.message}
                    checkboxClass="radio-variation"
                  />
                </div>
              </Col>
            </Form.Group>
            {/* ----------------New code Miim-177 ----------------*/}
            <Form.Group
              as={Row}
              className={`mb-3 align-items-baseline ${
                isReviewTitle('jmdCooperation', variation) && 'pe-none'
              }`}
            >
              <Form.Label column md="2">
                jmd連携
              </Form.Label>
              <Col md="10">
                <div ref={jmdCooperationRef}>
                  <GroupCheckBox
                    id={`jmdCooperation-${variationIndex}`}
                    name="jmdCooperation"
                    options={jmdCooperationOptions}
                    colMd="12"
                    colClass="px-0"
                    classForm="align-items-center mb-0 h-100"
                    onChange={handleChangeJmdCooperationCheckBox}
                    errorMessage={errors?.jmdCooperation?.message}
                  />
                </div>
              </Col>
            </Form.Group>
            {jmdCooperationValue === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER && (
              <>
                <div ref={riajGenreIdRef}>
                  <FormGroupSelect
                    value={currentRiajGenreIdOption}
                    styleLabel={{
                      width: '16.3%',
                    }}
                    label="日本レコード協会ジャンル"
                    placeholder=""
                    labelMd="2"
                    colMd="4"
                    colClass="px-3"
                    classNameSlc={
                      isReviewTitle('riajGenreId', variation) &&
                      JPRecordingAssociationGenreOptions.length > 0
                        ? 'pulldown-select-title-isReview'
                        : 'pulldown-select-title'
                    }
                    options={JPRecordingAssociationGenreOptions}
                    onChange={(item) => {
                      setValueIntoForm({ riajGenreId: item?.value });
                    }}
                    rowClass="mb-3"
                    errorMessage={errors.riajGenreId?.message}
                    disabled={isReviewTitle('riajGenreId', variation)}
                  />
                </div>

                <div ref={jmdMusicGenreRef}>
                  <FormGroupSelect
                    value={linkJMDAudioValue.firstValue.value ? linkJMDAudioValue.firstValue : ''}
                    styleLabel={{
                      width: '16%',
                    }}
                    label="jmd音楽ジャンル"
                    placeholder="分類１を選択してください"
                    labelMd="2"
                    colMd="4"
                    colClass="px-3"
                    classNameSlc={
                      isReviewJmdMusicGenreId && janCodeLinkJMDOptions.length > 0
                        ? 'pulldown-select-title-isReview'
                        : 'pulldown-select-title'
                    }
                    options={janCodeLinkJMDOptions}
                    onChange={(item) => {
                      setLinkJMDAudioValue((prev) => ({
                        ...prev,
                        firstValue: item,
                        secondValue: initValueOption,
                        thirdValue: initValueOption,
                        lastValue: initValueOption,
                      }));
                      handleValueJmdMusic(item.value);
                    }}
                    rowClass="mb-3"
                    disabled={isReviewJmdMusicGenreId}
                  />
                  <Row>
                    <Col
                      md="2"
                      style={{
                        maxWidth: '16%',
                      }}
                    >
                      <Link to={'/title/jmd-audio'} target="_blank">
                        ジャンル表
                      </Link>
                    </Col>
                    <Col
                      md="4"
                      style={{
                        padding: '0',
                      }}
                    >
                      <FormGroupSelect
                        value={
                          linkJMDAudioValue.secondValue?.value ? linkJMDAudioValue.secondValue : ''
                        }
                        label=""
                        disabled={
                          janCodeLinkJMDOptionsSecond.length <= 0 || isReviewJmdMusicGenreId
                        }
                        isClear={Boolean(!linkJMDAudioValue.secondValue?.value)}
                        placeholder={`分類 2 を選択してください`}
                        colMd="12"
                        colClass={`px-3 ${
                          janCodeLinkJMDOptionsSecond.length <= 0 && 'bg-disable-selector'
                        }`}
                        classNameSlc={
                          isReviewJmdMusicGenreId && janCodeLinkJMDOptionsSecond.length > 0
                            ? 'pulldown-select-title-isReview'
                            : 'pulldown-select-title'
                        }
                        options={janCodeLinkJMDOptionsSecond}
                        onChange={(item) => {
                          setLinkJMDAudioValue((prev) => ({
                            ...prev,
                            secondValue: item,
                            thirdValue: initValueOption,
                            lastValue: initValueOption,
                          }));
                          handleValueJmdMusic(item.value);
                        }}
                        rowClass="mb-3"
                      />
                      <FormGroupSelect
                        value={
                          linkJMDAudioValue.thirdValue?.value ? linkJMDAudioValue.thirdValue : ''
                        }
                        label=""
                        disabled={janCodeLinkJMDOptionsThird.length <= 0 || isReviewJmdMusicGenreId}
                        isClear={Boolean(!linkJMDAudioValue.thirdValue?.value)}
                        placeholder={`分類 3 を選択してください`}
                        colMd="12"
                        colClass={`px-3 ${
                          janCodeLinkJMDOptionsThird.length <= 0 && 'bg-disable-selector'
                        }`}
                        classNameSlc={
                          isReviewJmdMusicGenreId && janCodeLinkJMDOptionsThird.length > 0
                            ? 'pulldown-select-title-isReview'
                            : 'pulldown-select-title'
                        }
                        options={janCodeLinkJMDOptionsThird}
                        onChange={(item) => {
                          setLinkJMDAudioValue((prev) => ({
                            ...prev,
                            thirdValue: item,
                            lastValue: initValueOption,
                          }));
                          handleValueJmdMusic(item.value);
                        }}
                        rowClass="mb-3"
                      />
                      <FormGroupSelect
                        value={
                          linkJMDAudioValue.lastValue?.value ? linkJMDAudioValue.lastValue : ''
                        }
                        label=""
                        disabled={janCodeLinkJMDOptionsLast.length <= 0 || isReviewJmdMusicGenreId}
                        isClear={Boolean(!linkJMDAudioValue.lastValue?.value)}
                        placeholder={`分類 4 を選択してください`}
                        colMd="12"
                        colClass={`px-3 ${
                          janCodeLinkJMDOptionsLast.length <= 0 && 'bg-disable-selector'
                        }`}
                        classNameSlc={
                          isReviewJmdMusicGenreId && janCodeLinkJMDOptionsLast.length > 0
                            ? 'pulldown-select-title-isReview'
                            : 'pulldown-select-title'
                        }
                        options={janCodeLinkJMDOptionsLast}
                        onChange={(item) => {
                          setLinkJMDAudioValue((prev) => ({
                            ...prev,
                            lastValue: item,
                          }));
                          handleValueJmdMusic(item.value);
                        }}
                        rowClass="mb-3"
                        errorMessage={errors.jmdMusicGenreId?.message}
                      />
                    </Col>
                  </Row>
                </div>
              </>
            )}
            {/* ----------------End code Miim-177 ----------------*/}
            <Form.Group
              as={Row}
              className={`mb-3 align-items-baseline ${
                isReviewTitle('musicFormat', variation) && 'pe-none'
              }`}
            >
              <Form.Label column md="2">
                楽曲フォーマット
              </Form.Label>
              <Col md="10">
                <div ref={musicFormatRef}>
                  <GroupCheckBox
                    id={`musicFormat-${variationIndex}`}
                    name="musicFormat"
                    options={musicFormat}
                    colMd="12"
                    colClass="px-0"
                    classForm="align-items-center mb-0 h-100"
                    onChange={handleChangeCheckBox}
                    errorMessage={errors?.musicFormat?.message}
                  />
                </div>
              </Col>
            </Form.Group>
            <Col md="6">
              <div ref={saleTokenDateRef}>
                <FormGroupDate
                  label="情報公開解禁日時*"
                  colMd="8"
                  labelMd="4"
                  minDate={new Date()}
                  compareMinDate={true}
                  selected={handlerConvertTime(variation.saleTokenDate)}
                  onChange={(date: IDatePicker) => onChangeDate(date, 'saleTokenDate')}
                  dateFormat={DATE_FORMAT_FOR_DATE_PICKER}
                  placeholder="日時を選択　(yyyy/mm/dd hh:mm)"
                  register={register('saleTokenDate')}
                  errorMessage={
                    !isEmpty(getErrorMsg('saleTokenDate'))
                      ? getErrorMsg('saleTokenDate')
                      : errors?.saleTokenDate?.message
                  }
                  subContent=""
                  disabled={isReviewTitle('saleTokenDate', variationCurrent[variationIndex])}
                  minTime={new Date()}
                />
              </div>
              <div ref={downloadDateRef}>
                <FormGroupDate
                  label="DL開始日時（発売日時）*"
                  colMd="8"
                  labelMd="4"
                  minDate={
                    variation.saleTokenDate
                      ? handlerConvertTime(variation.saleTokenDate)
                      : new Date()
                  }
                  compareMinDate={true}
                  selected={handlerConvertTime(variation.downloadDate)}
                  onChange={(date: IDatePicker) => onChangeDate(date, 'downloadDate')}
                  dateFormat={DATE_FORMAT_FOR_DATE_PICKER}
                  placeholder="日時を選択　(yyyy/mm/dd hh:mm)"
                  register={register('downloadDate')}
                  errorMessage={
                    !isEmpty(getErrorMsg('downloadDate'))
                      ? getErrorMsg('downloadDate')
                      : errors.downloadDate?.message
                  }
                  subContent="発売日の前日まで延期が可能です。"
                  disabled={isReviewTitle('downloadDate', variationCurrent[variationIndex])}
                  minTime={
                    variation.saleTokenDate
                      ? handlerConvertTime(variation.saleTokenDate)
                      : new Date()
                  }
                />
              </div>
              <div ref={notiDownloadTimeRef}>
                <FormGroupDate
                  label="DL開始通知時刻"
                  colMd="8"
                  labelMd="4"
                  compareMinDate={false}
                  placeholder="時刻を選択 (hh:mm)"
                  showTimeSelectOnly
                  selected={handlerConvertTime(variation.notiDownloadTime)}
                  dateFormat={TIME_FORMAT}
                  onChange={(date: any) => {
                    const validDate = replaceTimeOfDate(variation?.downloadDate, date);
                    onChangeDate(date === null ? null : validDate, 'notiDownloadTime');
                  }}
                  minTime={getMinTimeStart()}
                  maxTime={getMaxTime()}
                  register={register('notiDownloadTime')}
                  errorMessage={
                    !isEmpty(getErrorMsg('notiDownloadTime'))
                      ? getErrorMsg('notiDownloadTime')
                      : errors.notiDownloadTime?.message
                  }
                  disabled={isAfterDownload(variationCurrent[variationIndex])}
                  isDefaultSetTime
                />
              </div>

              <div ref={productCodeRef}>
                <FormGroupInput
                  register={register('productCode')}
                  labelMd="4"
                  colMd="8"
                  label="品番*"
                  value={variation.productCode}
                  errorMessage={
                    !isEmpty(getErrorMsg('productCode'))
                      ? getErrorMsg('productCode')
                      : errors.productCode?.message
                  }
                  onChange={(value) => handleOnchangeInput(value, 'productCode', true)}
                  isReview={isReviewTitle('productCode', variation)}
                />
              </div>
              <div ref={janCodeRef}>
                <FormInputNumber
                  name="janCode"
                  labelMd="4"
                  colMd="8"
                  label="JANコード*"
                  pureType={true}
                  decimalScale={0}
                  maxLength={13}
                  allowLeadingZeros={true}
                  value={variation.janCode}
                  errorMessage={
                    !isEmpty(getErrorMsg('janCode'))
                      ? getErrorMsg('janCode')
                      : errors.janCode?.message
                  }
                  onChange={(value) => handleOnchangeInput(value, 'janCode', true)}
                  allowNegative={false}
                  isReview={isReviewTitle('janCode', variation)}
                />
              </div>
              <div ref={priceRef}>
                <FormInputNumber
                  name="price"
                  colMd="4"
                  labelMd="4"
                  label="販売価格*"
                  value={variation?.price}
                  classCol="position-relative price-end"
                  pureType={true}
                  decimalScale={0}
                  allowNegative={false}
                  maxLength={7}
                  thousandSeparator={true}
                  errorMessage={
                    !isEmpty(getErrorMsg('price')) ? getErrorMsg('price') : errors.price?.message
                  }
                  isReview={isReviewTitle('price', variation)}
                  onBlurInput={(price) => {
                    handleOnchangeInput(price, 'price', true);
                    handleAutoCaculateFee(price);
                  }}
                />
              </div>
              <Row className="mb-4">
                <Col md={4}>
                  <span>Apple経由販売価格</span>
                </Col>
                <Col>
                  <span>{`${formatPrice(convertPriceApple(variation?.price))} 円`}</span>
                </Col>
              </Row>
            </Col>
            <Col md="6">
              <div ref={coverImageUrlRef}>
                <Form.Group
                  as={Row}
                  className={`mb-3 ${isReviewTitle('coverImageUrl', variation) && 'pe-none'}`}
                >
                  <Col md={4}>
                    ジャケット画像 <p>(600px X 600px)</p>
                  </Col>
                  <Col md={8}>
                    <BoxDropZone
                      maxFiles={1}
                      key={`cover-image-${variationIndex}`}
                      variationIndex={variationIndex}
                      coverImageUrl={variations[variationIndex]?.coverImageUrl}
                    />
                    <MessageError message={errors.coverImageUrl?.message} />
                  </Col>
                </Form.Group>
              </div>
            </Col>
          </Row>
          <div ref={bookletsRef}>
            <Form.Group
              as={Row}
              className={`mb-3
            ${isReviewTitle('booklets', variation) && 'pe-none'} `}
            >
              <Col md={2} className="ps-4">
                ブックレット画像 <p>(600px X 600px)</p>
              </Col>
              <Col md={10}>
                {handleRenderBooklet}
                <MessageError message={errors?.booklets?.message} />
              </Col>
            </Form.Group>
          </div>
        </form>
        <div className={`${isToggle ? 'visible' : 'd-none'} mb-4`}>{handleRenderBoxSong}</div>
        <div className={`${isToggle ? 'visible' : 'd-none'} mb-4`}>{handleRenderBoxVideo}</div>
        <div
          className={`${isToggle ? 'visible' : 'd-none'} mb-4 ${
            isReviewTitle('images', variation) && 'pe-none'
          }`}
        >
          {handleRenderBoxImage()}
          <MessageError message={listError?.videos?.message} />
        </div>

        <div className={`${isToggle ? 'visible' : 'd-none'} text-center mt-4 mb-2`}>
          <Button
            variant="danger"
            disabled={isDiabledDelete()}
            onClick={() => setModalType('delete')}
          >
            バリエーションを削除する
          </Button>
        </div>
      </CardContainer>
      <ModalConfirm
        isShow={!!modalType}
        onClose={() => setModalType('')}
        onSubmitDelete={() => {
          handleDeleteVariation();
          setModalType('');
        }}
        type={modalType}
      />
      <ModalComponent
        show={visableModalDelete}
        onCloseModal={() => {
          setValue('includeJasracContent', INCLUDE_JARSAC_CONTENT.INCLUDE);
          setVisibleModalDelete(false);
          let dataSource = [...variations];
          dataSource = dataSource.map((variation, key) =>
            key === variationIndex
              ? { ...variation, includeJasracContent: INCLUDE_JARSAC_CONTENT.INCLUDE }
              : { ...variation },
          );
          dispatch(setVariations({ variations: dataSource }));
          setIncludeJasracContents([
            {
              label: 'バリエーション内の楽曲または動画に著作権管理コンテンツを含む',
              value: INCLUDE_JARSAC_CONTENT.INCLUDE,
              checked: true,
            },
            {
              label: '含まない',
              value: INCLUDE_JARSAC_CONTENT.NOT_INCLUDE,
              checked: false,
            },
          ]);
        }}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="danger"
        title="変更確認"
        onSubmitModal={() => handleSubmitModalJarsac()}
        confirmText={'はい'}
        cancelText={'いいえ'}
      >
        <div>
          <p className="mb-2">
            「著作権信託先」が選択されています。選択を破棄し登録を続行しますか？
          </p>
        </div>
      </ModalComponent>
    </>
  );
}
