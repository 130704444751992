import React, { useMemo } from 'react';
import { Cell } from 'react-table';
// import dayjs from 'dayjs';
import TableCommonV2 from 'Components/Common/Table/TableCommonV2';
// import { DATE_FORMAT_9 } from 'Constant/Date';

export default function TableSampleMasterDpResponse({
  dataSource,
}: {
  dataSource: any[];
}): JSX.Element {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: 'miim ID',
            accessor: 'miimID',
            width: '100',
            Cell: ({ row }: Cell<any>) => (
              <div className="text-truncate">{row?.original?.miimID}</div>
            ),
          },
          {
            Header: 'ユーザ名',
            accessor: 'userName',
            Cell: ({ row }: Cell<any>) => {
              return (
                <>
                  <div className="text-truncate">{row?.original?.realName}</div>
                </>
              );
            },
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
              const defaultVal = desc ? 'A' : 'Z';
              return (rowA?.original?.realName ?? defaultVal).localeCompare(
                rowB?.original?.realName ?? defaultVal,
              );
            },
          },
          {
            Header: 'メールアドレス',
            accessor: 'email',
            Cell: ({ row }: Cell<any>) => {
              return <div className="text-truncate">{row?.original?.email}</div>;
            },
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
              const defaultVal = desc ? 'A' : 'Z';
              return (rowA?.original?.email ?? defaultVal).localeCompare(
                rowB?.original?.email ?? defaultVal,
              );
            },
          },
        ],
      },
    ],
    [],
  );
  return (
    <div className="sample-master-table mt-3 mb-0">
      <TableCommonV2
        data={dataSource}
        columns={columns}
        isNotShowSort={true}
        getCellProps={() => {
          return {
            className: 'text-start',
          };
        }}
      />
    </div>
  );
}
