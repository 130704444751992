import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Filter from 'Components/Common/Filter/Filter';
import DropdownMenu from 'Components/Common/EC/DropdownMenu';
import OrderCancellation from './EcSendBulkEmailForm';

export default function EmailManagement(): JSX.Element {
  const groupOptions = [
    { name: '購入完了通知' },
    { name: '抽選購入申込完了通知' },
    { name: '抽選購入当選通知' },
    { name: '注文全キャンセル通知' },
    { name: '注文一部キャンセル通知' },
    { name: 'お問い合わせメール_お客様宛・指示用' },
    { name: 'お問い合わせメール_EC宛・指示用' },
  ];

  const [activeOption, setActiveOption] = useState(groupOptions[0].name);

  return (
    <Container fluid>
      <Filter filterClass={`shadow-sm `}>
        <div>メールテンプレート</div>
        <DropdownMenu
          options={groupOptions}
          activeOption={activeOption}
          onChange={setActiveOption}
          classButton="ec_custom_dropdown-menu_btn"
        />
      </Filter>
      {activeOption == groupOptions[0].name ? (
        <OrderCancellation name={groupOptions[0].name} />
      ) : activeOption == groupOptions[1].name ? (
        <OrderCancellation name={groupOptions[1].name} />
      ) : activeOption == groupOptions[2].name ? (
        <OrderCancellation name={groupOptions[2].name} />
      ) : activeOption == groupOptions[3].name ? (
        <OrderCancellation name={groupOptions[3].name} />
      ) : activeOption == groupOptions[4].name ? (
        <OrderCancellation name={groupOptions[4].name} />
      ) : activeOption == groupOptions[5].name ? (
        <OrderCancellation name={groupOptions[5].name} />
      ) : (
        <OrderCancellation name={groupOptions[6].name} />
      )}
    </Container>
  );
}
