import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import CardContainer from 'Components/Common/CardContainer';
import { Link } from 'react-router-dom';
import { REGISTRATION, APPROVAL, UPDATE } from 'Constant/Approval';
import ModalComponent from 'Components/Common/Modal';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import MessageError from 'Components/Common/MessageError';
import dayjs from 'dayjs';
import { DATE_FORMAT_8, DATE_FORMAT_9 } from 'Constant/Date';

interface IVariationProps {
  value: any;
  key: any;
}

interface IItemStatus {
  type: number;
  name: string;
}

export const schemaVariation = yup.object().shape({
  memo: yup.string().required('入力してください。').trim('入力してください。').nullable(),
});

export default function ItemVariation(props: IVariationProps): JSX.Element {
  const { value } = props;
  const [memo, setMemo] = useState<string>('');
  const [modalConfirmApproval, setModalConfirmApproval] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaVariation),
  });

  const onSubmit = handleSubmit(() => {
    setModalConfirmApproval(true);
  });

  const renderTag = (type: number) => {
    if (type === REGISTRATION) return <p className="tag bg-1">登録</p>;
    if (type === APPROVAL) return <p className="tag bg-2">承認</p>;
    if (type === UPDATE) return <p className="tag bg-3">変更</p>;
  };

  const renderItem = (item: IItemStatus) => {
    return (
      <Row>
        <div className="d-flex flex-row mb-2">
          {renderTag(item.type)}
          <p className="d-inline-flex mx-3 col-3 ">{dayjs('2022-01-05').format(DATE_FORMAT_9)}</p>
          <p className="text-truncate col-2">{item.name}</p>
          <p className="text-break mx-3">承認時めも・伝言。xxxxxxxxxxxxxxxxxxx承認します。</p>
        </div>
      </Row>
    );
  };

  const onHandleApproval = () => {
    setModalConfirmApproval(false);
  };

  return (
    <CardContainer
      title="[Title name] [Variation name] [Digital tokuten name] - [Artist name]"
      darkHeader
      className="mt-2 mx-4 mb-4"
    >
      <form onSubmit={onSubmit}>
        <Row>
          <Col md={1} className="px-0 img-variation">
            <img
              id={`img-variation-base64-${value.id}`}
              className="border d-flex align-items-center justify-content-center"
              alt={`img-variation-base64-${value.id}`}
              crossOrigin="anonymous"
              src="https://gatorworks.net/wp-content/uploads/2020/06/actual-photo-size.png"
            />
          </Col>
          <Col>
            <Row>
              <Col md={1} lg={2}>
                <p className="title-item">タイトル</p>
                <p className="result-item text-truncate">TITLEdsadasdasd</p>
              </Col>
              <Col md={1} lg={2}>
                <p className="title-item">バリエーション</p>
                <p className="result-item text-truncate">VARIATION NAME</p>
              </Col>
              <Col md={1} lg={2}>
                <p className="title-item">デジタル特典名称</p>
                <p className="result-item text-truncate">DIGITAL TOKUTEN</p>
              </Col>
            </Row>
            <p className="ps-4 my-2 text-truncate">アーティスト</p>

            <Row className="ps-3 px-0">
              <Col md={1} lg={2}>
                <p className="title-item">価格</p>
                <p className="fs-14">{`${Number('1000')?.toLocaleString()}円`}</p>
              </Col>
              <Col md={1} lg={2}>
                <p className="title-item">発売日</p>
                <p className="fs-14">{dayjs('2022-01-05').format(DATE_FORMAT_8)}</p>
              </Col>
              <Col md={1} lg={2}>
                <p className="title-item">品番</p>
                <p className="fs-14">ABC-1234</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="mt-4" />
        <Row>
          <Col md={1} className="d-flex align-self-center px-0">
            <Link to="/">デジタル特典</Link>
          </Col>
          <Col md={7}>{value?.item?.map((item: IItemStatus) => renderItem(item))}</Col>
          <Col md={4}>
            <textarea
              {...register('memo')}
              className="form-control h-120px"
              placeholder="メモ・伝言を入力することができます。"
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
            />

            <MessageError message={errors.memo?.message} />
            <Button className="mt-2 float-end button-submit" type="submit">
              確認しました
            </Button>
          </Col>
        </Row>
        <ModalComponent
          show={modalConfirmApproval}
          onCloseModal={() => setModalConfirmApproval(false)}
          classNameContent="d-flex align-items-center content-body-modal justify-content-center"
          variantButtonSubmit="primary"
          title="承認確認"
          confirmText="承認する"
          onSubmitModal={() => onHandleApproval()}
        >
          <div>
            <p className="mb-2">情報を承認します。よろしいですか。</p>
          </div>
        </ModalComponent>
      </form>
    </CardContainer>
  );
}
