import React from 'react';
import { ReactComponent as VisaIcon } from 'Static/Images/visa.svg';
import { ReactComponent as MasterCardIcon } from 'Static/Images/master-card.svg';
import { ReactComponent as JCBIcon } from 'Static/Images/jcb.svg';
import { ReactComponent as DiscoverIcon } from 'Static/Images/discover.svg';
import { ReactComponent as AmericanExpressIcon } from 'Static/Images/american-express.svg';
import { ReactComponent as DinersClubIcon } from 'Static/Images/diners-club.svg';

const SALES_STATUS = [
  { value: undefined, label: 'すべて' },
  { value: 0, label: '公開未設定', bgColor: '#e6e6e6', borderColor: '#C4C4C4' },
  { value: 1, label: '公開前', bgColor: '#77a6e5', borderColor: '#7C86B1' },
  { value: 2, label: '公開中販売前', bgColor: '#77d8e5', borderColor: '#7C9FB1' },
  { value: 3, label: '公開中販売可', bgColor: '#90e577', borderColor: '#95B17C' },
  { value: 4, label: '公開中販売終了', bgColor: '#fcc369', borderColor: '#D69313' },
  { value: 5, label: '公開終了', bgColor: '#e6e6e6', borderColor: '#C4C4C4' },
  { value: 6, label: '非公開', bgColor: '#ff9696', borderColor: '#9D0404' },
];

const PURCHASE_STATUS = [
  { value: 0, label: 'キャンセル', bgColor: '#ff9696' },
  { value: 1, label: '部分キャンセル', bgColor: '#fcc369' },
  { value: 2, label: '購入済み', bgColor: '#e6e6e6' },
  { value: 3, label: '決済エラー', bgColor: '#ff9696' },
];

const PURCHASE_DETAIL_STATUS = {
  CANCEL: 'CANCEL',
  PURCHASED: 'PURCHASED',
  PARTIAL_CANCELLATION: 'PARTIAL_CANCELLATION',
  NOT_PAYMENT: 'NOT_PAYMENT',
  ERROR: 'ERROR',
};

interface PaymentCard {
  [key: string]: { name: string; logo: any };
}

const PAYMENT_CARD: PaymentCard = {
  Visa: {
    name: 'Visa',
    logo: <VisaIcon width={60} />,
  },
  MasterCard: {
    name: 'MasterCard',
    logo: <MasterCardIcon width={50} />,
  },
  JCB: {
    name: 'JCB',
    logo: <JCBIcon width={50} />,
  },
  Discover: {
    name: 'Discover',
    logo: <DiscoverIcon width={50} />,
  },
  AmericanExpress: {
    name: 'AmericanExpress',
    logo: <AmericanExpressIcon width={50} height={50} />,
  },
  DinersClub: {
    name: 'DinersClub',
    logo: <DinersClubIcon width={58} />,
  },
};

interface PaymentCardStatus {
  [key: string]: string;
}

const PAYMENT_CARD_STATUS: PaymentCardStatus = {
  passed: '通過',
  declined: '否決',
  in_review: '審査中',
};

interface AccountPaymentStatus {
  [key: string]: string;
}

const ACCOUNT_PAYMENT_STATUS: AccountPaymentStatus = {
  unconfirmed: 'pending',
  confirmed: 'success',
  failed: 'failed',
};

const ACCOUNT_TYPE = [
  {
    label: '普通口座',
    value: 0,
  },
  {
    label: '当座口座',
    value: 1,
  },
];

export {
  SALES_STATUS,
  PURCHASE_STATUS,
  PURCHASE_DETAIL_STATUS,
  PAYMENT_CARD,
  PAYMENT_CARD_STATUS,
  ACCOUNT_PAYMENT_STATUS,
  ACCOUNT_TYPE,
};
