/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Barcode, { barcodeOptions } from 'Components/Common/BarCode/BarCode';
import { DATE_FORMAT_17, DATE_FORMAT_23, TIME_FORMAT } from 'Constant/Date';
import { IDataSaleQuantityItem } from 'Datasource/SupportRevenue';
import React from 'react';
import { formatDate } from 'Utils/DateTime';
import { formatCurrency2 } from 'Utils/Numbers';

interface IProps {
  data: IDataSaleQuantityItem;
}

export enum PromotionType {
  stillValid = 0,
  expired = 1,
}

const CardBill = ({ data }: IProps) => {
  return (
    <div className="card-bill d-flex flex-column gap-3">
      <div className="d-flex flex-column gap-1">
        <p>{data.artistName}</p>
        <p className="fw-700 fs-14">{data.titleName}</p>
        <p className="text-truncate fs-12">{data.cardCd}</p>
      </div>
      <div className="border-bottom-gray"></div>
      <div className="d-flex flex-column gap-1">
        <div className="d-flex justify-content-between">
          <p className="min-w-200">バリエーション名</p>
          <p className="text-truncate">{data.variationName}</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="min-w-200">品番</p>
          <p className="text-truncate">{data.variationProductCode}</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="min-w-200">販売価格</p>
          <p className="text-truncate">{formatCurrency2(data.variationPrice)}円</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="min-w-200">DL開始日（発売日）</p>
          <p className="text-truncate">{formatDate(data.variationDownloadDate, DATE_FORMAT_23)}</p>
        </div>
      </div>
      <div className="border-bottom-gray"></div>
      <div className="px-3">
        <div className="d-flex justify-content-between align-items-end">
          <p className="fw-700 fs-18 min-w-200">販売数</p>
          <p className="fw-700 fs-18 text-truncate">{formatCurrency2(data.purchasesQuantity)}</p>
        </div>
        <div className="d-flex justify-content-between align-items-end">
          <p className="fw-700 fs-18 min-w-150">販売合計金額</p>
          <p className="fw-700 fs-18 text-truncate">
            ¥{formatCurrency2(data.totalCardPurchasePrice)}
          </p>
        </div>
      </div>
      <div>
        <div
          className={`card-bill-contact d-flex flex-column gap-1 ${
            data.status === PromotionType.expired
              ? 'card-bill-contact_bg_gray'
              : 'card-bill-contact_bg_blue'
          }`}
        >
          <p className="fw-700 fs-18 text-center">
            {data.status === PromotionType.expired ? '販売終了' : '販売中'}
          </p>
          {data.saleEndDate && (
            <p className="fs-12 text-center text-truncate">
              販売終了：{formatDate(data.saleEndDate, DATE_FORMAT_17)}
              <strong className="fs-14 mx-1 text-truncate">
                {formatDate(data.saleEndDate, TIME_FORMAT)}
              </strong>
            </p>
          )}
          {data.campaigns.length > 0 && (
            <>
              <div className="border-bottom-white"></div>
              <div className="d-flex flex-column">
                {data.campaigns.map((campaign) => {
                  return (
                    <p key={campaign} className="fs-12 text-truncate">
                      {campaign}
                    </p>
                  );
                })}
              </div>
            </>
          )}
        </div>
        <div className="d-flex justify-content-center mt-1">
          {data.status === PromotionType.expired ? (
            <>
              <Barcode value={data.variationJanCode} options={barcodeOptions} />
            </>
          ) : (
            <p className="fw-700 text-truncate">{data.variationJanCode}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardBill;
