import React, { PropsWithChildren, useMemo } from 'react';
import TableCommon from 'Components/Common/Table/TableCommon';
import { includes, isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { DATE_FORMAT_18, DATE_FORMAT_21 } from 'Constant/Date';
import { TABLE_PRODUCTS_STATUS } from 'Constant';
import { formatCurrency } from 'Utils/Numbers';

interface TableProps {
  data: any;
  className?: string;
  type: string;
}

export default function TableCommonProducts({
  data,
  className,
  type,
}: PropsWithChildren<TableProps>): JSX.Element {
  const renderLotteryDate = (row: any) => {
    const { lotteryDate, courseType } = row;
    let txt = '';
    if (lotteryDate) {
      txt = dayjs(lotteryDate).format(DATE_FORMAT_18);
    } else {
      if (courseType === 0) {
        txt = '全員配布';
      }
      if (courseType === 2) {
        txt = 'スピード抽選';
      }
    }
    return txt;
  };
  const columnsVariation = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: '対象商品',
            accessor: 'productName',
            Cell: ({ row }: any) => {
              const { productName, cdId } = row?.original;
              return (
                <div className="d-flex">
                  <p>{productName}</p>
                  {cdId && <span className="text-item px-2 me-2 fs-11 ms-2">CD</span>}
                </div>
              );
            },
          },
          {
            Header: '販売価格',
            accessor: 'price',
            Cell: ({ row }: any) => {
              const { price } = row?.original;
              return <p>¥{formatCurrency(price)}</p>;
            },
          },
          {
            Header: '品番',
            accessor: 'productCode',
          },
          {
            Header: 'JANコード',
            accessor: 'janCode',
          },
        ],
      },
    ],
    [],
  );
  const columnsCourse = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: '応募コース',
            accessor: 'courseName',
          },
          {
            Header: '販売期間',
            Cell: ({ row }: any) => {
              const { saleStartDate, saleEndDate } = row?.original;
              return (
                <div className={`${saleStartDate || 'd-none'}`}>
                  {dayjs(saleStartDate).format(DATE_FORMAT_18)}~
                  {dayjs(saleEndDate).format(DATE_FORMAT_18)}
                </div>
              );
            },
          },
          {
            Header: '抽選日',
            Cell: ({ row }: any) => {
              return <div>{renderLotteryDate(row?.original)}</div>;
            },
          },
        ],
      },
    ],
    [],
  );
  const columnsTargetCampaign = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: 'キャンペーン名',
            accessor: 'campaignName',
            className: 'text-start',
          },
          {
            Header: '応募コース',
            accessor: 'courseName',
            Cell: ({ row }: any) => {
              const { courseProductInfos } = row?.original;
              return (
                <>
                  {courseProductInfos.map((el: any, index: number) => {
                    return (
                      <div key={index} className="p-1">
                        <p>{el.courseName}</p>
                      </div>
                    );
                  })}
                </>
              );
            },
          },
          {
            Header: '販売期間',
            accessor: 'date',
            Cell: ({ row }: any) => {
              const { courseProductInfos } = row?.original;
              return (
                <>
                  {courseProductInfos.map((el: any, index: number) => {
                    const { saleStartDate, saleEndDate } = el;
                    return (
                      <div key={index} className="p-1">
                        {saleStartDate ? (
                          <>
                            {dayjs(saleStartDate).format(DATE_FORMAT_18)}~
                            {dayjs(saleEndDate).format(DATE_FORMAT_18)}
                          </>
                        ) : (
                          '　'
                        )}
                      </div>
                    );
                  })}
                </>
              );
            },
          },
          {
            Header: '抽選日',
            accessor: 'lotteryDate',
            Cell: ({ row }: any) => {
              const { courseProductInfos } = row?.original;
              return (
                <>
                  {courseProductInfos.map((el: any, index: number) => {
                    return (
                      <div key={index} className="p-1">
                        {renderLotteryDate({
                          lotteryDate: el.lotteryDate,
                          courseType: el.courseType,
                        })}
                      </div>
                    );
                  })}
                </>
              );
            },
          },
        ],
      },
    ],
    [],
  );
  const columnsApplied = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: 'バリエーション名',
            accessor: 'productName',
            Cell: ({ row }: any) => {
              const { productName, cdId } = row?.original;
              return (
                <div className="d-flex">
                  <p>{productName}</p>
                  {cdId && <span className="text-item px-2 me-2 fs-11 ms-2">CD</span>}
                </div>
              );
            },
          },
          {
            Header: '販売価格',
            accessor: 'price',
            Cell: ({ row }: any) => {
              const { price } = row?.original;
              return <p>¥{formatCurrency(price)}</p>;
            },
          },
          {
            Header: '品番',
            accessor: 'productCode',
          },
          {
            Header: 'JANコード',
            accessor: 'janCode',
          },
          {
            Header: '発売日時',
            accessor: '',
            Cell: ({ row }: any) => {
              const { downloadDate } = row?.original;
              return <>{downloadDate && dayjs(downloadDate).format(DATE_FORMAT_21)}</>;
            },
          },
        ],
      },
    ],
    [],
  );

  const renderColumns = (type: string) => {
    if (type === TABLE_PRODUCTS_STATUS[0]) {
      return columnsVariation;
    }
    if (type === TABLE_PRODUCTS_STATUS[1]) {
      return columnsCourse;
    }
    if (type === TABLE_PRODUCTS_STATUS[2]) {
      return columnsTargetCampaign;
    }
    return columnsApplied;
  };

  return (
    <>
      {!isEmpty(data) ? (
        <TableCommon
          classTable={`custom-table-products ${className}`}
          data={data}
          columns={renderColumns(type)}
          isNotShowSort
          bordered={false}
          getHeaderProps={(column: any) => {
            return {
              className: `text-white d-flex align-item-center bg-dark ${
                includes(['productName', 'courseName', 'campaignName'], column.id)
                  ? 'text-start'
                  : 'text-center'
              }`,
            };
          }}
          getCellProps={(column) => {
            return {
              className: `text-break ${
                includes(['productName', 'courseName', 'campaignName'], column.column.id)
                  ? 'text-start'
                  : 'text-center'
              }`,
            };
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
}
