import brAxios from 'Api/Interceptors';

interface InputVariationGetData {
  artistId?: string | number | null;
  titleId?: string | number | null;
  titleStatus?: string | number | null;
  fromDate?: any;
  toDate?: any;
  recordCompanyId?: string | number | null;
  progressVariationType?: string;
}

interface InputVariationGetCampaignsData {
  artistId?: string | number | null;
  titleId?: string | number | null;
  titleStatus?: string | number | null;
  fromDate?: any;
  toDate?: any;
  progressVariationType?: string;
  recordCompanyId?: string;
}

interface InputVariationSort {
  artistId?: string | number | null;
  titleId?: string | number | null;
  titleStatus?: string | number | null;
  fromDate?: any;
  toDate?: any;
  sort?: string | null;
  order?: string | null;
  recordCompanyId?: string | number | null;
  progressVariationType?: string;
}

interface InputVariationCampaignsSort {
  artistId?: string | number | null;
  titleId?: string | number | null;
  titleStatus?: string | number | null;
  fromDate?: any;
  toDate?: any;
  sort?: string | null;
  order?: string | null;
  progressVariationType?: string;
  recordCompanyId?: string;
}

interface InputExportExcel {
  artistId?: string | number | null;
  titleId?: string | number | null;
  titleStatus?: string | number | null;
  fromDate?: any;
  toDate?: any;
  sort?: string | null;
  order?: string | null;
  recordCompanyId?: string | number | null;
  progressVariationType?: string;
}

// interface InputManager {
//   artistList?: number | string | null;
// }

export const ProcessBoardApi = {
  //GET
  getData: ({
    artistId,
    titleId,
    titleStatus,
    fromDate,
    toDate,
    recordCompanyId,
    progressVariationType,
  }: InputVariationGetData) => {
    const response = brAxios.get(
      `/record-company/progress-board/variations/get-data?artistId=${artistId ?? ''}&titleId=${
        titleId ?? ''
      }&titleStatus=${titleStatus ?? ''}&fromDate=${fromDate ?? ''}&toDate=${
        toDate ?? ''
      }&progressVariationType=${progressVariationType ?? ''}${
        recordCompanyId ? `&recordCompanyId=${recordCompanyId}` : ''
      }`,
    );
    return response;
  },
  getCampaignsData: ({
    artistId,
    titleId,
    titleStatus,
    fromDate,
    toDate,
    progressVariationType,
    recordCompanyId,
  }: InputVariationGetCampaignsData) => {
    const response = brAxios.get(
      `/record-company/progress-board/campaigns/join-date?artistId=${artistId ?? ''}&titleId=${
        titleId ?? ''
      }&titleStatus=${titleStatus ?? ''}&fromDate=${fromDate ?? ''}&toDate=${
        toDate ?? ''
      }&progressVariationType=${progressVariationType ?? ''}&recordCompanyId=${
        recordCompanyId ?? ''
      }`,
    );
    return response;
  },
  // Sort
  getSortData: ({
    fromDate,
    toDate,
    sort,
    order,
    progressVariationType,
    artistId,
    titleId,
    titleStatus,
    recordCompanyId,
  }: InputVariationSort) => {
    const response = brAxios.get(
      `/record-company/progress-board/variations/get-data?artistId=${artistId ?? ''}&titleId=${
        titleId ?? ''
      }&titleStatus=${titleStatus ?? ''}&fromDate=${fromDate ?? ''}&toDate=${
        toDate ?? ''
      }&progressVariationType=${progressVariationType ?? ''}&sort=${sort ?? ''}&order=${
        order ?? ''
      }${recordCompanyId ? `&recordCompanyId=${recordCompanyId}` : ''}`,
    );
    return response;
  },
  getSortCampaignsData: ({
    artistId,
    titleId,
    titleStatus,
    fromDate,
    toDate,
    sort,
    order,
    progressVariationType,
    recordCompanyId,
  }: InputVariationCampaignsSort) => {
    const response = brAxios.get(
      `/record-company/progress-board/campaigns/join-date?artistId=${artistId ?? ''}&titleId=${
        titleId ?? ''
      }&titleStatus=${titleStatus ?? ''}&fromDate=${fromDate ?? ''}&toDate=${
        toDate ?? ''
      }&progressVariationType=${progressVariationType ?? ''}&sort=${sort ?? ''}&order=${
        order ?? ''
      }&recordCompanyId=${recordCompanyId ?? ''}`,
    );
    return response;
  },
  //Excel
  ExportGetDataExcel: (params: InputExportExcel) => {
    const response = brAxios.get('/record-company/progress-board/variations/get-data/export', {
      params,
      responseType: 'blob',
    });
    return response;
  },
  ExportCampaignExcel: (params: InputExportExcel) => {
    const response = brAxios.get('/record-company/progress-board/campaigns/join-date/export', {
      params,
      responseType: 'blob',
    });
    return response;
  },
  //Manager
  ManagerByArtist: (params: any) => {
    const response = brAxios.get(`/record-company/managers`, {
      params,
    });
    return response;
  },
};
