import axios from 'axios';
import DateSelector from 'Components/Common/DateSelector';
import Search from 'Components/Common/Search/Search';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { IPullDownItem } from 'Types/Common';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import {
  handleGetIds,
  handleSearchArtist,
  handleSearchTitle,
  ItemSearch,
  PageQuery,
  transformKey,
  updateQuery,
} from 'Utils/Search';

export default function SearchApproval(): JSX.Element {
  const [codeProducts, setCodeProducts] = useState('');
  const [startDate, setStartDates] = useState<any>(new Date());

  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistId: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
  });
  const [queryObj, setQueryObj] = useState({
    queryArtist: query.queryArtist,
    queryTitle: query.queryTitle,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageObj, setPageObj] = useState<PageQuery>({
    pageArtist: 1,
    pageTitle: 1,
    pageVariation: 1,
  });

  const [res, setRes] = useState<Array<ItemSearch>>([]);

  const handleGets = ({ queryArtist = '', queryTitle = '', artistId = '' }) => {
    const artistsR = handleSearchArtist({ queryArtist, page: pageObj.pageArtist });
    const titlesR = handleSearchTitle({ queryTitle, artistId, page: pageObj.pageTitle });
    return axios.all([artistsR, titlesR]);
  };

  const handleSearchAll = async ({ queryArtist = '', queryTitle = '', artistId = '' }) => {
    setLoading(true);

    const res = await handleGets({ queryArtist, queryTitle, artistId });

    const artists = res[0]?.data?.data?.map((artist: IPullDownItem) => ({
      label: artist.localName,
      value: artist.id,
    }));
    const titles = res[1].data.data.map((title: IPullDownItem) => ({
      label: title.name,
      value: title.id,
    }));
    const dataSource = [artists, titles];

    setRes(dataSource);
    setLoading(false);
  };

  const handleEnter = useCallback(
    (type: string) => {
      const ids = res.map((item: any) => handleGetIds(item));

      if (type === 'queryArtist') {
        const artistIds = query[type] ? (ids[0] ? ids[0] : 'no-data') : '';
        setQuery({ artistIds: artistIds, page: 1 });
      }

      if (type === 'queryTitle') {
        const titleIds = query[type] ? (ids[1] ? ids[1] : 'no-data') : '';
        setQuery({ titleIds: titleIds, page: 1 });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [res, query, loading],
  );

  const handleClickItem = useCallback(
    (id: string, type: string) => {
      if (type === 'queryArtist') {
        setQuery({ artistId: id, artistIds: id, page: 1 });
      }

      if (type === 'queryTitle') {
        setQuery({ titleIds: id, page: 1 });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouceRequest = useCallback((value) => updateQuery(value, setQueryObj), []);

  useEffect(() => {
    debouceRequest({
      queryArtist: query.queryArtist,
      queryTitle: query.queryTitle,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.queryArtist, query.queryTitle]);

  useEffect(() => {
    setLoading(true);
    handleSearchAll(transformKey(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryObj]);

  useEffect(() => {
    let validQuery = { ...query } as any;
    if (!query.queryArtist) {
      validQuery = {
        artistIds: undefined,
        artistId: undefined,
        queryArtist: undefined,
        queryTitle: undefined,
        titleIds: undefined,
      };

      setQuery(validQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.queryArtist]);

  useEffect(() => {
    let validQuery = { ...query } as any;
    if (!query.queryArtist) {
      validQuery = {
        artistIds: undefined,
        queryArtist: undefined,
        artistId: undefined,
        queryTitle: undefined,
        titleIds: undefined,
      };

      setQuery(validQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.queryArtist]);

  return (
    <>
      <Col md="2" className="px-1 ps-3">
        <Search
          label="アーティスト"
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          classLabel="px-0 fs-11"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryArtist"
          onEnter={() => handleEnter('queryArtist')}
          onClickResult={handleClickItem}
          options={res[0]}
          loading={loading}
        />
      </Col>
      <Col md="2" className="px-1 ps-3">
        <Search
          label="タイトル"
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          classLabel="px-0 fs-11"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryTitle"
          onEnter={() => handleEnter('queryTitle')}
          onClickResult={handleClickItem}
          options={res[1]}
          loading={loading}
        />
      </Col>
      <Col md="2" className="px-1 ps-3">
        <Form.Group className="w-100">
          <Form.Label className="px-0 fs-11" column md={4}>
            品番
          </Form.Label>
          <Col md={12} className={`px-0 position-relative`}>
            <Form.Control
              value={codeProducts}
              type="text"
              onChange={(e) => setCodeProducts(e.target.value)}
              className="input-search"
            />
          </Col>
        </Form.Group>
      </Col>
      <Col md="2" className="px-1 ps-3">
        <Form.Group className="w-100">
          <Form.Label className="px-0 fs-11" column md={4}>
            品番
          </Form.Label>
          <Col md={12} className={`px-0 position-relative`}>
            <DateSelector
              selected={startDate}
              dateFormat="yyyy年MM月dd"
              showTime={false}
              onChange={(date) => setStartDates(date)}
            />
          </Col>
        </Form.Group>
      </Col>
    </>
  );
}
