export const RoleNames = [
  'RECORD_COMPANY_MASTER',
  'RECORD_COMPANY_MANAGER',
  'BOOTROCK_COMPANY_MASTER',
  'BOOTROCK_COMPANY_MANAGER',
  'EC_COMPANY_MASTER',
  'SALE_CHANNEL_MASTER',
  'SALE_CHANNEL_MANAGER',
];

export const RolesRecord = [
  'RECORD_COMPANY_MASTER',
  'RECORD_COMPANY_MANAGER',
  'RECORD_COMPANY_MANAGER_HIGH',
  'RECORD_COMPANY_MANAGER_LOW',
  'SALE_CHANNEL_MASTER',
  'SALE_CHANNEL_MANAGER',
  'EC_COMPANY_MASTER',
  'EC_COMPANY_MANAGER',
];

export const ROLE_RECORD_MENU = {
  RCCompMaster: 'RECORD_COMPANY_MASTER',
  RCCompManager: 'RECORD_COMPANY_MANAGER',
  RCCompManagerHigh: 'RECORD_COMPANY_MANAGER_HIGH',
  RCCompManagerLow: 'RECORD_COMPANY_MANAGER_LOW',
  SALEChanelMaster: 'SALE_CHANNEL_MASTER',
  SALEChanelManager: 'SALE_CHANNEL_MANAGER',
  ECCompMaster: 'EC_COMPANY_MASTER',
  ECCompManager: 'EC_COMPANY_MANAGER',
};

export const ROLE_RECORD_HEADER = {
  RCCompMaster: 'RECORD_COMPANY_MASTER',
  RCCompManager: 'RECORD_COMPANY_MANAGER',
  RCCompManagerHigh: 'RECORD_COMPANY_MANAGER_HIGH',
  RCCompManagerLow: 'RECORD_COMPANY_MANAGER_LOW',
  ECCompMaster: 'EC_COMPANY_MASTER',
  ECCompManager: 'EC_COMPANY_MANAGER',
  SALEChanelMaster: 'SALE_CHANNEL_MASTER',
  SALEChanelManager: 'SALE_CHANNEL_MANAGER',
  BRMaster: 'BOOTROCK_COMPANY_MASTER',
  BRManager: 'BOOTROCK_COMPANY_MANAGER',
};

export const RolesSystem = {
  RCCompMaster: 'RECORD_COMPANY_MASTER',
  RCCompManagerHigh: 'RECORD_COMPANY_MANAGER_HIGH',
  RCCompManagerLow: 'RECORD_COMPANY_MANAGER_LOW',
  ECCompMaster: 'EC_COMPANY_MASTER',
  ECCompManager: 'EC_COMPANY_MANAGER',
  ECCompEventApp: 'EC_COMPANY_EVENT_APP',
  SALEChanelMaster: 'SALE_CHANNEL_MASTER',
  SALEChanelManager: 'SALE_CHANNEL_MANAGER',
  BRMaster: 'BOOTROCK_COMPANY_MASTER',
  BRManager: 'BOOTROCK_COMPANY_MANAGER',
};
