import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { map } from 'lodash';
import { ColSpec } from 'Components/Common/Form/form';
import { ICheckBox } from 'Types/Common';
import { UseFormRegisterReturn } from 'react-hook-form';
import MessageError from 'Components/Common/MessageError';
import { labelRequire } from 'Components/Common/Form/FormLabel';
import HintTextField from 'Components/Common/Form/HintTextField';
interface Props {
  id: string;
  name?: string;
  labelForm?: string | null;
  classForm?: string | null;
  classLabel?: string;
  errorMessage?: string;
  options: ICheckBox[];
  labelMd?: ColSpec;
  colMd: ColSpec;
  onChange?: (arrayValue: ICheckBox[]) => void;
  value?: string | number | undefined | null;
  onValue?: (value: string | number, index?: number) => void;
  register?: UseFormRegisterReturn;
  disabled?: boolean;
  isReview?: boolean;
  colClass?: string;
  classBoxErr?: string;
  classOption?: string;
  checkboxClass?: string;
  onClickError?: () => void;
  hintText?: string;
}
function GroupCheckBox(props: Props): JSX.Element {
  const {
    id,
    name,
    labelForm,
    classForm,
    colClass,
    errorMessage,
    options,
    labelMd,
    colMd,
    classLabel,
    onChange,
    value,
    onValue,
    register,
    disabled = false,
    isReview = false,
    classBoxErr,
    classOption = '',
    checkboxClass,
    onClickError,
    hintText = '',
  } = props;

  const handleOnchange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const checked = e.currentTarget.checked;
    let newOptions = null;
    if (!onChange) {
      return;
    }
    newOptions = map(options, (option, optionKey) => ({
      ...option,
      checked: optionKey === index ? checked : false,
    }));
    return onChange(newOptions);
  };

  return (
    <Form.Group as={Row} className={`${classForm ?? ''}`}>
      {labelForm && (
        <Form.Label column md={labelMd}>
          {labelRequire(labelForm, classLabel)}
        </Form.Label>
      )}
      <Col md={colMd} className={`align-self-center ${colClass} `}>
        <div className={`d-flex w-100 box-checkBox flex-wrap ${checkboxClass}`}>
          {options &&
            options.map((item, index) => {
              return (
                <Form.Check
                  {...register}
                  key={index}
                  inline
                  label={item.label}
                  name={name || 'check-box-group'}
                  type="radio"
                  value={item.value}
                  isValid={item.subLabel ? true : false}
                  checked={value === 0 || value ? value === item.value : item.checked}
                  onChange={(e) => {
                    if (isReview) return;
                    onValue ? onValue(item.value ?? '', index) : handleOnchange(e, index);
                  }}
                  id={`${id}-${index}`}
                  feedback={item.subLabel}
                  className={`check-box-ct mb-0 ${classOption}`}
                  disabled={disabled || item.disabled}
                />
              );
            })}
        </div>
        <HintTextField hintText={hintText} />
        <div className={classBoxErr} onClick={onClickError} aria-hidden="true">
          <MessageError message={errorMessage} />
        </div>
      </Col>
    </Form.Group>
  );
}

export default GroupCheckBox;
