import React from 'react';
import { Button } from 'react-bootstrap';

const GroupButton = ({ option, handleAddTextToContent }: any) => {
  //   const [textAdd, setTextAdd] = useState('');

  const groupOptions = [
    { name: '注文全キャンセル通知' },
    { name: '注文一部キャンセル通知' },
    { name: 'お問い合わせメール_お客様宛・指示用' },
    { name: '購入完了通知' },
    { name: '抽選購入申込完了通知' },
    { name: '抽選購入当選通知' },
    { name: 'お問い合わせメール_EC宛・指示用' },
  ];

  const handleClickBtn = (btnText: any) => {
    handleAddTextToContent(btnText);
  };
  return (
    <>
      {option == groupOptions[0].name ? (
        <>
          <Button
            className="btn btn-dark d-flex border-radius-right-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            購入者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__PURCHASE_CONTENT__]]')}
          >
            注文内容
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__PURCHASE_ITEMS__]]')}
          >
            注文商品明細
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__SITE_TITLE__]]')}
          >
            サイトタイトル
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__CONTACT_LINK__]]')}
          >
            お問い合わせページのリンク
          </Button>
        </>
      ) : option == groupOptions[1].name ? (
        <>
          <Button
            className="btn btn-dark d-flex border-radius-right-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            購入者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__PURCHASE_CONTENT__]]')}
          >
            注文内容
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__PURCHASE_ITEMS__]]')}
          >
            注文商品明細
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__SITE_TITLE__]]')}
          >
            サイトタイトル
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__CONTACT_LINK__]]')}
          >
            お問い合わせページのリンク
          </Button>
        </>
      ) : option == groupOptions[2].name ? (
        <>
          <Button
            className="btn btn-dark d-flex border-radius-right-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            購入者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__CONTACT_DETAIL__]]')}
          >
            お問い合わせ明細
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option == groupOptions[3].name ? (
        <>
          <Button
            className="btn btn-dark d-flex border-radius-right-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            購入者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__ORDER_DETAILS__]]')}
          >
            注文内容
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__METHOD_PAYMENT__]]')}
          >
            支払い方法
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__SITE_TITLE__]]')}
          >
            サイトタイトル
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option == groupOptions[4].name ? (
        <>
          <Button
            className="btn btn-dark d-flex ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            購入者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__APPLICATION_DETAILS__]]')}
          >
            申込内容
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__METHOD_PAYMENT__]]')}
          >
            支払い方法
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__SITE_TITLE__]]')}
          >
            サイトタイトル
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option == groupOptions[5].name ? (
        <>
          <Button
            className="btn btn-dark d-flex border-radius-right-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            購入者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__ORDER_DETAILS__]]')}
          >
            ご当選内容
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__APPLICATION_DETAILS__]]')}
          >
            申込内容
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__METHOD_PAYMENT__]]')}
          >
            支払い方法
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__SITE_TITLE__]]')}
          >
            サイトタイトル
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option == groupOptions[6].name ? (
        <>
          <Button
            className="btn btn-dark d-flex border-radius-right-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__CONTACT_DETAIL__]]')}
          >
            お問い合わせ明細
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default GroupButton;
