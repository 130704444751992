import React from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
  Label,
} from 'recharts';

const data = [
  { x: 100, y: 200, z: 200 },
  { x: 120, y: 100, z: 260 },
  { x: 170, y: 300, z: 400 },
  { x: 140, y: 250, z: 280 },
  { x: 150, y: 400, z: 500 },
  { x: 110, y: 280, z: 200 },
];

const data1 = [
  { x: 110, y: 200, z: 200 },
  { x: 130, y: 100, z: 260 },
  { x: 140, y: 300, z: 400 },
  { x: 150, y: 250, z: 280 },
  { x: 160, y: 400, z: 500 },
  { x: 170, y: 280, z: 200 },
];

const PerformanceChart = (): JSX.Element => {
  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <ScatterChart
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#fff" fill="#E6E6E6" />
          <XAxis type="number" dataKey="x" tickCount={9} tickLine={false}>
            <Label
              value="ひとり当たり購入数"
              position="insideBottomRight"
              offset={20}
              dx={30}
              dy={30}
            />
          </XAxis>
          <YAxis
            interval={0}
            type="number"
            dataKey="y"
            tickCount={9}
            tickLine={false}
            tickFormatter={(e) => e || ''}
          >
            <Label value="Gift率" position="top" offset={20} />
          </YAxis>

          <Tooltip cursor={{ strokeDasharray: '3 3' }} />
          <Scatter name="A school" data={data} fill="#FD1111">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={'#FD1111'} />
            ))}
          </Scatter>
          <Scatter name="B school" data={data1} fill="#010101">
            {data1.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={'#010101'} />
            ))}
          </Scatter>
        </ScatterChart>
      </ResponsiveContainer>
    </>
  );
};

export default PerformanceChart;
