import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';

interface ButtonCountDownProps {
  handleClick: () => void;
  seconds?: number;
  title: string;
  isCountDown: (isCount: boolean) => void;
  disabled?: boolean;
  className?: string;
}

const ButtonCountDown = ({
  handleClick,
  seconds = 5,
  title,
  isCountDown,
  disabled,
  className,
}: ButtonCountDownProps): JSX.Element => {
  const [timeLeft, setTimeLeft] = useState(seconds);
  const [isStart, setIsStart] = useState(false);
  const intervalRef = useRef<any>();

  useEffect(() => {
    if (isStart) {
      isCountDown(true);
      intervalRef.current = setInterval(() => {
        setTimeLeft((t) => t - 1);
      }, 1000);
      return () => clearInterval(intervalRef.current);
    }
  }, [isStart]);

  useEffect(() => {
    if (timeLeft <= 0) {
      clearInterval(intervalRef.current);
      setIsStart(false);
      setTimeLeft(seconds);
      isCountDown(false);
    }
  }, [timeLeft]);

  const debounceFunc = debounce(() => handleClick(), +seconds * 1000);

  const handleOnClick = () => {
    if (!isStart) {
      setIsStart(true);
      debounceFunc();
    }
  };

  return (
    <Button
      variant="primary"
      onClick={handleOnClick}
      disabled={disabled}
      className={className || ''}
    >
      {title}
      {isStart && timeLeft ? <span className="ps-2">{`( ${timeLeft} )`}</span> : <></>}
    </Button>
  );
};

export default ButtonCountDown;
