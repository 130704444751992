/* eslint-disable no-irregular-whitespace */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import { DATE_FORMAT_8 } from 'Constant/Date';
import { formatFixedDecimalAndNumb, formatPrice } from 'Utils/Numbers';
interface Props {
  startDate: string;
  endDate: string;
  createdAt: string;
  paymentDate: string;
  transferAmount: number;
  totalAmount: number;
  chargeFee: number;
  platformFee: number;
  carriedAmount: number;
  nextPaymentAmount: number;
  tax: number;
}

export default function DetailBox({
  startDate,
  endDate,
  paymentDate,
  transferAmount,
  carriedAmount,
}: Props): JSX.Element {
  const startDateJa = dayjs(startDate?.substring(0, 10)).format(DATE_FORMAT_8);
  const endDateJa = dayjs(endDate?.substring(0, 10)).format(DATE_FORMAT_8);
  const paymentDateJa = dayjs(paymentDate?.substring(0, 10)).format(DATE_FORMAT_8);

  return (
    <Row className="shadow-sm border mt-4 ml-30px mr-30px">
      <Col className="mt-8px mb-8px" sm={4}>
        <div className="border-bottom-ec mr-6px pb-6px">
          対象期間　{startDateJa}　～　{endDateJa}　
        </div>
        <div className="d-flex flex-column align-items-center mt-2">
          <div>
            <div>
              <div className="mb-8px">支払日</div>
              <div className="fs-18 font-bold mb-8px">{paymentDateJa}</div>
            </div>
            <div>
              <div>支払金額</div>
              <div className="fs-26 font-bold d-inline border-bottom-2px-ec pl-12px pr-12px ml-minus-12px">
                ¥{transferAmount ? formatPrice(formatFixedDecimalAndNumb(transferAmount, 2)) : 0}
              </div>
            </div>
          </div>
        </div>
      </Col>
      {carriedAmount > 0 && (
        <Col className="mt-8px mb-8px border-left-ec" sm={2}>
          <div className="d-flex pt-35px justify-content-between h-100">
            <div>その他</div>
            <div>{formatPrice(carriedAmount)}</div>
          </div>
        </Col>
      )}
    </Row>
  );
}
