/* eslint-disable no-irregular-whitespace */
import Filter from 'Components/Common/Filter/Filter';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import React, { useEffect, useMemo, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import DetailBox from './PurchaseSummaryDetailBox';
import { Cell } from 'react-table';
import { ECApis } from 'Datasource/EC';
import { useParams } from 'react-router-dom';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';
// import dayjs from 'dayjs';
// import { DATE_FORMAT_8 } from 'Constant/Date';
import { PER_PAGE_EC } from 'Constant';
import { formatFixedDecimalAndNumb, formatPrice, formatPriceFloat } from 'Utils/Numbers';

import { useAppSelector } from 'App/Store';
import TableEC from 'Components/Common/Table/TableEC';

interface IValue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export enum PaymentStatus {
  NOT_PAYMENT = 0,
  PAID = 1,
  CANCEL = 2,
  ERROR = 3,
}

export default function PurchaseSummaryDetail(): JSX.Element {
  const [query] = useQueryParams({
    page: NumberParam,
    year: NumberParam,
    month: NumberParam,
    companyName: StringParam,
  });
  const [activePage, setActivePage] = useState(query.page ?? 1);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const { openMessageError } = useMessage();
  const [transferDetail, setTransferDetail] = useState<any>();
  const [transferSummary, setTransferSummary] = useState<any>();
  const [transferTotal, setTransferTotal] = useState<number>(0);
  const {
    hashPassword: { hashPassword },
  } = useAppSelector((state) => state);
  const { id }: { id: string } = useParams();

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'bg-white border-none',
        columns: [
          {
            Header: '行番',
            accessor: 'purchaseId',
            className: 'tr-radius_start',
            width: '50',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <p className="text-truncate" title="false">
                  {(row.original?.currentPage - 1) * 50 + row.index + 1}
                </p>
              );
            },
          },
          {
            Header: 'レーベル',
            accessor: 'label',
            width: '150',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <p className="text-left text-truncate" title="false">
                  {row.original?.label}
                </p>
              );
            },
          },
          {
            Header: '品番',
            accessor: 'productCode',
            width: '150',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-truncate" title="false">
                  {row.original?.productCode}
                </div>
              );
            },
          },
          {
            Header: 'アーティスト',
            accessor: 'artistName',
            width: '200',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-left text-truncate" title="false">
                  {row.original?.artistName}
                </div>
              );
            },
          },
          {
            Header: 'タイトル',
            accessor: 'titleName',
            width: '200',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-left text-truncate" title="false">
                  {row.original?.titleName}
                </div>
              );
            },
          },
          {
            Header: 'バリエーション',
            accessor: 'variationName',
            width: '150',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-left text-truncate" title="false">
                  {row.original?.variationName}
                </div>
              );
            },
          },
        ],
      },
      {
        Header: '仕入',
        accessor: 'group1',
        className: 'border-top-left-radius-6px',
        columns: [
          {
            Header: '数量',
            accessor: 'amount',
            width: '80',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div
                  className={`text-right text-truncate ${
                    row.original?.amount !== null && row.original?.amount < 0 ? 'text-danger' : ''
                  }`}
                  title="false"
                >
                  {row.original?.amount !== null && row.original?.amount}
                </div>
              );
            },
          },
          {
            Header: '納入単価',
            accessor: 'purchasePrice',
            width: '100',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div
                  className={`text-right text-truncate ${
                    row.original?.purchasePrice !== null && row.original?.purchasePrice < 0
                      ? 'text-danger'
                      : ''
                  }`}
                  title="false"
                >
                  {row.original?.purchasePrice !== null &&
                    formatPrice(formatFixedDecimalAndNumb(row.original?.purchasePrice, 2))}
                </div>
              );
            },
          },
          {
            Header: '合計',
            accessor: 'totalPrice',
            width: '100',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div
                  className={`text-right ${
                    row.original?.totalPrice !== null && row.original?.totalPrice < 0
                      ? 'text-danger'
                      : ''
                  }`}
                  title="false"
                >
                  {row.original?.totalPrice !== null &&
                    formatPrice(formatFixedDecimalAndNumb(row.original?.totalPrice, 2))}
                </div>
              );
            },
          },
        ],
      },
      {
        Header: '控除',
        accessor: 'group2',
        className: 'border-top-right-radius-6px',
        columns: [
          {
            Header: '著作権使用料単価',
            accessor: 'janCode',
            width: '130',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div
                  className={`text-right ${
                    row.original?.royalty !== null && row.original?.royalty < 0 ? 'text-danger' : ''
                  }`}
                  title="false"
                >
                  {row.original?.royalty === null ? '' : formatPriceFloat(row.original?.royalty)}
                </div>
              );
            },
          },
          {
            Header: '著作権使用料',
            accessor: 'royalty',
            width: '120',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div
                  className={`text-right ${
                    row.original?.totalRoyalty !== null && row.original?.totalRoyalty < 0
                      ? 'text-danger'
                      : ''
                  }`}
                  title="false"
                >
                  {row.original?.totalRoyalty === null
                    ? ''
                    : formatPrice(formatFixedDecimalAndNumb(row.original?.totalRoyalty, 2))}
                </div>
              );
            },
          },
        ],
      },
      {
        Header: '',
        accessor: 'empty',
        className: 'bg-white border-none',
        columns: [
          {
            Header: '支払額',
            accessor: 'totalPaidAmount',
            className: 'tr-radius_end',
            width: '100',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div
                  className={`text-right ${
                    row.original?.totalPaidAmount !== null && row.original?.totalPaidAmount < 0
                      ? 'text-danger'
                      : ''
                  }`}
                  title="false"
                >
                  {row.original?.totalPaidAmount !== null &&
                    formatPrice(formatFixedDecimalAndNumb(row.original?.totalPaidAmount, 2))}
                </div>
              );
            },
          },
        ],
      },
    ],
    [],
  );

  const fetchPaymentDetail = async () => {
    setIsloading(true);
    try {
      const response = await ECApis.getPaymentSummaryDetail(
        id,
        query.year,
        query.month,
        activePage,
        hashPassword,
      );
      const { data } = response.data;
      const purchaseData = data.listPurchaseDetail.map((item: any) => {
        return { ...item, currentPage: data.meta.currentPage };
      });
      setTransferTotal(data?.meta?.itemCount);
      setTransferSummary(data?.summary);
      setTransferDetail(purchaseData);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    fetchPaymentDetail();
  }, [activePage]);

  return (
    <Container fluid>
      <SpinnerComponent isLoading={isLoading} />
      <Filter filterClass={`shadow-sm d-flex justify-content-between`}>
        <div className="d-flex">
          <p>支払明細</p>
          <p className="font-bold">【{query?.companyName}】</p>
        </div>
        <div className="d-flex">
          <div>伝票番号 {transferSummary?.orderNumber}</div>
          <div className="ml-40px">
            発行日　
            {/* {transferSummary?.date
              ? dayjs(transferSummary?.date?.substring(0, 10)).format(DATE_FORMAT_8)
              : null} */}
            {transferSummary?.date ? transferSummary?.date : null}
          </div>
        </div>
      </Filter>
      {transferSummary && <DetailBox props={transferSummary} />}
      <Row className="space-between mt-3 ml-30px mr-30px">
        <div className="w-100 pr-0px">
          {transferDetail && (
            <PaginationComponent
              activePage={activePage}
              total={transferTotal}
              perPage={PER_PAGE_EC}
              onClick={(newPage) => setActivePage(newPage)}
            />
          )}
        </div>
      </Row>
      <Row className="mt-3 ml-18px mr-30px">
        {transferDetail && (
          <TableEC
            classTable="purchase-summary-table pl-0px"
            data={transferDetail || []}
            columns={columns}
            getHeaderProps={() => {
              return {
                className: `text-white d-flex align-item-center bg-dark text-center`,
              };
            }}
            isNotShowSort={true}
          />
        )}
      </Row>
    </Container>
  );
}
