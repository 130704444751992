import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NFTState {
  artistId?: string;
}

const initialState: NFTState = {
  artistId: '',
};

export const nftSlice = createSlice({
  name: 'nft',
  initialState,
  reducers: {
    setNFTFilter: (state, data: PayloadAction<{ artistId: string }>) => {
      const { payload } = data;
      return { ...payload };
    },
  },
});

export const { setNFTFilter } = nftSlice.actions;
export default nftSlice.reducer;
