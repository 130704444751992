import { ROLES } from 'Components/Common/Permission';

export const getRoleName = (role: string): ROLES => {
  const _role = role.split('_');
  return _role[_role.length - 1].toUpperCase() as ROLES;
};

export function getBool(val: string | undefined | null | number): boolean {
  let result = false;
  if (!val) return result;
  result = !!JSON.parse(String(val).toLowerCase());
  return result;
}
