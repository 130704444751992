import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { DatePickerProps } from 'Components/Common/DatePicker/datepicker';

export default function BRDatePicker({
  dateClass,
  inputClass,
  inputPlaceholder,
}: DatePickerProps): JSX.Element {
  // eslint-disable-next-line
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div className={dateClass}>
      <DatePicker
        className={`form-control ${inputClass}`}
        selected={null}
        // selected={startDate}
        onChange={(date: Date) => setStartDate(date)}
        placeholderText={inputPlaceholder}
      />
    </div>
  );
}
