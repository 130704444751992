import brAxios from 'Api/Interceptors';
import { AxiosResponse } from 'axios';

export interface ParamsGetShops {
  page: number;
  limit: number;
  name?: string;
}
export interface ParamsUpdateShops {
  new_shops: string[];
  shop_delete_ids: number[];
}

const CancelTokenGetShop = brAxios.CancelToken;
let sourceGetShops: any;

export const ShopAPIs = {
  getShops: (params: ParamsGetShops): Promise<AxiosResponse> => {
    // cancel axios request previous
    if (typeof sourceGetShops != typeof undefined) {
      sourceGetShops.cancel('Operation canceled due to new request.');
    }

    sourceGetShops = CancelTokenGetShop.source();

    return brAxios.get(
      `/ec-site/shop?page=${params.page ?? ''}&limit=${params.limit ?? ''}&name=${
        params.name ? encodeURIComponent(params.name) : ''
      }`,
      { cancelToken: sourceGetShops.token },
    );
  },

  updateShops: (data: ParamsUpdateShops): Promise<AxiosResponse> => {
    return brAxios.post('/ec-site/shop/update', data);
  },
};
