import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import BoxForm from 'Components/Common/Form/BoxForm';
import { Button, Col, Form, Row } from 'react-bootstrap';
import SongTable from 'Components/Page/Title/Variation/SongTable';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import DropzoneDigitalPackage from 'Components/Page/Title/Variation/DropzoneDigitalPackage';
import { pullAt, filter, map, isEmpty, find } from 'lodash';
import { useAppDispatch, useAppSelector } from 'App/Store';
import { Song, Video } from 'Types/App/title';
import {
  JmdDataGenre,
  setCaculateFee,
  setVariationVideos,
  setVariationVideoSongs,
} from 'App/Features/Title';
import { calcMb, isAfterDownload, isReviewTitle } from 'Utils/File';
import {
  checklNumberRemainCapacity,
  getNumberRemainSong,
  handleMove,
  IGenreData,
  randomId,
} from 'Utils/Title';
import {
  INCLUDE_COPY_RIGHT,
  INCLUDE_JARSAC_CONTENT,
  LINK_JMD_TYPE_CONTENT,
  TYPE_MOVE,
  VIDEO_TYPES,
} from 'Constant/Title';
import { CaretDownSquareFill, CaretUpSquareFill } from 'react-bootstrap-icons';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { TYPE_MEDIA } from 'Constant';
import { UploadMediaApi } from 'Datasource/UploadMedia';
import useMessage from 'Hooks/useMessage';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import ModalComponent from 'Components/Common/Modal';
import GroupCheckBox from 'Components/Common/Form/GroupCheckBox';
import { formatPrice } from 'Utils/Numbers';
import { updateQuery } from 'Utils/Search';
import {
  MESSAGE_UPLOAD_FAIL,
  getNumChunk,
  getVideoDurationFormatted,
  uploadMultipartFile,
} from 'Utils/UploadFile';
import { Link } from 'react-router-dom';
import FormGroupSelect from 'Components/Common/Form/FormGroupSelect';
import { TitleApi } from 'Datasource/TitleApi';

const BoxVideo = (props: any): JSX.Element => {
  const { listError = [], variation } = props;
  const dispatch = useAppDispatch();
  const { titleForm, triggerMedia } = useAppSelector((state) => state.title);

  const videoList: any = useMemo(() => {
    return variation?.videos ?? [];
  }, [variation?.videos]);
  const nameVideos = useMemo(() => {
    return videoList.map((item: Video) => item.name);
  }, [videoList]);
  const numberRemainSong = getNumberRemainSong(titleForm, props.variationIndex);
  const [loading, setLoading] = useState<boolean>(false);
  const [visableModalDelete, setVisibleModalDelete] = useState<null | number | string>(null);
  const videosRef = useRef<any>([]);

  const [videoNames, setVideoNames] = useState<string[]>(nameVideos);
  const { openMessageError, openMessage } = useMessage();

  const [isDeleteSong, setIsDeleteSong] = useState<boolean>(false);
  const [isSwitchVideoType, setIsSwitchVideoType] = useState<boolean>(false);
  const [currentJmdVideoGenreIdValue, setCurrentJmdVideoGenreIdValue] = useState<number | null>(
    null,
  );
  const [currentJmdVideoGenreDetail, setCurrentJmdVideoGenreDetail] = useState<IGenreData | null>(
    null,
  );

  const initValueOption = {
    label: '',
    value: '',
  };

  const [linkJMDVideoValue, setLinkJMDVideoValue] = useState<{
    [key: string]: { [key: string]: string | number };
  }>({
    firstValue: initValueOption,
    secondValue: initValueOption,
    thirdValue: initValueOption,
    lastValue: initValueOption,
  });

  const [listJmdVideoGenreData, setListJmdVideoGenreData] = useState<JmdDataGenre[]>([]);

  const uploadVideo = async (video: File) => {
    try {
      setLoading(true);
      const { size, type } = video;
      const NUM_CHUNKS = getNumChunk(size);
      const form = {
        fileSize: size,
        mimeType: type,
        partNumber: NUM_CHUNKS,
        type: TYPE_MEDIA.DIGITAL_TOKUTEN,
        tokutenType: 1,
      };

      const urlMedia = await UploadMediaApi.upLoadMediaV2(form).then(async (res: any) => {
        const { listPart, uploadId, fileName } = res.data;
        return await uploadMultipartFile(video, listPart, uploadId, fileName);
      });

      if (!urlMedia) {
        openMessage({
          variant: 'error',
          message: MESSAGE_UPLOAD_FAIL,
        });
      } else {
        return urlMedia;
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
    return '';
  };

  const onChangeFiles = async (files: File[]) => {
    if (!isEmpty(files)) {
      const video = { file: files[0], songs: [] };
      if (checklNumberRemainCapacity(variation, video?.file.size)) return;
      const duration = await getVideoDurationFormatted(files[0] as File);

      const urlVideo = await uploadVideo(files[0]);

      const result = {
        introEc: '',
        url: urlVideo,
        type: 1,
        name: '',
        size: files[0].size,
        songs: [],
        includeCopyRightSong: null,
        videoType: null,
        mediaInformationFee: '',
        id: randomId(),
        duration: duration ?? '',
      };

      const tmpVideos = [...videoList, result];

      dispatch(setVariationVideos({ videos: tmpVideos, variationIndex: props.variationIndex }));
      if (variation?.includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE && variation.price)
        dispatch(setCaculateFee(true));
    }
  };

  const handleDeleteVideo = () => {
    const id = visableModalDelete || null;
    const index = videoList.findIndex((video: Video) => video.id === id);

    const tmpVideos = [...videoList];
    pullAt(tmpVideos, [index]);
    dispatch(setVariationVideos({ videos: tmpVideos, variationIndex: props.variationIndex }));
    setVisibleModalDelete(null);
    const tmpVideoNames = [...videoNames];
    tmpVideoNames.splice(index, 1);
    setVideoNames(tmpVideoNames);
    setLinkJMDVideoValue({
      firstValue: initValueOption,
      secondValue: initValueOption,
      thirdValue: initValueOption,
      lastValue: initValueOption,
    });
    if (variation?.includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE && variation.price)
      dispatch(setCaculateFee(true));
  };

  const handleMoveVideo = (index: number, type: string) => {
    const newVideo = handleMove(index, type, videoList) || videoList;
    dispatch(setVariationVideos({ videos: newVideo, variationIndex: props.variationIndex }));
  };

  const handleDeteleSong = useCallback(
    (item: Song, videoIndex: number) => {
      let newSongs: Song[] = videoList[videoIndex].songs ? [...videoList[videoIndex].songs] : [];
      newSongs = filter(newSongs, (itemSong: Song, songIndex: number) => songIndex !== item.index);
      newSongs = map(newSongs, (song: Song, index: number) => ({ ...song, index: index }));
      updateQuery(newSongs, () => {
        dispatch(
          setVariationVideoSongs({
            songs: newSongs,
            variationIndex: props.variationIndex,
            videoIndex,
          }),
        );
        if (variation?.includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE && variation.price)
          dispatch(setCaculateFee(true));
      });
    },
    [props.variationIndex, variation?.includeJasracContent, variation.price, videoList],
  );

  const handleMoveItemSong = (item: Song, type: string, videoIndex: number) => {
    const indexChange = item.index;
    let newSongs: Song[] = videoList[videoIndex].songs ? [...videoList[videoIndex].songs] : [];
    if (type === TYPE_MOVE[0]) {
      if (indexChange === 0) return;
      const tmp = newSongs[indexChange - 1];
      newSongs[indexChange - 1] = newSongs[indexChange];
      newSongs[indexChange] = tmp;
    }
    if (type === TYPE_MOVE[1]) {
      if (indexChange === newSongs.length - 1) return;
      const tmp = newSongs[indexChange + 1];
      newSongs[indexChange + 1] = newSongs[indexChange];
      newSongs[indexChange] = tmp;
    }
    newSongs = map(newSongs, (song: Song, index: number) => ({ ...song, index }));
    dispatch(
      setVariationVideoSongs({
        songs: newSongs,
        variationIndex: props.variationIndex,
        videoIndex,
      }),
    );
  };

  const handleAddSong = (videoIndex: number) => {
    const newSongs: Song[] = videoList[videoIndex].songs ? [...videoList[videoIndex].songs] : [];
    const maxIndex = newSongs.length;
    if (numberRemainSong === 0) return;
    newSongs.push({
      index: maxIndex,
    });
    dispatch(
      setVariationVideoSongs({
        songs: newSongs,
        variationIndex: props.variationIndex,
        videoIndex,
      }),
    );

    if (variation?.includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE && variation?.price) {
      dispatch(setCaculateFee(true));
    }
  };

  const handleChangeInput = (value: any, type: string, id: number | string) => {
    const tmpVideos = videoList.map((video: Video) =>
      video.id === id
        ? {
            ...video,
            [type]: value,
            songs:
              type === 'includeCopyRightSong' &&
              +value === INCLUDE_COPY_RIGHT.NOT_INCLUDE &&
              isDeleteSong
                ? []
                : isSwitchVideoType &&
                  type === 'videoType' &&
                  +value === VIDEO_TYPES.MUSIC_VIDEO &&
                  video.videoType !== INCLUDE_COPY_RIGHT.NOT_INCLUDE
                ? video?.songs?.slice(0, 1)
                : video.songs,
          }
        : video,
    );

    dispatch(setVariationVideos({ videos: tmpVideos, variationIndex: props.variationIndex }));
  };

  const handleImportSong = async (data: any, videoIndex: number) => {
    const currentSongs: Song[] = videoList[videoIndex].songs
      ? [...videoList[videoIndex].songs]
      : [];
    const addIndexData = data.map((el: any, index: number) => {
      const obj = { ...el, index: index };
      return obj;
    });

    const numberOfSong = numberRemainSong + +currentSongs.length;

    const dataAdd = await addIndexData.slice(0, numberOfSong);

    if (dataAdd?.length === 0 && numberOfSong === 0 && data.length > 0) {
      openMessage({
        variant: 'error',
        message: `楽曲登録数は${variation?.option?.maxSongCount}楽曲を超えているため、アップロードできません。`,
      });
    }

    dispatch(
      setVariationVideoSongs({
        //* if videoType is MV, only import the first song in dataAdd
        songs:
          videoList[videoIndex]?.videoType === VIDEO_TYPES.MUSIC_VIDEO
            ? [...dataAdd].slice(0, 1)
            : dataAdd,
        variationIndex: props.variationIndex,
        videoIndex,
      }),
    );

    //* after import songs => caculate informationFee
    if (
      dataAdd?.length > 0 &&
      variation?.includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE &&
      variation?.price
    ) {
      dispatch(setCaculateFee(true));
    }
  };

  useEffect(() => {
    if (triggerMedia === 'videos' && listError.length > 0) {
      const newListError = listError
        .map((item: any, index: number) => ({ ...item, index: index }))
        .filter((item: any) => !!item);
      videosRef?.current[newListError[0].index]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
  }, [listError, triggerMedia]);

  const handleClearSongs = () => {
    const id = visableModalDelete || null;
    const index = videoList.findIndex((video: Video) => video.id === id);
    dispatch(
      setVariationVideoSongs({
        songs: [],
        variationIndex: props.variationIndex,
        videoIndex: index,
      }),
    );

    if (id) {
      handleChangeInput(INCLUDE_COPY_RIGHT.NOT_INCLUDE, 'includeCopyRightSong', id);
    }

    setIsDeleteSong(false);
    setVisibleModalDelete(null);
    if (variation?.includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE && variation.price)
      dispatch(setCaculateFee(true));
  };

  const handleSwitchVideoType = () => {
    const id = visableModalDelete || null;
    const index = videoList.findIndex((video: Video) => video.id === id);

    dispatch(
      setVariationVideoSongs({
        songs: videoList[index]?.songs?.slice(0, 1),
        variationIndex: props.variationIndex,
        videoIndex: index,
      }),
    );
    setIsSwitchVideoType(false);
    if (id) {
      handleChangeInput(VIDEO_TYPES.MUSIC_VIDEO, 'videoType', id);
    }

    setVisibleModalDelete(null);
    if (variation?.includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE && variation.price)
      dispatch(setCaculateFee(true));
  };

  const handleValueJmdVideo = (value: number | string) => {
    setCurrentJmdVideoGenreIdValue(typeof value === 'string' ? null : value);
  };

  const getListJmdVideoGenre = async () => {
    const {
      data: { data },
    } = await TitleApi.getJmdVideoGenre();

    if (data.length > 0) {
      setListJmdVideoGenreData(data);
    }
  };

  useEffect(() => {
    getListJmdVideoGenre();
  }, []);

  const detailVideoJmd = async (id: number) => {
    const {
      data: { data },
    } = await TitleApi.getJmdVideoGenreById(id);
    setCurrentJmdVideoGenreDetail(data);
  };
  useEffect(() => {
    if (variation.jmdVideoGenreId && !currentJmdVideoGenreDetail) {
      detailVideoJmd(variation.jmdVideoGenreId);
    }
    if (!currentJmdVideoGenreIdValue) {
      setCurrentJmdVideoGenreIdValue(variation.jmdVideoGenreId);
    }
  }, [variation]);

  useEffect(() => {
    if (
      currentJmdVideoGenreDetail &&
      currentJmdVideoGenreDetail.id &&
      !linkJMDVideoValue.firstValue.value
    ) {
      const result = currentJmdVideoGenreDetail;
      setLinkJMDVideoValue({
        firstValue: { label: result.genre1, value: result.genre1 },
        ...(result?.genre2 && {
          secondValue: {
            label: result?.genre2,
            value: result?.genre3 ? result?.genre2 : result.id,
          },
        }),
        ...(result?.genre3 && {
          thirdValue: {
            label: result?.genre3,
            value: result?.genre4 ? result?.genre3 : result.id,
          },
        }),
        ...(result?.genre4 && {
          lastValue: { label: result?.genre4, value: result.id },
        }),
      });
    }
  }, [currentJmdVideoGenreDetail]);

  const janCodeLinkJMDVideoOptions = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      listJmdVideoGenreData.map(({ child, ...rest }) => {
        return { label: rest.genre1, value: rest.genre1 };
      }),
    [listJmdVideoGenreData],
  );

  const janCodeLinkJMDSecond = useMemo(() => {
    const foundItem = listJmdVideoGenreData.find(
      ({ genre1 }) => linkJMDVideoValue.firstValue.value === genre1,
    );
    if (foundItem) {
      return foundItem.child;
    }
    return [];
  }, [linkJMDVideoValue.firstValue, listJmdVideoGenreData]);

  const janCodeLinkJMDVideoOptionsSecond = useMemo(() => {
    if (janCodeLinkJMDSecond) {
      return janCodeLinkJMDSecond.map((childItem) => ({
        label: childItem.genre2,
        value: childItem.id || childItem.genre2,
      }));
    }
    return [];
  }, [janCodeLinkJMDSecond]);

  const janCodeLinkJMDThird = useMemo(() => {
    const foundItem =
      linkJMDVideoValue.secondValue &&
      janCodeLinkJMDSecond.find(({ genre2 }) => linkJMDVideoValue.secondValue.value === genre2);
    if (foundItem) {
      return foundItem.child;
    }
    return [];
  }, [janCodeLinkJMDSecond, linkJMDVideoValue.secondValue]);

  const janCodeLinkJMDVideoOptionsThird = useMemo(() => {
    if (Array.isArray(janCodeLinkJMDThird)) {
      return janCodeLinkJMDThird.map((childItem: { genre3: string; id: number }) => ({
        label: childItem.genre3,
        value: childItem.id || childItem.genre3,
      }));
    }
    return [];
  }, [janCodeLinkJMDThird]);

  const janCodeLinkJMDLast = useMemo(() => {
    const foundItem: any =
      linkJMDVideoValue.thirdValue &&
      Array.isArray(janCodeLinkJMDThird) &&
      janCodeLinkJMDThird.find(({ genre3 }) => linkJMDVideoValue.thirdValue.value === genre3);
    if (foundItem) {
      return foundItem.child;
    }
    return [];
  }, [janCodeLinkJMDThird, linkJMDVideoValue.thirdValue]);

  const janCodeLinkJMDVideoOptionsLast = useMemo(() => {
    if (Array.isArray(janCodeLinkJMDLast)) {
      return janCodeLinkJMDLast.map((childItem) => ({
        label: childItem.genre4,
        value: childItem.id || childItem.genre4,
      }));
    }
    return [];
  }, [janCodeLinkJMDLast]);

  useEffect(() => {
    props.onChangeValueJmdVideo(currentJmdVideoGenreIdValue);
  }, [currentJmdVideoGenreIdValue, linkJMDVideoValue]);

  const isReviewJmdVideoGenreId = useMemo(
    () => isReviewTitle('jmdVideoGenreId', variation),
    [variation],
  );

  return (
    <BoxForm
      title="動画コンテンツ"
      classBox="box-form-no-shadow pb-4"
      isGray={props.variationIndex % 2 ? true : false}
    >
      <SpinnerComponent isLoading={loading} />
      <div className={`${isAfterDownload(variation) && 'd-none'}`}>
        <DropzoneDigitalPackage
          acceptTypes="video/mp4"
          handleChange={onChangeFiles}
          messageError="映像ファイルはMP4ファイルを登録してください。"
          title="映像をアップロード"
        />
      </div>
      {videoList.map((video: Video, index: number) => {
        const preview = video?.url ? video?.url : '';
        return (
          <div
            className="border rounded mb-3"
            key={video.id}
            ref={(el) => (videosRef.current[index] = el)}
          >
            <div className="video-item d-flex px-3 pt-4">
              <Row className="w-100">
                <Col md="3" className="ps-0">
                  <video className="w-100" controls>
                    <track kind="captions" />
                    <source src={handleConnectStaticEndPoint(preview)} type="video/mp4" />
                    Your browser does not support HTML video.
                  </video>
                </Col>
                <Col md="9" className="pe-0">
                  <Form.Group
                    as={Row}
                    className={`mb-3 ${isReviewTitle('videos', variation) && 'pe-none'}`}
                  >
                    <Form.Label column>
                      <span>表示順</span>
                    </Form.Label>
                    <Col md={10} style={{ paddingLeft: ' 26px' }}>
                      <span className="me-3">{index + 1}</span>
                      <CaretUpSquareFill
                        onClick={() => handleMoveVideo(index, TYPE_MOVE[0])}
                        className="me-2"
                        size={25}
                      />
                      <CaretDownSquareFill
                        onClick={() => handleMoveVideo(index, TYPE_MOVE[1])}
                        size={25}
                      />
                      <span className="ms-3">{`${calcMb(video?.size || 0)}MB`}</span>
                    </Col>
                  </Form.Group>
                  <FormGroupInput
                    styleCol={{ paddingLeft: '26px' }}
                    labelMd="2"
                    colMd="10"
                    label="タイトル名"
                    value={video.name}
                    onChange={(value) => handleChangeInput(value, 'name', video.id)}
                    errorMessage={listError[index]?.name?.message}
                    isReview={isReviewTitle('videos', variation)}
                  />
                  {/* Miim-177 */}
                  {variation.jmdCooperation === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER && (
                    <>
                      <FormGroupSelect
                        styleLabel={{
                          width: '18%',
                          paddingRight: 0,
                        }}
                        value={
                          linkJMDVideoValue.firstValue.value ? linkJMDVideoValue.firstValue : ''
                        }
                        classNameSlc={
                          isReviewJmdVideoGenreId
                            ? 'pulldown-select-title-isReview'
                            : 'pulldown-select-title'
                        }
                        label="jmd動画ジャンル"
                        placeholder="分類１を選択してください"
                        labelMd="3"
                        colMd="6"
                        colClass="px-3"
                        options={janCodeLinkJMDVideoOptions}
                        onChange={(item) => {
                          setLinkJMDVideoValue((prev) => ({
                            ...prev,
                            firstValue: item,
                            secondValue: initValueOption,
                            thirdValue: initValueOption,
                            lastValue: initValueOption,
                          }));
                          handleValueJmdVideo(item.value);
                        }}
                        rowClass="mb-3"
                        disabled={index > 0 || isReviewJmdVideoGenreId}
                      />
                      <Row>
                        <Col
                          md="3"
                          style={{
                            maxWidth: '18%',
                          }}
                        >
                          <Link to={'/title/jmd-video'} target="_blank">
                            ジャンル表
                          </Link>
                        </Col>
                        <Col
                          md="6"
                          style={{
                            padding: '0',
                          }}
                        >
                          <FormGroupSelect
                            value={
                              linkJMDVideoValue.secondValue?.value
                                ? linkJMDVideoValue?.secondValue
                                : ''
                            }
                            label=""
                            classNameSlc={
                              isReviewJmdVideoGenreId
                                ? 'pulldown-select-title-isReview'
                                : 'pulldown-select-title'
                            }
                            disabled={
                              janCodeLinkJMDVideoOptionsSecond.length <= 0 ||
                              index > 0 ||
                              isReviewJmdVideoGenreId
                            }
                            isClear={Boolean(!linkJMDVideoValue?.secondValue?.value)}
                            placeholder={`分類 2 を選択してください`}
                            colMd="12"
                            colClass={`px-3 ${
                              janCodeLinkJMDVideoOptionsSecond.length <= 0 && 'bg-disable-selector'
                            }`}
                            options={janCodeLinkJMDVideoOptionsSecond}
                            onChange={(item) => {
                              setLinkJMDVideoValue((prev) => ({
                                ...prev,
                                secondValue: item,
                                thirdValue: initValueOption,
                                lastValue: initValueOption,
                              }));
                              handleValueJmdVideo(item.value);
                            }}
                            rowClass="mb-3"
                          />
                          <FormGroupSelect
                            value={
                              linkJMDVideoValue?.thirdValue?.value
                                ? linkJMDVideoValue?.thirdValue
                                : ''
                            }
                            label=""
                            disabled={
                              janCodeLinkJMDVideoOptionsThird.length <= 0 ||
                              index > 0 ||
                              isReviewJmdVideoGenreId
                            }
                            isClear={Boolean(!linkJMDVideoValue?.thirdValue?.value)}
                            placeholder={`分類 3 を選択してください`}
                            colMd="12"
                            classNameSlc={
                              isReviewJmdVideoGenreId
                                ? 'pulldown-select-title-isReview'
                                : 'pulldown-select-title'
                            }
                            colClass={`px-3 ${
                              janCodeLinkJMDVideoOptionsThird.length <= 0 && 'bg-disable-selector'
                            }`}
                            options={janCodeLinkJMDVideoOptionsThird}
                            onChange={(item) => {
                              setLinkJMDVideoValue((prev) => ({
                                ...prev,
                                thirdValue: item,
                                lastValue: initValueOption,
                              }));
                              handleValueJmdVideo(item.value);
                            }}
                            rowClass="mb-3"
                          />
                          <FormGroupSelect
                            value={
                              linkJMDVideoValue?.lastValue?.value
                                ? linkJMDVideoValue?.lastValue
                                : ''
                            }
                            label=""
                            disabled={
                              janCodeLinkJMDVideoOptionsLast.length <= 0 ||
                              index > 0 ||
                              isReviewJmdVideoGenreId
                            }
                            isClear={Boolean(!linkJMDVideoValue?.lastValue?.value)}
                            placeholder={`分類 4 を選択してください`}
                            colMd="12"
                            colClass={`px-3 ${
                              janCodeLinkJMDVideoOptionsLast.length <= 0 && 'bg-disable-selector'
                            }`}
                            classNameSlc={
                              isReviewJmdVideoGenreId
                                ? 'pulldown-select-title-isReview'
                                : 'pulldown-select-title'
                            }
                            options={janCodeLinkJMDVideoOptionsLast}
                            onChange={(item) => {
                              setLinkJMDVideoValue((prev) => ({
                                ...prev,
                                lastValue: item,
                              }));
                              handleValueJmdVideo(item.value);
                            }}
                            rowClass="mb-3"
                            errorMessage={props.jmdVideoGenreIdMsgError}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  {/* End code Miim-177 */}
                  <FormGroupInput
                    styleCol={{ paddingLeft: ' 26px' }}
                    labelMd="2"
                    colMd="10"
                    label="EC説明⽂"
                    value={video.introEc}
                    onChange={(value) => handleChangeInput(value, 'introEc', video.id)}
                    errorMessage={listError[index]?.introEc?.message}
                    isReview={isReviewTitle('videos', variation)}
                  />
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column md="2">
                      著作権管理
                    </Form.Label>
                    <Col md="10" style={{ paddingLeft: ' 26px' }}>
                      <GroupCheckBox
                        id={`includeCopyRightSong-${props.variationIndex}-${index}`}
                        name={`includeCopyRightSong-${props.variationIndex}-${index}`}
                        value={video?.includeCopyRightSong}
                        options={[
                          {
                            label: '著作権管理楽曲を含む',
                            value: INCLUDE_COPY_RIGHT.INCLUDE,
                            checked: video?.includeCopyRightSong === INCLUDE_COPY_RIGHT.INCLUDE,
                          },
                          {
                            label: '含まない',
                            value: INCLUDE_COPY_RIGHT.NOT_INCLUDE,
                            checked: video?.includeCopyRightSong === INCLUDE_COPY_RIGHT.NOT_INCLUDE,
                          },
                        ]}
                        colMd="12"
                        colClass="px-0"
                        classForm="align-items-center mb-0 h-100"
                        onChange={(options: any) => {
                          const { value } = find(options, (option) => option.checked) ?? options[0];
                          updateQuery(value, () => {
                            handleChangeInput(+value, 'includeCopyRightSong', video.id);

                            if (+value !== INCLUDE_COPY_RIGHT.INCLUDE && !isEmpty(video?.songs)) {
                              setIsDeleteSong(true);
                              setVisibleModalDelete(video.id);
                              return;
                            }
                            //* clear videoType when includeCopyRightSong === NOT_INCLUDE
                            if (
                              +value !== INCLUDE_COPY_RIGHT.INCLUDE &&
                              !visableModalDelete &&
                              !isDeleteSong
                            ) {
                              const tmpVideos = videoList.map((v: Video, vIndex: number) => ({
                                ...v,
                                videoType: vIndex === index ? null : v.videoType,
                                includeCopyRightSong:
                                  vIndex === index
                                    ? INCLUDE_COPY_RIGHT.NOT_INCLUDE
                                    : v.includeCopyRightSong,
                              }));

                              dispatch(
                                setVariationVideos({
                                  videos: tmpVideos,
                                  variationIndex: props.variationIndex,
                                }),
                              );
                            }
                          });
                        }}
                        errorMessage={listError[index]?.includeCopyRightSong?.message}
                        checkboxClass="radio-variation"
                      />
                    </Col>
                  </Form.Group>
                  {video?.includeCopyRightSong === INCLUDE_COPY_RIGHT.INCLUDE && (
                    <>
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column md="2">
                          動画種別
                        </Form.Label>
                        <Col md="10" style={{ paddingLeft: ' 26px' }}>
                          <GroupCheckBox
                            id={`videoType-${props.variationIndex}-${index}`}
                            name={`videoType-${props.variationIndex}-${index}`}
                            value={video?.videoType}
                            options={[
                              {
                                label: 'Music Video',
                                value: VIDEO_TYPES.MUSIC_VIDEO,
                                checked: video?.videoType === VIDEO_TYPES.MUSIC_VIDEO,
                              },
                              {
                                label: 'Music Video以外（LIVEなど）',
                                value: VIDEO_TYPES.OTHER,
                                checked: video?.videoType === VIDEO_TYPES.OTHER,
                              },
                            ]}
                            colMd="12"
                            colClass="px-0"
                            classForm="align-items-center mb-0 h-100"
                            onChange={(options: any) => {
                              const { value } =
                                find(options, (option) => option.checked) ?? options[0];

                              const songVideos = !isEmpty(video?.songs)
                                ? (video.songs as Song[])
                                : [];
                              if (
                                video?.videoType !== VIDEO_TYPES.MUSIC_VIDEO &&
                                +value === VIDEO_TYPES.MUSIC_VIDEO &&
                                songVideos.length > 1
                              ) {
                                setIsSwitchVideoType(true);
                                setVisibleModalDelete(video.id);
                              } else {
                                handleChangeInput(+value, 'videoType', video.id);
                              }
                            }}
                            errorMessage={listError[index]?.videoType?.message}
                            checkboxClass="radio-variation"
                          />
                        </Col>
                      </Form.Group>
                    </>
                  )}
                  {video?.includeCopyRightSong !== null && (
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column style={{ paddingRight: '0px' }}>
                        <span className="text-prev-wrap">{'情報料\n（みなし上代）'}</span>
                      </Form.Label>
                      <Col
                        md={10}
                        className="d-flex flex-column justify-content-center"
                        style={{ paddingLeft: ' 26px' }}
                      >
                        <p>
                          {video?.mediaInformationFee
                            ? `${formatPrice(video.mediaInformationFee)}円(税抜)`
                            : '0円(税抜)'}
                        </p>
                        {variation?.includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE && (
                          <>
                            <p className="fs-12">{'・自動計算されます'}</p>
                          </>
                        )}
                      </Col>
                    </Form.Group>
                  )}
                </Col>
              </Row>
            </div>
            <div
              className={`px-3 pb-3 ${
                video?.includeCopyRightSong !== INCLUDE_COPY_RIGHT.INCLUDE && 'd-none'
              }`}
            >
              <SongTable
                {...props}
                handleDeteleSong={(item) => handleDeteleSong(item, index)}
                handleAddSong={() => handleAddSong(index)}
                handleImportSong={(data: any) => handleImportSong(data, index)}
                songs={
                  video.songs?.map((el: any, index: number) => {
                    return { ...el, index: index };
                  }) ?? []
                }
                numberRemainSong={numberRemainSong}
                isVideo
                indexVideo={index}
                handleMoveItemSong={(item, type) => handleMoveItemSong(item, type, index)}
                variation={variation}
                variationName={variation?.name || ''}
                titleName={titleForm.name}
                errors={listError}
                isDiabledEditSong={false}
              />
            </div>
            <hr className="border-top border-dark" />
            <div className="text-end mb-3 me-3">
              <Button
                hidden={isAfterDownload(variation)}
                variant="secondary"
                onClick={() => setVisibleModalDelete(video.id)}
                className="py-2 px-5"
              >
                この動画を削除する
              </Button>
            </div>
            <ModalComponent
              show={visableModalDelete != null}
              onCloseModal={() => {
                if (isDeleteSong) {
                  setIsDeleteSong(false);
                  const tmpVideos = videoList.map((item: Video) => ({
                    ...item,
                    includeCopyRightSong:
                      visableModalDelete === item.id
                        ? INCLUDE_COPY_RIGHT.INCLUDE
                        : item.includeCopyRightSong,
                  }));

                  dispatch(
                    setVariationVideos({ videos: tmpVideos, variationIndex: props.variationIndex }),
                  );
                }

                if (isSwitchVideoType) {
                  setIsSwitchVideoType(false);
                  if (videoList[index].videoType !== null) {
                    handleChangeInput(VIDEO_TYPES.OTHER, 'videoType', video.id);
                  } else {
                    const tmpVideos = videoList.map((v: Video, vIndex: number) => ({
                      ...v,
                      videoType: vIndex === index ? null : v.videoType,
                    }));

                    dispatch(
                      setVariationVideos({
                        videos: tmpVideos,
                        variationIndex: props.variationIndex,
                      }),
                    );
                  }
                }
                setVisibleModalDelete(null);
              }}
              classNameContent="d-flex align-items-center content-body-modal justify-content-center"
              variantButtonSubmit="danger"
              title={isDeleteSong || isSwitchVideoType ? '変更確認' : '削除確認'}
              onSubmitModal={() => {
                isDeleteSong
                  ? handleClearSongs()
                  : isSwitchVideoType &&
                    (videoList[index].videoType === VIDEO_TYPES.OTHER ||
                      videoList[index].videoType === null)
                  ? handleSwitchVideoType()
                  : handleDeleteVideo();
              }}
              confirmText={isDeleteSong || isSwitchVideoType ? 'はい' : '削除する'}
              cancelText={isDeleteSong || isSwitchVideoType ? 'いいえ' : 'キャンセル'}
            >
              <div>
                <p className="mb-2">
                  {isDeleteSong
                    ? '登録した情報は削除されますがよろしいですか？'
                    : isSwitchVideoType
                    ? 'MusicVideoに登録できるのは１曲のみです。入力済の2曲目以降は削除されますがよろしいですか？'
                    : '動画を削除します。よろしいですか。'}
                </p>
              </div>
            </ModalComponent>
          </div>
        );
      })}
    </BoxForm>
  );
};

export default BoxVideo;
