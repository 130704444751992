import FormGroupDateRange from 'Components/Common/Form/FormGroupDateRange';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import FormGroupTextArea from 'Components/Common/Form/FormGroupTextArea';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import { DATE_FORMAT_FOR_DATE_PICKER_2 } from 'Constant/Date';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { normalizeDate, replaceGMTToUTC } from 'Utils/DateTime';
import useCampaign from 'Hooks/useCampaign';
import FormSelectSerial from 'Components/Common/Select/FormSelectSerial';

const SerialNumberGift = (): JSX.Element => {
  const { handleDisableDataCampaign } = useCampaign();

  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  return (
    <Row className="serial-number-gift-form">
      <hr className="my-3" />
      <Col className="mx-5">
        <FormGroupInput
          label="表示名称"
          labelMd="3"
          colMd="9"
          value={watch('type3.eventName')}
          register={register('type3.eventName')}
          onChange={(value) => setValue('type3.eventName', value)}
          classForm="form-with-label-nowrap"
          errorMessage={errors.type3?.eventName?.message}
          hintText="当選したユーザーのアプリに表示される名称を設定します。"
        />

        <FormInputNumber
          name="productQuantity"
          labelMd="3"
          colMd="1"
          label="付与数"
          pureType={true}
          decimalScale={0}
          maxLength={10}
          allowLeadingZeros={true}
          classForm="amount-of-people"
          allowNegative={false}
          value={watch('type3.productQuantity')}
          errorMessage={errors.type3?.productQuantity?.message}
          onChange={(value) => setValue('type3.productQuantity', value)}
          isReview={handleDisableDataCampaign(watch('type3.id'))}
        />

        <FormSelectSerial
          inputSearchString={watch('type3.seriesNumberMasterName')}
          rowClass="mb-1"
          label="シリアルコードマスタ"
          labelMd="3"
          colMd="9"
          onSelect={(selectedItem) => {
            setValue('type3.seriesNumberMasterName', selectedItem.label);
            setValue('type3.seriesNumberMasterId', selectedItem.value);
            setValue('type3.seriesNumberMasterTotal', selectedItem.total);
            setValue('type3.seriesNumberMasterDescription', selectedItem.description);
          }}
          placeholder="シリアルナンバーを選択してください"
          errorMessage={errors.type3?.seriesNumberMasterId?.message}
          disabled={handleDisableDataCampaign(watch('type3.id'))}
          onInput={(text: string) => {
            if (!text) {
              setValue('type3.seriesNumberMasterName', null);
              setValue('type3.seriesNumberMasterId', null);
              setValue('type3.seriesNumberMasterTotal', null);
              setValue('type3.seriesNumberMasterDescription', null);
            }
          }}
        />

        <Row className="mb-3 fs-12">
          <Col md="3" />
          <Col md="9">
            <div>発行総数:{watch('type3.seriesNumberMasterTotal')}</div>
            <div className="mt-2">
              <Col md={12}>
                <textarea
                  className="form-control w-100"
                  value={watch('type3.seriesNumberMasterDescription')}
                  autoComplete="off"
                  rows={5}
                  disabled
                />
              </Col>
            </div>
          </Col>
        </Row>

        <FormGroupInput
          label="URL"
          labelMd="3"
          colMd="9"
          value={watch('type3.url')}
          register={register('type3.url')}
          onChange={(value) => setValue('type3.url', value)}
          classForm="form-with-label-nowrap"
          errorMessage={errors.type3?.url?.message}
        />

        <FormGroupDateRange
          checkUpdateRange={true}
          label="有効期限"
          labelMd="3"
          colMd="6"
          placeholderStart="開始日　(yyyy/mm/dd)"
          placeholderEnd="終了日　(yyyy/mm/dd)"
          minDate={new Date()}
          maxStartDate={normalizeDate(watch('type3.endDate'))}
          startDate={normalizeDate(watch('type3.startDate'))}
          endDate={normalizeDate(watch('type3.endDate'))}
          onChangeStartDate={(date: Date | null) => {
            setValue('type3.startDate', replaceGMTToUTC(date));
          }}
          onChangeEndDate={(date: Date | null) => {
            setValue('type3.endDate', replaceGMTToUTC(date));
          }}
          dateFormat={DATE_FORMAT_FOR_DATE_PICKER_2}
          errorStartDate={errors?.type3?.startDate?.message}
          errorEndDate={errors?.type3?.endDate?.message}
        />

        <FormGroupTextArea
          labelMd="3"
          colMd="6"
          label="ユーザー表示用・備考注意事項"
          register={register('type3.description')}
          rows={4}
          value={watch('type3.description')}
          onChange={(value) => setValue('type3.description', value)}
          errorMessage={errors.type3?.description?.message}
          onKeyPress={(e) => {
            e.stopPropagation();
          }}
        />
      </Col>
    </Row>
  );
};
export default SerialNumberGift;
