import React, { memo } from 'react';

interface IDataBox {
  amount: number;
  title: string;
}

const BoxTicket = memo(({ amount, title }: IDataBox) => {
  return (
    <div style={{ width: 'fit-content' }}>
      <div className="txt-amount-box-ticket">{amount}</div>
      <div className="text-title-box-ticket fs-16 fw-700">{title}</div>
    </div>
  );
});

export { BoxTicket };
