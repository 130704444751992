import { TableGroup, TableColumns } from 'Components/Common/Table/TableGroup';

import React, { useCallback, useMemo } from 'react';
import { formatCurrency } from 'Utils/Numbers';
import { COL_END_CLASS } from 'Constant';
import { useAppSelector } from 'App/Store';
import { ROLE_RECORD_MENU } from 'Constant/Roles';

const TableSaleMonth = ({
  dataSource,
  saleChannels,
}: {
  dataSource: any[];
  saleChannels: any[];
}): JSX.Element => {
  const { role } = useAppSelector((state) => state.auth);

  const isMiimEcOrExternalEc = useMemo(() => {
    if (
      role &&
      (role === ROLE_RECORD_MENU.ECCompMaster ||
        role === ROLE_RECORD_MENU.ECCompManager ||
        role === ROLE_RECORD_MENU.SALEChanelMaster ||
        role === ROLE_RECORD_MENU.SALEChanelManager)
    ) {
      return true;
    }
    return false;
  }, [role]);

  const renderSaleChannelColumnTotal = useCallback(() => {
    const columnTotal = saleChannels.map((col, index) => ({
      text: col?.channelName,
      width: 80,
      dataIndex: `totalAmount${index}`,
      className: 'mw-150 text-truncate',
      render: ({ item }: { item: any }) => {
        return (
          <div className={`${COL_END_CLASS}`}>
            {formatCurrency(item[`totalAmount${index}`] || 0)}
          </div>
        );
      },
    }));

    if (isMiimEcOrExternalEc) {
      return [...columnTotal];
    }

    return [
      ...columnTotal,
      {
        text: '合計',
        width: 80,
        dataIndex: 'totalAmount',
        render: ({ item }: { item: any }) => {
          return <div className={`${COL_END_CLASS}`}>{formatCurrency(item.totalAmount || 0)}</div>;
        },
      },
    ];
  }, [saleChannels, isMiimEcOrExternalEc]);

  const renderSaleChannelColumnTotalMoney = useCallback(() => {
    const columnTotalMoney = saleChannels.map((col, index) => ({
      text: col?.channelName,
      width: 80,
      dataIndex: `totalMoney${index}`,
      className: 'mw-150 text-truncate',
      render: ({ item }: { item: any }) => {
        return (
          <div className={`${COL_END_CLASS}`}>
            {formatCurrency(item[`totalMoney${index}`] || 0)}
          </div>
        );
      },
    }));

    if (isMiimEcOrExternalEc) {
      return [...columnTotalMoney];
    }

    return [
      ...columnTotalMoney,
      {
        text: '合計',
        width: 80,
        dataIndex: 'totalMoney',
        render: ({ item }: { item: any }) => {
          return <div className={`${COL_END_CLASS}`}>{formatCurrency(item.totalMoney || 0)}</div>;
        },
      },
    ];
  }, [saleChannels, isMiimEcOrExternalEc]);

  const renderSellingPriceTaxColumn = useCallback(() => {
    const columnTaxTotalMoney = saleChannels.map((col, index) => ({
      text: col?.channelName,
      width: isMiimEcOrExternalEc ? 200 : 80,
      dataIndex: `afterTaxTotalMoney${index}`,
      className: 'mw-150 text-truncate',
      render: ({ item }: { item: any }) => {
        return (
          <div className={`${COL_END_CLASS}`}>
            {formatCurrency(item[`afterTaxTotalMoney${index}`] || 0)}
          </div>
        );
      },
    }));

    if (isMiimEcOrExternalEc) {
      return [...columnTaxTotalMoney];
    }
    return [
      ...columnTaxTotalMoney,
      {
        text: '合計',
        width: 80,
        dataIndex: 'afterTaxTotalMoney',
        render: ({ item }: { item: any }) => {
          return (
            <div className={`${COL_END_CLASS}`}>{formatCurrency(item.afterTaxTotalMoney || 0)}</div>
          );
        },
      },
    ];
  }, [isMiimEcOrExternalEc, saleChannels]);

  const columnTotals = useMemo(
    () =>
      [
        // {
        //   columns: [
        //     {
        //       text: '販売数',
        //       colSpan: saleChannels?.length + 1 || 1,
        //     },
        //     {
        //       text: '販売金額',
        //       colSpan: saleChannels?.length + 1 || 1,
        //     },
        //   ],
        // },
        {
          columns: renderSaleChannelColumnTotal(),
        },
      ] as TableColumns[],
    [renderSaleChannelColumnTotal, saleChannels?.length],
  );

  const columnTotalMoney = useMemo(
    () =>
      [
        // {
        //   columns: [
        //     {
        //       text: '販売数',
        //       colSpan: saleChannels?.length + 1 || 1,
        //     },
        //     {
        //       text: '販売金額',
        //       colSpan: saleChannels?.length + 1 || 1,
        //     },
        //   ],
        // },
        {
          columns: renderSaleChannelColumnTotalMoney(),
        },
      ] as TableColumns[],
    [renderSaleChannelColumnTotal, saleChannels?.length],
  );

  const columnPriceTax = useMemo(() => {
    return [
      {
        columns: renderSellingPriceTaxColumn(),
      },
    ] as TableColumns[];
  }, [renderSellingPriceTaxColumn]);

  return (
    <div className="table-month-price d-flex">
      {/* <div className="bg-dark text-white text-center d-flex align-items-center fw-bold px-4">
        販売
      </div> */}
      <div className="me-3 ec_mw-50ps" style={{ width: 'calc(100% / 3)' }}>
        <div className="text-center d-flex align-items-center fw-bold">販売数</div>
        <TableGroup dataSource={dataSource} tableColumns={columnTotals} />
      </div>

      <div className="me-3 ec_mw-50ps" style={{ width: 'calc(100% / 3)' }}>
        <div className="text-center d-flex align-items-center fw-bold">販売金額（税抜）</div>
        <TableGroup dataSource={dataSource} tableColumns={columnTotalMoney} />
      </div>

      <div className="me-3 ec_mw-50ps" style={{ width: 'calc(100% / 3)' }}>
        <div className="text-center d-flex align-items-center fw-bold">販売金額（税込）</div>
        <TableGroup dataSource={dataSource} tableColumns={columnPriceTax} />
      </div>
    </div>
  );
};
export default TableSaleMonth;
