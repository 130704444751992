/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import { Row, Image, Col, Button, ListGroup } from 'react-bootstrap';
// import { stringify, parseUrl } from 'query-string';
import Logo from 'Static/Images/logo.png';
import { List } from 'react-bootstrap-icons';
import { WHITE } from 'Constant/Colors';
import { useHistory } from 'react-router-dom';
import { logout } from 'App/Features/Auth';
import { PREFIX_WEB_APP } from 'Constant';
import { useAppDispatch } from 'App/Store';
import { default as LogoutConfirmationModal } from 'Components/Common/Modal';

export default function WebAppHeader(): JSX.Element {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [showLogoutConfirmationModal, setShowLogoutConfirmationModal] = useState(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const headerMenu = useMemo(() => {
    if (['/staff/using-status', '/staff/search'].includes(history.location.pathname)) {
      return '入場券・特典券　枚数確認';
    } else if (history.location.pathname === '/staff/support-revenue') {
      return '商品販売数確認';
    } else {
      return '';
    }
  }, [history.location]);

  const menus = useMemo(
    () => [
      {
        name: '入場券・特典券　枚数確認',
        action: () => {
          if (['/staff/search'].includes(history.location.pathname)) return;
          history.push({
            // search: stringify(parseUrl(window?.location?.href)?.query),
            pathname: `${PREFIX_WEB_APP}/search`,
          });
        },
      },
      {
        name: '商品販売数確認',
        action: () => {
          if (['/staff/support-revenue'].includes(history.location.pathname)) return;
          history.push({
            pathname: `${PREFIX_WEB_APP}/support-revenue`,
          });
        },
      },
      {
        name: 'ログアウト',
        action: () => {
          setShowLogoutConfirmationModal(true);
        },
      },
    ],
    [],
  );

  const handleLogout = () => {
    setShowLogoutConfirmationModal(false);
    dispatch(logout());
    history.push(`${PREFIX_WEB_APP}/login`);
  };

  return (
    <>
      <Row
        className="bg-menu position-fixed w-100 justify-content-between align-items-center py-3"
        style={{ zIndex: 100 }}
      >
        <Col xs="3">
          <Image className="d-block banner-mobile" src={Logo} />
        </Col>
        <Col xs="7">
          <p className="text-white fs-15 font-bold text-truncate">{headerMenu}</p>
        </Col>
        <Col xs="2" className="d-flex justify-content-end">
          <Button className="btn-toggle-app border-white" onClick={() => setShowMenu(!showMenu)}>
            <List size={28} color={WHITE} />
          </Button>
        </Col>
      </Row>
      {showMenu && (
        <ListGroup as="ul" className="webApp-dropdown">
          {menus.map((menu) => (
            <ListGroup.Item
              key={menu.name}
              as="li"
              variant="dark"
              className="btn-dark-ct p-3 text-white fs-16 fw-700"
              onClick={() => {
                menu.action();
                setShowMenu(!showMenu);
              }}
            >
              {menu.name}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}

      <LogoutConfirmationModal
        show={showLogoutConfirmationModal}
        onCloseModal={() => setShowLogoutConfirmationModal(false)}
        onSubmitModal={handleLogout}
        cancelText="キャンセル"
        confirmText="ログアウトする"
        title="ログアウト確認"
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
      >
        <div>ログアウトします。よろしいですか。</div>
      </LogoutConfirmationModal>
    </>
  );
}
