import brAxios from 'Api/Interceptors';

interface IQueryParams {
  page?: number;
  limit?: number;
  artistId?: string;
  downloadDateStart?: string;
  downloadDateEnd?: string;
  saleTokenDateStart?: string;
  saleTokenDateEnd?: string;
  gmt?: number;
}

export const NFTSApi = {
  getAllCard: (params: IQueryParams) => {
    const response = brAxios.get(`/record-company/cards`, { params });
    return response;
  },
  getCardDetail: (productId: number | string) => {
    const response = brAxios.get(`/record-company/cards/${productId}`);
    return response;
  },
  exportExcel: (params: IQueryParams) => {
    const response = brAxios.get(`/record-company/cards/export/download`, {
      params,
      responseType: 'blob',
    });
    return response;
  },
};
