import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Filter from 'Components/Common/Filter/Filter';
import { Link } from 'react-router-dom';
import CDSearch from 'Components/Page/CD/CDSearch';

export default function CDFilter(): JSX.Element {
  return (
    <div>
      <Filter filterClass="shadow-sm cd-search px-0">
        <Row>
          <CDSearch />
          <Col md="3" className="align-self-end text-end px-0">
            <Button className="btn-focus-none px-3">
              <Link to="/create-cd-master" className="text-white text-decoration-none">
                新規マスタ登録
              </Link>
            </Button>
          </Col>
        </Row>
      </Filter>
    </div>
  );
}
