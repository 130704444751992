/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import CardContainer from 'Components/Common/CardContainer';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useTitlePage from 'Hooks/useTitlePage';
import { useForm } from 'react-hook-form';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { handleEnteringDecimal, handleEnteringNumber } from 'Utils/Numbers';
import useMessage from 'Hooks/useMessage';
import { ECApis } from 'Datasource/EC';
import _, { isEmpty, toNumber } from 'lodash';
import MessageError from 'Components/Common/MessageError';
import { useAppSelector, useAppDispatch } from 'App/Store';
import { removeHashPassword } from 'App/Features/HashPassword';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { stringRequiredTrim } from 'Utils/Validation';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import FormGroupSelect from 'Components/Common/Form/FormGroupSelect';
import FormSelectOption from 'Components/Common/Form/FormSelectOption';
import {
  END_DAY_PAYMENT_DATE,
  HOLIDAY_PAYMENT_DATE_TYPES,
  PAYMENT_DATE,
  PAYMENT_DATE_TYPES,
} from './constants';
import TooltipsWithTruncate from 'Components/Common/Tooltips/TooltipsWithTruncate';
import { ISelect } from 'Types/Common';
import { Element, scroller } from 'react-scroll';

interface KeyOptions {
  keyLabel: string;
  key: string;
}

const DAY_KEY: KeyOptions = {
  keyLabel: 'keyLabel',
  key: 'day',
};

const OPTION_KEY: KeyOptions = {
  keyLabel: 'optionLabel',
  key: 'option',
};

const HOLIDAY_PROCESS_KEY: KeyOptions = {
  keyLabel: 'holidayProcessLabel',
  key: 'holidayProcess',
};

const rateSettingSchema = yup.object().shape({
  purchaseRateData: yup.array().of(
    yup.object().shape({
      hashBuyingRate: stringRequiredTrim({}),
      day: yup.number().nullable(),
      option: yup.number(),
      holidayProcess: yup.number(),
    }),
  ),
});
const RateSettingForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [purchaseRateData, setPurchaseRateData] = useState<any>([]);
  const { setMenuTitle } = useTitlePage();
  const { openMessageError } = useMessage();
  const [isError, setIsError] = useState(false);
  const dispatch = useAppDispatch();
  const {
    hashPassword: { hashPassword },
  } = useAppSelector((state) => state);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });

  const fetchPurchaseRateSetting = async () => {
    setIsLoading(true);
    try {
      const response = await ECApis.getPurchaseRateSetting(hashPassword);
      setValue('paymentMinAmount', response.data.data.paymentMinAmount || 0);
      const convertData = [...response.data.data.recordCompanies].map((item: any) => ({
        ...item,

        [`${DAY_KEY.keyLabel}`]:
          item.day === null
            ? END_DAY_PAYMENT_DATE.label
            : PAYMENT_DATE.find((d) => d.value === item.day)?.label,
        [`${DAY_KEY.key}`]: item.day,

        [`${OPTION_KEY.keyLabel}`]: PAYMENT_DATE_TYPES.find((d) => d.value === item.option)?.label,
        [`${OPTION_KEY.key}`]: item.option,

        [`${HOLIDAY_PROCESS_KEY.keyLabel}`]: HOLIDAY_PAYMENT_DATE_TYPES.find(
          (d) => d.value === item.holidayProcess,
        )?.label,
        [`${HOLIDAY_PROCESS_KEY.key}`]: item.holidayProcess,

        hashBuyingRate: toNumber(item.hashBuyingRate || ''),
      }));

      setPurchaseRateData(convertData);
      setValue('purchaseRateData', convertData);
    } catch (error) {
      dispatch(removeHashPassword());
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeBuyingRate = (value: number, index: number, key: string) => {
    const tempTable = [...purchaseRateData] as any[];
    let maximumValue: number = value;

    if (value > 100) {
      maximumValue = 100;
    }

    if (index !== -1) {
      tempTable[index][key] = maximumValue;
    }

    setPurchaseRateData(tempTable);
  };

  const onSubmit = async () => {
    if (
      watch('paymentMinAmount') === '' ||
      watch('paymentMinAmount') === null ||
      watch('paymentMinAmount') === undefined
    ) {
      setError('paymentMinAmount', { type: 'focus' }, { shouldFocus: true });
      setError('paymentMinAmountErr', { type: 'required', message: '入力してください。' });
    } else {
      clearErrors('paymentMinAmount');
      clearErrors('paymentMinAmountErr');
    }

    purchaseRateData.map((item: any) => {
      if (
        item.hashBuyingRate === '' ||
        item.hashBuyingRate === null ||
        item.hashBuyingRate === undefined
      ) {
        setError(
          item.recordCompanyName,
          { type: 'required', message: '入力してください。' },
          { shouldFocus: true },
        );
      } else {
        clearErrors(item.recordCompanyName);
      }
    });

    if (isEmpty(errors)) {
      setIsLoading(true);
      try {
        const buyingRateSetting = purchaseRateData.map((object: any) => {
          return _.pick(object, [
            'recordCompanyId',
            'hashBuyingRate',
            DAY_KEY.key,
            OPTION_KEY.key,
            HOLIDAY_PROCESS_KEY.key,
          ]);
        });
        const params = {
          paymentMinAmount: watch('paymentMinAmount'),
          buyingRateSetting: buyingRateSetting,
          hashPassword,
        };
        await ECApis.updatePurchaseRateSetting(params);
        await fetchPurchaseRateSetting();
      } catch (error) {
        openMessageError(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  /**
   * update key: day, option, holidayProcess
   */
  const handleChangeValueAndLabel = (item: ISelect, index: number, keyOption: KeyOptions) => {
    const { value } = item;
    const tempTable = [...purchaseRateData] as any[];

    if (index < 0) {
      return;
    }

    // value 末日 paymentDate
    if (value === undefined || value === null) {
      tempTable[index][keyOption.key] = null;
      tempTable[index][keyOption.keyLabel] = END_DAY_PAYMENT_DATE.label;

      setPurchaseRateData(tempTable);
      setValue('purchaseRateData', tempTable);
      return;
    }

    // setValue(`purchaseRateData.${index}.${key}`, Number(value) || '');

    tempTable[index][keyOption.key] = Number(value);

    // day
    if (keyOption.key === DAY_KEY.key) {
      tempTable[index][keyOption.keyLabel] = PAYMENT_DATE.find((d) => d.value === value)?.label;
    }

    // option
    if (keyOption.key === OPTION_KEY.key) {
      tempTable[index][keyOption.keyLabel] = PAYMENT_DATE_TYPES.find(
        (d) => d.value === value,
      )?.label;
    }

    // holiday
    if (keyOption.key === HOLIDAY_PROCESS_KEY.key) {
      tempTable[index][keyOption.keyLabel] = HOLIDAY_PAYMENT_DATE_TYPES.find(
        (d) => d.value === value,
      )?.label;
    }

    setPurchaseRateData(tempTable);
    setValue('purchaseRateData', tempTable);
  };

  const handleFocusRowRateSetting = (index: number, idElmScroll: string) => {
    scroller.scrollTo(idElmScroll, {
      duration: 500,
      delay: 0,
      smooth: true,
      containerId: 'container-row-rate-setting',
      offset: index === 0 ? -52 : -45, // Scrolls to element + 50 pixels down the page
    });
  };

  useEffect(() => {
    fetchPurchaseRateSetting();
    setMenuTitle('仕入率設定');
  }, []);

  return (
    <div className="mt-4">
      <Container id="ecPurchaseRate" className="d-flex justify-content-center">
        <SpinnerComponent isLoading={isLoading} />
        <CardContainer title="仕入支払設定" darkHeader>
          <FormGroupInput
            value={watch('paymentMinAmount')}
            onChange={(value) => {
              setValue('paymentMinAmount', handleEnteringDecimal(value) ?? 0);
            }}
            labelMd={3}
            colMd={3}
            label="仕入支払最低金額"
            errorMessage={errors.paymentMinAmount?.message}
            messageWrapper="mt-0 ml-6px"
            register={{ ...register('paymentMinAmount') }}
            hintText="円"
            classLabel="font-bold"
            classCol="d-flex align-items-center"
            classInput="text-end mr-10px"
            classForm="mb-1"
          />
          <Row>
            <Col md="3"></Col>
            <Col className="mt-minus-10px fs-12" md="9">
              仕入支払額が上記金額に満たない場合、次月へ繰り越されます。
            </Col>
          </Row>
          <Row>
            <Col md="3"></Col>
            <Col md="9">
              {errors.paymentMinAmountErr && (
                <MessageError message={errors.paymentMinAmountErr.message} />
              )}
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="font-bold" md="12">
              仕入率設定
            </Col>
          </Row>
          <Row className="mt-1 mb-5">
            <Col className="ml-40px fs-12 line-22px" md="10">
              仕入率は暗号化され保存されます。再表示時または、帳票出力時に「会計パスワード」が必要となります。
              暗号化されているため、システム管理者であっても仕入率を閲覧することはできません。
            </Col>
          </Row>
          <div className="ec_purchaseRate_table" id="container-row-rate-setting">
            <Table bordered>
              <thead style={{ zIndex: 1005 }}>
                <tr>
                  <td className="text-white bg-dark text-center align-middle">レコード会社</td>
                  <td className="text-white bg-dark text-center align-middle">仕入率</td>
                  <td className="text-white bg-dark text-center align-middle">締め日</td>
                  <td className="text-white bg-dark text-center align-middle" colSpan={2}>
                    支払日
                  </td>
                  <td className="text-white bg-dark text-center align-middle">支払日休日処理</td>
                </tr>
              </thead>
              <tbody>
                {purchaseRateData?.map((data: any, index: number) => {
                  return (
                    <tr key={index} id={`table-row-rate-setting-${index}`}>
                      <td className="w-50 line-35px" style={{ minWidth: 300, maxWidth: 400 }}>
                        <TooltipsWithTruncate
                          key={`rsa-result-${index}`}
                          id={`rsa-result-${index}`}
                          tooltips={data?.recordCompanyName ? data?.recordCompanyName : ''}
                          extraClientWidth={20}
                        >
                          <div className="text-truncate">{data?.recordCompanyName}</div>
                        </TooltipsWithTruncate>
                      </td>

                      <td style={{ minWidth: 180 }}>
                        <div className="d-flex align-items-center">
                          <Form.Control
                            {...register(data.recordCompanyName)}
                            value={data?.hashBuyingRate}
                            onChange={(e: any) => {
                              const decimalValue: number = handleEnteringDecimal(e.target.value);
                              onChangeBuyingRate(decimalValue, index, 'hashBuyingRate');
                            }}
                            className="mr-6px text-end"
                            type="text"
                          />
                          %
                        </div>
                        <MessageError message={errors[data.recordCompanyName]?.message} />
                      </td>

                      {/* 締め日 */}
                      <td className="align-middle text-center" style={{ minWidth: 120 }}>
                        月末
                      </td>

                      {/* 支払日 */}
                      <td style={{ minWidth: 120 }}>
                        <Element name={`table-row-${OPTION_KEY.key}-${index}`}>
                          <FormSelectOption
                            placeholder=""
                            colMd="12"
                            colClass="px-0"
                            options={PAYMENT_DATE_TYPES}
                            defaultValue={watch(`purchaseRateData.${index}.${OPTION_KEY.keyLabel}`)}
                            onSelect={(item: any) => {
                              handleChangeValueAndLabel(item, index, OPTION_KEY);
                            }}
                            onMenuFocus={() => {
                              handleFocusRowRateSetting(index, `table-row-${DAY_KEY.key}-${index}`);
                            }}
                          />
                        </Element>
                      </td>
                      <td style={{ minWidth: 120 }}>
                        <Element name={`table-row-${DAY_KEY.key}-${index}`}>
                          <FormSelectOption
                            placeholder=""
                            colMd="12"
                            colClass="px-0"
                            options={PAYMENT_DATE}
                            defaultValue={watch(`purchaseRateData.${index}.${DAY_KEY.keyLabel}`)}
                            onSelect={(item: any) => {
                              handleChangeValueAndLabel(item, index, DAY_KEY);
                            }}
                            onMenuFocus={() => {
                              handleFocusRowRateSetting(index, `table-row-${DAY_KEY.key}-${index}`);
                            }}
                            stylesListResult={{ maxHeight: 170 }}
                          />
                        </Element>
                      </td>

                      {/* 支払日休日処理 */}
                      <td style={{ minWidth: 200 }}>
                        <Element name={`table-row-${HOLIDAY_PROCESS_KEY.key}-${index}`}>
                          <FormSelectOption
                            placeholder=""
                            colMd="12"
                            colClass="px-0"
                            options={HOLIDAY_PAYMENT_DATE_TYPES}
                            defaultValue={watch(
                              `purchaseRateData.${index}.${HOLIDAY_PROCESS_KEY.keyLabel}`,
                            )}
                            onSelect={(item: any) => {
                              handleChangeValueAndLabel(item, index, HOLIDAY_PROCESS_KEY);
                            }}
                            onMenuFocus={() => {
                              handleFocusRowRateSetting(index, `table-row-${DAY_KEY.key}-${index}`);
                            }}
                          />
                        </Element>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* {errors.required && <MessageError message={errors.required.message} />} */}
          <div className="text-center mt-4">
            <Button onClick={onSubmit} className="px-5 btn-equal-width" variant="primary">
              登録する
            </Button>
          </div>
        </CardContainer>
      </Container>
    </div>
  );
};

export default RateSettingForm;
