import React from 'react';
import { Nav, NavItem, NavLink, Container } from 'react-bootstrap';
import { useAppSelector } from 'App/Store';
import { CampaignSteps } from 'Constant/Campaign';
import useCampaign from 'Hooks/useCampaign';

const StepRegister = ({
  handleNextStep,
  wrapperClass,
}: {
  handleNextStep: (step: number) => void;
  wrapperClass?: string;
}): JSX.Element => {
  const { step, overview } = useAppSelector((state) => state.campaign);
  const { handleSetOldTitleId } = useCampaign();

  const tabSteps: { label: string; eventKey: number }[] = [
    {
      label: '概要と対象商品の登録',
      eventKey: CampaignSteps.Overview,
    },
    {
      label: '応募コースと抽選方法',
      eventKey: CampaignSteps.CourseAndMethod,
    },
    {
      label: '賞(景品)登録',
      eventKey: CampaignSteps.SignUpRewards,
    },
    {
      label: '当選条件',
      eventKey: CampaignSteps.WinningConditions,
    },
    {
      label: '登録内容確認',
      eventKey: CampaignSteps.Confirm,
    },
  ];

  return (
    <div className="register-campaign">
      <Container fluid className="shadow-sm">
        <div className="py-4 d-flex align-items-center justify-content-center">
          <Nav
            variant="pills"
            // defaultActiveKey={titleState.currentStep}
            activeKey={step}
            className={`${wrapperClass ? wrapperClass : ''}`}
          >
            {tabSteps.map((item, index) => (
              <NavItem key={index} className="item-step position-relative bg-gray">
                <NavLink
                  className="h-100 w-100 d-flex align-items-center justify-content-center"
                  eventKey={item.eventKey}
                  onClick={() => {
                    handleNextStep(item.eventKey);
                    if (item.eventKey === CampaignSteps.CourseAndMethod) {
                      handleSetOldTitleId();
                    }
                  }}
                  disabled={!overview?.isAuthor}
                >
                  {item.label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
      </Container>
    </div>
  );
};

export default StepRegister;
