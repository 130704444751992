import React, { useEffect, useState } from 'react';
import BoxForm from 'Components/Common/Form/BoxForm';
import { Button, Col, FormControl, Row } from 'react-bootstrap';
import MessageError from 'Components/Common/MessageError';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import { ProductsApi } from 'Datasource/ProductsApi';
import { StringParam, useQueryParams } from 'use-query-params';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import dayjs from 'dayjs';
import { DATE_FORMAT_9 } from 'Constant/Date';
import useMessage from 'Hooks/useMessage';
import { isNumber, uniqBy } from 'lodash';

const schema = yup.object().shape({
  comment: yup.string().required('入力してください。').trim('入力してください。').nullable(),
});

export default function ConfirmationComment(): JSX.Element {
  const [processManagement, setProcessManagement] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listCampaign, setListCampaign] = useState<Array<any>>([]);
  const [listComment, setListComment] = useState<any>([]);
  const { openMessageError } = useMessage();

  const {
    image,
    artistName,
    titleName,
    variationNames = [],
    listCampaignProcess = [],
    listTitleProcess = [],
    isAuthor,
  } = processManagement;
  const [comment, setComment] = useState<string>('');
  const [onSelect, setOnSelect] = useState<string>('');

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [query] = useQueryParams({
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
  });

  useEffect(() => {
    const ids = {
      titleId: query.titleIds || '',
    };

    handleGetProductDetail(ids);
  }, [query.artistIds, query.titleIds]);

  const handleGetProductDetail = async (ids: any) => {
    if (!ids?.titleId) return;
    const queryArr = Object.values(query);
    const arrLookupNoData = queryArr.filter((q: any) => {
      return !!q && q === 'no-data';
    });

    if (arrLookupNoData.length > 0) {
      setProcessManagement({});
      return;
    }
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await ProductsApi.getProcessManagement({
        titleId: ids.titleId,
      });
      setProcessManagement(data);
      const listNameCampign = data?.listCampaignProcess?.map((item: any) => {
        return { name: item.campaignName, id: item.campaignId };
      });
      setListCampaign(uniqBy(listNameCampign, 'id'));
      {
        !ids?.isCommnet && setOnSelect(data?.titleName) && setListComment(data?.listTitleProcess);
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = handleSubmit(async () => {
    const param = {
      titleId: +(query?.titleIds || 0),
      note: comment,
      campaignId: isNumber(onSelect) ? onSelect : null,
      type: 2,
    };
    setIsLoading(true);
    await ProductsApi.createComment(param);
    handleGetProductDetail({ titleId: query.titleIds || '', isCommnet: true });
    setComment('');
    setValue('comment', '');
    setIsLoading(false);
  });

  useEffect(() => {
    if (onSelect === titleName) {
      setListComment(listTitleProcess);
      return;
    }
    const dataComment =
      listCampaignProcess.filter((element: any) => element?.campaignId === onSelect) || [];

    setListComment(dataComment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSelect, processManagement]);

  const renderComent = (item: any) => {
    return (
      <Row className={`${!item?.note && 'd-none'}`}>
        <Col>
          <span>{item.authorName}</span>
          <span className="float-end">{dayjs(item.createdAt).format(DATE_FORMAT_9)}</span>
          <hr className="m-0 mt-1" />
          <div className="pe-1">
            <blockquote className="border-0 bg-transparent  mt-1 w-100 pe-none text-prev-wrap">
              {item.note}
            </blockquote>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <BoxForm classBox={`${titleName || 'd-none'}`} title="コメント">
        <SpinnerComponent isLoading={isLoading} />

        <>
          <div className="d-flex">
            {image ? (
              <img
                className="img-products border ms-3"
                alt="preview"
                src={handleConnectStaticEndPoint(image)}
              />
            ) : (
              <div className="avatar-title bg-gray avatar-bank d-flex justify-content-center align-items-center">
                <p className="gray-dark">No Image</p>
              </div>
            )}
            <Col md={7} className="ms-4">
              <p className="fs-11">タイトル</p>
              <div>
                <span className="fw-bold">{titleName}</span>
                <span className="ps-2 fs-11">{artistName}</span>
              </div>
              <p className="fs-11 mt-2">バリエーション</p>
              {variationNames?.map((name: string, index: number) => {
                return (
                  <span className="fw-bold pe-3" key={index}>
                    {name}
                  </span>
                );
              })}
            </Col>
          </div>
          <Row className="mt-4">
            <Col md={2} className="p-0">
              <Row>
                <Button
                  onClick={() => setOnSelect(titleName)}
                  variant="light"
                  className={`text-truncate custom-border mb-2 ${
                    onSelect === titleName && 'bg-dark text-white opacity-100'
                  }`}
                >
                  {titleName}
                </Button>
                {listCampaign.map((campaign: any, index: number) => {
                  return (
                    <Button
                      key={index}
                      onClick={() => setOnSelect(campaign.id)}
                      variant="light"
                      className={`text-truncate custom-border mb-2 ${
                        onSelect === campaign.id && 'bg-dark text-white opacity-100'
                      }`}
                    >
                      {campaign.name}
                    </Button>
                  );
                })}
              </Row>
            </Col>

            <Col>
              <div className="layout-comment rounded border overflow-auto py-1">
                {listComment.map((el: any) => {
                  return renderComent(el);
                })}
              </div>
              <Row className="mt-2">
                <Col md={10} className="p-0">
                  <FormControl
                    {...register('comment')}
                    className="form-control h-120px"
                    value={comment}
                    placeholder="コメントを入力する"
                    onChange={(e) => setComment(e.target.value)}
                    as="textarea"
                  />
                </Col>
                <Col md={2} className="align-self-end d-grid">
                  <Button onClick={onSubmit} disabled={!isAuthor}>
                    コメント送信
                  </Button>
                </Col>
              </Row>
              <MessageError message={errors.comment?.message} />
            </Col>
          </Row>
        </>
      </BoxForm>
    </>
  );
}
