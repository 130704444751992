import React, { PropsWithChildren } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { DateSelectorProps } from 'Components/Common/DateSelector/date';
import ja from 'date-fns/locale/ja';
import { DATE_FORMAT_FOR_DATE_PICKER, MAX_DATE, TIME_FORMAT } from 'Constant/Date';
import MessageError from 'Components/Common/MessageError';
import { Col, Form } from 'react-bootstrap';
import dayjs from 'dayjs';
import { getNameBrower } from 'Utils/Brower';
registerLocale('ja', ja);

function DateSelector({
  selected,
  onChange,
  placeholder,
  dateFormat,
  showTime = true,
  register,
  startDate,
  compareMinDate,
  endDate,
  minDate,
  maxDate,
  selectsStart,
  selectsEnd,
  disabled = false,
  isReview = false,
  showTimeSelectOnly,
  classNameDateSelect = '',
  monthPicker = false,
  errorMessage,
  messageWrapper,
  label,
  classLabel,
  labelMd,
  labelLg,
  colMd,
  colLg,
  wrapperClass,
  maxTime,
  minTime,
  isDefaultSetTime,
}: PropsWithChildren<DateSelectorProps>): JSX.Element {
  const filterPassedTime = (time: Date) => {
    if (!compareMinDate) return true;
    let valid = true;
    const selectedDate = new Date(time);
    const minDateCompare = dayjs(minDate) <= dayjs(minTime) ? minTime : minDate;
    const maxDateCompare = dayjs(maxDate) <= dayjs(maxTime) ? maxTime : maxDate;

    if (minDateCompare && dayjs(minDateCompare).isSame(dayjs(selectedDate), 'day')) {
      valid = new Date(minDateCompare).getTime() - 30 * 60 < selectedDate.getTime();
    }
    if (maxDateCompare && dayjs(maxDateCompare).isSame(dayjs(selectedDate), 'day')) {
      valid = selectedDate.getTime() < new Date(maxDateCompare).getTime() - 30 * 60;
    }
    return valid;
  };

  const handleOnChange = (date: any) => {
    onChange && onChange(date);
  };

  const handleRenderMinMaxTime = () => {
    let dateObj: any = {
      minTime: undefined,
      maxTime: undefined,
    };
    if (!selected) {
      dateObj = {
        minTime: dayjs(minDate)?.toDate(),
        maxTime: dayjs(minDate)?.endOf('day')?.toDate(),
      };
    }
    if (dayjs(minDate).isSame(dayjs(selected))) {
      dateObj = {
        minTime: dayjs(minDate)?.toDate(),
        maxTime: dayjs(minDate)?.endOf('day')?.toDate(),
      };
    }
    if (dayjs(maxDate).isSame(dayjs(selected))) {
      dateObj = {
        minTime: dayjs(maxDate)?.startOf('day').toDate(),
        maxTime: dayjs(maxDate)?.toDate(),
      };
    }
    if (isDefaultSetTime) {
      dateObj = {
        minTime: minTime,
        maxTime: maxTime,
      };
    }
    return dateObj;
  };

  return (
    <div className={`${wrapperClass ? wrapperClass : ''}`}>
      {label && (
        <Form.Label className={classLabel} column md={labelMd} lg={labelLg}>
          {label}
        </Form.Label>
      )}
      <Col
        md={colMd}
        lg={colLg}
        className={`position-relative custom-date-picker ${classNameDateSelect}`}
      >
        <DatePicker
          className="form-control custom-icon-date"
          {...register}
          disabledKeyboardNavigation
          showTimeSelect={showTime}
          selected={selected}
          onChange={(date, e) => {
            if (!isReview) {
              if (getNameBrower() === 'safari') {
                const value = e?.currentTarget?.value?.replaceAll('/', '-');
                const regexDay = /^\d{4}-+\d{2}-0$/;
                const regexMonth = /^\d{4}-0$/;

                if (regexDay.test(value) || regexMonth.test(value)) return;
              }

              handleOnChange(date);
            }
          }}
          dateFormat={dateFormat ?? DATE_FORMAT_FOR_DATE_PICKER}
          timeFormat={TIME_FORMAT}
          placeholderText={placeholder}
          startDate={startDate && startDate}
          endDate={endDate && endDate}
          minDate={minDate}
          maxDate={maxDate || new Date(MAX_DATE)}
          selectsStart={selectsStart ? true : undefined}
          selectsEnd={selectsEnd ? true : undefined}
          showTimeSelectOnly={showTimeSelectOnly}
          disabled={disabled}
          filterTime={filterPassedTime}
          locale="ja"
          showMonthYearPicker={monthPicker}
          {...handleRenderMinMaxTime()}
        />
      </Col>
      <MessageError classWrapper={messageWrapper} message={errorMessage} />
    </div>
  );
}
export default DateSelector;
