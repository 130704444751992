import * as yup from 'yup';
import { stringRequired } from 'Utils/Validation';
// import { some } from 'lodash';
// import { getBool } from 'Utils/Permission';
import { validateEmailsStringWithSemicolons, maxMsg } from 'Utils/Validation';

const ECSendBulkEmailFormSchema = yup.object().shape({
  subject: stringRequired({}).max(100, maxMsg(100)).nullable(),
  body: stringRequired({}).max(2000, maxMsg(2000)).nullable(),
  operators: stringRequired({})
    .max(500, maxMsg(500))
    .nullable()
    .test(
      'operators',
      '「；」セミコロンで区切られた有効なメールアドレスを入力してください',
      (value) => (value ? validateEmailsStringWithSemicolons(value) : true),
    ),
});

export default ECSendBulkEmailFormSchema;
