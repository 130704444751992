import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import Filter from 'Components/Common/Filter/Filter';
import ProductArtist from 'Components/Page/Artist/Item';
import { ArtistApi } from 'Datasource/ArtistApi';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { useAppDispatch } from 'App/Store';
import { setPullDownSearchbyText } from 'App/Features/PullDown';
import useMessage from 'Hooks/useMessage';
import ArtistSearch from './ArtistSearch';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { PAGE_SIZE_ARTIST } from 'Constant';

type ArtistType = {
  image: string;
  name: string;
};
const perPage = PAGE_SIZE_ARTIST;

export default function Artist(): JSX.Element {
  const [query] = useQueryParams({
    page: NumberParam,
    status: StringParam,
    queryCompany: StringParam,
    queryArtist: StringParam,
    companyIds: StringParam,
    artistIds: StringParam,
  });

  const { openMessageError } = useMessage();
  const [page, setPage] = useState(query.page ?? 1);
  const [total, setTotal] = useState(0);
  const [artists, setArtists] = useState<ArtistType[]>([]);
  const [loading, setLoading] = useState(false);

  const handleGetArtists = async (ids: any) => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await ArtistApi.getAllRecordArtist({
        page,
        perPage,
        artistIds: ids.artistIds,
      });
      setArtists(data.artists);
      setTotal(data.totalCount);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const ids = {
      artistIds: query.artistIds ?? '',
    };
    handleGetArtists(ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, query.artistIds]);

  const onChangePage = (page: number) => {
    setPage(page);
  };

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setPullDownSearchbyText(true));
    return () => {
      dispatch(setPullDownSearchbyText(false));
    };
  }, [dispatch]);

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <div className="wrap-aritist-list">
        <Filter filterClass="d-flex align-items-center justify-content-between shadow-sm">
          <ArtistSearch />
          <PaginationComponent
            onClick={onChangePage}
            activePage={page}
            total={total}
            perPage={perPage}
          />
        </Filter>
        <Container>
          <Row className="list-item mt-4">
            {!isEmpty(artists) ? (
              artists.map((item, index) => {
                return (
                  <Col className="col-item" key={index}>
                    <ProductArtist artistInfo={item} />
                  </Col>
                );
              })
            ) : !loading ? (
              <div className="vh-100-final d-flex align-items-center justify-content-center">
                検索結果がありません。
              </div>
            ) : null}
          </Row>
        </Container>
      </div>
    </>
  );
}
