/* eslint-disable no-empty */
import { PER_PAGE } from 'Constant';
import { ArtistApi } from 'Datasource/ArtistApi';
import { isEmpty } from 'lodash';
import { IPullDownItem } from 'Types/Common';
import { TitleApi } from 'Datasource/TitleApi';
import { VariationApi } from 'Datasource/VariationApi';

export const handleSearchArtists = async (query: string) => {
  const {
    data: { data },
  } = await ArtistApi.getAllSuggestionArtist({
    page: 1,
    perPage: PER_PAGE,
    query: encodeURIComponent(query),
    screen: 'title',
  });
  const searchResult: IPullDownItem[] = [];
  if (!isEmpty(data)) {
    data.map((item: IPullDownItem) => {
      return searchResult.push({
        label: item.localName,
        value: item.id,
      });
    });
  }
  return searchResult;
};

export const handleSearchTitle = async (query: string, artistID: any) => {
  try {
    let listTitles: IPullDownItem[] = [];
    if (artistID) {
      const {
        data: { data },
      } = await TitleApi.getAllTitleByArtistID({
        artistID: artistID,
        page: 1,
        perPage: PER_PAGE,
        query,
      });
      listTitles = data;
    } else {
      const {
        data: { data },
      } = await TitleApi.getSuggestionTitles({
        page: 1,
        perPage: PER_PAGE,
        query: encodeURIComponent(query),
      });
      listTitles = data;
    }
    if (!isEmpty(listTitles)) {
      const searchResult: IPullDownItem[] = listTitles.map((item: IPullDownItem) => {
        return {
          label: item.titles_name || item.name,
          value: item.titles_id || item.id,
        };
      });
      return searchResult;
    }
  } catch (error) {}
};

export const handleSearchVariation = async (query: any, titleID: any) => {
  try {
    let listVariations: IPullDownItem[] = [];
    if (titleID) {
      const {
        data: { data },
      } = await VariationApi.getAllVariationByTitleID({
        titleID: titleID,
        page: 1,
        perPage: PER_PAGE,
        query: encodeURIComponent(query),
      });
      listVariations = data;
    } else {
      const {
        data: { data },
      } = await VariationApi.getAllSuggestionVariations({ page: 1, perPage: PER_PAGE, query });
      listVariations = data;
    }
    if (!isEmpty(listVariations)) {
      const searchResult: IPullDownItem[] = listVariations.map((item: IPullDownItem) => {
        return {
          label: item.variations_name,
          value: item.variations_id,
          tokutenCommons: item.digitalTokutenCommons,
        };
      });
      return searchResult;
    }
  } catch (error) {}
};
