import brAxios from 'Api/Interceptors';
// import { transformQuery } from 'Utils/Params';
// import { ActionType } from '../Constant';
import { getCurrentTimeZone } from 'Utils/DateTime';
import { AxiosResponse } from 'axios';

export interface QuerySaleProductsParams {
  limit?: number;
  page?: number;
  type?: null | string | undefined;
  artistId?: null | string | undefined;
  titleId?: null | string | undefined;
  campaignId?: null | string | undefined;
  statusId?: null | string | undefined;
  saleIds?: null | string | undefined;
  startDate?: null | string | undefined;
  endDate?: null | string | undefined;
  channelId?: null | string | undefined;
  sort?: string;
  order?: string;
  artistIds?: null | string | undefined;
  titleIds?: null | string | undefined;
  campaignIds?: null | string | undefined;
}

export interface QueryPurchaseParams {
  limit?: number;
  page?: number;
  orderNumber?: null | string | undefined;
  miimId?: null | string | undefined;
  realName?: null | string | undefined;
  email?: null | string | undefined;
  artistId?: null | string | undefined;
  titleId?: null | string | undefined;
  variationId?: null | string | undefined;
  campaignId?: null | string | undefined;
  startDate?: null | string | undefined;
  endDate?: null | string | undefined;
  sort?: string;
  order?: string;
  artistIds?: null | string | undefined;
  titleIds?: null | string | undefined;
  variationIds?: null | string | undefined;
  campaignIds?: null | string | undefined;
}

export interface ParamBulkEmail {
  subject?: string;
  body?: string;
  operators?: Array<string>;
  isTest: boolean;
}

const timeZoneOffSet = getCurrentTimeZone() * 60;

export const ECApis = {
  getSaleProducts: ({
    page,
    limit,
    type,
    artistId,
    titleId,
    campaignId,
    saleIds,
    statusId,
    startDate,
    endDate,
    sort,
    order,
    artistIds,
    titleIds,
    campaignIds,
  }: QuerySaleProductsParams): Promise<AxiosResponse> =>
    brAxios.get(
      `/ec-site/products/ec-sales-product-list?page=${page ?? 1}&limit=${limit ?? 50}${
        type ? `&type=${type}` : ''
      }${artistId ? `&artistId=${artistId}` : ''}${titleId ? `&titleId=${titleId}` : ''}${
        campaignId ? `&campaignId=${campaignId}` : ''
      }${statusId ? `&status=${statusId}` : ''}${startDate ? `&startDate=${startDate}` : ''}${
        endDate ? `&endDate=${endDate}` : ''
      }${saleIds ? `&channelId=${saleIds}` : ''}${sort ? `&sort=${sort}` : ''}&order=${
        order || 'DESC'
      }${artistIds ? `&artistIds=${artistIds}` : ''}${titleIds ? `&titleIds=${titleIds}` : ''}${
        campaignIds ? `&campaignIds=${campaignIds}` : ''
      }`,
    ),
  saleProductDownloadExcel: ({
    type,
    artistId,
    titleId,
    campaignId,
    statusId,
    startDate,
    endDate,
    saleIds,
  }: QuerySaleProductsParams): Promise<AxiosResponse> => {
    const curTimeZone = getCurrentTimeZone();
    return brAxios.get(
      `/ec-site/products/export-excel?${type ? `&type=${type}` : ''}${
        artistId ? `&artistId=${artistId}` : ''
      }${titleId ? `&titleId=${titleId}` : ''}${campaignId ? `&campaignId=${campaignId}` : ''}${
        statusId ? `&status=${statusId}` : ''
      }${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}${
        saleIds ? `&channelId=${saleIds}` : ''
      }${`&timezone=${curTimeZone}`}`,
      {
        responseType: 'blob',
      },
    );
  },
  getSaleProductDetail: (id: string | number): Promise<AxiosResponse> =>
    brAxios.get(`/ec-site/products/ec-sales-product-detail/${id}`),
  updateSaleProductDetail: ({
    id,
    params,
  }: {
    id: string | number;
    params: any;
  }): Promise<AxiosResponse> =>
    brAxios.put(`/ec-site/products/ec-sales-product-detail/${id}`, params),
  getPurchase: ({
    limit,
    page,
    orderNumber,
    miimId,
    realName,
    startDate,
    endDate,
    artistId,
    titleId,
    variationId,
    campaignId,
    sort,
    order,
    artistIds,
    titleIds,
    variationIds,
    campaignIds,
  }: QueryPurchaseParams): Promise<AxiosResponse> =>
    brAxios.get(
      `/ec-site/purchase?page=${page ?? 1}&limit=${limit ?? 50}${
        orderNumber ? `&orderNumber=${orderNumber}` : ''
      }${miimId ? `&miimId=${miimId}` : ''}${realName ? `&realName=${realName}` : ''}${
        artistId ? `&artistId=${artistId}` : ''
      }${titleId ? `&titleId=${titleId}` : ''}${variationId ? `&variationId=${variationId}` : ''}${
        campaignId ? `&campaignId=${campaignId}` : ''
      }${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}${
        sort ? `&sort=${sort}` : ''
      }&order=${order || 'DESC'}${artistIds ? `&artistIds=${artistIds}` : ''}${
        titleIds ? `&titleIds=${titleIds}` : ''
      }${variationIds ? `&variationIds=${variationIds}` : ''}${
        campaignIds ? `&campaignIds=${campaignIds}` : ''
      }`,
    ),
  getPurchaseDetail: (id: string | number): Promise<AxiosResponse> =>
    brAxios.get(`/ec-site/purchase/${id}`),
  purchaseDownloadExcel: ({
    orderNumber,
    miimId,
    realName,
    artistId,
    titleId,
    variationId,
    campaignId,
    startDate,
    endDate,
  }: QueryPurchaseParams): Promise<AxiosResponse> => {
    const curTimeZone = getCurrentTimeZone();
    return brAxios.get(
      `/ec-site/purchase/export-excel?${orderNumber ? `&orderNumber=${orderNumber}` : ''}${
        miimId ? `&miimId=${miimId}` : ''
      }${realName ? `&realName=${realName}` : ''}${artistId ? `&artistId=${artistId}` : ''}${
        titleId ? `&titleId=${titleId}` : ''
      }${variationId ? `&variationId=${variationId}` : ''}${
        campaignId ? `&campaignId=${campaignId}` : ''
      }${startDate ? `&startDate=${startDate}` : ''}${
        endDate ? `&endDate=${endDate}` : ''
      }${`&timezone=${curTimeZone}`}`,
      {
        responseType: 'blob',
      },
    );
  },
  purchaseRefund: ({ purchaseId }: { purchaseId: string | number }): Promise<AxiosResponse> =>
    brAxios.put(`/ec-site/purchase/${purchaseId}/refund`),

  purchaseRefundAll: ({
    channelOrderId,
  }: {
    channelOrderId: string | number;
  }): Promise<AxiosResponse> => brAxios.put(`/ec-site/purchase/${channelOrderId}/refund-all`),

  purchaseUpdateDescription: ({
    id,
    params,
  }: {
    id: string | number;
    params: any;
  }): Promise<AxiosResponse> => brAxios.put(`/ec-site/purchase/${id}/update-description`, params),

  sendBlukEmail: (params: ParamBulkEmail): Promise<AxiosResponse> =>
    brAxios.post(`/ec-site/mail-template/send-mail-to-operator`, params),

  getSaleChannelGetApplicationUrls: (): Promise<AxiosResponse> =>
    brAxios.get(`/ec-site/sale-channel/get-application-urls`),
  getSections: (): Promise<AxiosResponse> => brAxios.get('/record-company/sections'),

  getPurchaseRateSetting: (hashPassword: string): Promise<AxiosResponse> =>
    brAxios.post(`/ec-site/buying-rate`, { hashPassword }),

  getSaleChannelTenantDetail: (): Promise<AxiosResponse> =>
    brAxios.get(`/ec-site/sale-channel/get-tenant-detail`),

  putSaleChannelClearPassword: (): Promise<AxiosResponse> =>
    brAxios.put(`/ec-site/sale-channel/clear-password`),

  updateSaleChannelPassword: (params: any): Promise<AxiosResponse> =>
    brAxios.put(`/ec-site/sale-channel/update-password`, params),

  getSaleChannelBranchs: (): Promise<AxiosResponse> =>
    brAxios.get(`/ec-site/sale-channel/get-branchs`),

  updatePurchaseRateSetting: (params: any): Promise<AxiosResponse> =>
    brAxios.put(`/ec-site/buying-rate`, params),

  getTranferGroupDate: (): Promise<AxiosResponse> =>
    brAxios.get(`/ec-site/transfers/group-date-only`),

  getTranferList: (
    year: string,
    order: string | undefined | null,
    sort: string | undefined | null,
  ): Promise<AxiosResponse> =>
    brAxios.get(
      `/ec-site/transfers?year=${year}${order ? `&order=${order}` : ''}${
        sort ? `&sort=${sort}` : ''
      }`,
    ),

  getTranferDetail: (
    id: string,
    page: number | undefined | null,
    limit: number,
  ): Promise<AxiosResponse> =>
    brAxios.get(`/ec-site/transfers/${id}${page ? `?page=${page}&limit=${limit}` : ''}`),

  tranferDetailExportExcel: (tranferId: string): Promise<AxiosResponse> =>
    brAxios.get(`ec-site/transfers/${tranferId}/export-excel?timezoneOffset=${timeZoneOffSet}`, {
      responseType: 'blob',
    }),

  tranferDetailExportPDF: (tranferId: string): Promise<AxiosResponse> =>
    brAxios.get(`ec-site/transfers/${tranferId}/export-pdf?timezoneOffset=${timeZoneOffSet}`, {
      responseType: 'blob',
    }),

  getPaymentSummaryDate: (): Promise<AxiosResponse> =>
    brAxios.get(`/ec-site/statistic/purchase-summary/group-date-only`),

  getPaymentSummaryList: (
    year: number,
    month: number,
    hashPassword: string,
    sort?: string,
    order?: string,
  ): Promise<AxiosResponse> =>
    brAxios.post(`ec-site/statistic/purchase-summary`, {
      year,
      month,
      hashPassword,
      sort,
      order,
    }),

  getPaymentSummaryDetail: (
    companyId: string,
    year: number | undefined | null,
    month: number | undefined | null,
    page: number,
    hashPassword: string,
  ): Promise<AxiosResponse> =>
    brAxios.post(`ec-site/statistic/purchase-summary/${companyId}`, {
      year,
      month,
      page,
      limit: 50,
      hashPassword,
    }),

  purchaseSummaryExportExcel: (
    companyId: string,
    year: number,
    month: number,
    hashPassword: string,
  ): Promise<AxiosResponse> =>
    brAxios.post(
      `ec-site/statistic/purchase-summary/${companyId}/export-excel?timezoneOffset=${timeZoneOffSet}`,
      {
        year,
        month,
        hashPassword,
      },
      { responseType: 'blob' },
    ),

  purchaseSummaryExportPDF: (
    companyId: string,
    year: number,
    month: number,
    hashPassword: string,
  ): Promise<AxiosResponse> =>
    brAxios.post(
      `ec-site/statistic/purchase-summary/${companyId}/export-pdf?timezoneOffset=${timeZoneOffSet}`,
      { year, month, hashPassword },
      { responseType: 'blob' },
    ),

  purchaseSummaryExportZipFile: (
    year: number,
    month: number,
    hashPassword: string,
  ): Promise<AxiosResponse> =>
    brAxios.post(
      `/ec-site/statistic/purchase-summary/zip-file?timezoneOffset=${timeZoneOffSet}`,
      { year, month, hashPassword },
      { responseType: 'blob' },
    ),

  EcValidatePassword: (password: string): Promise<AxiosResponse> =>
    brAxios.post(`/ec-site/sale-channel/validate-password`, { password }),
};
