import brAxios from 'Api/Interceptors';
import { ActionType } from 'Constant';

interface QueryParams {
  page?: number;
  perPage?: number;
  query?: string | number;
  type?: string;
  titleID?: number;
  artistId?: string;
  titleId?: string;
  companyId?: string;
  action?: number;
  showDraft?: boolean;
}

export const VariationApi = {
  getAllSuggestionVariations: ({
    page,
    perPage,
    query,
    artistId,
    titleId,
    companyId,
    action,
    showDraft,
  }: QueryParams) => {
    const response = brAxios.get(
      `/suggestion/variations?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }&artistId=${artistId ?? ''}&titleId=${titleId ?? ''}&companyId=${companyId ?? ''}&action=${
        action || ActionType.VIEW
      }&showDraft=${showDraft}`,
    );
    return response;
  },
  getAllVariationByTitleID: ({ titleID, page, perPage, query }: QueryParams) => {
    const response = brAxios.get(
      `/suggestion/variation/${titleID}?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }`,
    );
    return response;
  },
};
