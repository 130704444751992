import BoxForm from 'Components/Common/Form/BoxForm';
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import TableCost from 'Components/Page/CostInformation/TableCost';
import CostCDCard from 'Components/Page/CostInformation/CostCDCard';
import CardContainer from 'Components/Common/CardContainer';
import { RecordCompanyApi } from 'Datasource/RecordCompanyApi';
import { IRecordCompany } from 'Types/Page/CostInformation';

import useMessage from 'Hooks/useMessage';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { formatCurrency } from 'Utils/Numbers';

export default function CostInformation(): JSX.Element {
  const [recordCompany, setRecordCompany] = useState<IRecordCompany>();
  const [loading, setLoading] = useState<boolean>(false);

  const { openMessageError } = useMessage();

  useEffect(() => {
    getRecordCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRecordCompany = async () => {
    try {
      setLoading(true);
      const res = await RecordCompanyApi.getRecordCompany();
      const { data } = res.data;
      const recordData: IRecordCompany = {
        snFee: data.snFee ?? '1.0',
        snImportFee: data.snImportFee ?? '1.0',
        recordCompanyOptions: data.recordCompanyOptions ?? [],
      };
      setRecordCompany(recordData);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <CardContainer className="p-4">
        <BoxForm title="料金表詳細" classBox="ms-2 me-4">
          <div className="bg-white flex p-4">
            <p className="pb-1 underline-100 position-relative fw-bold text-break">
              miim利用基本料金
            </p>
            <div className="mt-2 pt-1">
              <TableCost dataTable={recordCompany?.recordCompanyOptions || []} />
            </div>

            <div className="ps-3 pb-4">
              <p className="pb-3 fw-bold text-break">
                {
                  '１回の購入につき　[販売金額（税抜）]　 ×　[サーバ・トラフィック費]　＋　[miim生成費]　の料金が発生します'
                }
              </p>
              <span className="ps-3 fs-12">
                楽曲登録数、データ容量の上限については営業担当までご相談ください。
              </span>
            </div>

            <div className="mt-5 pb-5">
              <p className="pb-1 underline-100 position-relative fw-bold text-break">
                シリアルコードオプション料金
              </p>
              <Col md="12" lg="10">
                <Row className="mt-4">
                  <Col md="12" lg="6">
                    <CostCDCard
                      unitPrice={formatCurrency(Number(recordCompany?.snFee)) || ''}
                      text="管理画面で登録した時点でオプション料金が発生します。"
                      cdLabel="CD封入シリアルコード発行料金"
                    />
                  </Col>
                  <Col md="12" lg="6">
                    <CostCDCard
                      unitPrice={formatCurrency(Number(recordCompany?.snImportFee)) || ''}
                      text="発行したコードをmiimアプリで読み込まれた時点で発生します。"
                      cdLabel="CD封入シリアルコード読み込み料金"
                    />
                  </Col>
                </Row>
              </Col>
            </div>
          </div>
        </BoxForm>
      </CardContainer>
    </>
  );
}
