import React, { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import Table from 'Components/Common/Table';
import Modal from 'Components/Common/Modal';
import SongInformation from 'Components/Page/Title/Variation/SongInfo';
import { Song } from 'Types/App/title';
interface ListSongProps {
  isReview?: boolean;
  variationName?: string;
  variationIndex: number;
  errors?: any[];
  setSongsToForm?: (songs: Song[]) => void;
  onClickError?: () => void;
  isDiabledEditSong?: boolean;
  songList: any;
}

export default function ListSong({
  isReview = false,
  variationName,
  variationIndex,
  errors = [],
  onClickError,
  isDiabledEditSong,
  songList,
}: ListSongProps): JSX.Element {
  const [visible, setVisible] = useState<boolean>(false);
  const [currentSong, setCurrentSong] = useState<number>();

  const columns = useMemo(
    () => [
      {
        title: '曲順',
        headerClass: 'w-15',
        key: 'index',
        dataIndex: 'index',
        render: (value: string) => <p>{value}</p>,
      },
      {
        title: '曲名',
        key: 'name',
        dataIndex: 'name',
        render: (value: string) => (
          <p className={`text-start text-truncate mw-35 ${!value ? 'gray' : ''}`}>
            {value ? value : '【楽曲が登録されていません】'}
          </p>
        ),
      },
      {
        title: '',
        key: 'sourceAudioUrl',
        dataIndex: 'sourceAudioUrl',
        render: (link: string, id?: number) => (
          <div className="d-flex align-items-center justify-content-end">
            {link && (
              <audio controls className="me-3 h-35px" key={link}>
                <track kind="captions" />
                <source src={process.env.REACT_APP_STATIC_URL + link} type="audio/mpeg" />
              </audio>
            )}
            <Button
              variant="primary"
              className="px-4 pe-4 text-keep-all"
              onClick={() => {
                setVisible(true);
                setCurrentSong(id);
              }}
            >
              {isReview ? '詳細' : '編集'}
            </Button>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSaveSongInfo = (songInfo: Song) => {
    console.log(songInfo);
  };

  return (
    <div>
      <Table
        tableKey="digital-song"
        columns={columns}
        dataSource={songList}
        errors={errors}
        onClickError={onClickError}
      />
      <Modal
        show={visible}
        onCloseModal={() => setVisible(false)}
        submitIdForm={`Variation-${variationIndex}-song-${currentSong}`}
        dialogClassName="max-w-85"
        footerClassName="justify-content-end"
        isReviewModal={isReview}
        title={
          isReview
            ? `${
                (songList && songList.find((item: Song) => item.index === currentSong)?.name) ??
                '【楽曲が登録されていません】'
              }の詳細`
            : `${
                variationName ?? '名称未設定バリエーション'
              }の${currentSong}曲⽬のデータを登録します`
        }
      >
        <SongInformation
          currentSong={currentSong}
          isDiabled={isDiabledEditSong}
          id={`Variation-${variationIndex}-song-${currentSong}`}
          onCloseModal={() => setVisible(false)}
          isReview={isReview}
          submitSongInfo={handleSaveSongInfo}
          songInfo={(songList && songList.find((item: any) => item.index === currentSong)) || {}}
        />
      </Modal>
    </div>
  );
}
