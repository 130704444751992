import React, { forwardRef } from 'react';
import { useAppSelector } from 'App/Store';
import CardContainer from 'Components/Common/CardContainer';
import { Button, Container } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import ViewOnlyField from '../Campaign/RegisterCampaign/Common/ViewOnlyField';

const Final = forwardRef((): JSX.Element => {
  const {
    titleForm: { managerList },
  } = useAppSelector((state) => state.title);
  const history = useHistory();

  return (
    <Container fluid>
      <CardContainer title="登録が完了しました" darkHeader className="mx-4 mt-3 mx-4">
        <p className="mb-3">以下アーティスト担当者へ登録通知メールを送信しました。</p>
        <div className="mx-3">
          {managerList.map((manger: any, index: number) => {
            return (
              <ViewOnlyField
                key={index}
                labelSize={2}
                valueSize={2}
                label={manger?.section}
                value={manger?.managerName}
              />
            );
          })}
        </div>
      </CardContainer>

      <CardContainer title="CDマスタ登録のお願い" darkHeader className="mx-4 mt-3 mx-4">
        <ul>
          <li>
            miim商品と紐づくCDマスタ情報を登録することで、miimとCDとの連動キャンペーンがご利用いただけます。
          </li>
          <li>
            株式会社ジャパンミュージックデータ
            のサービス「eCATS」上でmiim商品情報とCDマスタ情報が紐づけられます。
          </li>
        </ul>
        <div className="d-flex justify-content-center mt-5 mb-3">
          <Button
            onClick={() => history.push('/create-cd-master')}
            variant="primary me-5"
            className="ms-4 w-100"
            style={{ maxWidth: '250px' }}
          >
            CDマスタ登録へ進む
          </Button>
        </div>
      </CardContainer>

      <div className="d-flex justify-content-center align-items-center mt-4 py-4">
        <Button variant="secondary" className="btn-action">
          <Link to="/title/list" className="text-white text-decoration-none">
            タイトルリストへ戻る
          </Link>
        </Button>
        <Button
          onClick={() => history.push('/register-campaign')}
          variant="primary me-5"
          className="ms-4"
        >
          このマスタタイトルのキャンペーンを作成
        </Button>
      </div>
    </Container>
  );
});

export default Final;
