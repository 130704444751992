import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Cell } from 'recharts';
import { Japan_Provinces } from 'Constant/Japan';

export default function ProvincesChart(): JSX.Element {
  const data = Japan_Provinces.map((province: { id: number; name: string }) => ({
    name: province.name,
    value: Math.floor(Math.random() * 10000000) + 1,
  }));

  return (
    <BarChart
      width={1200}
      height={500}
      data={data}
      margin={{
        top: 40,
        right: 20,
        bottom: 20,
        left: 20,
      }}
    >
      <Bar dataKey="value" fill="#00a0fc" stroke="#000000" strokeWidth={1} barSize={10}>
        {data.map((entry: any, index: number) => {
          const color =
            (entry.value / 10000000) * 100 > 25
              ? '#fc0000'
              : (entry.value / 10000000) * 100 > 10
              ? '#EDA616'
              : '#70AD47';
          return <Cell key={`cell-${index}`} fill={color} />;
        })}
      </Bar>

      <XAxis dataKey="name" angle={-90} textAnchor="end" interval={0} />
      <YAxis yAxisId={0} dataKey="value" />
      <YAxis yAxisId={1} orientation="right" dataKey="value" />
    </BarChart>
  );
}
