import { yupResolver } from '@hookform/resolvers/yup';
import BoxForm from 'Components/Common/Form/BoxForm';
import FormLabel from 'Components/Common/Form/FormLabel';
import MessageError from 'Components/Common/MessageError';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import _ from 'lodash';
import ModalComponent from 'Components/Common/Modal';
import TooltipsWithTruncate from 'Components/Common/Tooltips/TooltipsWithTruncate';
import { ParamsGetShops, ParamsUpdateShops, ShopAPIs } from 'Datasource/Shop';
import useMessage from 'Hooks/useMessage';
import { maxMsg } from 'Utils/Validation';

interface ShopsType {
  name: string;
  id?: number;
  company_id?: number;
  action?: 'ADD' | 'EDIT' | 'DELETE';
}

export interface ShopItem {
  [key: string]: any;
}

const schemaAdd = (data: Array<ShopsType>) => {
  return yup.object().shape({
    name: yup
      .string()
      .trim()
      .required('入力してください。')
      .max(255, maxMsg(255))
      .test('', '店舗名が既に存在しています。別の店舗名で登録してください。', (values) => {
        if (_.isEmpty(data)) {
          return true;
        } else {
          return !data
            .filter((e: ShopsType) => e?.action !== 'DELETE')
            .some((e: ShopsType) => e.name === values);
        }
      }),
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function RegisterShop() {
  const [listShop, setListShop] = useState<Array<ShopsType>>([]);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { openMessageError } = useMessage();

  /**
   * Add shop useForm
   */
  const {
    handleSubmit: handleSubmitAdd,
    register: registerAdd,
    reset: resetAdd,
    formState: { errors: errorsAdd },
  } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schemaAdd(listShop)),
  });

  const handleAddShop = (data: ShopsType) => {
    setListShop([{ ...data, action: 'ADD' }, ...listShop]);
    resetAdd();
  };

  const handleValidateListShops = async () => {
    onModalConfirm(true);
  };

  const handleDeleteShop = (index: number, shopDeleted: ShopsType) => {
    const listShopTmp = [...listShop];

    if (shopDeleted.id) {
      listShopTmp[index].action = 'DELETE';
      setListShop(listShopTmp);
      return;
    }

    if (index > -1) {
      listShopTmp.splice(index, 1);
      setListShop(listShopTmp);
      return;
    }
  };

  const handleGetAllShop = async () => {
    setIsLoading(true);
    try {
      const params: ParamsGetShops = {
        page: 1,
        limit: 999999,
      };

      const {
        data: { data },
      } = await ShopAPIs.getShops(params);

      setListShop(data.items);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Modal confirm save shops
   * @param isOpen
   */
  const onModalConfirm = (isOpen: boolean) => {
    setIsOpenConfirm(isOpen);
  };

  const handleSaveShops = async () => {
    try {
      setIsLoading(true);

      const new_shops: string[] = listShop
        .filter((item) => item?.action === 'ADD')
        .map((item) => item.name);

      const shop_delete_ids: number[] = listShop
        .filter((item) => item?.action === 'DELETE')
        .map((item: any) => {
          return +item.id;
        });

      const paramsUpdate: ParamsUpdateShops = {
        new_shops,
        shop_delete_ids,
      };
      await ShopAPIs.updateShops(paramsUpdate);

      handleGetAllShop();
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
      onModalConfirm(false);
    }
  };

  useEffect(() => {
    handleGetAllShop();
  }, []);

  return (
    <Container className="mt-4">
      <SpinnerComponent isLoading={isLoading} />

      <Row>
        <Col md="12" className="px-0">
          <BoxForm title="店舗登録">
            <Form
              id="shop-add"
              onSubmit={handleSubmitAdd(handleAddShop)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            >
              <Row className="d-flex justify-content-center mb-2">
                <FormLabel colMd="9" label="新しい店舗の登録" />
              </Row>

              <Row className="d-flex justify-content-center">
                <Col md={8}>
                  <Form.Control type="text" {...registerAdd('name')} />
                  <MessageError message={errorsAdd.name?.message} />
                </Col>

                <Col md={1} className="d-flex justify-content-end">
                  <Button
                    className="btn h-max-content btn-focus-none"
                    type="submit"
                    onClick={handleSubmitAdd(handleAddShop)}
                  >
                    追加
                  </Button>
                </Col>
              </Row>
            </Form>

            <hr className="mt-5" />

            <Row className="d-flex justify-content-center mb-2">
              <Col md="9">登録済み店舗一覧</Col>
            </Row>

            <div>
              {listShop.map((item: ShopsType, index: number) => {
                if (item?.action !== 'DELETE')
                  return (
                    <Row key={index} className="justify-content-center align-items-end">
                      <Col md={8} className="mt-2">
                        <TooltipsWithTruncate
                          id={`${index}`}
                          tooltips={item.name}
                          extraClientWidth={20}
                        >
                          <Form.Control
                            value={item.name}
                            type="text"
                            className="text-truncate"
                            disabled
                          />
                        </TooltipsWithTruncate>
                      </Col>
                      <Col md={1} className="d-flex justify-content-end">
                        <Button
                          className="btn btn-danger h-max-content "
                          onClick={() => handleDeleteShop(index, item)}
                        >
                          削除
                        </Button>
                      </Col>
                    </Row>
                  );
              })}
            </div>
          </BoxForm>

          <div className="d-flex justify-content-center mb-5">
            <Button
              className="mt-4 mb-4  btn-focus-none btn-curator-register"
              variant="primary"
              onClick={handleValidateListShops}
            >
              保存する
            </Button>
          </div>
        </Col>
      </Row>

      <ModalComponent
        show={isOpenConfirm}
        onCloseModal={() => onModalConfirm(false)}
        onSubmitModal={handleSaveShops}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
      >
        <div>入力した内容で登録します。よろしいですか。</div>
      </ModalComponent>
    </Container>
  );
}

export default RegisterShop;
