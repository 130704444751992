import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ProductItem {
  id: string | null;
  name: string | null;
  email: string | null;
  contactName: string | null;
  titleId: number | null;
  titleStatus: number | null;
  type: number | null;
  productChannel?: {
    channelName: string | null;
    email: string | null;
    id: number;
    name: string | null;
  }[];
  info: {
    campaignAndCards: any[];
  } | null;
}

export interface ProductState {
  productCurrent: ProductItem;
}

const initialState: ProductState = {
  productCurrent: {
    id: null,
    name: null,
    email: null,
    contactName: null,
    titleId: null,
    titleStatus: null,
    type: null,
    productChannel: [],
    info: null,
  },
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setCurrentProduct: (state, data: PayloadAction<ProductItem>) => {
      const payload = data.payload;
      state.productCurrent = payload;
    },
  },
});

export const { setCurrentProduct } = productSlice.actions;
export default productSlice.reducer;
