import { useAppSelector } from 'App/Store';
import CardContainer from 'Components/Common/CardContainer';
import Booklet from 'Components/Common/Dropzone/Booklet';
import BoxDropZone from 'Components/Common/Dropzone/BoxDropZone';
import DigitalSong from 'Components/Page/Title/Copm/DigitalSong';
import GroupTextProps from 'Components/Page/Title/FormTextGroup';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import DigitalImage from 'Components/Page/Title/Copm/DigitalImage';
import DigitalVideo from 'Components/Page/Title/Copm/DigitalVideo';
import { convertPriceApple, handleConvertJmdCooperation } from 'Utils/Title';
import dayjs from 'dayjs';
import { DATE_FORMAT_2 } from 'Constant/Date';
import { formatPrice } from 'Utils/Numbers';
import MessageError from 'Components/Common/MessageError';
import { INCLUDE_JARSAC_CONTENT, LINK_JMD_TYPE_CONTENT } from 'Constant/Title';
import { TitleApi } from 'Datasource/TitleApi';

interface VariationInformationProps {
  data?: any;
  errors?: any;
  onClickError?: any;
  numberOfVariations?: number;
  nameRef?: any;
  musicFormatRef?: any;
  saleTokenDateRef?: any;
  downloadDateRef?: any;
  notiDownloadTimeRef?: any;
  productCodeRef?: any;
  janCodeRef?: any;
  priceRef?: any;
  coverImageUrlRef?: any;
  bookletsRef?: any;
  songsRef?: any;
  videosRef?: any;
  imagesRef?: any;
  jarsacContentRef?: any;
  jmdCooperationRef?: any;
  riajGenreIdRef?: any;
  jmdMusicGenreIdRef?: any;
  jmdVideoGenreRef?: any;
  riajGenreData?: any[];
}

export default function VariationInformation(props: VariationInformationProps): JSX.Element {
  const {
    data,
    errors,
    onClickError,
    numberOfVariations,
    nameRef,
    musicFormatRef,
    saleTokenDateRef,
    downloadDateRef,
    notiDownloadTimeRef,
    productCodeRef,
    janCodeRef,
    priceRef,
    coverImageUrlRef,
    bookletsRef,
    songsRef,
    videosRef,
    imagesRef,
    jarsacContentRef,
    jmdCooperationRef,
    riajGenreIdRef,
    jmdMusicGenreIdRef,
    riajGenreData,
  } = props;
  const {
    titleForm: { variations },
  } = useAppSelector((state) => state.title);

  const [nameJpMusicJmd, setNameJpMusicJmd] = useState('');

  const detailMusicJmd = async (id: number) => {
    if (!id) return;
    const {
      data: { data },
    } = await TitleApi.getJmdMusicGenreById(id);
    return setNameJpMusicJmd(data.nameJp);
  };

  useEffect(() => {
    if (data.jmdMusicGenreId) {
      detailMusicJmd(data.jmdMusicGenreId);
    } else {
      setNameJpMusicJmd('');
    }
    return () => {
      setNameJpMusicJmd('');
    };
  }, [data]);

  const riajGenreNameJp = useMemo(() => {
    return riajGenreData?.find((item) => item.value === data.riajGenreId)?.label;
  }, [data.riajGenreId, riajGenreData]);

  return (
    <>
      <CardContainer
        title={`バリエーション情報 (${data?.index + 1}/${
          numberOfVariations || variations?.length
        })`}
        darkHeader
        className="mx-4 mt-3 border-0 shadow-none"
      >
        <Row>
          <Col>
            <Row className="mt-2">
              <Col md="5">タイトルタイプ</Col>
              <Col md="7">{data?.option?.titleType || ''}</Col>
            </Row>
            <div ref={nameRef}>
              <GroupTextProps
                mdLable={5}
                label="バリエーション名"
                value={data?.name}
                errorContent={errors?.name}
                onClickError={onClickError}
              />
            </div>
            <div ref={jarsacContentRef}>
              <GroupTextProps
                mdLable={5}
                label="著作権管理"
                value={
                  data?.includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE
                    ? 'バリエーション内の楽曲または動画に著作権管理コンテンツを含む'
                    : data?.includeJasracContent === INCLUDE_JARSAC_CONTENT.NOT_INCLUDE
                    ? '含まない'
                    : ''
                }
                errorContent={errors?.includeJasracContent}
                onClickError={onClickError}
              />
            </div>
            <div ref={jmdCooperationRef}>
              <GroupTextProps
                mdLable={5}
                label="jmd連携"
                value={handleConvertJmdCooperation(data?.jmdCooperation) ?? ''}
                errorContent={errors?.jmdCooperation}
                onClickError={onClickError}
              />
            </div>
            {data?.jmdCooperation === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER && (
              <>
                <div ref={riajGenreIdRef}>
                  <GroupTextProps
                    mdLable={5}
                    label="日本レコード協会ジャンル"
                    value={riajGenreNameJp}
                    errorContent={errors?.riajGenreId}
                    onClickError={onClickError}
                  />
                </div>
                <div ref={jmdMusicGenreIdRef}>
                  <GroupTextProps
                    mdLable={5}
                    label="jmd音楽ジャンル"
                    value={nameJpMusicJmd}
                    errorContent={errors?.jmdMusicGenreId}
                    onClickError={onClickError}
                  />
                </div>
              </>
            )}
            <div ref={musicFormatRef}>
              <GroupTextProps
                mdLable={5}
                label="楽曲フォーマット"
                value={
                  data?.musicFormat === 0
                    ? 'MP3 192kbps'
                    : data?.musicFormat === 1
                    ? 'WAV 24bit 96kHz (ハイレゾ)'
                    : ''
                }
                errorContent={errors?.musicFormat}
                onClickError={onClickError}
              />
            </div>
            <div ref={productCodeRef}>
              <GroupTextProps
                mdLable={5}
                label="品番"
                value={data?.productCode}
                errorContent={errors?.productCode}
                onClickError={onClickError}
              />
            </div>
            <div ref={janCodeRef}>
              <GroupTextProps
                mdLable={5}
                label="JANコード"
                value={data?.janCode}
                errorContent={errors?.janCode}
                onClickError={onClickError}
              />
            </div>
            <div ref={priceRef}>
              <GroupTextProps
                mdLable={5}
                label="販売価格"
                value={`${data?.price ? `${formatPrice(data?.price)}円（税込）` : ''}`}
                errorContent={errors?.price}
                onClickError={onClickError}
                mdValue={8}
                errorClass={'px-0 fs-13'}
              />
            </div>
            <div ref={saleTokenDateRef}>
              <GroupTextProps
                mdLable={5}
                label="情報公開解禁日時"
                value={data?.saleTokenDate && dayjs(data?.saleTokenDate).format(DATE_FORMAT_2)}
                errorContent={errors?.saleTokenDate}
                onClickError={onClickError}
              />
            </div>
            <div ref={downloadDateRef}>
              <GroupTextProps
                mdLable={5}
                label="DL開始日時（発売日時）"
                value={data?.downloadDate && dayjs(data?.downloadDate).format(DATE_FORMAT_2)}
                errorContent={errors?.downloadDate}
                onClickError={onClickError}
              />
            </div>
            <div ref={notiDownloadTimeRef}>
              <GroupTextProps
                mdLable={5}
                label="DL開始通知時刻"
                value={data?.notiDownloadTime ? dayjs(data?.notiDownloadTime).format('HH:mm') : ''}
                errorContent={errors?.notiDownloadTime}
                onClickError={onClickError}
              />
            </div>
            <div ref={priceRef}>
              <GroupTextProps
                mdLable={5}
                label="Apple経由販売価格"
                value={`${data?.price ? `${formatPrice(convertPriceApple(data?.price))}円` : ''}`}
                onClickError={onClickError}
              />
            </div>
          </Col>
          <Col>
            <Row className="mt-2">
              <Col md="3">ジャケット画像</Col>
              <Col className="justify-content-end align-items-end">
                <div className="pe-none" ref={coverImageUrlRef}>
                  <BoxDropZone
                    maxFiles={1}
                    key={`cover-image-${data?.index}`}
                    coverImageUrl={data?.coverImageUrl}
                  />
                </div>
                <div className="d-flex" onClick={onClickError} onKeyDown={onClickError}>
                  <MessageError message={errors?.coverImageUrl?.message} />
                </div>
              </Col>
            </Row>
          </Col>
          <Row className="mt-2">
            <Col md="2">
              ブックレット画像 <p>(600px X 600px)</p>
            </Col>

            <Col md="10">
              <div ref={bookletsRef}>
                <Booklet variationIndex={data?.index} listBooklets={data?.booklets} isReview />
                <div onClick={onClickError} onKeyDown={onClickError}>
                  <MessageError message={errors?.booklets?.message} />
                </div>
              </div>
            </Col>
          </Row>
          <div ref={songsRef}>
            <DigitalSong
              data={data}
              errors={errors?.songs}
              onClickError={onClickError}
              riajGenreData={riajGenreData}
            />
          </div>
          <div ref={videosRef}>
            <DigitalVideo
              data={data}
              errors={errors?.videos}
              errorsForm={errors}
              onClickError={onClickError}
              riajGenreData={riajGenreData}
            />
          </div>
          <div ref={imagesRef}>
            <DigitalImage data={data} errors={errors?.images} onClickError={onClickError} />
          </div>
          <div onClick={onClickError} onKeyDown={onClickError}>
            <MessageError message={errors?.videos?.message} />
          </div>
        </Row>
      </CardContainer>
    </>
  );
}
