import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { FormGroupSearchProps } from 'Components/Common/Form/form';
import MessageError from 'Components/Common/MessageError';
import { labelRequire } from 'Components/Common/Form/FormLabel';
import AsyncSearchComponent_2 from 'Components/Common/Select/CustomSelect/AsyncSearchComponent_2';

export default function FormGroupSearch(props: FormGroupSearchProps): JSX.Element {
  const {
    label,
    classLabel,
    onSearch,
    onSelect,
    placeholder,
    labelMd,
    colMd,
    colLg,
    labelLg,
    labelXXL,
    colXXL,
    labelXL,
    colXL,
    rowClass,
    colClass,
    showIcon = false,
    showIconClear = false,
    onEnter,
    defaultValue,
    errorMessage,
    inputSearchString = '',
    onInputChange,
    isReview,
    disabled,
    onLoadMore,
    onMenuClose,
    isLoading,
    options,
    onMenuFocus,
    onClear,
    styleLabel = {},
    styleCol = {},
  } = props;
  return (
    <Form.Group as={Row} className={`${rowClass} ${isReview && 'pe-none'} `}>
      {label && (
        <Form.Label
          style={styleLabel}
          className={classLabel}
          column
          md={labelMd}
          lg={labelLg}
          xl={labelXL}
          xxl={labelXXL}
        >
          {labelRequire(label)}
        </Form.Label>
      )}
      <Col md={colMd} lg={colLg} xl={colXL} xxl={colXXL} className={colClass} style={styleCol}>
        <AsyncSearchComponent_2
          onInputChange={onInputChange}
          defaultValue={defaultValue}
          onEnter={onEnter}
          placeholder={placeholder}
          onSearch={(keyword: string) => onSearch && onSearch(keyword)}
          onSelect={onSelect}
          showIcon={showIcon}
          showIconClear={showIconClear}
          inputSearchString={inputSearchString}
          disabled={disabled}
          onLoadMore={onLoadMore}
          onMenuClose={onMenuClose}
          isLoading={isLoading}
          options={options}
          onMenuFocus={onMenuFocus}
          onClear={onClear}
        />
        <MessageError message={errorMessage} />
      </Col>
    </Form.Group>
  );
}
