/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import Results from 'Components/Common/Select/CustomSelect/Results';
import 'Components/Common/Select/CustomSelect/css/index.scss';
import { ISelect } from 'Types/Common';
import { useAppSelector } from 'App/Store';
import SelectOption from './SelectOption';

interface SelectOptionComponentProps {
  onSearch: (keyword: string) => any;
  onSelect?: (result: ISelect) => void;
  showIcon?: boolean;
  maxResults?: number;
  placeholder?: string;
  resultStringKeyName?: string;
  inputSearchString?: string;
  formatResult?: (val: string | undefined) => React.ReactNode;
  onEnter?: (keyword: string) => void;
  inputDebounce?: number;
  defaultValue?: string | undefined;
  onInputChange?: (value: string) => void;
  disabled?: boolean;
  showIconClear?: boolean;
  onLoadMore?: (event: any) => void;
  onMenuClose?: () => void;
  options?: ISelect[];
  isLoading?: boolean;
  onMenuFocus?: () => void;
  stylesListResult?: React.CSSProperties;
}

export default function SelectOptionComponent(props: SelectOptionComponentProps): JSX.Element {
  const {
    onSearch,
    onSelect,
    showIcon,
    showIconClear,
    maxResults = 10,
    placeholder,
    resultStringKeyName,
    inputSearchString = '',
    formatResult,
    defaultValue,
    inputDebounce = 500,
    onInputChange,
    onEnter = null,
    disabled,
    onLoadMore,
    onMenuClose,
    options,
    isLoading,
    onMenuFocus,
    stylesListResult = {},
  } = props;

  const [searchString, setSearchString] = useState(inputSearchString);

  const [toggleView, setToggleView] = useState<boolean>(false);

  const { filterByText } = useAppSelector((state) => state.pullDown);
  useEffect(() => {
    if (defaultValue) {
      setSearchString(defaultValue);
    } else {
      setSearchString('');
    }
  }, [defaultValue]);

  const handleOnSearch = debounce((keyword) => {
    // onSearch && onSearch(keyword);
    onInputChange && onInputChange(keyword);
  }, inputDebounce);

  useEffect(() => {
    setSearchString(inputSearchString ?? '');
  }, [inputSearchString]);

  const handleOnClick = (result: ISelect) => {
    setToggleView(false);
    onMenuClose && onMenuClose();
    onSelect && onSelect(result);
  };

  const handleOnEnter = (e: React.KeyboardEvent<EventTarget>) => {
    if (e.key === 'Enter') {
      if (onEnter) {
        onEnter(searchString);
        setToggleView(false);
      } else {
        onSearch && onSearch(searchString);
      }
    }
  };

  const handleSetSearchString = ({ target }: { target: any }) => {
    const keyword = target.value;
    setSearchString(keyword);
    handleOnSearch(keyword);
    setToggleView(true);
  };
  const onBlurSearchIpt = () => {
    if (defaultValue && !filterByText) {
      setSearchString(defaultValue);
    }
    if (filterByText) {
      if (onEnter) {
        onEnter(searchString);
      }
    }
    setToggleView(false);
    onMenuClose && onMenuClose();
  };

  const onClear = () => {
    setSearchString('');
    handleOnSearch('');
    setToggleView(false);
  };

  return (
    <div className={`custom-select ${toggleView}`}>
      <div className={`wrapper ${disabled ? 'disable-search' : ''}`}>
        <SelectOption
          searchString={searchString}
          setSearchString={handleSetSearchString}
          onBlur={onBlurSearchIpt}
          onFocus={() => {
            if (!toggleView && onMenuFocus) onMenuFocus();
            setToggleView(true);
          }}
          placeholder={placeholder}
          showIcon={showIcon}
          showIconClear={showIconClear}
          onEnter={handleOnEnter}
          onClear={onClear}
        />
        <Results
          results={options || []}
          onClick={handleOnClick}
          setSearchString={setSearchString}
          maxResults={maxResults}
          resultStringKeyName={resultStringKeyName}
          formatResult={formatResult}
          isShowResult={toggleView}
          isLoading={isLoading}
          onLoadMore={(event: any) => {
            onLoadMore && onLoadMore(event);
          }}
          stylesListResult={stylesListResult}
        />
      </div>
    </div>
  );
}
