import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import MessageError from 'Components/Common/MessageError';
import uploadMedia from 'Utils/UploadFile';
import { TYPE_MEDIA } from 'Constant';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import SpinnerComponent from 'Components/Common/SpinnerComponent';

const SerialNumberGift = (): JSX.Element => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleOnDrop = async (acceptedFiles: File[]) => {
    try {
      setIsLoading(true);
      const url = await uploadMedia(acceptedFiles[0], `${TYPE_MEDIA.CAMPAIGN}`);
      setValue('type5.imageUrl', url);
    } finally {
      setIsLoading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png,image/gif',
    maxFiles: 1,
    onDrop: handleOnDrop,
  });

  return (
    <>
      <SpinnerComponent isLoading={isLoading} />
      <Row className="serial-number-gift-form">
        <hr className="my-3" />
        <Col className="mx-5">
          <FormGroupInput
            label="表示名称"
            labelMd="3"
            colMd="9"
            value={watch('type5.eventName')}
            register={register('type5.eventName')}
            onChange={(value) => setValue('type5.eventName', value)}
            classForm="form-with-label-nowrap"
            errorMessage={errors.type5?.eventName?.message}
            hintText="当選したユーザーのアプリに表示される名称を設定します。"
          />
          <Row className="mb-3">
            <Col md="3">
              景品画像
              <br /> （1点）
            </Col>
            <Col md="9">
              <div className="mb-2">
                <Button variant="primary" {...getRootProps()}>
                  画像をアップロードする
                  <input {...getInputProps()} />
                </Button>
              </div>
              <div>
                <div {...getRootProps({ className: 'preview-image' })}>
                  <input {...getInputProps()} />
                  <img
                    className="border rounded file-box"
                    src={handleConnectStaticEndPoint(watch('type5.imageUrl'))}
                    alt=""
                  />
                </div>
              </div>
              <MessageError message={errors.type5?.imageUrl?.message} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default SerialNumberGift;
