import { useState } from 'react';
import useMessage from 'Hooks/useMessage';
import { CampaignApis } from 'Datasource/Campaign';
import { useAppDispatch } from 'App/Store';
import {
  CampaignSteps,
  changeStep,
  updateCampaign,
  setOldTitleId,
  replacePrizes,
} from 'App/Features/Campaign';
import { updateMailList } from 'App/Features/MailRecipientList';
import { campaignOptionsType, CampaignStatus, subTypeOptions } from 'Constant/Campaign';
import { includes, isEmpty, mapValues, omit, some, toNumber } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'App/Store';
import { CourseCampaign, Prize } from 'Types/Page/Campaign/RegisterCampaign';
import { AppRouter } from 'Types/Router';

const useCampaignDraft = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [dataRes, setDataRes] = useState<any>();
  const { openMessageError, openMessage } = useMessage();
  const dispatch = useAppDispatch();
  const { overview } = useAppSelector((state) => state.campaign);

  const handleChangeStep = (newStep: CampaignSteps) => {
    dispatch(changeStep({ step: newStep }));
  };

  const handleDecodeOverView = (data: any) => {
    const result = {
      artistId: data?.artistId,
      artistName: data?.artistName,
      titleId: data?.titleId,
      titleName: data?.titleName,
      name: data?.name,
      startDate: data?.startDate,
      endDate: data?.endDate,
      description: data?.description,
      dataSize: data?.dataSize ?? 0,
      status: data?.status ?? 0,
      isDeletable: data?.isDeletable,
      earliestSaleDate: data?.earliestSaleDate,
      isAuthor: data?.isAuthor,
    };

    return result;
  };

  const handleNormalizationData = (courses: any) => {
    let status = CampaignStatus.Official;

    const tmpCourses = courses.map((course: any, courseIndex: number) => {
      const courseInfos = course.courseInfos.map((v: any) => {
        return {
          ...v,
          enabled: Number(v.enabled),
          option1: Number(v.option1),
          option2: Number(v.option2),
          option3: Number(v.option3),
          option4: Number(v.option4),
        };
      });

      const prizes = course.prizes.map((p: any, pIndex: number) => {
        if (p.type === campaignOptionsType[3].value) {
          const isEmptyFile = isEmpty(p.prizeDigitalTokutens)
            ? true
            : some(p.prizeDigitalTokutens, (prizeDT) => {
                return !prizeDT.url;
              });

          status = isEmptyFile ? CampaignStatus.NoDigital : CampaignStatus.Official;
        }
        const prizeDigitalTokutens = p?.prizeDigitalTokutens?.map((d: any) => {
          return { ...d, percentage: d.percentage ? `${d.percentage}` : null };
        });
        let matrix = '';
        mapValues(p, (o, k) => {
          if (includes(k, 'condition')) {
            matrix = `${matrix}${o ? o : 0}`;
          }
        });
        return {
          ...p,
          index: pIndex,
          matrix,
          onlyOne: p.onlyOne ? 1 : 0,
          abandoned: p?.abandoned ? 1 : 0,
          userQuantity: p?.userQuantity ? +p?.userQuantity : null,
          prizeDigitalTokutens,
          winRate: +p?.winRate || 0,
          isIncentive: p?.isIncentive || false,
        };
      });

      const totalWinRate = course[courseIndex]?.prizes?.reduce(
        (total: number, currentValue: Prize) => {
          const currentWinRate = currentValue.winRate ? +currentValue.winRate : 0;
          return total + currentWinRate;
        },
        0,
      );

      let slipRate = 0;
      if (!course?.prizes?.length) {
        slipRate = 100;
      }

      if (100 - +totalWinRate > 0) {
        slipRate = +(100 - +totalWinRate).toFixed(2);
      } else {
        slipRate = 0;
      }

      const hasIncentive = course.prizes.some(
        (prize: Prize) => !prize?.userQuantity && prize.isIncentive,
      );

      if (hasIncentive) {
        slipRate = 0;
      }

      const prizeSorted = () => {
        let tmpPrize: Prize[] = [];
        if (prizes.length) {
          const prizeNotIncentive = prizes.filter((p: Prize) => !p.isIncentive);
          const prizeIncentive = prizes.filter((p: Prize) => p.isIncentive);
          tmpPrize = [...prizeNotIncentive, ...prizeIncentive].map((item, i) => ({
            ...item,
            index: i,
          }));
        }
        return tmpPrize;
      };

      return {
        ...course,
        courseInfos,
        prizes: prizeSorted(),
        countJoinStart: course?.countJoinStart ? +course?.countJoinStart : course?.countJoinStart,
        countJoinEnd: course?.countJoinEnd ? +course?.countJoinEnd : course?.countJoinEnd,
        ticketPrice: course?.ticketPrice ? +course?.ticketPrice : course?.ticketPrice,
        status,
        subType: course?.subType ? +course?.subType : subTypeOptions[0].value,
        slipRate: slipRate,
        prizeQuantity: course?.prizeQuantity ? course?.prizeQuantity : '',
      };
    });
    return tmpCourses;
  };

  const saveOrUpdateDraft = async (dataInput: any) => {
    setIsLoading(true);

    const courseCopy = dataInput?.courses?.map((course: any) => {
      const courseInfos = course.courseInfos.map((info: any) => omit(info, 'id'));
      const blackListUsers = course?.blackListUsers?.map((blackListUser: any) =>
        omit(blackListUser, 'id'),
      );
      const prizes = course.prizes.map((prize: any) => {
        const prizeDigitalTokutens = prize?.prizeDigitalTokutens?.map((dGT: any) => {
          dGT = omit(dGT, 'id');
          return dGT?.percentage
            ? { ...dGT, percentage: `${dGT.percentage}` }
            : { ...dGT, percentage: dGT.percentage ? dGT.percentage : null };
        });
        prize = {
          ...prize,
          prizeDigitalTokutens,
        };
        const parseField = {
          productQuantity: prize?.productQuantity
            ? `${prize?.productQuantity}`
            : prize?.productQuantity,
          seatNumber: prize?.seatNumber ? `${prize?.seatNumber}` : prize?.seatNumber,
          prizeDigitalTokutens,
        };
        if (Number(prize?.type) === 2) {
          prize = {
            ...prize,
            seriesNumberMasterId: null,
            seriesNumberMasterName: null,
            seriesNumberMasterTotal: 0,
          };
        }
        return { ...omit(prize, 'id'), ...parseField };
      });
      return { ...omit(course, 'id'), courseInfos, prizes, blackListUsers };
    });

    const courses = handleNormalizationData(!dataInput?.id ? courseCopy : dataInput?.courses);
    courses.forEach((course: any) => {
      if (!course.prizeQuantity) {
        course.prizeQuantity = null;
      }
    });
    const status = dataInput?.status ? dataInput?.status : CampaignStatus.Draft;
    await (async function fetchData() {
      try {
        if (!dataInput?.id) {
          const {
            data: { data },
          } = await CampaignApis.postDraft({
            params: { courses, ...dataInput?.overview, status },
          });
          setDataRes(data);

          if (data?.id) {
            history.push(`/campaigns/${data?.id}`, { isSaveDraft: dataInput?.step });
          }
        }
        if (dataInput?.id) {
          const {
            data: { data },
          } = await CampaignApis.updateDraft({
            id: dataInput?.id,
            params: { courses, ...dataInput?.overview, status },
          });
          setDataRes(data);
          dispatch(
            updateCampaign({
              overview: { ...handleDecodeOverView(data), dataSize: +data?.dataSize },
              courses: handleDecodeMatrixPrizes(data?.courses),
            }),
          );
          if (dataInput?.status) {
            dispatch(updateMailList({ mailList: data?.mailList, isSuccess: true }));
            dispatch(changeStep({ step: CampaignSteps.Success }));
          }
        }
        if (!dataInput?.status) {
          openMessage({
            variant: 'success',
            message: '一時保存しました。',
          });
        }
        setIsSuccess(true);
      } catch (error) {
        setIsSuccess(false);
        openMessageError(error);
      } finally {
        setIsLoading(false);
      }
    })();
  };

  const saveOrUpdateOfficial = async (dataInput: any) => {
    const courses = handleNormalizationData(dataInput?.courses);
    courses.forEach((course: any) => {
      if (!course.prizeQuantity) {
        course.prizeQuantity = null;
      }
    });
    const isNoDigital = some(courses, (course) => {
      return course?.status === CampaignStatus.NoDigital;
    });
    const params = {
      courses,
      ...dataInput?.overview,
      status: isNoDigital ? CampaignStatus.NoDigital : CampaignStatus.Official,
    };

    await (async function fetchData() {
      try {
        setIsLoading(true);
        if (!dataInput?.id) {
          const {
            data: { data },
          } = await CampaignApis.postOfficial({
            params,
          });
          setDataRes(data);
          dispatch(updateMailList({ mailList: data?.mailList, isSuccess: true }));

          history.push(`${AppRouter.CAMPAIGN_DETAIL}/${data?.id}`, {
            isSaveDraft: CampaignSteps.Success,
          });
        }
        if (dataInput?.id) {
          const {
            data: { data },
          } = await CampaignApis.updateOfficial({
            id: dataInput?.id,
            params,
          });
          setDataRes(data);
          dispatch(updateMailList({ mailList: data?.mailList, isSuccess: true }));
          dispatch(changeStep({ step: CampaignSteps.Success }));
        }
        setIsSuccess(true);
      } catch (error) {
        openMessageError(error);
        setIsSuccess(false);
      } finally {
        setIsLoading(false);
      }
    })();
  };

  const handleDeleteCampaign = async (id: string | number) => {
    try {
      await CampaignApis.deleteCampaign({ id });
      history.push('/campaign-list');
    } catch (error) {
      openMessageError(error);
    }
  };

  const handleDecodeMatrixPrizes = (courses: any) => {
    const tmpCourses = courses.map((course: any) => {
      const prizes = course?.prizes
        .sort((prizeA: any, prizeB: any) => prizeA.index - prizeB.index)
        .map((prize: any, prizeIndex: number) => {
          const conditionArrValue = Array.from(prize.matrix ? prize.matrix : '0');
          const conditionArr = [...Array(course?.prizes.length)].map((n, i) => {
            return {
              [`condition${i}`]: conditionArrValue[i] ? Number(conditionArrValue[i]) : 0,
            };
          });
          const conditionObj = conditionArr.reduce((map, item) => {
            return { ...map, ...item };
          }, {});
          const prizeDigitalTokutens = prize?.prizeDigitalTokutens.map((d: any) => {
            return d?.percentage
              ? { ...d, percentage: `${d.percentage}` }
              : { ...d, percentage: d.percentage ? d.percentage : null };
          });

          const parseField = {
            productQuantity: prize?.productQuantity
              ? `${prize?.productQuantity}`
              : prize?.productQuantity,
            seatNumber: prize?.seatNumber ? `${prize?.seatNumber}` : prize?.seatNumber,
            prizeDigitalTokutens,
          };

          return {
            ...prize,
            ...parseField,
            index: prizeIndex,
            abandoned: prize.abandoned ? prize.abandoned : 0,
            onlyOne: prize.onlyOne ? prize.onlyOne : 0,
            winRate: +prize?.winRate || 0,
            isIncentive: prize?.isIncentive || false,
            ...conditionObj,
          };
        });

      const totalWinRate = course?.prizes?.reduce((total: number, currentValue: Prize) => {
        const currentWinRate = currentValue.winRate ? +currentValue.winRate : 0;
        return total + currentWinRate;
      }, 0);

      let slipRate = 0;
      if (!course?.prizes?.length || totalWinRate === 0) {
        slipRate = 100;
      }

      if (100 - +totalWinRate > 0) {
        slipRate = +(100 - +totalWinRate).toFixed(2);
      } else {
        slipRate = 0;
      }

      const hasIncentive = course.prizes.some(
        (prize: Prize) => !prize?.userQuantity && prize.isIncentive,
      );

      if (hasIncentive && totalWinRate > 0) {
        slipRate = 0;
      }

      const prizeSorted = () => {
        let tmpPrize: Prize[] = [];
        if (prizes.length) {
          const prizeNotIncentive = prizes.filter((p: Prize) => !p.isIncentive);
          const prizeIncentive = prizes.filter((p: Prize) => p.isIncentive);
          tmpPrize = [...prizeNotIncentive, ...prizeIncentive].map((item, i) => ({
            ...item,
            index: i,
          }));
        }
        return tmpPrize;
      };

      return {
        ...course,
        subType: course?.subType ? +course?.subType : subTypeOptions[0].value,
        slipRate: slipRate,
        prizeQuantity: course?.prizeQuantity ? course?.prizeQuantity : '',
        prizes: prizeSorted(),
      };
    });
    return tmpCourses;
  };

  const handleCopyCampaign = async (campaignId: number | string) => {
    try {
      setIsLoading(true);
      const {
        data: { data: campaign },
      } = await CampaignApis.getCampaign({ id: campaignId as string });
      const nomarlizeCourses = handleDecodeMatrixPrizes(campaign?.courses);
      const tmpCourses = nomarlizeCourses?.map((course: any) => {
        const courseInfos = course.courseInfos.map((info: any) => omit(info, 'id'));
        const blackListUsers = course?.blackListUsers?.map((blackListUser: any) =>
          omit(blackListUser, 'id'),
        );
        const prizes = course.prizes.map((prize: any) => {
          const prizeDigitalTokutens = prize?.prizeDigitalTokutens?.map((dGT: any) => {
            dGT = omit(dGT, 'id');
            return dGT?.percentage
              ? { ...dGT, percentage: `${dGT.percentage}` }
              : { ...dGT, percentage: dGT.percentage ? dGT.percentage : null };
          });
          prize = {
            ...prize,
            prizeDigitalTokutens,
            startTime: Number(prize?.type) === 1 ? null : prize.startTime,
          };
          const parseField = {
            productQuantity: prize?.productQuantity
              ? `${prize?.productQuantity}`
              : prize?.productQuantity,
            seatNumber: prize?.seatNumber ? `${prize?.seatNumber}` : prize?.seatNumber,
            prizeDigitalTokutens,
          };
          if (Number(prize?.type) === 2) {
            prize = {
              ...prize,
              seriesNumberMasterId: null,
              seriesNumberMasterName: null,
              seriesNumberMasterTotal: 0,
            };
          }
          return { ...omit(prize, 'id'), ...parseField };
        });
        return { ...omit(course, 'id'), courseInfos, prizes, blackListUsers };
      });
      history.push(AppRouter.REGISTER_CAMPAIGN, { campaignId: campaignId });
      dispatch(
        updateCampaign({
          overview: {
            artistId: campaign.artistId,
            artistName: campaign.artistName,
            titleId: campaign.titleId,
            titleName: campaign.titleName,
            name: campaign.name,
            startDate: campaign.startDate,
            endDate: campaign.endDate,
            description: campaign.description,
            dataSize: toNumber(campaign.dataSize) ?? 0,
            earliestSaleDate: campaign.earliestSaleDate,
            isAuthor: campaign.isAuthor,
            status: CampaignStatus.Draft,
            isDeletable: true,
          },
          courses: tmpCourses,
        }),
      );
      handleChangeStep(CampaignSteps.Overview);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisableDataCampaign = (id: number | string | undefined) => {
    const isDisabled = overview?.status && overview?.status !== CampaignStatus.Draft && !!id;
    return Boolean(isDisabled);
  };

  const handleSetOldTitleId = (isReset = false) => {
    dispatch(setOldTitleId({ id: isReset ? null : overview?.titleId }));
  };

  const handleReplacePrizes = ({ prizes, courseIndex }: { prizes: any; courseIndex: number }) => {
    dispatch(replacePrizes({ prizes, courseIndex }));
  };
  const handleDisabledSave = (courses: CourseCampaign[]) => {
    const disabledCourses = courses.every((course: CourseCampaign) => {
      let disabled = false;
      if (course.type === 2) {
        if (course.subType === 0) {
          const prizeLimited = course.prizes.filter((item: Prize) => !!item.userQuantity);
          const totalPrizeLimited = prizeLimited.reduce((total, currentValue: Prize) => {
            return total + +currentValue.userQuantity;
          }, 0);

          disabled =
            course?.prizeQuantity && +course.prizeQuantity < +totalPrizeLimited ? true : false;
        }

        if (course.subType === 1) {
          const totalWinRate = course.prizes.reduce((total, currentValue: Prize) => {
            const currentWinRate = currentValue.winRate ? +currentValue.winRate : 0;
            return total + currentWinRate;
          }, 0);

          const totalNotIncentives = course.prizes.reduce((total, currentValue: Prize) => {
            const currentWinRate =
              currentValue.winRate && !currentValue.isIncentive ? +currentValue.winRate : 0;
            return total + currentWinRate;
          }, 0);

          if (totalWinRate > 100) {
            disabled = true;
          }

          if (100 - totalNotIncentives < 0) {
            disabled = true;
          }
        }
      }
      return disabled;
    });

    return disabledCourses;
  };

  //* disabled submit if course only has 1 prize & 1 prize is unlimited
  const disabledCourseRealtime1 = (courses: CourseCampaign[]) => {
    return [...courses].every((course: CourseCampaign) => {
      let disabled = false;
      if (course?.type === 2 && course?.subType !== 2) {
        const prizeUnlimteds = course.prizes.filter((prize: Prize) => !prize.userQuantity);
        disabled = prizeUnlimteds.length === 1 && course.prizes.length === 1 ? true : false;
      }
      return disabled;
    });
  };

  //* disabled submit if course realtime & subtype 2 & prize unlimited > 1
  const disabledCourseRealtime3 = (courses: CourseCampaign[]) => {
    return [...courses].every((course: CourseCampaign) => {
      let disabled = false;
      if (course?.type === 2 && course?.subType === 2) {
        const prizeUnlimteds = course.prizes.filter((prize: Prize) => !prize.userQuantity);
        disabled = prizeUnlimteds.length > 1 ? true : false;
      }
      return disabled;
    });
  };

  return {
    isSuccess,
    isLoading,
    dataRes,
    saveOrUpdateDraft,
    saveOrUpdateOfficial,
    handleChangeStep,
    handleCopyCampaign,
    handleDecodeMatrixPrizes,
    handleDisableDataCampaign,
    handleDeleteCampaign,
    handleSetOldTitleId,
    handleReplacePrizes,
    handleDisabledSave,
    disabledCourseRealtime1,
    disabledCourseRealtime3,
  };
};

export default useCampaignDraft;
