import React, { useEffect } from 'react';
import { ReactNode } from 'Components/Layouts/common';
import { Container } from 'react-bootstrap';
import WebAppHeader from 'Components/Layouts/WebAppHeader';
// import { use100vh } from 'react-div-100vh';

interface Props {
  children: ReactNode;
  isAccept: boolean;
}
const LayoutWebApp = ({ children, isAccept }: Props): JSX.Element => {
  // const height = use100vh();

  // const responseHeight = height ? height : '100vh';

  useEffect(() => {
    document.title = `STAFF APP`;
  }, []);

  return (
    <Container
      fluid
      className="flex-nowrap overflow-hidden justify-content-center"
      // style={{ height: responseHeight }}
    >
      {isAccept ? <WebAppHeader /> : <></>}
      <div className="d-flex" style={isAccept ? { marginTop: '74px' } : {}}>
        <main className="p-0 w-100">{children}</main>
        {/* <main className="p-0 w-100">{children}</main> */}
      </div>
    </Container>
  );
};

export default LayoutWebApp;
