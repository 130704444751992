import React from 'react';
import { Card, Container, useAccordionButton } from 'react-bootstrap';
import { Dash, Plus } from 'react-bootstrap-icons';

interface CardProps {
  title: string | null;
  children: React.ReactNode;
  className?: string;
  eventKey?: string;
  onCollapse?: (eventKey: string) => void;
  activated?: boolean;
  subTitle?: string;
}
const CardWrapper = ({
  title,
  children,
  className,
  eventKey,
  onCollapse,
  activated,
  subTitle,
}: CardProps): JSX.Element => {
  const decoratorOnClick = useAccordionButton(
    eventKey || '',
    () => onCollapse && onCollapse(eventKey || ''),
  );
  return (
    <>
      <Container className={`mt-3 ${className}`}>
        <Card className="card-container">
          <Card.Header className="bg-dark text-white">
            <div className="d-flex justify-content-between align-items-center">
              <p className="w-85 text-truncate">
                <span>{title}</span>
              </p>
              <div>
                <span className="text-truncate mw-25 d-block">{subTitle}</span>
                {eventKey && (
                  <button
                    className="d-flex align-items-center bg-transparent border-0 collapse-btn"
                    onClick={decoratorOnClick}
                  >
                    <span className="d-flex justify-content-center align-items-center rounded collapse-minus-icon white">
                      {activated ? <Dash /> : <Plus />}
                    </span>
                    <p className="ms-2 white">{activated ? '縮⼩表⽰' : '詳細表示'}</p>
                  </button>
                )}
              </div>
            </div>
          </Card.Header>

          <Card.Body>{children}</Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default CardWrapper;
