import React from 'react';
import { Col } from 'react-bootstrap';
import CardContainer from 'Components/Common/CardContainer';
import GroupTextProps from 'Components/Page/Title/FormTextGroup';
import { titleText } from 'Constant/Texts/Title';

interface TitleInformationProps {
  errors?: any;
  titleForm?: any;
  onClickError?: () => void;
}

export default function TitleInformation(props: TitleInformationProps): JSX.Element {
  const { errors, onClickError, titleForm } = props;
  return (
    <>
      <CardContainer title="タイトル情報" darkHeader className="mx-4 mt-5 border-0 shadow-none">
        <Col md={{ span: 10, offset: 0 }}>
          <GroupTextProps
            label="アーティスト"
            value={titleForm?.artistName}
            errorContent={errors?.artistName}
            onClickError={onClickError}
          />
          <GroupTextProps
            label="タイトル名"
            value={titleForm?.name}
            errorContent={errors?.name}
            onClickError={onClickError}
          />
          <GroupTextProps
            label="タイトル名（フリガナ）"
            value={titleForm?.furiganaName}
            errorContent={errors?.furiganaName}
            onClickError={onClickError}
          />
          <GroupTextProps
            label="タイトル名（英語表記）"
            value={titleForm?.globalName}
            errorContent={errors?.globalName}
            onClickError={onClickError}
          />
          <GroupTextProps
            label="音楽ジャンル"
            value={titleForm?.musicGenreName || titleForm?.musicGenreId}
            errorContent={errors?.musicGenreId}
            onClickError={onClickError}
          />
          <GroupTextProps
            label="レーベル名"
            value={titleForm?.label}
            errorContent={errors?.label}
            onClickError={onClickError}
          />
          <GroupTextProps
            label={titleText.iap.label}
            value={titleForm?.sellIap === 1 ? '販売する' : ''}
            errorContent={errors?.sellIap}
            onClickError={onClickError}
          />
        </Col>
      </CardContainer>
    </>
  );
}
