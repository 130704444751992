import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { PAGE_SIZE, PER_PAGE } from 'Constant';
import { DATE_FORMAT_4, DATE_FORMAT_2 } from 'Constant/Date';
import { PromotionMangagerApi } from 'Datasource/PromotionManager';
import { IDataPromotion } from 'Types/Page/PromotionManager';
import TableCommon from 'Components/Common/Table/TableCommon';

export default function SaleSchedule(): JSX.Element {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: '特典名称',
            accessor: 'name',
            Cell: ({ row }: Cell<IDataPromotion>) => {
              return (
                <Link
                  title={row.original.name}
                  className="d-block text-truncate text-start"
                  to={`/digital-tokuten/${row.original.id}`}
                >
                  {row.original.name}
                </Link>
              );
            },
          },
          {
            Header: '販売開始⽇時',
            accessor: 'saleStartDate',

            Cell: ({ row }: Cell<IDataPromotion>) => {
              return dayjs(row.original.saleStartDate).format(DATE_FORMAT_2);
            },
          },
          {
            Header: '販売終了日時',
            accessor: 'saleEndDate',

            Cell: ({ row }: Cell<IDataPromotion>) => {
              return dayjs(row.original.saleEndDate).format(DATE_FORMAT_2);
            },
          },
          {
            Header: 'DL可能開始⽇時',
            accessor: 'downloadDate',

            Cell: ({ row }: Cell<IDataPromotion>) => {
              return dayjs(row.original.downloadDate).format(DATE_FORMAT_2);
            },
          },
        ],
      },
    ],
    [],
  );

  const [loading, setLoading] = useState(false);
  const [digitalTokutens, setDigitalTokutens] = useState([]);
  const [total, setTotal] = useState(0);

  const [query] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    queryVariation: StringParam,
    artistId: StringParam,
    titleId: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
    variationIds: StringParam,
    dateSearch: StringParam,
  });

  const handleOnchangeList = async (ids: any) => {
    setLoading(true);
    const {
      data: { data },
    } = await PromotionMangagerApi.getAllPromotions({
      page: query.page ?? 1,
      limit: PAGE_SIZE,
      artistId: ids.artistIds,
      titleId: ids.titleIds,
      variationId: ids.variationIds,
      time: ids.dateSearch,
      startDate: ids.startDate,
      endDate: ids.endDate,
    });

    setDigitalTokutens(data.digitalTokutens);
    setTotal(data.totalCount);
    setLoading(false);
  };

  useEffect(() => {
    const dateSearch = query.dateSearch ? query.dateSearch : dayjs().format(DATE_FORMAT_4);
    const startOfMonth = dayjs(dateSearch).startOf('month').format();
    const startDate = dayjs(startOfMonth).utc().format();
    const endOfMonth = dayjs(dateSearch).endOf('month').format();
    const endDate = dayjs(endOfMonth).utc().format();
    const ids = {
      artistIds: query.artistIds ?? '',
      titleIds: query.titleIds ?? '',
      variationIds: query.variationIds ?? '',
      dateSearch: dateSearch,
      startDate,
      endDate,
    };

    handleOnchangeList(ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.page, query.artistIds, query.titleIds, query.variationIds, query.dateSearch]);

  return (
    <Row className="px-3">
      <SpinnerComponent isLoading={loading} />

      {!isEmpty(digitalTokutens) ? (
        <>
          <div className="d-flex my-2 justify-content-end">
            <PaginationComponent activePage={query.page ?? 1} total={total} perPage={PER_PAGE} />
          </div>
          <TableCommon
            classTable="table-digital-tokuten"
            heightBody="calc(100vh - 350px)"
            data={digitalTokutens}
            columns={columns}
          />
        </>
      ) : !loading ? (
        <div className="vh-100-final d-flex align-items-center justify-content-center">
          商品が登録されていません
        </div>
      ) : null}
    </Row>
  );
}
