import React, { useCallback, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { CampaignSteps } from 'App/Features/Campaign';
import { useAppSelector } from 'App/Store';
import { useParams } from 'react-router-dom';
import ModalComponent from 'Components/Common/Modal';
import useMessage from 'Hooks/useMessage';
import useCampaign from 'Hooks/useCampaign';
import { CourseCampaign } from 'Types/Page/Campaign/RegisterCampaign';
import { updateQuery } from 'Utils/Search';
import { getMsgExceed100 } from 'Utils/Campaign';
interface ActionButtonsProps {
  handleNext?: () => void;
  handleBack?: () => void;
  handleSaveDraft?: () => void;
  handleDelete?: () => void;
  handleSave?: () => void;
  isVisibleDelete?: boolean;
  isVisibleSave?: boolean;
  disabledSave?: boolean;
  courses?: CourseCampaign[];
}

const ActionButtons = ({
  handleNext,
  handleBack,
  handleSaveDraft,
  handleDelete,
  handleSave,
  disabledSave,
  courses = [],
}: ActionButtonsProps): JSX.Element => {
  const { step, overview, errorRealTime } = useAppSelector((state) => state.campaign);
  const { id }: { id: string } = useParams();
  const { openMessage } = useMessage();
  const { disabledCourseRealtime1, disabledCourseRealtime3 } = useCampaign();

  const [visible, setVisible] = useState<boolean>(false);

  const handleBackStep = () => {
    handleBack && handleBack();
  };

  const handleNextStep = () => {
    handleNext && handleNext();
  };

  const debouceRequest = useCallback(
    () =>
      updateQuery('', () => {
        handleSaveDraft && handleSaveDraft();
      }),
    [handleSaveDraft],
  );

  return (
    <>
      <Row className="d-flex justify-content-center align-items-center py-3">
        {step === CampaignSteps.Overview && !!id && (
          <Button
            variant="danger"
            className="btn-action"
            onClick={() => setVisible(true)}
            disabled={!overview?.isDeletable}
          >
            キャンペーンを削除する
          </Button>
        )}
        {step !== CampaignSteps.Overview && (
          <Button
            className="btn-action"
            onClick={() => {
              handleBackStep();
            }}
            disabled={!overview?.isAuthor}
          >
            前のステップへ戻る
          </Button>
        )}
        <Button
          className="btn-action ms-3"
          disabled={overview?.status}
          onClick={() => {
            if (disabledCourseRealtime1(courses)) {
              openMessage({
                variant: 'error',
                message: '上限ありの賞を追加してください',
              });

              return;
            }

            if (disabledCourseRealtime3(courses)) {
              openMessage({
                variant: 'error',
                message: 'キャンペーンパターンは正しくないので登録できません。',
              });

              return;
            }

            if (errorRealTime?.prizeQuantityTooSmall) {
              openMessage({
                variant: 'error',
                message: getMsgExceed100(courses, errorRealTime?.courseName),
              });
              return;
            }

            if (errorRealTime?.exceed100) {
              openMessage({
                variant: 'error',
                message: getMsgExceed100(courses, errorRealTime?.courseName),
              });
              return;
            }

            debouceRequest();
          }}
        >
          一時保存する
        </Button>
        {step !== CampaignSteps.Confirm && (
          <Button className="btn-action ms-3" onClick={() => handleNextStep()}>
            次のステップへ進む
          </Button>
        )}
        {step === CampaignSteps.Confirm && (
          <Button
            className="btn-action ms-3"
            disabled={disabledSave || !overview?.isAuthor}
            onClick={overview?.isAuthor && handleSave}
          >
            この内容で登録する
          </Button>
        )}
      </Row>
      <ModalComponent
        show={visible}
        onCloseModal={() => setVisible(false)}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="danger"
        onSubmitModal={() => {
          handleDelete && handleDelete();
          setVisible(false);
        }}
        title="削除確認"
        confirmText="削除する"
      >
        <div>
          <p className="mb-2">キャンペーンを削除します。よろしいですか。</p>
        </div>
      </ModalComponent>
    </>
  );
};

export default ActionButtons;
