import React from 'react';
import Form from 'Components/Page/ForgotPassword/Form';
import { Container, Image } from 'react-bootstrap';
import BrLogo from 'Static/Images/br-logo.png';
import Logo from 'Static/Images/logo.png';

export default function ForgotPassword(): JSX.Element {
  return (
    <div className="bg-public position-relative">
      <Container className="d-flex flex-column justify-content-center align-items-center vh-100 text-white">
        <h1 className="text-center mb-5">
          <Image src={Logo} />
        </h1>
        <Form />
      </Container>
      <div className="text-center p-2 position-absolute bottom-0 w-100">
        <img src={BrLogo} alt="bg logo" className="w-max-content" />
        <p className="mt-2 fs-7">Copyright ©2021 BootRock Inc. All rights reserved.</p>
      </div>
    </div>
  );
}
