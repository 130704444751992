import React, { forwardRef, PropsWithChildren } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import MessageError from 'Components/Common/MessageError';
import { labelRequire } from 'Components/Common/Form/FormLabel';
import { FormInputNumberProps } from 'Components/Common/Form/FormInputNumber/form-input-number';
import HintTextField from 'Components/Common/Form/HintTextField';

const FormInputNumber = forwardRef(
  (props: PropsWithChildren<FormInputNumberProps>, ref: any): JSX.Element => {
    const {
      label,
      classForm,
      classLabel,
      placeholder,
      labelMd,
      colMd,
      name,
      value,
      classInput,
      classCol,
      format,
      onChange,
      errorMessage,
      isReview = false,
      pureType,
      onBlurInput,
      decimalScale,
      allowLeadingZeros,
      thousandSeparator,
      maxLength,
      minLength,
      allowNegative,
      hintText = '',
      maxValue,
      minValue,
      parentClass = 'mb-3',
      suffix = '',
    } = props;
    const { control } = useForm();

    const handleOnchange = (number: string) => {
      if (onChange) {
        if (minValue) {
          if (+number >= +minValue) {
            onChange(number || '');
          } else {
            onChange('');
          }
        } else {
          onChange(number || '');
        }
      }
    };

    const isAllowed = (values: NumberFormatValues): boolean => {
      let isValid = true;
      if (maxLength) {
        isValid = values?.value.length <= maxLength;
      }
      if (minLength) {
        isValid = values?.value.length >= minLength;
      }
      if (maxValue) {
        isValid = (values?.floatValue ?? 0) <= maxValue;
      }
      return isValid;
    };

    return (
      <div className={parentClass}>
        <Form.Group as={Row} className={`number-grants-end ${classForm}`} data-text={suffix}>
          <Form.Label column md={labelMd}>
            {labelRequire(label, classLabel)}
          </Form.Label>
          <Col md={colMd} className={classCol}>
            <Controller
              name={name}
              control={control}
              render={() => (
                <NumberFormat
                  value={value}
                  onValueChange={({ formattedValue, value }) => {
                    handleOnchange(pureType ? value : formattedValue);
                  }}
                  className={`form-control rounded position-relative ${classInput}`}
                  placeholder={placeholder}
                  format={format}
                  disabled={isReview}
                  onBlur={(event) => {
                    onBlurInput && onBlurInput(event?.target?.defaultValue?.replace(',', ''));
                  }}
                  decimalScale={decimalScale}
                  allowLeadingZeros={allowLeadingZeros}
                  thousandSeparator={thousandSeparator}
                  isAllowed={isAllowed}
                  allowNegative={allowNegative}
                  ref={ref}
                />
              )}
            />
          </Col>
        </Form.Group>
        <Row>
          <Col md={labelMd} />
          <Col md={colMd}>
            <HintTextField hintText={hintText} />
            <MessageError classWrapper="w-max-content ms-2" message={errorMessage} />
          </Col>
        </Row>
      </div>
    );
  },
);

export default FormInputNumber;
