import { NUMBER_ONLY_REGEX } from 'Constant';
import { ERROR } from 'Constant/Error';

const RuleNumberOnly = () => ({
  required: ERROR['this field is required'],
  pattern: {
    value: NUMBER_ONLY_REGEX,
    message: ERROR['this field is only allowed number'],
  },
});

export const COMPANY_REGISTER_FORM_RULES = {
  companyName: {
    required: ERROR['this field is required'],
  },
  rpsttName: {
    required: ERROR['this field is required'],
  },
  zipCode: {
    ...RuleNumberOnly(),
    minLength: {
      value: 6,
      message: ERROR['at least 6 numbers'],
    },
  },
  address1: {
    required: ERROR['this field is required'],
  },
  address2: {
    required: ERROR['this field is required'],
  },
  phoneNumber: {
    ...RuleNumberOnly(),
    minLength: {
      value: 10,
      message: ERROR['phone number is not in the correct format'],
    },
  },
  bankName: {
    required: ERROR['this field is required'],
  },
  bankBranchNumber: {
    ...RuleNumberOnly(),
    minLength: {
      value: 4,
      message: ERROR['bank branch number has least 4 numbers'],
    },
  },
  bankBranchName: {
    required: ERROR['this field is required'],
  },
  accountNumber: {
    ...RuleNumberOnly(),
    minLength: {
      value: 7,
      message: ERROR['bank account number has least 7 numbers'],
    },
  },
  accountName: {
    required: ERROR['this field is required'],
  },
};
