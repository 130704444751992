import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { IAudio } from 'Components/Common/AudioCustom/audio-types';
import { PlayFill, PauseFill } from 'react-bootstrap-icons';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import { convertToClock, convertToClockMMSS } from 'Utils/Title';

const ICON_STATUS = {
  size: 19,
};

const AudioCustom = forwardRef((props: IAudio, ref: any): JSX.Element => {
  const {
    src,
    onLoadedMeta,
    audioPlayer,
    classAudio,
    onTimeAudioUpdate,
    onPrimaryClick,
    isPlayManual = false,
    onTimeDrag,
    onEnded,
  } = props;
  const rateBar = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [timeCurrent, setTimeCurrent] = useState<number>(0);
  const [totalTimeSong, setTotalTimeSong] = useState<number>(0);

  const handleTimeDrag = (e: any) => {
    const clickPositionInPage = e?.pageX;
    const bar = rateBar?.current;

    const barStart = bar?.getBoundingClientRect().left + window.scrollX;
    const barWidth = bar.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = totalTimeSong / barWidth;

    const clickedTime = timePerPixel * clickPositionInBar;
    audioPlayer.current.currentTime = clickedTime;
    onTimeDrag && onTimeDrag();
  };

  useEffect(() => {
    isPlaying ? audioPlayer.current.pause() : audioPlayer.current.play();
  }, [isPlaying]);

  useEffect(() => {
    setIsPlaying(!isPlayManual);
  }, [isPlayManual]);

  const onLoadedMetadata = () => {
    setTotalTimeSong(audioPlayer?.current?.duration);
    onLoadedMeta && onLoadedMeta();
  };

  const renderbtnStatus = () => {
    return (
      <div
        onKeyUp={() => {
          // TODO
        }}
        onClick={() => {
          setIsPlaying(!isPlaying);
          onPrimaryClick && onPrimaryClick();
        }}
        className="ps-2"
      >
        {isPlaying && audioPlayer?.current?.paused ? (
          <PlayFill {...ICON_STATUS} />
        ) : (
          <PauseFill {...ICON_STATUS} />
        )}
      </div>
    );
  };
  const curPercentage = (timeCurrent / totalTimeSong) * 100;

  return (
    <div className={`${classAudio} player pe-auto`}>
      <audio
        onTimeUpdate={(e: any) => {
          setTimeCurrent(e?.target?.currentTime);
          onTimeAudioUpdate && onTimeAudioUpdate(e);
        }}
        onLoadedMetadata={onLoadedMetadata}
        ref={ref}
        key={src}
        onEnded={() => {
          audioPlayer.current.currentTime = 0;
          setIsPlaying(true);
          onEnded && onEnded();
        }}
      >
        <track kind="captions" />
        <source key={src} src={handleConnectStaticEndPoint(src)} type="audio/mpeg" />
      </audio>
      {renderbtnStatus()}
      {/* BAR */}
      <div className="bar ms-3">
        <span className="bar__time">{timeCurrent ? convertToClock(timeCurrent) : '00:00:000'}</span>
        <span className="bar__time mx-1">{`/`}</span>
        <span className="bar__time">
          {totalTimeSong ? convertToClockMMSS(totalTimeSong) : '00:00'}
        </span>
        <div
          className="bar__progress"
          style={{
            background: `linear-gradient(to right, #0B0B0B ${curPercentage}%, #C1C2C3 0)`,
          }}
          onMouseDown={(e) => handleTimeDrag(e)}
          ref={rateBar}
        >
          <span className="bar__progress__knob" style={{ left: `${curPercentage - 1}%` }} />
        </div>
      </div>
    </div>
  );
});

export default AudioCustom;
