/* eslint-disable react-hooks/exhaustive-deps */
import Search from 'Components/Common/Search/Search';
import React, { useState, useRef, useCallback } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import useMessage from 'Hooks/useMessage';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { SampleMasterApi } from 'Datasource/SampleMasterApi';
import useSuggestionSample from 'Hooks/useSuggestionSample';
import FormLabel from 'Components/Common/Form/FormLabel';
import MessageError from 'Components/Common/MessageError';
import { isNil } from 'lodash';
interface Props {
  onImportExcel: (e: any) => void;
}

const ExcelTypes = ['xlsx', 'xls'];

export default function SearchSaleProduct({ onImportExcel }: Props): JSX.Element {
  const inputRef = useRef(null) as any;
  const { openMessage, openMessageError } = useMessage();
  const [miimIDValue, setMiimIDValue] = useState<string>('');
  const [currentMsg, setCurrentMsg] = useState<string>('');

  const {
    artistOptions,
    titleOptions,
    variationOptions,
    loadingArtist,
    loadingTitle,
    loadingVariation,
    handleLoadMore,
    handleFocusInput,
    handleEnterPrimary,
    handleClickItemPrimary,
  } = useSuggestionSample({
    validQueryObj: {
      validArtist: {
        artistIds: undefined,
        queryArtist: undefined,
        artistId: undefined,
        queryTitle: undefined,
        titleIds: undefined,
        titleId: undefined,
        queryVariation: undefined,
        variationIds: undefined,
        variationId: undefined,
      },
      validTitle: {
        queryTitle: undefined,
        titleIds: undefined,
        titleId: undefined,
        queryVariation: undefined,
        variationIds: undefined,
        variationId: undefined,
      },
      validVariation: {
        queryVariation: undefined,
        variationIds: undefined,
        variationId: undefined,
      },
    },
    fetchList: ['artist', 'title', 'variation'],
  });

  const [loadingComponent, setLoadingComponent] = useState(false);
  const [query] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
    variationIds: StringParam,
    queryVariation: StringParam,
    artistId: StringParam,
    titleId: StringParam,
    variationId: StringParam,
  });

  const handleImportExcel = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const selectedFile: File = (target.files as FileList)[0];
    const fileType = selectedFile.name.split('.').pop() || '';
    if (!ExcelTypes.includes(fileType)) {
      openMessage({
        variant: 'error',
        message: `${selectedFile.name} ファイルのアップロードに失敗しました。`,
      });
      inputRef.current.value = '';
      return;
    }

    try {
      setLoadingComponent(true);
      const formData: FormData = new FormData();
      formData.append('file', selectedFile);
      const {
        data: { data },
      } = await SampleMasterApi.getListUserFromExcel(formData);
      onImportExcel(data);
      setLoadingComponent(false);
    } catch (error: any) {
      openMessageError(error);
      setLoadingComponent(false);
    } finally {
      inputRef.current.value = '';
    }
  };

  const handleSearchByMiimID = useCallback(async () => {
    setCurrentMsg('');
    if (!miimIDValue) {
      return setCurrentMsg('入力してください。');
    }
    try {
      const {
        data: { data },
      } = await SampleMasterApi.getUserByMiimID(miimIDValue);
      onImportExcel([data]);
      if (isNil(data.id)) {
        setCurrentMsg('miim IDが存在しません。');
      }
      setLoadingComponent(true);
    } catch (error: any) {
      openMessageError(error);
    } finally {
      setLoadingComponent(false);
      setMiimIDValue('');
    }
  }, [miimIDValue]);

  return (
    <>
      <SpinnerComponent isLoading={loadingComponent} />
      <Row className="align-items-end">
        <Col md="3">
          <Search
            label="アーティスト"
            labelMd="12"
            colMd="12"
            placeholder="検索"
            showIcon
            classLabel="px-0"
            rowClass="w-100"
            colClass="px-0"
            searchKey="queryArtist"
            onEnter={() => handleEnterPrimary('queryArtist')}
            onClickResult={handleClickItemPrimary}
            options={artistOptions.options || []}
            loading={loadingArtist}
            onLoadMore={() => handleLoadMore('pageArtist')}
            onFocusInput={() => {
              handleFocusInput('queryArtist', 'pageArtist');
            }}
          />
        </Col>
        <Col md="3">
          <Search
            label="タイトル"
            labelMd="12"
            colMd="12"
            placeholder="検索"
            showIcon
            classLabel="px-0"
            rowClass="w-100"
            colClass="px-0"
            searchKey="queryTitle"
            isDisabled={!query?.queryArtist}
            onEnter={() => handleEnterPrimary('queryTitle')}
            onClickResult={handleClickItemPrimary}
            options={titleOptions.options || []}
            loading={loadingTitle}
            onLoadMore={() => handleLoadMore('pageTitle')}
            onFocusInput={() => {
              handleFocusInput('queryTitle', 'pageTitle');
            }}
          />
        </Col>
        <Col md="3">
          <Search
            label="バリエーション"
            labelMd="12"
            colMd="12"
            placeholder="検索"
            showIcon
            classLabel="px-0"
            rowClass="w-100"
            colClass="px-0"
            isDisabled={!query?.queryTitle}
            searchKey="queryVariation"
            onEnter={() => handleEnterPrimary('queryVariation')}
            onClickResult={handleClickItemPrimary}
            options={variationOptions.options || []}
            loading={loadingVariation}
            onLoadMore={() => handleLoadMore('pageVariation')}
            onFocusInput={() => {
              handleFocusInput('queryVariation', 'pageVariation');
            }}
          />
        </Col>

        <Col md="3" className="ps-4">
          <Button
            className="btn-focus-none button-submit"
            onClick={() => {
              inputRef.current && inputRef.current.click();
            }}
          >
            Excel読み込み
          </Button>
          <input
            ref={inputRef}
            className="hidden-file"
            type="file"
            onChange={handleImportExcel}
            accept={'.xlsx, .xls'}
          />
        </Col>
      </Row>
      <div className="my-5">
        <Row className="d-flex justify-content-start mb-2">
          <FormLabel colMd="9" label="miim IDで配布先を登録する" />
        </Row>
        <Row className="d-flex justify-content-start">
          <Col md={2}>
            <Form.Control
              value={miimIDValue}
              type="text"
              onChange={(e) => setMiimIDValue(e.target.value)}
            />
            <MessageError message={currentMsg} />
          </Col>
          <Col md={1} className="d-flex justify-content-start px-0">
            <Button className="btn h-max-content btn-focus-none" onClick={handleSearchByMiimID}>
              追加
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}
