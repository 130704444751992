import React, { useState } from 'react';
import { CalendarEvent } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';

const InputDateRangePicker = (): JSX.Element => {
  const [dateRange, setDateRange] = useState<any>([null, null]);
  const [startDate, endDate] = dateRange;
  return (
    <>
      <div className="position-relative">
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            setDateRange(update);
          }}
          placeholderText="開始日（yyyy/mm/dd hh:mm)"
          locale="ja"
          className="form-control"
          popperPlacement="bottom-end"
        />
        <CalendarEvent className="affix-icon" />
      </div>
    </>
  );
};

export default InputDateRangePicker;
