import Search from 'Components/Common/Search/Search';
import useSuggestion from 'Hooks/useSuggestion';
import { IPullDownItem } from 'Types/Common';
import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
interface SearchProps {
  titleIdTransfer?: number | string;
}

export default function SearchProductsDetail({ titleIdTransfer }: SearchProps): JSX.Element {
  const location = useLocation<any>();
  const [query, setQuery] = useQueryParams({
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistId: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
  });

  const {
    artistOptions,
    titleOptions,
    loadingArtist,
    loadingTitle,
    handleLoadMore,
    handleFocusInput,
    handleEnterPrimary,
    handleClickItemPrimary,
  } = useSuggestion({
    fetchList: ['artist', 'title'],
  });

  const validQueryDefault = {
    artistIds: undefined,
    artistId: undefined,
    queryArtist: undefined,
    queryTitle: undefined,
    titleIds: undefined,
  };

  useEffect(() => {
    setQuery({
      queryTitle: location?.state?.titleName || '',
      titleIds: titleIdTransfer?.toString(),
    });
  }, [titleIdTransfer]);

  useEffect(() => {
    if (!query.queryArtist && (!titleIdTransfer || !query.titleIds)) {
      setQuery(validQueryDefault);
    }
  }, [query.queryArtist, titleIdTransfer, query.titleIds]);

  useEffect(() => {
    if (titleOptions.options.length > 0 && query.titleIds) {
      const currentTitle = titleOptions.options.find(
        (item: IPullDownItem) => item.value?.toString() === query.titleIds,
      );

      if (currentTitle) {
        setQuery({ queryTitle: currentTitle?.label });
      }
    }
  }, [query?.titleIds, titleOptions.options]);

  return (
    <>
      <Col md="2" className="ps-2">
        <Search
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          label="アーティスト"
          classLabel="px-0"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryArtist"
          onEnter={() => handleEnterPrimary('queryArtist')}
          onClickResult={handleClickItemPrimary}
          options={artistOptions.options || []}
          loading={loadingArtist}
          onLoadMore={() => handleLoadMore('pageArtist')}
          onFocusInput={() => {
            handleFocusInput('queryArtist', 'pageArtist');
          }}
        />
      </Col>
      <Col md="2" className="ps-2">
        <Search
          labelMd="12"
          colMd="12"
          placeholder="検索"
          label="タイトル"
          showIcon
          classLabel="px-0"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryTitle"
          onEnter={() => handleEnterPrimary('queryTitle')}
          onClickResult={handleClickItemPrimary}
          options={titleOptions.options || []}
          loading={loadingTitle}
          onLoadMore={() => handleLoadMore('pageTitle')}
          onFocusInput={() => {
            handleFocusInput('queryTitle', 'pageTitle');
          }}
        />
      </Col>
    </>
  );
}
