import React, { useCallback, useEffect, useState } from 'react';
import { FormGroupSearchProps } from 'Components/Common/Form/form';
import FormGroupSearch from 'Components/Common/Form/FormGroupSearch';
import { SearchResponse } from 'Hooks/useSuggestion';
import useMessage from 'Hooks/useMessage';
import { PER_PAGE } from 'Constant';
import { SerialApis } from 'Datasource/Serial';
import { updateQuery } from 'Utils/Search';
export interface SelectCDProps extends Omit<FormGroupSearchProps, 'onInputChange' | 'onSearch'> {
  titleId: number | string;
  onInput?: (keyword: string) => void;
}

export default function FormSelectCD(props: SelectCDProps): JSX.Element {
  const [page, setPage] = useState<number>(1);
  const [cd, setCD] = useState<SearchResponse>({ options: [], totalPages: 0 });
  const [loading, setLoading] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const { openMessageError } = useMessage();

  const { onInput } = props;

  const handleSearchCD = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await SerialApis.getCdByTitle({
        titleId: props.titleId,
        page: page,
        limit: PER_PAGE,
        query: encodeURIComponent(searchKey),
      });

      const options = data.items.map((cd: any) => {
        return {
          label: cd.name,
          value: cd.id,
        };
      });
      setCD({
        options: page === 1 ? options : [...cd.options, ...options],
        totalPages: data?.meta?.totalPages,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadmore = () => {
    if (page < cd.totalPages) {
      let p = page;
      p += 1;
      setPage(p);
    }
  };

  useEffect(() => {
    handleSearchCD();
  }, [searchKey, page, props.titleId]);

  const debouceRequest = useCallback(
    (value: string) =>
      updateQuery(value, () => {
        setSearchKey(value);
        setPage(1);
        onInput && onInput(value);
      }),
    [onInput],
  );

  return (
    <FormGroupSearch
      onLoadMore={() => handleLoadmore()}
      onMenuClose={() => {
        setSearchKey('');
        setPage(1);
      }}
      isLoading={loading}
      options={cd.options || []}
      onInputChange={(keyword: string) => debouceRequest(keyword)}
      onMenuFocus={() => {
        setCD({ options: [], totalPages: 0 });
        handleSearchCD();
      }}
      showIconClear
      {...props}
    />
  );
}
