import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RevenueState {
  filters: {
    sale: {
      monthly: {
        isViewProductId: boolean;
        isViewArtistName: boolean;
        isViewTitleName: boolean;
        isViewVariationName: boolean;
        isViewVariationProductCode: boolean;
        isViewDownloadDate: boolean;
        isViewShop: boolean;
        isViewVariationJanCode: boolean;
        isViewTitleLabel: boolean;
      };
      daily: {
        isViewProductId: boolean;
        isViewArtistName: boolean;
        isViewTitleName: boolean;
        isViewVariationName: boolean;
        isViewVariationProductCode: boolean;
        isViewDownloadDate: boolean;
        isViewShop: boolean;
        isViewVariationJanCode: boolean;
        isViewTitleLabel: boolean;
      };
      detail: {
        isViewProductId: boolean;
        isViewArtistName: boolean;
        isViewTitleName: boolean;
        isViewVariationName: boolean;
        isViewVariationProductCode: boolean;
        isViewDownloadDate: boolean;
        isViewShop: boolean;
        isViewVariationJanCode: boolean;
        isViewTitleLabel: boolean;
      };
    };
    cost: {
      monthly: {
        isViewProductId: boolean;
        isViewArtistName: boolean;
        isViewTitleName: boolean;
        isViewVariationName: boolean;
        isViewVariationProductCode: boolean;
        isViewDownloadDate: boolean;
      };
      daily: {
        isViewProductId: boolean;
        isViewArtistName: boolean;
        isViewTitleName: boolean;
        isViewVariationName: boolean;
        isViewVariationProductCode: boolean;
        isViewDownloadDate: boolean;
      };
      detail: {
        isViewProductId: boolean;
        isViewArtistName: boolean;
        isViewTitleName: boolean;
        isViewVariationName: boolean;
        isViewVariationProductCode: boolean;
        isViewDownloadDate: boolean;
      };
    };
  };
}

export const initialRevenueState: RevenueState = {
  filters: {
    sale: {
      monthly: {
        isViewProductId: true,
        isViewArtistName: true,
        isViewTitleName: true,
        isViewVariationName: true,
        isViewVariationProductCode: true,
        isViewDownloadDate: true,
        isViewShop: true,
        isViewVariationJanCode: true,
        isViewTitleLabel: true,
      },
      daily: {
        isViewProductId: true,
        isViewArtistName: true,
        isViewTitleName: true,
        isViewVariationName: true,
        isViewVariationProductCode: true,
        isViewDownloadDate: true,
        isViewShop: true,
        isViewVariationJanCode: true,
        isViewTitleLabel: true,
      },
      detail: {
        isViewProductId: true,
        isViewArtistName: true,
        isViewTitleName: true,
        isViewVariationName: true,
        isViewVariationProductCode: true,
        isViewDownloadDate: true,
        isViewShop: true,
        isViewVariationJanCode: true,
        isViewTitleLabel: true,
      },
    },
    cost: {
      monthly: {
        isViewProductId: true,
        isViewArtistName: true,
        isViewTitleName: true,
        isViewVariationName: true,
        isViewVariationProductCode: true,
        isViewDownloadDate: true,
      },
      daily: {
        isViewProductId: true,
        isViewArtistName: true,
        isViewTitleName: true,
        isViewVariationName: true,
        isViewVariationProductCode: true,
        isViewDownloadDate: true,
      },
      detail: {
        isViewProductId: true,
        isViewArtistName: true,
        isViewTitleName: true,
        isViewVariationName: true,
        isViewVariationProductCode: true,
        isViewDownloadDate: true,
      },
    },
  },
};

export const RevenueSlice = createSlice({
  name: 'revenue',
  initialState: initialRevenueState,
  reducers: {
    setSaleMonthlyFilters: (state, data: PayloadAction<{ filters: any }>) => {
      const { payload } = data;
      return {
        ...state,
        filters: { ...state.filters, sale: { ...state.filters.sale, monthly: payload.filters } },
      };
    },
    setCostMonthlyFilters: (state, data: PayloadAction<{ filters: any }>) => {
      const { payload } = data;
      return {
        ...state,
        filters: { ...state.filters, cost: { ...state.filters.cost, monthly: payload.filters } },
      };
    },
    setSaleDailyFilters: (state, data: PayloadAction<{ filters: any }>) => {
      const { payload } = data;
      return {
        ...state,
        filters: { ...state.filters, sale: { ...state.filters.sale, daily: payload.filters } },
      };
    },
    setCostDailyFilters: (state, data: PayloadAction<{ filters: any }>) => {
      const { payload } = data;
      return {
        ...state,
        filters: { ...state.filters, cost: { ...state.filters.cost, daily: payload.filters } },
      };
    },
    setSaleDetailFilters: (state, data: PayloadAction<{ filters: any }>) => {
      const { payload } = data;
      return {
        ...state,
        filters: { ...state.filters, sale: { ...state.filters.sale, detail: payload.filters } },
      };
    },
    setCostDetailFilters: (state, data: PayloadAction<{ filters: any }>) => {
      const { payload } = data;
      return {
        ...state,
        filters: { ...state.filters, cost: { ...state.filters.cost, detail: payload.filters } },
      };
    },
    resetRevenueFilters: (state) => {
      state = { ...initialRevenueState };
      return state;
    },
  },
});

export const {
  setSaleMonthlyFilters,
  setCostMonthlyFilters,
  setSaleDailyFilters,
  setCostDailyFilters,
  setSaleDetailFilters,
  setCostDetailFilters,
  resetRevenueFilters,
} = RevenueSlice.actions;

export default RevenueSlice.reducer;
