/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';

import { Col, Row, Button } from 'react-bootstrap';
import { formatPrice } from 'Utils/Numbers';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';

import { ECApis } from 'Datasource/EC';
import useMessage from 'Hooks/useMessage';

interface ECPurchaseCancelAllProps {
  data?: any;
  info?: any;
  onCloseModal: () => void;
  callback?: () => void;
  setLoading?: any;
}

export default function ECPurchaseCancelAll({
  data,
  info,
  onCloseModal,
  setLoading,
  callback,
}: ECPurchaseCancelAllProps): JSX.Element | null {
  const totalAllPrice = useMemo(() => {
    if (Array.isArray(data) && data.length > 0) {
      return data.reduce(
        (init, next) => {
          if (next.amount) {
            init.amount += next.amount;
          }
          if (next.totalPrice) {
            init.totalPrice += next.totalPrice;
          }

          return init;
        },
        { amount: 0, totalPrice: 0 },
      );
    }
    return { amount: 0, totalPrice: 0 };
  }, [data]);

  const { openMessageError } = useMessage();

  if (!data) {
    return null;
  }

  const handleRefundAllPurchase = async () => {
    try {
      setLoading(true);
      await ECApis.purchaseRefundAll({ channelOrderId: info?.channelOrderId });
      onCloseModal();
      if (callback) {
        callback();
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="text-error mb-2">
        以下の注文をすべてキャンセルします。
        <br />
        DL開始日後のキャンセルの場合は返金処理のみとなります。よろしいですか。
      </div>
      <div className="d-flex flex-column mb-2">
        <span>注文番号: {info?.ecOrderId}</span>
        <span>ユーザーID: {info?.userMiimId}</span>
        <span>本名: {info?.userRealName}</span>
      </div>

      <div className="ec_purchase_cancel_all_modal-items_wrapper">
        {Array.isArray(data) &&
          data?.map((item: any) => {
            return (
              <div className="ec_purchase_detail-item mt-1" key={item?.coverImageUrl}>
                <Row>
                  <Col md="5">
                    <div className="ec_purchase_detail-item_infor d-flex">
                      <div>
                        {item?.coverImageUrl ? (
                          <img
                            className="ec_purchase_detail-image border"
                            alt="preview"
                            src={handleConnectStaticEndPoint(item?.coverImageUrl)}
                          />
                        ) : (
                          <div className="ec_purchase_detail-image bg-gray avatar-bank d-flex justify-content-center align-items-center">
                            <p className="gray-dark">No Image</p>
                          </div>
                        )}
                      </div>
                      <div className="d-flex flex-column ms-3">
                        <div className="mb-1 d-flex flex-column">
                          <span className="ec_fs-10">タイトル</span>
                          <span className="fw-bold ec_fs-18">{item?.titleName}</span>
                          <span className="ec_fs-12 ms-3">{item?.artistName}</span>
                        </div>
                        <div className="mb-1 d-flex flex-column">
                          <span className="ec_fs-10">バリエーション</span>
                          <span className="fw-bold ec_fs-18">{item?.variationName}</span>
                        </div>
                        <div className="mb-1">
                          <div>
                            <span className="ec_fs-10">販売用ID</span>
                          </div>
                          <div>
                            <span className="ec_fs-14">{item?.cardCd}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md="7">
                    <div className="d-flex flex-column justify-content-between h-100">
                      <div>
                        <div className="ec_purchase_detail-item_price">
                          <Row className="row-dpadding">
                            <Col md="3">
                              <div className="d-flex flex-column">
                                <span className="ec_fs-10">品番</span>
                                <span className="fw-bold ec_fs-18">{item?.productCode}</span>
                              </div>
                            </Col>
                            <Col md="3">
                              <div className="d-flex flex-column">
                                <span className="ec_fs-10">販売価格</span>
                                <span className="fw-bold ec_fs-18">{`¥${formatPrice(
                                  item?.purchasePrice,
                                )}`}</span>
                              </div>
                            </Col>
                            <Col md="3">
                              <div className="d-flex flex-column">
                                <span className="ec_fs-10">数量</span>
                                <span className="fw-bold ec_fs-18">{item?.amount}点</span>
                              </div>
                            </Col>
                            <Col md="3">
                              <div className="d-flex flex-column">
                                <span className="ec_fs-10">小計</span>
                                <span className="fw-bold ec_fs-18">{`¥${formatPrice(
                                  item?.totalPrice,
                                )}`}</span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="d-flex flex-column mt-2">
                          購入時キャンペーン
                          {Array.isArray(item?.campaigns) && item?.campaigns.length > 0 ? (
                            item?.campaigns?.map((campaign: any) => (
                              <div key={campaign?.id} className="ec_purchase_detail-campaign_link">
                                {campaign?.name}
                              </div>
                            ))
                          ) : (
                            <div className="ec_purchase_detail-campaign_link">なし</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
      </div>

      <div className="d-flex mt-2">
        <Row className="w-100 align-items-center">
          <Col md="5"></Col>
          <Col md="7">
            <div className="ec_purchase_detail-item_price">
              <Row className="align-items-end row-dpadding">
                <Col md="6">
                  <div className="d-flex flex-column">
                    <span className="fw-bold ec_fs-18">合計</span>
                  </div>
                </Col>

                <Col md="3">
                  <div className="d-flex flex-column">
                    <span className="ec_fs-10">数量</span>
                    <span className="fw-bold ec_fs-18">{totalAllPrice?.amount}点</span>
                  </div>
                </Col>
                <Col md="3">
                  <div className="d-flex flex-column">
                    <span className="ec_fs-10">合計</span>
                    <span className="fw-bold ec_fs-18">{`¥${formatPrice(
                      totalAllPrice?.totalPrice,
                    )}`}</span>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <Button className="me-4 btn btn-secondary" onClick={onCloseModal}>
          もどる
        </Button>
        <Button onClick={handleRefundAllPurchase}>キャンセル実行</Button>
      </div>
    </>
  );
}
