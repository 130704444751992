import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import BoxForm from 'Components/Common/Form/BoxForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { stringRequiredTrim } from 'Utils/Validation';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import FormGroupTextArea from 'Components/Common/Form/FormGroupTextArea';
import ModalComponent from 'Components/Common/Modal';
import { useHistory, useParams } from 'react-router-dom';
import CheckBoxs from 'Components/Common/Form/GroupCheckBox/CheckBoxs';
import { SerialTypeOptions } from 'Constant/SerialNumber';
import { ActionType } from 'Constant';
import { SerialApis } from 'Datasource/Serial';
import MessageError from 'Components/Common/MessageError';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';
import Dropzone from 'react-dropzone';
import { exportExcel } from 'Utils/File';
import * as XLSX from 'xlsx';
import { filter, isEmpty } from 'lodash';
import { RecordCompanyApi } from 'Datasource/RecordCompanyApi';
import FormSelectArtist from 'Components/Common/Select/FormSelectArtist';
import FormSelectTitle from 'Components/Common/Select/FormSelectTitle';
import FormSelectCD from 'Components/Common/Select/FormSelectCD';
import { formatCurrency, formatCurrencyFloat } from 'Utils/Numbers';

const schema = yup.object().shape({
  name: stringRequiredTrim({}).nullable(),
  quantity: yup
    .string()
    .when(['type'], {
      is: (type: number) => +type === 0,
      then: yup.string().required('入力してください。').nullable().trim(),
    })
    .nullable(),
  artistId: yup
    .string()
    .when(['type'], {
      is: (type: number) => +type === 0,
      then: yup.string().required('入力してください。').nullable().trim(),
    })
    .nullable(),
  titleId: yup
    .string()
    .when(['type', 'artistId'], {
      is: (type: number, artistId: string) => +type === 0 && artistId,
      then: yup.string().required('入力してください。').nullable().trim(),
    })
    .nullable(),
  cdId: yup
    .string()
    .when(['type', 'artistId', 'titleId'], {
      is: (type: number, artistId: string, titleId: string) => +type === 0 && artistId && titleId,
      then: yup.string().required('入力してください。').nullable().trim(),
    })
    .nullable(),
  file: yup
    .mixed()
    .when(['type', 'id'], {
      is: (type: number, id: string) => +type === 1 && !id,
      then: yup.mixed().required('入力してください。'),
    })
    .nullable(),
});

export default function CreateSerialNumber(): JSX.Element {
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const { openMessageError, openMessage } = useMessage();

  const [loading, setLoading] = useState<boolean>(false);
  const [modalConfirmInfo, setModalConfirmInfo] = useState<any>({
    show: false,
    type: null,
  });

  const [serialNumberId, setSerialNumberId] = useState<string | number>(id);
  const [serialTypeOptions, setSerialTypeOptions] = useState(SerialTypeOptions);
  const [snFee, setSNFee] = useState(`1.0`);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    trigger,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      name: '',
      type: 0,
      cdId: null,
      cdName: '',
      quantity: null,
      file: null,
      description: '',
      titleId: '',
      titleName: '',
      artistId: '',
      artistName: '',
      isAuthor: 1,
      isUsed: false,
    },
    resolver: yupResolver(schema),
  });

  const handleCreateSerialNumber = async (values: any) => {
    setModalConfirmInfo({ show: false, type: null });
    setLoading(true);
    try {
      if (serialNumberId) {
        await SerialApis.updateSerial({ params: { ...values, id: +serialNumberId } });
      } else {
        const {
          data: { data },
        } = await SerialApis.createSerial({ params: values });
        setSerialNumberId(data?.masterId);
      }
      history.push('/serial-numbers');
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteSerial = async () => {
    try {
      setModalConfirmInfo({ show: false, type: null });
      setLoading(true);
      await SerialApis.deleteSerialNumber({ id: serialNumberId });
      history.push('/serial-numbers');
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeInput = (nameFill: string, value: string | number | undefined) => {
    setValue(nameFill as any, value);
  };

  const handleOnDrop = (files: File[]) => {
    clearErrors('file');
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      if (!e) return;
      /* Parse data */
      const bstr = e?.target?.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      let validateError = false;
      data?.forEach((el: any) => {
        if (el?.length > 1) {
          validateError = true;
        }
      });

      if (validateError) {
        openMessage({
          variant: 'error',
          message: `${files[0]?.name}ファイルのアップロードに失敗しました。`,
        });
        return;
      }

      const converData = filter(data, (item: any) => {
        return item[0] != undefined;
      });

      const result = converData.map((el: any) => {
        return el[0];
      });
      const quantity = Array.from(new Set(result));
      setValue('quantity', quantity?.length);
      setValue('file', files[0]);
    };
    if (rABS) reader.readAsBinaryString(files[0]);
    else reader.readAsArrayBuffer(files[0]);
  };

  const handleExportCSV = async () => {
    try {
      await exportExcel(
        SerialApis.exportCSV({ id: serialNumberId }),
        watch('name') || 'serial-number-csv',
        'csv',
      );
    } catch (error) {
      openMessageError(error);
    }
  };

  const handleGetDetailSerialNumber = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await SerialApis.getSerialNumber({ id: id });
      reset({
        id: data.id,
        name: data.name,
        type: data.type,
        cdId: data.CDId,
        cdName: data.cd?.name,
        quantity: data.quantity,
        file: null,
        description: data.description,
        titleId: data.cd?.title?.id,
        titleName: data.cd?.title?.name,
        artistId: data.cd?.title?.artist?.id,
        artistName: data.cd?.title?.artist?.localName,
        isAuthor: data?.isAuthor,
        isUsed: data?.isUsed,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const getRecordCompany = async () => {
    try {
      const {
        data: { data },
      } = await RecordCompanyApi.getRecordCompany();
      SerialTypeOptions[0].subLabel = `CDに封入するためのシリアルコードを新規に発行します。１コード発行につき${
        String(formatCurrencyFloat(data?.snFee, 1)) || ''
      }円のオプション料金が発生します。`;
      setSNFee(`${data?.snFee}`);
      setSerialTypeOptions([...SerialTypeOptions]);
    } catch (error) {
      openMessageError(error);
    }
  };

  useEffect(() => {
    if (id) {
      handleGetDetailSerialNumber();
    }
    getRecordCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <Container className="serial-number-form">
        <Row>
          <Col md="12" className="my-4">
            <BoxForm title="シリアルコード発行">
              <Form
                onSubmit={handleSubmit(handleCreateSerialNumber)}
                id="create-serial"
                className={
                  !watch('isAuthor') ? 'pe-none user-select-none' : 'pe-auto user-select-auto'
                }
              >
                <FormGroupInput
                  label="シリアルコード名"
                  labelMd="2"
                  colMd="10"
                  register={register('name')}
                  value={watch('name')}
                  errorMessage={errors.name?.message}
                  onChange={(value) => handleChangeInput('name', value)}
                />
                <div
                  className={`${
                    serialNumberId ? 'pe-none user-select-none' : 'pe-auto user-select-auto'
                  }`}
                >
                  <CheckBoxs
                    id="type-serial"
                    labelMd="2"
                    colMd="10"
                    checkboxClass="flex-nowrap"
                    classOption="w-50"
                    labelForm="用途種別"
                    value={watch('type')}
                    onChange={(item) => {
                      setValue('type', item?.value);
                      setValue('quantity', '');
                      setValue('file', null);
                      clearErrors('file');
                    }}
                    options={serialTypeOptions}
                    errorMessage={errors?.type?.message}
                    disabled={!!serialNumberId}
                  />

                  <Row className="mt-3 p-0" hidden={watch('type') === serialTypeOptions[1].value}>
                    <Form.Label md={2} column className="m-0">
                      対象商品
                    </Form.Label>
                    <Col md={10} as={Row} className="px-0">
                      <FormSelectArtist
                        defaultValue={watch('artistName')}
                        colClass="px-0"
                        rowClass="mt-3 px-0"
                        labelMd="3"
                        colMd="5"
                        label="アーティスト"
                        placeholder="アーティストを選択してください。"
                        inputSearchString={watch('artistName')}
                        onSelect={(selectedItem) => {
                          reset({
                            ...getValues(),
                            artistId: selectedItem.value,
                            artistName: selectedItem.label,
                            titleId: '',
                            titleName: '',
                            cdId: '',
                            cdName: '',
                          });
                        }}
                        errorMessage={errors.artistId?.message}
                        onInput={(text) => {
                          if (!text) {
                            reset({
                              ...getValues(),
                              artistId: '',
                              artistName: '',
                              titleId: '',
                              titleName: '',
                              cdId: '',
                              cdName: '',
                            });
                          }

                          if (text?.trim() !== watch('artistName').trim()) {
                            reset({
                              ...getValues(),
                              artistId: '',
                              titleId: '',
                              titleName: '',
                              cdId: '',
                              cdName: '',
                            });
                          }
                        }}
                        permission={serialNumberId ? ActionType.EDIT : ActionType.CREATE}
                      />
                      <FormSelectTitle
                        colClass="px-0"
                        rowClass="mt-3 px-0"
                        labelMd="3"
                        colMd="5"
                        label="タイトル"
                        inputSearchString={watch('titleName')}
                        placeholder="タイトルを選択してください。"
                        disabled={!watch('artistId')}
                        onSelect={(selectedItem) => {
                          reset({
                            ...getValues(),
                            titleId: selectedItem.value,
                            titleName: selectedItem.label,
                          });
                        }}
                        errorMessage={errors.titleId?.message}
                        onInput={(text) => {
                          if (!text) {
                            reset({
                              ...getValues(),
                              titleId: '',
                              titleName: '',
                              cdId: '',
                              cdName: '',
                            });
                          }
                          if (text?.trim() !== watch('titleName').trim()) {
                            reset({
                              ...getValues(),
                              titleId: '',
                              cdId: '',
                              cdName: '',
                            });
                          }
                        }}
                        artistID={watch('artistId')}
                      />

                      <FormSelectCD
                        colClass="px-0"
                        rowClass="mt-3 px-0"
                        labelMd="3"
                        colMd="5"
                        disabled={!watch('titleId')}
                        inputSearchString={watch('cdName')}
                        label="CD商品名（バリエーション）・品番"
                        onSelect={(selectedItem) =>
                          reset({
                            ...getValues(),
                            cdId: selectedItem.value,
                            cdName: selectedItem.label,
                          })
                        }
                        onInput={(text: string) => {
                          if (!text) {
                            reset({
                              ...getValues(),
                              cdId: '',
                              cdName: '',
                            });
                          }
                          if (text?.trim() !== watch('cdName').trim()) {
                            reset({
                              ...getValues(),
                              cdId: '',
                            });
                          }
                        }}
                        placeholder="CD商品名（バリエーション）・品番を選択してください。"
                        errorMessage={errors.cdId?.message}
                        titleId={watch('titleId')}
                      />
                    </Col>
                  </Row>
                  <div
                    hidden={watch('type') === serialTypeOptions[1].value}
                    className="position-relative"
                  >
                    <FormInputNumber
                      classForm="mt-5 p-0"
                      name="quantity"
                      labelMd="2"
                      colMd="2"
                      label="発行数"
                      allowNegative={false}
                      maxValue={200000}
                      decimalScale={0}
                      value={watch('quantity')}
                      onChange={(value) => handleChangeInput('quantity', value)}
                      errorMessage={errors.quantity?.message}
                    />
                    {watch('quantity') && (
                      <div className="position-absolute fs-12 top-50 start-50 translate-middle ms-4 ps-4">
                        <MessageError
                          labelClass="mt-0"
                          message={`シリアルコードオプション料金 ${String(
                            formatCurrencyFloat(snFee, 1),
                          )} 円 x ${Number(watch('quantity'))} = ${formatCurrency(
                            parseFloat(snFee) * watch('quantity'),
                          )}円の料金が発生します。`}
                        />
                      </div>
                    )}
                  </div>
                  {watch('type') === serialTypeOptions[1].value && (
                    <>
                      <Form.Group
                        as={Row}
                        className="mt-5 p-0"
                        hidden={watch('type') === serialTypeOptions[0].value}
                      >
                        <Form.Label md={2} column className="m-0">
                          シリアルコード
                        </Form.Label>
                        <Col md={10} as={Row}>
                          <Col md={2} className="px-0">
                            <Dropzone
                              accept="text/csv"
                              onDropAccepted={(files) => handleOnDrop(files)}
                              maxFiles={1}
                              onDropRejected={(files: any) => {
                                if (!isEmpty(files)) {
                                  setError('file', {
                                    type: 'manual',
                                    message: `${files[0]?.file?.name}ファイルのアップロードに失敗しました。`,
                                  });
                                }
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps({})}>
                                  <input {...getInputProps()} />
                                  <Button className="btn-focus-none px-3 me-3 button-submit">
                                    CSVアップロード
                                  </Button>
                                </div>
                              )}
                            </Dropzone>
                          </Col>
                          {watch('quantity') ? (
                            <p className="fs-12 px-0 my-1">
                              {watch('quantity')}件を取り込みました。
                            </p>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Form.Group>
                      <Row md={2}>
                        <Col md={2} />
                        <Col md={10}>
                          <MessageError
                            classWrapper="w-max-content"
                            message={errors.file?.message}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </div>

                <FormGroupTextArea
                  labelMd="2"
                  colMd="7"
                  label="管理用備考"
                  register={register('description')}
                  classInput="mt-2"
                  rows={4}
                  value={watch('description')}
                  onChange={(value) => handleChangeInput('description', value)}
                />
                <hr />
                <div
                  className={`d-flex m-auto ${
                    watch('type') === serialTypeOptions[1].value
                      ? 'justify-content-end'
                      : 'justify-content-between'
                  }`}
                >
                  <div hidden={watch('type') === serialTypeOptions[1].value} className="w-50">
                    <Button
                      className="me-5 pe-auto"
                      disabled={!serialNumberId}
                      onClick={handleExportCSV}
                    >
                      CSVダウンロード
                    </Button>
                  </div>
                  {serialNumberId && watch('type') === serialTypeOptions[1].value && (
                    <>
                      <Button
                        variant="danger"
                        className="me-4 w-btn"
                        onClick={() => setModalConfirmInfo({ show: true, type: 0 })}
                        disabled={!watch('isAuthor') || watch('isUsed')}
                      >
                        削除する
                      </Button>
                    </>
                  )}
                  <Button
                    className="w-btn btn-focus-none"
                    variant="primary"
                    onClick={async () => {
                      const isValidForm = await trigger();
                      if (isValidForm) {
                        setModalConfirmInfo({ show: true, type: 1 });
                      }
                    }}
                    disabled={!watch('isAuthor')}
                  >
                    保存する
                  </Button>
                </div>
              </Form>
            </BoxForm>
          </Col>
        </Row>

        <ModalComponent
          title={modalConfirmInfo?.type === 0 ? '削除確認' : '登録確認'}
          show={modalConfirmInfo?.show}
          onCloseModal={() => setModalConfirmInfo({ show: false, type: null })}
          classNameContent="d-flex align-items-center content-body-modal justify-content-center"
          onSubmitModal={modalConfirmInfo?.type === 0 ? onDeleteSerial : undefined}
          confirmText={modalConfirmInfo?.type === 0 ? '削除する' : '登録する'}
          variantButtonSubmit={modalConfirmInfo?.type === 0 ? 'danger' : 'primary'}
          submitIdForm={modalConfirmInfo?.type === 0 ? undefined : 'create-serial'}
        >
          <div>
            {modalConfirmInfo?.type === 0
              ? 'シリアルコードを削除します。よろしいですか。'
              : 'シリアルコードを登録します。よろしいですか？'}
          </div>
        </ModalComponent>
      </Container>
    </>
  );
}
