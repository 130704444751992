import React from 'react';
import { Col, Container } from 'react-bootstrap';
import { Header, ItemVariation } from 'Components/Page/ListApproval/';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import { PAGE_SIZE_APPROVAL } from 'Constant';

export default function ListApproval(): JSX.Element {
  const DATA = [
    {
      id: 1,
      item: [
        { name: 'AAAAAAAAAAAAAAAAAAAAAAAAAA', type: 1 },
        { name: 'B', type: 2 },
        { name: 'c', type: 3 },
      ],
    },
    {
      id: 2,
      item: [
        { name: 'A', type: 1 },
        { name: 'B', type: 2 },
      ],
    },
    {
      id: 3,
      item: [],
    },
  ];

  return (
    <>
      <Container fluid className="list-approval">
        <Header />

        <Col md={12} className="d-flex justify-content-end p-0 px-4 mt-2">
          <PaginationComponent activePage={1} total={100} perPage={PAGE_SIZE_APPROVAL} />
        </Col>
        <>
          {DATA.map((item, index) => (
            <ItemVariation key={index} value={item} />
          ))}
        </>
      </Container>
    </>
  );
}
