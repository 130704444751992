import React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import JSCookie from 'js-cookie';
// import ReportByTitle from 'Page/Reports/ByTitle';
import Page404 from 'Page/Exception/404';
import Layout from 'Components/Layouts/Layout';

import Login from 'Page/Login';
import Artist from 'Page/Artist';

import ForgotPassword from 'Page/ForgotPassword';
import ResetPassword from 'Page/ResetPassword';
import ChangePassword from 'Page/ChangePassword';

import RegisterArtist from 'Page/Artist/Register';
import AccessCode from 'Page/AccessCode';

import CompanyRegisterSection from 'Page/Company/RegisterSection';
import CompanyRegister from 'Page/Company/CompanyRegister';

import PromotionManager from 'Page/PromotionManager';
import DetailTokuten from 'Page/PromotionManager/DetailTokuten';
import TitleList from 'Page/Title/List';
import TitleRegister from 'Page/Title/Register';

import OperatingCompanyInfo from 'Page/OperatingCompanyInfo/OperatingCompanyInfo';

import NFTList from 'Page/NFTList';
import CreateCompanyInfo from 'Page/CompanyInfo/CreateCompanyInfo';
import SessionManage from 'Page/CompanyInfo/SessionManage';
import CuratorManagement from 'Page/CuratorManagement';
import PaymentPrint from 'Page/PaymentNotice';
import ManagerCD from 'Page/ManagerCD';
import CreateCD from 'Page/ManagerCD/CreateCD';
import Jasrac from 'Page/InformationAccountant/Jasrac';
import RevenueManagement from 'Page/InformationAccountant/RevenueManagement';
import ListApproval from 'Page/ListApproval';
import { ReactNode } from 'Components/Layouts/common';

import Campaign from 'Page/Campaign/Campaign';
import RegisterCampaign from 'Page/Campaign/Register';

import FanProfile from 'Page/FanProfile';
import SerialNumberManagement from 'Page/ManagerSerial';
import CreateSerialNumber from 'Page/ManagerSerial/CreateSerialNumber';
import CostInfomation from 'Page/CostInfomation/CostInfomation';
import ListProducts from 'Page/ListProducts';
import ProductsDetail from 'Page/ListProducts/ProductsDetail';
import CreateProducts from 'Page/ListProducts/CreateProducts';

import { useAppSelector } from 'App/Store';
import { ACCOUNT_STATUS_ACTIVE, PREFIX_WEB_APP } from 'Constant';
import DashBoard from 'Page/Dashboard';
import StatisticCampaign from 'Page/Campaign/StatisticCampaign';

import ECListSaleProducts from 'Page/EC/ECListSaleProducts';
import ECSaleProductDetail from 'Page/EC/ECSaleProductDetail';
import ECListPurchase from 'Page/EC/ECListPurchase';
import ECPurchaseDetail from 'Page/EC/ECPurchaseDetail';

import LayoutWebApp from 'Components/Layouts/LayoutWebApp';
import ForgotPasswordWebApp from 'Page/WebApp/ForgotPassword/ForgotPassword';
import ResetPasswordWebApp from 'Page/WebApp/ResetPassword/ResetPassword';
import LoginWebApp from 'Page/WebApp/Login';
import SearchWebApp, { KeyLocalStorage } from 'Page/WebApp/SearchEvent/Search';
import UsingStatus from 'Page/WebApp/UsingStatus';
import ProcessBoard from 'Page/ProcessBoard';
import WebSettingAndPolicy from 'Page/EC/SettingSite';
import EmailManagement from 'Page/EC/EmailManagement';
import EcPurchaseRateSetting from 'Page/EC/PurchaseRateSetting/index';
import PaymentAdviceList from 'Page/EC/PaymentAdvice';
import PaymentAdviceDetail from 'Page/EC/PaymentAdvice/PaymentAdviceDetail';
import PurchaseSummaryDetail from 'Page/EC/PurchaseSummary/PurchaseSummaryDetail';
import PurchaseSummary from 'Page/EC/PurchaseSummary';
import RegisterShop from 'Page/ShopManagement/RegisterShop';
import JMDAudios from 'Components/Page/Title/TableJMDData/JMDAudios';
import JMDVideos from 'Components/Page/Title/TableJMDData/JMDVideos';
import SupportRevenue from 'Page/WebApp/SupportRevenue/SupportRevenue';
interface RouteProps {
  component: ReactNode;
  authed: boolean;
  userRole?: Array<string>;
  isWebApp?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const PrivateRoute = ({ component: Component, authed, isWebApp, ...rest }: RouteProps) => {
  return (
    <Route
      {...rest}
      render={() =>
        authed ? <Component /> : <Redirect to={isWebApp ? `${PREFIX_WEB_APP}/login` : '/login'} />
      }
    />
  );
};

const PublicRoute = ({ component: Component, authed, isWebApp, ...rest }: RouteProps) => {
  !authed && window?.localStorage?.removeItem(KeyLocalStorage.DataTickets);
  return (
    <Route
      {...rest}
      render={() =>
        authed && location.pathname !== '/reset-password' ? (
          <Redirect to={isWebApp ? `${PREFIX_WEB_APP}/search` : '/'} />
        ) : (
          <Component />
        )
      }
    />
  );
};

export default function Routing(): JSX.Element {
  const {
    auth: { status },
  } = useAppSelector((state) => state);
  const accessToken = JSCookie.get('token');

  const isAccept = !!accessToken && status === ACCOUNT_STATUS_ACTIVE;
  const isAcceptChangePassword = !!accessToken;

  const { pathname } = useLocation();

  return (
    <Switch>
      <PublicRoute authed={isAccept} exact strict path="/login" component={Login} />
      <PublicRoute
        authed={isAccept}
        exact
        strict
        path="/forgot-password"
        component={ForgotPassword}
      />
      <PublicRoute
        authed={isAccept}
        exact
        strict
        path="/reset-password"
        component={ResetPassword}
      />
      <Route exact strict path="/code/:accessCode" component={AccessCode} />
      <Route exact strict path="/title/jmd-audio" component={JMDAudios} />
      <Route exact strict path="/title/jmd-video" component={JMDVideos} />
      <PrivateRoute
        authed={isAcceptChangePassword}
        exact
        strict
        path="/change-password"
        component={ChangePassword}
      />
      {pathname.includes(PREFIX_WEB_APP) ? (
        <Switch>
          <LayoutWebApp isAccept={isAccept}>
            <PublicRoute
              isWebApp
              authed={isAccept}
              exact
              strict
              path={`${PREFIX_WEB_APP}`}
              component={() => (
                <Redirect to={isAccept ? `${PREFIX_WEB_APP}/search` : `${PREFIX_WEB_APP}/login`} />
              )}
            />
            <PublicRoute
              isWebApp
              authed={isAccept}
              exact
              strict
              path={`${PREFIX_WEB_APP}/login`}
              component={LoginWebApp}
            />
            <PublicRoute
              authed={isAccept}
              exact
              strict
              isWebApp
              path={`${PREFIX_WEB_APP}/forgot-password`}
              component={ForgotPasswordWebApp}
            />
            <PublicRoute
              authed={isAccept}
              strict
              isWebApp
              path={`${PREFIX_WEB_APP}/reset-password`}
              component={ResetPasswordWebApp}
            />
            <PrivateRoute
              isWebApp
              authed={isAccept}
              exact
              strict
              path={`${PREFIX_WEB_APP}/search`}
              component={SearchWebApp}
            />
            <PrivateRoute
              isWebApp
              authed={isAccept}
              exact
              strict
              path={`${PREFIX_WEB_APP}/support-revenue`}
              component={SupportRevenue}
            />

            <PrivateRoute
              isWebApp
              authed={isAccept}
              exact
              strict
              path={`${PREFIX_WEB_APP}/using-status`}
              component={UsingStatus}
            />
          </LayoutWebApp>
        </Switch>
      ) : (
        <Layout>
          <Switch>
            {/* TO DO FOR PHASE 2 */}
            {/* <PrivateRoute authed={isAccept} exact strict path="/" component={ReportByTitle} /> */}
            <PrivateRoute authed={isAccept} exact strict path="/" component={DashBoard} />
            <PrivateRoute authed={isAccept} exact strict path="/artist" component={Artist} />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/artist/register-artist"
              component={RegisterArtist}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/artist/detail-artist/:id"
              component={RegisterArtist}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/company/register-section"
              component={CompanyRegisterSection}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/register-company"
              component={CompanyRegister}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/digital-tokuten"
              component={PromotionManager}
            />

            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/digital-tokuten/:id"
              component={DetailTokuten}
            />
            <PrivateRoute authed={isAccept} exact strict path="/title/list" component={TitleList} />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/title/register"
              component={TitleRegister}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/title-detail/:id"
              component={TitleRegister}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/operating-company-info"
              component={OperatingCompanyInfo}
            />
            <PrivateRoute authed={isAccept} exact strict path="/nft-list" component={NFTList} />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/create-company-info"
              component={CreateCompanyInfo}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/session-manage"
              component={SessionManage}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/shop-management"
              component={RegisterShop}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/curator-management"
              component={CuratorManagement}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/payment-notice"
              component={PaymentPrint}
            />
            <PrivateRoute authed={isAccept} exact strict path="/cd-master" component={ManagerCD} />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/create-cd-master"
              component={CreateCD}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/cd-master/:id"
              component={CreateCD}
            />
            <PrivateRoute authed={isAccept} exact strict path="/jasrac" component={Jasrac} />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/list-approval"
              component={ListApproval}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/fan-profile"
              component={FanProfile}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/serial-numbers"
              component={SerialNumberManagement}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/create-serial-number"
              component={CreateSerialNumber}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/serial-numbers/:id"
              component={CreateSerialNumber}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/revenue-management"
              component={RevenueManagement}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/cost-information"
              component={CostInfomation}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/campaign-list"
              component={Campaign}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/register-campaign"
              component={RegisterCampaign}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/campaigns/:id"
              component={RegisterCampaign}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/list-products"
              component={ListProducts}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/create-products/:id"
              component={CreateProducts}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/products-detail/:id"
              component={ProductsDetail}
            />

            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/products-detail"
              component={ProductsDetail}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/statistic-campaign/:campaignId"
              component={StatisticCampaign}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/ec/list-sale-products"
              component={ECListSaleProducts}
            />

            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/ec/sale-product-detail/:id"
              component={ECSaleProductDetail}
            />

            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/ec/purchase-detail/:id"
              component={ECPurchaseDetail}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/ec/setting-site"
              component={WebSettingAndPolicy}
            />

            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/ec/list-purchase"
              component={ECListPurchase}
            />

            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/ec/email-message-management"
              component={EmailManagement}
            />

            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/ec/purchasing-rate-setting"
              component={EcPurchaseRateSetting}
            />

            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/ec/payment-advice-list"
              component={PaymentAdviceList}
            />

            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/ec/payment-advice-detail/:id"
              component={PaymentAdviceDetail}
            />
            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/processboard"
              component={ProcessBoard}
            />

            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/ec/purchase-summary-list"
              component={PurchaseSummary}
            />

            <PrivateRoute
              authed={isAccept}
              exact
              strict
              path="/ec/purchase-summary-detail/:id"
              component={PurchaseSummaryDetail}
            />

            <PrivateRoute authed={isAccept} exact strict component={Page404} />
          </Switch>
        </Layout>
      )}
      <Route component={Page404} />
    </Switch>
  );
}
