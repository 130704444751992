import React, { useCallback, useEffect, useState } from 'react';
import { FormGroupSearchProps } from 'Components/Common/Form/form';
import FormGroupSearch from 'Components/Common/Form/FormGroupSearch';
import { SearchResponse } from 'Hooks/useSuggestion';
import useMessage from 'Hooks/useMessage';
import { PER_PAGE } from 'Constant';
import { ArtistApi } from 'Datasource/ArtistApi';
import { updateQuery } from 'Utils/Search';

export interface SelectArtistProps
  extends Omit<FormGroupSearchProps, 'onInputChange' | 'onSearch'> {
  permission: number;
  onInput?: (keyword: string) => void;
}

export default function FormSelectArtist(props: SelectArtistProps): JSX.Element {
  const [page, setPage] = useState<number>(1);
  const [artists, setArtists] = useState<SearchResponse>({ options: [], totalPages: 0 });
  const [loadingArtist, setLoadingArtist] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const { openMessageError } = useMessage();

  const { onInput } = props;

  const handleSearchArtist = async () => {
    try {
      setLoadingArtist(true);
      const {
        data: { data },
      } = await ArtistApi.getAllSuggestionArtist({
        page: page,
        perPage: PER_PAGE,
        query: encodeURIComponent(searchKey),
        action: props.permission,
      });

      const options = data?.items?.map((item: { id: number; localName: string }) => ({
        label: item.localName,
        value: item.id,
      }));
      setArtists({
        options: page === 1 ? options : [...artists.options, ...options],
        totalPages: data?.meta?.totalPages,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingArtist(false);
    }
  };

  const handleLoadmore = () => {
    if (page < artists.totalPages) {
      let p = page;
      p += 1;
      setPage(p);
    }
  };

  useEffect(() => {
    handleSearchArtist();
  }, [searchKey, page]);

  const debouceRequest = useCallback(
    (value: string) =>
      updateQuery(value, () => {
        setSearchKey(value);
        setPage(1);
        onInput && onInput(value);
      }),
    [onInput],
  );

  return (
    <FormGroupSearch
      onLoadMore={() => handleLoadmore()}
      onMenuClose={() => {
        setSearchKey('');
        setPage(1);
      }}
      isLoading={loadingArtist}
      options={artists.options || []}
      onInputChange={(keyword: string) => debouceRequest(keyword)}
      onMenuFocus={() => {
        setArtists({ options: [], totalPages: 0 });
        handleSearchArtist();
      }}
      showIconClear
      {...props}
    />
  );
}
