import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PromotionManagerState {
  artistId?: string;
  titleId?: string;
  variationId?: string;
}

const initialState: PromotionManagerState = {
  artistId: '',
  titleId: '',
  variationId: '',
};

export const promotionManagerSlice = createSlice({
  name: 'promotionManager',
  initialState,
  reducers: {
    setPromotionFilter: (
      state,
      data: PayloadAction<{ artistId: string; titleId: string; variationId: string }>,
    ) => {
      const { payload } = data;
      return { ...payload };
    },
  },
});

export const { setPromotionFilter } = promotionManagerSlice.actions;
export default promotionManagerSlice.reducer;
