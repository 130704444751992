import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { IDigital } from 'Types/Common';
import MessageError from 'Components/Common/MessageError';

export default function ItemDigital(props: IDigital): JSX.Element {
  const {
    element,
    labelMd,
    colMd,
    titleLeft,
    placeholder,
    errorMessage,
    register,
    onSubmit,
    maxLength,
    registerName,
    titleName,
    errorMessageName,
  } = props;
  const hanlderConfirmModal = () => {
    if (onSubmit) {
      return onSubmit();
    }
    return;
  };

  return (
    <div className="item-digital">
      <Form.Group as={Row} className="mb-2">
        <Col md={labelMd}>
          <div className="box-image">{element}</div>
        </Col>
        <Col md={colMd}>
          <Row className="d-flex flex-wrap align-items-center">
            {titleName && (
              <>
                <div className="title-left w-15">{titleName}</div>
                <div className="box-inp w-85">
                  <Form.Control {...registerName} type="text" />
                </div>
                <div className="w-85 ms-auto">
                  <MessageError message={errorMessageName} />
                </div>
                <div className="mt-4" />
              </>
            )}
            <>
              <div className="title-left w-15">{titleLeft}</div>
              <div className="box-inp w-85">
                <Form.Control
                  {...register}
                  type="text"
                  placeholder={placeholder}
                  maxLength={maxLength}
                />
              </div>
              <div className="w-85 ms-auto">
                <MessageError message={errorMessage} />
              </div>
            </>
            <div className="w-100 d-flex justify-content-end mt-3">
              <Button className="ms-auto" variant="danger" onClick={hanlderConfirmModal}>
                削除
              </Button>
            </div>
          </Row>
        </Col>
      </Form.Group>
    </div>
  );
}
