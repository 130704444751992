const COLOR_ENTRANCES = ['#F2CCD8', '#F5F5F5'];
const COLOR_ENTRANCES_SELECTED = ['#EA2C68', '#FFEAF0'];

const COLOR_TOKUTEN = ['#F4E8CC', '#F5F5F5'];
const COLOR_TOKUTEN_SELECTED = ['#C6962A', '#F9F2E2'];

const COLOR_TEXT = ['white', 'black'];

const TYPE_TICKETS = { ENTRANCE: 0, TOKUTEN: 1 };
const NAME_TICKETS = ['入場券', '特典券'];

export {
  COLOR_ENTRANCES,
  COLOR_ENTRANCES_SELECTED,
  COLOR_TOKUTEN,
  COLOR_TOKUTEN_SELECTED,
  COLOR_TEXT,
  TYPE_TICKETS,
  NAME_TICKETS,
};
