import { ISelect } from 'Types/Common';

/**
 * enum OptionBuyingRate BE
 * equal "option" key BE
 */
export const PAYMENT_DATE_TYPES: ISelect[] = [
  { label: '翌月', value: 0 },
  { label: '翌々月', value: 1 },
];

/**
 * enum HolidayProcess  BE
 * equal "holidayProcess" key BE
 */
export const HOLIDAY_PAYMENT_DATE_TYPES: ISelect[] = [
  { label: 'そのまま', value: 0 },
  { label: '前営業日', value: 1 },
  { label: '翌営業日', value: 2 },
];

export const END_DAY_PAYMENT_DATE = {
  label: '末日',
  value: undefined,
};

/**
 * equal "day" key BE
 * if value: undefined -> set value to null
 */
export const PAYMENT_DATE: ISelect[] = [
  { label: '1日', value: 1 },
  { label: '2日', value: 2 },
  { label: '3日', value: 3 },
  { label: '4日', value: 4 },
  { label: '5日', value: 5 },
  { label: '6日', value: 6 },
  { label: '7日', value: 7 },
  { label: '8日', value: 8 },
  { label: '9日', value: 9 },
  { label: '10日', value: 10 },
  { label: '11日', value: 11 },
  { label: '12日', value: 12 },
  { label: '13日', value: 13 },
  { label: '14日', value: 14 },
  { label: '15日', value: 15 },
  { label: '16日', value: 16 },
  { label: '17日', value: 17 },
  { label: '18日', value: 18 },
  { label: '19日', value: 19 },
  { label: '20日', value: 20 },
  { label: '21日', value: 21 },
  { label: '22日', value: 22 },
  { label: '23日', value: 23 },
  { label: '24日', value: 24 },
  { label: '25日', value: 25 },
  { label: '26日', value: 26 },
  { label: '27日', value: 27 },
  { label: '28日', value: 28 },
  { label: '29日', value: 29 },
  { label: '30日', value: 30 },
  { label: '31日', value: 31 },
  { label: END_DAY_PAYMENT_DATE.label, value: END_DAY_PAYMENT_DATE.value },
];
