import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import queryString from 'query-string';

export interface IQueryParam {
  page?: number | undefined;
  artistSearch?: string;
  variationSearch?: string;
  dateSearch?: string;
  titleSearch?: string;
  endSaleDate?: string;
  startSaleDate?: string;
  startDLDate?: string;
  endDLDate?: string;
  artistSlcId?: string;
  titleSlcId?: string;
  variationSlcId?: string;
}
function isJSON(str: string) {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
}

export const addQueryParams = (url: string, query: IQueryParam) => {
  if (url) {
    const search = queryString.stringify(query);
    return `${url}?${search}`;
  }
  return '';
};

export const getParamsFromQueryString = (search: any) => {
  const params = queryString.parse(search);
  const result: any = {};
  for (const [key, value] of Object.entries(params)) {
    const newValue: any = value;
    if (key) {
      if (isJSON(newValue)) {
        result[key] = JSON.parse(newValue);
      } else {
        result[key] =
          /^\d+$/.test(newValue) && newValue[0] != 0 ? parseInt(newValue, 10) : newValue;
      }
    }
  }
  return result;
};

export const getUserGMT = () => dayjs().utcOffset() / 60;

export const transformQuery = (params: any): string => {
  let strParam = '';
  if (!isEmpty(params)) {
    strParam = Object.entries(params)
      .filter(([, val]: any) => !!val || val === 0)
      .map(([key, val]: any) => `${key}=${encodeURIComponent(val)}`)
      .join('&');
    strParam = `?${strParam}`;
  }
  return strParam;
};

export const getQueryParam = (param: string, locationSearch: string) => {
  const searchParams = new URLSearchParams(locationSearch);
  return searchParams.get(param);
};
