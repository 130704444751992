import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Cell } from 'react-table';
import TableCommon from 'Components/Common/Table/TableCommon';

import {
  renderCampaignInformation,
  renderId,
  renderJanCode,
  renderPrice,
  renderProductsCode,
  renderSaleId,
  renderSalesChannel,
  renderStartDate,
  renderUpdate,
  renderVariation,
} from 'Components/Page/ListProducts/ColumnsTableProducts';
import { SortMethod } from 'Components/Common/Table/table';

interface IListProducts {
  [key: string]: any;
}

interface ITableProducts {
  data: any;
  onSort?: (sortingState: SortMethod[]) => void;
}

export default function TableListProducts({ data, onSort }: ITableProducts): JSX.Element {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: '商品No.',
            accessor: 'id',
            width: '100',
            className: 'd-flex align-items-center justify-content-center',
            Cell: ({ row }: Cell<IListProducts>) => {
              return renderId(row);
            },
          },
          {
            Header: '管理名称',
            width: '300',
            accessor: 'type',
            Cell: ({ row }: Cell<IListProducts>) => {
              return renderSalesChannel(row);
            },
          },
          {
            Header: 'キャンペーン情報',
            width: '300',
            className: 'align-items-center',
            Cell: ({ row }: Cell<IListProducts>) => {
              return renderCampaignInformation(row);
            },
          },
          {
            Header: '販売用ID',
            width: '300',
            Cell: ({ row }: Cell<IListProducts>) => {
              return renderSaleId(row);
            },
          },
          {
            Header: 'バリエーション',
            width: '250',
            Cell: ({ row }: Cell<IListProducts>) => {
              return renderVariation(row);
            },
          },
          {
            Header: 'DL開始日時',
            width: '150',
            Cell: ({ row }: Cell<IListProducts>) => {
              return renderStartDate(row);
            },
          },
          {
            Header: '販売価格',
            width: '100',
            Cell: ({ row }: Cell<IListProducts>) => {
              return renderPrice(row);
            },
          },
          {
            Header: '品番',
            width: '100',
            Cell: ({ row }: Cell<IListProducts>) => {
              return renderProductsCode(row);
            },
          },
          {
            Header: 'JANコード',
            width: '150',
            Cell: ({ row }: Cell<IListProducts>) => {
              return renderJanCode(row);
            },
          },
          {
            Header: '登録更新日',
            width: '150',
            accessor: 'updatedAt',
            className: 'd-flex align-items-center justify-content-center',
            Cell: ({ row }: Cell<IListProducts>) => {
              return renderUpdate(row);
            },
          },
        ],
      },
    ],
    [],
  );

  return (
    <Row className="px-4 mt-3">
      <div className="table-list-products">
        <Row>
          <Col md={12} className="d-flex justify-content-center p-0">
            <TableCommon
              heightBody="calc(100vh - 280px)"
              data={data}
              columns={columns}
              getCellProps={() => {
                return {
                  className: 'p-0',
                };
              }}
              getRowProps={(row) => {
                return {
                  className: `${row?.original?.type === 0 && 'master-record-row'}`,
                };
              }}
              messageNoData="検索結果がありません。"
              manualSortBy
              onSort={onSort}
            />
          </Col>
        </Row>
      </div>
    </Row>
  );
}
