import React from 'react';
import { Button, Col, Container, FormControl, ProgressBar, Row } from 'react-bootstrap';
import AgeChart from 'Components/Page/FanProfile/Charts/AgeChart';
import PerformanceChart from 'Components/Page/FanProfile/Charts/PerformanceChart';
import ColumnNoSpacing from 'Components/Common/Column/ColumnNoSpacing';
import ProvincesChart from 'Components/Page/FanProfile/Charts/ProvincesChart';
import Tabs from 'Components/Common/Tabs/Tabs';

const MainFanProfile = (): JSX.Element => {
  return (
    <div>
      <Container>
        <Row className="justify-content-end my-3">
          <Button variant="primary" className="btn-excel btn-focus-none">
            Excelダウンロード
          </Button>
        </Row>
        <Row className="justify-content-center">
          <Col md="4">
            <Row className="mb-2">
              <FormControl placeholder="アーティスト名" />
            </Row>
            <Row className="mb-2">
              <FormControl placeholder="タイトル名" />
            </Row>
            <Row className="mb-2">
              <FormControl placeholder="Date" />
            </Row>
          </Col>
          <Col md="4">
            <Row className="mb-2">
              <FormControl placeholder="アーティスト名" />
            </Row>
            <Row className="mb-2">
              <FormControl placeholder="タイトル名" />
            </Row>
            <Row className="mb-2">
              <FormControl placeholder="Date" />
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center mb-3">
          <Col md="5">
            <div className="d-flex justify-content-between mb-2">
              <div className="">男性</div>
              <div className="">女性</div>
            </div>
            <ProgressBar className="border custom-progress-bar">
              <ProgressBar
                className="text-white bg-dark"
                variant="dark"
                now={80.6}
                key={1}
                label={`${80.6}%`}
              />
              <ProgressBar
                className="text-dark"
                variant="white"
                now={19.4}
                key={2}
                label={`${19.4}%`}
              />
            </ProgressBar>
          </Col>
          <Col md="5">
            <div className="d-flex justify-content-between mb-2">
              <div className="">男性</div>
              <div className="">女性</div>
            </div>
            <ProgressBar className="border custom-progress-bar">
              <ProgressBar
                className="text-white bg-dark"
                variant="dark"
                now={80.6}
                key={1}
                label={`${80.6}%`}
              />
              <ProgressBar
                className="text-dark"
                variant="white"
                now={19.4}
                key={2}
                label={`${19.4}%`}
              />
            </ProgressBar>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="6">
            <div className="border h-100">
              <AgeChart />
            </div>
          </Col>
          <Col md="6">
            <div className="border h-100">
              <AgeChart />
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <ColumnNoSpacing md="6">
            {[...Array(5)].map((_, i) => (
              <Row key={i} className="mb-2">
                <Col md="5">
                  <FormControl placeholder="" />
                </Col>
                <Col md="5">
                  <FormControl placeholder="" />
                </Col>
                <Col md="2" className="d-flex align-items-center">
                  <FormControl placeholder="" /> <span className="ps-1">%</span>
                </Col>
              </Row>
            ))}
          </ColumnNoSpacing>
          <ColumnNoSpacing md="6">
            {[...Array(5)].map((_, i) => (
              <Row key={i} className="mb-2">
                <Col md="5">
                  <FormControl placeholder="" />
                </Col>
                <Col md="5">
                  <FormControl placeholder="" />
                </Col>
                <Col md="2" className="d-flex align-items-center">
                  <FormControl placeholder="" /> <span className="ps-1">%</span>
                </Col>
              </Row>
            ))}
          </ColumnNoSpacing>
        </Row>

        <Row className="mb-3">
          <Col>
            <Row>二次マーケティングのパフォーマンス散布図</Row>
            <Row>
              <Col md="6">
                <div className="d-flex align-items-center justify-content-end">
                  <div className="legend-circle me-1" />
                  リリカルスクール 夏なんだよ
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <div className="legend-circle me-1" />
                  リリカルスクール 冬なんです
                </div>
              </Col>
              <Col md="6" className="d-flex">
                <div className="me-3">
                  <div className="border px-2 py-1 text-center report-tab">Gift</div>
                  <div className="border px-2 py-1 text-white bg-dark text-center report-tab">
                    3.001
                  </div>
                  <div className="border px-2 py-1 text-center report-tab">10%</div>
                </div>
                <div>
                  <div className="border px-2 py-1 text-center report-tab">Audition</div>
                  <div className="border px-2 py-1 text-white bg-dark text-center report-tab">
                    3.001
                  </div>
                  <div className="border px-2 py-1 text-center report-tab">10%</div>
                </div>
              </Col>
            </Row>
            <div
              style={{
                width: '100%',
                height: '400px',
              }}
            >
              <PerformanceChart />
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <div>顧客A</div>
          <div>都道府県別住居地（以下、 イメージ）</div>
          <ProvincesChart />
        </Row>
        <Row className="justify-content-end">
          <Tabs
            tabActive="順位別"
            groupClass="w-15"
            btnClass="py-2"
            options={[{ name: '順位別' }, { name: '地域別' }]}
            disabledSetQuery
            disabledSetPage
          />
        </Row>
      </Container>
    </div>
  );
};

export default MainFanProfile;
