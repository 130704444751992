import React, { useMemo } from 'react';
import { Row, Form } from 'react-bootstrap';
import { formatCurrency } from 'Utils/Numbers';
import { ICost } from 'Types/Page/CompanyInformation/CompanyInformation';
import { COL_END_CLASS, COL_START_CLASS } from 'Constant';
import {
  renderArtistName,
  renderBuyFeeMoney,
  renderCardCd,
  renderDownloadDate,
  renderFeeName,
  renderUnitTaxFee,
  renderOptionTaxFee,
  renderPrice,
  renderProductId,
  // renderProductName,
  renderPurchaseDate,
  renderSnFee,
  renderSnFeeMoney,
  renderTaxFeeMoney,
  renderTitleName,
  renderTitleNameMiimEc,
  renderTotalAmount,
  renderTotalMoney,
  renderVariationName,
} from 'Components/Page/InformationAccountant/renderReportColumn';
import AliTable, { AliColumn, AliSortType } from 'Components/Common/Table/AliTable';
import { unionBy } from 'lodash';
import { useAppSelector } from 'App/Store';
import { ROLE_RECORD_MENU } from 'Constant/Roles';
interface Props {
  dataSource: ICost[];
  option: number;
  filters?: any;
  activeColumns?: any;
  setActiveColumns?: any;
  onSort?: (sortingState: AliSortType[]) => void;
}
export default function TableCostManagement({
  dataSource,
  option,
  activeColumns,
  setActiveColumns,
  onSort,
  filters,
}: Props): JSX.Element {
  const { role } = useAppSelector((state) => state.auth);

  const isMiimEcOrExternalEc = useMemo(() => {
    if (
      role &&
      (role === ROLE_RECORD_MENU.ECCompMaster ||
        role === ROLE_RECORD_MENU.ECCompManager ||
        role === ROLE_RECORD_MENU.SALEChanelMaster ||
        role === ROLE_RECORD_MENU.SALEChanelManager)
    ) {
      return true;
    }
    return false;
  }, [role]);

  const isAllChecked = useMemo(() => {
    if (option !== 2) {
      return false;
    }
    const activeColumnsId = activeColumns?.map((col: any) => col?.purchaseId);
    const dataColumnsId = dataSource
      ?.filter((c: any) => !c.deleted)
      ?.map((col: any) => col?.purchaseId);

    return dataColumnsId.every((colId: any) => activeColumnsId?.includes(colId));
  }, [dataSource, activeColumns, option]);

  const addOrRemoveAllColumns = (e: any) => {
    const dataColumnsId = dataSource
      .filter((c: any) => !c.deleted)
      .map((col: any) => col?.purchaseId);

    if (e.target.checked) {
      const newActiveColumns = unionBy(dataSource, activeColumns, 'purchaseId');
      setActiveColumns(newActiveColumns.filter((c: any) => !c.deleted));
    } else {
      const newActiveColumns = activeColumns.filter(
        (col: any) => !dataColumnsId.includes(col?.purchaseId),
      );
      setActiveColumns(newActiveColumns);
    }
  };

  const addOrRemoveColumns = (val: any) => {
    const foundSourceData = dataSource.find((col: any) => col?.purchaseId === val);
    const foundData = activeColumns.find((col: any) => col?.purchaseId === val);
    if (foundData) {
      const newActiveColumns = activeColumns.filter((col: any) => col?.purchaseId !== val);
      setActiveColumns(newActiveColumns);
    } else {
      const newActiveColumns = [...activeColumns, foundSourceData];
      setActiveColumns(newActiveColumns);
    }
  };

  //* column month
  const columnMonth = useMemo(() => {
    const initColumnMonth = [
      renderProductId,
      // renderProductName,
      renderArtistName,
      isMiimEcOrExternalEc ? renderTitleNameMiimEc : renderTitleName,
      renderVariationName,
      renderCardCd,
      renderDownloadDate,
      renderPrice,
      renderFeeName,
      renderTotalAmount,
      {
        name: '定額料金',
        align: 'center',
        width: 160,
        children: [
          {
            name: '単価',
            width: 100,
            code: 'optionCardBuyFee',
            align: 'center',
            render: (value: any) => {
              return <div className={`${COL_END_CLASS}`}>{formatCurrency(value)}</div>;
            },
          },
          renderBuyFeeMoney,
        ],
      },
      {
        name: '定率料金',
        align: 'center',
        width: 160,
        children: [renderUnitTaxFee, renderOptionTaxFee, renderTaxFeeMoney],
      },
      {
        name: 'オプション料金',
        align: 'center',
        width: 160,
        children: [renderSnFee, renderSnFeeMoney],
      },
      renderTotalMoney,
    ];

    if (filters) {
      if (filters.isViewProductId) {
        renderProductId.hidden = false;
      }
      if (!filters.isViewProductId) {
        renderProductId.hidden = true;
      }

      if (filters.isViewArtistName) {
        renderArtistName.hidden = false;
      }

      if (!filters.isViewArtistName) {
        renderArtistName.hidden = true;
      }

      if (filters.isViewTitleName) {
        renderTitleName.hidden = false;
        renderTitleNameMiimEc.hidden = false;
      }

      if (!filters.isViewTitleName) {
        renderTitleName.hidden = true;
        renderTitleNameMiimEc.hidden = true;
      }

      if (filters.isViewVariationName) {
        renderVariationName.hidden = false;
      }

      if (!filters.isViewVariationName) {
        renderVariationName.hidden = true;
      }

      if (filters.isViewVariationProductCode) {
        renderCardCd.hidden = false;
      }

      if (!filters.isViewVariationProductCode) {
        renderCardCd.hidden = true;
      }

      if (filters.isViewDownloadDate) {
        renderDownloadDate.hidden = false;
      }

      if (!filters.isViewDownloadDate) {
        renderDownloadDate.hidden = true;
      }
    }
    return [...initColumnMonth] as AliColumn[];
  }, [filters, isMiimEcOrExternalEc]);

  //* column daily
  const columnDaily = useMemo(() => {
    const initColumnDaily = [
      renderProductId,
      renderPurchaseDate,
      // renderProductName,
      renderArtistName,
      isMiimEcOrExternalEc ? renderTitleNameMiimEc : renderTitleName,
      renderVariationName,
      renderCardCd,
      renderDownloadDate,
      renderPrice,
      renderFeeName,
      renderTotalAmount,
      {
        name: '定額料金',
        align: 'center',
        width: 160,
        children: [
          {
            name: '単価',
            width: 100,
            code: 'optionCardBuyFee',
            align: 'center',
            render: (value: any) => {
              return <div className={`${COL_END_CLASS}`}>{formatCurrency(value)}</div>;
            },
          },
          renderBuyFeeMoney,
        ],
      },
      {
        name: '定率料金',
        align: 'center',
        width: 160,
        children: [renderUnitTaxFee, renderOptionTaxFee, renderTaxFeeMoney],
      },
      {
        name: 'オプション料金',
        align: 'center',
        width: 160,
        children: [renderSnFee, renderSnFeeMoney],
      },
      renderTotalMoney,
    ];

    if (filters) {
      if (filters.isViewProductId) {
        renderProductId.hidden = false;
      }
      if (!filters.isViewProductId) {
        renderProductId.hidden = true;
      }

      if (filters.isViewArtistName) {
        renderArtistName.hidden = false;
      }

      if (!filters.isViewArtistName) {
        renderArtistName.hidden = true;
      }

      if (filters.isViewTitleName) {
        renderTitleName.hidden = false;
        renderTitleNameMiimEc.hidden = false;
      }

      if (!filters.isViewTitleName) {
        renderTitleName.hidden = true;
        renderTitleNameMiimEc.hidden = true;
      }

      if (filters.isViewVariationName) {
        renderVariationName.hidden = false;
      }

      if (!filters.isViewVariationName) {
        renderVariationName.hidden = true;
      }

      if (filters.isViewVariationProductCode) {
        renderCardCd.hidden = false;
      }

      if (!filters.isViewVariationProductCode) {
        renderCardCd.hidden = true;
      }

      if (filters.isViewDownloadDate) {
        renderDownloadDate.hidden = false;
      }

      if (!filters.isViewDownloadDate) {
        renderDownloadDate.hidden = true;
      }
    }

    return [...initColumnDaily] as AliColumn[];
  }, [filters, isMiimEcOrExternalEc]);

  //* column detail
  const columnDetail = useMemo(() => {
    const initColumnDetail = [
      {
        name: (
          <Form.Check
            type="checkbox"
            id="isCheckAll"
            name="isCheckAll"
            // checked={false}
            className="ec_custom-checkbox"
            checked={isAllChecked}
            // onChange={() => setValue('isPublic', !watch('isPublic'))}
            onChange={addOrRemoveAllColumns}
          />
        ),
        lock: true,
        className: 'd-flex align-items-center justify-content-center',
        width: 50,
        code: 'purchaseId',
        render: (value: any) => {
          const foundCol = activeColumns?.find((col: any) => col?.purchaseId == value);
          return (
            <div className="d-flex align-items-center justify-content-center h-100 p-2">
              <Form.Check
                type="checkbox"
                id={value}
                name={value}
                // checked={false}
                className="ec_custom-checkbox"
                checked={!!foundCol}
                // onChange={() => setValue('isPublic', !watch('isPublic'))}
                // onChange={(e) => setIsShowOnlyMaster(e.target.checked)}
                onChange={() => addOrRemoveColumns(value)}
              />
            </div>
          );
        },
      },
      renderProductId,
      renderPurchaseDate,
      {
        name: '購入者',
        width: 160,
        code: 'userName',
        features: { sortable: true },
        lock: true,
        align: 'center',
        render: (value: any) => {
          return <div className={`${COL_START_CLASS}`}>{value}</div>;
        },
      },
      // renderProductName,
      renderArtistName,
      isMiimEcOrExternalEc ? renderTitleNameMiimEc : renderTitleName,
      renderVariationName,
      renderCardCd,
      renderDownloadDate,
      renderPrice,
      renderFeeName,
      renderTotalAmount,
      {
        name: '定額料金',
        align: 'center',
        width: 160,
        children: [
          {
            name: '単価',
            width: 100,
            code: 'optionCardBuyFee',
            align: 'center',
            render: (value: any) => {
              return <div className={`${COL_END_CLASS}`}>{formatCurrency(value)}</div>;
            },
          },
          renderBuyFeeMoney,
        ],
      },
      {
        name: '定率料金',
        align: 'center',
        width: 160,
        children: [renderUnitTaxFee, renderOptionTaxFee, renderTaxFeeMoney],
      },
      {
        name: 'オプション料金',
        align: 'center',
        width: 160,
        children: [renderSnFee, renderSnFeeMoney],
      },
      renderTotalMoney,
    ];

    if (filters) {
      if (filters.isViewProductId) {
        renderProductId.hidden = false;
      }
      if (!filters.isViewProductId) {
        renderProductId.hidden = true;
      }

      if (filters.isViewArtistName) {
        renderArtistName.hidden = false;
      }

      if (!filters.isViewArtistName) {
        renderArtistName.hidden = true;
      }

      if (filters.isViewTitleName) {
        renderTitleName.hidden = false;
        renderTitleNameMiimEc.hidden = false;
      }

      if (!filters.isViewTitleName) {
        renderTitleName.hidden = true;
        renderTitleNameMiimEc.hidden = true;
      }

      if (filters.isViewVariationName) {
        renderVariationName.hidden = false;
      }

      if (!filters.isViewVariationName) {
        renderVariationName.hidden = true;
      }

      if (filters.isViewVariationProductCode) {
        renderCardCd.hidden = false;
      }

      if (!filters.isViewVariationProductCode) {
        renderCardCd.hidden = true;
      }

      if (filters.isViewDownloadDate) {
        renderDownloadDate.hidden = false;
      }

      if (!filters.isViewDownloadDate) {
        renderDownloadDate.hidden = true;
      }
    }
    return [...initColumnDetail] as AliColumn[];
  }, [filters, activeColumns, isAllChecked, isMiimEcOrExternalEc, dataSource]);

  const renderColumn =
    option === 0 ? columnMonth : option === 1 ? columnDaily : option === 2 ? columnDetail : [];

  return (
    <Row className="mt-3">
      <AliTable
        wrapperClass="ali_table-revenue_management"
        dataSource={dataSource}
        columns={renderColumn}
        heightBody="calc(100vh - 450px)"
        onSort={onSort}
      />
    </Row>
  );
}
