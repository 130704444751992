import { DATE_FORMAT_PROCESSBOARD } from 'Constant/Date';
import dayjs from 'dayjs';
import React, { memo } from 'react';
import { Row } from 'react-bootstrap';

function Time(time: any): JSX.Element {
  const currentTime = dayjs(new Date(time?.time)).format(DATE_FORMAT_PROCESSBOARD);
  return (
    <Row>
      <div className="col-md-3 offset-md-9" style={{ textAlign: 'right' }}>
        {currentTime}
      </div>
    </Row>
  );
}

export default memo(Time);
