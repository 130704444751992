import { Button, Col, Row } from 'react-bootstrap';
import BRDatePicker from 'Components/Common/DatePicker/BRDatePicker';
import Filter from 'Components/Common/Filter/Filter';
import React from 'react';

export default function PaymentPrint(): JSX.Element {
  return (
    <div>
      <Filter filterClass="d-flex align-items-center justify-content-end shadow-sm">
        <Row>
          <Col md={12}>
            <div className="d-flex">
              <BRDatePicker dateClass="me-2 w-100" inputPlaceholder="2021年10月" />
              <Button variant="primary" className="w-100">
                PDFダウンロード
              </Button>
            </div>
          </Col>
        </Row>
      </Filter>
    </div>
  );
}
