import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import DateRangePicker from 'Components/Common/DateRangePicker/DateRangePicker';
import { labelRequire } from 'Components/Common/Form/FormLabel';
import { FormGroupDateRangeProps } from 'Components/Common/Form/FormGroupDateRange/form-group-date';

export default function FormGroupDateRange(props: FormGroupDateRangeProps): JSX.Element {
  const {
    label,
    subLabel,
    classForm,
    colClass,
    labelMd,
    colMd,
    placeholderStart,
    placeholderEnd,
    registerStartDate,
    registerEndDate,
    onChangeStartDate,
    onChangeEndDate,
    minDate,
    startDate,
    endDate,
    errorStartDate,
    errorEndDate,
    register,
    checkUpdateRange = false,
    showTimeSelect = false,
    endDateDisabled = false,
    startDateDisabled = false,
    dateFormat,
    maxStartDate,
    minStartDate,
    minEndDate,
    maxEndDate,
    maxTimeEndDate,
    minTimeEndDate,
  } = props;

  return (
    <Form.Group as={Row} className={`mb-3 ${classForm}`}>
      <Form.Label column md={labelMd}>
        {labelRequire(label ?? '')}
        {<p className="fs-12">{subLabel}</p>}
      </Form.Label>
      <Col md={colMd} className={colClass}>
        <DateRangePicker
          checkUpdateRange={checkUpdateRange}
          minDate={minDate}
          startDate={startDate}
          endDate={endDate}
          errorStartDate={errorStartDate}
          errorEndDate={errorEndDate}
          placeholderStart={placeholderStart}
          placeholderEnd={placeholderEnd}
          registerStartDate={registerStartDate}
          registerEndDate={registerEndDate}
          onChangeStartDate={onChangeStartDate}
          onChangeEndDate={onChangeEndDate}
          register={register}
          showTimeSelect={showTimeSelect}
          endDateDisabled={endDateDisabled}
          startDateDisabled={startDateDisabled}
          dateFormat={dateFormat}
          maxStartDate={maxStartDate}
          minStartDate={minStartDate}
          minEndDate={minEndDate}
          maxEndDate={maxEndDate}
          minTimeEndDate={minTimeEndDate}
          maxTimeEndDate={maxTimeEndDate}
        />
      </Col>
    </Form.Group>
  );
}
