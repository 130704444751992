import React from 'react';
import MessageError from 'Components/Common/MessageError';
import { Col, Form } from 'react-bootstrap';
import { ColSpec } from 'Components/Common/Form/form';
import BRTimePicker, {
  BRDatePickerTypes,
  BRDate,
} from 'Components/Common/NewDatePicker/TimePicker';

interface FormGroupDateProps extends Omit<BRDatePickerTypes, 'onChangeDate'> {
  label?: string;
  subLabel?: string;
  wrapperClass?: string;
  colClass?: string;
  labelMd?: ColSpec | undefined;
  onChange: (date: BRDate) => void;
  selected: BRDate;
  colDP?: ColSpec;
  subContent?: string;
  classCol?: string;
  errorMessage?: string;
  hintText?: string;
  showTime?: boolean;
  classLabel?: string;
  labelLg?: ColSpec | undefined;
  colMd?: ColSpec | undefined;
  colLg?: ColSpec | undefined;
  messageWrapper?: string;
  classNameDateSelect?: string;
}

export default function TimeSelector({
  label,
  labelMd,
  colMd,
  wrapperClass,
  onChange,
  errorMessage,
  selected,
  minDate,
  maxDate,
  disabled = false,
  placeholder,
  dateFormat,
  showTime = true,
  currentDate,
  classLabel,
  labelLg,
  colLg,
  messageWrapper,
  classNameDateSelect,
}: FormGroupDateProps): JSX.Element {
  return (
    <div className={`${wrapperClass ? wrapperClass : ''}`}>
      {label && (
        <Form.Label className={classLabel} column md={labelMd} lg={labelLg}>
          {label}
        </Form.Label>
      )}
      <Col md={colMd} lg={colLg} className={`position-relative ${classNameDateSelect}`}>
        <BRTimePicker
          dateFormat={dateFormat}
          timeFormat="HH:mm"
          placeholder={placeholder}
          currentDate={currentDate}
          selected={selected}
          onChangeDate={onChange}
          minDate={minDate ? minDate : new Date()}
          maxDate={maxDate}
          disabled={disabled}
          showTimeSelect={showTime}
          showIcon
        />
      </Col>
      <MessageError classWrapper={messageWrapper} message={errorMessage} />
    </div>
  );
}
