import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import BoxForm from 'Components/Common/Form/BoxForm';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import DropzoneDigitalPackage from 'Components/Page/Title/Variation/DropzoneDigitalPackage';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'App/Store';
import { setVariationImages } from 'App/Features/Title';
import { Image as ImageType } from 'Types/App/title';
import { calcMb, isAfterDownload } from 'Utils/File';
import { CaretDownSquareFill, CaretUpSquareFill } from 'react-bootstrap-icons';
import { TYPE_MOVE } from 'Constant/Title';
import { checklNumberRemainCapacity, handleMove, randomId } from 'Utils/Title';
import { TYPE_MEDIA } from 'Constant';
import { UploadMediaApi } from 'Datasource/UploadMedia';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import ModalComponent from 'Components/Common/Modal';

const BoxImage = ({
  variationIndex,
  listError = [],
}: {
  variationIndex: number;
  listError?: any;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { titleForm, triggerMedia } = useAppSelector((state) => state.title);
  const variation = titleForm.variations[variationIndex];
  const images: any = variation?.images ?? [];
  const [loading, setLoading] = useState<boolean>(false);
  const { openMessageError } = useMessage();

  const [deleteId, setDeleteId] = useState<null | number | string>(null);
  const imagesRef = useRef<any>([]);

  const uploadImage = async (image: Blob) => {
    try {
      setLoading(true);
      const formData: FormData = new FormData();
      formData.append('type', TYPE_MEDIA.VARIATIONS.toString());
      formData.append('file', image);
      const {
        data: { url },
      } = await UploadMediaApi.upLoadMedia(formData);

      return url;
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
    return '';
  };

  const onChangeFiles = async (files: File[]) => {
    if (!isEmpty(files)) {
      const image = { file: files[0] };
      if (checklNumberRemainCapacity(variation, image?.file.size)) return;
      const urlImage = await uploadImage(files[0]);

      const imgsTmp = [
        ...images,
        {
          introEc: '',
          name: '',
          size: image?.file.size,
          url: urlImage,
          type: 0,
          id: randomId(),
        },
      ];
      dispatch(setVariationImages({ images: imgsTmp, variationIndex: variationIndex }));
    }
  };

  const handleDeleteImage = () => {
    const imgsTmp = images.filter((image: ImageType) => image.id !== deleteId);

    dispatch(setVariationImages({ images: imgsTmp, variationIndex: variationIndex }));
    setDeleteId(null);
  };

  const handleMoveImage = (index: number, type: string) => {
    const newImages = handleMove(index, type, images) || images;
    dispatch(setVariationImages({ images: newImages, variationIndex: variationIndex }));
  };

  const handleChangeInput = (value: any, type: string, index: number) => {
    const imgsTmp = images.map((image: ImageType, imageIndex: number) =>
      imageIndex === index ? { ...image, [type]: value } : image,
    );
    dispatch(setVariationImages({ images: imgsTmp, variationIndex: variationIndex }));
  };

  useEffect(() => {
    if (triggerMedia === 'images' && listError.length > 0) {
      const newListError = listError
        .map((item: any, index: number) => ({ ...item, index: index }))
        .filter((item: any) => !!item);
      imagesRef?.current[newListError[0].index]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
  }, [listError, triggerMedia]);

  return (
    <BoxForm
      title="画像コンテンツ"
      classBox="box-form-no-shadow py-2"
      isGray={variationIndex % 2 ? true : false}
    >
      <div className={`${isAfterDownload(variation) && 'd-none'}`}>
        <DropzoneDigitalPackage
          acceptTypes="image/jpeg,image/png,image/gif"
          handleChange={onChangeFiles}
          messageError="画像タイプはjpeg, pngまたはfgifの画像を登録してください。"
          title="画像をアップロード"
        />
      </div>
      {images.map((item: ImageType, index: number) => {
        const preview = item?.url ? handleConnectStaticEndPoint(item.url) : '';

        return (
          <div className="border rounded mb-3" key={item.id}>
            <SpinnerComponent isLoading={loading} />
            <div className="video-item d-flex p-3" ref={(el) => (imagesRef.current[index] = el)}>
              <Row className="w-100">
                <Col md="3" className="ps-0">
                  <Image src={preview} className="rounded" />
                </Col>
                <Col md="9" className="pe-0">
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column>
                      <span>表示順</span>
                    </Form.Label>
                    <Col md={10}>
                      <span className="me-3">{index + 1}</span>
                      <CaretUpSquareFill
                        onClick={() => handleMoveImage(index, TYPE_MOVE[0])}
                        className="me-2"
                        size={25}
                      />
                      <CaretDownSquareFill
                        onClick={() => handleMoveImage(index, TYPE_MOVE[1])}
                        size={25}
                      />
                      <span className="ms-3">{`${calcMb(item?.size || 0)}MB`}</span>
                    </Col>
                  </Form.Group>
                  <FormGroupInput
                    labelMd="2"
                    colMd="10"
                    label="タイトル名"
                    value={item.name}
                    onChange={(value) => handleChangeInput(value, 'name', index)}
                    errorMessage={listError[index]?.name?.message}
                  />
                  <FormGroupInput
                    labelMd="2"
                    colMd="10"
                    label="EC説明⽂"
                    value={item.introEc}
                    onChange={(value) => handleChangeInput(value, 'introEc', index)}
                    errorMessage={listError[index]?.introEc?.message}
                  />
                </Col>
              </Row>
            </div>
            <hr className="border-top border-dark" />
            <div className="text-end mb-3 me-3">
              <Button
                hidden={isAfterDownload(variation)}
                variant="secondary"
                onClick={() => setDeleteId(item.id)}
              >
                この画像を削除する
              </Button>
            </div>
            <ModalComponent
              show={deleteId !== null}
              onCloseModal={() => setDeleteId(null)}
              classNameContent="d-flex align-items-center content-body-modal justify-content-center"
              variantButtonSubmit="danger"
              onSubmitModal={handleDeleteImage}
              title="削除確認"
              confirmText="削除する"
              cancelText="キャンセル"
            >
              <div>
                <p className="mb-2">画像を削除します。よろしいですか。</p>
              </div>
            </ModalComponent>
          </div>
        );
      })}
    </BoxForm>
  );
};

export default BoxImage;
