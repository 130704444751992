import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
// import { useLocation } from 'react-router';
import SearchDatePicker from './DatePicker';
import { SearchDateProps } from 'Components/Common/Search/ISearch';
import { DATE_FORMAT_1, DATE_FORMAT_FOR_DATE_PICKER_2 } from 'Constant/Date';
import dayjs from 'dayjs';

export default function DateSearch({
  selected,
  label,
  placeholder,
  rowClass,
  classLabel,
  colClass,
  labelMd,
  labelLg,
  colMd,
  colLg,
  searchKey,
  minDate = undefined,
  maxDate,
  onMenuClose,
  onSelectDate,
  disabled,
}: SearchDateProps): JSX.Element {
  //   const callbackRef = useRef<{ handleSetQuery: (query: string) => void }>(null);

  //   const location = useLocation();
  //   const urlParams = new URLSearchParams(location.search);
  //   const queryParams = Object.fromEntries(urlParams);
  const [isShow, setIsShow] = useState(false);

  const handleSelectDate = (date: any, searchKey: string) => {
    const formattedDate = date ? dayjs(date).format(DATE_FORMAT_1) : null;
    onSelectDate(formattedDate, searchKey);
    handleReset();
  };

  const handleReset = () => {
    setIsShow(false);
  };

  useEffect(() => {
    if (!isShow && onMenuClose) onMenuClose();
  }, [isShow, onMenuClose]);

  return (
    <>
      <Form.Group
        as={Row}
        className={rowClass}
        onBlur={() => {
          // handleOnBlur();
          handleReset();
        }}
        onFocus={() => {
          setIsShow(true);
        }}
        key={searchKey}
      >
        {label && (
          <Form.Label className={classLabel} column md={labelMd} lg={labelLg}>
            {label}
          </Form.Label>
        )}
        <Col
          md={colMd}
          lg={colLg}
          className={`${colClass} ${disabled && 'pe-none'} position-relative`}
        >
          <SearchDatePicker
            dateFormat={DATE_FORMAT_FOR_DATE_PICKER_2}
            disabled={disabled}
            selected={selected}
            placeholder={placeholder}
            onChangeDate={(date) => handleSelectDate(date, searchKey)}
            minDate={minDate}
            maxDate={maxDate}
          />
        </Col>
      </Form.Group>
    </>
  );
}
