import { ReactNode } from 'react';

export interface Router {
  authed?: boolean;
  exact?: boolean;
  strict?: boolean;
  path: string;
  component: ReactNode;
  roles?: string[];
}

export enum AppRouter {
  //* Root
  DASH_BOARD = '/',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  CHANGE_PASSWORD = '/change-password',
  LOGIN = '/login',
  //* RECORD

  PROGRESS_BOARD = '/progressboard',
  TITLE_LIST = '/title/list',
  TITLE_REGISTER = '/title/register',
  TITLE_DETAIL = '/title-detail',
  JMD_AUDIO = '/title/jmd-audio',
  JMD_VIDEO = '/title/jmd-video',
  CAMPAIGN_LIST = '/campaign-list',
  SERIAL_NUMBER = '/serial-numbers',
  CREATE_SERIAL_NUMBER = '/create-serial-number',
  CD_MASTERS = '/cd-master',
  CREATE_CD = '/create-cd-master',
  LIST_PRODUCT = '/list-products',
  CREATE_PRODUCT = '/create-products',
  PRODUCT_DETAILS = '/products-detail',
  LIST_SALE_PRODUCT = '/list-sale-products',
  REVENUE_MANAGER = '/revenue-management',
  JARSAC = '/jarsac',
  ARTIST = '/artist',
  ARTIST_DETAIL = '/artist/detail-artist',
  ARTIST_REGISTER = '/artist/register-artist',
  CREATE_COMPANY_INFO = '/create-company-info',
  SESSION_MANAGER = '/session-manage',
  CURATOR_MANAGER = '/curator-management',
  COST_INFORMATION = '/cost-information',
  OPERATING_COMPANY_INFO = '/operating-company-info',
  REGISTER_SESSION = '/company/register-section',
  STATISTIC_CAMPAIGN = '/statistic-campaign',
  REGISTER_CAMPAIGN = '/register-campaign',
  CAMPAIGN_DETAIL = '/campaigns',
  DIGITAL_TOKUTEN = '/digital-tokuten',
  SALE_PRODUCT_DETAIL = '/sale-product-detail',
  //* WEB APP
  WEB_APP_SEARCH = '/search',
  WEB_APP_USING_STATUS = '/using-status',
  //* MIIM EC

  PURCHASE_LIST = '/list-purchase',
  PURCHASE_SUMMARY_LIST = '/purchase-summary-list',
  PURCHASE_RATE_SETTING = '/purchasing-rate-setting',
  SETTING_SITE = '/setting-site',
  EMAIL_MESSAGE_MANAGER = '/email-message-management',
  PAYMENT_ADVICE_LIST = '/payment-advice-list',
  PAYMENT_ADVICE_DETAIL = '/payment-advice-detail',
  PURCHASE_DETAIL = '/purchase-detail',
  SUMMARY_PURCHASE_DETAIL = '/purchase-summary-detail',
  SHOP_MANAGEMENT = '/shop-management',
}
