interface CheckboxOption {
  label: string;
  checked: boolean;
  value: number;
  subLabel?: string;
  disabled?: boolean;
}

enum CampaignSteps {
  Overview = 0,
  CourseAndMethod = 1,
  SignUpRewards = 2,
  WinningConditions = 3,
  Confirm = 4,
}

enum TargetProduct {
  DP = 0,
  CD = 1,
}

enum CDStatus {
  NotUsedYet = 0,
  Used = 1,
}

enum CampaignStatus {
  Draft = 0,
  NoDigital = 1,
  Official = 2,
}

enum CAMPAIGN_MEDIA_TYPE {
  Image = 0,
  Video = 1,
  Audio = 2,
}

const distributionSettingOptions: CheckboxOption[] = [
  {
    label: '1商品1応募権利',
    subLabel: '1商品で1回の応募権利が付与されます。',
    checked: false,
    value: 0,
  },
  {
    label: '金額応募',
    subLabel: '購入金額を応募権利に換算し応募します。',
    checked: false,
    value: 1,
  },
  {
    label: '組合せ応募',
    subLabel: '指定した商品の組み合わせを応募権利に換算し応募します。',
    checked: false,
    value: 2,
  },
];

const methodOptions: CheckboxOption[] = [
  {
    label: '全員配布',
    subLabel: '応募したユーザー全てに景品を提供します。',
    checked: false,
    value: 0,
  },
  {
    label: '抽選会',
    subLabel:
      '購入後ユーザーがキャンペーンに応募し、定められた抽選日に抽選処理を行います。\nキャンペーンの応募条件を設定することが可能です。',
    checked: false,
    value: 1,
  },
  {
    label: 'スピード抽選',
    subLabel:
      '購入時に抽選処理を実行します。\nユーザーは購入時に抽選結果を確認することができます。',
    checked: false,
    value: 2,
  },
];

const awardSettings: CheckboxOption[] = [
  {
    label: '応募時に賞を指定しない',
    subLabel:
      'ユーザーがこのコースに応募する場合は、\nコースのみを指定して応募します。\n賞の選定は抽選処理にて確定します。',
    checked: false,
    value: 0,
    disabled: false,
  },
  {
    label: '応募時に賞を指定する',
    subLabel: 'ユーザーがこのコースに応募する場合は、\nコースと景品を指定し応募します。',
    checked: false,
    value: 1,
    disabled: false,
  },
];

const luckyDrawOption: CheckboxOption[] = [
  {
    label: 'デフォルト',
    checked: true,
    value: 0,
    disabled: false,
  },
  {
    label: 'オリジナル',
    checked: false,
    value: 1,
    disabled: false,
  },
];

const campaignOptionsType: CheckboxOption[] = [
  {
    label: '入場券',
    checked: false,
    value: 0,
  },
  {
    label: '特典券',
    checked: false,
    value: 1,
  },
  {
    label: 'シリアルコード',
    checked: false,
    value: 2,
  },
  {
    label: 'デジタル特典',
    checked: false,
    value: 3,
  },
  {
    label: 'グッズ',
    checked: false,
    value: 4,
  },
];

const COLOR_DEAFAULT = [
  '#E9967A',
  '#800080',
  '#DB7093',
  '#C0FF80',
  '#20B2AA',
  '#F4AF60',
  '#FFA500',
  '#4682B4',
  '#FAF0E6',
  '#FFE4E1',
];

const NotificationOptions = [
  {
    label: '通知なし',
    checked: false,
    value: 0,
  },
  {
    label: '通知時刻設定',
    checked: false,
    value: 1,
  },
];

const AnnouncementTimeOptions = [
  {
    label: '通知なし',
    checked: false,
    value: 0,
  },
  {
    label: '通知時刻設定',
    checked: false,
    value: 1,
  },
];

const distributionOptions = [
  {
    label: '全コンテンツ付与',
    subLabel: '登録されている全てのデジタルコンテンツを\n該当ユーザーへ付与します。',
    checked: false,
    value: 0,
  },
  {
    label: '抽選により1点のみを付与',
    subLabel:
      '設定された当選係数により抽選を行い、登録されているデジタルコンテンツのいずれか\n１点を該当ユーザーへ付与します。',
    checked: false,
    value: 1,
  },
];

const attachedBonus = [
  {
    label: '自動付与',
    subLabel:
      'ユーザーがDPを購入した時点で対象の景品が付与されます。\nキャンペーンのコース及び景品は１つのみ登録可能です。',
    checked: false,
    value: 0,
  },
  {
    label: '応募付与',
    subLabel:
      'ユーザーがDPを購入し、任意のコース及び賞に応募し景品を付与します。\nキャンペーンのコース及び賞は複数登録可能です。',
    checked: false,
    value: 1,
  },
];

const NOTE_CAMPAIGN_TYPE = [
  'ライブやイベントの入場券を景品として設定できます。\n当選したユーザーは、miimアプリで入場券を提示しもぎりを行うことができます。\n複数回購入または、当選した場合でも入場券の付与は１ユーザー１枚となります。',
  'イベント会場で利用できる特典券を景品として設定できます。\n特典券との引き換えサービスはイベント経営者が企画したものとなります。',
  '外部主催のイベント参加用のシリアルナンバーを配布することができます。',
];

const CAMPAIGN_INITIAL_STATE = {
  step: 0,
  oldTitleId: null,
  mailRecipientList: [],
  overview: {
    artistId: '',
    artistName: '',
    titleId: '',
    titleName: '',
    name: '',
    startDate: null,
    endDate: null,
    description: '',
    size: 0,
    isDeletable: true,
    earliestSaleDate: null,
    isAuthor: 1,
  },
  courses: [],
  disabledNextStep: false,
  triggerCaculateRate: false,
  currentCourseIndex: 0,
  errorRealTime: {
    exceed100: false,
    prizeQuantityTooSmall: false,
    max100: false,
    courseName: '',
  },
};

const FIELD_SIZE = {
  label: 2,
  value: 10,
};

const CourseTypes = {
  ALL: 0,
  STANDARD: 1,
  REALTIME: 2,
};
const bonusPopupInitialState = {
  name: '',
  type: 0,
  userQuantity: null,
  isLimited: true,
  isIncentive: false,
  winRate: null,
  type1: {
    eventName: '',
    placeName: '',
    description: '',
    startDate: null,
    endDate: null,
    openTime: null,
    startTime: null,
    seatRow: null,
    seatNumber: null,
    seatPickerType: 0,
    isSendNoti: 0,
    notificationDate: null,
  },

  type2: {
    startDate: null,
    endDate: null,
    productQuantity: null,
    placeName: '',
    eventName: '',
    description: '',
    isSendNoti: 0,
    notificationDate: null,
  },

  type3: {
    eventName: '',
    productQuantity: null,
    description: '',
    url: '',
    seriesNumberMasterId: null,
    seriesNumberMasterName: '',
    seriesNumberMasterTotal: null,
    startDate: null,
    endDate: null,
  },

  type4: {
    digitalTokutenDistributionDate: null,
    digitalTokutenPrizeType: 1,
    prizeDigitalTokutens: [],
  },
  type5: {
    eventName: '',
    imageUrl: '',
  },
};

const back = new Date(new Date().setMonth(new Date().getMonth() - 1));
const now = new Date();
const next = new Date(new Date().setMonth(new Date().getMonth() + 1));
const defaultDates = [back, now, next];

const sitOptions = [
  {
    label: 'なし',
    labelConfirm: '席順なし',
    value: 0,
  },
  {
    label: '先着順',
    labelConfirm: '席順あり',
    value: 1,
  },
  {
    label: 'ランダム発行',
    labelConfirm: '席順あり',
    value: 2,
  },
];

const initialCourse = {
  id: undefined,
  name: '',
  option: 0,
  courseInfos: [],
  type: 0,
  receiveType: 0,
  standardTime: null,
  timeJoinStart: null,
  timeJoinEnd: null,
  countJoinStart: null,
  countJoinEnd: null,
  prizeOption: 0,
  ticketPrice: null,
  prizes: [],
  subType: 0,
  prizeQuantity: null,
};

const defaultConditions = {
  enabled: true,
  option1: false,
  option2: false,
  option3: false,
  option4: false,
};

const statisticTab = {
  CAMPAIGN_DETAIL: '登録内容詳細',
  APPLY_STATUS: '応募状況',
  WINNING_RESULT: '当選結果',
};

const methodOptionTabs = [
  {
    name: '全員配布',
    value: CourseTypes.ALL,
    description:
      '応募したユーザー全てに景品を提供します。\n付与するタイミングを以下から選択可能です。',
  },
  {
    name: '抽選会',
    value: CourseTypes.STANDARD,
    description:
      '購入後ユーザーがキャンペーンに応募し、定められた抽選日に抽選処理を行います。\nキャンペーンの応募条件を設定することが可能です。',
  },
  {
    name: 'スピード抽選',
    value: CourseTypes.REALTIME,
    description:
      '購入時に抽選処理を実行します。\nユーザーは購入時に抽選結果を確認することができます。',
  },
];

const subTypeOptions = [
  {
    label: '販売数の上限を設定する',
    subLabel:
      '販売数を設定し販売数内ですべての景品を当選させます。\n設定した販売数に達した時点で販売を停止します。\n当選確率は「景品数 / 販売数」で自動的に設定されます。',
    checked: true,
    value: 0,
  },
  {
    label: '任意の当選確率で抽選を行う',
    subLabel: '任意の当選確率を設定することが可能です。',
    checked: false,
    value: 1,
  },
  {
    label: '自動変動確率',
    subLabel: '初日と最終日の予想応募者数を設定し\n日々の当選確率を自動的に調整します。',
    checked: false,
    value: 2,
  },
];

const COLOR_PIE_CHART = [
  'rgb(255,75,0)',
  'rgb(255,241,0)',
  'rgb(3,175,122)',
  'rgb(0,90,255)',
  'rgb(77,196,255)',
  'rgb(255,128,130)',
  'rgb(246,170,0)',
  'rgb(153,0,153)',
  'rgb(128,64,0)',
];

export {
  awardSettings,
  methodOptions,
  distributionSettingOptions,
  COLOR_DEAFAULT,
  CampaignSteps,
  campaignOptionsType,
  NotificationOptions,
  AnnouncementTimeOptions,
  distributionOptions,
  NOTE_CAMPAIGN_TYPE,
  luckyDrawOption,
  CAMPAIGN_INITIAL_STATE,
  FIELD_SIZE,
  CourseTypes,
  defaultDates,
  attachedBonus,
  CDStatus,
  TargetProduct,
  bonusPopupInitialState,
  sitOptions,
  initialCourse,
  defaultConditions,
  CampaignStatus,
  CAMPAIGN_MEDIA_TYPE,
  statisticTab,
  methodOptionTabs,
  subTypeOptions,
  COLOR_PIE_CHART,
};
export type { CheckboxOption };
