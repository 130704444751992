import DateSelector from 'Components/Common/DateSelector';
import FormSelectOption from 'Components/Common/Form/FormSelectOption';
import SearchCommon from 'Components/Common/Search/SearchCommon';
import dayjs from 'dayjs';
import useSuggestion from 'Hooks/useSuggestion';
import React, { useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { IDatePicker } from 'Types/Common';
import { BooleanParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

interface searchProcessBoard {
  onExportExcel?: () => void;
  loadingExcel?: boolean;
}

function SearchProcessBoard({ onExportExcel, loadingExcel }: searchProcessBoard): JSX.Element {
  const [query, setQuery] = useQueryParams({
    fromDate: withDefault(StringParam, '', true),
    toDate: withDefault(StringParam, '', true),
    currentTime: StringParam,
    reload: BooleanParam,
    // checkReload: BooleanParam,
    titleStatus: StringParam,
  });

  const items = [
    {
      value: '',
      label: 'すべて',
    },
    {
      value: '0',
      label: '一時保存',
    },
    {
      value: '1',
      label: '登録済',
    },
  ];
  const [variationTemporary, setVariationTemporary] = useState('');

  const onChangeDate = (date: any, type: string) => {
    if (!date) {
      setQuery({
        [type]: '',
      });
      return;
    }
    setQuery({
      [type]: dayjs(date),
    });
  };

  const handleSearch = () => {
    setQuery({
      reload: !query.reload,
      // checkReload: true,
    });
  };

  const {
    artistOptions,
    titleOptions,
    loadingArtist,
    loadingTitle,
    handleLoadMore,
    handleFocusInput,
    handleEnterPrimary,
    handleClickItemPrimary,
  } = useSuggestion({
    fetchList: ['artist', 'title'],
    showDraftVariation: true,
  });

  return (
    <>
      <Row className="group-item-content">
        <Col md={6} className="px-0 filter-content">
          <Row>
            <Col md="4" className="padding-left-0">
              <SearchCommon
                label="アーティスト"
                labelMd="12"
                colMd="12"
                placeholder="検索"
                showIcon
                classLabel="px-0"
                rowClass="w-100"
                colClass="px-0"
                searchKey="queryArtist"
                onEnter={() => handleEnterPrimary('queryArtist')}
                onClickResult={handleClickItemPrimary}
                options={artistOptions.options || []}
                loading={loadingArtist}
                onLoadMore={() => handleLoadMore('pageArtist')}
                onFocusInput={() => {
                  handleFocusInput('queryArtist', 'pageArtist');
                }}
              />
            </Col>
            <Col md="4">
              <SearchCommon
                label="タイトル"
                labelMd="12"
                colMd="12"
                placeholder="検索"
                showIcon
                classLabel="px-0"
                rowClass="w-100"
                colClass="px-0"
                searchKey="queryTitle"
                onEnter={() => handleEnterPrimary('queryTitle')}
                onClickResult={handleClickItemPrimary}
                options={titleOptions.options || []}
                loading={loadingTitle}
                onLoadMore={() => handleLoadMore('pageTitle')}
                onFocusInput={() => {
                  handleFocusInput('queryTitle', 'pageTitle');
                }}
              />
            </Col>
            <Col md="4">
              <Col md={12}>
                <Form.Label column md={{ span: 10, offset: 1 }}>
                  登録ステータス
                </Form.Label>
              </Col>
              <Col md={12}>
                <FormSelectOption
                  defaultValue={variationTemporary}
                  inputSearchString={items[0]?.label}
                  placeholder="検索"
                  colMd="12"
                  options={items}
                  onSelect={(item: any) => setQuery({ titleStatus: item?.value })}
                  rowClass="mb-3"
                  onInputChange={(keyword: string) => setVariationTemporary(keyword)}
                  onMenuFocus={() => setQuery({ titleStatus: '' })}
                  showIconClear
                />
              </Col>
            </Col>
          </Row>
        </Col>
        <Col md={3} className="px-0 date-content">
          <Col md="12" className="padding-left-0">
            <Form.Label column md="12">
              検索期間
            </Form.Label>
            <Row>
              <Col md="6" className="p-0">
                <DateSelector
                  placeholder="開始日"
                  dateFormat="yyyy/MM/dd"
                  showTime={false}
                  maxDate={query.toDate ? new Date(query.toDate) : null}
                  onChange={(fromDate: IDatePicker) => onChangeDate(fromDate, 'fromDate')}
                  selected={query.fromDate ? new Date(query.fromDate) : null}
                  classNameDateSelect="w-85"
                />
              </Col>
              <Col md="6" className="p-0">
                <DateSelector
                  placeholder="終了日"
                  dateFormat="yyyy/MM/dd"
                  showTime={false}
                  minDate={query.fromDate ? new Date(query.fromDate) : null}
                  onChange={(toDate: IDatePicker) => onChangeDate(toDate, 'toDate')}
                  selected={query.toDate ? new Date(query.toDate) : null}
                  classNameDateSelect="w-85"
                />
              </Col>
            </Row>
          </Col>
        </Col>
        <Col
          md={3}
          className="px-0 d-flex justify-content-btn-group align-items-center btn-content mt-3"
        >
          <Col md="3" className="px-0 btn-search-col">
            <Button className="btn-focus-none btn-submit btn-search-pb px-4" onClick={handleSearch}>
              検索
            </Button>
          </Col>
          <Col md="9" className="px-0 btn-export-col">
            <Button
              className={`btn-focus-none prize-btn-excel btn-submit btn-excel-pb px-4 ${
                loadingExcel ? 'pe-none' : ''
              }`}
              onClick={onExportExcel}
            >
              {loadingExcel ? (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Excelダウンロード'
              )}
            </Button>
          </Col>
        </Col>
      </Row>
    </>
  );
}

export default SearchProcessBoard;
