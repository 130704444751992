import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { filter, findLastIndex, indexOf, isEmpty, pullAt, reduce, toNumber } from 'lodash';
import { CAMPAIGN_INITIAL_STATE, CourseTypes, subTypeOptions } from 'Constant/Campaign';
import { arrayMoveImmutable } from 'array-move';
import { Prize } from 'Types/Page/Campaign/RegisterCampaign';

export enum CampaignSteps {
  Overview = 0,
  CourseAndMethod = 1,
  SignUpRewards = 2,
  WinningConditions = 3,
  Confirm = 4,
  Success = 5,
}

const initialState: any = { ...CAMPAIGN_INITIAL_STATE };

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    changeStep: (state, data: PayloadAction<{ step: CampaignSteps }>) => {
      const { step } = data.payload;
      state.step = step;
    },

    updateCampaign: (state, data: PayloadAction<any>) => {
      const { overview, courses } = data.payload;
      state.overview = overview;
      state.courses = courses;
    },

    updateStep1State: (state, data: PayloadAction<any>) => {
      const { overview } = data.payload;
      state.overview = overview;
    },

    updateStep2State: (state, data: PayloadAction<any>) => {
      const { courses } = data.payload;
      state.courses = courses;
    },

    updatePrizes: (state, data: PayloadAction<any>) => {
      const { prize, courseIndex } = data.payload;
      const courses = [...state.courses].map((course: any, index: number) => {
        //* course no exist(Initialization)
        if (courseIndex === index && prize.index === undefined) {
          let prizes = [...current(course.prizes), { ...prize }];
          const arrPrizeUnlimited = filter([...prizes, { ...prize }], (prize: any) => {
            return !prize.userQuantity;
          });
          if (arrPrizeUnlimited.length === 1) {
            if (state.courses[courseIndex].type !== CourseTypes.REALTIME) {
              const prizeIndex = indexOf(current(course.prizes), arrPrizeUnlimited[0]);
              prizes = arrayMoveImmutable(prizes, prizeIndex, prizes.length - 1).map(
                (prize: any, index: number) => {
                  return { ...prize, index };
                },
              );
            } else {
              prizes = prizes.map((prize: any, index: number) => {
                return { ...prize, index };
              });
            }
          }
          return {
            ...course,
            prizes: [...prizes],
            blackListUsers: course?.blackListUsers ?? [],
          };
        }
        //* course exist(Update)
        if (courseIndex === index && prize.index !== undefined) {
          const prizes = course?.prizes.map((cPrize: any, prizeIndex: number) => {
            return prizeIndex === prize.index ? prize : cPrize;
          });
          return { ...course, prizes };
        }
        return course;
      });
      state.courses = courses;
    },

    replacePrizes: (state, data: PayloadAction<any>) => {
      const { prizes, courseIndex } = data.payload;
      const courses = state.courses.map((course: any, index: number) => {
        return courseIndex === index ? { ...course, prizes } : { ...course };
      });
      state.courses = courses;
    },

    deletePrize: (state, data: PayloadAction<any>) => {
      const { prize, courseIndex } = data.payload;
      const prizeSize: number = reduce(
        prize.prizeDigitalTokutens,
        (sum, field) => {
          return sum + Number(field.size ?? 0);
        },
        0,
      );
      if (Number(prize?.type) === 3) {
        state.overview.dataSize = Number(state?.overview?.dataSize) - Number(prizeSize);
      }
      const courses = state.courses.map((course: any, index: number) => {
        if (courseIndex === index && prize.index !== undefined) {
          pullAt(course.prizes, [prize.index]);
          const lastIndex = findLastIndex(course.prizes, function (el: Prize) {
            return !el.userQuantity;
          });
          const newPrizes = course.prizes.map((item: Prize, pIndex: number) => {
            return {
              ...item,
              isIncentive: pIndex === lastIndex && course.subType !== 1 ? true : item.isIncentive,
              winRate: item.isIncentive
                ? toNumber(item.winRate) + toNumber(prize.winRate)
                : item.winRate,
            };
          });
          const totalWinRate = newPrizes.reduce((total: number, currentValue: Prize) => {
            const currentWinRate = currentValue.winRate ? toNumber(currentValue.winRate) : 0;
            return total + currentWinRate;
          }, 0);
          const slipRateRealtime = 100 - totalWinRate > 0 ? 100 - totalWinRate : 0;
          return {
            ...course,
            prizes: newPrizes,
            slipRate:
              toNumber(course.subType) === subTypeOptions[1].value
                ? slipRateRealtime
                : course.slipRate,
          };
        }

        return course;
      });
      state.courses = courses;
    },

    updateBlacklists: (state, data: PayloadAction<any>) => {
      const { userInfo, courseIndex } = data.payload;
      const courses = state.courses.map((course: any, index: number) => {
        if (courseIndex === index) {
          const blackListUsers = course?.blackListUsers ?? [];
          return {
            ...course,
            blackListUsers: [
              ...blackListUsers,
              {
                userId: userInfo.userId,
                userName: userInfo.userName,
                email: userInfo.email,
                userMiim: userInfo.userMiim,
                address: userInfo.address,
                realName: userInfo.realName,
              },
            ],
          };
        }
        return course;
      });
      state.courses = courses;
    },

    deleteBlacklist: (state, data: PayloadAction<any>) => {
      const { userId, courseIndex } = data.payload;
      const courses = state.courses.map((course: any, index: number) => {
        if (courseIndex === index) {
          const blackListUsers = filter(course.blackListUsers, (user) => user.userId !== userId);
          return { ...course, blackListUsers };
        }
        return course;
      });
      state.courses = courses;
    },

    setOldTitleId: (state, data: PayloadAction<any>) => {
      const { id } = data.payload;
      state.oldTitleId = id;
    },

    setDataSize: (state, data: PayloadAction<any>) => {
      const { dataSize } = data.payload;
      state.overview.dataSize = dataSize;
    },

    sortPrizes: (state, data: PayloadAction<{ courseIndex: number }>) => {
      const { courseIndex } = data.payload;

      const prizes = state.courses[courseIndex].prizes || [];
      const prizeSorted = () => {
        let tmpPrize: Prize[] = [];
        if (prizes.length) {
          if (state.courses[courseIndex].type !== CourseTypes.REALTIME) {
            const prizeLimited = prizes.filter((p: any) => !!p.userQuantity);
            const prizeUnlimited = prizes.filter((p: any) => !p.userQuantity);
            tmpPrize = [...prizeLimited, ...prizeUnlimited].map((item, i) => ({
              ...item,
              index: i,
            }));
          } else {
            const prizeNotIncentive = prizes.filter((p: Prize) => !p.isIncentive);
            const prizeIncentive = prizes.filter((p: Prize) => p.isIncentive);
            tmpPrize = [...prizeNotIncentive, ...prizeIncentive].map((item, i) => ({
              ...item,
              index: i,
            }));
          }
        }
        return tmpPrize;
      };

      if (prizes.length) {
        if (
          state.courses[courseIndex].type === CourseTypes.REALTIME &&
          state.courses[courseIndex].subType !== 2
        ) {
          const totalWinRate = prizeSorted().reduce((total, currentValue: Prize) => {
            return total + (currentValue?.winRate ? toNumber(currentValue?.winRate) : 0);
          }, 0);

          if (totalWinRate <= 0) {
            state.errorRealTime.max100 = true;
            state.errorRealTime.courseName = state.courses[courseIndex].name;
          }
        }
      }
      state.courses[courseIndex].prizes = prizeSorted();
    },

    setDisabledNextStep: (state, data: PayloadAction<boolean>) => {
      const { payload } = data;
      state.disabledNextStep = payload;
    },

    setErrorRealTime: (
      state,
      data: PayloadAction<{
        key?: string;
        status?: boolean;
        keys?: string[];
        statuses?: boolean[];
        courseName?: string;
      }>,
    ) => {
      const { keys, statuses, courseName } = data.payload;
      const keyList = isEmpty(keys) ? [] : (keys as string[]);
      const statusList = isEmpty(statuses) ? [] : (statuses as boolean[]);
      if (!isEmpty(keyList) && !isEmpty(statusList)) {
        keyList.forEach((item: string, index: number) => {
          state.errorRealTime[item] = statusList[index];
          state.errorRealTime.courseName = courseName;
        });
      }
    },

    setTriggerCaculateRate: (
      state,
      data: PayloadAction<{ status: boolean; courseIndex: number }>,
    ) => {
      const { status, courseIndex } = data.payload;
      state.triggerCaculateRate = status;
      state.currentCourseIndex = courseIndex;
    },

    updateCourse: (state, data: PayloadAction<any>) => {
      const { course, courseIndex } = data.payload;

      const prizeSorted = () => {
        const prizes = course.prizes ? course.prizes : [];
        let tmpPrize: Prize[] = [];
        if (prizes.length > 0) {
          if (state.courses[courseIndex].type !== CourseTypes.REALTIME) {
            const prizeLimited = prizes.filter((p: any) => !!p.userQuantity);
            const prizeUnlimited = prizes.filter((p: any) => !p.userQuantity);
            tmpPrize = [...prizeLimited, ...prizeUnlimited].map((item, i) => ({
              ...item,
              index: i,
            }));
          } else {
            const prizeNotIncentive = prizes.filter((p: Prize) => !p.isIncentive);
            const prizeIncentive = prizes.filter((p: Prize) => p.isIncentive);
            tmpPrize = [...prizeNotIncentive, ...prizeIncentive].map((item, i) => ({
              ...item,
              index: i,
            }));
          }
        }
        return tmpPrize;
      };

      state.courses[courseIndex] = { ...course, prizes: prizeSorted() };
    },

    resetData: (state) => {
      state = { ...CAMPAIGN_INITIAL_STATE };
      return state;
    },
  },
});

export const {
  changeStep,
  updateStep1State,
  updateStep2State,
  updatePrizes,
  deletePrize,
  updateBlacklists,
  deleteBlacklist,
  resetData,
  updateCampaign,
  setOldTitleId,
  setDataSize,
  replacePrizes,
  sortPrizes,
  setDisabledNextStep,
  setTriggerCaculateRate,
  setErrorRealTime,
  updateCourse,
} = campaignSlice.actions;
export default campaignSlice.reducer;
