export type Step = number;

export interface Title {
  [key: string]: any;
  artistId: number | null;
  name: string | null;
  format: number | null;
  label: string | null;
  musicGenreId: number | null;
  isAuthor?: string | number | undefined;
  status?: number | null;
  musicGenreName?: string;
  variations: Variation[];
  sellIap?: number;
}

export type Variation = {
  index?: number | null;
  id?: number | null;
  type?: number | string;
  musicFormat?: number | null;
  isDisplayName?: number;
  name?: string | null;
  productCode?: string | null;
  saleTokenDate?: string | null;
  downloadDate?: string | null;
  janCode?: string | null;
  price?: number | null;
  coverImageUrl?: string | null;
  errorText?: string | null | undefined;
  songs?: Song[];
  videos: Video[];
  images: Image[];
  capacityUsed?: number;
  capacity?: number;
  booklets?: any;
  option?: any;
  isUsedInCampaign?: boolean;
  includeJasracContent?: number | null;
  notiDownloadTime?: string | null;
  jmdCooperation?: number | null;
};

export type Song = {
  index: number;
  name?: string;
  furiganaName?: string;
  englishName?: string;
  musicianName?: string;
  musicianFuriganaName?: string | null;
  musicianEnglishName?: string;
  composerName?: string;
  composerFuriganaName?: string | null;
  composerEnglishName?: string;
  arrangerName?: string;
  arrangerFuriganaName?: string | null;
  arrangerEnglishName?: string;
  showTime?: string;
  originalType?: number;
  IVTType?: number;
  liveType?: number;
  authoziredGroup?: string;
  authoziredType?: number;
  ISRCCode?: string;
  contentType?: number;
  collectCode?: number;
  JASRACCode?: string;
  otherCode?: string;
  informationFee?: number;
  lyricsClassify?: number;
  sourceAudioUrl?: string;
  size?: number;
  fileName?: string;
  lyrics?: string;
  lyricInformationFee?: number;
  authorizedGroupType?: number;
  id?: string | number;
  riajGenreId?: number | null;
};

export type Video = {
  name?: string;
  introEc?: string;
  songs?: Song[];
  file?: File;
  url?: string;
  size?: number;
  includeCopyRightSong?: number | null;
  videoType?: number | null;
  type?: number | null;
  mediaInformationFee?: string | number | null;
  id: number | string;
};

export type Image = {
  name?: string;
  introEc?: string;
  url?: string;
  file?: File;
  size?: number;
  id: number | string;
};

export enum CapacityTypes {
  Image = 'Image',
  Video = 'Video',
  Song = 'Song',
  Thumbnail = 'Thumbnail',
  Booklet = 'Booklet',
}

export type Capacity = {
  used: number;
  type: CapacityTypes;
  index: number | string;
};

export interface VariationFieldObj {
  name: string;
  index: number;
}
export interface SongFeePayload {
  songName: string;
  lyric?: string | null;
  authorizedGroupType?: string | number;
  index: number;
}

export interface VideoFeePayload {
  type: string;
  videoName: string;
  songs: SongFeePayload[];
  index: number;
}
export interface InformationFeePayload {
  index: number;
  isNormalVersion: boolean;
  price?: number | null;
  songs: SongFeePayload[];
  videos: VideoFeePayload[];
}
