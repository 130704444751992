// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ColSpec } from 'Components/Common/Form/form';
import { labelRequire } from 'Components/Common/Form/FormLabel';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
// import { HexColorPicker } from 'react-colorful';
import { SketchPicker } from 'react-color';

export default function ColorPickerEc({
  labelMd,
  label,
  colMd,
  classCol,
  classContent,
  color,
  setColor,
}: {
  labelMd: ColSpec;
  label: string;
  colMd: ColSpec;
  classCol: string;
  classContent: string;
  color: string;
  setColor: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element {
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const handleShowColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };
  const handleSetColor = (color: any) => {
    setColor(color.hex);
  };

  const colorPickerRef = useRef(null);
  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref?.current && !ref?.current.contains(event.target)) {
          setShowColorPicker(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mouseup', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mouseup', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(colorPickerRef);

  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column md={labelMd}>
          {labelRequire(label)}
        </Form.Label>
        <Col md={colMd} className={classCol}>
          <div
            onClick={handleShowColorPicker}
            onKeyPress={handleShowColorPicker}
            className={classContent}
            style={{ backgroundColor: color, cursor: 'pointer' }}
          ></div>
          <div ref={colorPickerRef}>
            {showColorPicker ? (
              <SketchPicker
                color={color}
                className="fade-in-colorpicker"
                onChangeComplete={handleSetColor}
              />
            ) : (
              ''
            )}
          </div>
        </Col>
      </Form.Group>
    </>
  );
}
