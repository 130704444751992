/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import SelectComponent from 'Components/Page/WebApp/Select';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import DatePicker from '../../../Components/Page/WebApp/DatePicker';
import useMessage from 'Hooks/useMessage';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import CardBill, { PromotionType } from './_components/CardBill/CardBill';
import { Button } from 'react-bootstrap';
import { formatCurrency2 } from 'Utils/Numbers';
import { useRole } from 'Hooks/useRole';
import { IDataSalesQuantity, SupportRevenueAPIs } from 'Datasource/SupportRevenue';
import { formatDate } from 'Utils/DateTime';
import { DATE_FORMAT_22, DATE_FORMAT_YMD_2 } from 'Constant/Date';
import ModalComponent from 'Components/Common/Modal';

export enum KeyLocalStorage {
  DataTickets = 'DataTickets',
}

interface IDataArtistOption {
  label: string;
  value: number;
}

interface IDataShopOption {
  label: string;
  value: number;
}

const SupportRevenue = () => {
  const height = use100vh();

  const responsiveHeight = height ? height - 74 : 'calc(100vh - 74px)';

  const { isEC, isECCompEventApp } = useRole();
  const { openMessageError, openMessage } = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [dataArtist, setDataArtist] = useState<IDataArtistOption[]>([]);
  const [dataShop, setDataShop] = useState<IDataShopOption[]>([]);
  const [dataSalesQuantity, setDataSalesQuantity] = useState<IDataSalesQuantity | null>(null);
  const [query, setQuery] = useQueryParams({
    date: StringParam,
    store: StringParam,
    storeId: StringParam,
    artist: StringParam,
    artistId: StringParam,
    page: NumberParam,
  });

  const isRecordHasStillValid = useMemo(
    () =>
      (dataSalesQuantity &&
        dataSalesQuantity?.items?.some((item) => item.status === PromotionType.stillValid)) ??
      true,
    [dataSalesQuantity],
  );

  //method
  const fetchDataArtistByShop = async () => {
    if (!query.storeId) return;
    try {
      const {
        data: { data },
      } = await SupportRevenueAPIs.getListArtistByShop({ shopId: query.storeId });
      setDataArtist(
        data?.map((i: any) => {
          return { label: i.localName, value: i.id };
        }),
      );
    } catch (error) {
      openMessageError(error);
    }
  };

  const fetchDataShop = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await SupportRevenueAPIs.getShopsRoleStaff();
      setDataShop(
        data?.map((i: any) => {
          return { label: i.name, value: i.id };
        }),
      );
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataSupportRevenues = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await SupportRevenueAPIs.getListSupportRevenue({
        saleEndDate: formatDate(query.date, DATE_FORMAT_YMD_2) || '',
        artistId: query.artistId || '',
        shopId: query.storeId || '',
      });

      setDataSalesQuantity(data);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = useCallback(async () => {
    if (!query.storeId || !query.date || !query.artistId || isRecordHasStillValid) return;

    try {
      setLoading(true);
      await SupportRevenueAPIs.updateSupportRevenues({
        artistId: query.artistId || '',
        saleEndDate: formatDate(query.date, DATE_FORMAT_YMD_2) || '',
        shopId: query.storeId || '',
      });

      // refetch date then update success!
      openMessage({
        variant: 'success',
        message: '内容登録が完了しました。',
      });
      await fetchDataSupportRevenues();
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
      setIsShowModal(false);
    }
  }, [query.artistId, query.date, query.store, isRecordHasStillValid]);

  const handleSubmitModal = () => {
    setIsShowModal(true);
  };

  //useEffect
  useEffect(() => {
    if (!query.storeId || !query.date || !query.artistId) return;
    fetchDataSupportRevenues();
  }, [query.artistId, query.date, query.store]);

  useEffect(() => {
    if (!query.storeId) return;
    fetchDataArtistByShop();
  }, [query.storeId]);

  useEffect(() => {
    fetchDataShop();
  }, []);

  return (
    <div className="px-2 mx-1 py-2 d-flex flex-column" style={{ minHeight: responsiveHeight }}>
      <SpinnerComponent isLoading={loading} />
      <div style={{ height: 'fit-content' }}>
        <SelectComponent
          label="店舗名"
          labelMd="4"
          colMd="8"
          placeholder="店舗を選択してください"
          classLabel="px-0 fs-16"
          rowClass="w-100 fs-16"
          colClass="px-0 fs-16"
          value={
            query?.storeId && query?.store
              ? { value: Number(query?.storeId), label: query?.store }
              : null
          }
          isSearchable={true}
          onChange={(store: any) => {
            window?.localStorage?.removeItem(KeyLocalStorage.DataTickets);
            setQuery({
              storeId: store?.value,
              store: store?.label,
              artistId: null,
              artist: null,
              date: null,
              page: 1,
            });
          }}
          options={dataShop}
          onMenuOpen={() =>
            setQuery({
              storeId: null,
              store: null,
              artistId: null,
              artist: null,
              date: null,
              page: 1,
            })
          }
        />
        <DatePicker
          label="販売期間最終日"
          placeholder="販売期間終了日を入力してください"
          isDisabled={!query.storeId}
        />
        <SelectComponent
          label="アーティスト"
          labelMd="4"
          colMd="8"
          placeholder="アーティストを選択してください"
          classLabel="px-0 fs-16"
          rowClass="w-100 fs-16"
          colClass="px-0 fs-16"
          value={
            query?.artistId && query?.artist
              ? { value: Number(query?.artistId), label: query?.artist }
              : null
          }
          onChange={(artist: any) => {
            window?.localStorage?.removeItem(KeyLocalStorage.DataTickets);
            setQuery({
              artistId: artist?.value,
              artist: artist?.label,
              page: 1,
            });
          }}
          onMenuOpen={() =>
            setQuery({
              artistId: null,
              artist: null,
              page: 1,
            })
          }
          isSearchable={true}
          options={dataArtist}
          isDisabled={!query.date}
        />
      </div>
      {!dataSalesQuantity || dataSalesQuantity?.items?.length <= 0 ? (
        query.artistId && query.date && query.storeId ? (
          <div className="d-flex justify-content-center align-items-center flex-fill fs-16">
            該当イベントはありません。
          </div>
        ) : (
          <div className="d-flex justify-content-center mt-5">
            <p className="gray fs-16">表示する内容を選択してください</p>
          </div>
        )
      ) : (
        <>
          <div className="d-flex flex-column gap-3">
            <div className={`px-1 ${!dataSalesQuantity.submitDate ? 'my-3' : 'mt-3'}`}>
              <div className="row align-items-center">
                <p className="col-6 fw-700 fs-18">販売商品数</p>
                <div className="col-6 d-flex align-items-end justify-content-center">
                  <p className="fw-700 fs-40">{dataSalesQuantity?.items?.length}</p>
                  <p className="fw-700 fs-20" style={{ paddingBottom: '5px' }}>
                    件
                  </p>
                </div>
              </div>
              <div className="row mt-1 align-items-center">
                <p className="col-6 fw-700 fs-18">POS入力</p>
                <div className="col-6 d-flex align-items-end justify-content-center ">
                  <div
                    className={`support-revenue-badge support-revenue-badge-${
                      dataSalesQuantity.submitDate ? 'completed' : 'not-registered'
                    }`}
                  >
                    <span className="fs-18">
                      {dataSalesQuantity.submitDate ? '登録完了' : '未登録'}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-6"></div>
                {dataSalesQuantity.submitDate && (
                  <div className="col-6 text-center">
                    <p className="fs-13">
                      {formatDate(dataSalesQuantity.submitDate, DATE_FORMAT_22)}
                    </p>
                  </div>
                )}
              </div>
            </div>
            {dataSalesQuantity &&
              dataSalesQuantity?.items?.map((item) => {
                return <CardBill key={item.cardCd} data={item} />;
              })}
          </div>
          <div className="my-5 d-flex flex-column gap-2 card-bill-total">
            <div className="d-flex justify-content-between align-items-end">
              <p className="fw-700 fs-18">販売数総計</p>
              <p className="fw-700 fs-18">
                {formatCurrency2(dataSalesQuantity.totalPurchaseQuantity)}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-end">
              <p className="fw-700 fs-18">販売金額総計</p>
              <p className="fw-700 fs-18">
                ¥{formatCurrency2(dataSalesQuantity.totalPurchasePrice)}
              </p>
            </div>
          </div>
          {(isEC || isECCompEventApp) && !dataSalesQuantity.submitDate && (
            <div className="mb-4 d-flex justify-content-center">
              <Button
                className="w-40 fw-700"
                onClick={handleSubmitModal}
                disabled={isRecordHasStillValid}
              >
                入力完了
              </Button>
            </div>
          )}
        </>
      )}
      <ModalComponent
        show={isShowModal}
        onCloseModal={() => setIsShowModal(false)}
        onSubmitModal={handleSubmit}
        title="POS入力を完了させます。よろしいですか？"
        confirmText="完了"
      >
        {dataSalesQuantity && (
          <div className="d-flex flex-column gap-2 card-bill-total border-0">
            <div className="d-flex justify-content-between align-items-end">
              <p className="fw-700 fs-18">販売数総計</p>
              <p className="fw-700 fs-18">
                {formatCurrency2(dataSalesQuantity.totalPurchaseQuantity)}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-end">
              <p className="fw-700 fs-18">販売金額総計</p>
              <p className="fw-700 fs-18">
                ¥{formatCurrency2(dataSalesQuantity.totalPurchasePrice)}
              </p>
            </div>
          </div>
        )}
      </ModalComponent>
    </div>
  );
};

export default SupportRevenue;
