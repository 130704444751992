import { forEach, omit, isEmpty, toNumber } from 'lodash';
import {
  DEFAULT_VARIATION_NAME,
  INCLUDE_COPY_RIGHT,
  INCLUDE_JARSAC_CONTENT,
  LINK_JMD_TYPE_CONTENT,
  MESSAGE_CAPACITY,
  PRICE_JPY,
  TYPE_MOVE,
  VIDEO_TYPES,
} from 'Constant/Title';
import * as yup from 'yup';
import {
  arrayRequiredValidation,
  englishAndDashValidation,
  numberRequired,
  stringRequired,
  stringRequiredTrim,
} from 'Utils/Validation';

import { calcMb } from './File';
import { toast } from 'react-toastify';
import { MusicFormat } from 'Constant/Song';
import { getSum } from './Numbers';
import { Song, SongFeePayload, Video, VideoFeePayload, VariationFieldObj } from 'Types/App/title';

export interface IGenreData {
  id: string | number;
  nameJp: string;
  nameKana: string;
  genre1: string;
  genre2: string;
  genre3: string;
  genre4: string;
}

interface IVariation {
  id: number;
  name: string;
  songQuantity: number;
  productCode: string;
  saleTokenDate: string;
  downloadDate: string;
  isDisplayName: boolean;
  janCode: string;
  price: number;
  coverImageUrl: string;
  songs: any[];
  option: any;
  musicFormat: string;
  booklets: any[];
  notiDownloadTime: string;
  dpMedias: any[];
  isUsedInCampaign: boolean;
  includeJasracContent: boolean;
  jmdCooperation: string;
  riajGenre: IGenreData;
  jmdMusicGenre: IGenreData;
  jmdVideoGenre: IGenreData;
}

const handleConvertSongs = (songs: any) => {
  const cloneSongs = songs?.map((song: any, index: number) => ({
    id: song.id,
    index: index,
    name: song.name,
    furiganaName: song.furiganaName,
    englishName: song.englishName,
    musicianName: song.musicianName,
    musicianFuriganaName: song.musicianFuriganaName,
    musicianEnglishName: song.musicianEnglishName,
    composerName: song.composerName,
    composerFuriganaName: song.composerFuriganaName,
    composerEnglishName: song.composerEnglishName,
    arrangerName: song.arrangerName,
    arrangerFuriganaName: song.arrangerFuriganaName,
    arrangerEnglishName: song.arrangerEnglishName,
    versionIdentificationType: song.versionIdentificationType,
    showTime: song.showTime,
    ivtType: song.ivtType,
    liveType: song.liveType,
    authorizedGroupType: song.authorizedGroupType,
    authorizedGroup: song.authorizedGroup,
    isrcCode: song.isrcCode,
    collectCode: song.collectCode,
    jasracCode: song.jasracCode,
    otherJasracCode: song.otherJasracCode,
    informationFee: song.informationFee ? toNumber(song.informationFee) : 0,
    lyricsClassifyType: song.lyricsClassifyType,
    sourceAudioUrl: song.sourceAudioUrl,
    size: song.size,
    lyrics: song.lyrics,
    fileName: song.fileName,
    lyricInformationFee: song.lyricInformationFee ? toNumber(song.lyricInformationFee) : 0,
    riajGenre: song.riajGenre ?? null,
    riajGenreId: song.riajGenre?.id ?? null,
  }));
  return cloneSongs;
};

const handleConvertVariations = (
  variations: any,
  status: number | unknown,
): Record<string, unknown> => {
  const cloneVariations = variations?.map((variation: IVariation, index: number) => {
    const dpMedias: any = variation?.dpMedias || [];
    const videos = dpMedias
      .filter((item: any) => +item.type == 1)
      .map((video: Video) => ({
        ...video,
        songs:
          video?.includeCopyRightSong !== null &&
          video.includeCopyRightSong !== INCLUDE_COPY_RIGHT.INCLUDE
            ? []
            : video.songs,
        mediaInformationFee: video?.mediaInformationFee
          ? toNumber(video.mediaInformationFee as string)
          : 0,
      }));

    const images = dpMedias.filter((item: any) => item.type == 0);
    return {
      index,
      id: variation.id,
      type: index === 0 ? 0 : 1,
      name: variation.name,
      songQuantity: variation.songQuantity,
      productCode: variation.productCode,
      saleTokenDate: variation.saleTokenDate,
      downloadDate: variation.downloadDate,
      isDisplayName: variation.isDisplayName,
      janCode: variation.janCode,
      price: variation.price,
      coverImageUrl: variation.coverImageUrl,
      songs: handleConvertSongs(variation.songs),
      option: variation.option,
      musicFormat: variation.musicFormat,
      booklets: variation.booklets,
      notiDownloadTime: variation.notiDownloadTime,
      videos: videos,
      images: images,
      status: status,
      isUsedInCampaign: variation?.isUsedInCampaign,
      includeJasracContent:
        variation?.includeJasracContent !== null
          ? variation?.includeJasracContent
          : INCLUDE_JARSAC_CONTENT.INCLUDE,
      jmdCooperation: variation.jmdCooperation ?? LINK_JMD_TYPE_CONTENT.WORK_TOGETHER,
      riajGenre: variation.riajGenre ?? null,
      jmdMusicGenre: variation.jmdMusicGenre ?? null,
      jmdVideoGenre: variation.jmdVideoGenre ?? null,
      riajGenreId: variation.riajGenre?.id ?? null,
      jmdMusicGenreId: variation?.jmdMusicGenre?.id ?? null,
      jmdVideoGenreId: variation?.jmdVideoGenre?.id ?? null,
    };
  });
  return cloneVariations;
};

const handleConvertTitleInfo = (title: Record<string, unknown>): Record<string, unknown> => {
  const titleInfo = {
    id: title.id,
    artistId: title.artistId,
    artistName: title.artistName,
    name: title.name,
    type: title.type,
    label: title.label,
    musicGenreId: title.musicGenreId,
    furiganaName: title.furiganaName,
    musicGenreName: title.musicGenreName,
    globalName: title.globalName,
    variations: handleConvertVariations(title.variations, title.status),
    isAuthor: title.isAuthor,
    status: title.status,
    managerList: title?.managerList || [],
    sellIap: title?.sellIap || 0,
  };
  return titleInfo;
};

const handleConvertSongsDuplicate = (songs: any) => {
  const cloneSongs = songs?.map((song: any, index: number) => ({
    index: index,
    name: song.name,
    furiganaName: song.furiganaName,
    englishName: song.englishName,
    musicianName: song.musicianName,
    musicianFuriganaName: song.musicianFuriganaName,
    musicianEnglishName: song.musicianEnglishName,
    composerName: song.composerName,
    composerFuriganaName: song.composerFuriganaName,
    composerEnglishName: song.composerEnglishName,
    arrangerName: song.arrangerName,
    arrangerFuriganaName: song.arrangerFuriganaName,
    arrangerEnglishName: song.arrangerEnglishName,
    versionIdentificationType: song.versionIdentificationType,
    showTime: song.showTime,
    ivtType: song.ivtType,
    liveType: song.liveType,
    authorizedGroupType: song.authorizedGroupType,
    authorizedGroup: song.authorizedGroup,
    isrcCode: song.isrcCode,
    collectCode: song.collectCode,
    jasracCode: song.jasracCode,
    otherJasracCode: song.otherJasracCode,
    informationFee: song?.informationFee ? toNumber(song.informationFee) : 0,
    lyricsClassifyType: song?.lyricsClassifyType,
    sourceAudioUrl: song?.sourceAudioUrl,
    size: song?.size,
    lyrics: song?.lyrics,
    fileName: song?.fileName,
    lyricInformationFee: song?.lyricInformationFee ? toNumber(song.lyricInformationFee) : 0,
    riajGenre: song.riajGenre ?? null,
    riajGenreId: song.riajGenre?.id ?? null,
  }));
  return cloneSongs;
};

const handleConvertJmdCooperation = (value: number) => {
  return value === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER ? '連携する' : '連携しない';
};

const handleConvertVideosDuplicate = (videos: any) => {
  const cloneVideos = videos.map((video: any) => {
    video = omit(video, ['id', 'variationId', 'dpMediaId']);
    if (
      video?.includeCopyRightSong !== null &&
      +video?.includeCopyRightSong !== INCLUDE_COPY_RIGHT.INCLUDE
    ) {
      video['songs'] = [];
    } else {
      video['songs'] = handleConvertSongsDuplicate(video?.songs);
    }

    if (video?.mediaInformationFee) {
      video.mediaInformationFee = toNumber(video.mediaInformationFee);
    }
    video.id = randomId();
    return video;
  });
  return cloneVideos;
};

const handleConvertImagesDuplicate = (images: any) => {
  const cloneImages = images.map((image: any) => {
    image = omit(image, ['id', 'variationId', 'dpMediaId']);
    image.id = randomId();
    return image;
  });
  return cloneImages;
};

const handleConvertBookletsDuplicate = (booklets: any) => {
  const cloneBooklets = booklets.map((booklet: any) => {
    booklet = omit(booklet, ['id', 'variationId']);
    return booklet;
  });
  return cloneBooklets;
};

const handleConvertVariationsDuplicate = (variations: any): Record<string, unknown> => {
  const cloneVariations = variations?.map((variation: IVariation, index: number) => {
    const dpMedias: any = variation?.dpMedias || [];
    const videos = dpMedias.filter((item: any) => item.type == 1);
    const images = dpMedias.filter((item: any) => item.type == 0);
    return {
      index,
      type: variation.name === DEFAULT_VARIATION_NAME ? 0 : 1,
      name: variation.name,
      songQuantity: variation.songQuantity,
      productCode: variation.productCode,
      saleTokenDate: '',
      downloadDate: '',
      isDisplayName: variation.isDisplayName,
      janCode: variation.janCode,
      price: variation.price,
      coverImageUrl: variation.coverImageUrl,
      songs: handleConvertSongsDuplicate(variation.songs),
      option: variation.option,
      musicFormat: variation.musicFormat,
      booklets: handleConvertBookletsDuplicate(variation.booklets),
      notiDownloadTime: '',
      videos: handleConvertVideosDuplicate(videos),
      images: handleConvertImagesDuplicate(images),
      includeJasracContent:
        variation?.includeJasracContent !== null
          ? variation?.includeJasracContent
          : INCLUDE_JARSAC_CONTENT.INCLUDE,
      jmdCooperation: variation.jmdCooperation ?? LINK_JMD_TYPE_CONTENT.WORK_TOGETHER,
      riajGenre: variation.riajGenre ?? null,
      jmdMusicGenre: variation.jmdMusicGenre ?? null,
      jmdVideoGenre: variation.jmdVideoGenre ?? null,
      riajGenreId: variation.riajGenre?.id ?? null,
      jmdMusicGenreId: variation?.jmdMusicGenre?.id ?? null,
      jmdVideoGenreId: variation?.jmdVideoGenre?.id ?? null,
    };
  });
  return cloneVariations;
};

const handleConvertTitleDuplicate = (title: Record<string, unknown>): Record<string, unknown> => {
  const titleInfo = {
    artistId: title.artistId,
    artistName: title.artistName,
    name: '',
    type: title.type,
    label: title.label,
    musicGenreId: title.musicGenreId,
    furiganaName: title.furiganaName,
    musicGenreName: title.musicGenreName,
    variations: handleConvertVariationsDuplicate(title.variations),
    isAuthor: title.isAuthor,
    managerList: title?.managerList || [],
    sellIap: title?.sellIap || 0,
    globalName: title?.globalName || '',
  };
  return titleInfo;
};

const validateVariations = (variations: any, callback: any): void => {
  const dataSource: any = [...variations];
  const lookup = dataSource.reduce((duplicate: any, variation: any) => {
    duplicate[variation.name] = ++duplicate[variation.name] || 0;
    return duplicate;
  }, {});
  const nameNotBlanks = dataSource.filter((item: any) => !isEmpty(item.name));
  let duplicateNames = [];
  if (nameNotBlanks.length > 0) {
    duplicateNames = dataSource.filter(
      (variation: any) => variation.type === 1 && lookup[variation.name],
    );
  }

  forEach(duplicateNames, (variation) => {
    return callback(variation);
  });
};

const padNum = (num: number, length = 3) => {
  return `${Array(length + 1 - num.toString().length).join('0') + num}`;
};

const convertToClock = (seconds: number) => {
  if (!seconds) return '';
  let secondsFloat = seconds | 0;
  const mm = ((secondsFloat / 60) | 0).toString().padStart(2, '0');
  const ss = (secondsFloat %= 60).toString().padStart(2, '0');
  const mls = `${seconds}`.split('.')[1].toString().slice(0, 3);

  return `${mm}:${ss}:${mls}`;
};

const convertToClockMMSS = (seconds: number) => {
  if (!seconds) return '';
  let secondsFloat = seconds | 0;
  const mm = ((secondsFloat / 60) | 0).toString().padStart(2, '0');
  const ss = (secondsFloat %= 60).toString().padStart(2, '0');

  return `${mm}:${ss}`;
};

const convertToSeconds = (time: number) => {
  if (!time) return 0;
  const value = time.toString().replace(/:/g, '');
  const mm = value.substring(0, 2);
  const ss = value.substring(2, 4);
  const mls = value.substring(4, 7);
  return +mm * 60 + +ss + +`0.${mls}`;
};

const getNumberRemainSong = (titleForm: any, variationIndex: number): number => {
  const va = titleForm?.variations[variationIndex];
  const numberMax = va?.option?.maxSongCount || 0;
  const numberSong = va?.songs?.length || 0;
  let numberSongVideo = 0;
  va?.videos?.map((item: any) => {
    numberSongVideo = numberSongVideo + item?.songs?.length;
  });
  return numberMax - numberSong - numberSongVideo;
};

const checklNumberRemainCapacity = (variation: any, currentSize: number): boolean => {
  const va = variation;
  const currentSizeMb = calcMb(currentSize);

  const maxCapacity = va?.option?.maxVariationStorage || 0;
  let bookletsCapacity = 0;
  va.booklets?.map((item: any) => {
    bookletsCapacity = bookletsCapacity + +item?.size;
  });

  let imagesCapacity = 0;
  va.images?.map((item: any) => {
    imagesCapacity = imagesCapacity + +item?.size;
  });

  let songsCapacity = 0;
  va.songs?.map((item: any) => {
    if (item?.size) {
      songsCapacity = songsCapacity + +item?.size;
    }
  });

  let videosCapacity = 0;
  va.videos?.map((item: any) => {
    videosCapacity = videosCapacity + +item?.size;
  });

  const remainingCapacity =
    maxCapacity - calcMb(bookletsCapacity + imagesCapacity + videosCapacity + songsCapacity);

  const isOverCapacity = currentSizeMb > remainingCapacity;

  if (isOverCapacity) toast(`${remainingCapacity}${MESSAGE_CAPACITY}`, { type: 'error' });
  return isOverCapacity;
};

const convertPriceApple = (price: number): number => {
  return PRICE_JPY.find((element: number) => element >= price) || 0;
};

const handleMove = (index: number, type: string, array: any) => {
  const newResult = [...array];
  if (type === TYPE_MOVE[0]) {
    if (index === 0) return;
    const tmp = newResult[index - 1];
    newResult[index - 1] = array[index];
    newResult[index] = tmp;
  }
  if (type === TYPE_MOVE[1]) {
    if (index === newResult.length - 1) return;
    const tmp = newResult[index + 1];
    newResult[index + 1] = array[index];
    newResult[index] = tmp;
  }
  return newResult;
};

const checkValidateSaveDraft = async (infoTitle: any) => {
  const schemaTitleInfo = yup.object().shape({
    artistId: yup.string().required('アーティスト: 入力してください。').nullable(),
    name: yup.string().required('タイトル名: 入力してください。').nullable(),
    variations: yup
      .array()
      .min(1, 'require min 1 varilation')
      .of(
        yup.object().shape({
          name: stringRequired({}),
          productCode: englishAndDashValidation({}).nullable(),
          janCode: yup.string().required('入力してください。').nullable(),
          price: yup
            .string()
            .required('入力してください。')
            .test({
              name: 'price',
              message: '100,000円以下の値を入力してください。',
              test: (value, context: any) => {
                const { createError, from, path } = context;
                const { option } = from[0].value;
                const price = value ? +value : 0;
                const { minPrice } = option;
                if (price < +minPrice) {
                  return createError({
                    message: `${+minPrice}円以上を入力してください。`,
                    path,
                  });
                } else {
                  return price < 100000;
                }
              },
            })
            .nullable(),
          downloadDate: stringRequired({}).nullable(),
          saleTokenDate: stringRequired({}).nullable(),
          songs: yup.array().of(
            yup.object().shape({
              sourceAudioUrl: yup
                .string()
                .trim()
                .test({
                  name: 'sourceAudioUrl',
                  message: '⾳源ファイルは の⾳源を登録してください。',
                  test: (value, context: any) => {
                    const { createError, from, path } = context;
                    const { musicFormat } = from[1].value;
                    const isWAV = (value || '')?.search('.wav') > 0;
                    if (
                      musicFormat === undefined ||
                      value === undefined ||
                      value === null ||
                      musicFormat === null
                    )
                      return true;
                    const result =
                      (isWAV && musicFormat === MusicFormat.WAV) ||
                      (!isWAV && musicFormat === MusicFormat.MP3);
                    return (
                      result ||
                      createError({
                        message: `⾳源ファイルは ${
                          musicFormat ? '.wav' : '.mp3'
                        } の⾳源を登録してください。`,
                        path,
                      })
                    );
                  },
                })
                .notRequired()
                .nullable(),
            }),
          ),
          videos: yup.array().of(
            yup.object().shape({
              videoType: yup
                .number()
                .when('includeCopyRightSong', {
                  is: INCLUDE_COPY_RIGHT.INCLUDE,
                  then: numberRequired({}).nullable(),
                })
                .nullable(),
              songs: yup.array().when(['includeCopyRightSong'], {
                is: (includeCopyRightSong: number) =>
                  includeCopyRightSong === INCLUDE_COPY_RIGHT.INCLUDE,
                then: arrayRequiredValidation({}).min(1, '著作権管理楽曲を登録してください'),
              }),
            }),
          ),
        }),
      ),
  });
  const validationResult = await schemaTitleInfo
    .validate(infoTitle, { abortEarly: false })
    .catch((err) => {
      return err;
    });
  const arrError = validationResult?.inner || [];
  return arrError;
};

const checkAutoFormatJasrac = (value: string) => {
  if (value?.length < 11) {
    const cleaned = `${value.trim()}`.replaceAll('-', '');
    const normValue = `${cleaned.substring(0, 3)}${
      cleaned.length > 3 ? '-' : ''
    }${cleaned.substring(3, 7)}${cleaned.length > 7 ? '-' : ''}${cleaned.substring(7, 8)}`;

    return normValue;
  }
};

const schemaDraftVariation = () => {
  return yup.object().shape({
    variations: yup
      .array()
      .min(1, 'require min 1 varilation')
      .of(
        yup.object().shape({
          name: stringRequired({}),
          saleTokenDate: stringRequired({}).nullable(),
          downloadDate: stringRequired({}).nullable(),
          productCode: englishAndDashValidation({}).nullable(),
          janCode: yup.string().required('入力してください。').nullable(),
          price: yup
            .string()
            .required('入力してください。')
            .test({
              name: 'price',
              message: '100,000円以下の値を入力してください。',
              test: (value, context: any) => {
                const { createError, from, path } = context;
                const { option } = from[0].value;
                const price = value ? +value : 0;
                const { minPrice } = option;
                if (price < +minPrice) {
                  return createError({
                    message: `${+minPrice}円以上を入力してください。`,
                    path,
                  });
                } else {
                  return price < 100000;
                }
              },
            })
            .nullable(),
          songs: yup.array().of(
            yup.object().shape({
              sourceAudioUrl: yup
                .string()
                .trim()
                .test({
                  name: 'sourceAudioUrl',
                  message: '⾳源ファイルは の⾳源を登録してください。',
                  test: (value, context: any) => {
                    const { createError, from, path } = context;
                    const { musicFormat } = from[1].value;
                    const isWAV = (value || '')?.search('.wav') > 0;
                    if (
                      musicFormat === undefined ||
                      value === undefined ||
                      value === null ||
                      musicFormat === null
                    )
                      return true;
                    const result =
                      (isWAV && musicFormat === MusicFormat.WAV) ||
                      (!isWAV && musicFormat === MusicFormat.MP3);
                    return (
                      result ||
                      createError({
                        message: `⾳源ファイルは ${
                          musicFormat ? '.wav' : '.mp3'
                        } の⾳源を登録してください。`,
                        path,
                      })
                    );
                  },
                })
                .notRequired()
                .nullable(),
            }),
          ),
          videos: yup.array().of(
            yup.object().shape({
              videoType: yup
                .number()
                .when('includeCopyRightSong', {
                  is: INCLUDE_COPY_RIGHT.INCLUDE,
                  then: numberRequired({}).nullable(),
                })
                .nullable(),
              songs: yup.array().when(['includeCopyRightSong'], {
                is: (includeCopyRightSong: number) =>
                  includeCopyRightSong === INCLUDE_COPY_RIGHT.INCLUDE,
                then: arrayRequiredValidation({}).min(1, '著作権管理楽曲を登録してください'),
              }),
            }),
          ),
          jmdMusicGenreId: yup.string().when('songs', {
            is: (songs: any[]) => songs && songs.length > 0,
            then: yup.string().required('入力してください。').nullable(),
          }),
          jmdVideoGenreId: yup.number().when('videos', {
            is: (videos: any[]) => videos && videos.length > 0,
            then: yup.number().required('入力してください。').nullable(),
          }),
        }),
      ),
  });
};

const schemaOfficialVariation = () => {
  return yup.object().shape({
    variations: yup.array().of(
      yup.object().shape({
        name: stringRequired({}),
        type: numberRequired({}).nullable(),
        includeJasracContent: numberRequired({}).nullable(),
        musicFormat: numberRequired({}).nullable(),
        saleTokenDate: stringRequired({}).nullable(),
        downloadDate: stringRequired({}).nullable(),
        notiDownloadTime: stringRequired({}).nullable(),
        coverImageUrl: stringRequired({}).nullable(),
        productCode: englishAndDashValidation({}).nullable(),
        janCode: stringRequired({}).nullable(),
        price: yup
          .string()
          .required('入力してください。')
          .test({
            name: 'price',
            message: '100,000円以下の値を入力してください。',
            test: (value, context: any) => {
              const { createError, from, path } = context;
              const { option } = from[0].value;
              const price = value ? +value : 0;
              const { minPrice } = option;
              if (price < +minPrice) {
                return createError({
                  message: `${+minPrice}円以上を入力してください。`,
                  path,
                });
              } else {
                return price < 100000;
              }
            },
          })
          .nullable(),
        songs: yup.array().of(
          yup.object().shape({
            sourceAudioUrl: stringRequiredTrim({})
              .test({
                name: 'sourceAudioUrl',
                message: '⾳源ファイルは の⾳源を登録してください。',
                test: (value, context: any) => {
                  const { createError, from, path } = context;
                  const { musicFormat } = from[1].value;
                  const isWAV = (value || '')?.search('.wav') > 0;
                  if (
                    musicFormat === undefined ||
                    value === undefined ||
                    value === null ||
                    musicFormat === null
                  )
                    return true;
                  const result = (isWAV && musicFormat === 1) || (!isWAV && musicFormat === 0);
                  return (
                    result ||
                    createError({
                      message: `⾳源ファイルは ${
                        musicFormat ? '.wav' : '.mp3'
                      } の⾳源を登録してください。`,
                      path,
                    })
                  );
                },
              })
              .nullable(),
          }),
        ),
        videos: yup
          .array()
          .when(['songs'], {
            is: (songs: any) => songs.length == 0,
            then: arrayRequiredValidation({}).min(
              1,
              '楽曲コンテンツ、または動画コンテンツを入力してください。',
            ),
          })
          .of(
            yup.object().shape({
              name: stringRequired({}),

              introEc: stringRequired({}),
              includeCopyRightSong: numberRequired({}).nullable(),
              videoType: yup
                .number()
                .when('includeCopyRightSong', {
                  is: INCLUDE_COPY_RIGHT.INCLUDE,
                  then: numberRequired({}).nullable(),
                })
                .nullable(),
              songs: yup.array().when(['includeCopyRightSong'], {
                is: (includeCopyRightSong: number) =>
                  includeCopyRightSong === INCLUDE_COPY_RIGHT.INCLUDE,
                then: arrayRequiredValidation({}).min(1, '著作権管理楽曲を登録してください'),
              }),
            }),
          ),
        images: yup.array().of(
          yup.object().shape({
            name: stringRequired({}),
            introEc: stringRequired({}),
          }),
        ),
        jmdMusicGenreId: yup.string().when('songs', {
          is: (songs: any[]) => songs && songs.length > 0,
          then: yup.string().required('入力してください。').nullable(),
        }),
        jmdVideoGenreId: yup.number().when('videos', {
          is: (videos: any[]) => videos && videos.length > 0,
          then: yup.number().required('入力してください。').nullable(),
        }),
      }),
    ),
  });
};

const variationFields = (): VariationFieldObj[] => {
  //! please make it follow index in UI page
  const results = [
    'name',
    'type',
    'includeJasracContent',
    'musicFormat',
    'saleTokenDate',
    'downloadDate',
    'notiDownloadTime',
    'coverImageUrl',
    'productCode',
    'janCode',
    'price',
    'booklets',
    'songs',
    'videos',
    'images',
  ].map((key: string, index: number) => ({ name: key, index: index }));
  return results;
};

const getInformationFeeManual = (songs: any[]) => {
  if (!songs.length) return 0;

  return getSum(songs, 'informationFee');
};

const handleConvertSongInformationFee = (songs: Song[]) => {
  const result: SongFeePayload[] = songs.map((song: Song, songIndex: number) => ({
    songName: song?.name || '',
    lyric: song.lyrics && song.lyrics !== '[]' ? song.lyrics : null,
    index: songIndex,
  }));

  return result;
};

const handleConvertSongVideoInformationFee = (songs: Song[]) => {
  const result: SongFeePayload[] = songs.map((song: Song, songIndex: number) => ({
    songName: song?.name || '',
    lyric: song.lyrics && song.lyrics !== '[]' ? song.lyrics : null,
    index: songIndex,
    authorizedGroupType: song?.authorizedGroupType ? +song?.authorizedGroupType : '',
  }));

  return result;
};

const handleConvertVideoInformationFee = (videos: any) => {
  const result = videos.map((video: any, videoIndex: number) => ({
    type: video?.videoType ? `${video.videoType}` : VIDEO_TYPES.MUSIC_VIDEO,
    videoName: video?.name || '',
    songs: handleConvertSongVideoInformationFee(video?.songs || []),
    index: videoIndex,
  }));

  return result as VideoFeePayload[];
};

//* cut text if text too long
const truncateText = (text: string, length: number): string => {
  return text.length > length ? `${text.slice(0, length)}...` : text;
};

function randomId(): string {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return `uuid-${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
}

function extractIdsAndNamesGenreFromJSON(data: any[]): any[] {
  const result: any[] = [];

  function traverse(obj: any) {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        traverse(obj[key]);
      }
      if (key === 'id' && obj['nameJp']) {
        result.push({ id: obj['id'], nameJp: obj['nameJp'], nameKana: obj['nameKana'] });
      }
    }
  }

  data.forEach((item) => traverse(item));

  return result;
}

export {
  handleConvertTitleInfo,
  validateVariations,
  padNum,
  convertToClock,
  getNumberRemainSong,
  convertToSeconds,
  checkValidateSaveDraft,
  convertPriceApple,
  handleMove,
  checklNumberRemainCapacity,
  handleConvertTitleDuplicate,
  handleConvertVariationsDuplicate,
  checkAutoFormatJasrac,
  convertToClockMMSS,
  schemaDraftVariation,
  schemaOfficialVariation,
  variationFields,
  getInformationFeeManual,
  handleConvertSongInformationFee,
  handleConvertSongVideoInformationFee,
  handleConvertVideoInformationFee,
  truncateText,
  randomId,
  handleConvertJmdCooperation,
  extractIdsAndNamesGenreFromJSON,
};
