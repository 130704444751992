import React from 'react';
import { Col } from 'react-bootstrap';
import useSuggestion from 'Hooks/useSuggestion';
import Search from 'Components/Common/Search/Search';

export default function CampaignSearch(): JSX.Element {
  const {
    artistOptions,
    titleOptions,
    loadingArtist,
    loadingTitle,
    handleLoadMore,
    handleFocusInput,
    handleEnterPrimary,
    handleClickItemPrimary,
  } = useSuggestion({ fetchList: ['artist', 'title'] });

  return (
    <>
      <Col md="4" className="px-0">
        <Search
          label="アーティスト"
          labelMd="4"
          colMd="8"
          placeholder="検索"
          showIcon
          classLabel="px-0 artist-label"
          rowClass="w-100"
          colClass="px-0"
          options={artistOptions.options || []}
          onClickResult={handleClickItemPrimary}
          searchKey="queryArtist"
          onEnter={() => handleEnterPrimary('queryArtist')}
          loading={loadingArtist}
          onLoadMore={() => handleLoadMore('pageArtist')}
          onFocusInput={() => {
            handleFocusInput('queryArtist', 'pageArtist');
          }}
        />
      </Col>
      <Col md="4" className="pe-1 ps-3">
        <Search
          label="タイトル"
          labelMd="4"
          colMd="8"
          placeholder="検索"
          showIcon
          classLabel="px-0 title-label"
          rowClass="w-100"
          colClass="px-0"
          options={titleOptions.options || []}
          onClickResult={handleClickItemPrimary}
          searchKey="queryTitle"
          onEnter={() => handleEnterPrimary('queryTitle')}
          loading={loadingTitle}
          onLoadMore={() => handleLoadMore('pageTitle')}
          onFocusInput={() => {
            handleFocusInput('queryTitle', 'pageTitle');
          }}
        />
      </Col>
    </>
  );
}
