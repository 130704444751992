/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Image as ImageC, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import BoxForm from 'Components/Common/Form/BoxForm';
import ModalComponent from 'Components/Common/Modal';
import {
  checkIfFilesAreCorrectType,
  checkIfFilesAreTooBig,
  checkImgSquare,
  checkImgSquareAndDimension,
} from 'Utils/File';
import { ArtistApi } from 'Datasource/ArtistApi';
import useMessage from 'Hooks/useMessage';
import MessageError from 'Components/Common/MessageError';
import {
  englishValidation,
  katakanaFullSizeValidation,
  regexOfficialWebsite,
  stringRequiredTrim,
} from 'Utils/Validation';
import PermissionWrapper from 'Components/Common/Permission';
import { getBool } from 'Utils/Permission';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import FormLabel from 'Components/Common/Form/FormLabel';
import { useDropzone } from 'react-dropzone';

interface IFormInputs {
  nameArtistDomestic: string;
  nameArtistFurigana: string;
  nameArtistDoreign: string;
  officialWebsite: string;
  artistImage: File;
}

interface DataParams {
  localName: string;
  furiganaName: string;
  globalName: string;
  officialSite: string;
  image: File;
}

interface ParamsURL {
  id?: string;
}

interface ModalProps {
  id?: string;
  isShow: boolean;
  title?: string;
  type: string;
  onClose: () => void;
  onOpen: () => void;
}

const schema = yup
  .object()
  .shape({
    nameArtistDomestic: stringRequiredTrim({ required: '入力してください。' }),
    nameArtistFurigana: katakanaFullSizeValidation({}),
    nameArtistDoreign: englishValidation({}),
    officialWebsite: yup.string().matches(regexOfficialWebsite, {
      message: 'URLを入力してください。',
      excludeEmptyString: true,
    }),
    artistImage: yup
      .mixed()
      .required('入力してください。')
      .test({
        name: 'is-size-file',
        message: '画像サイズは縦横６００ｘ６００ピクセルの画像を登録してください。',
        test: (value) => {
          return checkImgSquareAndDimension(value, 600);
        },
      })
      .test(
        'is-correct-file',
        '画像タイプはjpeg, pngまたはgifの画像を登録してください。',
        checkIfFilesAreCorrectType,
      )
      .test(
        'is-big-file',
        '画像ファイルは2.0MB以内のjpeg、png又はgifのファイルを登録してください。',
        checkIfFilesAreTooBig,
      )
      .test(
        'is-square-image',
        '2.0MB以内の正方形の画像を登録してください。',
        async function (imgFile) {
          return (await checkImgSquareAndDimension(imgFile, 600))
            ? await checkImgSquare(imgFile)
            : true;
        },
      ),
  })
  .required();

interface AritstForm {
  nameArtistDomestic: string;
  nameArtistFurigana: string;
  nameArtistDoreign: string;
  officialWebsite: string;
  artistImage: File | string | null;
  isAuthor: string | number;
}
export default function RegisterArtist(): JSX.Element {
  const history = useHistory();
  const { id }: ParamsURL = useParams();
  const { openMessageError } = useMessage();
  const [fileImage, setFileImage] = useState<string>('');
  const [fileImageAPI, setFileImageAPI] = useState<string>('');

  const [showModal, setShowModal] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [isChangeImage, setChangeImage] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('normal');
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    trigger,
    watch,
    formState: { errors },
  } = useForm<AritstForm>({
    defaultValues: {
      nameArtistDomestic: '',
      nameArtistFurigana: '',
      nameArtistDoreign: '',
      officialWebsite: '',
      artistImage: '',
    },
    resolver: yupResolver(schema),
  });

  const handleGetDetailArtist = async () => {
    if (!id) {
      return;
    }

    const idArtist = parseInt(id, 10);
    try {
      setLoading(true);
      const {
        data: { data },
      } = await ArtistApi.getArtistById(idArtist);

      setFileImageAPI(`${data.image}`);
      reset({
        nameArtistDomestic: data?.localName,
        nameArtistFurigana: data?.furiganaName,
        nameArtistDoreign: data?.globalName,
        officialWebsite: data?.officialSite,
        artistImage: data?.image,
        isAuthor: data?.isAuthor,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeImage = async (e: React.SyntheticEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    const files = target.files;
    if (!files) {
      return;
    }
    const fileUpload = URL.createObjectURL(files[0]);
    setFileImage(fileUpload);
    setValue('artistImage', files[0]);
    await trigger('artistImage');
    setChangeImage(true);
    return;
  };

  const handleOnDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) return;
    const fileUpload = URL.createObjectURL(file);
    setFileImage(fileUpload);
    setValue('artistImage', file);
    await trigger('artistImage');
    setChangeImage(true);
    return;
  };

  const onToggleShowModal = async (type: string) => {
    const isValidForm = await trigger();
    if (isValidForm) {
      setShowModal(true);
      setModalType(type);
    }
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onSubmit = (data: IFormInputs) => {
    const params = {
      localName: data.nameArtistDomestic,
      furiganaName: data.nameArtistFurigana,
      globalName: data.nameArtistDoreign,
      officialSite: data.officialWebsite,
      image: data.artistImage,
    };
    if (!id) {
      handleCreateArtist(params);
    } else {
      if (isChangeImage) {
        handleEditArtist(params, id);
        setChangeImage(false);
      } else {
        if (modalType === 'delete') {
          handleDeleteArtist(id);
          return;
        }
        const paramsNotFieldImage = {
          localName: data.nameArtistDomestic,
          furiganaName: data.nameArtistFurigana,
          globalName: data.nameArtistDoreign,
          officialSite: data.officialWebsite,
          image: new File([''], 'imageNotFound', {
            type: 'image/jpeg',
          }),
        };
        handleEditArtist(paramsNotFieldImage, id);
      }
    }
  };

  const resetFormState = () => {
    reset();
    setFileImage('');
    setLoading(false);
    setFileImageAPI('');
  };

  const handleCreateArtist = async (artist: DataParams) => {
    try {
      onCloseModal();
      setLoading(true);
      await ArtistApi.handlerRegisterArtist(artist);
      history.push('/artist');
      resetFormState();
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditArtist = async (artist: DataParams, id: string) => {
    try {
      onCloseModal();
      setLoading(true);
      await ArtistApi.handlerEditArtist(artist, id);
      setLoading(false);
      history.push('/artist');
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteArtist = async (id: string) => {
    try {
      onCloseModal();
      setLoading(true);
      await ArtistApi.handlerDeleteArtist(id);
      setLoading(false);
      history.push('/artist');
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/png, image/gif, image/jpeg',
    maxFiles: 1,
    onDrop: handleOnDrop,
  });

  useEffect(() => {
    if (id) {
      handleGetDetailArtist();
      return;
    }
    resetFormState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const ModalWrapper = ({ isShow = false, onClose, type }: ModalProps) => {
    return (
      <ModalComponent
        show={isShow}
        onCloseModal={onClose}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="primary"
        submitIdForm="register-artist"
        confirmText={type === 'delete' ? '削除する' : '登録する'}
        title={type === 'delete' ? '削除確認' : '登録確認'}
      >
        <div>
          {type === 'delete'
            ? 'アーティストを削除します。よろしいですか。'
            : '入力した内容で登録します。よろしいですか。'}
        </div>
      </ModalComponent>
    );
  };

  const handleRenderBtnUpload = () => {
    if (id && !getBool(watch('isAuthor'))) return;
    return (
      <div className="align-self-end">
        <label
          className="p-2 mt-2 cursor-pointer bg-primary text-white rounded btn-focus-none"
          htmlFor="file-image"
        >
          ファイル選択
        </label>
        <Form.Control
          className="invisible position-absolute"
          id="file-image"
          type="file"
          name="artistImage"
          accept="image/png, image/gif, image/jpeg"
          onChange={handleChangeImage}
        />
      </div>
    );
  };

  return (
    <Container className="overflow-hidden">
      <SpinnerComponent isLoading={loading} />
      <Row>
        <Col md="12" className="my-4">
          <Form onSubmit={handleSubmit(onSubmit)} id="register-artist">
            <PermissionWrapper pic={id ? getBool(watch('isAuthor')) : true}>
              <BoxForm title={id ? 'アーティスト情報' : 'アーティスト登録'}>
                <>
                  <FormGroupInput
                    value={watch('nameArtistDomestic')}
                    onChange={(value) => setValue('nameArtistDomestic', value)}
                    labelMd="3"
                    colMd="9"
                    label="アーティスト名（国内向け表記）*"
                    errorMessage={errors.nameArtistDomestic?.message}
                    register={{ ...register('nameArtistDomestic') }}
                  />
                  <FormGroupInput
                    value={watch('nameArtistFurigana')}
                    onChange={(value) => setValue('nameArtistFurigana', value)}
                    labelMd="3"
                    colMd="9"
                    label="アーティスト名（フリガナ）*"
                    errorMessage={errors.nameArtistFurigana?.message}
                    register={{ ...register('nameArtistFurigana') }}
                  />
                  <FormGroupInput
                    value={watch('nameArtistDoreign')}
                    onChange={(value) => setValue('nameArtistDoreign', value)}
                    labelMd="3"
                    colMd="9"
                    label="アーティスト名（海外向け表記）*"
                    errorMessage={errors.nameArtistDoreign?.message}
                    register={{ ...register('nameArtistDoreign') }}
                  />
                  <FormGroupInput
                    value={watch('officialWebsite')}
                    onChange={(value) => setValue('officialWebsite', value)}
                    labelMd="3"
                    colMd="9"
                    label="オフィシャルサイト"
                    errorMessage={errors.officialWebsite?.message}
                    register={{ ...register('officialWebsite') }}
                  />

                  <Form.Group as={Row} className="mb-2">
                    <Form.Label column md="3">
                      <FormLabel label="アーティストイメージ*" />
                      <FormLabel classLabel="ms-3" label="(600px X 600px)" />
                    </Form.Label>
                    <Col sm="9">
                      <div className="d-flex w-100 position-relative">
                        <div
                          {...getRootProps({
                            className: `box-image d-flex justify-content-center align-items-center rounded-3 ${
                              fileImage || fileImageAPI ? '' : 'box-file-border'
                            }`,
                          })}
                        >
                          {fileImage || fileImageAPI ? (
                            <>
                              <input {...getInputProps()} />
                              <ImageC
                                className="w-100 h-100 rounded-3"
                                src={
                                  fileImage ? fileImage : handleConnectStaticEndPoint(fileImageAPI)
                                }
                                alt=""
                              />
                            </>
                          ) : (
                            <>
                              <input {...getInputProps()} />
                              <p className="fs-12 text-gray-ct">
                                画像ファイルをアップロードしてください
                              </p>
                            </>
                          )}
                        </div>
                        <div className="ms-3 d-flex">{handleRenderBtnUpload()}</div>
                      </div>
                      <div className="w-100">
                        <MessageError message={errors.artistImage?.message} />
                      </div>
                    </Col>
                  </Form.Group>
                  <div className="d-flex justify-content-end text-danger">
                    ご注意：＊印は必須項目です。
                  </div>
                  <ModalWrapper
                    isShow={showModal}
                    type={modalType}
                    id={id}
                    onClose={onCloseModal}
                    onOpen={onCloseModal}
                  />
                </>
              </BoxForm>
            </PermissionWrapper>
            <div className="d-flex justify-content-center m-auto">
              {id ? (
                getBool(watch('isAuthor')) ? (
                  <div className="d-flex align-items-center mt-4 ">
                    <Button
                      variant="danger"
                      className="me-4 w-btn d-none"
                      onClick={() => onToggleShowModal('delete')}
                    >
                      担当者を削除する
                    </Button>
                    <Button
                      className="w-btn btn-focus-none"
                      variant="primary"
                      onClick={() => onToggleShowModal('normal')}
                    >
                      保存する
                    </Button>
                  </div>
                ) : (
                  <Button
                    className="mt-4 w-btn me-4 btn-focus-none"
                    variant="secondary"
                    onClick={() => {
                      history.push('/artist');
                    }}
                  >
                    一覧へ戻る
                  </Button>
                )
              ) : (
                <Button
                  className="mt-4 w-btn btn-focus-none"
                  variant="primary"
                  onClick={() => onToggleShowModal('normal')}
                >
                  保存する
                </Button>
              )}
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
