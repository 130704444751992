import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { ButtonGroup, Container, Button, Row, Col } from 'react-bootstrap';
import Select, { InputActionMeta } from 'react-select';
import { useParams, useHistory } from 'react-router-dom';
import _, { find, filter, isEmpty, omit, uniqBy, toNumber } from 'lodash';
import VariationC from 'Components/Page/Title/Variation/VariationC';
import { useAppDispatch, useAppSelector } from 'App/Store';
import {
  setCurrentStep,
  addVariationAction,
  setTriggerValidate,
  setTriggerValidateNameVariation,
  setTitleForm,
  setTriggerSaveDraft,
  setTriggerMedia,
  setCaculateFee,
  setVariations,
} from 'App/Features/Title';
import { INCLUDE_JARSAC_CONTENT, msgJarsac, STEPS, VARIATION_INIT } from 'Constant/Title';
import useMessage from 'Hooks/useMessage';
import { TitleApi } from 'Datasource/TitleApi';
import { getBool } from 'Utils/Permission';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import {
  checkValidateSaveDraft,
  handleConvertSongInformationFee,
  handleConvertTitleInfo,
  handleConvertVideoInformationFee,
  randomId,
  schemaDraftVariation,
  schemaOfficialVariation,
  truncateText,
  variationFields,
} from 'Utils/Title';
import { ExclamationCircle } from 'react-bootstrap-icons';
import ReactTooltip from 'react-tooltip';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { Song, VariationFieldObj, Video, Variation } from 'Types/App/title';

interface SelectItem {
  value: number | string;
  label: string;
}

const defaultItem = [
  {
    value: 0,
    label: '新しい',
  },
];

const Variations = forwardRef((props: any, ref: any): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id }: { id: string } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const { titleForm, caculateFee, statusFetchingOptions } = useAppSelector((state) => state.title);
  const { variations } = titleForm;
  const { openMessageError, openMessage } = useMessage();
  const [variationTemporary, setVariationTemporary] = useState<SelectItem | null>(null);

  const [listError, setListError] = useState<any>([]);
  const [validateDraft, setValidateDraft] = useState<boolean>(false);
  const variationRefs = useRef<any>([]);

  //* create ref to scroll field error
  const nameRef = useRef<any>([]);
  const musicFormatRef = useRef<any>([]);
  const saleTokenDateRef = useRef<any>([]);
  const downloadDateRef = useRef<any>([]);
  const notiDownloadTimeRef = useRef<any>([]);
  const productCodeRef = useRef<any>([]);
  const janCodeRef = useRef<any>([]);
  const priceRef = useRef<any>([]);
  const coverImageUrlRef = useRef<any>([]);
  const bookletsRef = useRef<any>([]);
  const songsRef = useRef<any>([]);
  const videosRef = useRef<any>([]);
  const imagesRef = useRef<any>([]);
  const includeJasracContentRef = useRef<any>([]);
  const riajGenreIdRef = useRef<any>([]);
  const jmdCooperationRef = useRef<any>([]);
  const jmdMusicGenreRef = useRef<any>([]);

  const [next, setNext] = useState<boolean>(false);
  const {
    trigger,
    reset,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      variations: titleForm.variations,
    },
    resolver: yupResolver(validateDraft ? schemaDraftVariation() : schemaOfficialVariation()),
    mode: 'onSubmit',
  });

  useEffect(() => {
    reset(titleForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleForm]);

  const handleRemoveIds = (array: any) => {
    array = array.map((item: any) => {
      item = omit(item, ['variationId', 'dpMediaId']);
      item['id'] = randomId();
      return item;
    });
    return array;
  };

  const handleRemoveVideosIds = (videos: any) => {
    videos = videos.map((video: any) => {
      video = omit(video, ['variationId', 'dpMediaId']);
      video['songs'] = handleRemoveIds(video.songs);
      video['id'] = randomId();
      return video;
    });
    return videos;
  };

  const handleCopyVariation = () => {
    if (
      !variationTemporary ||
      variationTemporary?.value === null ||
      variationTemporary?.value === '' ||
      variationTemporary?.value === undefined
    )
      return;
    setVariationTemporary(null);
    dispatch(setTriggerValidate(false));
    dispatch(setTriggerValidateNameVariation(false));
    dispatch(setTriggerSaveDraft(false));

    const maxIndex = variations.length;

    if (variationTemporary?.label === defaultItem[0].label) {
      dispatch(
        addVariationAction({
          variation: { ...VARIATION_INIT, type: 1, name: '', index: maxIndex, id: randomId() },
        }),
      );
      return;
    }

    const exitsVariation = find(
      variations,
      (variation) => variation.name === variationTemporary?.label,
    );
    if (exitsVariation) {
      dispatch(
        addVariationAction({
          variation: {
            ...exitsVariation,
            index: maxIndex,
            type: 1,
            name: '',
            id: randomId(),
            saleTokenDate: null,
            downloadDate: null,
            notiDownloadTime: null,
            productCode: null,
            janCode: null,
            coverImageUrl: null,
            songs: handleRemoveIds(exitsVariation.songs),
            booklets: handleRemoveIds(exitsVariation.booklets),
            images: handleRemoveIds(exitsVariation.images),
            videos: handleRemoveVideosIds(exitsVariation.videos),
          },
        }),
      );
    }
  };

  const handleNextStep = async () => {
    setNext(true);
    let valid = true;
    setValidateDraft(true);
    if (!checkAuthorizedGroupType()) {
      openMessage({ variant: 'error', message: msgJarsac });
      valid = false;
    }

    if (handleCheckPriceVariations()) {
      valid = false;
    }

    if (!handleValidateNameTitle()) {
      valid = false;
    }
    if (valid) {
      dispatch(setCurrentStep(STEPS.REVIEW));
    } else {
      dispatch(setCurrentStep(STEPS.CREATE_VARIATION));
    }
  };

  const handleBackStep = () => {
    dispatch(setCurrentStep(STEPS.CREATE_TITLE_INFO));
  };

  useImperativeHandle(ref, () => ({
    handleNextStepFromParent() {
      handleNextStep();
      dispatch(setTriggerValidate(false));
    },
  }));

  const handleSaveDraft = async () => {
    const params = titleForm;
    setLoading(true);
    try {
      const {
        data: { data },
      } = await TitleApi.createDraftTitle(params);
      if (data?.id) history.push(`/title-detail/${data.id}`);
      const titleInfoData = handleConvertTitleInfo(data);
      dispatch(setTitleForm({ ...titleInfoData, isAuthor: '1' }));
      dispatch(setCurrentStep(STEPS.CREATE_TITLE_INFO));
      const titleInfo = handleConvertTitleInfo(data);
      dispatch(setTitleForm(titleInfo));
      openMessage({
        variant: 'success',
        message: '一時保存しました',
      });
    } catch (err) {
      openMessageError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateDraft = async () => {
    const params = titleForm;
    setLoading(true);
    try {
      const {
        data: { data },
      } = await TitleApi.updateDraftTitle({ id, params });
      const titleInfo = handleConvertTitleInfo(data);
      dispatch(setTitleForm(titleInfo));
      openMessage({
        variant: 'success',
        message: '一時保存しました',
      });
    } catch (err) {
      openMessageError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleValidateNameTitle = () => {
    let listErr = [];
    const dataSource = [...variations];
    const listVariationNoSettingName = _.filter(dataSource, (item) => isEmpty(item.name)).map(
      (variation) => {
        return { index: variation.index, message: '入力してください。', name: 'name' };
      },
    );

    const lookup = dataSource.reduce((duplicate: any, variation: any) => {
      duplicate[variation.name.trim()] = ++duplicate[variation.name.trim()] || 0;
      return duplicate;
    }, {});

    const ERR_DUPLICATE = '同じバリエーション名の登録はできません。';
    const nameNotBlanks = dataSource.filter((item: any) => !isEmpty(item.name));
    let duplicateNames: any[] = [];

    if (nameNotBlanks.length > 0) {
      duplicateNames = _.filter(
        nameNotBlanks,
        (variation: any) => lookup[variation?.name.trim()],
      ).map((variation: any) => {
        setError(`variations.${variation.index}.name`, {
          type: 'manual',
          message: ERR_DUPLICATE,
        });
        return {
          index: variation.index,
          message: ERR_DUPLICATE,
          name: 'name',
        };
      });
    }

    if (duplicateNames?.length > 0) {
      openMessage({
        variant: 'error',
        message: ERR_DUPLICATE,
      });
    }
    listErr = [...listVariationNoSettingName, ...duplicateNames];
    setListError(listErr);
    return !listErr.length;
  };

  const handleValidateCode = async () => {
    try {
      setLoading(true);
      const paramValidateCode = variations.map((va: any, index: number) => {
        return {
          productCode: va.productCode,
          janCode: va.janCode,
          index,
          id: va?.id && va?.id?.toString()?.includes('uuid') ? null : va?.id,
        };
      });

      const paramValidateCodeTitle = titleForm.variations.map((va: any, index: number) => {
        return {
          productCode: va.productCode,
          janCode: va.janCode,
          index,
          id: va?.id && va?.id?.toString()?.includes('uuid') ? null : va?.id,
        };
      });

      //! FIXME: HAS CODE -> check duplicate producCode, janCode in status === 0
      const lookupProductCode = paramValidateCodeTitle.reduce((a: any, e: any) => {
        a[e.productCode] = ++a[e.productCode] || 0;
        return a;
      }, {});
      const lookupJanCode = paramValidateCodeTitle.reduce((a: any, e: any) => {
        a[e.janCode] = ++a[e.janCode] || 0;
        return a;
      }, {});

      const errProductCode = paramValidateCodeTitle.filter(
        (e: any) => lookupProductCode[e.productCode],
      );
      const errJanCode = paramValidateCodeTitle.filter((e: any) => lookupJanCode[e.janCode]);

      if (errProductCode.length > 0 || errJanCode.length > 0) {
        const listErrorProducts = errProductCode.map((el: any) => {
          setError(`variations.${el.index}.productCode`, {
            type: 'manual',
            message: '品番は既に存在しています。',
          });
          return {
            index: el.index,
            message: el.productCode ? '品番は既に存在しています。' : '入力してください。',
            name: 'productCode',
          };
        });
        const listErrorJan = errJanCode.map((el: any) => {
          setError(`variations.${el.index}.janCode`, {
            type: 'manual',
            message: 'JANコードは既に存在しています。',
          });
          return {
            index: el.index,
            message: el.janCode ? 'JANコードは既に存在しています。' : '入力してください。',
            name: 'janCode',
          };
        });

        setListError([...listErrorProducts, ...listErrorJan]);
        return errProductCode.length > 0 || errJanCode.length > 0;
      }
      //////////////////////////

      const {
        data: { data },
      } = await TitleApi.validateCode({ variationValidationDtos: paramValidateCode, titleId: +id });

      if (data?.length > 0) {
        const listErrorCode = data.map((el: any) => {
          if (Object.keys(el)[0] === 'janCode') {
            setError(`variations.${el.index}.janCode`, {
              type: 'manual',
              message: 'JANコードは既に存在しています。',
            });
            return {
              index: el.index,
              message: 'JANコードは既に存在しています。',
              name: 'janCode',
            };
          } else {
            setError(`variations.${el.index}.productCode`, {
              type: 'manual',
              message: '品番は既に存在しています。',
            });
            return {
              index: el.index,
              message: '品番は既に存在しています。',
              name: 'productCode',
            };
          }
        });

        setListError(listErrorCode);
      }
      return data?.length > 0;
    } catch (err) {
      openMessageError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveOrUpdateDraft = async () => {
    dispatch(setTriggerSaveDraft(false));
    dispatch(setTriggerValidate(false));
    clearErrors();
    setValidateDraft(true);

    // CHECK DUPLICATE NAME WHEN TYPE === 1
    if (!handleValidateNameTitle()) {
      trigger();
      return;
    }
    // CHECK VALIATE SAVE DRAFT
    const listErrorDraft = await checkValidateSaveDraft(titleForm);

    if (listErrorDraft.length > 0) {
      trigger();
      if (listErrorDraft[0].path === 'name') {
        dispatch(setCurrentStep(STEPS.CREATE_TITLE_INFO));
      }
      dispatch(setTriggerSaveDraft(true));
      return;
    }

    if (await handleValidateCode()) {
      trigger();
      return;
    }

    if (!checkAuthorizedGroupType()) {
      openMessage({ variant: 'error', message: msgJarsac });
      return;
    }
    if (handleCheckPriceVariations()) return;
    if (!titleForm?.status) {
      id ? handleUpdateDraft() : handleSaveDraft();
      return;
    }
    openMessage({
      variant: 'error',
      message: '更新が失敗しました',
    });
  };

  const handleRenderVariationOptions = () => {
    let options: any = [...variations];
    options = options.map((option: any, index: number) => ({
      value: index + 1,
      label: option.name || '',
    }));

    options = filter(options, (option) => option.label);
    options = [...defaultItem, ...options];
    return options;
  };

  const handleDisable = () => {
    let disabled = false;
    if (!id) disabled = false;
    if (id && !getBool(titleForm?.isAuthor)) disabled = true;
    if (titleForm?.status) disabled = true;
    return disabled;
  };

  useEffect(() => {
    return () => {
      dispatch(setTriggerValidateNameVariation(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckValidate = async () => {
    dispatch(setTriggerValidate(false));
    clearErrors();
    setValidateDraft(false);
    await trigger();
    dispatch(setTriggerValidate(true));
    trigger();
    handleScrollErrors();
    if (!handleValidateNameTitle()) return;
    if (await handleValidateCode()) return;
    if (!checkAuthorizedGroupType()) {
      openMessage({ variant: 'error', message: msgJarsac });
      return;
    }
    if (handleCheckPriceVariations()) return;
  };

  const handleScrollErrors = useCallback(() => {
    if (errors?.variations && !isEmpty(errors.variations)) {
      const errorIndex = +Object.keys(errors.variations)[0] || 0;
      const formKeys = Object.keys(Object.values(errors.variations)[0] as any);
      const listErrorNames = listError.map((item: any) => item.name);

      const errorMap = uniqBy([...listErrorNames, ...formKeys], function (e) {
        return e;
      });

      const filterErrorMap = variationFields()
        .filter((item: VariationFieldObj) => errorMap.includes(item.name))
        .sort((a: VariationFieldObj, b: VariationFieldObj) => a.index - b.index);

      const formRefs: any = {
        name: nameRef,
        includeJasracContent: includeJasracContentRef,
        musicFormat: musicFormatRef,
        saleTokenDate: saleTokenDateRef,
        downloadDate: downloadDateRef,
        notiDownloadTime: notiDownloadTimeRef,
        productCode: productCodeRef,
        janCode: janCodeRef,
        price: priceRef,
        coverImageUrl: coverImageUrlRef,
        booklets: bookletsRef,
        songs: songsRef,
        videos: videosRef,
        images: imagesRef,
        jmdCooperation: jmdCooperationRef,
        riajGenreId: riajGenreIdRef,
        jmdMusicGenre: jmdMusicGenreRef,
      };

      const errorKey: string = filterErrorMap[0]?.name || '';

      if (filterErrorMap[0]?.name === 'songs') {
        dispatch(setTriggerMedia('songs'));
        return;
      }

      if (filterErrorMap[0]?.name === 'videos') {
        dispatch(setTriggerMedia('videos'));
        return;
      }

      if (filterErrorMap[0]?.name === 'images') {
        dispatch(setTriggerMedia('images'));
        return;
      }

      dispatch(setTriggerMedia(null));

      formRefs[errorKey as any]?.current[errorIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
      return;
    }
  }, [errors, listError]);

  //* validate price variation
  const handleCheckPriceVariations = () => {
    let errorPrices: number[] = [];
    variations.forEach((variation: any, variationIndex: number) => {
      const firstPrice = variations[0]?.price ? +variations[0]?.price : 0;
      const variationPrice = variation?.price ? +variation?.price : 0;

      if (variation?.price) {
        if (variationPrice < firstPrice) {
          setError(`variations.${variationIndex}.price`, {
            type: 'manual',
            message: `${truncateText(titleForm?.artistName || '', 32)} / ${truncateText(
              titleForm?.name || '',
              32,
            )} / ${truncateText(variations[0]?.name || '', 32)} 以上の金額に設定してください。`,
          });
          errorPrices.push(variationIndex);
        } else {
          clearErrors(`variations.${variationIndex}.price`);
          errorPrices = errorPrices.filter((item: number) => item !== variationIndex);
        }
      }
    });

    if (errorPrices.length > 0) {
      priceRef.current[errorPrices[0]]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
    return errorPrices.length > 0;
  };

  useEffect(() => {
    if (!isEmpty(errors)) {
      handleScrollErrors();
    }
  }, [errors, handleScrollErrors, trigger]);

  useEffect(() => {
    if (listError.length > 0 && next) {
      variationRefs.current[listError[0].index]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
      setNext(false);
    }
  }, [listError, next]);

  useEffect(() => {
    [...listError].forEach((item: any) => {
      if (item.name === 'name') {
        setError(`variations.${item.index}.name`, { type: 'manual', message: item.message });
      }

      if (item.name === 'productCode') {
        setError(`variations.${item.index}.productCode`, { type: 'manual', message: item.message });
      }

      if (item.name === 'janCode') {
        setError(`variations.${item.index}.janCode`, { type: 'manual', message: item.message });
      }
    });
  }, [listError]);

  useEffect(() => {
    //* reset trigger media when unmount
    return () => {
      dispatch(setTriggerMedia(null));
    };
  }, []);

  //* handle caculate informationFee
  const caculateInformationFee = useCallback(async () => {
    const isValidPrice = variations.every(
      (variation: Variation) => variation.price && toNumber(variation.price) !== 0,
    );

    if (!isValidPrice) {
      dispatch(setCaculateFee(false));
      return;
    }

    try {
      const variationPayload: any[] = variations?.map((val: Variation) => {
        const songPayloads = handleConvertSongInformationFee(val?.songs || []);
        const videoPayloads = handleConvertVideoInformationFee(val?.videos || []);

        return {
          isNormalVersion: val.index === 0,
          index: val.index,
          price: val?.price ? toNumber(val.price) : 0,
          songs: songPayloads,
          videos: videoPayloads,
          includeJasracContent: val.includeJasracContent,
        };
      });

      const {
        data: { data },
      } = await TitleApi.caculateInformationFee({ variations: variationPayload });
      if (Array.isArray(data) && data.length > 0) {
        const dataSorted = [...data].sort((a: any, b: any) => a.index - b.index);

        const newVariations: Variation[] = variations?.map(
          (variation: any, variationIndex: number) => {
            const songs: Song[] = variation?.songs?.map((song: Song, songIndex: number) => {
              const dataSongs = !isEmpty(dataSorted[variationIndex]?.songs[songIndex])
                ? dataSorted[variationIndex]?.songs[songIndex]
                : [];
              let informationFee: number | string = '';
              if (dataSongs?.songInformationFee) {
                informationFee =
                  toNumber(dataSongs?.songInformationFee) < 100
                    ? 100
                    : toNumber(dataSongs?.songInformationFee);
              }

              let lyricInformationFee: number | string = '';
              if (dataSongs?.lyricInformationFee) {
                lyricInformationFee =
                  toNumber(dataSongs?.lyricInformationFee) < 100
                    ? 100
                    : toNumber(dataSongs?.lyricInformationFee);
              }

              return {
                ...song,
                informationFee:
                  variation?.includeJasracContent !== INCLUDE_JARSAC_CONTENT.INCLUDE
                    ? song?.informationFee
                    : toNumber(informationFee),
                lyricInformationFee:
                  variation?.includeJasracContent !== INCLUDE_JARSAC_CONTENT.INCLUDE
                    ? song?.lyricInformationFee
                    : toNumber(lyricInformationFee),
              };
            });

            const videos: Video[] = variation?.videos?.map((video: Video, videoIndex: number) => {
              const dataVideos = !isEmpty(dataSorted[variationIndex]?.videos[videoIndex])
                ? dataSorted[variationIndex]?.videos[videoIndex]
                : [];

              const dataSongs = !isEmpty(dataVideos?.songs) ? dataVideos?.songs : [];

              const songVideos = video?.songs?.map((song: Song, songIndex: number) => {
                let fee: number | string = '';
                if (dataSongs[songIndex]?.songInformationFee) {
                  fee = toNumber(dataSongs[songIndex]?.songInformationFee);
                }
                return {
                  ...song,
                  informationFee:
                    variation?.includeJasracContent !== INCLUDE_JARSAC_CONTENT.INCLUDE
                      ? song?.informationFee
                      : toNumber(fee),
                };
              });
              const mediaFee = toNumber(dataVideos?.mediaInformationFee);
              return {
                ...video,
                mediaInformationFee:
                  variation?.includeJasracContent !== INCLUDE_JARSAC_CONTENT.INCLUDE
                    ? video?.mediaInformationFee
                    : mediaFee || '',
                songs: Array.isArray(songVideos) ? songVideos : [],
              };
            });
            return {
              ...variation,
              songs: Array.isArray(songs) ? songs : [],
              videos: Array.isArray(videos) ? videos : [],
            };
          },
        );

        dispatch(setVariations({ variations: newVariations }));
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      dispatch(setCaculateFee(false));
    }
  }, [variations]);

  //* trigger when Variation blur input price
  useEffect(() => {
    if (caculateFee) caculateInformationFee();
  }, [caculateFee]);

  //* validate if includeJarsacContent === INCLUDE && songs must have at least 1 song with jarsac or nextone
  const checkAuthorizedGroupType = (): boolean => {
    const isPassed = variations.every((item: Variation) => {
      const songVideos = item?.videos?.flatMap((video: Video) => (video.songs ? video.songs : []));
      const songs = item?.songs ? item.songs : [];
      const songList = [...songVideos, ...songs];
      const songHasJarsacOrNextone = songList.filter(
        (song: Song) =>
          song.authorizedGroupType && [1, 2].includes(Number(song.authorizedGroupType)),
      );
      const isValid =
        (item.includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE &&
          !isEmpty(songHasJarsacOrNextone) &&
          (!isEmpty(songs) || !isEmpty(songVideos))) ||
        item.includeJasracContent === INCLUDE_JARSAC_CONTENT.NOT_INCLUDE;

      return isValid;
    });
    return isPassed;
  };

  const loadingFetchOptionVariation: boolean = useMemo(() => {
    const rs = statusFetchingOptions.some((o: any) => o.status === true);
    return rs;
  }, [statusFetchingOptions]);

  return (
    <>
      <SpinnerComponent isLoading={loading || loadingFetchOptionVariation} />
      <Container className="my-2">
        {variations.map((variation: any, index: number) => (
          <div key={variation?.id} ref={(el) => (variationRefs.current[index] = el)}>
            <VariationC
              variationIndex={index}
              variation={variation}
              dataError={listError}
              listError={
                errors?.variations && !isEmpty(errors.variations) ? errors.variations[index] : {}
              }
              nameRef={(el: any) => (nameRef.current[index] = el)}
              musicFormatRef={(el: any) => (musicFormatRef.current[index] = el)}
              jmdCooperationRef={(el: any) => (jmdCooperationRef.current[index] = el)}
              saleTokenDateRef={(el: any) => (saleTokenDateRef.current[index] = el)}
              downloadDateRef={(el: any) => (downloadDateRef.current[index] = el)}
              notiDownloadTimeRef={(el: any) => (notiDownloadTimeRef.current[index] = el)}
              productCodeRef={(el: any) => (productCodeRef.current[index] = el)}
              janCodeRef={(el: any) => (janCodeRef.current[index] = el)}
              priceRef={(el: any) => (priceRef.current[index] = el)}
              coverImageUrlRef={(el: any) => (coverImageUrlRef.current[index] = el)}
              bookletsRef={(el: any) => (bookletsRef.current[index] = el)}
              songsRef={(el: any) => (songsRef.current[index] = el)}
              videosRef={(el: any) => (videosRef.current[index] = el)}
              imagesRef={(el: any) => (imagesRef.current[index] = el)}
              includeJasracContentRef={(el: any) => (includeJasracContentRef.current[index] = el)}
              riajGenreIdRef={(el: any) => (riajGenreIdRef.current[index] = el)}
              jmdMusicGenreRef={(el: any) => (jmdMusicGenreRef.current[index] = el)}
            />
          </div>
        ))}
        <Row>
          <Col md={{ span: 7, offset: 5 }} className="p-0">
            <ButtonGroup className="w-100 pt-4">
              <Select
                value={variationTemporary}
                options={handleRenderVariationOptions()}
                className="flex-1 placeholder-text-center"
                placeholder={
                  <div className="text-center w-100 gray">
                    [登録済みのバリエーション名]をコピーして or [新しい]
                  </div>
                }
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  placeholder: () => ({
                    flex: '1',
                  }),
                }}
                onChange={(value) => setVariationTemporary(value)}
                noOptionsMessage={() => '検索結果がありません。'}
                isClearable
                onInputChange={(text: string, action: InputActionMeta) => {
                  if (action.action === 'input-change') {
                    setVariationTemporary({
                      value: variationTemporary?.value || '',
                      label: text || '',
                    });
                  }
                }}
              />
              <Button className="flex-0 btn-focus-none" onClick={handleCopyVariation}>
                バリエーションを追加する
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <div className="d-flex align-items-center justify-content-center mt-4 pb-4">
          <ReactTooltip id={'title-step-1'} place="top" effect="solid" className="h-35 text-center">
            マスタタイトルの登録に必須となる入力を確認します
          </ReactTooltip>
          <Button
            variant="primary"
            className="btn-action btn-focus-none me-5 btn-valid-title"
            onClick={handleCheckValidate}
          >
            入力内容を確認する
            <ExclamationCircle
              size={20}
              className="ms-2 outline-none"
              data-tip
              data-for={'title-step-1'}
            />
          </Button>
          <Button className="me-5 btn-action btn-back-title" onClick={() => handleBackStep()}>
            前のステップへ戻る
          </Button>
          <Button
            className="me-5 btn-action btn-focus-none"
            disabled={handleDisable()}
            onClick={handleSaveOrUpdateDraft}
          >
            ⼀時保存する
          </Button>
          <Button className="btn-action btn-focus-none" onClick={() => handleNextStep()}>
            次のステップへ進む
          </Button>
        </div>
      </Container>
    </>
  );
});

export default Variations;
