/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import BoxFormEc from 'Components/Common/EC/BoxForm';
import ECCkeditor from 'Components/Page/EC/ECCkeditor';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import ModalComponent from 'Components/Common/Modal';
import { useForm } from 'react-hook-form';
import { SiteBasicSettingEc } from 'Datasource/SettingEc';
import useMessage from 'Hooks/useMessage';
import MessageError from 'Components/Common/MessageError';

export default function CommercialLaw(): JSX.Element {
  const [showModal, setShowModal] = useState(false);
  const [commercialLawData, setCommercialLawData] = useState<any>();
  const [isFetchedData, setIsFetchedData] = useState<boolean>(false);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const { openMessageError } = useMessage();

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });

  const onCommercialLawChange = (event: any) => {
    setValue('commercialLaw', event.editor.getData());
  };

  const onSubmit = handleSubmit(() => {
    if (watch('commercialLaw') == '') {
      setError('入力してください。');
    } else {
      setError('');
      setShowModal(true);
    }
  });

  const handleFetchSettingData = async () => {
    try {
      setIsloading(true);
      const {
        data: { data },
      } = await SiteBasicSettingEc.getSetting(3);
      setValue('commercialLaw', data?.content);
      setCommercialLawData(data);
      setIsFetchedData(true);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsloading(false);
    }
  };

  const onSubmitModal = async () => {
    setIsloading(true);
    const param = {
      type: 3,
      content: watch('commercialLaw'),
    };
    try {
      setShowModal(false);
      await SiteBasicSettingEc.updateDocument(param);
    } catch (error) {
      openMessageError(error, '内容を入力してください。');
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    handleFetchSettingData();
  }, []);

  return (
    <Container className="py-4">
      <SpinnerComponent isLoading={isLoading} />
      <ModalComponent
        title="登録確認"
        show={showModal}
        onCloseModal={() => setShowModal(false)}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="primary"
        submitIdForm="curatorManagement"
        onSubmitModal={onSubmitModal}
        confirmText="保存する"
      >
        <div>入力した内容で保存します。よろしいですか。</div>
      </ModalComponent>
      <Row>
        <Col className="m-auto" md="11">
          <Form onSubmit={onSubmit} id="web-setting">
            <BoxFormEc classBox="text-white" title="特定商取引法に基づく表示">
              <div className="w-100">
                <Row className="w-100">
                  {isFetchedData ? (
                    <ECCkeditor
                      initData={commercialLawData?.content}
                      onEditorChange={onCommercialLawChange}
                      name="commercialLaw"
                    />
                  ) : null}
                </Row>
              </div>
              {error ? <MessageError message={error} /> : <div></div>}
              <div className="text-center mt-4">
                <Button type="submit" className="px-5 btn-equal-width" variant="primary">
                  保存する
                </Button>
              </div>
            </BoxFormEc>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
