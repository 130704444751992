/* eslint-disable react/prop-types */
import React, { memo, useEffect, useState } from 'react';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { filter, isEmpty } from 'lodash';
import { calcMb, checkIfFilesAreCorrectType, checkImgSquareAndDimension } from 'Utils/File';
import MessageError from 'Components/Common/MessageError';
import { CapacityTypes } from 'Types/App/title';
import { useAppDispatch, useAppSelector } from 'App/Store';
import { X } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';
import { setBooklets } from 'App/Features/Title';
import { checklNumberRemainCapacity } from 'Utils/Title';
import { TYPE_MEDIA } from 'Constant';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { UploadMediaApi } from 'Datasource/UploadMedia';
import useMessage from 'Hooks/useMessage';
import { toast } from 'react-toastify';

type ItemType = {
  title: string;
  imageUrl: string;
  id: number;
  index: number;
  size: number;
  type: string;
  file: any;
};

const ImageComponent = memo(({ imageUrl }: { imageUrl: string }) => {
  //Only hash code
  return <img src={`${process.env.REACT_APP_STATIC_URL}${imageUrl}`} alt="" />;
});

const Booklet = (props: any): JSX.Element => {
  const dispatch = useAppDispatch();
  const [items, setItems] = useState<ItemType[]>([]);
  const [isDrag, setIsDrag] = useState(false);
  const [error, setError] = useState<string>('');
  const {
    titleForm: { variations },
  } = useAppSelector((state) => state.title);
  const { openMessageError } = useMessage();

  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setItems(props?.listBooklets || variations[props.variationIndex]?.booklets);
  }, [props]);

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const dataSource = [...arrayMove(items, oldIndex, newIndex)];
    setItems(dataSource);
    const newItems = dataSource.map((el: any, index: number) => {
      return { ...el, index: index };
    });
    dispatch(setBooklets({ booklets: newItems, variationIndex: props.variationIndex }));
  };

  const handleDeleteItem = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any) => {
    const { index, id } = item;
    e.preventDefault();
    e.stopPropagation();
    const dataSource = [...items];
    const newItems = filter(dataSource, (item) => (id ? item.id !== id : item.index !== index));
    setItems(newItems);
    dispatch(setBooklets({ booklets: newItems, variationIndex: props.variationIndex }));
  };

  const SortableItem = SortableElement(({ item }: { item: ItemType }) => (
    <div className="inner-item position-relative">
      <Button
        hidden={props?.isReview}
        className="position-absolute bg-dark-1 p-0 border-0"
        style={{ top: 5, right: 5 }}
        onClick={(e) => handleDeleteItem(e, item)}
      >
        <X size={25} />
      </Button>
      <ImageComponent imageUrl={item?.imageUrl} />

      <p>{`${calcMb(item?.size)}MB`}</p>
    </div>
  ));

  const SortableList = SortableContainer(({ items }: { items: ItemType[] }) => (
    <div className="booklet-container rounded">
      {items.map((item: ItemType, index: number) => {
        return <SortableItem key={`${item.id}`} index={index} item={item} />;
      })}
    </div>
  ));

  const handleCheckValidFile = async (file: FileWithPath, index: number) => {
    const isSquareImg = await checkImgSquareAndDimension(file as File, 600);

    if (!isSquareImg) {
      setError('画像サイズは縦横６００ｘ６００ピクセルの画像を登録してください。');
      return { isErr: true };
    }
    const indexitem = items.length + index + 1;
    const item = {
      title: `Item ${indexitem}`,
      index: indexitem,
      imageUrl: URL.createObjectURL(file),
      size: file?.size,
      type: CapacityTypes.Booklet,
      file,
    };
    return { isErr: false, item };
  };

  const uploadBooklets = async (files: ItemType[]) => {
    try {
      setLoading(true);
      const formData: FormData = new FormData();
      formData.append('type', TYPE_MEDIA.VARIATIONS.toString());
      {
        files.map((el: ItemType) => {
          formData.append('files', el.file);
        });
      }

      const { data } = await UploadMediaApi.upLoadBookletsMedia(formData);
      const resultBooklets = files.map((el: ItemType, index: number) => {
        delete el['file'];
        return { ...el, imageUrl: data[index] };
      });

      return resultBooklets;
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
    return [];
  };

  const handleOnDrop = async (files: FileWithPath[]) => {
    const dataSource = [...items];

    if (files?.length === 0) {
      toast('画像タイプはjpeg, pngまたはgifの画像を登録してください。', { type: 'error' });
      return;
    }
    if (error) setError('');
    const fileLength = files.length + dataSource.length;
    // HANDLE VALIDATE
    if (fileLength > 24) {
      setError('ブックレット画像 は24枚以上のアップロードができません。');
      setIsDrag(false);
      return;
    }

    let tmpFile: any = [];
    for (let i = 0; i < files.length; i++) {
      const isValidType = checkIfFilesAreCorrectType(files[i] as File);

      if (!isValidType) {
        return;
      }
      const { isErr, item } = await handleCheckValidFile(files[i], i);
      if (!isErr) {
        tmpFile = [...tmpFile, item];
      }
    }

    // HANDLE CHECK MAX CAPACITY
    const sizeBookletNew = tmpFile.reduce(
      (total: number, value: ItemType) => (total = total + (value?.size || 0)),
      0,
    );
    if (checklNumberRemainCapacity(variations[props.variationIndex], sizeBookletNew)) return;

    // HANDLE UP FROM API
    const data = await uploadBooklets(tmpFile);

    const result = [...dataSource, ...data];
    setItems(result);
    dispatch(setBooklets({ booklets: result, variationIndex: props.variationIndex }));
    setIsDrag(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png,image/gif',
    onDrop: handleOnDrop,
  });

  return (
    <div>
      <SpinnerComponent isLoading={loading} />
      {isDrag || isEmpty(items) ? (
        <div
          style={{ minHeight: '200px' }}
          {...getRootProps({
            className: `border rounded d-flex align-items-center justify-content-center ${
              props.isReview && 'pe-none'
            }`,
          })}
        >
          <input {...getInputProps()} />
          <p className="gray">画像ファイルをドロップしてください</p>
        </div>
      ) : (
        <div
          className="border rounded"
          style={{ minHeight: '200px' }}
          onDragEnter={() => setIsDrag(true)}
          onClick={() => setError('')}
          aria-hidden="true"
        >
          <SortableList
            items={items}
            onSortEnd={onSortEnd}
            axis="xy"
            distance={2}
            helperClass={`SortableHelper`}
          />
        </div>
      )}
      <MessageError message={error} key={error} />
    </div>
  );
};

export default memo(Booklet);
