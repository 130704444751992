/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-case-declarations */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Image, Button, Placeholder } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import nextId from 'react-id-generator';
import BoxForm from 'Components/Common/Form/BoxForm';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import GroupCheckBox from 'Components/Common/Form/GroupCheckBox';
import ItemDigital from 'Components/Page/DigitalPrivilege/itemDigital';
import {
  IDatePicker,
  IDigitalUploadFile,
  IDataFormTokutenUploads,
  IListTokutenCommon,
} from 'Types/Common';
import FormGroupDate from 'Components/Common/Form/FormGroupDate';
import ModalComponent from 'Components/Common/Modal';
import MessageError from 'Components/Common/MessageError';

import { PromotionMangagerApi } from 'Datasource/PromotionManager';
import { UploadMediaApi } from 'Datasource/UploadMedia';
import { TYPE_MEDIA, TOKUTEN_TYPE_MEDIA } from 'Constant';
import { checkIfFilesGeneralAreCorrectType } from 'Utils/FileGeneral';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import FormGroupDateRange from 'Components/Common/Form/FormGroupDateRange';
import useMessage from 'Hooks/useMessage';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import { isAfterDate } from 'Utils/DateTime';
import {
  IMediaResponse,
  IMediaUploads,
  IDataForm,
  defaultValue,
  IProps,
} from 'Types/Page/PromotionManager';

import 'Page/PromotionManager/index.scss';
import { exportToExcel, sheetToJson } from 'Utils/SheetJs';
import ListSong from 'Components/Page/DigitalPrivilege/ListSong';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import FormGroupTextArea from 'Components/Common/Form/FormGroupTextArea';
import PullDownSearch from 'Components/Common/PullDownSearch';
import { formListSongDefault } from 'Constant/Song';

const DATA_DOWNLOAD: any = [];

const validateDigitalMain = {
  required: {
    message: '入力してください。',
    value: true,
  },
  maxLength: {
    message: '',
    value: 120,
  },
};

const defaultCheckboxOptions: {
  label: string;
  subLabel: string;
  checked: boolean;
  value: number | string;
}[] = [
  {
    label: '共通コンテンツ',
    subLabel: '選択しているバリエーション全てに設定される特典です',
    checked: false,
    value: '0',
  },
  {
    label: '個別コンテンツ',
    subLabel: '個別コンテンツの追加により販売商品の組み合わせを増やすことができます',
    checked: false,
    value: '1',
  },
];

export default function DigitalPrivilege(props: IProps): JSX.Element {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' });

  const digitalImage = watch('artistImage') ?? [];
  const digitalAudio = watch('artistAudio') ?? [];
  const digitalVideo = watch('artistVideo') ?? [];
  const [isOnlyView, setIsOnlyView] = useState<boolean>(true);
  const { openMessageError } = useMessage();
  const { id } = useParams<{ id: string }>();
  const convertTypeMedia = (objMedias: IDigitalUploadFile[], typeMedia: string) => {
    return objMedias
      .filter((item: IDigitalUploadFile) => item.type == typeMedia)
      .map((item: IDigitalUploadFile) => {
        return {
          content: item.description,
          URL: handleConnectStaticEndPoint(item?.url ?? ''),
          type: item.type,
          id: item.id,
          title: item.title ?? '',
        };
      });
  };

  const [loading, setLoading] = useState<boolean>(false);

  const [pullDownLabel, setPullDownLabel] = useState({
    artist: '',
    title: '',
    variation: '',
  });

  const [minDateStart, setMinDateStart] = useState<Date | string>(() => new Date());

  const [artistID, setArtistID] = useState<number | undefined>();
  const [titleID, setTitleID] = useState<number | undefined>();

  const [isLoadingBoxDigitalImage, setIsLoadingBoxDigitalImage] = useState<boolean>(false);
  const [valueDefault, setValueDefault] = useState<defaultValue>();
  const [sttPullDownTitle, setSttPullDownTitle] = useState<boolean>(true);
  const [sttPullDownVariation, setSttPullDownVariation] = useState<boolean>(true);
  const [digitalError, setDigitalError] = useState<boolean>(true);
  const [modalConfirmImage, setShowModalConfirmImage] = useState<boolean>(false);
  const [modalConfirmAudio, setShowModalConfirmAudio] = useState<boolean>(false);
  const [modalConfirmVideo, setShowModalConfirmVideo] = useState<boolean>(false);
  const [modalSubmitForm, setShowModalSubmitForm] = useState<boolean>(false);

  const [imgRemoveID, setImgRemoveID] = useState<number>(0);
  const [audioRemoveID, setAudioRemoveID] = useState<number>(0);

  const [digitalCommons, setDigitalCommons] = useState<IListTokutenCommon[]>([]);

  const [tokutenName, setTokutenName] = useState('');
  const [ECPromotion, setECPromotion] = useState('');

  const [dataPost, setDataPost] = useState<IDataForm>({
    ECPromotion: '',
    Variation: '',
    artist: '',
    artistAudio: [],
    artistImage: [],
    artistVideo: [],
    dateDownload: undefined,
    digitalImage: undefined,
    registrationType: '',
    endSalesDate: undefined,
    startSalesDate: undefined,
    tokutenName: '',
    daySales: {
      startSalesDate: undefined,
      endSalesDate: undefined,
    },
  });

  const watchEndSalesDate = watch('daySales.endSalesDate');
  const watchStartSalesDate = watch('daySales.startSalesDate');
  const watchDLDate = watch('dateDownload');
  const watchArtistImage = watch('artistImage');
  const watchArtistVideo = watch('artistVideo');
  const watchArtistAudio = watch('artistAudio');

  const [warning, setWarning] = useState({
    audio: '',
    video: '',
    image: '',
  });

  const handleChangeDate = (date: Date | null | IDatePicker, type: string) => {
    const currentDate = new Date(minDateStart);
    const dateDownLoad = new Date(getValues('dateDownload'));

    if (type === 'startSaleDate') {
      setValue('daySales.startSalesDate', date);
      const startSaleDate = new Date(getValues('daySales.startSalesDate'));
      const endSaleDate = new Date(getValues('daySales.endSalesDate'));
      const dlDate = new Date(getValues('dateDownload'));
      if (date && isAfterDate(currentDate, startSaleDate)) {
        setValue('daySales.startSalesDate', currentDate);
      }
      if ((dlDate && isAfterDate(startSaleDate, dateDownLoad)) || !dlDate) {
        setValue(
          'dateDownload',
          date && isAfterDate(startSaleDate, new Date()) ? date : new Date(),
        );
      }
      if ((dlDate && isAfterDate(startSaleDate, endSaleDate)) || !date) {
        setValue('daySales.endSalesDate', '');
      }
    }
    if (type === 'endSaleDate') {
      setValue('daySales.endSalesDate', date);
      const startSaleDate = new Date(getValues('daySales.startSalesDate'));
      const endSaleDate = new Date(getValues('daySales.endSalesDate'));
      if (date && isAfterDate(startSaleDate, endSaleDate)) {
        setValue('daySales.endSalesDate', startSaleDate);
      }
    }
    if (type === 'dateDownload') {
      const startSaleDate = getValues('daySales.startSalesDate');
      if (date) {
        if (startSaleDate && isAfterDate(startSaleDate, date as Date)) {
          setValue('dateDownload', startSaleDate);
        } else {
          setValue('dateDownload', isAfterDate(date as Date, new Date()) ? date : new Date());
        }
      } else {
        setValue('dateDownload', '');
      }
    }
  };

  useEffect(
    () => {
      const handleGetDataDetail = async () => {
        if (id) {
          try {
            setLoading(true);
            const responseDataDetail = await PromotionMangagerApi.getDetailPromotion(id);
            const {
              digital_tokutens_name,
              artists_id,
              artists_name,
              titles_id,
              titles_name,
              variations_id,
              variations_name,
              intro_ec,
              isAuthor,
              download_date,
              sale_end_date,
              sale_start_date,
              content_type,
              digitalTokutenMedia,
              digitalTokutenCommons,
              variations_download_date,
            } = responseDataDetail.data.data;

            setValue('tokutenName', digital_tokutens_name);
            setValue('ECPromotion', intro_ec);
            setDigitalCommons(digitalTokutenCommons);
            setValue('registrationType', content_type.toString());
            setValue('dateDownload', new Date(download_date));
            setValue('daySales.startSalesDate', new Date(sale_start_date));
            setValue('daySales.endSalesDate', new Date(sale_end_date));
            setValue('artist', artists_id);
            setValue('title', titles_id);
            setValue('Variation', variations_id);
            setMinDateStart(
              isAfterDate(new Date(sale_start_date), new Date())
                ? new Date()
                : new Date(sale_start_date),
            );
            setPullDownLabel({
              ...pullDownLabel,
              artist: artists_name,
              title: titles_name,
              variation: variations_name,
            });

            setTokutenName(digital_tokutens_name);
            setECPromotion(intro_ec);
            if (
              isAuthor === '1' &&
              isAfterDate(new Date(variations_download_date), new Date()) &&
              isAfterDate(new Date(download_date), new Date())
            ) {
              setIsOnlyView(false);
            }

            const listImageDigitals = convertTypeMedia(
              digitalTokutenMedia,
              TOKUTEN_TYPE_MEDIA.IMAGE.toString(),
            );
            setValue('artistImage', listImageDigitals);

            const listAudioDigitals = convertTypeMedia(
              digitalTokutenMedia,
              TOKUTEN_TYPE_MEDIA.AUDIO.toString(),
            );
            setValue('artistAudio', listAudioDigitals);

            const listVideoDigitals = convertTypeMedia(
              digitalTokutenMedia,
              TOKUTEN_TYPE_MEDIA.VIDEO.toString(),
            );
            setValue('artistVideo', listVideoDigitals);

            setSttPullDownTitle(false);
            setSttPullDownVariation(false);

            setLoading(false);
          } catch (error) {
            setLoading(false);
            openMessageError(error);
          }
        } else {
          setValue('daySales', {
            startSalesDate: undefined,
            endSalesDate: undefined,
          });
          setMinDateStart(new Date());
          reset(dataPost);
          setDigitalCommons([]);
          setPullDownLabel({
            ...pullDownLabel,
            artist: '',
            title: '',
            variation: '',
          });
        }
      };
      handleGetDataDetail();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, setValue, openMessageError],
  );

  useEffect(() => {
    if (watchEndSalesDate && watchStartSalesDate) {
      setValue('daySales.endSalesDate', watchEndSalesDate);
      setValue('daySales.startSalesDate', watchStartSalesDate);
      setValue('dateDownload', watchDLDate);
    }
  }, [setValue, watchEndSalesDate, watchStartSalesDate, watchDLDate]);

  useEffect(() => {
    if (watchArtistImage?.length || watchArtistAudio?.length || watchArtistVideo?.length) {
      setDigitalError(true);
    }
  }, [watchArtistImage, digitalError, watchArtistAudio, watchArtistVideo]);

  useEffect(() => {
    if (isLoadingBoxDigitalImage) {
      setTimeout(() => {
        setIsLoadingBoxDigitalImage(false);
      }, 1000);
    }
  }, [isLoadingBoxDigitalImage]);

  const onSubmit = (data: any) => {
    setWarning({ ...warning, image: '', video: '', audio: '' });
    if (isAfterDate(new Date(), data.dateDownload as Date)) {
      openMessageError({
        response: { data: { message: ['DL可能開始のは未来の⽇時でなければなりません'] } },
      });
      return;
    }
    setShowModalSubmitForm(true);
    setDataPost(data);
  };

  const uploadMediaCommon = async (
    dataMedia: IMediaUploads[],
    typeMedia: number,
    data: IDataForm,
  ) => {
    let listResponseMedia: any = [];
    const oldListResponseMedia: any = [];

    if (!isOnlyView && id) {
      dataMedia.forEach((itemMedia) => {
        if (!itemMedia.isNew) {
          oldListResponseMedia.push({
            id: itemMedia.id,
            url: itemMedia.URL?.replace(`${process.env.REACT_APP_STATIC_URL}`, ''),
            description: itemMedia.content,
            title: itemMedia.title,
            type: parseInt(itemMedia.type.toString(), 10),
          });
        }
      });
      dataMedia = dataMedia.filter((itemMedia) => {
        return itemMedia.isNew;
      });
    }

    try {
      listResponseMedia = await Promise.all(
        dataMedia.map(async (item: IMediaUploads) => {
          const formData: FormData = new FormData();
          formData.append('type', TYPE_MEDIA.DIGITAL_TOKUTEN.toString());
          formData.append('artistId', data.artist.toString());
          formData.append('file', item.File);
          formData.append('tokutenType', typeMedia.toString());
          const response = await UploadMediaApi.upLoadMedia(formData);
          if (response) {
            return {
              url: response.data.url,
              description: item.content,
              type: typeMedia,
              title: item.title,
            };
          }
        }),
      );
      listResponseMedia = oldListResponseMedia.concat(listResponseMedia);
    } catch (error) {
      openMessageError(error);
    }
    return listResponseMedia;
  };

  const handleCreatePromotion = async (dataHandle: IDataForm) => {
    setShowModalSubmitForm(false);
    const { Variation, tokutenName, ECPromotion, registrationType, dateDownload, daySales } =
      dataHandle;

    const dataForm: IDataFormTokutenUploads = {
      variationId: Variation,
      name: tokutenName,
      introEc: ECPromotion,
      contentType: parseInt(registrationType.toString(), 10),
      downloadDate: new Date(dateDownload as Date).toISOString(),
      saleStartDate: new Date(daySales.startSalesDate as Date).toISOString(),
      saleEndDate: new Date(daySales.endSalesDate as Date).toISOString(),
      digitalTokutenMedias: [],
    };
    try {
      setLoading(true);
      const listResponseImg: IMediaResponse[] = await uploadMediaCommon(
        dataHandle.artistImage,
        TOKUTEN_TYPE_MEDIA.IMAGE,
        dataHandle,
      );

      const listResponseAudio: IMediaResponse[] = await uploadMediaCommon(
        dataHandle.artistAudio,
        TOKUTEN_TYPE_MEDIA.AUDIO,
        dataHandle,
      );

      const listResponseVideo: IMediaResponse[] = await uploadMediaCommon(
        dataHandle.artistVideo,
        TOKUTEN_TYPE_MEDIA.VIDEO,
        dataHandle,
      );
      dataForm.digitalTokutenMedias = [
        ...listResponseImg,
        ...listResponseAudio,
        ...listResponseVideo,
      ].map((item: IMediaResponse) => {
        return {
          url: item.url,
          description: item.description,
          type: item.type,
          title: item.title,
        };
      });

      await PromotionMangagerApi.createPromotion(dataForm);
      setLoading(false);
      props.onChangeTabs('販売スケジュール');
    } catch (error) {
      setLoading(false);
      openMessageError(error);
    }
  };

  const handleUpdatePromotion = async (dataHandle: IDataForm) => {
    const { Variation, tokutenName, ECPromotion, registrationType, dateDownload, daySales } =
      dataHandle;
    setShowModalSubmitForm(false);

    const dataForm: IDataFormTokutenUploads = {
      variationId: Variation,
      name: tokutenName,
      introEc: ECPromotion,
      contentType: parseInt(registrationType.toString(), 10),
      downloadDate: new Date(dateDownload as Date).toISOString(),
      saleStartDate: new Date(daySales.startSalesDate as Date).toISOString(),
      saleEndDate: new Date(daySales.endSalesDate as Date).toISOString(),
      digitalTokutenMedias: [],
    };
    try {
      setLoading(true);

      const listResponseImg: IMediaResponse[] = await uploadMediaCommon(
        dataHandle.artistImage,
        TOKUTEN_TYPE_MEDIA.IMAGE,
        dataHandle,
      );

      const listResponseAudio: IMediaResponse[] = await uploadMediaCommon(
        dataHandle.artistAudio,
        TOKUTEN_TYPE_MEDIA.AUDIO,
        dataHandle,
      );

      const listResponseVideo: IMediaResponse[] = await uploadMediaCommon(
        dataHandle.artistVideo,
        TOKUTEN_TYPE_MEDIA.VIDEO,
        dataHandle,
      );

      dataForm.digitalTokutenMedias = [
        ...listResponseImg,
        ...listResponseAudio,
        ...listResponseVideo,
      ].map((item: IMediaResponse) => {
        return {
          id: item.id,
          url: item.url ?? item.URL,
          description: item.description ?? item.content,
          type: item.type,
          title: item.title ?? '',
        };
      });
      await PromotionMangagerApi.updatePromotion(id, dataForm);
      setLoading(false);
      props.onChangeTabs('販売スケジュール');
    } catch (error) {
      setLoading(false);
      openMessageError(error);
    }
  };

  const onSubmitModal = (type: string, data: IDigitalUploadFile) => {
    const itemDataDigital = {
      type,
      data,
    };
    setValueDefault(itemDataDigital);
    switch (type) {
      case 'image':
        setShowModalConfirmImage(true);
        return;
      case 'audio':
        setShowModalConfirmAudio(true);
        return;
      case 'video':
        setShowModalConfirmVideo(true);
        return;
      default:
        setShowModalConfirmImage(false);
        setShowModalConfirmAudio(false);
        setShowModalConfirmVideo(false);
    }
  };

  const addDigitalProduct = (type: string, files: FileList) => {
    if (files.length === 0) {
      return;
    }
    const urlName = URL.createObjectURL(files[0]);
    switch (type) {
      case 'image':
        const checkTypeFileImage = checkIfFilesGeneralAreCorrectType('image', files);
        if (!checkTypeFileImage) {
          setWarning({
            ...warning,
            image: '画像ファイルは2.0MB以内のjpeg、png又はgifのファイルを登録してください。',
          });
          return;
        }
        setWarning({
          ...warning,
          image: '',
        });
        const newDataImage = [
          ...digitalImage,
          {
            id: nextId(),
            isNew: true,
            URL: urlName,
            content: '',
            File: files[0],
          },
        ];
        setValue('artistImage', newDataImage);
        return;
      case 'audio':
        const checkTypeFileAudio = checkIfFilesGeneralAreCorrectType('audio', files);
        if (!checkTypeFileAudio) {
          setWarning({
            ...warning,
            audio: '音源ファイルは1.5M-20.0MB以内のMP3ファイルを登録してください。',
          });
          return;
        }

        setWarning({
          ...warning,
          audio: '',
        });
        const newDataAudio = [
          ...digitalAudio,
          {
            id: nextId(),
            URL: urlName,
            isNew: true,
            content: '',
            File: files[0],
          },
        ];
        setValue('artistAudio', newDataAudio);
        return;

      case 'video':
        const checkTypeFileVideo = checkIfFilesGeneralAreCorrectType('video', files);
        if (digitalVideo.length === 1 || !checkTypeFileVideo) {
          setWarning({
            ...warning,
            video: '映像ファイルは200MB以内のMP4ファイルを登録してください。',
          });
          return;
        }
        setWarning({
          ...warning,
          video: '',
        });
        const newDataVideo = [
          ...digitalVideo,
          {
            id: nextId(),
            isNew: true,
            URL: urlName,
            content: '',
            File: files[0],
          },
        ];
        setValue('artistVideo', newDataVideo);
        return;
      default:
        break;
    }
  };

  const handleChangeDigital = (
    type: string,
    e: React.SyntheticEvent<EventTarget>,
    data: IDigitalUploadFile,
  ) => {
    const newDigitalData: Array<IDigitalUploadFile> =
      type === 'image' ? digitalImage : type === 'audio' ? digitalAudio : digitalVideo;
    const target = e.target as HTMLInputElement;
    const files = target.files;
    if (!files || !newDigitalData) {
      return;
    }
    const urlName = URL.createObjectURL(files[0]);
    const newData: IDigitalUploadFile = {
      ...data,
      URL: urlName,
      File: files[0],
    };
    const elementsIndex = newDigitalData.findIndex((el) => el.id === newData.id);
    newDigitalData[elementsIndex] = newData;
    switch (type) {
      case 'image':
        setValue('artistImage', newDigitalData);
        setIsLoadingBoxDigitalImage(true);
        return;
      case 'audio':
        setValue('artistAudio', newDigitalData);
        setValue('artistAudio', newDigitalData);
        setIsLoadingBoxDigitalImage(true);
        return;
      case 'video':
        setValue('artistVideo', newDigitalData);
        setIsLoadingBoxDigitalImage(true);
        return;
      default:
        break;
    }
  };

  const handleImportFile = (event: any) => {
    sheetToJson(event.target.files[0]);
  };

  const ElementDigitalComponent = (type: string, data: IDigitalUploadFile) => {
    switch (type) {
      case 'image':
        return (
          <div>
            {data.URL ? (
              <Image src={data?.URL} rounded />
            ) : (
              <Form.Control
                id={`file-image-${data.id}`}
                type="file"
                onChange={(e) => {
                  handleChangeDigital('image', e, data);
                }}
              />
            )}
          </div>
        );
      case 'audio':
        return (
          <div>
            {data.URL ? (
              <>
                <audio controls src={data.URL}>
                  Your browser does not support the
                  <code>audio</code> element.
                </audio>
              </>
            ) : (
              <Form.Control
                id={`file-audio-${data.id}`}
                type="file"
                onChange={(e) => {
                  handleChangeDigital('audio', e, data);
                }}
              />
            )}
          </div>
        );
      case 'video':
        return (
          <div>
            {data.URL ? (
              <div className="w-100">
                <video className="w-100" controls>
                  <source src={data.URL} type="video/mp4" />
                  Your browser does not support HTML video.
                </video>
              </div>
            ) : (
              <Form.Control
                id={`file-video-${data.id}`}
                type="file"
                onChange={(e) => {
                  handleChangeDigital('video', e, data);
                }}
              />
            )}
          </div>
        );
      default:
        break;
    }
  };

  const onDeleteItem = (index: number) => {
    const type = valueDefault?.type;
    const newData = valueDefault?.data;
    if (!newData || !type) {
      return;
    }
    const newDigitalData =
      type === 'image' ? digitalImage : type === 'audio' ? digitalAudio : digitalVideo;
    switch (type) {
      case 'image':
        if (index > -1) {
          newDigitalData.splice(index, 1);
          setValue('artistImage', [...newDigitalData]);
          setShowModalConfirmImage(false);
          setIsLoadingBoxDigitalImage(true);
        }
        return;
      case 'audio':
        if (index > -1) {
          newDigitalData.splice(index, 1);
          setValue('artistAudio', [...newDigitalData]);
          setShowModalConfirmAudio(false);
          setIsLoadingBoxDigitalImage(true);
        }
        return;
      case 'video':
        if (index > -1) {
          newDigitalData.splice(index, 1);
          setValue('artistVideo', [...newDigitalData]);
          setShowModalConfirmVideo(false);
          setIsLoadingBoxDigitalImage(true);
        }
        return;
      default:
        return;
    }
  };

  const checkTrimJP = (value: string) => {
    return value.trim() ? true : '入力してください。';
  };

  const handleChangeInput = (value: string, type: string) => {
    if (!type) return;
    setValue(`${type}`, value);
  };

  return (
    <div className={`main-wrap-digital mt-4 ${id && isOnlyView ? 'onlyViewDigital' : ''}`}>
      <SpinnerComponent isLoading={loading} />
      <Container>
        <Row>
          <Col md="8" className="w-100">
            <Form className="pb-5" onSubmit={handleSubmit(onSubmit)}>
              <BoxForm title="デジタル特典情報">
                <>
                  <div className="mb-4">
                    <PullDownSearch
                      onChange={(val: number) => {
                        if (val) {
                          setSttPullDownTitle(false);
                          if (val !== artistID) {
                            setArtistID(val);
                            setValue('title', '');
                            setValue('Variation', '');
                            setValue('artist', val);
                            setSttPullDownVariation(true);
                            setPullDownLabel({ ...pullDownLabel, title: '', variation: '' });
                            setDigitalCommons([]);
                          }
                        } else {
                          setSttPullDownTitle(true);
                        }
                      }}
                      defaultValue={pullDownLabel.artist}
                      label="アーティスト*"
                      placeholder="アーティストを選択してください"
                      errorMessage={errors?.artist?.message}
                      register={register('artist', {
                        required: {
                          message: '入力してください。',
                          value: true,
                        },
                      })}
                      type="ARTIST"
                    />
                  </div>
                  <div className="mb-4">
                    <PullDownSearch
                      defaultValue={pullDownLabel.title}
                      disabled={sttPullDownTitle}
                      artistID={getValues('artist')}
                      onChange={(val: number) => {
                        if (val) {
                          if (val !== titleID) {
                            setTitleID(val);
                            setValue('Variation', '');
                            setValue('title', val);
                            setPullDownLabel({ ...pullDownLabel, variation: '' });
                            setDigitalCommons([]);
                          }
                          setSttPullDownVariation(false);
                        } else {
                          setSttPullDownVariation(true);
                        }
                      }}
                      label="タイトル*"
                      placeholder="タイトルを選択してください"
                      errorMessage={errors?.title?.message}
                      register={register('title', {
                        required: {
                          message: '入力してください。',
                          value: true,
                        },
                      })}
                      type="TITEL"
                    />
                  </div>
                  <div className="mb-4">
                    <PullDownSearch
                      defaultValue={pullDownLabel.variation}
                      disabled={sttPullDownVariation}
                      onChange={(val: number, items, listTokutenCommons) => {
                        setValue('Variation', val);
                        setDigitalCommons(listTokutenCommons ?? []);
                      }}
                      titleID={getValues('title')}
                      artistID={getValues('artist')}
                      label="バリエーション*"
                      placeholder="バリエーションを選択してください"
                      errorMessage={errors?.Variation?.message}
                      register={register('Variation', {
                        required: {
                          message: '入力してください。',
                          value: true,
                        },
                      })}
                      type="VARIATION"
                    />
                  </div>
                  <div className="mb-4">
                    <FormGroupInput
                      labelMd="2"
                      colMd="10"
                      label="特典名称*"
                      maxLength={32}
                      errorMessage={errors?.tokutenName?.message}
                      value={tokutenName}
                      register={register('tokutenName', {
                        required: {
                          message: '入力してください。',
                          value: true,
                        },
                        maxLength: {
                          value: 32,
                          message: '全角文字で入力してください。',
                        },
                        validate: {
                          checkTrim: checkTrimJP,
                        },
                      })}
                      onChange={setTokutenName}
                    />
                  </div>
                  <div className="mb-4">
                    <FormGroupInput
                      labelMd="2"
                      colMd="10"
                      label="EC用宣伝文*"
                      maxLength={250}
                      errorMessage={errors?.ECPromotion?.message}
                      value={ECPromotion}
                      register={register('ECPromotion', {
                        required: {
                          message: '入力してください。',
                          value: true,
                        },
                        validate: {
                          checkTrim: checkTrimJP,
                        },
                      })}
                      onChange={setECPromotion}
                    />
                  </div>
                  <div className="mb-4">
                    <GroupCheckBox
                      value={watch('registrationType')}
                      id="registration-type"
                      labelMd="2"
                      colMd="10"
                      classBoxErr="w-100"
                      labelForm="登録タイプ*"
                      onValue={(value: number | string) => {
                        if (value == '0') {
                          setValue('registrationType', '0');
                        } else {
                          setValue('registrationType', '1');
                        }
                      }}
                      options={defaultCheckboxOptions}
                      classForm="d-flex"
                      errorMessage={errors?.registrationType?.message}
                      register={register('registrationType', {
                        required: {
                          message: '入力してください。',
                          value: true,
                        },
                      })}
                    />
                  </div>
                  <div className="mb-4">
                    <FormGroupDateRange
                      checkUpdateRange={true}
                      showTimeSelect={true}
                      minDate={new Date(minDateStart)}
                      label="販売期間指定*"
                      labelMd="2"
                      colMd="10"
                      startDate={getValues('daySales.startSalesDate')}
                      endDate={getValues('daySales.endSalesDate')}
                      placeholderStart="開始日時を選択（yyyy/mm/dd/ hh:mm）"
                      placeholderEnd="終了⽇時を選択（yyyy/mm/dd/ hh:mm）"
                      onChangeStartDate={(date: Date | null) => {
                        handleChangeDate(date, 'startSaleDate');
                      }}
                      onChangeEndDate={(date: Date | null) => {
                        handleChangeDate(date, 'endSaleDate');
                      }}
                      registerStartDate={register('daySales.startSalesDate', {
                        required: {
                          message: '入力してください。',
                          value: true,
                        },
                      })}
                      registerEndDate={register('daySales.endSalesDate', {
                        required: {
                          message: '入力してください。',
                          value: true,
                        },
                      })}
                      endDateDisabled={!!getValues('daySales.startSalesDate')}
                      errorStartDate={errors?.daySales?.startSalesDate?.message}
                      errorEndDate={errors?.daySales?.endSalesDate?.message}
                    />
                  </div>
                  <div className="mb-4">
                    <FormGroupDate
                      colDP={5}
                      label="DL可能開始⽇時*"
                      labelMd="2"
                      colMd="10"
                      minDate={watch('daySales.startSalesDate') ?? new Date()}
                      compareMinDate={true}
                      selected={getValues('dateDownload')}
                      onChange={(date: IDatePicker) => handleChangeDate(date, 'dateDownload')}
                      placeholder="⽇時を選択（yyyy/mm/dd/ hh:mm）"
                      classForm="group-date"
                      errorMessage={errors?.dateDownload?.message}
                      register={register('dateDownload', {
                        required: {
                          message: '入力してください。',
                          value: true,
                        },
                      })}
                    />
                  </div>
                  {digitalCommons?.length ? (
                    <div className="mb-4 ms-2">
                      <p>登録済みの共通デジタルコンテンツ</p>
                      {digitalCommons.map((item: IListTokutenCommon, index: number) => {
                        return (
                          <p
                            key={index}
                            style={{ marginLeft: '16.6666666667%', paddingLeft: '5px' }}
                          >
                            {item}
                          </p>
                        );
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              </BoxForm>
              <div className="mt-5">
                <BoxForm title={`画像コンテンツ（登録可能数：3点）`}>
                  <div className="box-item-main">
                    <div className="box-item-content">
                      {digitalImage || isLoadingBoxDigitalImage ? (
                        digitalImage.map((item: IDigitalUploadFile, index: number) => {
                          return (
                            <div key={index}>
                              <div className="pt-4 pb-3 border-bottom-gray">
                                <ItemDigital
                                  element={ElementDigitalComponent('image', item)}
                                  labelMd={4}
                                  colMd={8}
                                  titleLeft="EC説明⽂ "
                                  maxLength={120}
                                  errorMessage={
                                    errors?.artistImage &&
                                    errors?.artistImage[index]?.content.message
                                  }
                                  onSubmit={() => {
                                    onSubmitModal('image', item);
                                    setImgRemoveID(index);
                                  }}
                                  register={{
                                    ...register(
                                      `artistImage.${index}.content`,
                                      validateDigitalMain,
                                    ),
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div>
                          <Placeholder as="p" animation="glow">
                            <Placeholder xs={12} />
                          </Placeholder>
                        </div>
                      )}
                      <ModalComponent
                        show={modalConfirmImage}
                        onCloseModal={() => setShowModalConfirmImage(false)}
                        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
                        variantButtonSubmit="primary"
                        title="削除確認"
                        confirmText="削除する"
                        onSubmitModal={() => onDeleteItem(imgRemoveID)}
                      >
                        <div>
                          <p className="mb-2">画像コンテンツを削除します。よろしいですか。</p>
                        </div>
                      </ModalComponent>
                      <MessageError message={warning.image} />
                    </div>
                    <div className="w-100 d-flex justify-content-end">
                      <Form.Group controlId="boxUploadDigitalImage" className="mb-3">
                        <div className="w-100 d-flex justify-content-end box-bottom-btn">
                          <Form.Label
                            className={`btn bg-blue-ct${
                              digitalImage.length === 3 ? '2 d-none' : ''
                            } btn-bottom-submit text-white`}
                          >
                            画像コンテンツを追加
                          </Form.Label>
                        </div>
                        <Form.Control
                          type="file"
                          accept="image/jpg, image/jpeg, image/gif, image/png"
                          disabled={digitalImage.length === 3}
                          {...register('digitalImage')}
                          className="d-none"
                          onChange={(e) => {
                            const target = e.target as HTMLInputElement;
                            const files = target.files;

                            if (!files) {
                              return;
                            }
                            addDigitalProduct('image', files);
                            target.value = '';
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </BoxForm>
              </div>
              <div className="mt-5">
                <BoxForm title={`音源コンテンツ（登録可能数：2点）`}>
                  <div className="box-item-main">
                    <div className="box-item-content">
                      {digitalAudio || isLoadingBoxDigitalImage ? (
                        digitalAudio.map((item: IDigitalUploadFile, index: number) => {
                          return (
                            <div key={index}>
                              <div className="pt-4 pb-3 border-bottom-gray">
                                <ItemDigital
                                  element={ElementDigitalComponent('audio', item)}
                                  labelMd={4}
                                  colMd={8}
                                  titleLeft="EC説明文"
                                  maxLength={120}
                                  onSubmit={() => {
                                    onSubmitModal('audio', item);
                                    setAudioRemoveID(index);
                                  }}
                                  errorMessage={
                                    errors?.artistAudio &&
                                    errors?.artistAudio[index]?.content.message
                                  }
                                  register={{
                                    ...register(
                                      `artistAudio.${index}.content`,
                                      validateDigitalMain,
                                    ),
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div>
                          <Placeholder as="p" animation="glow">
                            <Placeholder xs={12} />
                          </Placeholder>
                        </div>
                      )}
                      <ModalComponent
                        show={modalConfirmAudio}
                        onCloseModal={() => setShowModalConfirmAudio(false)}
                        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
                        variantButtonSubmit="primary"
                        title="削除確認"
                        confirmText="削除する"
                        onSubmitModal={() => onDeleteItem(audioRemoveID)}
                      >
                        <div>
                          <p className="mb-2">⾳源コンテンツを削除します。よろしいですか。</p>
                        </div>
                      </ModalComponent>
                      <MessageError message={warning.audio} />
                    </div>
                    <div className="w-100 d-flex justify-content-end">
                      <Form.Group controlId="boxUploadDigitalAudio" className="mb-3">
                        <div className="w-100 d-flex justify-content-end box-bottom-btn">
                          <Form.Label
                            className={`btn bg-blue-ct${
                              digitalAudio.length === 2 ? '2 d-none' : ''
                            } btn-bottom-submit text-white`}
                          >
                            音源コンテンツを追加
                          </Form.Label>
                        </div>
                        <Form.Control
                          type="file"
                          accept="audio/mpeg3, audio/x-mpeg-3, audio/mpeg"
                          disabled={digitalAudio.length === 2}
                          className="d-none"
                          onChange={(e) => {
                            const target = e.target as HTMLInputElement;
                            const files = target.files;
                            if (!files) {
                              return;
                            }
                            addDigitalProduct('audio', files);
                            target.value = '';
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </BoxForm>
              </div>
              <div className="mt-5">
                <BoxForm title={`映像コンテンツ（登録可能数：1点）`}>
                  <div className="box-item-main">
                    {digitalVideo?.length > 0 && (
                      <div className="d-flex justify-content-end">
                        <Button
                          className="btn-link me-2 h-35px"
                          onClick={() => exportToExcel(formListSongDefault)}
                        >
                          <p className="text-primary">一括登録用ひながたダウンロード</p>
                        </Button>

                        <Form.Group controlId="fileImport">
                          <Form.Label className={`btn bg-blue-ct text-white`}>
                            一括登録ファイルインポート
                          </Form.Label>
                          <Form.Control
                            type="file"
                            accept=".xlsx"
                            className="d-none"
                            onChange={handleImportFile}
                          />
                        </Form.Group>
                      </div>
                    )}

                    <div className="box-item-content">
                      {digitalVideo || isLoadingBoxDigitalImage ? (
                        digitalVideo.map((item: IDigitalUploadFile, index: number) => {
                          return (
                            <div key={index}>
                              <div className="pt-4 pb-3 border-bottom-gray">
                                <ItemDigital
                                  element={ElementDigitalComponent('video', item)}
                                  labelMd={4}
                                  colMd={8}
                                  titleLeft="EC説明文"
                                  maxLength={120}
                                  onSubmit={() => onSubmitModal('video', item)}
                                  errorMessage={
                                    errors?.artistVideo &&
                                    errors?.artistVideo[index]?.content?.message
                                  }
                                  errorMessageName={
                                    errors?.artistVideo &&
                                    errors?.artistVideo[index]?.title?.message
                                  }
                                  register={{
                                    ...register(
                                      `artistVideo.${index}.content`,
                                      validateDigitalMain,
                                    ),
                                  }}
                                  titleName="タイトル名"
                                  registerName={{
                                    ...register(`artistVideo.${index}.title`, validateDigitalMain),
                                  }}
                                />
                              </div>
                              <ModalComponent
                                show={modalConfirmVideo}
                                onCloseModal={() => setShowModalConfirmVideo(false)}
                                classNameContent="d-flex align-items-center content-body-modal justify-content-center"
                                variantButtonSubmit="primary"
                                title="削除確認"
                                confirmText="削除する"
                                onSubmitModal={() => onDeleteItem(index)}
                              >
                                <div>
                                  <p className="mb-2">
                                    映像コンテンツを削除します。よろしいですか。
                                  </p>
                                </div>
                              </ModalComponent>
                            </div>
                          );
                        })
                      ) : (
                        <div>
                          <Placeholder as="p" animation="glow">
                            <Placeholder xs={12} />
                          </Placeholder>
                        </div>
                      )}
                      <MessageError message={warning.video} />
                    </div>
                    <div className="w-100 d-flex justify-content-end">
                      <Form.Group controlId="boxUploadDigitalVideo" className="mb-3">
                        <div className="w-100 d-flex justify-content-end box-bottom-btn">
                          <Form.Label
                            className={`btn bg-blue-ct${
                              digitalVideo.length === 1 ? '2 d-none' : ''
                            } btn-bottom-submit text-white`}
                          >
                            映像コンテンツを追加
                          </Form.Label>
                        </div>
                        <Form.Control
                          type="file"
                          accept="video/mp4"
                          disabled={digitalVideo.length === 1}
                          className="d-none"
                          onChange={(e) => {
                            const target = e.target as HTMLInputElement;
                            const files = target.files;
                            if (!files) {
                              return;
                            }
                            addDigitalProduct('video', files);
                            target.value = '';
                          }}
                        />
                      </Form.Group>
                    </div>
                    {digitalVideo?.length > 0 && (
                      <div className="mt-5">
                        <BoxForm classBox="shadow-none" title="収録楽曲データ">
                          <div className="box-item-main">
                            <ListSong
                              variationIndex={DATA_DOWNLOAD.length}
                              songList={DATA_DOWNLOAD}
                            />
                          </div>
                        </BoxForm>
                      </div>
                    )}
                  </div>
                </BoxForm>
              </div>
              {!digitalError && <MessageError message="コンテンツはいずれかを登録してください。" />}

              <div className="mt-5 d-none">
                <BoxForm title="シリアルナンバー">
                  <div className="box-item-main">
                    <FormInputNumber
                      name="price"
                      colMd="2"
                      labelMd="2"
                      label="付与数"
                      classCol="position-relative number-lever"
                      pureType={true}
                      decimalScale={0}
                      allowNegative={false}
                      maxLength={10}
                      value={null}
                    />
                    <PullDownSearch
                      onChange={(val: number) => console.log(val)}
                      label="シリアルナンバーマスタ"
                      placeholder="シリアルナンバーを選択してください"
                      type="SERIAL"
                    />
                    <FormGroupTextArea
                      labelMd="2"
                      colMd="10"
                      label="注意事項"
                      register={register('memo')}
                      classInput="mt-2"
                      rows={1}
                    />

                    <div className="d-flex justify-content-end mt-3 px-3">
                      <Button className="ms-auto" variant="danger">
                        削除する
                      </Button>
                    </div>
                  </div>
                </BoxForm>
              </div>
              <div className="d-flex justify-content-end txt-require mt-4">
                ご注意：＊印は必須項目です。
              </div>
              <FormGroupInput
                classForm="invisible"
                labelMd="1"
                colMd="1"
                label=""
                errorMessage={errors?.validateDigital?.message}
                value={watch('validateDigital')}
                register={register('validateDigital', {
                  validate: {
                    checkDigital: () => {
                      if (
                        digitalImage.length > 0 ||
                        digitalAudio.length > 0 ||
                        digitalVideo.length > 0
                      ) {
                        setDigitalError(true);
                        return true;
                      }
                      setDigitalError(false);
                      return false;
                    },
                  },
                })}
                onChange={(e) => handleChangeInput(e, 'validateDigital')}
              />

              <Button
                className="btn-bottom-submit btn-show-modal d-flex justify-content-center"
                variant="primary"
                type="submit"
              >
                保存する
              </Button>
            </Form>
          </Col>
        </Row>
        <ModalComponent
          show={modalSubmitForm}
          onCloseModal={() => setShowModalSubmitForm(false)}
          classNameContent="d-flex align-items-center content-body-modal justify-content-center"
          variantButtonSubmit="primary"
          title="登録確認"
          confirmText="登録する"
          onSubmitModal={() => {
            if (!isOnlyView && id) return handleUpdatePromotion(dataPost);
            return handleCreatePromotion(dataPost);
          }}
        >
          入力した内容で登録します。よろしいですか。
        </ModalComponent>
      </Container>
    </div>
  );
}
