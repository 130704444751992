import { Menu } from 'Components/Layouts/common';
import { ROLE_RECORD_MENU } from 'Constant/Roles';

export const MENUS: Menu[] = [
  {
    title: 'トップ',
    slug: '#',
    roles: [
      ROLE_RECORD_MENU.RCCompMaster,
      ROLE_RECORD_MENU.RCCompManagerHigh,
      ROLE_RECORD_MENU.RCCompManagerLow,
      ROLE_RECORD_MENU.SALEChanelMaster,
      ROLE_RECORD_MENU.SALEChanelManager,
      ROLE_RECORD_MENU.ECCompMaster,
      ROLE_RECORD_MENU.ECCompManager,
    ],
    children: [
      {
        title: 'レポーティング',
        roles: [
          ROLE_RECORD_MENU.RCCompMaster,
          ROLE_RECORD_MENU.RCCompManagerHigh,
          ROLE_RECORD_MENU.RCCompManagerLow,
          ROLE_RECORD_MENU.SALEChanelMaster,
          ROLE_RECORD_MENU.SALEChanelManager,
          ROLE_RECORD_MENU.ECCompMaster,
          ROLE_RECORD_MENU.ECCompManager,
        ],
        slug: '/',
      },
      {
        title: '進行ボード',
        roles: [
          ROLE_RECORD_MENU.RCCompMaster,
          ROLE_RECORD_MENU.RCCompManagerHigh,
          ROLE_RECORD_MENU.RCCompManagerLow,
          ROLE_RECORD_MENU.SALEChanelMaster,
          ROLE_RECORD_MENU.SALEChanelManager,
          ROLE_RECORD_MENU.ECCompMaster,
          ROLE_RECORD_MENU.ECCompManager,
        ],
        slug: '/processboard',
      },
    ],
  },
  {
    title: 'タイトル管理',
    slug: '#',
    roles: [
      ROLE_RECORD_MENU.RCCompMaster,
      ROLE_RECORD_MENU.RCCompManagerHigh,
      ROLE_RECORD_MENU.RCCompManagerLow,
    ],
    children: [
      {
        title: 'マスタタイトルリスト',
        slug: '/title/list',
        roles: [
          ROLE_RECORD_MENU.RCCompMaster,
          ROLE_RECORD_MENU.RCCompManagerHigh,
          ROLE_RECORD_MENU.RCCompManagerLow,
        ],
      },
      {
        title: 'マスタタイトル登録',
        slug: '/title/register',
        roles: [
          ROLE_RECORD_MENU.RCCompMaster,
          ROLE_RECORD_MENU.RCCompManagerHigh,
          ROLE_RECORD_MENU.RCCompManagerLow,
        ],
      },
    ],
  },
  {
    title: 'キャンペーン管理',
    slug: '#',
    roles: [
      ROLE_RECORD_MENU.RCCompMaster,
      ROLE_RECORD_MENU.RCCompManagerHigh,
      ROLE_RECORD_MENU.RCCompManagerLow,
    ],
    children: [
      {
        title: 'キャンペーンリスト・登録',
        slug: '/campaign-list',
        roles: [
          ROLE_RECORD_MENU.RCCompMaster,
          ROLE_RECORD_MENU.RCCompManagerHigh,
          ROLE_RECORD_MENU.RCCompManagerLow,
        ],
      },
      {
        title: 'シリアルコードマスタ',
        slug: '/serial-numbers',
        roles: [
          ROLE_RECORD_MENU.RCCompMaster,
          ROLE_RECORD_MENU.RCCompManagerHigh,
          ROLE_RECORD_MENU.RCCompManagerLow,
        ],
      },
      {
        title: 'CDマスタ',
        slug: '/cd-master',
        roles: [
          ROLE_RECORD_MENU.RCCompMaster,
          ROLE_RECORD_MENU.RCCompManagerHigh,
          ROLE_RECORD_MENU.RCCompManagerLow,
        ],
      },
    ],
  },
  {
    title: '販売管理',
    slug: '#',
    roles: [
      ROLE_RECORD_MENU.RCCompMaster,
      ROLE_RECORD_MENU.RCCompManagerHigh,
      ROLE_RECORD_MENU.RCCompManagerLow,
    ],
    children: [
      {
        title: '販売商品リスト',
        slug: '/list-products',
        roles: [
          ROLE_RECORD_MENU.RCCompMaster,
          ROLE_RECORD_MENU.RCCompManagerHigh,
          ROLE_RECORD_MENU.RCCompManagerLow,
        ],
      },
      {
        title: '販売商品詳細',
        slug: '/products-detail',
        roles: [
          ROLE_RECORD_MENU.RCCompMaster,
          ROLE_RECORD_MENU.RCCompManagerHigh,
          ROLE_RECORD_MENU.RCCompManagerLow,
        ],
      },
    ],
  },
  {
    title: 'ECサイト管理',
    slug: '#',
    roles: [
      ROLE_RECORD_MENU.RCCompMaster,
      ROLE_RECORD_MENU.RCCompManagerHigh,
      ROLE_RECORD_MENU.RCCompManagerLow,
      ROLE_RECORD_MENU.SALEChanelMaster,
      ROLE_RECORD_MENU.SALEChanelManager,
      ROLE_RECORD_MENU.ECCompMaster,
      ROLE_RECORD_MENU.ECCompManager,
    ],
    children: [
      {
        title: 'EC販売商品リスト',
        slug: '/ec/list-sale-products',
        roles: [
          ROLE_RECORD_MENU.RCCompMaster,
          ROLE_RECORD_MENU.RCCompManagerHigh,
          ROLE_RECORD_MENU.RCCompManagerLow,
          ROLE_RECORD_MENU.SALEChanelMaster,
          ROLE_RECORD_MENU.SALEChanelManager,
          ROLE_RECORD_MENU.ECCompMaster,
          ROLE_RECORD_MENU.ECCompManager,
        ],
      },
      {
        title: '購入リスト',
        slug: '/ec/list-purchase',
        roles: [ROLE_RECORD_MENU.ECCompMaster, ROLE_RECORD_MENU.ECCompManager],
      },
      {
        title: 'サイト設定',
        slug: '/ec/setting-site',
        roles: [ROLE_RECORD_MENU.ECCompMaster, ROLE_RECORD_MENU.ECCompManager],
      },
      {
        title: 'メールメッセージ管理',
        slug: '/ec/email-message-management',
        roles: [ROLE_RECORD_MENU.ECCompMaster, ROLE_RECORD_MENU.ECCompManager],
      },
    ],
  },
  {
    title: '会計情報',
    slug: '#',
    roles: [
      ROLE_RECORD_MENU.RCCompMaster,
      ROLE_RECORD_MENU.RCCompManagerHigh,
      ROLE_RECORD_MENU.RCCompManagerLow,
      ROLE_RECORD_MENU.SALEChanelMaster,
      ROLE_RECORD_MENU.SALEChanelManager,
      ROLE_RECORD_MENU.ECCompMaster,
      ROLE_RECORD_MENU.ECCompManager,
    ],
    children: [
      {
        title: '売上管理',
        slug: '/revenue-management',
        roles: [
          ROLE_RECORD_MENU.RCCompMaster,
          ROLE_RECORD_MENU.RCCompManagerHigh,
          ROLE_RECORD_MENU.RCCompManagerLow,
          ROLE_RECORD_MENU.SALEChanelMaster,
          ROLE_RECORD_MENU.SALEChanelManager,
          ROLE_RECORD_MENU.ECCompMaster,
          ROLE_RECORD_MENU.ECCompManager,
        ],
      },
      {
        title: 'EC取引明細リスト',
        slug: '/ec/payment-advice-list',
        roles: [ROLE_RECORD_MENU.ECCompMaster, ROLE_RECORD_MENU.ECCompManager],
      },
      {
        title: 'EC仕入サマリ',
        slug: '/ec/purchase-summary-list',
        roles: [
          ROLE_RECORD_MENU.SALEChanelMaster,
          ROLE_RECORD_MENU.SALEChanelManager,
          ROLE_RECORD_MENU.ECCompMaster,
          ROLE_RECORD_MENU.ECCompManager,
        ],
      },
      {
        title: 'EC仕入支払設定',
        slug: '/ec/purchasing-rate-setting',
        roles: [
          ROLE_RECORD_MENU.SALEChanelMaster,
          ROLE_RECORD_MENU.SALEChanelManager,
          ROLE_RECORD_MENU.ECCompMaster,
          // ROLE_RECORD_MENU.ECCompManager,
        ],
      },
      {
        title: '著作権レポート',
        slug: '/jasrac',
        roles: [
          ROLE_RECORD_MENU.RCCompMaster,
          ROLE_RECORD_MENU.RCCompManagerHigh,
          ROLE_RECORD_MENU.RCCompManagerLow,
          ROLE_RECORD_MENU.SALEChanelMaster,
          ROLE_RECORD_MENU.SALEChanelManager,
          ROLE_RECORD_MENU.ECCompMaster,
          ROLE_RECORD_MENU.ECCompManager,
        ],
      },
    ],
  },
  {
    title: 'アーティスト管理',
    slug: '#',
    roles: [
      ROLE_RECORD_MENU.RCCompMaster,
      ROLE_RECORD_MENU.RCCompManagerHigh,
      ROLE_RECORD_MENU.RCCompManagerLow,
    ],
    children: [
      {
        title: 'アーティストリスト',
        slug: '/artist',
        roles: [
          ROLE_RECORD_MENU.RCCompMaster,
          ROLE_RECORD_MENU.RCCompManagerHigh,
          ROLE_RECORD_MENU.RCCompManagerLow,
        ],
      },
      {
        title: 'アーティスト登録',
        slug: '/artist/register-artist',
        roles: [
          ROLE_RECORD_MENU.RCCompMaster,
          ROLE_RECORD_MENU.RCCompManagerHigh,
          ROLE_RECORD_MENU.RCCompManagerLow,
        ],
      },
    ],
  },
  {
    title: 'アカウント情報管理',
    slug: '#',
    roles: [
      ROLE_RECORD_MENU.RCCompMaster,
      ROLE_RECORD_MENU.RCCompManagerHigh,
      ROLE_RECORD_MENU.RCCompManagerLow,
      ROLE_RECORD_MENU.SALEChanelMaster,
      ROLE_RECORD_MENU.SALEChanelManager,
      ROLE_RECORD_MENU.ECCompMaster,
      ROLE_RECORD_MENU.ECCompManager,
    ],
    children: [
      {
        title: '会社情報変更',
        slug: '/create-company-info',
        roles: [
          ROLE_RECORD_MENU.RCCompMaster,
          ROLE_RECORD_MENU.RCCompManagerHigh,
          ROLE_RECORD_MENU.RCCompManagerLow,
          ROLE_RECORD_MENU.SALEChanelMaster,
          ROLE_RECORD_MENU.SALEChanelManager,
          ROLE_RECORD_MENU.ECCompMaster,
          ROLE_RECORD_MENU.ECCompManager,
        ],
      },
      {
        title: '店舗登録',
        slug: '/shop-management',
        roles: [
          ROLE_RECORD_MENU.ECCompMaster,
          ROLE_RECORD_MENU.ECCompManager,
          ROLE_RECORD_MENU.SALEChanelMaster,
          ROLE_RECORD_MENU.SALEChanelManager,
        ],
      },
      {
        title: 'セクション管理',
        slug: '/session-manage',
        roles: [
          ROLE_RECORD_MENU.RCCompMaster,
          ROLE_RECORD_MENU.RCCompManagerHigh,
          ROLE_RECORD_MENU.RCCompManagerLow,
          ROLE_RECORD_MENU.SALEChanelMaster,
          ROLE_RECORD_MENU.SALEChanelManager,
          ROLE_RECORD_MENU.ECCompMaster,
          ROLE_RECORD_MENU.ECCompManager,
        ],
      },
      {
        title: '担当者管理',
        slug: '/curator-management',
        roles: [
          ROLE_RECORD_MENU.RCCompMaster,
          ROLE_RECORD_MENU.RCCompManagerHigh,
          ROLE_RECORD_MENU.RCCompManagerLow,
          ROLE_RECORD_MENU.SALEChanelMaster,
          ROLE_RECORD_MENU.SALEChanelManager,
          ROLE_RECORD_MENU.ECCompMaster,
          ROLE_RECORD_MENU.ECCompManager,
        ],
      },
    ],
  },
  {
    title: 'システム情報',
    slug: '#',
    roles: [
      ROLE_RECORD_MENU.RCCompMaster,
      ROLE_RECORD_MENU.RCCompManagerHigh,
      ROLE_RECORD_MENU.RCCompManagerLow,
      ROLE_RECORD_MENU.SALEChanelMaster,
      ROLE_RECORD_MENU.SALEChanelManager,
      ROLE_RECORD_MENU.ECCompMaster,
      ROLE_RECORD_MENU.ECCompManager,
    ],
    children: [
      {
        title: '料金表',
        slug: '/cost-information',
        roles: [
          ROLE_RECORD_MENU.RCCompMaster,
          ROLE_RECORD_MENU.RCCompManagerHigh,
          ROLE_RECORD_MENU.RCCompManagerLow,
        ],
      },
      {
        title: '運営会社情報',
        slug: '/operating-company-info',
        roles: [
          ROLE_RECORD_MENU.RCCompMaster,
          ROLE_RECORD_MENU.RCCompManagerHigh,
          ROLE_RECORD_MENU.RCCompManagerLow,
          ROLE_RECORD_MENU.SALEChanelMaster,
          ROLE_RECORD_MENU.SALEChanelManager,
          ROLE_RECORD_MENU.ECCompMaster,
          ROLE_RECORD_MENU.ECCompManager,
        ],
      },
    ],
  },
];
