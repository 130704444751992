import CardContainer from 'Components/Common/CardContainer';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { calcMb } from 'Utils/File';
import GroupTextProps from 'Components/Page/Title/FormTextGroup';
import { formatPrice } from 'Utils/Numbers';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import { useAppSelector } from 'App/Store';
import { INCLUDE_COPY_RIGHT, LINK_JMD_TYPE_CONTENT, VIDEO_TYPES } from 'Constant/Title';
import MessageError from 'Components/Common/MessageError';
import { TitleApi } from 'Datasource/TitleApi';

interface DigitalVideoProps {
  data?: any;
  errors?: any;
  errorsForm?: any;
  riajGenreData?: any[];
  onClickError?: () => void;
}

export default function DigitalVideo(props: DigitalVideoProps): JSX.Element {
  const { data, errors = [], onClickError, riajGenreData, errorsForm } = props;
  const { videos } = data;
  const videosRef = useRef<any>([]);
  const { triggerMedia } = useAppSelector((state) => state.title);
  const [nameJpVideoJmd, setNameJpVideoJmd] = useState('');

  const detailVideoJmd = async (id: number) => {
    if (!id) return;
    const {
      data: { data },
    } = await TitleApi.getJmdVideoGenreById(id);
    setNameJpVideoJmd(data.nameJp);
  };

  useEffect(() => {
    detailVideoJmd(data.jmdVideoGenreId);
    return () => {
      setNameJpVideoJmd('');
    };
  }, [data]);

  const riajGenreNameJp = useCallback(
    (riajGenreId: number) => {
      return riajGenreData?.find((item) => item.value === riajGenreId)?.label ?? '';
    },
    [riajGenreData, data],
  );

  const convertAuthorizedType = (type: number, songDesc?: string) => {
    switch (type) {
      case 1:
        return 'JASRAC';
      case 2:
        return 'NexTone';
      case 3:
        return '自己管理';
      case 4:
        return songDesc ?? '';

      default:
        return '';
    }
  };

  const convertLyricsClassifyType = (type: number) => {
    switch (type) {
      case 1:
        return '１（原詞）';
      case 2:
        return '２（訳詞）';
      case 3:
        return '３（不明）';
      case 4:
        return ' ブランク（Vocalなし）';

      default:
        return '';
    }
  };

  const convertIvtType = (type: number) => {
    switch (type) {
      case 0:
        return 'I（Vocalなし）';
      case 1:
        return 'V（Vocalあり）';
      case 2:
        return 'T（詞のみ）';
      default:
        return '';
    }
  };

  const convertVersionIdentificationType = (type: number) => {
    switch (type) {
      case 0:
        return 'L（国内原版）';
      case 1:
        return 'I（外国原版）';
      default:
        return '';
    }
  };

  const convertLiveType = (type: number) => {
    switch (type) {
      case 0:
        return 'スタジオ';
      case 1:
        return 'ライブ';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (triggerMedia === 'videos' && errors.length > 0) {
      const newListError = errors
        .map((item: any, index: number) => ({ ...item, index: index }))
        .filter((item: any) => !!item);
      videosRef?.current[newListError[0].index]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
  }, [errors, triggerMedia]);
  const convertCopyRightSong = (type: number) => {
    switch (type) {
      case INCLUDE_COPY_RIGHT.INCLUDE:
        return '著作権管理楽曲を含む';
      case INCLUDE_COPY_RIGHT.NOT_INCLUDE:
        return '含まない';
      default:
        return '';
    }
  };

  const convertVideoType = (type: number) => {
    switch (type) {
      case VIDEO_TYPES.MUSIC_VIDEO:
        return 'Music Video';
      case VIDEO_TYPES.OTHER:
        return 'Music Video以外（LIVEなど）';
      default:
        return '';
    }
  };

  return (
    <CardContainer title="動画コンテンツ" className="mb-3 mt-4 p-0">
      {videos.length === 0 &&
        (data?.id ? (
          <p>登録なし</p>
        ) : (
          <p className="text-cherry d-flex align-items-center">【登録されていません】</p>
        ))}
      {videos?.map((video: any, index: number) => {
        return (
          <div key={index} className="mb-4" ref={(el) => (videosRef.current[index] = el)}>
            <Row>
              <Col md={3} className="mt-4">
                <video className="w-100" controls>
                  <track kind="captions" />
                  <source src={handleConnectStaticEndPoint(video?.url)} type="video/mp4" />
                  Your browser does not support HTML video.
                </video>
              </Col>
              <Col md={9} className="mt-4">
                <Col md={12} className="mb-2">
                  <GroupTextProps
                    label="タイトル名:"
                    value={video?.name || ''}
                    errorContent={errors[index]?.name}
                    onClickError={onClickError}
                    mdLable={3}
                    mdValue={9}
                  />
                </Col>
                <Col md={12} className="mb-2">
                  {data?.jmdCooperation === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER && (
                    <GroupTextProps
                      label="jmd動画ジャンル:"
                      value={nameJpVideoJmd}
                      errorContent={errorsForm?.jmdVideoGenreId}
                      onClickError={onClickError}
                      mdLable={3}
                      mdValue={9}
                    />
                  )}
                </Col>
                <Col md={12} className="mb-2">
                  <GroupTextProps
                    label="EC用宣伝文:"
                    value={video?.introEc || ''}
                    errorContent={errors[index]?.introEc}
                    onClickError={onClickError}
                    mdLable={3}
                    mdValue={9}
                  />
                  <GroupTextProps
                    label="著作権管理:"
                    value={
                      video?.includeCopyRightSong !== null
                        ? convertCopyRightSong(+video?.includeCopyRightSong)
                        : ''
                    }
                    errorContent={errors[index]?.includeCopyRightSong}
                    onClickError={onClickError}
                    mdLable={3}
                    mdValue={9}
                  />
                  <GroupTextProps
                    label="動画種別:"
                    value={video?.videoType !== null ? convertVideoType(+video?.videoType) : ''}
                    errorContent={errors[index]?.videoType}
                    onClickError={onClickError}
                    mdLable={3}
                    mdValue={9}
                  />
                  <GroupTextProps
                    label={'情報料:\n（みなし上代）'}
                    value={`${
                      video?.mediaInformationFee
                        ? `${formatPrice(video?.mediaInformationFee)}円(税抜)`
                        : '0円(税抜)'
                    }`}
                    errorContent={errors[index]?.mediaInformationFee}
                    onClickError={onClickError}
                    mdLable={3}
                    mdValue={9}
                    labelClass="text-prev-wrap"
                  />
                </Col>
                <Col md={7}>
                  <GroupTextProps label={`${calcMb(video?.size)}MB`} value={'  '} mdLable={8} />
                </Col>
              </Col>
            </Row>
            {video?.songs?.map((el: any, index: number) => {
              return (
                <>
                  <hr />
                  <Row key={index}>
                    <Col>
                      <GroupTextProps mdLable={5} label="曲名" value={el?.name} />
                      <GroupTextProps
                        mdLable={5}
                        label="曲名（フリガナ）"
                        value={el?.furiganaName}
                      />
                      <GroupTextProps
                        mdLable={5}
                        label="曲名（英語表記）"
                        value={el?.englishName}
                      />
                      {data?.jmdCooperation === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER && (
                        <GroupTextProps
                          mdLable={5}
                          label="日本レコード協会ジャンル"
                          value={riajGenreNameJp(el?.riajGenreId)}
                        />
                      )}
                      <GroupTextProps
                        mdLable={5}
                        label="IVT識別"
                        value={convertIvtType(el?.ivtType)}
                      />
                      <GroupTextProps mdLable={5} label="作詞者名" value={el?.musicianName} />
                      <GroupTextProps
                        mdLable={5}
                        label="作詞者名（フリガナ）"
                        value={el?.musicianFuriganaName}
                      />
                      <GroupTextProps
                        mdLable={5}
                        label="作詞者名（英語表記）"
                        value={el?.musicianEnglishName}
                      />
                      <GroupTextProps mdLable={5} label="作曲者名" value={el?.composerName} />
                      <GroupTextProps
                        mdLable={5}
                        label="作曲者名（フリガナ）"
                        value={el?.composerFuriganaName}
                      />
                      <GroupTextProps
                        mdLable={5}
                        label="作曲者名（英語表記）"
                        value={el?.composerEnglishName}
                      />
                      <GroupTextProps mdLable={5} label="編曲者名" value={el?.arrangerName} />
                      <GroupTextProps
                        mdLable={5}
                        label="編曲者名（フリガナ）"
                        value={el?.arrangerFuriganaName}
                      />
                      <GroupTextProps
                        mdLable={5}
                        label="編曲者名（英語表記）"
                        value={el?.arrangerEnglishName}
                      />
                    </Col>
                    <Col>
                      <GroupTextProps mdLable={5} label="演奏時間" value={el?.showTime} />
                      <GroupTextProps
                        mdLable={5}
                        label="原盤識別"
                        value={convertVersionIdentificationType(el?.versionIdentificationType)}
                      />

                      <GroupTextProps
                        mdLable={5}
                        label="ライブ区分"
                        value={convertLiveType(el?.liveType)}
                      />
                      <GroupTextProps
                        mdLable={5}
                        label="著作権信託先"
                        value={convertAuthorizedType(el?.authorizedGroupType, el?.authorizedGroup)}
                      />
                      <GroupTextProps mdLable={5} label="ISRC番号" value={el?.isrcCode} />
                      <GroupTextProps mdLable={5} label="JASRAC作品コード" value={el?.jasracCode} />
                      <GroupTextProps
                        mdLable={5}
                        label="JASRAC以外の作品コード"
                        value={el?.otherJasracCode}
                      />
                      <GroupTextProps
                        mdLable={5}
                        label="情報料(みなし上代)"
                        value={`${
                          el?.informationFee
                            ? `${formatPrice(el?.informationFee)}円(税抜)`
                            : '0円(税抜)'
                        }`}
                      />
                      <GroupTextProps
                        mdLable={5}
                        label="原詞訳詞区分"
                        value={convertLyricsClassifyType(el?.lyricsClassifyType)}
                      />
                    </Col>
                  </Row>
                </>
              );
            })}
            {!video?.songs?.length && errors[index]?.songs ? (
              <>
                <hr />
                <MessageError message={'著作権管理楽曲を登録してください'} />
              </>
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </CardContainer>
  );
}
