import React, { useCallback, useEffect, useState } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { CaretUpFill, CaretDownFill } from 'react-bootstrap-icons';
import { Button, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import MessageError from 'Components/Common/MessageError';
import { CourseTypes, subTypeOptions } from 'Constant/Campaign';
import { Prize } from 'Types/Page/Campaign/RegisterCampaign';
import { useAppDispatch } from 'App/Store';
import { setErrorRealTime, updateCourse } from 'App/Features/Campaign';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import useCampaign from 'Hooks/useCampaign';
import useMessage from 'Hooks/useMessage';
import { useWatch } from 'react-hook-form';
import { updateQuery } from 'Utils/Search';
import { toNumber } from 'lodash';
import { getMsgExceed100 } from 'Utils/Campaign';
interface RowTypes {
  first: string;
  second: string;
  third: string;
  className: string;
  isShowError?: boolean;
  handleEditItem?: () => void;
  isDisabled?: boolean;
  courseIndex?: number;
  prizeIndex: number;
  control?: any;
  setValue?: any;
  getValues?: any;
  prize?: Prize;
  watch: any;
  register: any;
}

interface CheckboxType {
  className: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: any) => void;
}

const RowHandler = SortableHandle(() => (
  <div className="me-3 d-flex flex-column" style={{ cursor: 'grabbing' }}>
    <CaretUpFill fontSize="12" />
    <CaretDownFill fontSize="12" />
  </div>
));

const CheckBox = ({ checked = false, className, disabled, onChange }: CheckboxType) => {
  return (
    <div className="text-center">
      <Form.Check
        type="checkbox"
        defaultChecked={checked}
        className={className}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  );
};

const TableRow = ({
  first,
  second,
  third,
  className,
  handleEditItem,
  isShowError = false,
  isDisabled,
  courseIndex = 0,
  prizeIndex = 0,
  getValues,
  setValue,
  prize,
  watch,
  control,
}: RowTypes): JSX.Element => {
  const dispatch = useAppDispatch();
  const { handleDisableDataCampaign } = useCampaign();
  const { openMessage } = useMessage();

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const getWidth = React.useMemo(() => {
    return getValues(`courses.${courseIndex}.type`) === 2 &&
      getValues(`courses.${courseIndex}.subType`) !== 2
      ? 300
      : 650;
  }, [courseIndex, getValues, mounted]);

  const prizes =
    (useWatch({
      control,
      name: `courses.${courseIndex}.prizes`,
    }) as Prize[]) || [];

  const roundUpNumber = (total: number): number => {
    let number = 0;
    if (!total) return 0;
    if (100 - total > 0) {
      number = 100 - total;
    } else {
      number = 0;
    }
    return toNumber(number.toFixed(2));
  };

  const handleChangWinRate = (value: number | string) => {
    const totalWinRate = prizes.reduce((total, currentValue: Prize) => {
      const currentWinRate = currentValue.winRate ? toNumber(currentValue.winRate) : 0;
      return total + currentWinRate;
    }, 0);

    const totalNotIncentives = prizes.reduce((total, currentValue: Prize) => {
      const currentWinRate =
        currentValue.winRate && !currentValue.isIncentive ? toNumber(currentValue.winRate) : 0;
      return total + currentWinRate;
    }, 0);

    if (!value) {
      let newCourse = watch(`courses.${courseIndex}`);
      const tmpPrizes = newCourse.prizes.map((prize: Prize, pIndex: number) => {
        let rate = prize.winRate;
        if (pIndex === prizeIndex) {
          rate = '';
        } else {
          if (prize.isIncentive) {
            rate = roundUpNumber(totalNotIncentives);
          }
        }
        return {
          ...prize,
          winRate: rate,
        };
      });
      const tmpTotalWinRate = tmpPrizes.reduce((total: number, currentValue: Prize) => {
        const currentWinRate = currentValue.winRate ? toNumber(currentValue.winRate) : 0;
        return total + currentWinRate;
      }, 0);
      newCourse = {
        ...newCourse,
        prizes: tmpPrizes,
        slipRate: 100 - tmpTotalWinRate > 0 ? (100 - tmpTotalWinRate).toFixed(2) : 0,
      };
      setValue(`courses.${courseIndex}`, newCourse);
      dispatch(updateCourse({ course: newCourse, courseIndex }));

      return;
    }

    if (value) {
      const isIncentiveItem = prizes.findIndex((item: Prize) => !!item.isIncentive);

      if (isIncentiveItem !== -1) {
        setValue(
          `courses.${courseIndex}.prizes.${isIncentiveItem}.winRate`,
          roundUpNumber(totalNotIncentives) > 0 ? roundUpNumber(totalNotIncentives) : '',
        );
        dispatch(
          setErrorRealTime({
            keys: ['prizeQuantityTooSmall'],
            statuses: [100 - totalNotIncentives < 0 || totalWinRate > 100],
            courseName: watch(`courses.${courseIndex}.name`),
          }),
        );

        if (100 - totalNotIncentives < 0) {
          dispatch(updateCourse({ course: watch(`courses.${courseIndex}`), courseIndex }));
          openMessage({
            variant: 'error',
            message: getMsgExceed100(watch('courses'), watch(`courses.${courseIndex}.name`)),
          });

          return;
        }
      }

      dispatch(
        setErrorRealTime({
          keys: ['exceed100', 'prizeQuantityTooSmall', 'max100'],
          statuses: [false, false, totalWinRate > 100 && isIncentiveItem < 0],
          courseName: watch(`courses.${courseIndex}.name`),
        }),
      );
      if (totalWinRate > 100 && isIncentiveItem < 0) {
        setValue(`courses.${courseIndex}.slipRate`, 0);
        dispatch(updateCourse({ course: watch(`courses.${courseIndex}`), courseIndex }));

        return;
      }
      if (totalWinRate - toNumber(value) > 100 && isIncentiveItem > 0) {
        setValue(`courses.${courseIndex}.slipRate`, 0);
        dispatch(updateCourse({ course: watch(`courses.${courseIndex}`), courseIndex }));
        openMessage({
          variant: 'error',
          message: getMsgExceed100(watch('courses'), watch(`courses.${courseIndex}.name`)),
        });

        return;
      }

      if (prizes[prizeIndex]?.isIncentive) {
        setValue(`courses.${courseIndex}.slipRate`, 0);
      } else {
        setValue(
          `courses.${courseIndex}.slipRate`,
          100 - totalWinRate > 0 ? (100 - totalWinRate).toFixed(2) : 0,
        );
      }

      dispatch(updateCourse({ course: watch(`courses.${courseIndex}`), courseIndex }));
    }
  };

  const handleCheckBox = useCallback(
    (checked: boolean) => {
      setValue(`courses.${courseIndex}.prizes.${prizeIndex}.isIncentive`, checked);
      const tmpPrizes = [...prizes];
      const totalNotIncentives = tmpPrizes
        .filter((_, index: number) => index !== prizeIndex)
        .reduce((total, currentValue: Prize) => {
          const currentWinRate = currentValue.winRate ? toNumber(currentValue.winRate) : 0;
          return total + currentWinRate;
        }, 0);

      tmpPrizes.forEach((item: Prize, index) => {
        if (index === prizeIndex) {
          item.isIncentive = checked;
        } else {
          item.isIncentive = false;
        }

        if (item.isIncentive) {
          if (roundUpNumber(totalNotIncentives) <= 0) {
            let rate = item.winRate;
            if (100 - totalNotIncentives >= 0) {
              rate = 100 - totalNotIncentives;
            } else {
              rate = '';
            }

            if (totalNotIncentives >= 100) {
              rate = 100;
            }
            item.winRate = rate;
            console.log('first', item.name, item.winRate, 100 - totalNotIncentives);
          } else {
            console.log('second', item.name, item.winRate);
            item.winRate =
              roundUpNumber(totalNotIncentives) > 0 ? roundUpNumber(totalNotIncentives) : '';
          }
        } else {
          // TODO: case all item has win rate 0%
          const isIncentiveItem = tmpPrizes.find(
            (el: Prize) => el.isIncentive && toNumber(el.winRate) >= 100,
          );
          if (toNumber(item.winRate) >= 100 && isIncentiveItem) {
            item.winRate = '';
          }
        }
        if (!checked && totalNotIncentives <= 0) {
          item.winRate = '';
        }
      });

      const totalWinRate = tmpPrizes.reduce((total, currentValue: Prize) => {
        const currentWinRate = currentValue.winRate ? toNumber(currentValue.winRate) : 0;
        return total + currentWinRate;
      }, 0);

      setValue(`courses.${courseIndex}.prizes`, JSON.parse(JSON.stringify(tmpPrizes)));
      setValue(`courses.${courseIndex}.slipRate`, roundUpNumber(totalWinRate));
      if (!checked && totalNotIncentives <= 0) {
        setValue(`courses.${courseIndex}.slipRate`, 100);
      }

      console.log(watch(`courses.${courseIndex}`));
      dispatch(updateCourse({ course: watch(`courses.${courseIndex}`), courseIndex }));
    },
    [courseIndex, prizeIndex, prizes],
  );

  const debounceCheckbox = useCallback(
    (checked: boolean) =>
      updateQuery(checked, () => {
        handleCheckBox(checked);
      }),
    [handleCheckBox],
  );

  useEffect(() => {
    if (getValues(`courses.${courseIndex}.subType`) === subTypeOptions[1].value) {
      const otherSumWinRate = prizes.reduce((total, currentValue: Prize, currentIndex: number) => {
        const currentWinRate =
          currentValue.winRate && currentIndex !== +prizeIndex ? toNumber(currentValue.winRate) : 0;
        return total + currentWinRate;
      }, 0);

      dispatch(
        setErrorRealTime({
          keys: ['prizeQuantityTooSmall'],
          statuses: [otherSumWinRate >= 100],
          courseName: `${watch(`courses.${courseIndex}.name`)}`,
        }),
      );
    }
  }, [getValues(`courses.${courseIndex}.subType`), prizes.length]);

  return (
    <tr className={`tr-drag ${className}`}>
      <td
        style={{
          width: `${getWidth}px`,
        }}
      >
        <div className="d-flex align-items-center">
          <div
            className={`${
              isDisabled
                ? 'pe-none user-select-none opacity-50'
                : 'pe-auto user-select-auto opacity-100'
            }`}
          >
            <RowHandler />
          </div>
          <div className="d-flex align-items-center flex-column">
            <OverlayTrigger overlay={<Tooltip id={first}>{first}</Tooltip>}>
              <div
                className="text-truncate text-nowrap"
                style={{
                  maxWidth: `${getWidth}px`,
                }}
              >
                {first}
              </div>
            </OverlayTrigger>
            <div className="d-flex flex-column align-self-start">
              {isShowError ? <MessageError message="未登録コンテンツあり" /> : <></>}
            </div>
          </div>
        </div>
      </td>
      <td>{second}</td>
      <td
        className={`${
          (getValues(`courses.${courseIndex}.subType`) === subTypeOptions[2].value ||
            getValues(`courses.${courseIndex}.type`) !== CourseTypes.REALTIME) &&
          'text-center'
        }`}
      >
        <div
          className={`${
            (getValues(`courses.${courseIndex}.subType`) !== subTypeOptions[2].value ||
              getValues(`courses.${courseIndex}.type`) !== CourseTypes.REALTIME) &&
            'd-block winRate-column-type1'
          }`}
        >
          {third}
        </div>
      </td>
      {getValues(`courses.${courseIndex}.type`) === CourseTypes.REALTIME &&
        getValues(`courses.${courseIndex}.subType`) !== subTypeOptions[2].value && (
          <td>
            {((watch(`courses.${courseIndex}.subType`) === subTypeOptions[0].value &&
              watch(`courses.${courseIndex}.prizeQuantity`)) ||
              prize?.isIncentive) && (
              <div className="d-block winRate-column-type1">
                {prizes[prizeIndex]?.winRate ? `${prizes[prizeIndex]?.winRate}%` : '0%'}
              </div>
            )}
            <td></td>

            {getValues(`courses.${courseIndex}.subType`) === subTypeOptions[1].value &&
              !prize?.isIncentive && (
                <div className="d-block winRate-column-type1 text-right">
                  <FormInputNumber
                    name={`winRate-${prizeIndex}`}
                    label=""
                    labelMd="1"
                    colMd="12"
                    classCol="position-relative"
                    value={
                      getValues(`courses.${courseIndex}.prizes.${prizeIndex}.winRate`) !== 0
                        ? getValues(`courses.${courseIndex}.prizes.${prizeIndex}.winRate`)
                        : ''
                    }
                    decimalScale={2}
                    isReview={handleDisableDataCampaign(getValues(`courses.${courseIndex}`)?.id)}
                    classForm="form-winRate"
                    parentClass=""
                    allowNegative={false}
                    allowLeadingZeros={false}
                    minValue={1}
                    onBlurInput={(number) => {
                      setValue(`courses.${courseIndex}.prizes.${prizeIndex}.winRate`, number);
                      handleChangWinRate(number);
                    }}
                  />
                </div>
              )}
          </td>
        )}
      {getValues(`courses.${courseIndex}.type`) === CourseTypes.REALTIME ? (
        <>
          <td>
            {getValues(`courses.${courseIndex}.subType`) !== subTypeOptions[1].value &&
              prizes[prizeIndex]?.isIncentive && (
                <CheckBox
                  checked
                  className={
                    'checkbox-no-label check-box-size check-box-ct check-box-step3-subType-0 pe-none'
                  }
                />
              )}

            {getValues(`courses.${courseIndex}.subType`) === subTypeOptions[1].value &&
              !prizes[prizeIndex]?.userQuantity && (
                <Form.Check
                  type="checkbox"
                  defaultChecked={prizes[prizeIndex]?.isIncentive}
                  checked={prizes[prizeIndex]?.isIncentive}
                  className={`checkbox-no-label check-box-size check-box-ct check-box-step3`}
                  onChange={(event) => {
                    debounceCheckbox(event?.target?.checked);
                  }}
                  disabled={handleDisableDataCampaign(getValues(`courses.${courseIndex}`)?.id)}
                />
              )}
          </td>
        </>
      ) : (
        <></>
      )}

      <td>
        <div className="text-end">
          <Button onClick={handleEditItem}>編集</Button>
        </div>
      </td>
    </tr>
  );
};

export default TableRow;
