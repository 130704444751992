import Search from 'Components/Common/Search/Search';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { Button, Col, Spinner } from 'react-bootstrap';
import { IDatePicker } from 'Types/Common';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import useSuggestion from 'Hooks/useSuggestion';
import { DATE_FORMAT_4 } from 'Constant/Date';
import DateSelectorDashboard from 'Components/Common/DateSelector/DateSelectorDashboard';
import { updateQuery } from 'Utils/Search';

interface SearchDashBoard {
  isMonth?: boolean;
  onExportExcel?: () => void;
  loadingExcel?: boolean;
}

export default function SearchDashBoard({
  onExportExcel,
  loadingExcel,
}: SearchDashBoard): JSX.Element {
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    variationIds: StringParam,
    isOption: NumberParam,
    queryVariation: StringParam,
    artistId: StringParam,
    titleId: StringParam,
    variationId: StringParam,
  });

  const {
    artistOptions,
    titleOptions,
    variationOptions,
    loadingArtist,
    loadingTitle,
    loadingVariation,
    handleLoadMore,
    handleFocusInput,
    handleEnterPrimary,
    handleClickItemPrimary,
  } = useSuggestion({
    fetchList: ['artist', 'title', 'variation'],
    showDraftVariation: true,
  });

  const onChangeDate = useCallback((date: any, type: string) => {
    const checkDate = dayjs(date).format(DATE_FORMAT_4);
    if (!date) {
      updateQuery('', () => {
        setQuery({ [type]: '', page: 1 });
      });
      return;
    } else if (dayjs(checkDate, DATE_FORMAT_4, true).isValid()) {
      updateQuery(date, () => {
        setQuery({ [type]: dayjs(date), page: 1 });
      });
    }
    return;
  }, []);

  const subtractMinYears = (date: any) => {
    if (date) {
      const dateCopy = new Date(date);
      dateCopy.setFullYear(date.getFullYear() - 3);
      return dateCopy;
    }
    return;
  };
  const subtractMaxYears = (date: any) => {
    if (date) {
      const dateCopy = new Date(date);
      dateCopy.setFullYear(date.getFullYear() + 3);
      return dateCopy;
    }
    return;
  };

  return (
    <>
      <Col md="2" className="px-1 ps-3">
        <Search
          label="アーティスト"
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          classLabel="px-0"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryArtist"
          onEnter={() => handleEnterPrimary('queryArtist')}
          onClickResult={handleClickItemPrimary}
          options={artistOptions.options || []}
          loading={loadingArtist}
          onLoadMore={() => handleLoadMore('pageArtist')}
          onFocusInput={() => {
            handleFocusInput('queryArtist', 'pageArtist');
          }}
        />
      </Col>
      <Col md="2" className="px-1 ps-3">
        <Search
          label="タイトル"
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          classLabel="px-0"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryTitle"
          onEnter={() => handleEnterPrimary('queryTitle')}
          onClickResult={handleClickItemPrimary}
          options={titleOptions.options || []}
          loading={loadingTitle}
          onLoadMore={() => handleLoadMore('pageTitle')}
          onFocusInput={() => {
            handleFocusInput('queryTitle', 'pageTitle');
          }}
        />
      </Col>
      <Col md="2" className="px-1 ps-3">
        <Search
          label="バリエーション"
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          classLabel="px-0"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryVariation"
          onEnter={() => handleEnterPrimary('queryVariation')}
          onClickResult={handleClickItemPrimary}
          options={variationOptions.options || []}
          loading={loadingVariation}
          onLoadMore={() => handleLoadMore('pageVariation')}
          onFocusInput={() => {
            handleFocusInput('queryVariation', 'pageVariation');
          }}
        />
      </Col>
      <>
        <Col md="2" className="ps-3 pe-2">
          <DateSelectorDashboard
            label="開始日"
            labelMd="12"
            placeholder="開始日"
            dateFormat="yyyy/MM/dd"
            showTime={false}
            minDate={query.endDate ? subtractMinYears(new Date(query.endDate)) : null}
            maxDate={query.endDate ? new Date(query.endDate) : null}
            onChange={(dateStart: IDatePicker) => onChangeDate(dateStart, 'startDate')}
            selected={query.startDate ? new Date(query.startDate) : null}
            classNameDateSelect="w-85"
          />
        </Col>
        <Col md="2" className="ps-3 pe-2">
          <DateSelectorDashboard
            label="終了日"
            labelMd="12"
            placeholder="終了日"
            dateFormat="yyyy/MM/dd"
            showTime={false}
            minDate={query.startDate ? new Date(query.startDate) : null}
            maxDate={query.startDate ? subtractMaxYears(new Date(query.startDate)) : null}
            onChange={(dateEnd: IDatePicker) => onChangeDate(dateEnd, 'endDate')}
            selected={query.endDate ? new Date(query.endDate) : null}
            classNameDateSelect="w-85"
            wrapperClass="endDateRevenue"
          />
        </Col>
      </>
      <Col md="2" className="mt-4 d-flex justify-content-center align-items-center">
        <Button
          className={`btn-focus-none px-3 prize-btn-excel button-submit ${
            loadingExcel ? 'pe-none' : ''
          }`}
          onClick={onExportExcel}
        >
          {loadingExcel ? (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'Excelダウンロード'
          )}
        </Button>
      </Col>
    </>
  );
}
