import React, { memo } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { ColSpec } from 'Components/Common/Form/form';
import { UseFormRegisterReturn } from 'react-hook-form';
import MessageError from 'Components/Common/MessageError';
// import { isEmpty, isEqual, xorWith } from 'lodash';

interface Props {
  id: string;
  name?: string;
  labelForm?: string | null;
  classForm?: string | null;
  errorMessage?: string;
  labelMd?: ColSpec;
  colMd?: ColSpec;
  onChange?: (option: boolean) => void;
  value?: string | number | undefined | null;
  register?: UseFormRegisterReturn;
  disabled?: boolean;
  isReview?: boolean;
  colClass?: string;
  classBoxErr?: string;
  classOption?: string;
  checkboxClass?: string;
  checked?: boolean;
  onClickError?: () => void;
  hintText?: string;
}

function ECSwitch(props: Props): JSX.Element {
  const {
    id,
    name,
    labelForm,
    classForm,
    colClass,
    errorMessage,
    colMd,
    onChange,
    register,
    disabled = false,
    classBoxErr,
    checked,
    classOption = '',
    checkboxClass,
    onClickError,
  } = props;

  const handleOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) {
      return;
    }
    onChange(e?.target?.checked);
  };

  return (
    <Form.Group as={Row} className={`${classForm ?? ''} `}>
      <Col md={colMd} className={`align-self-center ${colClass} `}>
        <div
          className={`d-flex w-100 align-items-center box-checkBox flex-wrap ${checkboxClass} ${
            disabled
              ? 'pe-none user-select-none opacity-50'
              : 'pe-auto user-select-auto opacity-100'
          }`}
        >
          <Form.Check
            {...register}
            inline
            label={labelForm}
            name={name || 'check-box-group'}
            type="switch"
            onChange={(e) => handleOnchange(e)}
            id={`${id}`}
            checked={checked}
            className={`mb-0 ec-form-switch ${classOption}`}
          />
        </div>
        <div className={classBoxErr} onClick={onClickError} aria-hidden="true">
          <MessageError message={errorMessage} />
        </div>
      </Col>
    </Form.Group>
  );
}

export default memo(ECSwitch);
