/* eslint-disable @typescript-eslint/no-unused-vars */
import { JmdDataGenre } from 'App/Features/Title';
import { TitleApi } from 'Datasource/TitleApi';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

const JMDAudios: React.FC = () => {
  const [listJmdMusicGenreData, setListJmdMusicGenreData] = useState<JmdDataGenre[]>([]);
  const [result, setResult] = useState<any[][]>([]);
  const getListJmdMusicGenre = async () => {
    const {
      data: { data },
    } = await TitleApi.getJmdMusicGenre();

    if (data.length > 0) {
      setListJmdMusicGenreData(data);
    }
  };

  useEffect(() => {
    getListJmdMusicGenre();
  }, []);

  const dataConverted = (items: any[], level: number) => {
    let array: any[] = [];
    for (const item of items) {
      const label = item.genre1 || item.genre2 || item.genre3 || item.genre4;
      array.push({
        label,
        rowspan: getRowspan(item.child || []),
        level: level,
      });

      if (item.child) {
        array = [...array, ...dataConverted(item.child, level + 1)];
      }
    }

    return array;
  };

  const getRowspan = (items: any[]) => {
    let rowspan = 0;
    for (const item of items) {
      if (item.child) {
        rowspan += getRowspan(item.child);
      } else {
        rowspan++;
      }
    }

    return rowspan;
  };

  useEffect(() => {
    if (listJmdMusicGenreData.length <= 0) return;
    const items = dataConverted(listJmdMusicGenreData, 1);

    let result = [];

    for (const [index, item] of items.entries()) {
      if (items[index - 1] && items[index - 1].rowspan > 0) {
        const lastResultIndex = result.length - 1;
        result[lastResultIndex].push(item);
      } else {
        result.push([item]);
      }
    }

    result = result.map((items) => {
      const maxLevel = Math.max(...items.map((item) => item.level));
      for (let i = 0; i < 4 - maxLevel; i++) {
        items.push({
          label: '',
          rowspan: 0,
        });
      }

      return items;
    });

    setResult(result);
  }, [listJmdMusicGenreData]);

  return (
    <Container className="my-4 mx-auto">
      <h4 className="text-center font-bold mb-3">JMD音楽ジャンル表</h4>
      <table className="border table-bordered">
        <thead className="text-white bg-dark text-center">
          <tr>
            <th className="py-1">分類1</th>
            <th className="py-1">分類2</th>
            <th className="py-1">分類3</th>
            <th className="py-1">分類4</th>
          </tr>
        </thead>
        <tbody className="border">
          {result.map((items, index) => (
            <tr key={index}>
              {items.map((item, inx) => (
                <td key={inx} rowSpan={item.rowspan || 1} width={400} className="p-1">
                  {item.label}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default JMDAudios;
