import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INCLUDE_JARSAC_CONTENT, STEPS } from 'Constant/Title';
import { isEmpty } from 'lodash';
import { Song, Video, Image, Step, Title, Variation, Capacity } from 'Types/App/title';
import { getInformationFeeManual } from 'Utils/Title';

export interface JmdDataGenre {
  child: {
    cd: string;
    id: number;
    nameJp: string;
    nameKana: string;
    genre2: string;
    child: {
      cd: string;
      id: number;
      nameJp: string;
      nameKana: string;
      genre3: string;
      child: {
        cd: string;
        id: number;
        nameJp: string;
        nameKana: string;
        genre4: string;
      }[];
    }[];
  }[];
  genre1: string;
}

interface StatusFetchingOptions {
  id: number | null | undefined;
  status: boolean;
}

interface TitleState {
  currentStep: Step;
  titleForm: Title;
  role: number;
  artistName: string;
  isValidate: boolean;
  isValidateNameVariation: boolean;
  isValidateSaveDraft: boolean;
  fetchTitleFail?: boolean;
  triggerMedia?: string | null;
  caculateFee?: boolean;
  titleDuplicateId: null | number; // Fix bug 92531
  statusFetchingOptions: StatusFetchingOptions[];
}

const initialState: TitleState = {
  currentStep: STEPS.CREATE_TITLE_INFO,
  role: 1,
  artistName: '',
  isValidate: false,
  isValidateNameVariation: false,
  isValidateSaveDraft: false,
  titleForm: {
    id: null,
    isValidate: null,
    status: null,
    artistId: null,
    artistName: null,
    name: null,
    furiganaName: null,
    format: null,
    label: null,
    musicGenreId: null,
    variations: [],
    sellIap: 0,
  },
  fetchTitleFail: false,
  triggerMedia: null,
  caculateFee: false,
  titleDuplicateId: null, // Fix bug 92531
  statusFetchingOptions: [],
};

export const titleSlice = createSlice({
  name: 'title',
  initialState,
  reducers: {
    setForm: (state, data: PayloadAction<TitleState>) => {
      const { payload } = data;
      return { ...payload };
    },
    setTriggerValidate: (state, data: PayloadAction<boolean>) => {
      const { payload } = data;
      state.isValidate = payload;
    },
    setTriggerValidateNameVariation: (state, data: PayloadAction<boolean>) => {
      const { payload } = data;
      state.isValidateNameVariation = payload;
    },
    setTriggerSaveDraft: (state, data: PayloadAction<boolean>) => {
      const { payload } = data;
      state.isValidateSaveDraft = payload;
    },
    setCurrentStep: (state, data: PayloadAction<Step>) => {
      const payload = data.payload;
      state.currentStep = payload;
    },
    setTitleForm: (state, data: PayloadAction<Partial<Title>>) => {
      const payload = data.payload;
      const titleForm: Title = { ...state.titleForm };
      Object.keys(payload).map((key) => {
        if (typeof payload[key] !== 'undefined') {
          titleForm[key] = payload[key];
        }
      });

      const optionsLoadingInit: StatusFetchingOptions[] = titleForm.variations.map((v) => {
        return { id: v.id, status: false };
      });

      state.titleForm = titleForm;
      state.statusFetchingOptions = optionsLoadingInit;
    },
    setVariations: (state, data: PayloadAction<{ variations: Variation[] }>) => {
      const payload = data.payload;

      const { variations } = payload;
      const titleForm: Title = { ...state.titleForm };
      titleForm.variations = variations;
      state.titleForm = titleForm;
    },
    setOption: (state, data: PayloadAction<{ option: string; variationIndex: number }>) => {
      const payload = data.payload;
      const { option, variationIndex } = payload;
      const titleForm: Title = { ...state.titleForm };
      titleForm.variations[variationIndex].option = option;
      state.titleForm = titleForm;
    },
    setCoverImage: (state, data: PayloadAction<{ urlImage: string; variationIndex: number }>) => {
      const payload = data.payload;
      const { urlImage, variationIndex } = payload;
      const titleForm: Title = { ...state.titleForm };
      titleForm.variations[variationIndex].coverImageUrl = urlImage;
      state.titleForm = titleForm;
    },
    setBooklets: (state, data: PayloadAction<{ booklets: any; variationIndex: number }>) => {
      const payload = data.payload;
      const { booklets, variationIndex } = payload;
      const titleForm: Title = { ...state.titleForm };
      titleForm.variations[variationIndex].booklets = booklets;
      state.titleForm = titleForm;
    },
    setVariationSongs: (state, data: PayloadAction<{ songs: Song[]; variationIndex: number }>) => {
      const payload = data.payload;
      const { songs, variationIndex } = payload;
      const titleForm: Title = { ...state.titleForm };
      titleForm.variations[variationIndex].songs = songs;
      state.titleForm = titleForm;
    },
    setVariationVideos: (
      state,
      data: PayloadAction<{ videos: Video[]; variationIndex: number }>,
    ) => {
      const payload = data.payload;
      const { videos, variationIndex } = payload;
      const titleForm: Title = { ...state.titleForm };
      titleForm.variations[variationIndex].videos = videos.map((video: Video) => ({
        ...video,
        mediaInformationFee:
          titleForm.variations[variationIndex].includeJasracContent !==
          INCLUDE_JARSAC_CONTENT.INCLUDE
            ? getInformationFeeManual(video?.songs || [])
            : video.mediaInformationFee,
      }));
      state.titleForm = titleForm;
    },
    setVariationVideoSongs: (
      state,
      data: PayloadAction<{ songs: Song[]; variationIndex: number; videoIndex: number }>,
    ) => {
      const { songs, variationIndex, videoIndex } = data.payload;
      const variations: Variation[] = state.titleForm.variations;
      variations[variationIndex].videos[videoIndex].songs = songs;

      if (
        !isEmpty(songs) &&
        variations[variationIndex].includeJasracContent !== INCLUDE_JARSAC_CONTENT.INCLUDE
      ) {
        variations[variationIndex].videos[videoIndex].mediaInformationFee =
          getInformationFeeManual(songs);
      }
    },
    addVariationAction: (state, data: PayloadAction<{ variation: any }>) => {
      const { variation } = data.payload;
      const titleForm: Title = { ...state.titleForm };
      titleForm.variations.push(variation);
      state.titleForm = titleForm;
    },
    setVariationImages: (
      state,
      data: PayloadAction<{ images: Image[]; variationIndex: number }>,
    ) => {
      const payload = data.payload;
      const { images, variationIndex } = payload;
      const titleForm: Title = { ...state.titleForm };
      titleForm.variations[variationIndex].images = images;
      state.titleForm = titleForm;
    },
    deleteVariationAction: (state, data: PayloadAction<number>) => {
      const index = data.payload;
      const titleForm: Title = { ...state.titleForm };
      titleForm.variations.splice(index, 1);
      state.titleForm = titleForm;
    },
    resetFormTitleDataAction: () => {
      return initialState;
    },
    setArtistName: (state, data: PayloadAction<string>) => {
      const artistName = data.payload;
      state.artistName = artistName;
    },
    //* For Capacity
    setCapacity: (state, data: PayloadAction<{ capacity: Capacity; variationIndex: number }>) => {
      const { capacity, variationIndex } = data.payload;
      const titleForm: Title = { ...state.titleForm };
      titleForm.variations[variationIndex].capacity = +capacity || 0;
      state.titleForm = titleForm;
    },
    //* For Capacity

    //* For getDetail title fail
    setFetchFail: (state, data: PayloadAction<boolean>) => {
      const { payload } = data;
      state.fetchTitleFail = payload;
    },

    //* trigger scroll validate media
    setTriggerMedia: (state, data: PayloadAction<string | null>) => {
      const { payload } = data;
      state.triggerMedia = payload;
    },

    //* trigger caculate informationFee from parant
    setCaculateFee: (state, data: PayloadAction<boolean>) => {
      const { payload } = data;
      state.caculateFee = payload;
    },

    setTitleDuplicateId: (state, data: PayloadAction<null | number>) => {
      const { payload } = data;
      state.titleDuplicateId = payload;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setStatusFetchOption: (state, data: PayloadAction<StatusFetchingOptions>) => {
      const { payload } = data;
      const statusTmp = [...state.statusFetchingOptions];
      const rs = statusTmp.map((status) => {
        if (status.id === payload.id) {
          return { id: status.id, status: payload.status };
        }

        return status;
      });

      state.statusFetchingOptions = rs;
    },
  },
});

export const {
  setTitleForm,
  setCurrentStep,
  setForm,
  setVariationSongs,
  addVariationAction,
  deleteVariationAction,
  setVariations,
  resetFormTitleDataAction,
  setArtistName,
  setBooklets,
  setTriggerValidate,
  setTriggerValidateNameVariation,
  setTriggerSaveDraft,
  setVariationVideos,
  setVariationVideoSongs,
  setVariationImages,
  setCapacity,
  setCoverImage,
  setOption,
  setFetchFail,
  setTriggerMedia,
  setCaculateFee,
  setTitleDuplicateId,
  setStatusFetchOption,
} = titleSlice.actions;
export default titleSlice.reducer;
