import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { login } from 'App/Features/Auth';
import { useAppDispatch } from 'App/Store';
import { ERROR } from 'Constant/Error';
import _ from 'lodash';
import { Button, FormControl, FormGroup, FormLabel, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { emailValidation } from 'Utils/Validation';
import * as yup from 'yup';
import { AuthApi } from 'Datasource/Auth';
import MessageError from 'Components/Common/MessageError';
import useMessage from 'Hooks/useMessage';
import { ACCOUNT_STATUS_ACTIVE, PREFIX_WEB_APP } from 'Constant';
interface ILoginForm {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: emailValidation({
    required: ERROR['no fill out of email field'],
    email: ERROR['valid email address'],
  }),
  password: yup.string().required('入力してください。'),
});

export default function WebAppLoginForm(): JSX.Element {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { openMessageError } = useMessage();
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<ILoginForm>({ mode: 'onSubmit', resolver: yupResolver(schema) });

  const onSubmit = async (params: ILoginForm) => {
    if (!_.isEmpty(errors)) return;
    try {
      const {
        data: { data },
      } = await AuthApi.loginApi(params);
      dispatch(login(data));
      history.push(
        data.status === ACCOUNT_STATUS_ACTIVE ? `${PREFIX_WEB_APP}/search` : '/change-password',
      );
    } catch (error) {
      openMessageError(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pt-2">
      <div className="py-4">
        <FormGroup as={Row} className="mb-3">
          <FormLabel className="fs-16">ログインメールアドレス</FormLabel>
          <FormControl
            {...register('email')}
            disabled={isSubmitting}
            type="text"
            aria-label="email"
            aria-describedby="input--email"
          />
        </FormGroup>

        <FormGroup as={Row} className="mb-3">
          <FormLabel className="fs-16">パスワード</FormLabel>
          <FormControl
            {...register('password')}
            disabled={isSubmitting}
            type="password"
            aria-label="password"
            aria-describedby="input--password"
            value={watch('password')}
            onChange={({ target: { value } }) => setValue('password', value)}
          />
        </FormGroup>

        <FormGroup className="d-flex justify-content-end">
          <Link to="/staff/forgot-password" className="white always:color-white fs-16">
            パスワードを忘れた方はこちら
          </Link>
        </FormGroup>
        <FormGroup className="mt-3">
          {!_.isEmpty(errors) && (
            <MessageError message={'ログインメールアドレスまたはパスワードが一致しません。'} />
          )}
        </FormGroup>
      </div>
      <FormGroup className="d-flex justify-content-center">
        <Button
          type="submit"
          className="bg-black border-0 d-flex align-items-center justify-content-center w-200 mt-3 fs-16"
          disabled={isSubmitting}
        >
          {isSubmitting && (
            <Spinner animation="border" role="status" variant="light" size="sm" className="me-2">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          <p>LOGIN</p>
        </Button>
      </FormGroup>
    </form>
  );
}
