/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState } from 'react';
import Filter from 'Components/Common/Filter/Filter';
import { Row } from 'react-bootstrap';
import ColumnNoSpacing from 'Components/Common/Column/ColumnNoSpacing';
import InputDateRangePicker from 'Components/Common/DateRangePicker/InputDateRangePicker';
import Search from 'Components/Common/Search/Search';

const FilterFanProfile = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState([]);
  const handleEnter = useCallback(
    (type: string) => {
      return false;
    },
    // [res, query, loading],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleClickItem = useCallback(
    (id: string, type: string) => {
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <>
      <Filter filterClass="shadow-sm ">
        <Row className="mb-3 justify-content-center">
          <ColumnNoSpacing
            md="1"
            colClass="pe-3 fw-bold d-flex align-items-center justify-content-end fs-18"
          >
            A
          </ColumnNoSpacing>
          <ColumnNoSpacing md="3" colClass="pe-1">
            <Search
              label="アーティスト"
              labelMd="4"
              colMd="8"
              placeholder="検索"
              showIcon
              classLabel="px-0"
              rowClass="w-100"
              colClass="px-0"
              searchKey="queryArtist"
              onEnter={() => handleEnter('queryArtist')}
              onClickResult={handleClickItem}
              options={res[0]}
              loading={loading}
            />
          </ColumnNoSpacing>
          <ColumnNoSpacing md="3" colClass="pe-1">
            <Search
              label="アーティスト"
              labelMd="4"
              colMd="8"
              placeholder="検索"
              showIcon
              classLabel="px-0"
              rowClass="w-100"
              colClass="px-0"
              searchKey="queryArtist"
              onEnter={() => handleEnter('queryArtist')}
              onClickResult={handleClickItem}
              options={res[0]}
              loading={loading}
            />
          </ColumnNoSpacing>
          <ColumnNoSpacing md="2">
            <InputDateRangePicker />
          </ColumnNoSpacing>
        </Row>
        <Row className="justify-content-center">
          <ColumnNoSpacing
            md="1"
            colClass="pe-3 fw-bold d-flex align-items-center justify-content-end fs-18"
          >
            B
          </ColumnNoSpacing>
          <ColumnNoSpacing md="3" colClass="pe-1">
            <Search
              label="アーティスト"
              labelMd="4"
              colMd="8"
              placeholder="検索"
              showIcon
              classLabel="px-0"
              rowClass="w-100"
              colClass="px-0"
              searchKey="queryArtist"
              onEnter={() => handleEnter('queryArtist')}
              onClickResult={handleClickItem}
              options={res[0]}
              loading={loading}
            />
          </ColumnNoSpacing>
          <ColumnNoSpacing md="3" colClass="pe-1">
            <Search
              label="アーティスト"
              labelMd="4"
              colMd="8"
              placeholder="検索"
              showIcon
              classLabel="px-0"
              rowClass="w-100"
              colClass="px-0"
              searchKey="queryArtist"
              onEnter={() => handleEnter('queryArtist')}
              onClickResult={handleClickItem}
              options={res[0]}
              loading={loading}
            />
          </ColumnNoSpacing>
          <ColumnNoSpacing md="2">
            <InputDateRangePicker />
          </ColumnNoSpacing>
        </Row>
      </Filter>
    </>
  );
};

export default FilterFanProfile;
