import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import _isEmpty from 'lodash/isEmpty';
import { Button, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { emailValidation } from 'Utils/Validation';
import * as yup from 'yup';
import { AuthApi } from 'Datasource/Auth';
import MessageError from 'Components/Common/MessageError';
import useMessage from 'Hooks/useMessage';
import SpinnerComponent from 'Components/Common/SpinnerComponent';

const schema = yup.object().shape({
  email: emailValidation({
    required: 'メールアドレスを入力してください。',
    email: '有効なメールアドレスを入力してください。',
  }),
});

export default function ForgotPasswordForm(): JSX.Element {
  const { openMessageError } = useMessage();
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{ email: string }>({ mode: 'onSubmit', resolver: yupResolver(schema) });

  const onSubmit = handleSubmit((data: { email: string }) => {
    const handleSendMailForget = async (data: { email: string }) => {
      if (_isEmpty(errors)) {
        try {
          setLoading(true);
          await AuthApi.forgetPassword(data);
          setLoading(false);
          setIsSuccess(true);
        } catch (error) {
          setLoading(false);
          openMessageError(error);
        }
      }
    };
    handleSendMailForget(data);
  });

  return isSuccess ? (
    <div className="text-center">
      入力されたメールアドレスへパスワード再設定用のURLを送信しました。
      <br />
      そのURLにアクセスしてパスワードを再設定ください。
    </div>
  ) : (
    <>
      <SpinnerComponent isLoading={loading} />
      <form onSubmit={onSubmit} className="public-card-width">
        <FormGroup className="mb-3">
          <FormLabel>ログインメールアドレス</FormLabel>
          <FormControl
            {...register('email')}
            type="text"
            aria-label="email"
            aria-describedby="input--email"
          />
          <MessageError message={errors.email?.message} />
          <div className="mt-2 w-max-content">
            入力されたメールアドレスへパスワード再設定用のURLを送信します。
          </div>
        </FormGroup>

        <FormGroup className="mt-5 d-flex justify-content-center">
          <Button type="submit" className="bg-black border-0 pe-5 ps-5">
            送信
          </Button>
        </FormGroup>
      </form>
    </>
  );
}
