import React, { useState, useEffect } from 'react';
import BoxForm from 'Components/Common/Form/BoxForm';
import SearchSampleMasterDp from 'Page/ListProducts/SearchSampleMasterDp';
import { Col, Button, Row, Container } from 'react-bootstrap';
import TableSampleMasterDp from 'Components/Page/ListProducts/TableSampleMasterDp';
import TableSampleMasterDpResponse from 'Components/Page/ListProducts/TableSampleMasterDpResponse';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';
import { useQueryParams, StringParam } from 'use-query-params';
import { SampleMasterApi } from 'Datasource/SampleMasterApi';

interface IDataExcel {
  deleted: DeleteType;
  email?: string;
  id: number | string;
  miimID: string;
  realName: string;
}

export enum DeleteType {
  NOT_DELETE = 0,
  DELETED = 1,
}

export default function SaleProductDetail(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [dataExcel, setDataExcel] = useState<IDataExcel[]>([]);
  const [dataUserReleased, setUserReleased] = useState<IDataExcel[]>([]);
  const { openMessageError } = useMessage();

  const [query] = useQueryParams({ variationId: StringParam });

  const getDataExel = (data: any) => {
    setDataExcel([...dataExcel, ...data]);
  };

  const fetchDataReleased = async () => {
    if (!query.variationId) {
      setUserReleased([]);
      return;
    }
    try {
      setLoading(true);
      const {
        data: { data },
      } = await SampleMasterApi.getListUserReleasedByVaritionId({
        variationId: query.variationId,
      });
      setUserReleased(data);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataReleased();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.variationId]);

  const handleReleaseSample = async () => {
    if (dataExcel.length > 0) {
      const dataReleaseFormat: number[] = dataExcel
        .filter((v: IDataExcel) => v.deleted === DeleteType.NOT_DELETE)
        .map((el: IDataExcel) => +el.id);
      try {
        setLoading(true);
        await SampleMasterApi.createSampleMasterDP({
          variationId: Number(query.variationId),
          userIds: dataReleaseFormat,
        });
        fetchDataReleased();
        setDataExcel([]);
      } catch (error) {
        openMessageError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Container fluid className="pb-5">
      <SpinnerComponent isLoading={loading} />
      <BoxForm title="サンプル用miim配信先登録">
        <Col md="12">
          <SearchSampleMasterDp onImportExcel={getDataExel} />
          <Row>
            <Col md="9">
              <TableSampleMasterDp dataSource={dataExcel || []} />
              <div className="d-flex justify-content-center mb-5">
                <Button
                  onClick={handleReleaseSample}
                  className="btn-focus-none button-submit"
                  disabled={
                    !dataExcel.length ||
                    !query.variationId ||
                    dataExcel?.every((item: IDataExcel) => item?.deleted === DeleteType.DELETED)
                  }
                >
                  上記miimユーザへサンプルを配布する
                </Button>
              </div>
              <p className="pt-3 pb-2 underline-100 position-relative">配布済みユーザ</p>
              <TableSampleMasterDpResponse dataSource={dataUserReleased || []} />
            </Col>
          </Row>
        </Col>
      </BoxForm>
    </Container>
  );
}
