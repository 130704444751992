import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

interface Options {
  year: string;
  disabled?: boolean;
}

export default function DropdownMenu({
  activeOption,
  onChange,
  options,
  classButton,
}: {
  activeOption: string;
  onChange?: (event: string) => void;
  options: Options[];
  classButton?: string;
}): JSX.Element {
  const [active, setActive] = useState(activeOption);

  const onChangeOption = (option: string) => {
    setActive(option);
    if (onChange) onChange(option);
  };

  useEffect(() => {
    if (activeOption) onChangeOption(activeOption);
  }, [active]);
  return (
    <>
      <DropdownButton id="dropdown-basic-button" className={`${classButton}`} title={activeOption}>
        {options.map((option, index) => {
          return (
            <Dropdown.Item key={index} onClick={() => onChangeOption(option.year)}>
              {option.year}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
    </>
  );
}
