import brAxios from 'Api/Interceptors';
import { FormCompanyInfo } from 'Page/CompanyInfo/CreateCompanyInfo';
import { checkEmptyString } from 'Utils/Numbers';
import { AxiosResponse } from 'axios';

interface IManager {
  id?: number;
  sectionId: string;
  managerName: string;
  furiganaName: string;
  managerPhone: string;
  managerEmail: string;
  artistList: Array<number>;
  viewPermission?: number;
}
interface IManagerEc {
  id?: number;
  sectionId: string;
  name: string;
  furiganaName: string;
  phone: string;
  email: string;
}
interface IGetManager {
  artistList?: number | null | undefined;
  artistId?: number | null | undefined;
}

export interface Ips {
  id?: number | null | string;
  source?: string;
  description?: string;
}

export const CompanyInformationApi = {
  getInformation: () => brAxios.get('/record-company/information'),
  updateInformation: (params: FormCompanyInfo) =>
    brAxios.put('/record-company/information', params),

  getManagers: (params: IGetManager): Promise<AxiosResponse> => {
    const response = brAxios.get('/record-company/managers', { params });
    return response;
  },
  getManagerDetail: (id: string | number): Promise<AxiosResponse> => {
    const response = brAxios.get(`/record-company/managers/${id}`);
    return response;
  },
  addManagers: (params: IManager): Promise<AxiosResponse> =>
    brAxios.post('record-company/managers', params),

  updateManagers: (params: IManager): Promise<AxiosResponse> =>
    brAxios.put(`/record-company/managers/${params.id}`, {
      ...params,
      managerPhone: checkEmptyString(params?.managerPhone),
    }),
  deleteManagers: (id: number): Promise<AxiosResponse> =>
    brAxios.delete(`/record-company/managers/${id}`),
  addManagersEc: (params: IManagerEc): Promise<AxiosResponse> =>
    brAxios.post('/ec-site/sale-channel/create-miim-ec', params),
  updateManagersEc: (params: IManagerEc): Promise<AxiosResponse> =>
    brAxios.put(`/ec-site/sale-channel/miim-ec/${params.id}`, {
      ...params,
    }),
  deleteManagersEc: (id: number): Promise<AxiosResponse> =>
    brAxios.delete(`/ec-site/sale-channel/miim-ec/${id}`),
  getWhiteListIp: (): Promise<AxiosResponse> => brAxios.get('/ip-filter'),
  createWhiteListIp: (params: Ips[]): Promise<AxiosResponse> =>
    brAxios.post('/ip-filter', { rules: params }),
};
