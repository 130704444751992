import React, { useMemo } from 'react';
import TableCommon from 'Components/Common/Table/TableCommon';
import { formatCurrency } from 'Utils/Numbers';
import { Cell } from 'react-table';

//* TAX = 1 + 10%
// const TAX = 1.1;
interface TableCostMiniProps {
  dataSource: any[];
  title?: string;
}
const TableCostMonth = ({ dataSource, title }: TableCostMiniProps): JSX.Element => {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'index',
        className: 'pe-none',
        columns: [
          {
            Header: '税込',
            accessor: 'totalMoneyTax',
            className: 'hidden-sort',
            width: 120,
            Cell: ({ row }: Cell<any>) => {
              return (
                <div>
                  {formatCurrency(
                    Math.round(
                      row.original.afterTaxTotalMoney
                        ? row.original.afterTaxTotalMoney
                        : row.original.totalMoneyTax,
                    ) || 0,
                  )}
                </div>
              );
            },
          },
          {
            Header: '税抜',
            accessor: 'totalMoney',
            className: 'hidden-sort',
            width: 120,
            Cell: ({ row }: Cell<any>) => {
              return <div>{formatCurrency(row.original.totalMoney || 0)}</div>;
            },
          },
        ],
      },
    ],
    [],
  );
  return (
    <div className="d-flex flex-column me-3">
      <div className="text-center d-flex align-items-center fw-bold">{title}</div>
      <TableCommon
        data={dataSource}
        columns={columns}
        getHeaderProps={() => {
          return {
            className: 'text-white d-flex align-items-center bg-dark text-center',
          };
        }}
        getColumnProps={() => {
          return {
            className: 'text-end',
          };
        }}
        getRowProps={() => {
          return {
            style: { minHeight: '32px' },
          };
        }}
        getCellProps={() => {
          return {
            className: 'bg-white text-dark',
          };
        }}
      />
    </div>
  );
};

export default TableCostMonth;
