/* eslint-disable radix */
import { subTypeOptions } from 'Constant/Campaign';
import { DATE_FORMAT_1 } from 'Constant/Date';
import dayjs from 'dayjs';
import _ from 'lodash';
import { CourseCampaign } from 'Types/Page/Campaign/RegisterCampaign';
interface CampaignEvents {
  dates: any[];
  events: any[];
}

function getDateRange(startDate: string, endDate: string): string[] {
  const start = startDate.split('/');
  const end = endDate.split('/');
  const startYear = parseInt(start[0]);
  const endYear = parseInt(end[0]);
  const dates = [];

  for (let i = startYear; i <= endYear; i++) {
    const endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
    const startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      const month = j + 1;
      const displayMonth = month < 10 ? `0${month}` : month;
      dates.push([i, displayMonth, '01'].join('-'));
    }
  }
  return dates;
}

const handleGetMonths = (campaignRelations: CampaignEvents) => {
  const minDate: Date | undefined = _.min(campaignRelations.dates);
  const maxDate: Date | undefined = _.max(campaignRelations.dates);
  const dates = getDateRange(
    minDate ? `${dayjs(minDate).format(DATE_FORMAT_1)}` : '',
    maxDate ? `${dayjs(maxDate).format(DATE_FORMAT_1)}` : '',
  );
  return dates;
};

const getMsgExceed100 = (courses: CourseCampaign[], courseName: string): string => {
  let msg = '当選確率が100％を超えています。';
  if (courses[0]?.subType === subTypeOptions[1].value) {
    msg = `${courseName || ''} で全景品の当選確率が100％を超えないように入力してください。`;
  }
  return msg;
};

export { getDateRange, handleGetMonths, getMsgExceed100 };
