/* eslint-disable @typescript-eslint/no-unused-vars */
import DropdownMenuEC from 'Components/Common/EC/DropdownMenuEC';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { ECApis } from 'Datasource/EC';
import useMessage from 'Hooks/useMessage';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import PurchaseAmountTable from './PurchaseAmountTable';
import PurchaseDetailTable from './PurchaseDetailTable';
import PurchaseSummaryListTable from './PurchaseSummaryListTable';
import dayjs from 'dayjs';
import { useAppSelector, useAppDispatch } from 'App/Store';
import { StringParam, useQueryParams } from 'use-query-params';
import { removeHashPassword } from 'App/Features/HashPassword';
import { exportExcel } from 'Utils/File';
import useErrorBlob from 'Hooks/useErrorBlob';
import { formatFixedDecimalAndNumb, formatPrice } from 'Utils/Numbers';

const currentYear = dayjs().year();
const currentMonth = dayjs().month();
interface Options {
  yearMonth: string;
}

export default function PurchaseSummaryList(): JSX.Element {
  const [query, setQuery] = useQueryParams({
    page: StringParam,
    sort: StringParam,
    order: StringParam,
    yearMonth: StringParam,
  });
  const { openMessageError } = useMessage();
  const { openErrorBlob } = useErrorBlob();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [purchaseSummaryData, setPurchaseSummaryData] = useState<any>();
  const dispatch = useAppDispatch();
  const [groupOptions, setGroupOptions] = useState<Array<Options>>([
    { yearMonth: query.yearMonth ? query.yearMonth : `${currentYear}年${currentMonth + 1}月` },
  ]);
  const [activeOption, setActiveOption] = useState(groupOptions[0]?.yearMonth);
  const {
    hashPassword: { hashPassword },
  } = useAppSelector((state) => state);

  const screenWidth = window?.innerWidth;

  const fetchPurchaseSummaryDate = async () => {
    try {
      setIsLoading(true);
      const res = await ECApis.getPaymentSummaryDate();
      const { data } = res.data;
      const reverseArray = data.reverse();
      setGroupOptions(reverseArray);
    } catch (error) {
      openMessageError(error);
    } finally {
      // setIsLoading(false);
    }
  };

  const fetchPurchaseSummaryData = async () => {
    const dateParams = activeOption.split('年');
    const yearParams = parseInt(dateParams[0], 10);
    const monthParams = parseInt(dateParams[1].substring(0, 2), 10);
    try {
      setIsLoading(true);
      const res = await ECApis.getPaymentSummaryList(
        yearParams,
        monthParams,
        hashPassword,
        query.sort ? query.sort : undefined,
        query.order ? query.order : undefined,
      );
      const { data } = res.data;
      setPurchaseSummaryData(data);
    } catch (error) {
      dispatch(removeHashPassword());
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickPrevMonth = () => {
    groupOptions.map((option, index) => {
      if (activeOption === option.yearMonth && index < groupOptions.length - 1) {
        setActiveOption(groupOptions[index + 1].yearMonth);
        setQuery({ yearMonth: groupOptions[index + 1].yearMonth });
      }
    });
  };

  const handleClickNextMonth = () => {
    groupOptions.map((option, index) => {
      if (activeOption === option.yearMonth && index > 0) {
        setActiveOption(groupOptions[index - 1].yearMonth);
        setQuery({ yearMonth: groupOptions[index - 1].yearMonth });
      }
    });
  };

  const handleDownloadZipFile = async () => {
    const dateParams = activeOption.split('年');
    const yearParams = parseInt(dateParams[0], 10);
    const monthParams = parseInt(dateParams[1].substring(0, 2), 10);
    setIsLoading(true);
    try {
      const formatDate = dayjs(`${yearParams}-${monthParams}`).format('YYMM');
      const res = await exportExcel(
        ECApis.purchaseSummaryExportZipFile(yearParams, monthParams, hashPassword),
        `${formatDate}_miim仕入支払サマリ`,
        'zip',
      );
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPurchaseSummaryDate();
  }, []);

  useEffect(() => {
    fetchPurchaseSummaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOption, query.sort, query.order]);

  return (
    <div>
      <Container fluid>
        <SpinnerComponent isLoading={isLoading} />

        <div className="px-4 pt-3  d-flex flex-column flex-xl-row">
          <div
            className="d-flex justify-content-xl-between mr-100px "
            style={{ minWidth: '505px' }}
          >
            <div className="me-3 me-xl-0">
              <div className="fs-12 font-bold ">対象月</div>
              <div className="purchase-summary-dropdown d-flex">
                <Button
                  onClick={handleClickPrevMonth}
                  variant="light"
                  className="purchase-summary-prev-btn"
                >
                  前月
                </Button>
                <DropdownMenuEC
                  options={groupOptions}
                  activeOption={activeOption}
                  onChange={setActiveOption}
                  setQuery={setQuery}
                  classButton=""
                />
                <Button
                  onClick={handleClickNextMonth}
                  variant="light"
                  className="purchase-summary-next-btn"
                >
                  次月
                </Button>
              </div>
            </div>

            <div className={`d-inline-table text-center `}>
              <div className="fs-12 lg-fs-9px font-bold">{activeOption} 支払総額</div>
              <div className="lg-fs-16px fs-24px font-bold d-inline border-bottom-2px-ec">
                {purchaseSummaryData?.totalTotalPrice &&
                  formatPrice(formatFixedDecimalAndNumb(purchaseSummaryData?.totalTotalPrice, 2))}
              </div>
            </div>

            <div className="ms-3 ms-xl-0">
              <div className={`${screenWidth <= 1500 ? 'fs-9px' : 'fs-12'} font-bold`}>
                {activeOption} 合計金額
              </div>
              <PurchaseAmountTable
                totalPurchaseTotalPrice={purchaseSummaryData?.totalPurchaseTotalPrice}
              />
            </div>
          </div>

          <PurchaseDetailTable
            totalCurPrice={purchaseSummaryData?.totalCurPrice}
            totalLicensePrice={purchaseSummaryData?.totalLicensePrice}
            totalPrevPrice={purchaseSummaryData?.totalPrevPrice}
            totalTotalPrice={purchaseSummaryData?.totalTotalPrice}
            totalTotalPriceAfterApplyBuyingRate={
              purchaseSummaryData?.totalTotalPriceAfterApplyBuyingRate
            }
          />

          <div className="flex-fill text-start text-xl-end px-2 mt-3" style={{ maxWidth: '250px' }}>
            <Button
              onClick={handleDownloadZipFile}
              className="lg-pl-8px lg-pr-8px lg-fs-10px fs-12 lg-w-max-content text-nowrap btn-lg"
              disabled={!purchaseSummaryData?.canDownload}
            >
              一括ダウンロード
            </Button>
          </div>
        </div>

        <PurchaseSummaryListTable
          data={purchaseSummaryData?.items}
          canDownload={purchaseSummaryData?.canDownload}
        />
      </Container>
    </div>
  );
}
