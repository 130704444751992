import React, { useEffect, useRef } from 'react';
import JsBarcode from 'jsbarcode';

export const barcodeOptions: JsBarcode.Options = {
  textMargin: 0,
  fontOptions: 'bold',
  width: 1.9,
  height: 59,
};

interface BarcodeProps {
  value: string;
  options?: JsBarcode.Options;
}

const Barcode: React.FC<BarcodeProps> = ({ value, options }) => {
  const barcodeRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (barcodeRef.current) {
      let barcodeFormat = '';

      if (value.length === 12) {
        barcodeFormat = 'EAN13';
      } else if (value.length === 7) {
        barcodeFormat = 'EAN8';
      } else if (value.length === 5) {
        barcodeFormat = 'EAN5';
      } else if (value.length === 2) {
        barcodeFormat = 'EAN2';
      } else {
        console.error('Invalid barcode length');
      }
      JsBarcode(barcodeRef.current, value, {
        ...options,
        format: barcodeFormat,
      });
    }
  }, [value, options]);

  return <svg ref={barcodeRef}></svg>;
};

export default Barcode;
