/* eslint-disable jsx-a11y/anchor-is-valid */
import CampaignCourses from 'Components/Page/Campaign/List/CampaignCourses';
import { methodOptions } from 'Constant/Campaign';
import { DATE_FORMAT_2 } from 'Constant/Date';
import dayjs from 'dayjs';
import { find } from 'lodash';
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { calcMb } from 'Utils/File';
import { formatCurrencyUS } from 'Utils/Numbers';

export const renderId = (row: any) => {
  const { titleId } = row.original;
  return <span>{titleId}</span>;
};

export const renderSaleDate = (row: any) => {
  const { timeSale } = row.original;
  return <span>{dayjs(timeSale).format(DATE_FORMAT_2)}</span>;
};

export const renderDownloadDate = (row: any) => {
  const { timeDownload } = row.original;
  return <span>{timeDownload ? dayjs(timeDownload).format(DATE_FORMAT_2) : '-'}</span>;
};

export const renderArtist = (row: any) => {
  const { artistName } = row.original;
  return <span className="text-break text-clamp-2 text-start">{artistName}</span>;
};

export const renderTitle = (row: any) => {
  const { titleName, titleId, artistId, artistName } = row.original;
  return (
    <span className="text-break text-clamp-2 text-start">
      <Link
        to={`products-detail/${titleId}?artistId=${artistId}&artistIds=${artistId}&page=1&queryArtist=${artistName}&queryTitle=${titleName}&status=タイトル情報`}
      >
        {titleName}
      </Link>
    </span>
  );
};

export const renderTitleEC = (row: any) => {
  const { titleName } = row.original;
  return <span className="text-break text-clamp-2 text-start">{titleName}</span>;
};

export const renderVariation = (row: any) => {
  const { variationName } = row.original;
  return <span className="text-break text-clamp-2 text-start">{variationName}</span>;
};

export const renderTitleStatus = (row: any) => {
  const { titleStatus } = row.original;
  return <span>{titleStatus === 0 ? '一時保存' : '登録済'}</span>;
};

export const renderSaleChannels = (row: any) => {
  const { saleChannels = [], titleId } = row.original;
  return (
    <div className={`h-100 w-100 d-flex flex-column justify-content-center`}>
      <ListGroup>
        {saleChannels.length ? (
          saleChannels.map((item: any, cIndex: number) => (
            <ListGroup.Item key={cIndex}>
              {item?.url ? (
                item?.type !== 'SALE_CHANNEL_COMPANY' ? (
                  <a
                    href={`${item.url}/products/${titleId}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-break text-clamp-2"
                  >
                    {item?.name ?? '-'}
                  </a>
                ) : (
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-break text-clamp-2"
                  >
                    {item?.name ?? '-'}
                  </a>
                )
              ) : (
                <a href="#" className="text-break text-clamp-2">
                  {item?.name ?? '-'}
                </a>
              )}
            </ListGroup.Item>
          ))
        ) : (
          <ListGroup.Item className="text-center">-</ListGroup.Item>
        )}
      </ListGroup>
    </div>
  );
};

export const renderSaleChannelsDownload = (row: any) => {
  const { saleChannels = [] } = row.original;
  return (
    <div className={`h-100 w-100 d-flex flex-column justify-content-center`}>
      <ListGroup>
        {saleChannels.length ? (
          saleChannels.map((item: any, cIndex: number) => (
            <ListGroup.Item key={cIndex}>{item?.name ?? '-'}</ListGroup.Item>
          ))
        ) : (
          <ListGroup.Item className="text-center">-</ListGroup.Item>
        )}
      </ListGroup>
    </div>
  );
};

export const renderCapacityDP = (row: any) => {
  const { capacityDP } = row.original;
  const data: number = calcMb(capacityDP);
  return (
    <span>
      {capacityDP ? (data >= 1000 ? `${Number(data / 1000).toFixed(2)}GB` : `${data}MB`) : '-'}
    </span>
  );
};

export const renderTotalMasterSale = (row: any) => {
  const { totalMasterSale } = row.original;
  return <span>{totalMasterSale ? formatCurrencyUS(totalMasterSale) : '0'}</span>;
};

export const renderManager = (row: any) => {
  const { artistId, artistName } = row.original;
  return (
    <Link
      to={`curator-management?artistList=${
        artistId ?? ''
      }&queryArtist=${artistName}&artistId=${artistId}`}
    >
      リスト
    </Link>
  );
};

export const renderRecordCompany = (row: any) => {
  const { recordCompanyName } = row.original;
  return (
    <span className="text-break text-clamp-2">{recordCompanyName ? recordCompanyName : '-'}</span>
  );
};

export const renderCampaignsId = (row: any) => {
  const { id } = row.original;
  return <span>{id}</span>;
};

export const renderCampaignsDate = (row: any) => {
  const { startDate, endDate } = row.original;
  return (
    <div className="px-2">
      {dayjs(startDate).format(DATE_FORMAT_2)} - {dayjs(endDate).format(DATE_FORMAT_2)}
    </div>
  );
};

export const renderCampaignsArtist = (row: any) => {
  const { title } = row.original;
  return <div className="text-break text-clamp-2 px-2">{title ? title.artist.localName : ''}</div>;
};

export const renderCampaignsTitle = (row: any) => {
  const { title } = row.original;
  return (
    <span className="text-break text-clamp-2 px-2">
      <Link
        to={`products-detail/${title?.id}?artistId=${title?.artist?.id}&artistIds=${title?.artist?.id}&page=1&queryArtist=${title?.artist?.localName}&queryTitle=${title?.name}&status=タイトル情報`}
      >
        {title ? title.name : ''}
      </Link>
    </span>
  );
};

export const renderCampaignsTitleEC = (row: any) => {
  const { title } = row.original;
  return <span className="text-break text-clamp-2 px-2">{title ? title.name : ''}</span>;
};

export const renderCampaignsName = (row: any) => {
  const { name, id } = row.original;
  return (
    <div className="px-2 overflow-hidden">
      <Link className="d-flex" to={`campaigns/${id}`}>
        <span className="text-break text-clamp-2">{name}</span>
      </Link>
    </div>
  );
};

export const renderCampaignsNameEC = (row: any) => {
  const { name } = row.original;
  return (
    <div className="px-2 overflow-hidden">
      <span className="text-break text-clamp-2">{name}</span>
    </div>
  );
};

export const renderCampaignsCoursesName = (row: any) => {
  const { courses = [] } = row.original;
  return (
    <div className="h-100 d-flex flex-column justify-content-center">
      {courses.length ? (
        row.original?.courses.map((course: any, cIndex: number) => {
          return (
            <div
              key={cIndex}
              className={`px-2 ${
                cIndex === row.original?.courses.length - 1 ? '' : 'border-bottom'
              } d-flex align-items-center`}
              style={{ height: `calc(100%/${row.original?.courses.length})` }}
            >
              <span className="text-break text-clamp-2">{course?.name}</span>
            </div>
          );
        })
      ) : (
        <div className="d-flex align-items-center justify-content-center">-</div>
      )}
    </div>
  );
};

export const renderCampaignsCourseType = (row: any) => {
  const { courses = [] } = row.original;
  return (
    <div className={`h-100 ${!courses.length && 'd-flex flex-column justify-content-center'}`}>
      {courses.length ? (
        courses.map((course: any, cIndex: number) => {
          const typeLabel =
            find(methodOptions, (option) => option.value === course.type)?.label ?? '';

          return (
            <div
              key={cIndex}
              className={`p-2 ${
                cIndex === courses.length - 1 ? '' : 'border-bottom'
              } d-flex align-items-center`}
              style={{ height: `calc(100%/${row.original?.courses.length})` }}
            >
              {typeLabel}
            </div>
          );
        })
      ) : (
        <div className="d-flex align-items-center justify-content-center">-</div>
      )}
    </div>
  );
};

export const renderCampaignsCourse = (row: any) => {
  const { courses = [] } = row.original;
  return (
    <div className={`h-100 ${!courses.length && 'd-flex flex-column justify-content-center'}`}>
      {courses.length ? (
        courses.map((course: any, cIndex: number) => {
          return (
            <div
              key={cIndex}
              className={`p-2 ${cIndex === courses.length - 1 ? '' : 'border-bottom'}`}
              style={{ height: `calc(100%/${courses.length})` }}
            >
              <CampaignCourses course={course} />
            </div>
          );
        })
      ) : (
        <div className="text-center align-items-center">-</div>
      )}
    </div>
  );
};

export const renderCampaignsStandardTime = (row: any) => {
  const { courses = [] } = row.original;
  return (
    <div className={`h-100 ${!courses.length && 'd-flex flex-column justify-content-center'}`}>
      {courses.length ? (
        courses.map((course: any, cIndex: number) => {
          return (
            <div
              key={cIndex}
              className={`p-2 ${
                cIndex === courses.length - 1 ? '' : 'border-bottom'
              } text-center d-flex align-items-center justify-content-center`}
              style={{ height: `calc(100%/${row.original?.courses.length})` }}
            >
              {course.standardTime ? dayjs(course.standardTime).format(DATE_FORMAT_2) : '-'}
            </div>
          );
        })
      ) : (
        <div className="d-flex align-items-center justify-content-center">-</div>
      )}
    </div>
  );
};

export const renderCampaignsTitleStatus = (row: any) => {
  const { title } = row.original;
  return (
    <div className="text-center">{title ? (title.status === 0 ? '一時保存' : '登録済') : ''}</div>
  );
};

export const renderCampaignsCardPurchase = (row: any) => {
  const { cardPurchasedAmount } = row.original;
  return (
    <div className="px-2">{cardPurchasedAmount ? formatCurrencyUS(cardPurchasedAmount) : 0}</div>
  );
};

export const renderCampaignsManager = (row: any) => {
  const { title = [] } = row.original;
  return (
    <div className="text-center">
      <Link
        to={`curator-management?artistList=${title?.artist?.id ?? ''}&queryArtist=${
          title?.artist?.localName
        }&artistId=${title?.artist?.id}`}
      >
        リスト
      </Link>
    </div>
  );
};
