import { AliColumn } from 'Components/Common/Table/AliTable';
import { COL_END_CLASS, COL_START_CLASS } from 'Constant';
import React, { ReactNode, useRef, useState } from 'react';
import { IPurchase } from 'Types/Page/CompanyInformation/CompanyInformation';
import { formatDate } from 'Utils/DateTime';
import { formatCurrency, formatDecimal } from 'Utils/Numbers';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TooltipsWithTruncate from 'Components/Common/Tooltips/TooltipsWithTruncate';
import { renderShopName } from 'Constant/Shop';

const ToolTipNameComp = ({ value }: any): JSX.Element => {
  const titleRef = useRef<HTMLInputElement>(null);
  const [isOverFlowText, setIsOverFlowText] = useState(false);

  const handleMouseOver = () => {
    if (
      titleRef?.current?.scrollWidth &&
      titleRef?.current?.scrollWidth > titleRef?.current?.offsetWidth
    ) {
      setIsOverFlowText(true);
    }
  };

  const handleMouseLeave = () => {
    setIsOverFlowText(false);
  };

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      <OverlayTrigger
        key={`tooltip-title-${value}`}
        placement="top"
        overlay={isOverFlowText ? <Tooltip id={`tooltip-${value}`}>{value}</Tooltip> : <></>}
      >
        <div ref={titleRef} className={`${COL_START_CLASS} ec_width-fitContent mw-100`}>
          {value}
        </div>
      </OverlayTrigger>
    </div>
  );
};

const ToolTipLinkComp = ({ value, path }: any): JSX.Element => {
  const titleRef = useRef<HTMLInputElement>(null);
  const [isOverFlowText, setIsOverFlowText] = useState(false);

  const handleMouseOver = () => {
    if (titleRef?.current?.clientWidth && titleRef?.current?.clientWidth >= 135) {
      setIsOverFlowText(true);
    }
  };

  const handleMouseLeave = () => {
    setIsOverFlowText(false);
  };

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      <OverlayTrigger
        key={`tooltip-title-${value}`}
        placement="top"
        overlay={isOverFlowText ? <Tooltip id={`tooltip-${value}`}>{value}</Tooltip> : <></>}
      >
        <div ref={titleRef} className={`${COL_START_CLASS} ec_width-fitContent mw-100`}>
          <Link to={path}>{value}</Link>
        </div>
      </OverlayTrigger>
    </div>
  );
};

export const renderTotalPrice: AliColumn = {
  name: '販売金額',
  width: 160,
  align: 'center',
  code: 'totalMoney',
  render: (value: any): ReactNode => {
    return <div className={`${COL_END_CLASS}`}>{value ? formatCurrency(value) : ''}</div>;
  },
};
export const renderAmount: AliColumn = {
  name: '販売数',
  width: 160,
  code: 'totalAmount',
  align: 'center',
  render: (value: any) => {
    return <div className={`${COL_END_CLASS}`}>{value || ''}</div>;
  },
};

export const renderProductName: AliColumn = {
  lock: true,
  code: 'productName',
  width: 200,
  name: '管理名称',
  align: 'center',
  features: { sortable: true },
  render: (value: IPurchase): ReactNode => {
    return <div className={`${COL_START_CLASS}`}>{value}</div>;
  },
};

export const renderArtistName: AliColumn = {
  code: 'artistName',
  width: 160,
  name: 'アーティスト',
  features: { sortable: true },
  lock: true,
  align: 'center',
  render: (value: IPurchase): ReactNode => {
    return <div className={`${COL_START_CLASS}`}>{value}</div>;
  },
};

export const renderTitleName: AliColumn = {
  code: 'titleName',
  width: 160,
  name: 'タイトル',
  features: { sortable: true },
  lock: true,
  align: 'center',
  render: (value: IPurchase, row: any): ReactNode => {
    return <ToolTipLinkComp value={value} path={`title-detail/${row.titleId}`} />;
  },
};

export const renderTitleNameMiimEc: AliColumn = {
  code: 'titleName',
  width: 160,
  name: 'タイトル',
  features: { sortable: true },
  lock: true,
  align: 'center',
  render: (value: IPurchase): ReactNode => {
    return <ToolTipNameComp value={value} />;
  },
};

export const renderVariationName: AliColumn = {
  code: 'variationName',
  width: 160,
  name: 'バリエーション',
  features: { sortable: true },
  lock: true,
  align: 'center',
  render: (value: IPurchase): ReactNode => {
    return <ToolTipNameComp value={value} />;
  },
};

export const renderCardCd: AliColumn = {
  code: 'variationProductCode',
  width: 160,
  name: '品番',
  align: 'center',
  features: { sortable: true },
  render: (value: IPurchase): ReactNode => {
    return <div className="text-start text-break">{value}</div>;
  },
};

export const renderDownloadDate: AliColumn = {
  name: 'DL開始日',
  width: 120,
  align: 'center',
  code: 'downloadDate',
  features: { sortable: true },
  render: (value: any): ReactNode => {
    return (
      <div className={`${COL_END_CLASS}`}>{value && value !== '-' ? formatDate(value) : '-'}</div>
    );
  },
};

export const renderPrice: AliColumn = {
  name: '販売価格',
  width: 160,
  align: 'center',
  code: 'price',
  features: { sortable: true },
  render: (value: any): ReactNode => {
    return (
      <div className={`${COL_END_CLASS}`}>
        {value && value !== '-' ? formatCurrency(value) : '-'}
      </div>
    );
  },
};

export const renderPurchaseDate: AliColumn = {
  name: '販売日',
  width: 100,
  code: 'purchaseDate',
  features: { sortable: true },
  lock: true,
  align: 'center',
  render: (value: any) => {
    return <div className={`${COL_END_CLASS}`}>{formatDate(value || '')}</div>;
  },
};

export const renderTotalMoney: AliColumn = {
  name: '原価合計',
  code: 'totalMoney',
  width: 160,
  align: 'center',
  render: (value: any) => {
    return <div className={`${COL_END_CLASS}`}>{value ? formatCurrency(value) : ''}</div>;
  },
};

export const renderSnFeeMoney: AliColumn = {
  name: '金額',
  width: 100,
  code: 'snFeeMoney',
  align: 'center',
  render: (value: any) => {
    return <div className={`${COL_END_CLASS}`}>{value ? formatCurrency(value) : ''}</div>;
  },
};

export const renderSnFee: AliColumn = {
  name: '単価',
  width: 100,
  code: 'snFee',
  align: 'center',
  render: (value: any) => {
    return <div className={`${COL_END_CLASS}`}>{value ? formatCurrency(value) : ''}</div>;
  },
};

export const renderBuyFeeMoney: AliColumn = {
  name: '金額',
  width: 100,
  code: 'buyFeeMoney',
  align: 'center',
  render: (value: any) => {
    return <div className={`${COL_END_CLASS}`}>{value ? formatCurrency(value) : ''}</div>;
  },
};

export const renderUnitTaxFee: AliColumn = {
  name: '料率',
  width: 100,
  code: 'unitTaxFee',
  align: 'center',
  render: (value: any) => {
    return <div className={`${COL_END_CLASS}`}>{value ? `${formatDecimal(value, 1)}%` : ''}</div>;
  },
};

export const renderOptionTaxFee: AliColumn = {
  name: '単価',
  width: 100,
  code: 'optionTaxFee',
  align: 'center',
  render: (value: any) => {
    return <div className={`${COL_END_CLASS}`}>{value ? formatCurrency(value) : ''}</div>;
  },
};

export const renderTaxFeeMoney: AliColumn = {
  name: '金額',
  width: 100,
  code: 'taxFeeMoney',
  align: 'center',
  render: (value: any) => {
    return <div className={`${COL_END_CLASS}`}>{value ? formatCurrency(value) : ''}</div>;
  },
};

export const renderTotalAmount: AliColumn = {
  name: '数量',
  code: 'totalAmount',
  align: 'center',
  features: { sortable: true },
  render: (value: any) => {
    return <div className={`${COL_END_CLASS}`}>{value ? formatCurrency(value) : ''}</div>;
  },
};

export const renderFeeName: AliColumn = {
  name: '料金種別',
  code: 'feeName',
  width: 160,
  align: 'center',
  features: { sortable: true },
  render: (value: any) => {
    return <div className="text-truncate">{value}</div>;
  },
};

export const renderProductId: AliColumn = {
  name: '商品No.',
  width: 100,
  align: 'center',
  lock: true,
  code: 'productId',
  features: { sortable: true },
};

export const renderProductShop: AliColumn = {
  name: '店舗名',
  width: 130,
  align: 'center',
  lock: true,
  code: 'shopName',
  features: { sortable: true },
  render: (value: any, row: any) => {
    if (row?.shopId === null) {
      return <></>;
    }

    return (
      <TooltipsWithTruncate tooltips={renderShopName(row)}>
        <div className="text-start text-truncate">{renderShopName(row)}</div>
      </TooltipsWithTruncate>
    );
  },
};
export const renderLabelName: AliColumn = {
  name: 'レーベル名',
  width: 160,
  align: 'center',
  lock: true,
  code: 'titleLabel',
  render: (value: IPurchase): ReactNode => {
    return <ToolTipNameComp value={value} />;
  },
};
export const renderJanCode: AliColumn = {
  name: 'JANコード',
  width: 140,
  align: 'center',
  code: 'variationJanCode',
  render: (value: IPurchase): ReactNode => {
    return <ToolTipNameComp value={value} />;
  },
};
