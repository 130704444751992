/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';

export interface IMediaParam {
  fileSize: number;
  mimeType: string;
  partNumber: number;
  type?: number;
  tokutenType?: number;
}

export const UploadMediaApi = {
  upLoadMedia: async (params: FormData) => {
    const response = await brAxios.post('/record-company/upload-file', params);
    return response.data;
  },
  upLoadBookletsMedia: async (params: FormData) => {
    const response = await brAxios.post('/record-company/upload-booklets', params);
    return response.data;
  },
  upLoadMediaV2: async (params: IMediaParam) => {
    const response = await brAxios.post('/record-company/upload-file-presign-url', params);
    return response.data;
  },
};
