/* eslint-disable react-hooks/exhaustive-deps */
import Filter from 'Components/Common/Filter/Filter';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import Tabs from 'Components/Common/Tabs/TabRevenue';
import { PER_PAGE } from 'Constant';
// import { DATE_FORMAT_YMD } from 'Constant/Date';
import { RecordCompanyApi } from 'Datasource/RecordCompanyApi';
import { CostResponse, PurchaseResponse } from 'Types/Page/CompanyInformation/CompanyInformation';
import dayjs from 'dayjs';
import useMessage from 'Hooks/useMessage';
import CostManagement from 'Page/InformationAccountant/CostManagement';
import SaleManagement from 'Page/InformationAccountant/SaleManagement';
import SearchRevenue from 'Page/InformationAccountant/SearchRevenue';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import Modal from 'Components/Common/Modal';

import { ROLE_RECORD_MENU } from 'Constant/Roles';
import { useAppSelector } from 'App/Store';

import { exportExcel } from 'Utils/File';
import useTitlePage from 'Hooks/useTitlePage';

import { omit } from 'lodash';
import {
  currentEnDMonth,
  currentStartMonth,
  handleStartDate,
  handleEndMonth,
  handleStartMonth,
  handleEndDate,
  formatDateTimeUTCToLocalEndTime,
  formatDateTimeUTCToLocalStartTime,
} from 'Utils/DateTime';
import useErrorBlob from 'Hooks/useErrorBlob';
import { AliSortType } from 'Components/Common/Table/AliTable';
import { DATE_FORMAT_EXPORT_EXCEL } from 'Constant/Date';
import ECSendBulkEmailForm from 'Components/Page/EC/ECSendBulkEmailForm';

export default function RevenueManagement(): JSX.Element {
  const [dataPurchase, setDataPurchase] = useState<PurchaseResponse>();
  const [isShowBlukEmailModal, setIsShowBlukEmailModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataCost, setDataCost] = useState<CostResponse>();
  const { openMessageError } = useMessage();
  const { openErrorBlob } = useErrorBlob();
  const { setMenuTitle } = useTitlePage();
  const { role } = useAppSelector((state) => state.auth);
  const [exporting, setExporting] = useState<boolean>(false);

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistId: StringParam,
    artistIds: StringParam,
    titleId: StringParam,
    titleIds: StringParam,
    companyIds: StringParam,
    startDate: withDefault(StringParam, formatDateTimeUTCToLocalStartTime(currentStartMonth), true),
    endDate: withDefault(StringParam, formatDateTimeUTCToLocalEndTime(currentEnDMonth), true),
    variationId: StringParam,
    variationIds: StringParam,
    isOption: withDefault(NumberParam, 0, true),
    queryVariation: StringParam,
    status: withDefault(StringParam, '売上管理/月別', true),
    sort: StringParam,
    order: StringParam,
    companyId: StringParam,
  });

  const isMiimEcOrExternalEc = useMemo(() => {
    if (
      role &&
      (role === ROLE_RECORD_MENU.ECCompMaster ||
        role === ROLE_RECORD_MENU.ECCompManager ||
        role === ROLE_RECORD_MENU.SALEChanelMaster ||
        role === ROLE_RECORD_MENU.SALEChanelManager)
    ) {
      return true;
    }
    return false;
  }, [role]);

  const btnGroupSaleOptions = useMemo(
    () => [
      { name: '月別', value: '売上管理/月別' },
      { name: '日別', value: '売上管理/日別' },
      { name: '詳細', value: '売上管理/詳細' },
      { name: '日計表', value: '売上管理/日計表' },
    ],
    [],
  );

  const btnGroupCostOptions = useMemo(
    () => [
      { name: '月別', value: '原価管理/月別' },
      { name: '日別', value: '原価管理/日別' },
      { name: '詳細', value: '原価管理/詳細' },
      { name: '日計表', value: '原価管理/日計表' },
    ],
    [],
  );

  const [tabActive, setTabActive] = useState(btnGroupSaleOptions[0].value);
  const [activeColumns, setActiveColumns] = useState<any>([]);

  //* get previous tab active
  const prevTab = useRef<any>(null);
  useEffect(() => {
    prevTab.current = tabActive;
  }, [tabActive]);
  //* ******************** *//

  const isPurchase = useMemo(() => {
    const purchase = btnGroupSaleOptions.map((item) => item.value);
    return purchase.includes(query.status);
  }, [query.status]);

  useEffect(() => {
    if (isPurchase) {
      setMenuTitle('売上管理');
    } else {
      setMenuTitle('原価管理');
    }
  }, [isPurchase]);

  useEffect(() => {
    if (isMiimEcOrExternalEc) {
      if (query.companyId || query.companyIds) {
        handleSearch();
      } else {
        setDataPurchase(undefined);
        setDataCost(undefined);
      }
    } else {
      handleSearch();
    }
  }, [
    isMiimEcOrExternalEc,
    query.page,
    query.artistId,
    query.artistIds,
    query.titleId,
    query.titleIds,
    query.startDate,
    query.endDate,
    query.variationId,
    query.variationIds,
    query.isOption,
    query.status,
    query.companyId,
    query.sort,
    query.order,
    query.companyIds,
  ]);

  const handleChangeTabs = (tab: string) => {
    if (!tab) return;
    setTabActive(tab);
    setActiveColumns([]);
    const monthly = ['売上管理/月別', '原価管理/月別'];
    const daily = ['売上管理/日別', '原価管理/日別'];
    const detail = ['売上管理/詳細', '原価管理/詳細'];
    const dailyAccount = ['売上管理/日計表', '原価管理/日計表'];

    setQuery({ sort: '', order: '' });
    if (monthly.includes(tab)) {
      setQuery({
        isOption: 0,
        endDate: formatDateTimeUTCToLocalEndTime(query.startDate),
        startDate: formatDateTimeUTCToLocalStartTime(query.startDate),
      });
    }
    if (daily.includes(tab)) {
      setQuery({
        isOption: 1,
        endDate:
          !query.endDate ||
          prevTab?.current === btnGroupSaleOptions[0].value ||
          prevTab?.current === btnGroupCostOptions[0].value
            ? formatDateTimeUTCToLocalEndTime(query.startDate)
            : formatDateTimeUTCToLocalEndTime(query.endDate),
      });
    }
    if (detail.includes(tab)) {
      setQuery({
        isOption: 2,
        endDate:
          !query.endDate ||
          prevTab?.current === btnGroupSaleOptions[0].value ||
          prevTab?.current === btnGroupCostOptions[3].value
            ? formatDateTimeUTCToLocalEndTime(query.startDate)
            : formatDateTimeUTCToLocalEndTime(query.endDate),
      });
    }
    if (dailyAccount.includes(tab)) {
      setQuery({
        isOption: 3,
        endDate: formatDateTimeUTCToLocalEndTime(query.startDate),
        startDate: formatDateTimeUTCToLocalStartTime(query.startDate),
      });
    }
  };
  //* handle reset startDate when click menu sidebar
  useEffect(() => {
    setQuery({ startDate: query.startDate });
  }, [query.startDate]);

  useEffect(() => {
    if (query.status) {
      setTabActive(query.status);
    }
  }, [query.status]);

  //* handle sort from server side
  const handleManualSort = useCallback(
    (sortBy: AliSortType[]) => {
      if (sortBy.length > 0) {
        const sortOption = sortBy[0];
        setQuery({ sort: sortOption.code, order: sortOption.order === 'desc' ? 'DESC' : 'ASC' });
      } else {
        setQuery({ sort: '', order: '' });
      }
    },
    [setQuery],
  );

  const renderTab = useMemo(() => {
    return isPurchase ? (
      <SaleManagement
        page={query.page}
        purchaseData={dataPurchase}
        option={query.isOption}
        onSort={handleManualSort}
        activeColumns={activeColumns}
        setActiveColumns={setActiveColumns}
      />
    ) : (
      <CostManagement
        page={query.page}
        costData={dataCost}
        option={query.isOption}
        onSort={handleManualSort}
        activeColumns={activeColumns}
        setActiveColumns={setActiveColumns}
      />
    );
  }, [dataPurchase, dataCost, query.page, tabActive, query.isOption, isPurchase, activeColumns]);

  const isFilterMonth =
    tabActive === btnGroupSaleOptions[0].value ||
    tabActive === btnGroupCostOptions[0].value ||
    tabActive === btnGroupSaleOptions[3].value ||
    tabActive === btnGroupCostOptions[3].value;

  const searchPurchase = async () => {
    try {
      setLoading(true);
      const res = await RecordCompanyApi.getAllReportPurchase({
        ...query,
        limit: PER_PAGE,
        page: query.page ?? 1,
        isOption: query.isOption ?? 0,
        reportCompanyId: query.companyId || '',
        endDate:
          query.isOption === 0 ? handleEndMonth(query.startDate) : handleEndDate(query.endDate),
        startDate:
          query.isOption === 0
            ? handleStartMonth(query.startDate)
            : handleStartDate(query.startDate),
        sort: query.sort || '',
        order: query.order || '',
        companyIds: query.companyIds || '',
      });

      const { data } = res.data;
      const dataMeta = {
        totalCount: data?.paginateData?.total,
        feePurchase: data.feePurchase,
      };

      setDataPurchase({
        items:
          query.isOption == 3
            ? Object.keys(data.items)?.map((d): any => ({ ...data.items?.[d], purchaseDate: d }))
            : data.items,
        meta: dataMeta,
        saleChannels: data.saleChannels,
        dataAmount: data.dataAmount,
        totalByMonth: data.totalByMonth,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const searchCost = async () => {
    try {
      setLoading(true);
      const res = await RecordCompanyApi.getAllReportCost({
        ...query,
        limit: PER_PAGE,
        page: query.page ?? 1,
        isOption: query.isOption ?? 0,
        reportCompanyId: query.companyId,
        endDate:
          query.isOption === 0 ? handleEndMonth(query.startDate) : handleEndDate(query.endDate),
        startDate:
          query.isOption === 0
            ? handleStartMonth(query.startDate)
            : handleStartDate(query.startDate),
        sort: query.sort || '',
        order: query.order || '',
        companyIds: query.companyIds || '',
      });
      const { data } = res.data;
      const dataMeta = {
        totalCount: data?.paginateData?.total,
        feePurchase: data.feePurchase,
        snFee: data.snFee,
        snImportFee: data.snImportFee,
      };
      setDataCost({
        items: data.items || [],
        companyOptions: data.companyOptions,
        meta: dataMeta,
        saleChannels: data.saleChannels,
        amountTotal: data.amountTotal,
        amountTaxFeeMoneyTotal: data.amountTaxFeeMoneyTotal,
        miimCostTotal: [{ totalMoneyTax: data?.totalTax, totalMoney: data?.total }],
        amountOptionFeeTotal: data?.amountOptionFeeTotal,
        amountCardBuyFeeTotal: data?.amountCardBuyFeeTotal,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    if (isPurchase) {
      searchPurchase();
    } else {
      searchCost();
    }
  };

  const onExportExcel = async () => {
    let optionsExcel = {
      artistIds: query.artistIds ?? undefined,
      titleIds: query.titleIds ?? undefined,
      variationIds: query.variationIds ?? undefined,
      isOption: query.isOption ?? 0,
      endDate: handleEndDate(query.endDate),
      startDate: handleStartDate(query.startDate),
      reportCompanyId: query.companyId || '',
      companyIds: query.companyIds || '',
    };
    if (!query.startDate) {
      optionsExcel = omit(optionsExcel, 'startDate') as any;
    }

    if (!query.endDate) {
      optionsExcel = omit(optionsExcel, 'endDate') as any;
    }
    try {
      setExporting(true);
      await exportExcel(
        isPurchase
          ? RecordCompanyApi.exportExcelPurchase(optionsExcel)
          : RecordCompanyApi.exportExcelCost(optionsExcel),
        `${tabActive}_${dayjs().format(DATE_FORMAT_EXPORT_EXCEL).toString()}`,
      );
    } catch (error: any) {
      openErrorBlob(error);
    } finally {
      setExporting(false);
    }
  };

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <Container fluid className="pb-4">
        <Filter filterClass="shadow-sm">
          <Row className="align-items-end">
            <SearchRevenue
              isMonth={isFilterMonth}
              onExportExcel={onExportExcel}
              loadingExcel={exporting}
            />

            <Col className="mt-3 align-self-end mx-1">
              <div className="d-flex">
                <div className="d-flex flex-column me-4">
                  <span className="font-bold">売上管理</span>
                  <Tabs
                    groupClass="mt-1"
                    options={btnGroupSaleOptions}
                    tabActive={tabActive}
                    onChange={handleChangeTabs}
                    clickOnly
                  />
                </div>
                {!isMiimEcOrExternalEc && (
                  <div className="d-flex flex-column">
                    <span className="font-bold">原価管理</span>
                    <Tabs
                      groupClass="mt-1"
                      options={btnGroupCostOptions}
                      tabActive={tabActive}
                      onChange={handleChangeTabs}
                      clickOnly
                    />
                  </div>
                )}
              </div>
            </Col>

            {(tabActive === btnGroupSaleOptions[2].value ||
              tabActive === btnGroupCostOptions[2].value) && (
              <Col className="d-flex align-items-end justify-content-end">
                <Button
                  variant="primary"
                  className="w-btn btn-focus-none ms-4 me-3 h-45px"
                  disabled={!activeColumns.length}
                  onClick={() => {
                    setIsShowBlukEmailModal(true);
                  }}
                >
                  チェックしたユーザへメールを送信
                </Button>
              </Col>
            )}
          </Row>
        </Filter>
        {renderTab}
      </Container>
      <Modal
        show={isShowBlukEmailModal}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCloseModal={() => {
          setIsShowBlukEmailModal(false);
        }}
        dialogClassName="w-900px"
        footerClassName="d-none"
        title="一括メール送信"
        // title={
        //   isReview
        //     ? `${
        //         (songList && songList.find((item: Song) => item.index === currentSong)?.name) ??
        //         '【楽曲が登録されていません】'
        //       }の詳細`
        //     : `${
        //         variationName ?? '名称未設定バリエーション'
        //       }の${currentSong}曲⽬のデータを登録します`
        // }
      >
        <ECSendBulkEmailForm
          onCloseModal={() => {
            setIsShowBlukEmailModal(false);
          }}
          callback={() => {
            setActiveColumns([]);
          }}
          setLoading={setLoading}
          data={activeColumns}
        />
      </Modal>
    </>
  );
}
