/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Form, Row, Col, Image } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router';
import InputSearch from './PurchaseInputSearch';
import { SearchMultipleProps, ResultProps } from './ISearch';
import { LoadingIcon } from 'Components/Common/Select/CustomSelect/LoadingIcon';

import SelectComponent from 'Components/Common/Select/index';

import { components } from 'react-select';
import searchIcon from 'Static/Images/search-icon.svg';

export default function PurchaseMultipleSearch({
  label,
  placeholder,
  rowClass,
  classLabel,
  colClass,
  labelMd,
  labelLg,
  colMd,
  colLg,
  showIcon,
  isDisabled,
  queryValue,
  setQueryValue,
  setQueryValueTitle,
  onEnter,
  searchKey,
  options,
  loading,
  onClickResult,
  onLoadMore,
  onFocusInput,
  onMenuClose,
  disabled,
}: SearchMultipleProps): JSX.Element {
  const callbackRef = useRef<{ handleSetQuery: (query: string) => void }>(null);

  const [scrollTop, setScrollTop] = useState<number>(0);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const queryParams = Object.fromEntries(urlParams);
  const [isShow, setIsShow] = useState(false);

  const handleClickItem = (result: Array<ResultProps>) => {
    setQueryValue('');
    setQueryValueTitle(result?.map((res) => res.label));
    // callbackRef && callbackRef?.current?.handleSetQuery();
    const id = result?.map((res) => res.value);
    onClickResult(id, searchKey);
    handleReset();
  };

  const handleReset = () => {
    setIsShow(false);
  };

  const handleOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      onEnter();
      if (loading) return;
      handleReset();
    }
  };

  // useEffect(() => {
  //   if (!isShow && onMenuClose) onMenuClose();
  // }, [isShow, onMenuClose]);

  const handleOnSearch = (value: any) => {
    setQueryValue(value);
  };

  return (
    <>
      <Form.Group
        as={Row}
        className={rowClass}
        onBlur={() => {
          // handleOnBlur();
          handleReset();
        }}
        onFocus={() => {
          setIsShow(true);
        }}
        key={searchKey}
      >
        {label && (
          <Form.Label className={classLabel} column md={labelMd} lg={labelLg}>
            {label}
          </Form.Label>
        )}
        <Col
          md={colMd}
          lg={colLg}
          className={`${colClass} ${disabled && 'pe-none'} position-relative`}
        >
          <div className="position-relative input-search-wrapper">
            {showIcon && (
              <Image
                src={searchIcon}
                style={{ zIndex: 100 }}
                className="icon-search position-absolute"
              />
            )}
            <SelectComponent
              isMulti
              // ref={selectRef}
              // disabled={disabled}
              // defaultMenuIsOpen
              isDisabled={disabled}
              value={queryValue}
              onChange={handleClickItem}
              onSearch={handleOnSearch}
              placeholder={placeholder}
              options={options}
              // components={{ MenuList }}
              onMenuScrollToBottom={(event: any) => {
                onLoadMore && onLoadMore(event);
              }}
              // isLoading={loading}
              noOptionsMessage={() => '検索結果がありません。'}
              maxMenuHeight={500}
              styles={{
                placeholder: (defaultStyles: any) => {
                  return {
                    ...defaultStyles,
                    color: 'gray',
                    opacity: 1,
                  };
                },
                control: (defaultStyles: any) => {
                  return {
                    ...defaultStyles,
                    borderColor: '#dfe1e5!important',
                    cursor: 'text',
                    boxShadow: 'none',
                    backgroundColor: '#fff!important',
                    '&:hover': {
                      boxShadow: 'rgb(32 33 36 / 28%) 0px 1px 6px 0px',
                    },
                  };
                },
                option: (defaultStyles: any) => {
                  return {
                    ...defaultStyles,
                    cursor: 'pointer',
                  };
                },
                noOptionsMessage: (defaultStyles: any) => {
                  return {
                    ...defaultStyles,
                    // display: 'none',
                    color: 'inherit',
                  };
                },
                valueContainer: (defaultStyles: any) => {
                  return {
                    ...defaultStyles,
                    paddingLeft: '30px',
                  };
                },
                clearIndicator: (defaultStyles: any) => {
                  return {
                    ...defaultStyles,
                    padding: 0,
                    cursor: 'pointer',
                  };
                },
                indicatorSeparator: (defaultStyles: any) => {
                  return {
                    ...defaultStyles,
                    display: 'none',
                  };
                },
                dropdownIndicator: (defaultStyles: any) => {
                  return {
                    ...defaultStyles,
                    padding: '0 5px',
                  };
                },
              }}
            />
          </div>
        </Col>
      </Form.Group>
    </>
  );
}
