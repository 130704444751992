import React, { useState, useRef, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router';
import InputSearch from 'Components/Common/Search/InputSearch';
import { SearchProps, ResultProps } from 'Components/Common/Search/ISearch';
import { LoadingIcon } from 'Components/Common/Select/CustomSelect/LoadingIcon';

export default function SearchCommon({
  label,
  placeholder,
  rowClass,
  classLabel,
  colClass,
  labelMd,
  labelLg,
  colMd,
  colLg,
  showIcon,
  isDisabled,
  onEnter,
  searchKey,
  options,
  loading,
  onClickResult,
  onLoadMore,
  onFocusInput,
  onMenuClose,
  disabled,
}: SearchProps): JSX.Element {
  const callbackRef = useRef<{ handleSetQuery: (query: string) => void }>(null);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const queryParams = Object.fromEntries(urlParams);
  const [isShow, setIsShow] = useState(false);

  const handleClickItem = (result: ResultProps) => {
    callbackRef && callbackRef?.current?.handleSetQuery(result.label);
    const id = result.value;
    onClickResult(id, searchKey);
    handleReset();
  };

  const handleReset = () => {
    setIsShow(false);
  };

  const handleOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      onEnter();
      if (loading) return;
      handleReset();
    }
  };

  // const handleOnBlur = () => {
  //   console.log(queryParams?.queryArtist);
  // };

  useEffect(() => {
    if (!isShow && onMenuClose) onMenuClose();
  }, [isShow, onMenuClose]);

  return (
    <>
      <Form.Group
        as={Row}
        className={rowClass}
        onBlur={() => {
          // handleOnBlur();
          handleReset();
        }}
        onFocus={() => {
          setIsShow(true);
        }}
        key={searchKey}
      >
        {label && (
          <Form.Label className={classLabel} column md={labelMd} lg={labelLg}>
            {label}
          </Form.Label>
        )}
        <Col
          md={colMd}
          lg={colLg}
          className={`${colClass} ${disabled && 'pe-none'} position-relative`}
        >
          <InputSearch
            ref={callbackRef}
            setShow={setIsShow}
            placeholder={placeholder}
            showIcon={showIcon}
            params={queryParams}
            searchKey={searchKey}
            onEnter={handleOnEnter}
            onFocusInput={onFocusInput}
            isDisabled={isDisabled}
          />
          {isShow && (
            <div className="search-results">
              <ul
                onScroll={(event: any) => {
                  const { scrollTop, clientHeight, scrollHeight } = event?.target;
                  const bottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 2;
                  if (bottom && !loading) {
                    onLoadMore && onLoadMore(event);
                  }
                }}
              >
                {isEmpty(options) && !loading ? (
                  <li className="text-center result-option">検索結果がありません。</li>
                ) : (
                  options.map((option: any, index: number) => (
                    <li
                      data-test="result"
                      key={`rsa-result-${index}`}
                      onMouseDown={() => handleClickItem(option)}
                      role="presentation"
                      className="result-option"
                    >
                      <div className="text-truncate">{option.label}</div>
                    </li>
                  ))
                )}
                {loading && (
                  <li className="result-option text-center">
                    <LoadingIcon />
                  </li>
                )}
              </ul>
            </div>
          )}
        </Col>
      </Form.Group>
    </>
  );
}
