/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';

interface QueryParams {
  page?: number;
  perPage?: number;
  queryCompany?: string;
  type?: string;
}

export const CompanyApi = {
  getListCompanySuggestion: ({ page, perPage, queryCompany }: QueryParams) => {
    return brAxios.get(
      `/suggestion/companies?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        queryCompany ? encodeURIComponent(queryCompany) : ''
      }`,
    );
  },
};
