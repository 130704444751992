/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useAppDispatch } from 'App/Store';
import { ACCOUNT_TYPE } from 'Constant/EC';

import { removeHashPassword } from 'App/Features/HashPassword';

import { ICheckBox } from 'Types/Common';

import ModalComponent from 'Components/Common/Modal';

import { CompanyInformationApi, Ips } from 'Datasource/CompanyInformationApi';
import { ECApis } from 'Datasource/EC';
import {
  katakanaFullSizeValidationInBankAccountOwner,
  postCodeValidation,
  numberOfCharacters,
  branchValidationNotRequire,
  stringRequiredTrim,
  stringNotRequired,
  stringRequired,
  minMsg,
  maxMsg,
  hashPasswordValidation,
  confirmHashPasswordValidation,
  ENGLISH_CHAR_ONLY,
} from 'Utils/Validation';
import { toast } from 'react-toastify';
import useMessage from 'Hooks/useMessage';

import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import ECFormGroupInput from 'Components/Page/EC/Form/FormGroupInput';
import GroupCheckBox from 'Components/Common/Form/GroupCheckBox';
import BoxForm from 'Components/Common/Form/BoxForm';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import FormLabel from 'Components/Common/Form/FormLabel';
import { handleEnteringPhoneNumber, handleEnteringNumber } from 'Utils/Numbers';
import { useAppSelector } from 'App/Store';
import { getRoleName } from 'Utils/Permission';
import { BankApi } from 'Datasource/Bank';
import ECPaymentInfo from 'Components/Page/EC/ECPaymentInfo';
import { ROLE_RECORD_MENU } from 'Constant/Roles';
import MessageError from 'Components/Common/MessageError';
import { PlusSquareDotted, DashSquareDotted } from 'react-bootstrap-icons';
import { ACCOUNT_PAYMENT_STATUS } from 'Constant/EC';
import { handleEnglishCharOnly } from 'Utils/TextField';
import { randomId } from 'Utils/Title';
import { isEmpty } from 'lodash';

export interface FormCompanyInfo {
  name: string;
  agentName: string;
  zipCode: string;
  firstAddress: string;
  secondAddress: string;
  bankName: string;
  branchCode: string;
  branchName: string;
  bankAccountType: number;
  bankAccountNumber: string;
  bankAccountOwner: string;
  masterName: string;
  registrationNumber: string;
  masterPhoneNumber: string;
  masterEmail: string;
}

const schema = yup.object().shape({
  name: stringRequiredTrim({}).nullable(),
  agentName: stringRequiredTrim({}).nullable(),
  // zipCode: postCodeValidation({}).nullable(),
  zipCode: stringRequiredTrim({}).nullable(),
  firstAddress: stringRequiredTrim({}).nullable(),
  bankCode: stringRequiredTrim({}).max(255, maxMsg(255)).nullable(),
  bankName: stringRequiredTrim({}).max(255, maxMsg(255)).nullable(),
  branchCode: branchValidationNotRequire({}).max(255, maxMsg(255)).nullable(),
  branchName: stringRequiredTrim({}).max(255, maxMsg(255)).nullable(),
  bankAccountNumber: stringRequiredTrim({}).nullable(),
  registrationNumber: yup
    .string()
    .matches(ENGLISH_CHAR_ONLY, '半角英数字で入力してください。')
    .max(14, maxMsg(14))
    .nullable(),
  bankAccountOwner: katakanaFullSizeValidationInBankAccountOwner({}).nullable(),
  rules: yup.array().of(
    yup.object().shape({
      source: yup
        .string()
        .trim()
        .test({
          name: 'source',
          message: '正しいIPアドレスを入力してください。',
          test: (value, context: any) => {
            const { createError, path } = context;
            if (value === undefined || value === null || value === '') return true;
            const IPv4SegmentFormat = '(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])';
            const IPv4AddressFormat = `(${IPv4SegmentFormat}[.]){3}${IPv4SegmentFormat}`;
            const IPv4AddressRegExp = new RegExp(`^${IPv4AddressFormat}$`);
            const result = IPv4AddressRegExp.test(value);
            return (
              result ||
              createError({
                message: '正しいIPアドレスを入力してください。',
                path,
              })
            );
          },
        }),
    }),
  ),
});

const schemaPw = yup.object().shape({
  oldPassword: stringNotRequired()
    .when('hasPassword', {
      is: (hasPassword: boolean) => !!hasPassword,
      then: stringRequired({}).nullable(),
    })
    .nullable(),
  newPassword: hashPasswordValidation({}),

  confirmNewPassword: confirmHashPasswordValidation({
    ref: 'newPassword',
    matched: '会計パスワードと一致しません。',
  }),
});

const bankNameError = '入力した銀行コードが一致する銀行名は存在しません。';
const branchNameError = '入力した支店コードが一致する支店名は存在しません。';

export default function CreateCompanyInfo(): JSX.Element {
  const { role } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  const [disabled, setDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowModalConfirm, setIsShowModalConfirm] = useState<boolean>(false);

  const [isShowModalClearPw, setIsShowModalClearPw] = useState<boolean>(false);
  const [isShowModalRegisterPw, setIsShowModalRegisterPw] = useState<boolean>(false);
  const [dataCompanyInfo, setDataCompanyInfo] = useState<FormCompanyInfo | any>({
    agentName: '',
    bankAccountNumber: '',
    bankAccountOwner: '',
    bankAccountType: 0,
    bankCode: '',
    bankName: '',
    branchCode: '',
    branchName: '',
    firstAddress: null,
    masterEmail: '',
    masterName: '',
    masterPhoneNumber: null,
    registrationNumber: null,
    name: '',
    secondAddress: null,
    zipCode: null,
    phoneNumber: '',
  });

  const [dataSaleChannelTenantInfo, setDataSaleChannelTenantInfo] = useState<any>({});

  const { openMessageError } = useMessage();

  const [bankAccountType, setBankAccountTypes] = useState<Array<ICheckBox>>([
    {
      label: '普通口座',
      checked: true,
      value: 0,
    },
    {
      label: '当座口座',
      checked: false,
      value: 1,
    },
  ]);

  const isMiimEcOrExternalEc = useMemo(() => {
    if (
      role &&
      (role === ROLE_RECORD_MENU.ECCompMaster ||
        role === ROLE_RECORD_MENU.ECCompManager ||
        role === ROLE_RECORD_MENU.SALEChanelMaster ||
        role === ROLE_RECORD_MENU.SALEChanelManager)
    ) {
      return true;
    }
    return false;
  }, [role]);

  useEffect(() => {
    handleGetCompanyInformation();
    if (isMiimEcOrExternalEc) {
      handleGetSaleChannelTenantDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMiimEcOrExternalEc]);

  // useEffect(() => {
  //   //* role !== MASTER cannot edit
  //   if (role) {
  //     setDisabled(getRoleName(role) !== 'MASTER');
  //   }
  // }, [role]);

  const isMasterRole = useMemo(() => {
    if (role) {
      return getRoleName(role) === 'MASTER';
    }
    return false;
  }, [role]);

  const handleGetCompanyInformation = async () => {
    setIsLoading(true);
    try {
      const res = await CompanyInformationApi.getInformation();
      const { data } = res.data;
      if (data.hasPassword) {
        setValuePw('hasPassword', true);
      }
      setDataCompanyInfo({ ...data });

      const bankAccountTypeValid = bankAccountType.map((type) => {
        if (data.bankAccountType == type.value) {
          return { ...type, checked: true };
        } else {
          return { ...type, checked: false };
        }
      });

      setBankAccountTypes(bankAccountTypeValid);

      reset({ ...data, rules: watch('rules') });
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetSaleChannelTenantDetail = async () => {
    setIsLoading(true);
    try {
      const res = await ECApis.getSaleChannelTenantDetail();
      const { data } = res.data;
      // console.log(data, 'heress');
      setDataSaleChannelTenantInfo(data);

      // reset(data);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * return true: need review
   */
  const handleVerifyStatusPayment = (payments: any) => {
    if (!payments) {
      return true;
    }

    if (payments.length === 0) {
      return true;
    }

    if (payments.every((item: any) => item.status !== 'passed')) {
      return true;
    }

    return false;
  };

  const onChangeAccountType = (value: Array<ICheckBox>) => {
    setBankAccountTypes(value);
    setValue('bankAccountType', value.find((item) => item.checked)?.label, {
      shouldValidate: true,
    });
  };

  const {
    register,
    setValue,
    reset,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...dataCompanyInfo,
      rules: [{ id: randomId(), description: '', source: '' }],
    },
    resolver: yupResolver(schema),
  });

  const {
    register: registerPw,
    setValue: setValuePw,
    reset: resetPw,
    handleSubmit: handleSubmitPw,
    getValues: getValuesPw,
    watch: watchPw,
    formState: { errors: errorsPw },
  } = useForm<any>({
    // defaultValues: {
    //   oldPassword: null,
    //   newPassword: null,
    //   confirmNewPassword: null,
    // },
    resolver: yupResolver(schemaPw),
  });

  const handleOpenCreateCompanyInforModal = () => {
    setIsShowModalConfirm(true);
  };

  const handleCreateCompanyInformation = async () => {
    setIsLoading(true);
    const phone = getValues('phoneNumber');
    const param = {
      ...dataCompanyInfo,
      phoneNumber: phone,
      bankAccountType: bankAccountType[0].checked ? 0 : 1,
    };
    delete param['rules'];

    try {
      await CompanyInformationApi.updateInformation(param);
      if (isMasterRole) {
        //* create or update white list ip
        const whitelistIps: Ips[] = watch('rules')
          ?.map((rule: Ips) => ({
            ...rule,
            id: rule?.id?.toString()?.includes('uuid') ? null : rule.id,
          }))
          .filter((ip: Ips) => !isEmpty(ip.source));

        const {
          data: { data: rules },
        } = await CompanyInformationApi.createWhiteListIp(whitelistIps);
        setValue(
          'rules',
          isEmpty(rules) ? [{ id: randomId(), description: '', source: '' }] : rules,
        );
        //******************************* */
      }

      setIsShowModalConfirm(false);
    } catch (error) {
      openMessageError(error);
      setIsLoading(false);
    }
    handleGetCompanyInformation();
  };

  const handleOpenRegisterECPassword = () => {
    setIsShowModalRegisterPw(true);
  };

  const handleSubmitRegisterECPassword = async () => {
    setIsLoading(true);

    try {
      const values = getValuesPw();

      const params = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      const res = await ECApis.updateSaleChannelPassword(params);
      // toast('パスワードリセットが登録しました', { type: 'success' });
      setIsShowModalRegisterPw(false);
      dispatch(removeHashPassword());

      resetPw();
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
    handleGetCompanyInformation();
  };

  const handleClearPassword = async () => {
    setIsLoading(true);
    try {
      const res = await ECApis.putSaleChannelClearPassword();
      toast('パスワードリセットが完了しました。', { type: 'success' });
      setIsShowModalClearPw(false);
      resetPw();
      dispatch(removeHashPassword());
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
    handleGetCompanyInformation();
  };

  const openClearPasswordModal = () => {
    setIsShowModalClearPw(true);
  };

  const setFillInput = (value: string, nameFill: string, isPhone = false) => {
    setValue(nameFill, isPhone ? handleEnteringPhoneNumber(value) : value);
    setDataCompanyInfo((old: any) => ({
      ...old,
      [nameFill]: isPhone ? handleEnteringPhoneNumber(value) : value,
    }));
  };

  const getBankInfo1 = async (bankCode: string) => {
    const response = await BankApi.getBankInfo(bankCode);
    if (response.data.data[0]) {
      clearErrors('bankCode');
      setFillInput(response.data.data[0].name, 'bankName');
    } else {
      setFillInput('', 'bankName');
      setError('bankCode', { type: 'custom', message: bankNameError });
    }
  };

  const getBankBranchInfo1 = async (bankCode: string, branchCode: string) => {
    const response = await BankApi.getBankBranchInfo(bankCode, branchCode);
    if (response.data.data[0]) {
      clearErrors('branchCode');
      setFillInput(response.data.data[0].name, 'branchName');
    } else {
      setFillInput('', 'branchName');
      setError('branchCode', { type: 'custom', message: branchNameError });
    }
  };

  const getWhiteListIp = async () => {
    try {
      const {
        data: { data },
      } = await CompanyInformationApi.getWhiteListIp();
      if (data?.length > 0) {
        setValue('rules', data);
      } else {
        const initData: Ips[] = [{ id: randomId(), description: '', source: '' }];
        setValue('rules', initData);
      }
    } catch (error) {
      openMessageError(error);
    }
  };

  useEffect(() => {
    getWhiteListIp();
  }, []);

  return (
    <Container className="py-4">
      <ModalComponent
        title="登録確認"
        show={isShowModalConfirm}
        onCloseModal={() => setIsShowModalConfirm(false)}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="primary"
        onSubmitModal={handleCreateCompanyInformation}
        confirmText="保存する"
      >
        <div>入力した内容で保存します。よろしいですか。</div>
      </ModalComponent>
      <ModalComponent
        title="会計パスワードをリセットしますか？"
        show={isShowModalClearPw}
        onCloseModal={() => setIsShowModalClearPw(false)}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="primary"
        onSubmitModal={handleClearPassword}
        classBtnSubmit="btn btn-danger"
        confirmText="リセットする"
      >
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div>
            <MessageError message="ご注意" />
          </div>
          <div className="text-center mt-3">
            会計パスワードをリセットすると登録済みの <br /> 仕入率が削除され初期状態となります。
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        title={
          dataCompanyInfo.hasPassword
            ? '会計パスワードを変更しますか？'
            : '会計パスワードを登録しますか？'
        }
        show={isShowModalRegisterPw}
        onCloseModal={() => setIsShowModalRegisterPw(false)}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="primary"
        onSubmitModal={handleSubmitRegisterECPassword}
        confirmText={dataCompanyInfo.hasPassword ? '変更する' : '登録する'}
      >
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div>
            <MessageError message="ご注意" />
          </div>
          <div className="text-center mt-3">
            会計パスワードは再発行できません。
            <br />
            初期化し再設定は可能ですが仕入率の再登録が必要となります。
            <br />
            入力したパスワードは紛失しないようご注意ください。
          </div>
        </div>
      </ModalComponent>
      <SpinnerComponent isLoading={isLoading} />
      <Row className={`${disabled && 'pe-none user-select-none'}`}>
        <Col md="12" className="px-0">
          <BoxForm title="会社情報">
            <>
              <Form id="create-company-info">
                <FormGroupInput
                  value={dataCompanyInfo?.name}
                  onChange={(e) => setFillInput(e, 'name')}
                  labelMd="2"
                  colMd="10"
                  disabled={!isMasterRole}
                  label="法人名*"
                  register={{ ...register('name') }}
                  errorMessage={errors.name?.message}
                />
                <FormGroupInput
                  value={dataCompanyInfo?.agentName}
                  onChange={(e) => setFillInput(e, 'agentName')}
                  labelMd="2"
                  colMd="10"
                  disabled={!isMasterRole}
                  label="代表者名*"
                  register={{ ...register('agentName') }}
                  errorMessage={errors.agentName?.message}
                />
                <FormGroupInput
                  maxLength={16}
                  value={dataCompanyInfo?.zipCode}
                  onChange={(e) => setFillInput(handleEnteringNumber(e), 'zipCode')}
                  labelMd="2"
                  colMd="4"
                  disabled={!isMasterRole}
                  label="郵便番号*"
                  register={{ ...register('zipCode') }}
                  errorMessage={errors.zipCode?.message}
                />
                <FormGroupInput
                  value={dataCompanyInfo?.firstAddress}
                  onChange={(e) => setFillInput(e, 'firstAddress')}
                  labelMd="2"
                  colMd="10"
                  disabled={!isMasterRole}
                  label="住所1*"
                  placeholder="港区南青山1-2-3"
                  register={{ ...register('firstAddress') }}
                  errorMessage={errors.firstAddress?.message}
                />
                <FormGroupInput
                  value={dataCompanyInfo?.secondAddress}
                  onChange={(e) => setFillInput(e, 'secondAddress')}
                  labelMd="2"
                  colMd="10"
                  label="住所2"
                  disabled={!isMasterRole}
                  placeholder="南青山ABCビル5F"
                  register={{ ...register('secondAddress') }}
                  errorMessage={errors.secondAddress?.message}
                />
                <FormGroupInput
                  value={dataCompanyInfo?.phoneNumber}
                  labelMd="2"
                  colMd="4"
                  label="電話番号"
                  disabled={!isMasterRole}
                  placeholder=""
                  register={{
                    ...register('phoneNumber'),
                  }}
                  onChange={(e: string) => setFillInput(e, 'phoneNumber', true)}
                  errorMessage={errors.phoneNumber?.message}
                  maxLength={13}
                  hintText="※「-」をつけて入力。（例）03-1234-5678 / 080-1234-5678"
                />
                <ECFormGroupInput
                  value={dataCompanyInfo?.bankCode}
                  onChange={(e: string) => setFillInput(handleEnteringNumber(e) ?? '', 'bankCode')}
                  onBlur={(e: any) => {
                    if (dataCompanyInfo?.bankCode == '') {
                      setFillInput('', 'bankName');
                    }
                    getBankInfo1(e.target.value);
                    setFillInput('', 'branchCode');
                    setFillInput('', 'branchName');
                  }}
                  disabled={!isMasterRole}
                  labelMd={2}
                  colMd={10}
                  label="銀行コード*"
                  errorMessage={errors.bankCode?.message}
                  messageWrapper="mt-0 ml-6px"
                  maxLength={4}
                  register={{ ...register('bankCode') }}
                  classInput="w-60px"
                  classCol="d-flex align-items-center"
                  hintText={`銀行名 ${
                    dataCompanyInfo?.bankName != undefined && dataCompanyInfo?.bankName != ''
                      ? `:`
                      : ''
                  }`}
                  hintTextClass="fs-14 ml-40px"
                  hintTextDes={`${
                    dataCompanyInfo?.bankName != undefined ? `${dataCompanyInfo?.bankName}` : ''
                  }`}
                  hintTextDesClass={`${
                    dataCompanyInfo?.bankName == bankNameError
                      ? 'text-error ml-6px fs-small'
                      : 'ml-6px'
                  }`}
                />

                <ECFormGroupInput
                  value={dataCompanyInfo?.branchCode}
                  onChange={(value) =>
                    setFillInput(handleEnteringNumber(value) ?? '', 'branchCode')
                  }
                  onBlur={(e: any) => {
                    if (dataCompanyInfo?.branchCode == '') {
                      setFillInput('', 'branchName');
                    }
                    if (dataCompanyInfo?.bankName != '') {
                      getBankBranchInfo1(dataCompanyInfo?.bankCode, e.target.value);
                    }
                  }}
                  labelMd={2}
                  colMd={10}
                  label="支店コード*"
                  maxLength={3}
                  errorMessage={errors.branchCode?.message}
                  messageWrapper="mt-0 ml-6px"
                  register={{ ...register('branchCode') }}
                  classInput="w-60px"
                  disabled={
                    dataCompanyInfo?.bankName == bankNameError ||
                    dataCompanyInfo?.bankName == undefined ||
                    dataCompanyInfo?.bankName == ''
                      ? true
                      : false || !isMasterRole
                  }
                  classCol="d-flex align-items-center"
                  hintText={`支店名 ${
                    dataCompanyInfo?.branchName != undefined && dataCompanyInfo?.branchName != ''
                      ? `:`
                      : ''
                  }`}
                  hintTextClass="fs-14 ml-40px"
                  hintTextDes={`${
                    dataCompanyInfo?.branchName != undefined ? `${dataCompanyInfo?.branchName}` : ''
                  }`}
                  hintTextDesClass={`${
                    dataCompanyInfo?.branchName == branchNameError
                      ? 'text-error ml-6px fs-small'
                      : 'ml-6px'
                  }`}
                />

                <GroupCheckBox
                  id="bank-acount-type"
                  classForm="mb-3 d-flex align-items-center"
                  labelForm="口座種別*"
                  disabled={!isMasterRole}
                  options={bankAccountType}
                  labelMd="2"
                  colMd="10"
                  onChange={onChangeAccountType}
                />
                <FormGroupInput
                  value={dataCompanyInfo?.bankAccountNumber}
                  onChange={(e) => setFillInput(handleEnteringNumber(e) ?? '', 'bankAccountNumber')}
                  labelMd="2"
                  colMd="4"
                  disabled={!isMasterRole}
                  label="口座番号*"
                  errorMessage={errors.bankAccountNumber?.message}
                  register={{ ...register('bankAccountNumber') }}
                />
                <FormGroupInput
                  value={dataCompanyInfo?.bankAccountOwner}
                  onChange={(e) => setFillInput(e, 'bankAccountOwner')}
                  labelMd="2"
                  colMd="10"
                  disabled={!isMasterRole}
                  label="口座名義（カタカナ）*"
                  hintText="全角カタカナで入力してください（全角カタカナ、全角数字、全角スペース、記号（）．ー／）"
                  // hintTextClass="mt-1"
                  errorMessage={errors.bankAccountOwner?.message}
                  register={{ ...register('bankAccountOwner') }}
                />

                <ECFormGroupInput
                  value={dataCompanyInfo?.registrationNumber}
                  onChange={(value) =>
                    setFillInput(handleEnglishCharOnly(value) ?? '', 'registrationNumber')
                  }
                  labelMd={2}
                  colMd={4}
                  label="登録番号"
                  disabled={!isMasterRole}
                  maxLength={14}
                  errorMessage={errors.registrationNumber?.message}
                  register={{ ...register('registrationNumber') }}
                  subLabel="（適格請求書発行事業者登録）"
                  hintText="（14桁）"
                  classCol="d-flex align-items-center"
                  classForm="d-flex align-items-center"
                  hintTextClass=" ml-10px min-w-50px"
                  leftTextClass="mr-10px"
                  messageWrapper="mb-2px"
                />

                <Row>
                  <Form.Label column md={2}>
                    マスター担当者
                  </Form.Label>
                  <Form.Label className="ps-4" column md={10}>
                    {dataCompanyInfo?.masterName}
                  </Form.Label>
                </Row>
                <Row>
                  <Form.Label column md={2}>
                    所属部署
                  </Form.Label>
                  <Form.Label className="ps-4" column md={10}>
                    {dataCompanyInfo?.sectionName}
                  </Form.Label>
                </Row>
                <Row>
                  <Form.Label column md={2}>
                    マスター直通電話番号
                  </Form.Label>
                  <Form.Label className="ps-4" column md={10}>
                    {dataCompanyInfo?.masterPhone}
                  </Form.Label>
                </Row>
                <Row>
                  <Form.Label column md={2}>
                    メールアドレス
                  </Form.Label>
                  <Form.Label className="ps-4" column md={10}>
                    {dataCompanyInfo?.masterEmail}
                  </Form.Label>
                </Row>
              </Form>
              <div className="d-flex justify-content-end txt-require">
                ご注意：＊印は必須項目です。
              </div>
            </>
          </BoxForm>

          <BoxForm classBox="mt-4" title={'管理画面アクセス制限'}>
            <p className="ms-4">許可IPアドレス</p>
            {watch('rules')?.map((item: Ips, index: number) => (
              <>
                <Row className="align-items-center mt-2" key={item.id}>
                  <Col md="10">
                    <FormGroupInput
                      labelWrapperClass="d-none"
                      classForm="mb-0"
                      name="ip-form"
                      labelMd="12"
                      colMd="12"
                      label=""
                      value={item.source || ''}
                      onChange={(value) => {
                        const currentValueIndex = watch('rules')?.findIndex(
                          (rule: Ips) => rule.id === item.id,
                        );
                        if (currentValueIndex !== -1) {
                          const tmpRules: Ips[] = [...watch('rules')];
                          tmpRules[currentValueIndex].source = value?.trim();
                          setValue('rules', tmpRules);
                        }
                      }}
                      placeholder="0.0.0.0"
                      disabled={!isMasterRole}
                    />
                  </Col>
                  <Col md="2" className="px-0">
                    <div>
                      {index === watch('rules')?.length - 1 ? (
                        <Button variant="outline" className="ps-0" disabled={!isMasterRole}>
                          <PlusSquareDotted
                            size={28}
                            color="gray"
                            onClick={() => {
                              setValue('rules', [
                                ...watch('rules'),
                                { id: randomId(), description: '', source: '' },
                              ]);
                            }}
                          />
                        </Button>
                      ) : (
                        <></>
                      )}
                      {watch('rules')?.length > 1 ? (
                        <Button variant="outline" className="px-0" disabled={!isMasterRole}>
                          <DashSquareDotted
                            size={28}
                            color="gray"
                            onClick={() => {
                              const filterList = watch('rules')?.filter(
                                (rule: Ips) => rule.id !== item.id,
                              );
                              setValue('rules', filterList);
                              clearErrors(`rules.${index}.source`);
                            }}
                          />
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                </Row>
                <MessageError
                  classWrapper="ms-4"
                  message={errors?.rules && errors?.rules[index]?.source?.message}
                />
              </>
            ))}
          </BoxForm>

          <div className="d-flex justify-content-center pb-4">
            <Button
              className="mt-4 mb-4 btn-focus-none btn-curator-register"
              variant="primary"
              // type="submit"
              onClick={handleSubmit(handleOpenCreateCompanyInforModal)}
              form="create-company-info"
              disabled={disabled || !isMasterRole}
            >
              保存する
            </Button>
          </div>
        </Col>

        {isMiimEcOrExternalEc && (
          <Col md="12" className="px-0">
            <BoxForm title="EC設定">
              <Row>
                <Form.Label column md={2}>
                  クレジットカード 会社
                  <br />
                  審査状況
                </Form.Label>
                <Form.Label className="ps-4" column md={10}>
                  <div className="d-flex flex-column">
                    {(dataSaleChannelTenantInfo.bankAccountStatus === null ||
                      handleVerifyStatusPayment(dataSaleChannelTenantInfo.items)) && (
                      <div className="mb-3">
                        <Button disabled={!isMasterRole} className="w-144px p-0">
                          <a
                            href={dataSaleChannelTenantInfo.applicationUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="w-144px d-inline-block text-decoration-none py-2"
                            style={{ color: '#ffffff' }}
                          >
                            審査申請を行う
                          </a>
                        </Button>

                        <div className="ec_fs-12 mt-2">
                          クレジットカード決済を開始するには上記ボタンより審査申請を行ってください。
                        </div>
                      </div>
                    )}

                    {/* {dataSaleChannelTenantInfo.bankAccountStatus ===
                      ACCOUNT_PAYMENT_STATUS.success && ( */}
                    <div className="d-flex align-items-start mb-3">
                      {dataSaleChannelTenantInfo.bankAccountStatus !== null &&
                        Array.isArray(dataSaleChannelTenantInfo.items) &&
                        dataSaleChannelTenantInfo.items.length > 0 &&
                        dataSaleChannelTenantInfo.items.map((item: any, index: number) => (
                          <ECPaymentInfo key={`${item.brand}-${index}`} data={item} />
                        ))}
                    </div>
                    {/* )} */}
                  </div>
                </Form.Label>
              </Row>
              <Row>
                <Form.Label column md={2}>
                  売上振込口座
                </Form.Label>
                <Form.Label className="ps-4" column md={10}>
                  <div>
                    <div className="mb-2">
                      {dataCompanyInfo.ecSaleChannelBankName} &nbsp;&nbsp;{' '}
                      {dataCompanyInfo.ecSaleChannelBranchName
                        ? `(${dataCompanyInfo.ecSaleChannelBranchName})`
                        : null}
                    </div>
                    <div className="mb-2">{dataCompanyInfo.ecSaleChannelBankAccountOwner}</div>
                    <div className="mb-2">
                      {ACCOUNT_TYPE.find(
                        (account) => account.value == dataCompanyInfo.ecSaleChannelBankAccountType,
                      )?.label ?? ''}{' '}
                      &nbsp;&nbsp; {dataCompanyInfo.ecSaleChannelBankAccountNumber}
                    </div>
                  </div>

                  <div className="mt-4 mb-4">
                    <Link to="/operating-company-info">
                      <span>変更する場合は弊社までご連絡ください。</span>
                    </Link>
                  </div>
                </Form.Label>
              </Row>

              <Row>
                {dataCompanyInfo.hasPassword && (
                  <Row>
                    <Col md={6} className="p-0">
                      <FormGroupInput
                        // maxLength={7}
                        value={watchPw('oldPassword')}
                        onChange={(e) => setValuePw('oldPassword', e)}
                        labelMd="4"
                        colMd="8"
                        disabled={!isMasterRole}
                        type="password"
                        label="現在の会計パスワード"
                        register={{ ...registerPw('oldPassword') }}
                        errorMessage={errorsPw.oldPassword?.message}
                      />
                    </Col>

                    <Col md={2}>
                      <Button
                        disabled={!isMasterRole}
                        className="w-100 btn btn-danger"
                        onClick={openClearPasswordModal}
                      >
                        パスワードリセット
                      </Button>
                    </Col>
                  </Row>
                )}
                <FormGroupInput
                  // maxLength={7}
                  value={watchPw('newPassword')}
                  onChange={(e) => setValuePw('newPassword', e)}
                  labelMd="2"
                  colMd="4"
                  type="password"
                  disabled={!isMasterRole}
                  label="会計パスワード"
                  register={{ ...registerPw('newPassword') }}
                  errorMessage={errorsPw.newPassword?.message}
                  classMessage="ec_mw-250ps"
                  messageWrapper="ec_w-250ps ms-2"
                />
                <FormGroupInput
                  // maxLength={7}
                  value={watchPw('confirmNewPassword')}
                  onChange={(e) => setValuePw('confirmNewPassword', e)}
                  labelMd="2"
                  colMd="4"
                  type="password"
                  label="会計パスワード確認"
                  disabled={!isMasterRole}
                  register={{ ...registerPw('confirmNewPassword') }}
                  errorMessage={errorsPw.confirmNewPassword?.message}
                  classMessage="ec_mw-250ps"
                  messageWrapper="ec_w-250ps ms-2"
                />

                <Form.Label column md={2}></Form.Label>

                <Form.Label className="ps-4" column md={10}>
                  {dataCompanyInfo.hasPassword ? (
                    <Button
                      disabled={!isMasterRole}
                      className="ms-5 mb-3"
                      onClick={handleSubmitPw(handleOpenRegisterECPassword)}
                    >
                      パスワードを変更
                    </Button>
                  ) : (
                    <Button
                      disabled={!isMasterRole}
                      className="ms-5 mb-3"
                      onClick={handleSubmitPw(handleOpenRegisterECPassword)}
                    >
                      パスワードを登録
                    </Button>
                  )}
                  <div>
                    このパスワードはレコード会社からの「仕入率」の設定、または「仕入率」を基とした帳票の閲覧及びダウンロード時に必要となります。
                    「仕入率」は本パスワードにより暗号化され保存されるため、システム管理者であっても閲覧することはできません。
                  </div>
                </Form.Label>
              </Row>
            </BoxForm>
          </Col>
        )}
      </Row>
    </Container>
  );
}
