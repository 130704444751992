import React from 'react';

interface Props {
  title: string | null;
  children: JSX.Element | JSX.Element[];
  classBox?: string;
  colorBox?: string;
  extraText?: string;
}

export default function BoxFormEc(props: Props): JSX.Element {
  const { title, children, classBox, colorBox, extraText } = props;

  return (
    <div className={`main-wrap-form ${classBox}`}>
      <div className="box-title-header bg-dark title-header-spacing rounded-top-1">
        {colorBox && <div className="tag-color" style={{ background: colorBox }} />}
        {title}
        {extraText && <p className="text-end ms-4">{extraText}</p>}
      </div>
      <div className="box-form-content card-body">{children}</div>
    </div>
  );
}
