import React from 'react';
import Filter from 'Components/Common/Filter/Filter';
import SearchApproval from 'Page/ListApproval/SearchApproval';
import { Row } from 'react-bootstrap';

export default function Header(): JSX.Element {
  return (
    <div>
      <Filter filterClass="shadow-sm">
        <Row>
          <SearchApproval />
        </Row>
      </Filter>
    </div>
  );
}
