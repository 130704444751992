import React, { useEffect, useState } from 'react';
import { CompanyInformationApi } from 'Datasource/CompanyInformationApi';
import { Col, Container, Row } from 'react-bootstrap';
import { ICuratorManagement } from 'Types/Page/CompanyInformation/CompanyInformation';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';
import { NumberParam, useQueryParams } from 'use-query-params';

interface RoleView {
  label: string;
  checked: boolean;
  subLabel: string;
}

interface ItemArtists {
  id: number;
  localName: string;
}

export interface Curator {
  artists?: Array<ItemArtists>;
  furiganaManagerName?: string;
  id: number | null;
  managerEmail?: string;
  managerName?: string;
  managerPermisson?: string;
  managerPhone?: string;
  session?: string;
  roleViews?: RoleView;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
interface CuratorListsProps {
  clickDetail: (e: Curator) => void;
}

export default function CuratorLists(props: CuratorListsProps): JSX.Element {
  const [query, setQuery] = useQueryParams({
    artistList: NumberParam,
    artistId: NumberParam,
  });

  const { clickDetail } = props;

  const [listCuratorManager, setListCuratorManager] = useState<any>([]);

  const [isLoading, setIsloading] = useState<boolean>(false);

  const { openMessageError } = useMessage();

  useEffect(() => {
    getCuratorManager();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.artistId]);

  const getCuratorManager = async () => {
    try {
      setIsloading(true);
      if (query?.artistList) {
        if (query?.artistId) {
          const res = await CompanyInformationApi.getManagers({ artistList: query?.artistList });
          const { data } = res.data;
          setListCuratorManager(data);
        } else {
          setQuery({
            artistList: undefined,
          });
          const res = await CompanyInformationApi.getManagers({ artistList: undefined });
          const { data } = res.data;
          setListCuratorManager(data);
        }
      } else {
        if (query?.artistId) {
          const res = await CompanyInformationApi.getManagers({ artistList: query?.artistId });
          const { data } = res.data;
          setListCuratorManager(data);
        } else {
          const res = await CompanyInformationApi.getManagers({ artistList: undefined });
          const { data } = res.data;
          setListCuratorManager(data);
        }
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsloading(false);
    }
  };

  const renderCurtatorManager = () => {
    return listCuratorManager.map((item: ICuratorManagement, index: number) => {
      if (!item) return;
      return (
        <Col key={index} xs="12" className="mb-4 px-0">
          <div className="border shadow p-2">
            <Row onClick={() => clickDetail(item)}>
              <Col md="6">
                <p className="pb-1 underline-100 position-relative fw-bold text-break">
                  {` ${item.managerName}  ${item?.sectionName && `(${item?.sectionName})`}`}
                </p>
                <p className="pt-1 ps-3 text-break">{item.managerEmail}</p>
                <p className="pt-1 ps-3 text-break">{item.managerPhone || '　'}</p>
              </Col>
              <Col md="6">
                {item.artists.map((item) => (
                  <>
                    <p className="pb-1 text-break">{item.artistName}</p>
                  </>
                ))}
              </Col>
            </Row>
          </div>
        </Col>
      );
    });
  };

  return (
    <div className="mt-4">
      <Container>
        <SpinnerComponent isLoading={isLoading} />
        <Row>{renderCurtatorManager()}</Row>
      </Container>
    </div>
  );
}
