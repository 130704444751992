/* eslint-disable no-useless-escape */
/*eslint @typescript-eslint/explicit-module-boundary-types: 0*/
import _, { pick } from 'lodash';
import * as Yup from 'yup';
// Messages definition
export const minMsg = (min: number) => `${min}文字以上で入力してください  `;
export const maxMsg = (max: number) => `${max}文字以内で入力してください。`;
// regex email
export const MailRegExp = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,10}$/g;
// messenger Alphabet
export const AlphabetMsg = '英字を入力してください';
export const SuggestMsg = '下記の条件の通りに入力してください。';
// regex password
export const regexValidatePassword = /^(?=.*[A-Za-z])(?=.*[0-9])(?=.{8,})/;
export const regexHashPassword = /^[A-Za-z0-9!#$%&*?_]{8,}$/;
export const PWDRegExp = /^[\w-.!@#$%^&*()_+-=:;,.?/\\|<>'" ]+$/g;
export const numberRegExp = /^[0-9]+$/;
export const REGEX_BRANCH_CODE = /^\d{1,3}$/;
export const REGEX_POST_CODE = /^\d{1,7}$/;
export const REGEX_PHONE_JAPAN = /^\d{2}(?:-\d{4}-\d{4}|\d{8}|\d-\d{3,4}-\d{4})$/;
export const REGEX_CODE_CD = /^[A-Za-z0-9-]{10,}$/;

// regex all space password
export const spaceAllRegExp =
  /^(?:(?=.*[a-zA-Z0-9])(?:(?=.*[\d\W])|(?=.*\W))|(?=.*[!@#$%^&*()_+-=:;,.?/\\|<>'"([a-zA\])])).{8,8}$/;
export const spaceAllMsg = 'スペースだけを入れてはいけません。';
export const katakanaFullSizeReg =
  /^([ァ-ン０-９“‘”’\u3000-\u303f\u30a0-\u30ff\uffe0-\uffef\u3400-\u4dbf])*$/u;
export const katakanaFullSizeRegBankAccountOwner =
  /^([ァ-ン０-９“‘”’\u3000-\u303f\u30a0-\u30ff\uffe0-\uffef\u3400-\u4dbf（）．ー／])*$/u;
export const halfsizeJP =
  /^[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u2605-\u2606\u2190-\u2195\u203B]+$/;
export const regexIsrc = /^[a-zA-Z0-9 ]*$/;
export const regexEnglish = /^[a-zA-Z0-9 `~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]*$/;
export const regexKanjiSlashes =
  /^(?!.*\s／)(?!.*／\s)(?!.*／／)(?!^／)(?!.*／$)(?:(?:[^／]*／[^／]*){0,2}[^／]*|[^／]*)$/;

export const PWD_MINIMUM_8 = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

export const katakanaFullSizeSlashesReg = /^(?!.*／／)([ァ-ヶー]+(／[ァ-ヶー]+){0,2})$/;
export const regexEnglishSlashes =
  /^(?!.*(?:^\s|／\s|\s／|\s$))(?:[\w\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+／[\w\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+／[\w\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+|[\w\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+／[\w\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+|[\w\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+)$/;

export const regexHalfWidth = /^[a-zA-Z0-9!-~\s]+$/;

export const ENGLISH_CHAR_ONLY = /^[A-Za-z0-9]*$/;
export const ENGLISH_AND_DASH = /^[A-Za-z0-9-]*$/;

// regex phone number
export const REGEX_PHONE_NUMBER = /[^0-9\-]/g;

export const REGEX_NUMBER = /[^0-9]/g;

export const REGEX_DECIMAL = /[^0-9\.]/g;

// regex Jasrac code
export const JASRAC_CODE = /^((\w\w\w)-(\w\w\w\w)-(\w))*$/u;
export const OTHER_JASRAC_CODE = /^(\w\w\w\w\w\w\w\w)*$/u;

// regex url
export const ONLY_DOMAIN_URL =
  /^$|^([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(,[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/;

export const stringRequiredTrim = ({ required = '入力してください。' }) =>
  Yup.string().required(required).trim(required);

export const stringRequired = ({ required = '入力してください。' }) =>
  Yup.string().required(required);

export const booleanRequired = ({ required = '入力してください。' }) =>
  Yup.boolean().required(required);

export const numberRequired = ({ required = '入力してください。' }) =>
  Yup.number().required(required);

export const zipCodeValidation = (message: string) =>
  Yup.string().max(100, maxMsg(100)).matches(REGEX_POST_CODE, message);

export const stringNotRequired = () => Yup.string();

export const maxLengthInput = (maxLength: number) =>
  stringNotRequired().max(maxLength, maxMsg(maxLength));

export const phoneValidation = ({ required = '入力してください。' }) =>
  stringRequired({ required }).max(100, maxMsg(100));
//TODO: await SRS
// .matches(REGEX_PHONE_JAPAN, '半角数字で入力してください。');

export const phoneValidationNotRequire = () =>
  stringNotRequiredValidation()
    .max(100, maxMsg(100))
    .matches(REGEX_PHONE_JAPAN, '電話番号の形式が正しくありません。');

export const branchValidationNotRequire = ({
  required = '入力してください。',
  msg = '半角数字3桁で入力してください。',
  number = 3,
}) =>
  stringRequired({ required }).min(number, msg).max(number, msg).matches(REGEX_BRANCH_CODE, msg);

export const postCodeValidation = ({ required = '入力してください。' }) =>
  stringRequired({ required })
    .max(100, maxMsg(100))
    .matches(REGEX_POST_CODE, '半角数字で入力してください。');

export const codeCDValidation = ({ required = '入力してください。' }) =>
  stringRequired({ required })
    .max(10, maxMsg(10))
    .matches(REGEX_CODE_CD, '半角英数字で入力してください。');

export const postCodeValidationNotRequire = () =>
  stringNotRequiredValidation()
    .max(7, maxMsg(7))
    .matches(REGEX_POST_CODE, '郵便番号の形式が正しくありません。');

export const emailValidation = ({
  email = '有効なメールアドレスを入力してください。',
  required = '入力してください。',
}) => stringRequired({ required }).email(email).max(100, maxMsg(100)).matches(MailRegExp, email);

export const passwordValidation = ({
  required = '入力してください。',
  min = 8,
  max = 256,
  mathched = 'パスワードは半角英数混合８文字以上を入力してください。',
}) =>
  stringRequired({ required })
    .min(min, 'パスワードは半角英数混合８文字以上を入力してください。')
    .max(max, maxMsg(max))
    .matches(regexValidatePassword, mathched);

export const confirmPasswordValidation = ({
  ref = 'password',
  matched = 'パスワードと、確認用のパスワードが一致しません。',
  required = '入力してください。',
  matchRegx = 'パスワードは半角英数混合８文字以上を入力してください。',
}) =>
  stringRequired({ required })
    .oneOf([Yup.ref(ref)], matched)
    .matches(regexValidatePassword, matchRegx);

export const hashPasswordValidation = ({
  required = '入力してください。',
  // min = 8,
  max = 256,
  mathched = '8文字以上入力してください。\n 半角英数字（大文字小文字）、キーボードに記載の記号（!#$%-&*?_）を入力可能です。',
}) => stringRequired({ required }).max(max, maxMsg(max)).matches(regexHashPassword, mathched);

export const confirmHashPasswordValidation = ({
  ref = 'password',
  matched = 'パスワードと、確認用のパスワードが一致しません。',
  required = '入力してください。',
  matchRegx = '8文字以上入力してください。\n 半角英数字（大文字小文字）、キーボードに記載の記号（!#$%-&*?_）を入力可能です。',
}) =>
  stringRequired({ required })
    .oneOf([Yup.ref(ref)], matched)
    .matches(regexHashPassword, matchRegx);

export const inputValidation = ({ required = '入力してください。', max = 100 }) =>
  stringRequired({ required }).max(max, maxMsg(max));

export const stringRequiredWithMaxLength = (
  maxLength: number,
  { required = '入力してください。' },
) => Yup.string().trim().required(required).max(maxLength, maxMsg(maxLength));

export const stringRequiredMaxLengthTrim = (
  maxLength: number,
  { required = '入力してください。' },
) => Yup.string().required(required).max(maxLength, maxMsg(maxLength)).trim(required);

export const emailNotRequiredValidation = ({ email = 'メール形式が正しくありません。' }) =>
  Yup.string().email(email).max(100, maxMsg(100)).matches(MailRegExp, AlphabetMsg);

export const ecEmailNotRequiredValidation = () =>
  Yup.string().test('emailNotCorrect', 'メール形式が正しくありません。', (value: any) =>
    value ? !!value.match(MailRegExp) : true,
  );

export const regexNumberPositive = /^(?:\d+)?$/g;

export const regexOfficialWebsite =
  /(http(s)?:\/\/.)|(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

export const numberPositiveValidation = ({
  number = '数字形式が正しくありません。',
  required = '入力してください。',
}) => Yup.string().required(required).max(100, maxMsg(100)).matches(regexNumberPositive, number);

export const regexLinkURL = /(https?:\/\/[^\s]+)/g;
export const URLNotRequiredValidation = ({ URL = 'URL形式が正しくありません。' }) =>
  Yup.string().matches(regexLinkURL, URL);

export const requiredValidation = ({ required = '入力してください。' }) =>
  Yup.string().required(required);

export const stringNotRequiredValidation = () => Yup.string().max(100, maxMsg(100));

export const postCodeValidationNoRequired = () =>
  Yup.string().max(100, maxMsg(100)).matches(REGEX_POST_CODE, '郵便番号の形式が正しくありません。');

export const katakanaFullSizeValidationInBankAccountOwner = ({ required = '入力してください。' }) =>
  Yup.string()
    .required(required)
    .matches(katakanaFullSizeRegBankAccountOwner, '全角カタカナで入力してください。');

export const katakanaFullSizeValidation = ({ required = '入力してください。' }) =>
  Yup.string().required(required).matches(katakanaFullSizeReg, '全角カタカナで入力してください。');

export const katakanaFullSizeValidationNotRequired = () =>
  Yup.string().trim().matches(katakanaFullSizeReg, '全角カタカナで入力してください。');

export const katakanaFullSizeSlashesNotRequired = () =>
  Yup.string().trim().matches(katakanaFullSizeSlashesReg, {
    message: SuggestMsg,
    excludeEmptyString: true,
  });

export const kanjiSlashesNotRequired = () =>
  Yup.string().trim().matches(regexKanjiSlashes, {
    message: SuggestMsg,
    excludeEmptyString: true,
  });

export const halfWidthValidation = () =>
  Yup.string()
    .trim()
    .required('入力してください。')
    .matches(regexHalfWidth, '半角英数字で入力してください');

export const englishValidation = ({ required = '入力してください。' }) =>
  Yup.string().trim().required(required).matches(regexEnglish, '半角英数字で入力してください');

export const englishValidationMaxLength = ({
  required = '入力してください。',
  maxLength,
}: {
  required: string;
  maxLength: number;
}) =>
  Yup.string()
    .trim()
    .required(required)
    .matches(regexEnglish, '半角英数字で入力してください')
    .max(maxLength, `${maxLength}文字を超えて入力しないでください`);

export const otherJasracCodeEnglishValidation = () =>
  Yup.string().trim().matches(regexEnglish, '半角英数字で入力してください');

export const englishValidationNotRequired = () =>
  Yup.string().trim().matches(regexEnglish, '半角英数字で入力してください');

export const englishSlashesValidationNotRequired = () =>
  Yup.string().trim().matches(regexEnglishSlashes, {
    message: SuggestMsg,
    excludeEmptyString: true,
  });

export const isrcCodeValidation = () =>
  Yup.string().trim().matches(regexIsrc, '半角英数字で入力してください');

export const objectRequiredValidation = ({ required = '入力してください。' }) =>
  Yup.object().required(required);

export const arrayRequiredValidation = ({ required = '入力してください。' }) =>
  Yup.array().required(required);

export const passwordMinimumEnglishCharOnly = ({
  required = '新しいパスワードを入力してください。',
  match = '新しいパスワードは半角英数混合8文字で入力してください。',
  matchSpecialCharacter = '半角英数字で入力してください。',
}) =>
  Yup.string()
    .required(required)
    .matches(ENGLISH_CHAR_ONLY, matchSpecialCharacter)
    .matches(PWD_MINIMUM_8, match);

export interface SectionItem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export function validateEmailsStringWithSemicolons(emails: any) {
  const emailList = emails?.split(/;|；/);
  for (const email of emailList) {
    if (!email.trim().match(MailRegExp)) {
      return false;
    }
  }
  return true;
}

export const sectionRequiredName = (
  { required = '入力してください。' },
  data: Array<SectionItem>,
) =>
  Yup.string()
    .required(required)
    .trim(required)
    .test(
      '',
      'セクション名が既に存在しています。別のセクション名で登録してください。',
      (values) => {
        if (_.isEmpty(data)) {
          return true;
        } else {
          return !data.some((e: SectionItem) => e.name === values);
        }
      },
    );

export const sectionRequiredNameUpdate = (
  { required = '入力してください。' },
  data: Array<SectionItem>,
) =>
  Yup.string()
    .required(required)
    .trim(required)
    .test('', 'セクション名が既に存在しています。別のセクション名で登録してください。', (value) => {
      const duValue = _.filter(data, (sections) => sections.name === value);
      return duValue.length <= 1;
    });

const songTimeReg = /^(\d\d):([0-5]\d):([0-5]\d)$/;

export const songTimeValidation = ({
  required = '入力してください。',
  invalidFormatTime = 'HH:MM:SSの形式で入力してください。',
}) => Yup.string().required(required).matches(songTimeReg, invalidFormatTime);

export const numberOfCharacters = ({
  required = '入力してください。',
  msg = '半角数字7桁で入力してください。',
  number = 7,
}) => stringRequired({ required }).min(number, msg).max(number, msg).matches(numberRegExp, msg);

export const englishCharacterOnlyValidation = ({
  required = '入力してください。',
  msg = '半角英数字で入力してください。',
}) => stringRequired({ required }).matches(ENGLISH_CHAR_ONLY, msg);

export const englishAndDashValidation = ({
  required = '入力してください。',
  msg = '半角英数字で入力してください。',
}) => stringRequired({ required }).matches(ENGLISH_AND_DASH, msg);

export const jasracCodeValidation = () =>
  Yup.string().trim().matches(JASRAC_CODE, '半角数字８桁で入力してください。');

export const otherJasracCodeValidation = () =>
  Yup.string().trim().matches(OTHER_JASRAC_CODE, '半角数字８桁で入力してください。');

export const englishAndDashValidationNotRequired = ({ msg = '半角英数字で入力してください。' }) =>
  Yup.string().matches(ENGLISH_AND_DASH, msg);

// eslint-disable-next-line @typescript-eslint/ban-types
export const fillValueObject = (o1: Object, o2: Object, value: any) => {
  const objectMerge = { ...o2 };
  Object.keys(o1).forEach((i: string) => {
    if (objectMerge[i as keyof typeof o2] === undefined) {
      objectMerge[i as keyof typeof o2] = value;
    }
  });
  return pick(objectMerge, Object.keys(o1));
};

export const urlOnlyDomainValidationNotRequired = ({
  msg = '入力されたドメインリンクが正しくありませんので、再度確認してください。',
}) => Yup.string().matches(ONLY_DOMAIN_URL, msg);
