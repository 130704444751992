import React, { useState, useEffect } from 'react';
import useMessage from 'Hooks/useMessage';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { CampaignApis } from 'Datasource/Campaign';
import useCampaign from 'Hooks/useCampaign';
import StepConfirmView from 'Components/Page/Campaign/RegisterCampaign/Step5/StepConfirmView';

interface Props {
  campaignId: string;
}
export default function CampaignDetail({ campaignId }: Props): JSX.Element {
  const { openMessageError } = useMessage();
  const [campaign, setCampaign] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const { handleDecodeMatrixPrizes } = useCampaign();

  useEffect(() => {
    getCampaign();
  }, []);

  const getCampaign = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await CampaignApis.getCampaign({ id: campaignId });
      const overview = {
        artistId: data.artistId,
        artistName: data.artistName,
        titleId: data.titleId,
        titleName: data.titleName,
        name: data.name,
        startDate: data.startDate,
        endDate: data.endDate,
        description: data.description,
        dataSize: +data.dataSize ?? 0,
        status: data.status,
      };
      const courses = handleDecodeMatrixPrizes(data?.courses);
      setCampaign({ overview, courses });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4">
      <SpinnerComponent isLoading={loading} />
      <StepConfirmView overview={campaign?.overview} courses={campaign?.courses} />
    </div>
  );
}
