import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import FormGroupTextArea from 'Components/Common/Form/FormGroupTextArea';
import CheckBoxs from 'Components/Common/Form/GroupCheckBox/CheckBoxs';
import MessageError from 'Components/Common/MessageError';
import { ONLY_TEXT_ALPHABET } from 'Constant';
import { NotificationOptions } from 'Constant/Campaign';
import { DATE_FORMAT_FOR_DATE_PICKER_2, TIME_FORMAT } from 'Constant/Date';
import dayjs from 'dayjs';
import useCampaign from 'Hooks/useCampaign';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { isFutureTime, normalizeDate, replaceGMTToUTC, replaceTimeOfDate } from 'Utils/DateTime';
import NumberFormat from 'react-number-format';
import { useAppSelector } from 'App/Store';
import { methodOptions } from 'Constant/Campaign';
import BRFormGroupDateRange from 'Components/Common/Form/FormGroupDateRange/BRFormGroupDateRange';
import BRFormGroupTime from 'Components/Common/Form/FormGroupDate/BRFormGroupTime';
import { setMinutes, setHours } from 'date-fns';
import TimeSelector from 'Components/Common/DateSelector/TimeSelector';

const AdmissionTicket = ({ courseIndex }: { courseIndex: number }): JSX.Element => {
  const { handleDisableDataCampaign } = useCampaign();
  const { courses } = useAppSelector((state) => state.campaign);
  const MIN_TIME = setHours(setMinutes(new Date(), 0), 0);

  const isDisabled = () => {
    return courses[courseIndex].type !== methodOptions[0].value;
  };

  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const startDateSameToday = () => {
    return dayjs(watch('type1.startDate')).isSame(new Date(), 'date');
  };

  // const minStartTime = () => {
  //   let time: any = MIN_TIME;
  //   if (watch('type1.openTime')) {
  //     console.log(normalizeDate(watch('type1.openTime')));

  //     time = normalizeDate(watch('type1.openTime'));
  //   }
  //   if (startDateSameToday() && !watch('type1.openTime')) {
  //     time = new Date();
  //   }
  //   return time;
  // };

  const minOpenTime = () => {
    if (startDateSameToday()) {
      return new Date();
    }
    return MIN_TIME;
  };

  const handleChangeStartDate = (date: any) => {
    const dateSameToDay = dayjs(date).isSame(new Date(), 'date');
    setValue('type1.startDate', date);
    if (!watch('type1.startDate')) {
      setValue('type1.endDate', null);
    }

    const openTimeSelected = replaceTimeOfDate(date, watch('type1.openTime'));
    const startTimeSelected = replaceTimeOfDate(date, watch('type1.startTime'));
    const notiDateSelected = replaceTimeOfDate(date, watch('type1.notificationDate'));
    if (dateSameToDay) {
      if (!isFutureTime(openTimeSelected)) {
        setValue('type1.openTime', null);
      }
      if (!isFutureTime(startTimeSelected)) {
        setValue('type1.startTime', null);
      }
      if (!isFutureTime(notiDateSelected)) {
        setValue('type1.notificationDate', null);
      }
    }
    if (watch('type1.notificationDate')) {
      setValue('type1.notificationDate', notiDateSelected);
    }

    if (watch('type1.openTime')) {
      setValue('type1.openTime', openTimeSelected);
    }

    if (watch('type1.startTime')) {
      setValue('type1.startTime', startTimeSelected);
    }
  };

  return (
    <Row>
      <div className="px-2">
        <hr />
        <Col className="mx-5">
          <FormGroupInput
            label="表示名称"
            labelMd="2"
            colMd="10"
            value={watch('type1.eventName')}
            register={register('type1.eventName')}
            onChange={(value) => setValue('type1.eventName', value)}
            classForm="form-with-label-nowrap"
            errorMessage={errors.type1?.eventName?.message}
            hintText="当選したユーザーのアプリに表示される名称を設定します。"
          />

          <FormGroupInput
            label="会場名"
            labelMd="2"
            colMd="10"
            value={watch('type1.placeName')}
            register={register('type1.placeName')}
            onChange={(value) => setValue('type1.placeName', value)}
            classForm="form-with-label-nowrap"
            errorMessage={errors.type1?.placeName?.message}
          />
          <BRFormGroupDateRange
            label="日付"
            labelMd="2"
            colMd="6"
            classForm="px-0"
            placeholderStart="開始日（yyyy/mm/dd)"
            placeholderEnd="終了日（yyyy/mm/dd)"
            dateFormat={DATE_FORMAT_FOR_DATE_PICKER_2}
            startDate={normalizeDate(watch('type1.startDate'))}
            endDate={normalizeDate(watch('type1.endDate'))}
            onChangeStartDate={(date: any) => handleChangeStartDate(date)}
            onChangeEndDate={(date: any) => setValue('type1.endDate', replaceGMTToUTC(date))}
            errorStartDate={errors.type1?.startDate?.message}
            errorEndDate={errors.type1?.endDate?.message}
            endDateDisabled={!watch('type1.startDate')}
            maxStartDate={normalizeDate(watch('type1.endDate'))}
          />
          <BRFormGroupTime
            label="開場"
            labelMd="2"
            colMd="4"
            currentDate={
              watch('type1.startTime') ? normalizeDate(watch('type1.startTime')) : new Date()
            }
            selected={normalizeDate(watch('type1.openTime'))}
            onChange={(date: any) => {
              if (watch('type1.startDate')) {
                const validDate = replaceTimeOfDate(watch('type1.startDate'), date);
                setValue('type1.openTime', validDate);
              } else {
                setValue('type1.openTime', date);
              }

              if (watch('type1.notificationDate')) {
                const validDate = replaceTimeOfDate(
                  watch('type1.openTime'),
                  watch('type1.notificationDate'),
                );
                setValue('type1.notificationDate', dayjs(validDate).toISOString());
              }
            }}
            placeholder="時刻を選択　(hh:mm)"
            dateFormat={TIME_FORMAT}
            errorMessage={errors.type1?.openTime?.message}
            minDate={minOpenTime()}
            maxDate={normalizeDate(watch('type1.startTime'))}
          />
          <BRFormGroupTime
            label="開演"
            labelMd="2"
            colMd="4"
            currentDate={
              watch('type1.openTime') ? normalizeDate(watch('type1.openTime')) : new Date()
            }
            selected={normalizeDate(watch('type1.startTime'))}
            onChange={(date: any) => {
              if (watch('type1.startDate')) {
                const validDate = replaceTimeOfDate(watch('type1.startDate'), date);
                setValue('type1.startTime', validDate);
              } else {
                setValue('type1.startTime', date);
              }
            }}
            placeholder="時刻を選択　(hh:mm)"
            dateFormat={TIME_FORMAT}
            errorMessage={errors.type1?.startTime?.message}
            minDate={watch('type1.openTime') ?? MIN_TIME}
          />

          <Row className="p-0 mb-3">
            <Col md="2">整理番号</Col>
            <Col md="10">
              <div className="d-flex">
                <Form.Check
                  className="check-box-ct mb-0"
                  type="radio"
                  inline
                  name="seatSettings"
                  id="seat-setting-1"
                  label="なし"
                  checked={watch('type1.seatPickerType') === 0}
                  onChange={() => {
                    setValue('type1.seatPickerType', 0);
                    setValue('type1.seatRow', null);
                    setValue('type1.seatNumber', null);
                  }}
                  disabled={handleDisableDataCampaign(watch('type1.id'))}
                />
                <div>
                  <div className="mb-3">
                    <Form.Check
                      className="check-box-ct mb-0"
                      type="radio"
                      inline
                      name="seatSettings"
                      id="seat-setting-2"
                      label="先着順"
                      checked={watch('type1.seatPickerType') === 1}
                      onChange={() => setValue('type1.seatPickerType', 1)}
                      disabled={isDisabled() || handleDisableDataCampaign(watch('type1.id'))}
                    />
                    <Form.Check
                      className="check-box-ct mb-0"
                      type="radio"
                      inline
                      name="seatSettings"
                      id="seat-setting-3"
                      label="ランダム発行"
                      disabled={handleDisableDataCampaign(watch('type1.id')) || !watch('isLimited')}
                      checked={watch('type1.seatPickerType') === 2}
                      onChange={() => setValue('type1.seatPickerType', 2)}
                    />
                  </div>
                  {watch('type1.seatPickerType') ? (
                    <>
                      <div className="d-flex align-items-center seat-setting">
                        <div className="me-3">発行初番</div>
                        <div>
                          <Form.Control
                            {...register('type1.seatRow')}
                            value={watch('type1.seatRow')}
                            onChange={(e) => {
                              if (e.target.value === '' || ONLY_TEXT_ALPHABET.test(e.target.value))
                                setValue('type1.seatRow', e.target.value);
                            }}
                            type="text"
                            maxLength={2}
                            className="w-90px me-3"
                            autoComplete="off"
                            disabled={handleDisableDataCampaign(watch('type1.id'))}
                          />
                        </div>
                        <div>
                          <NumberFormat
                            value={watch('type1.seatNumber')}
                            onChange={(e) => setValue('type1.seatNumber', e.target.value)}
                            className={`form-control rounded position-relative w-90px`}
                            allowLeadingZeros={true}
                            allowNegative={false}
                          />
                        </div>
                      </div>
                      <div>
                        <MessageError
                          message={
                            errors.type1?.seatRow?.message ?? errors.type1?.seatNumber?.message
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="p-0">
            <Col className="p-0" md={6}>
              <CheckBoxs
                id="eventDateAnnouncementTime"
                labelForm="当日通知時刻"
                name="eventDateAnnouncementTime"
                labelMd="4"
                colMd="8"
                classForm="form-with-label-nowrap mb-3"
                classOption="pe-4"
                options={NotificationOptions}
                value={watch(`type1.isSendNoti`)}
                onChange={({ value }) => {
                  setValue('type1.isSendNoti', value);

                  if (value === 0) {
                    setValue('type1.notificationDate', null);
                  }
                }}
              />
            </Col>
            <Col md={3}>
              <TimeSelector
                currentDate={
                  watch('type1.startDate') ? normalizeDate(watch('type1.startDate')) : new Date()
                }
                selected={normalizeDate(watch('type1.notificationDate'))}
                onChange={(date: any) => {
                  if (watch('type1.openTime')) {
                    const validDate = replaceTimeOfDate(watch('type1.openTime'), date);
                    setValue('type1.notificationDate', validDate);
                  } else {
                    setValue('type1.notificationDate', date);
                  }
                }}
                placeholder="時刻を選択　(hh:mm)"
                dateFormat={TIME_FORMAT}
                errorMessage={errors.type1?.notificationDate?.message}
                minDate={minOpenTime()}
                disabled={watch(`type1.isSendNoti`) === 0}
              />
            </Col>
          </Row>
          <FormGroupTextArea
            labelMd="2"
            colMd="6"
            rows={4}
            label="ユーザー表示用・備考注意事項"
            register={register('type1.description')}
            classInput="mt-2"
            value={watch('type1.description')}
            onChange={(value) => setValue('type1.description', value)}
            errorMessage={errors.type1?.description?.message}
            onKeyPress={(e) => {
              e.stopPropagation();
            }}
          />
        </Col>
      </div>
    </Row>
  );
};
export default AdmissionTicket;
