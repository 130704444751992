import React, { useState, useEffect } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { find } from 'lodash';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import { TabProps } from 'Components/Common/Tabs/tabs-types';
interface BtnOptionType {
  name: string;
  value?: number | string;
}

export default function Tabs({
  tabActive,
  groupClass,
  btnClass,
  options,
  onChange,
  redirect,
  disabledSetQuery,
  disabledSetPage,
  indexDefault,
  clickOnly = false,
}: TabProps): JSX.Element {
  const history = useHistory();
  const [query, setQuery] = useQueryParams({
    status: StringParam,
    page: NumberParam,
  });
  const [active, setActive] = useState(tabActive);
  const activeClass = 'bg-dark text-white pe-none opacity-100';

  const handleSetQueryParam = (option: BtnOptionType) => {
    if (disabledSetQuery) return;
    let newQuery = {
      status: option.name,
    } as any;
    if (!disabledSetPage) {
      newQuery = { ...newQuery, page: 1 };
    }
    setQuery(newQuery);
  };

  const onchangeTab = (tab: string) => {
    setActive(tab);
    if (onChange) onChange(tab);
    if (redirect) history.push(redirect);
  };

  useEffect(() => {
    if (tabActive && !clickOnly) onchangeTab(tabActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabActive, clickOnly]);

  useEffect(() => {
    if (!clickOnly) {
      const status = query.status ?? '';
      let option = find(options, (option) => option.name === status);
      if (!option) {
        option = options[indexDefault || 0];
      }
      onchangeTab(option?.name ?? '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.status, indexDefault, clickOnly]);

  return (
    <>
      <ButtonGroup className={groupClass} aria-label="basic-tab">
        {options.map((option, index) => (
          <Button
            key={index}
            variant="light"
            className={`text-truncate border ${btnClass ?? ''} ${
              active === option.name ? activeClass : ''
            }`}
            onClick={() => {
              onchangeTab(option.name);
              handleSetQueryParam(option);
            }}
            disabled={option.disabled}
          >
            {option.name}
          </Button>
        ))}
      </ButtonGroup>
    </>
  );
}
