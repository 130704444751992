import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

interface Options {
  yearMonth: string;
  disabled?: boolean;
}

export default function DropdownMenuEC({
  activeOption,
  onChange,
  options,
  classButton,
  setQuery,
}: {
  activeOption: string;
  onChange?: (event: string) => void;
  options: Options[];
  classButton?: string;
  setQuery: any;
}): JSX.Element {
  const [active, setActive] = useState(activeOption);

  const onChangeOption = (option: string) => {
    setActive(option);
    setQuery({ yearMonth: option });
    if (onChange) onChange(option);
  };

  useEffect(() => {
    if (activeOption) onChangeOption(activeOption);
  }, [active]);

  return (
    <>
      <DropdownButton
        id="dropdown-purchase-summary"
        className={`${classButton}`}
        title={activeOption}
      >
        {options.map((option, index) => {
          return (
            <Dropdown.Item key={index} onClick={() => onChangeOption(option.yearMonth)}>
              {option.yearMonth}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
    </>
  );
}
