import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Filter from 'Components/Common/Filter/Filter';
import BoxForm from 'Components/Common/Form/BoxForm';
import TableCommonProducts from 'Components/Page/ListProducts/TableCommonProducts';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ProductsApi } from 'Datasource/ProductsApi';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import dayjs from 'dayjs';
import { DATE_FORMAT_1, DATE_FORMAT_10 } from 'Constant/Date';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { arrayRequiredValidation, stringRequiredTrim } from 'Utils/Validation';
import useMessage from 'Hooks/useMessage';
import CalendarSwipper from 'Components/Page/Campaign/RegisterCampaign/Common/Swipper';
import { TABLE_PRODUCTS_STATUS } from 'Constant';
import MessageError from 'Components/Common/MessageError';
import { COLOR_DEAFAULT, defaultDates } from 'Constant/Campaign';
import { countDays } from 'Utils/DateTime';
import { reduce, isEmpty } from 'lodash';
import { DateType } from 'Types/Page/Campaign/RegisterCampaign';
import { handleGetMonths } from 'Utils/Campaign';
import { handleSearchSaleChannels } from 'Utils/Search';
// import { useAppSelector } from 'App/Store';
import { NFTSApi } from 'Datasource/NftsApi';
import { ProductItem } from 'App/Features/Product';

const schema = yup.object().shape({
  name: stringRequiredTrim({}).nullable(),
  contactName: stringRequiredTrim({}).nullable(),
  email: stringRequiredTrim({}).nullable(),
  channels: arrayRequiredValidation({}).min(1, '入力してください。'),
  campaignProducts: arrayRequiredValidation({}).min(1, '入力してください。'),
});

interface CampaignEvents {
  dates: DateType[];
  events: any[];
}

export default function CreateProducts(): JSX.Element {
  const { id }: { id: string } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAuthor, setIsAuthor] = useState<boolean>(false);
  const [data, setData] = useState({
    listCampaigns: [],
    productChannels: [],
    titleName: '',
    artistName: '',
  });
  const [listChannels, setListChannels] = useState<any>([]);
  const [product, setProduct] = useState<any>([]);
  const [dataChoose, setDataChoose] = useState<any>([]);
  const [isAllowUpdate, setIsAllowUpdate] = useState<boolean>(true);
  const variationProductInfos = dataChoose.flatMap((a: any) => a?.variationProductInfos || []);
  const { openMessageError } = useMessage();
  const history = useHistory();
  const location = useLocation();
  const [productCurrent, setProductCurrent] = useState<ProductItem | null>(null);
  const [productId, setProductId] = useState<string | null>(null);
  const searchParams = new URLSearchParams(location.search);
  const productIdFromUrl = searchParams.get('productId') ?? null;
  useEffect(() => {
    setProductId(productIdFromUrl);
  }, [location, productIdFromUrl]);

  const { listCampaigns } = data;

  const dataListCampaigns = listCampaigns?.map((el: any, key: number) => {
    const convertProducts = el?.courseProductInfos?.map((productsInfo: any) => {
      return { ...productsInfo, color: COLOR_DEAFAULT[Number(key) % COLOR_DEAFAULT.length] };
    });
    return {
      ...el,
      color: COLOR_DEAFAULT[Number(key) % COLOR_DEAFAULT.length],
      courseProductInfos: convertProducts,
    };
  });

  const handleFetchCardDetail = async (prodId: string) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await NFTSApi.getCardDetail(prodId);
      setProductCurrent(data);
    } catch (error) {
      openMessageError(error);
      setIsAuthor(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!productId) return;
    handleFetchCardDetail(productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  useEffect(() => {
    if (!productCurrent) return;
    const { productChannel, name, info, contactName, email } = productCurrent;

    const campaignAndCards = info?.campaignAndCards ?? [];

    setProduct(productChannel);
    setValue('campaignProducts', campaignAndCards);
    setValue('channels', productChannel);
    setValue('name', name);
    setValue('contactName', contactName);
    setValue('email', email);

    // FIXME: handle update campaign select
    if (dataListCampaigns?.length > 0) {
      const listCampaign = campaignAndCards?.map((el: any) => {
        const idCampaign = el?.campaignInfo?.id;
        return dataListCampaigns.find((campaign: any) => campaign?.campaignId === idCampaign);
      });

      setDataChoose(listCampaign?.filter((campaign: any) => campaign) ?? []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCurrent, listCampaigns]);

  const {
    handleSubmit,
    setValue,
    watch,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleFetchSales = async () => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await handleSearchSaleChannels({
        artistId: '',
        titleId: '',
        page: 1,
        showAll: 1,
        querySale: '',
      });

      setListChannels(data?.items || []);
      if (!data?.items?.length) setIsAuthor(true);
    } catch (error) {
      openMessageError(error);
      setIsAuthor(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetProductInfo = async () => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await ProductsApi.getCampaignProducts({
        titleId: id,
        productId: productCurrent?.id || '',
      });
      setData(data);
      setIsAuthor(data?.listCampaigns[0]?.isAuthor === 0);
      if (data?.isAllowUpdate !== null) setIsAllowUpdate(data?.isAllowUpdate);
    } catch (error) {
      openMessageError(error);
      setIsAuthor(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetProductInfo();
    handleFetchSales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, productCurrent?.id]);

  const onHandleSelect = (item: any) => {
    const data = [...dataChoose];
    if (isSelectCampaign(item)) {
      data.push(item);
    } else {
      const idx = dataChoose.findIndex((i: any) => i?.campaignId == item?.campaignId);
      data.splice(idx, 1);
    }
    setDataChoose(data);
    setValue('campaignProducts', data);
  };

  const isSelectCampaign = useCallback(
    (item: any) => {
      const idx = dataChoose.findIndex((i: any) => i?.campaignId == item?.campaignId);
      return idx < 0;
    },
    [dataChoose],
  );

  const onChooseChannels = (item: any) => {
    const data = [...product];
    const idx = product.findIndex((i: any) => i.id == item.id);
    if (isSelectChannels(item)) {
      data.push(item);
    } else {
      data.splice(idx, 1);
    }
    setProduct(data);
    setValue('channels', data);
  };

  const isSelectChannels = (item: any) => {
    const idx = product.findIndex((i: any) => i.id == item.id);
    return idx < 0;
  };

  const onSubmit = async (dataSubmit: any) => {
    const campaignProducts: any = [];
    dataChoose.map((el: any) => {
      const { variationProductInfos, campaignId } = el;
      variationProductInfos?.map((variation: any) => {
        const paramProduct = {
          campaignId: campaignId,
          variationId: variation.variationId || null,
          CdId: variation.cdId || null,
        };
        campaignProducts.push(paramProduct);
      });
    });
    const channels = product.map((el: any) => {
      return el?.id;
    });
    const params = {
      titleId: +id,
      name: dataSubmit.name,
      contactName: dataSubmit.contactName,
      email: dataSubmit.email,
      channels: channels,
      campaignProducts: campaignProducts,
    };

    try {
      setIsLoading(true);
      if (productCurrent?.id) {
        await ProductsApi.updateCard({ ...params, productId: productCurrent?.id });
      } else {
        await ProductsApi.createCard(params);
      }
      history.push('/list-products');
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const dataEventsCalender = () => {
    const eventArr = reduce(
      dataChoose,
      (result, item, index) => {
        const dates: DateType[] = [
          ...result?.dates,
          new Date(item.startDate),
          new Date(item.endDate),
        ];
        const chooseIndex = listCampaigns.findIndex((el: any) => el.campaignId === item.campaignId);
        const events: any[] = [
          ...result?.events,
          {
            title: item.campaignName,
            id: `e-${index}`,
            start: new Date(item.startDate),
            end: new Date(item.endDate),
            backgroundColor: COLOR_DEAFAULT[Number(chooseIndex) % COLOR_DEAFAULT.length],
            borderColor: COLOR_DEAFAULT[Number(chooseIndex) % COLOR_DEAFAULT.length],
            description: item.description,
            allDay:
              dayjs(item.startDate).format(DATE_FORMAT_1) ===
              dayjs(item.endDate).format(DATE_FORMAT_1),
          },
        ];
        result = { dates, events };
        return result;
      },
      { dates: [], events: [] } as CampaignEvents,
    );
    return eventArr;
  };

  const isDisableChannel = (id: number) => {
    const listChannelIds = data.productChannels.map(
      (item: { id: number; name: string; email: string }) => item.id,
    );
    const listCampaigns: any[] = data?.listCampaigns || [];

    return listChannelIds.includes(id) || listCampaigns[0]?.isAuthor === 0;
  };

  const listCampaignMemo = useMemo(() => {
    return isAuthor || !isAllowUpdate
      ? dataListCampaigns.filter((item: any) => !isSelectCampaign(item))
      : dataListCampaigns;
  }, [isAllowUpdate, isAuthor, isSelectCampaign, dataListCampaigns]);

  return ((productIdFromUrl && productCurrent) || (!productIdFromUrl && !productCurrent)) &&
    !isLoading ? (
    <>
      <Filter filterClass="d-flex align-items-center shadow-sm">
        <span className="fw-bold fs-20 me-4 text-truncate mw-35">{data?.titleName}</span>
        <span className="fw-bold fs-12 text-truncate ">{data?.artistName}</span>
      </Filter>
      <Container fluid className="p-3">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <SpinnerComponent isLoading={isLoading} />
          <BoxForm title="複数のキャンペーンを組み合わせて販売商品を作成します">
            <>
              <p className="fw-bold fs-16">販売チャネル</p>
              <hr className="m-0" />
              <Row className="mt-3">
                {((productIdFromUrl && data.productChannels.length > 0) || !productIdFromUrl) &&
                  listChannels.map((item: any, index: number) => {
                    return (
                      <Col key={index} md="2" className="px-2">
                        <OverlayTrigger
                          key={index}
                          placement="top"
                          overlay={
                            item?.channelName?.trim()?.length > 32 ? (
                              <Tooltip id={`tooltip-${index}`}>{item.channelName}</Tooltip>
                            ) : (
                              <></>
                            )
                          }
                        >
                          <Button
                            onClick={() => onChooseChannels(item)}
                            className={`w-100 h-50px mb-3 p-1 text-truncate ${
                              isSelectChannels(item) && 'bg-white text-dark custom-border'
                            }`}
                            disabled={isDisableChannel(item.id)}
                          >
                            {item.channelName}
                          </Button>
                        </OverlayTrigger>
                      </Col>
                    );
                  })}
              </Row>
              <p className="fw-bold fs-16 mt-4">組み合わせるキャンペーンを選択してください</p>
              <hr className="m-0 mb-1" />
              {dataListCampaigns.length === 0 && (
                <div className="mt-4">
                  <MessageError
                    classWrapper="align-items-center d-flex justify-content-center"
                    message="選択されたマスタタイトルに紐づくキャンペーンが存在しません。"
                  />
                  <div className="me-2 align-items-center d-flex justify-content-center">
                    <Button className="my-3" onClick={() => history.push('/register-campaign')}>
                      このマスタタイトルのキャンペーンを作成
                    </Button>
                  </div>
                </div>
              )}
              {listCampaignMemo.map((el: any, index: number) => {
                const { campaignName, variationProductInfos, endDate, startDate } = el;
                return (
                  <BoxForm
                    key={index}
                    colorBox={el.color}
                    title={campaignName}
                    classBox="mt-4 label-box-product"
                    extraText={`販売期間: ${dayjs(startDate).format(DATE_FORMAT_10)} ~
                    ${dayjs(endDate).format(DATE_FORMAT_10)}（終了まで
                    ${countDays(new Date(), endDate)}日）`}
                  >
                    <Row>
                      <Col>
                        <TableCommonProducts
                          className="tb-products-variation"
                          data={variationProductInfos}
                          type={TABLE_PRODUCTS_STATUS[0]}
                        />
                      </Col>
                      <Col>
                        <TableCommonProducts
                          className="tb-products-course"
                          data={el?.courseProductInfos}
                          type={TABLE_PRODUCTS_STATUS[1]}
                        />
                      </Col>
                      <Col md={1} className="align-items-center d-flex justify-content-center">
                        <Button
                          className={`h-45px w-90px custom-border ${
                            isSelectCampaign(el) && 'bg-white text-dark btn-primary'
                          } `}
                          onClick={() => onHandleSelect(el)}
                          disabled={isAuthor || !isAllowUpdate}
                        >
                          選択
                        </Button>
                      </Col>
                    </Row>
                  </BoxForm>
                );
              })}

              <hr className="my-4" />
              <BoxForm title="キャンペーン商品">
                <>
                  <FormGroupInput
                    value={watch('name')}
                    onChange={(e) => setValue('name', e)}
                    labelMd="2"
                    colMd="10"
                    label="管理名称*"
                    register={{ ...register('name') }}
                    errorMessage={errors.name?.message}
                    disabled={isAuthor}
                  />
                  <FormGroupInput
                    value={watch('contactName')}
                    onChange={(e) => setValue('contactName', e)}
                    labelMd="2"
                    colMd="10"
                    label="問合せ先名称*"
                    register={{ ...register('contactName') }}
                    errorMessage={errors.contactName?.message}
                    disabled={isAuthor}
                  />
                  <FormGroupInput
                    value={watch('email')}
                    onChange={(e) => setValue('email', e)}
                    labelMd="2"
                    colMd="10"
                    label="メールアドレス*"
                    register={{ ...register('email') }}
                    errorMessage={errors.email?.message}
                    disabled={isAuthor}
                  />
                  <Form.Group as={Row}>
                    <Form.Label column md={2}>
                      販売チャネル
                    </Form.Label>
                    <Col md={10} className="">
                      {product.map((field: any, index: number) => {
                        return (
                          <span className="pe-4" key={index}>
                            {field?.channelName}
                          </span>
                        );
                      })}
                      <MessageError message={errors?.channels?.message} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column md={2}>
                      対象キャンペーン
                    </Form.Label>
                    <Col md={10} className="">
                      <TableCommonProducts
                        className="table-target-campaign"
                        data={dataChoose}
                        type={TABLE_PRODUCTS_STATUS[2]}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column md={2}>
                      対象商品
                    </Form.Label>
                    <Col md={10} className="">
                      <TableCommonProducts
                        className="table-applied"
                        data={variationProductInfos}
                        type={TABLE_PRODUCTS_STATUS[4]}
                      />
                      <MessageError message={errors?.campaignProducts?.message} />
                    </Col>
                  </Form.Group>

                  <Form.Group className="mt-2" as={Row}>
                    <Form.Label column md={12}>
                      販売スケジュール
                    </Form.Label>
                    <Col md={12} className="">
                      <hr />
                      <CalendarSwipper
                        campaignRelations={{
                          ...dataEventsCalender(),
                          dates: isEmpty(handleGetMonths(dataEventsCalender()))
                            ? defaultDates
                            : handleGetMonths(dataEventsCalender()),
                        }}
                      />
                    </Col>
                  </Form.Group>
                </>
              </BoxForm>
              <div className="d-flex align-items-center justify-content-center my-4">
                <Button disabled={isAuthor} type="submit">
                  この内容でキャンペーン商品を作成
                </Button>
              </div>
            </>
          </BoxForm>
        </Form>
      </Container>
    </>
  ) : (
    <></>
  );
}
