import DateSelector from 'Components/Common/DateSelector';
import Filter from 'Components/Common/Filter/Filter';
import FormGroupSelect from 'Components/Common/Form/FormGroupSelect';
import Search from 'Components/Common/Search/Search';
import { DATE_FORMAT_4, DATE_FORMAT_YMD } from 'Constant/Date';
import { NFTSApi } from 'Datasource/NftsApi';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { IDatePicker, IPullDownItem } from 'Types/Common';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { exportExcel } from 'Utils/File';
import { getUserGMT } from 'Utils/Params';
import { handleSearchArtist, ItemSearch, transformKey, updateQuery } from 'Utils/Search';

export default function NFTFilter(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState<Array<ItemSearch>>([]);
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    artistId: StringParam,
    artistIds: StringParam,
    type: StringParam,
    downloadDateStart: StringParam,
    downloadDateEnd: StringParam,
  });
  const [queryObj, setQueryObj] = useState({
    queryArtist: query.queryArtist,
    downloadDateStart: query.downloadDateStart,
    downloadDateEnd: query.downloadDateEnd,
    type: query.type,
  });

  const handleGetIds = (arrItem: any) => {
    const ids = arrItem.map((item: any) => item.value);
    return ids.join(',');
  };

  const handleEnter = useCallback(
    (type: string) => {
      const ids = res.map((item: any) => handleGetIds(item));

      if (type === 'queryArtist') {
        const artistIds = query[type] ? (ids[0] ? ids[0] : 'no-data') : '';
        setQuery({ artistIds: artistIds, page: 1 });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [res, query, loading],
  );

  const handleClickItem = useCallback(
    (id: string, type: string) => {
      if (type === 'queryArtist') {
        setQuery({ artistId: id, artistIds: id, page: 1 });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query],
  );

  const onChangeDate = (date: any, type: string) => {
    if (!date) {
      setQuery({ [type]: undefined, page: 1 });
      return;
    }
    setQuery({ [type]: dayjs(date).format(DATE_FORMAT_4), page: 1 });
  };

  const handleSearchAll = async ({ queryArtist = '' }) => {
    setLoading(true);
    const res = await handleSearchArtist({ queryArtist, page: 1 });
    const artists = res.data.data.map((artist: IPullDownItem) => ({
      label: artist.localName,
      value: artist.id,
    }));
    const dataSource = [artists];
    setRes(dataSource);
    setLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouceRequest = useCallback((value) => updateQuery(value, setQueryObj), []);

  useEffect(() => {
    debouceRequest({
      queryArtist: query.queryArtist,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.queryArtist]);

  useEffect(() => {
    setLoading(true);
    handleSearchAll(transformKey(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryObj]);

  useEffect(() => {
    let validQuery = { ...query } as any;
    if (!query.queryArtist) {
      validQuery = {
        artistIds: undefined,
        artistId: undefined,
        queryArtist: undefined,
      };

      setQuery(validQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.queryArtist]);

  return (
    <div>
      <Filter filterClass="shadow-sm">
        <Row>
          <Col md="5" className="px-0 d-flex">
            <Col md="8">
              <Col md="12" className="px-0 pe-1">
                <Search
                  label="アーティスト"
                  labelMd="4"
                  colMd="8"
                  placeholder="検索"
                  showIcon
                  classLabel="px-0"
                  rowClass="w-100"
                  colClass="px-0"
                  searchKey="queryArtist"
                  onEnter={() => handleEnter('queryArtist')}
                  onClickResult={handleClickItem}
                  options={res[0]}
                  loading={loading}
                />
              </Col>
            </Col>
          </Col>
          <Col md="7" className="px-0">
            <Row className="justify-content-end">
              <Col md="2" className="ps-0 pe-1">
                <FormGroupSelect
                  colMd="12"
                  rowClass="w-100"
                  colClass="px-0"
                  classLabel="px-0"
                  labelMd="12"
                  label=""
                  placeholder=""
                  defaultValue={{ label: '発売日', value: '発売日' }}
                  options={[{ label: '発売日', value: '発売日' }]}
                  onChange={() => []}
                />
              </Col>
              <Col md="3" className="ps-0 pe-1">
                <DateSelector
                  placeholder="開始日"
                  dateFormat="yyyy/MM/dd"
                  showTime={false}
                  maxDate={query.downloadDateEnd ? new Date(query.downloadDateEnd) : null}
                  onChange={(dateStart: IDatePicker) =>
                    onChangeDate(dateStart, 'downloadDateStart')
                  }
                  selected={query.downloadDateStart ? new Date(query.downloadDateStart) : null}
                />
              </Col>
              <Col md="3" className="ps-0 pe-1">
                <DateSelector
                  placeholder="終了日"
                  dateFormat="yyyy/MM/dd"
                  showTime={false}
                  minDate={query.downloadDateStart ? new Date(query.downloadDateStart) : null}
                  onChange={(dateEnd: IDatePicker) => onChangeDate(dateEnd, 'downloadDateEnd')}
                  selected={query.downloadDateEnd ? new Date(query.downloadDateEnd) : null}
                />
              </Col>
              <Button
                variant="primary"
                className="btn-excel btn-focus-none"
                onClick={() =>
                  exportExcel(
                    NFTSApi.exportExcel({
                      artistId: query.artistIds ?? '',
                      downloadDateStart: query.downloadDateStart
                        ? dayjs(query.downloadDateStart).toISOString()
                        : undefined,
                      downloadDateEnd: query.downloadDateEnd
                        ? dayjs(query.downloadDateEnd).endOf('d').toISOString()
                        : undefined,
                      gmt: getUserGMT(),
                    }),
                    `商品リスト_${dayjs().format(DATE_FORMAT_YMD).toString()}`,
                  )
                }
              >
                Excelダウンロード
              </Button>
            </Row>
          </Col>
        </Row>
      </Filter>
    </div>
  );
}
