import React, { useCallback, useEffect, useState } from 'react';
import { FormGroupSearchProps } from 'Components/Common/Form/form';
import FormGroupSearch from 'Components/Common/Form/FormGroupSearch';
import { SearchResponse } from 'Hooks/useSuggestion';
import useMessage from 'Hooks/useMessage';
import { PER_PAGE } from 'Constant';
import { TitleApi } from 'Datasource/TitleApi';
import { IPullDownItem } from 'Types/Common';
import { updateQuery } from 'Utils/Search';
export interface SelectTitleProps extends Omit<FormGroupSearchProps, 'onInputChange' | 'onSearch'> {
  artistID: number | string;
  onInput?: (keyword: string) => void;
}

export default function FormSelectTitle(props: SelectTitleProps): JSX.Element {
  const [page, setPage] = useState<number>(1);
  const [titles, setTitles] = useState<SearchResponse>({ options: [], totalPages: 0 });
  const [loadingTitle, setLoadingTitle] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const { openMessageError } = useMessage();

  const { onInput } = props;

  const handleSearchTitle = async () => {
    if (!props.artistID) return;
    try {
      setLoadingTitle(true);
      const {
        data: { data },
      } = await TitleApi.getAllTitleByArtistID({
        artistID: props.artistID,
        page: page,
        perPage: PER_PAGE,
        query: encodeURIComponent(searchKey),
      });

      const options = data?.items?.map((title: IPullDownItem) => ({
        label: title.titles_name || title.name,
        value: title.titles_id || title.id,
        earliestSaleDate: title.earliestSaleDate,
      }));
      setTitles({
        options: page === 1 ? options : [...titles.options, ...options],
        totalPages: data?.meta?.totalPages,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingTitle(false);
    }
  };

  const handleLoadmore = () => {
    if (page < titles.totalPages) {
      let p = page;
      p += 1;
      setPage(p);
    }
  };

  useEffect(() => {
    handleSearchTitle();
  }, [searchKey, page, props.artistID]);

  const debouceRequest = useCallback(
    (value: string) =>
      updateQuery(value, () => {
        setSearchKey(value);
        setPage(1);
        onInput && onInput(value);
      }),
    [onInput],
  );

  return (
    <FormGroupSearch
      onLoadMore={() => handleLoadmore()}
      onMenuClose={() => {
        setSearchKey('');
        setPage(1);
      }}
      isLoading={loadingTitle}
      options={titles.options || []}
      onInputChange={(keyword: string) => debouceRequest(keyword)}
      onMenuFocus={() => {
        setTitles({ options: [], totalPages: 0 });
        handleSearchTitle();
      }}
      showIconClear
      {...props}
    />
  );
}
