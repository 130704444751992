import CardWrapper from 'Components/Common/Card/Card';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import FormGroupTextArea from 'Components/Common/Form/FormGroupTextArea';
import React, { useEffect, useImperativeHandle, forwardRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CalendarSwipper from 'Components/Page/Campaign/RegisterCampaign/Common/Swipper';
import ActionButtons from 'Components/Page/Campaign/RegisterCampaign/Common/ActionButtons';
import { ActionType } from 'Constant';
import { useAppDispatch, useAppSelector } from 'App/Store';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SelectValue } from 'Types/Common';
import { updateStep1State, updateStep2State } from 'App/Features/Campaign';
import { CampaignApis } from 'Datasource/Campaign';
import _, { isEmpty, omit, filter } from 'lodash';
import {
  COLOR_DEAFAULT,
  CampaignStatus,
  defaultDates,
  TargetProduct,
  defaultConditions,
} from 'Constant/Campaign';
import { getMsgExceed100, handleGetMonths } from 'Utils/Campaign';
import useCampaign from 'Hooks/useCampaign';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { normalizeDate } from 'Utils/DateTime';
import { DateType, Variantion } from 'Types/Page/Campaign/RegisterCampaign';
import dayjs from 'dayjs';
import BRFormGroupDateRange from 'Components/Common/Form/FormGroupDateRange/BRFormGroupDateRange';
import { stringRequired } from 'Utils/Validation';
import FormSelectArtist from 'Components/Common/Select/FormSelectArtist';
import FormSelectTitle from 'Components/Common/Select/FormSelectTitle';
import { DATE_FORMAT_1 } from 'Constant/Date';
import useMessage from 'Hooks/useMessage';
import { useParams } from 'react-router-dom';
interface IFormValues {
  artistId: string;
  artistName: string;
  titleId: string;
  titleName: string;
  name: string;
  startDate: DateType;
  endDate: DateType;
  description: string;
  dataSize: number;
  status: number;
  isDeletable: boolean;
  earliestSaleDate: DateType;
  isAuthor: number;
}

interface CampaignEvents {
  dates: DateType[];
  events: any[];
}

export const overviewSchema = yup.object().shape({
  artistId: stringRequired({}).nullable(),
  titleId: stringRequired({}).nullable(),
  name: stringRequired({}).trim().nullable(),
  startDate: stringRequired({}).nullable(),
  endDate: stringRequired({}).nullable(),
});

const StepOverview = forwardRef((props: any, ref: any): JSX.Element => {
  const dispatch = useAppDispatch();
  const { id }: { id: string } = useParams();

  const { overview, step, courses, errorRealTime, oldTitleId } = useAppSelector(
    (state) => state.campaign,
  );
  const {
    handleChangeStep,
    saveOrUpdateDraft,
    isLoading,
    handleDisableDataCampaign,
    handleDeleteCampaign,
    handleSetOldTitleId,
  } = useCampaign();

  const { openMessage, openMessageError } = useMessage();

  const {
    register,
    getValues,
    trigger,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<IFormValues>({
    defaultValues: {
      artistId: '',
      artistName: '',
      titleId: '',
      titleName: '',
      name: '',
      startDate: null,
      endDate: null,
      description: '',
      dataSize: 0,
      status: 0,
      isDeletable: true,
      earliestSaleDate: null,
      isAuthor: 1,
    },
    resolver: yupResolver(overviewSchema),
  });

  const [campaignRelations, setCampaignRelations] = useState<CampaignEvents>({
    dates: [],
    events: [],
  });

  const handleSearchArtist = async (query: string) => {
    if (!query) {
      setValue('artistId', '');
      setValue('artistName', '');
      setValue('titleId', '');
      setValue('titleName', '');
    }
  };

  const handleOnchangeSearch = (
    item: { label: string; value: string; earliestSaleDate: Date | null },
    type: string,
  ) => {
    if (type === 'artist') {
      setValue('artistId', item.value);
      setValue('artistName', item.label);
      return;
    }
    if (type === 'title') {
      setValue('titleId', item.value);
      setValue('titleName', item.label);
      setValue('earliestSaleDate', item.earliestSaleDate);

      // save data in step 2 to store
      handleGetCourseInfo(item.value);
      return;
    }
  };

  const handleChangeDate = (date: DateType, type: string) => {
    setValue(`${type}` as any, date);
  };

  const handleNext = async (back?: boolean) => {
    const formData = getValues();
    dispatch(updateStep1State({ overview: formData }));
    handleChangeStep(back ? step - 1 : step + 1);
  };

  useImperativeHandle(ref, () => ({
    handleNextStepFromParent(back?: boolean) {
      handleNext(back);
    },
  }));

  useEffect(() => {
    reset({
      artistId: overview.artistId,
      artistName: overview.artistName,
      titleId: overview.titleId,
      titleName: overview.titleName,
      name: overview.name,
      startDate: overview.startDate,
      endDate: overview.endDate,
      description: overview.description,
      dataSize: overview.dataSize ?? 0,
      status: !id ? CampaignStatus.Draft : overview.status,
      isDeletable: overview?.isDeletable,
      earliestSaleDate: overview.earliestSaleDate,
      isAuthor: overview.isAuthor,
    });
  }, [reset, overview, id]);

  const handleGetCampaignRelations = async (id: string) => {
    const {
      data: { data },
    } = await CampaignApis.getCampaignRelation({ id });

    const eventArr = _.reduce(
      data,
      (result, value, key) => {
        const dates: DateType[] = [
          ...result?.dates,
          new Date(value.startDate),
          new Date(value.endDate),
        ];
        const events: any[] = [
          ...result?.events,
          {
            title: value?.name,
            id: `e-${key}`,
            start: new Date(value.startDate),
            end: new Date(value.endDate),
            backgroundColor: COLOR_DEAFAULT[Number(key) % COLOR_DEAFAULT.length],
            borderColor: COLOR_DEAFAULT[Number(key) % COLOR_DEAFAULT.length],
            description: value?.description,
            allDay:
              dayjs(value.startDate).format(DATE_FORMAT_1) ===
              dayjs(value.endDate).format(DATE_FORMAT_1),
          },
        ];
        result = { dates, events };
        return result;
      },
      { dates: [], events: [] } as CampaignEvents,
    );
    setCampaignRelations(eventArr);
  };

  const handleSaveDraft = async () => {
    if (errorRealTime?.prizeQuantityTooSmall) {
      openMessage({
        variant: 'error',
        message: getMsgExceed100(courses, errorRealTime?.courseName),
      });
      return;
    }

    if (errorRealTime?.exceed100) {
      openMessage({
        variant: 'error',
        message: getMsgExceed100(courses, errorRealTime?.courseName),
      });
      return;
    }
    saveOrUpdateDraft({
      overview: { ...getValues(), dataSize: watch('dataSize') },
      courses: courses,
      id: props?.campaignId,
      step: step,
    });
  };

  const handleTrigger = async () => {
    await trigger();
    props?.clearBackValidate();
  };

  /**
   * when change title, get course info in step 2 and save to store
   * Bug: MUSICNFTCARD-700
   * @param titleId
   */
  const handleGetCourseInfo = async (titleId: string | number) => {
    try {
      const {
        data: { data },
      } = await CampaignApis.getVariations({
        id: titleId as string,
        campaignId: props?.campaignId,
      });

      const standardizeCourses = courses.map((course: any) => {
        let courseInfos: any = [];

        data.map((variation: Variantion, vIndex: number) => {
          const tmpVariation = omit(variation, 'id');

          if (!isEmpty(course?.courseInfos)) {
            //* CourseInfo already exists
            if (overview.titleId === oldTitleId) {
              let infoExists: any = [];

              infoExists = filter(course?.courseInfos, (info) => {
                const sameType = info?.type === variation?.type;
                const sameId = info?.variationId === variation?.id || info?.cdId === variation?.id;
                return sameType && sameId;
              });

              const isExist = !isEmpty(infoExists);

              const infoV = isExist
                ? {
                    ...tmpVariation,
                    ...infoExists[0],
                    index: vIndex + 1,
                    cdId: variation.type === TargetProduct.CD && variation.id ? variation.id : null,
                    cdStatus: variation.status ? variation.status : 0,
                    variationId:
                      variation.type === TargetProduct.DP && variation.id ? variation.id : null,
                    enabled:
                      variation.status && !infoExists[0]?.enabled ? false : infoExists[0]?.enabled,
                    option1: infoExists[0]?.option1 ?? false,
                    option2: infoExists[0]?.option2 ?? false,
                    option3: infoExists[0]?.option3 ?? false,
                    option4: infoExists[0]?.option4 ?? false,
                  }
                : {
                    ...tmpVariation,
                    index: vIndex + 1,
                    cdId: variation.type === TargetProduct.CD && variation.id ? variation.id : null,
                    cdStatus: variation.status ? variation.status : 0,
                    variationId:
                      variation.type === TargetProduct.DP && variation.id ? variation.id : null,
                    ...defaultConditions,
                    enabled: variation.status || props?.campaignId ? 0 : 1,
                  };

              courseInfos = [...courseInfos, infoV];
            } else {
              //* New info
              courseInfos = [
                ...courseInfos,
                {
                  ...tmpVariation,
                  cdId: variation.type === TargetProduct.CD && variation.id ? variation.id : null,
                  variationId:
                    variation.type === TargetProduct.DP && variation.id ? variation.id : null,
                  index: vIndex + 1,
                  cdStatus: variation.status ? variation.status : 0,
                  ...defaultConditions,
                  enabled: variation.status ? 0 : 1,
                },
              ];
            }
          } else {
            //* There are no variations yet
            courseInfos = [
              ...courseInfos,
              {
                ...tmpVariation,
                cdId: variation.type === TargetProduct.CD && variation.id ? variation.id : null,
                variationId:
                  variation.type === TargetProduct.DP && variation.id ? variation.id : null,
                index: vIndex + 1,
                cdStatus: variation.status ? variation.status : 0,
                ...defaultConditions,
                enabled: variation.status ? 0 : 1,
              },
            ];
          }
        });

        return {
          ...course,
          courseInfos,
        };
      });

      // save step 1
      const formData = getValues();
      dispatch(updateStep1State({ overview: formData }));

      // save step 2
      dispatch(updateStep2State({ courses: standardizeCourses }));
    } catch (error) {
      openMessageError(error);
    }
  };

  useEffect(() => {
    if (watch('titleId')) {
      handleGetCampaignRelations(watch('titleId'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('titleId')]);

  useEffect(() => {
    if (props?.isValidate) {
      handleTrigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.isValidate]);

  const minDate = (): Date => {
    if (watch('earliestSaleDate') && dayjs(new Date()).isBefore(watch('earliestSaleDate'))) {
      return normalizeDate(watch('earliestSaleDate')) as Date;
    } else {
      return new Date();
    }
  };

  return (
    <>
      <SpinnerComponent isLoading={isLoading} />
      <CardWrapper title="キャンペーン概要">
        <Row className="mb-3">
          <Col md="2">対象商品</Col>
          <Col md="5">
            <FormSelectArtist
              colClass="px-0"
              inputSearchString={watch('artistName')}
              labelMd="12"
              colMd="12"
              onInput={handleSearchArtist}
              onSelect={(selectedItem: SelectValue) => {
                handleOnchangeSearch(selectedItem, 'artist');
                setValue('titleId', '');
                setValue('titleName', '');
              }}
              placeholder="アーティストを選択してください。"
              errorMessage={errors.artistId?.message}
              isReview={handleDisableDataCampaign(props?.campaignId)}
              showIconClear
              permission={props?.campaignId ? ActionType.EDIT : ActionType.CREATE}
            />
          </Col>
          <Col md="5">
            <FormSelectTitle
              colClass="px-0"
              inputSearchString={watch('titleName')}
              labelMd="12"
              colMd="12"
              isReview={!watch('artistId') || handleDisableDataCampaign(props?.campaignId)}
              onInput={(query) => {
                if (!query) {
                  setValue('titleId', '');
                  setValue('titleName', '');
                }
              }}
              onSelect={(selectedItem) => handleOnchangeSearch(selectedItem, 'title')}
              placeholder="タイトルを選択してください。"
              errorMessage={errors.titleId?.message}
              showIconClear
              artistID={watch('artistId')}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <CalendarSwipper
            campaignRelations={{
              ...campaignRelations,
              dates: isEmpty(handleGetMonths(campaignRelations))
                ? defaultDates
                : handleGetMonths(campaignRelations),
            }}
          />
        </Row>
        <FormGroupInput
          label="新規キャンペーン名称"
          labelMd="2"
          colMd="10"
          value={watch('name')}
          register={register('name')}
          onChange={(value) => setValue('name', value)}
          classForm="form-with-label-nowrap"
          errorMessage={errors?.name?.message}
        />
        <BRFormGroupDateRange
          label="販売期間"
          labelMd="2"
          colMd="7"
          placeholderStart="開始（yyyy/mm/dd/ hh:mm)"
          placeholderEnd="終了（yyyy/mm/dd/ hh:mm)"
          showTimeSelect
          startDate={normalizeDate(watch('startDate'))}
          endDate={normalizeDate(watch('endDate'))}
          onChangeStartDate={(date: any) => {
            handleChangeDate(date, 'startDate');
            if (!date) {
              handleChangeDate(null, 'endDate');
            }
          }}
          onChangeEndDate={(date: any) => {
            handleChangeDate(date, 'endDate');
          }}
          startDateDisabled={handleDisableDataCampaign(props?.campaignId)}
          endDateDisabled={handleDisableDataCampaign(props?.campaignId) || !watch('startDate')}
          minStartDate={minDate()}
          maxStartDate={normalizeDate(watch('endDate'))}
          errorStartDate={errors?.startDate?.message}
          errorEndDate={errors?.endDate?.message}
          relationEndDate={watch('startDate')}
        />
        <FormGroupTextArea
          labelMd="2"
          colMd="10"
          label="キャンペーン概要"
          rows={4}
          register={register('description')}
          value={watch('description')}
          onChange={(value) => setValue('description', value)}
        />
      </CardWrapper>

      <ActionButtons
        handleDelete={() => handleDeleteCampaign(props?.campaignId)}
        handleNext={() => {
          handleNext();
          handleSetOldTitleId();
        }}
        handleSaveDraft={async () => {
          const isValid = await trigger();
          if (isValid) {
            handleSaveDraft();
          }
        }}
        courses={courses}
      />
    </>
  );
});

export default StepOverview;
