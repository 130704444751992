import React from 'react';
import CardWrapper from 'Components/Common/Card/Card';
import { formatDate } from 'Utils/DateTime';
import {
  attachedBonus,
  awardSettings,
  CourseTypes,
  distributionSettingOptions,
  methodOptions,
  subTypeOptions,
} from 'Constant/Campaign';
import ViewOnlyField from 'Components/Page/Campaign/RegisterCampaign/Common/ViewOnlyField';
import { DATE_FORMAT_18 } from 'Constant/Date';
import { filter, find, size } from 'lodash';
import { Row } from 'react-bootstrap';
import { FIELD_SIZE } from 'Constant/Campaign';
import TableCampaignView from 'Components/Page/Campaign/RegisterCampaign/Step2/TableCampaignView';
import TableWinningConditions from 'Components/Page/Campaign/RegisterCampaign/Step4/TableWinningConditions';
import PrizesTable from 'Components/Page/Campaign/RegisterCampaign/Step5/PrizesTable';
import TableUserResult from 'Components/Page/Campaign/RegisterCampaign/Step4/TableUserResult';
import { CourseConditionsOnlyView } from 'Components/Page/Campaign/RegisterCampaign/Step2/ConditionRow';
import MessageError from 'Components/Common/MessageError';
import { CourseCampaign } from 'Types/Page/Campaign/RegisterCampaign';
import { formatCurrency } from 'Utils/Numbers';

const StepConfirmView = ({
  overview,
  courses,
  errors,
  overCapacityMessage,
}: {
  courses: any;
  overview: any;
  errors?: any;
  overCapacityMessage?: string;
}): JSX.Element => {
  const formSize = {
    labelSize: FIELD_SIZE.label,
    valueSize: FIELD_SIZE.value,
  };

  const handleRenderListMessage = (courseIndex: number) => {
    const messages = filter(errors?.courses?.[courseIndex]?.courseInfos, size);
    return messages.map((mess: any, messIndex: number) => {
      return <MessageError key={messIndex} classWrapper="me-0" message={mess?.enabled?.message} />;
    });
  };

  const renderCourseType = (course: CourseCampaign) => {
    const courseTypeName =
      find(methodOptions, (option) => option.value === course.type)?.label ?? '';
    const courseSubTypeName =
      find(subTypeOptions, (option) => option.value === course.subType)?.label ?? '';
    return course?.type === CourseTypes.REALTIME
      ? `${courseTypeName} - ${courseSubTypeName}`
      : courseTypeName;
  };

  return (
    <>
      <CardWrapper title="キャンペーン概要" className="mb-3">
        <ViewOnlyField
          {...formSize}
          label="対象選択"
          value={`${overview?.artistName ?? '-'}  ${overview?.titleName ?? '-'}`}
          isMessageInside
          errorMessage={errors?.artistId?.message || errors?.titleId?.message}
        />
        <ViewOnlyField
          {...formSize}
          label="新規キャンペーン名称"
          value={overview?.name}
          isMessageInside
          errorMessage={errors?.name?.message}
        />
        <ViewOnlyField
          {...formSize}
          label="販売期間"
          value={
            overview?.startDate &&
            `${formatDate(overview?.startDate, DATE_FORMAT_18)} ~ ${formatDate(
              overview?.endDate,
              DATE_FORMAT_18,
            )}`
          }
          isMessageInside
          errorMessage={errors?.startDate?.message || errors?.endDate?.message}
        />
        <ViewOnlyField
          {...formSize}
          label="キャンペーン概要"
          value={overview?.description}
          isMessageInside
          errorMessage={errors?.description?.message}
          prevLine
        />
      </CardWrapper>
      {courses?.map((course: CourseCampaign, courseIndex: number) => {
        return (
          <CardWrapper
            title={`応募コース設定内容 (${courseIndex + 1}/${courses.length})`}
            className="mb-3"
            key={courseIndex}
          >
            <div className="mb-5">
              <ViewOnlyField
                {...formSize}
                label="応募コース名称"
                value={course?.name || '-'}
                isMessageInside
                errorMessage={errors?.courses?.[courseIndex]?.name?.message}
              />
              <ViewOnlyField
                {...formSize}
                label="応募方法"
                value={
                  find(distributionSettingOptions, (option) => option.value === course.option)
                    ?.label ?? ''
                }
                isMessageInside
                errorMessage={errors?.courses?.[courseIndex]?.option?.message}
              />
              <ViewOnlyField
                {...formSize}
                label="抽選方法"
                value={renderCourseType(course)}
                isMessageInside
                errorMessage={errors?.courses?.[courseIndex]?.type?.message}
              />

              <ViewOnlyField
                {...formSize}
                label="抽選日時"
                value={formatDate(course?.standardTime, DATE_FORMAT_18, '-')}
                isMessageInside
                errorMessage={errors?.courses?.[courseIndex]?.standardTime?.message}
              />
              <ViewOnlyField
                {...formSize}
                label="応募期間"
                value={
                  (course?.timeJoinStart &&
                    `${formatDate(course?.timeJoinStart, DATE_FORMAT_18)} ~ ${formatDate(
                      course.timeJoinEnd,
                      DATE_FORMAT_18,
                    )}`) ??
                  '-'
                }
                isMessageInside
                errorMessage={
                  errors?.courses?.[courseIndex]?.timeJoinStart?.message ||
                  errors?.courses?.[courseIndex]?.timeJoinEnd?.message
                }
              />
              {course.type === CourseTypes.ALL && (
                <ViewOnlyField
                  {...formSize}
                  label="景品付与設定"
                  value={
                    find(attachedBonus, (option) => option.value === course.receiveType)?.label ??
                    ''
                  }
                  isMessageInside
                  errorMessage={errors?.courses?.[courseIndex]?.prizeOption?.message}
                />
              )}
              {course.type === CourseTypes.REALTIME && (
                <>
                  <ViewOnlyField
                    {...formSize}
                    label="初日の応募予想数"
                    value={course?.countJoinStart ?? '-'}
                    isMessageInside
                    errorMessage={errors?.courses?.[courseIndex]?.countJoinStart?.message}
                  />
                  <ViewOnlyField
                    {...formSize}
                    label="最終日の応募予想数"
                    value={course?.countJoinEnd ?? '-'}
                    isMessageInside
                    errorMessage={errors?.courses?.[courseIndex]?.countJoinEnd?.message}
                  />
                </>
              )}
              <ViewOnlyField
                {...formSize}
                label="賞の指定可否"
                value={
                  find(awardSettings, (option) => option.value === course.prizeOption)?.label ?? ''
                }
                isMessageInside
                errorMessage={errors?.courses?.[courseIndex]?.prizeOption?.message}
              />

              <ViewOnlyField
                {...formSize}
                label="応募一口金額"
                value={`${
                  course?.ticketPrice && course.option === distributionSettingOptions[1].value
                    ? `¥ ${formatCurrency(course?.ticketPrice)}`
                    : '-'
                }`}
                errorMessage={errors?.courses?.[courseIndex]?.ticketPrice?.message}
                isMessageInside
              />
              <ViewOnlyField
                {...formSize}
                label="販売数上限"
                value={`${
                  course.subType === subTypeOptions[1].value
                    ? '上限なし'
                    : course?.prizeQuantity && course.subType === subTypeOptions[0].value
                    ? course?.prizeQuantity
                    : '-'
                }`}
                errorMessage={
                  course.subType !== subTypeOptions[2].value &&
                  errors?.courses?.[courseIndex]?.prizeQuantity?.message
                }
                isMessageInside
              />
            </div>
            <div className="mb-3">
              <TableCampaignView
                dataSource={course?.courseInfos}
                nestIndex={courseIndex}
                isShowConditions={course?.option === distributionSettingOptions[2].value}
                titleName={overview?.titleName}
              />
              <MessageError
                classWrapper="me-0"
                message={errors?.courses?.[courseIndex]?.courseInfos?.message}
              />
              {handleRenderListMessage(courseIndex)}
            </div>
            {course?.option === distributionSettingOptions[2].value && (
              <Row className="mb-3">
                <div>応募権利取得条件</div>
                <div className="mt-2 ps-3 fs-12">
                  <p>条件1～条件4をすべて満たしている必要があります。</p>
                  <CourseConditionsOnlyView info={course?.courseInfos} />
                </div>
              </Row>
            )}
            <div className="mb-3">
              <PrizesTable
                dataSource={course?.prizes}
                titleName={overview?.titleName}
                overCapacityMessage={overCapacityMessage}
                errors={errors?.courses?.[courseIndex]?.prizes}
                showWinRate={course?.type === 2 && [0, 1].includes(course?.subType)}
              />
              <MessageError
                classWrapper="me-0"
                message={errors?.courses?.[courseIndex]?.prizes?.message}
              />
            </div>
            <TableWinningConditions prizes={course.prizes} courseIndex={courseIndex} isReview />
            <CardWrapper title="ブラックリスト" className="card-wrapper-gray card-blacklist px-0">
              <div>
                <TableUserResult dataSource={course?.blackListUsers ?? []} viewOnly={true} />
                <MessageError
                  classWrapper="me-0"
                  message={errors?.courses?.[courseIndex]?.blackListUsers?.message}
                />
              </div>
            </CardWrapper>
          </CardWrapper>
        );
      })}
    </>
  );
};

export default StepConfirmView;
