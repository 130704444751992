import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { login } from 'App/Features/Auth';
import { useAppDispatch } from 'App/Store';
import { ERROR } from 'Constant/Error';
import _ from 'lodash';
import { Button, FormControl, FormGroup, FormLabel, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { emailValidation } from 'Utils/Validation';
import * as yup from 'yup';
import { AuthApi } from 'Datasource/Auth';
import MessageError from 'Components/Common/MessageError';
import useMessage from 'Hooks/useMessage';
import { RolesRecord } from 'Constant/Roles';
import { ACCOUNT_STATUS_ACTIVE } from 'Constant';
import { resetRevenueFilters } from 'App/Features/Revenue';
interface ILoginForm {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: emailValidation({
    required: ERROR['no fill out of email field'],
    email: ERROR['valid email address'],
  }),
  password: yup.string().required('入力してください。'),
});

export default function LoginForm(): JSX.Element {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { openMessageError, openMessage } = useMessage();
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<ILoginForm>({ mode: 'onSubmit', resolver: yupResolver(schema) });

  const onSubmit = async (params: ILoginForm) => {
    if (!_.isEmpty(errors)) return;
    try {
      const {
        data: { data },
      } = await AuthApi.loginApi(params);
      if (_.includes(RolesRecord, data.role)) {
        dispatch(login(data));
        dispatch(resetRevenueFilters());
        history.push(data.status === ACCOUNT_STATUS_ACTIVE ? '/' : '/change-password');
      } else {
        openMessage({
          message: 'ログインメールアドレスまたはパスワードが一致しません。',
          variant: 'error',
        });
      }
    } catch (error) {
      openMessageError(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="public-card-width">
      <FormGroup className="mb-3">
        <FormLabel>ログインメールアドレス</FormLabel>
        <FormControl
          {...register('email')}
          disabled={isSubmitting}
          type="text"
          aria-label="email"
          aria-describedby="input--email"
        />
      </FormGroup>

      <FormGroup className="mb-3">
        <FormLabel>パスワード</FormLabel>
        <FormControl
          {...register('password')}
          disabled={isSubmitting}
          type="password"
          aria-label="password"
          aria-describedby="input--password"
          value={watch('password')}
          onChange={({ target: { value } }) => setValue('password', value)}
        />
      </FormGroup>

      <FormGroup className="d-flex justify-content-end">
        <Link to="/forgot-password" className="white always:color-white">
          パスワードを忘れた方はこちら
        </Link>
      </FormGroup>
      <FormGroup className="mt-3">
        {!_.isEmpty(errors) && (
          <MessageError message={'ログインメールアドレスまたはパスワードが一致しません。'} />
        )}
      </FormGroup>
      <FormGroup className="mb-3 d-flex justify-content-center mt-4">
        <Button
          type="submit"
          className="bg-black border-0 d-flex align-items-center justify-content-center"
          disabled={isSubmitting}
        >
          {isSubmitting && (
            <Spinner animation="border" role="status" variant="light" size="sm" className="me-2">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          <p>ログイン</p>
        </Button>
      </FormGroup>
    </form>
  );
}
