import React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import { DATE_FORMAT_2, DATE_FORMAT_FOR_DATE_PICKER } from 'Constant/Date';
import { replaceTimeOfDate } from 'Utils/DateTime';
import dayjs from 'dayjs';
registerLocale('ja', ja);

export type BRDate = Date | null | undefined;
export interface BRDatePickerTypes {
  selected: BRDate;
  minDate?: BRDate;
  maxDate?: BRDate;
  onChangeDate: (date: BRDate) => void;
  dateFormat?: string;
  placeholder?: string;
  timeFormat?: string;
  showTimeSelect?: boolean;
  disabled?: boolean;
  currentDate: BRDate;
  showIcon?: boolean;
}

const minutesOfDay = (date: any) => {
  return dayjs(date).get('minute') + dayjs(date).get('hour') * 60;
};

const BRTimePicker = ({
  selected,
  minDate = new Date(),
  maxDate,
  onChangeDate,
  dateFormat,
  placeholder,
  timeFormat,
  showTimeSelect,
  disabled,
  currentDate,
  showIcon,
}: BRDatePickerTypes): JSX.Element => {
  const filterPassedTime = (time: any) => {
    const selectedDate = new Date(time);
    const currentDateCompare = currentDate
      ? new Date(replaceTimeOfDate(currentDate, selectedDate))
      : new Date();
    if (minDate) {
      const minDateCompare = new Date(dayjs(minDate).format(DATE_FORMAT_2));
      if (
        dayjs(currentDateCompare).isSame(dayjs(minDateCompare), 'day') &&
        minDateCompare.getTime() > currentDateCompare.getTime()
      ) {
        return false;
      }
    }
    if (maxDate) {
      const maxDateCompare = new Date(dayjs(maxDate).format(DATE_FORMAT_2));
      if (
        dayjs(currentDateCompare).isSame(dayjs(maxDateCompare), 'day') &&
        maxDateCompare.getTime() < currentDateCompare.getTime()
      ) {
        return false;
      }
    }
    return true;
  };

  const handleOnChange = (date: Date) => {
    if (
      minutesOfDay(date) < minutesOfDay(minDate) &&
      dayjs(currentDate).isSame(dayjs(minDate), 'day')
    ) {
      return onChangeDate(minDate);
    }

    if (maxDate && minutesOfDay(date) > minutesOfDay(maxDate)) {
      return onChangeDate(maxDate);
    }
    onChangeDate(date);
  };

  return (
    <div className={`position-relative ${showIcon ? 'custom-date-picker' : ''}`}>
      <ReactDatePicker
        className={`form-control ${showIcon ? 'custom-icon-date' : ''}`}
        locale="ja"
        strictParsing
        showTimeSelect={showTimeSelect}
        closeOnScroll={(e) => e.target === document}
        selected={selected}
        timeFormat={timeFormat ?? ''}
        dateFormat={dateFormat ?? DATE_FORMAT_FOR_DATE_PICKER}
        onChange={(date: Date) => handleOnChange(date)}
        minDate={minDate}
        maxDate={maxDate}
        filterTime={filterPassedTime}
        placeholderText={placeholder ?? ''}
        disabled={disabled}
        showTimeSelectOnly
        autoComplete="off"
      />
    </div>
  );
};

export default BRTimePicker;
