import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { InputEvent } from 'Components/Common/Form/form';
import MessageError from 'Components/Common/MessageError';
import { labelRequire } from 'Components/Common/Form/FormLabel';
import { InputProps } from 'Components/Common/Form/FormGroupInput/form-group-input';
import HintTextField from 'Components/Common/Form/HintTextField';
import { MAX_INPUT } from 'Constant';

export default function FormGroupInput(props: InputProps): JSX.Element {
  const {
    label,
    classLabel,
    subLabel,
    classForm = '',
    classCol,
    errorMessage,
    placeholder,
    labelMd,
    colMd,
    value,
    register,
    onChange,
    hintText = '',
    disabled = false,
    isReview = false,
    maxLength = MAX_INPUT,
    type = 'text',
    messageWrapper,
    classMessage,
    classInput,
    onKeyPress,
    onBlur,
    name,
    labelWrapperClass,
    styleCol,
    classHintText,
    isReadOnly = false,
  } = props;

  const handleOnchange = (e: InputEvent) => {
    if (onChange && !isReview) {
      onChange(e.target.value);
    }
  };

  return (
    <Form.Group as={Row} className={`mb-3 ${classForm}`}>
      <Form.Label column md={labelMd} className={labelWrapperClass}>
        {labelRequire(label, classLabel)}
        {<p className="fs-12">{subLabel}</p>}
      </Form.Label>
      <Col md={colMd} className={classCol} style={styleCol}>
        <Form.Control
          {...register}
          value={value ?? ''}
          onChange={handleOnchange}
          type={type}
          placeholder={placeholder}
          disabled={disabled || isReview}
          maxLength={maxLength}
          className={classInput}
          autoComplete="new-password"
          onKeyPress={onKeyPress}
          onBlur={onBlur}
          name={name}
          readOnly={isReadOnly}
        />
        <HintTextField hintText={hintText} classNames={classHintText} />
        <MessageError
          classWrapper={messageWrapper}
          classMessage={classMessage}
          message={errorMessage}
        />
      </Col>
    </Form.Group>
  );
}
