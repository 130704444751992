import React, { PropsWithChildren } from 'react';
import { Modal, Button } from 'react-bootstrap';

interface Props {
  onCloseModal: () => void;
  show: boolean;
  classNameContent?: string;
  submitIdForm?: string;
  variantButtonSubmit?: string;
  onSubmitModal?: () => void;
  dialogClassName?: string;
  headerClassName?: string;
  modalClassName?: string;
  fullscreen?: true | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down';
  footerClassName?: string;
  cancelText?: string;
  confirmText?: string;
  isReviewModal?: boolean;
  title?: string;
  showCancelButton?: boolean;
  size?: 'sm' | 'lg' | 'xl';
  classBtnSubmit?: string;
  classTitle?: string;
  disableModalTitleTruncate?: boolean;
}

function ModalComponent(props: PropsWithChildren<Props>): JSX.Element {
  const {
    children,
    onCloseModal,
    show,
    classNameContent,
    submitIdForm,
    variantButtonSubmit,
    onSubmitModal,
    dialogClassName,
    modalClassName,
    fullscreen,
    footerClassName,
    cancelText,
    confirmText,
    isReviewModal = false,
    headerClassName,
    title = '登録確認',
    showCancelButton = true,
    size,
    classBtnSubmit,
    classTitle,
    disableModalTitleTruncate = false,
  } = props;
  return (
    <Modal
      dialogClassName={`${!fullscreen ? 'main-wrap-modal' : ''} ${dialogClassName ?? ''}`}
      show={show}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`${modalClassName}`}
      fullscreen={fullscreen}
    >
      <Modal.Header className={headerClassName}>
        <Modal.Title id="contained-modal-title-vcenter" className={`${classTitle ?? ''}`}>
          <p className={`title-header mb-0 ${disableModalTitleTruncate ? '' : 'text-truncate'}`}>
            {title}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={classNameContent}>{children}</Modal.Body>
      <Modal.Footer className={`d-flex ${footerClassName ?? 'justify-content-around'}`}>
        {showCancelButton && (
          <Button
            className={`fs-16 ${isReviewModal ? 'btn' : 'button-submit'}`}
            variant="secondary"
            type="button"
            onClick={onCloseModal}
          >
            {isReviewModal ? '×' : cancelText ?? 'キャンセル'}
          </Button>
        )}
        {!isReviewModal && (
          <Button
            className={`fs-16 button-submit ${classBtnSubmit ?? ''}`}
            variant={variantButtonSubmit ?? 'primary'}
            type="submit"
            onClick={onSubmitModal}
            form={submitIdForm}
          >
            {confirmText ?? '登録する'}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalComponent;
