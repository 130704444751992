import React from 'react';

interface Props {
  title: string;
  dataTable: any[];
  dataIndex: string;
  colClass?: string;
  render?: (data: any) => void;
}
export default function StastiticRow({
  title,
  dataTable,
  render,
  dataIndex,
  colClass,
}: Props): JSX.Element {
  return (
    <tr className="show-tr">
      <td className="text-white bg-dark text-start d-block">{title}</td>
      {dataTable.map((item, index) => {
        return (
          <td key={index} className={`${colClass && colClass}`}>
            {render ? (
              <div>{render({ row: item, index }) as React.ReactNode}</div>
            ) : (
              item[dataIndex] && Math.round(item[dataIndex])
            )}
          </td>
        );
      })}
    </tr>
  );
}
