import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import DigitalPrivilege from 'Page/PromotionManager/DigitalPrivilege';
import SaleSchedule from 'Page/PromotionManager/SaleSchedule';
import Tabs from 'Components/Common/Tabs/Tabs';
import Filter from 'Components/Common/Filter/Filter';
import FilterPromotion from 'Page/PromotionManager/FilterPromotion';
import 'Page/PromotionManager/index.scss';

export default function DetailTokuten(): JSX.Element {
  const history = useHistory();
  const btnGroupOptions = [{ name: 'デジタル特典登録' }, { name: '販売スケジュール' }];
  const { id } = useParams<{ id: string }>();
  const [tab, setTab] = useState<string | null>(btnGroupOptions[0].name);

  useEffect(() => {
    if (id && tab === btnGroupOptions[1].name) {
      history.push(`/digital-tokuten?status=${btnGroupOptions[1].name}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tab]);
  return (
    <>
      <Container fluid className={`${tab === btnGroupOptions[0].name && 'shadow-none'}`}>
        <Filter filterClass="shadow-sm">
          <Row>
            <Col className="d-flex align-items-center justify-content-between px-0">
              <Tabs
                heightBtn="43px"
                btnClass="py-2"
                options={btnGroupOptions}
                onChange={setTab}
                tabActive={tab}
                disabledSetPage={true}
              />
            </Col>
          </Row>
          {tab === btnGroupOptions[1].name ? <FilterPromotion /> : <></>}
        </Filter>
      </Container>
      <Container fluid>
        {tab === btnGroupOptions[1].name ? (
          <SaleSchedule />
        ) : (
          <DigitalPrivilege onChangeTabs={setTab} />
        )}
      </Container>
    </>
  );
}
