/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Table } from 'react-bootstrap';
import { useTable, useSortBy, useBlockLayout } from 'react-table';
import { TableProps } from 'Components/Page/PaymentNotice/paymentnotice';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';

const defaultPropGetter = () => ({});

const PaymentListTable = ({
  columns,
  data,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}: TableProps): JSX.Element => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
    },
    useSortBy,
    useBlockLayout,
  );

  const firstPageRows = rows.slice(0, 10);

  const handleRenderSortIcon = (sortby = '') => {
    return (
      <>
        <CaretUpFill className={sortby === 'asc' ? 'text-primary' : ''} />
        <CaretDownFill className={sortby === 'desc' ? 'text-primary' : ''} />
      </>
    );
  };

  return (
    <>
      <Table bordered responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, headerGroupKey) => {
            return (
              <React.Fragment key={headerGroupKey}>
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, columnKey) => (
                    <React.Fragment key={columnKey}>
                      <th
                        {...column.getHeaderProps([
                          {
                            className: (column as any).className,
                            style: (column as any).style,
                          },
                          getColumnProps(column),
                          getHeaderProps(column),
                          column.getSortByToggleProps(),
                        ])}
                      >
                        <div className="w-100">{column.render('Header')}</div>
                        <div className="fs-12 d-flex flex-column">
                          {column.isSorted
                            ? column.isSortedDesc
                              ? handleRenderSortIcon('desc')
                              : handleRenderSortIcon('asc')
                            : handleRenderSortIcon('')}
                        </div>
                      </th>
                    </React.Fragment>
                  ))}
                </tr>
              </React.Fragment>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={i}>
                <tr {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map((cell, cellKey) => {
                    return (
                      <React.Fragment key={cellKey}>
                        <td
                          {...cell.getCellProps([
                            {
                              className: (cell.column as any).className,
                              style: (cell.column as any).style,
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell),
                          ])}
                        >
                          {cell.render('Cell')}
                        </td>
                      </React.Fragment>
                    );
                  })}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default PaymentListTable;
