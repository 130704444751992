import React, { useState, useEffect, useMemo } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Table } from 'react-bootstrap';
import TableRowView from 'Components/Page/Campaign/StatisticCampaign/Tables/TableRowView';
import { campaignOptionsType, CourseTypes, subTypeOptions } from 'Constant/Campaign';
import { find, isEmpty, some } from 'lodash';
import { CourseCampaign, Prize } from 'Types/Page/Campaign/RegisterCampaign';

interface TableProps {
  courseIndex: number;
  isDisabledItem?: boolean;
  course: CourseCampaign;
}

const SortableCont = SortableContainer(({ children }: { children: any }) => {
  return <tbody className="table-drag-item-body">{children}</tbody>;
});

const SortableItem = SortableElement((props: any) => {
  return <TableRowView {...props} />;
});

const TableAwardView = ({ courseIndex, course }: TableProps): JSX.Element => {
  const [items, setItems] = useState<Prize[]>([]);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setItems(course?.prizes || []);
  }, [course?.prizes]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const getWidth = React.useMemo(() => {
    return course.type === 2 && course.subType !== 2 ? 300 : 650;
  }, [course, mounted]);

  const showSlipRate = useMemo(() => {
    let show = false;
    if (+course?.subType === subTypeOptions[0].value) {
      show = items.filter((prize: Prize) => !prize.userQuantity).length === 0;
    }
    if (+course?.subType === subTypeOptions[1].value) {
      show = items.filter((prize: Prize) => prize.isIncentive).length === 0;
    }
    return show;
  }, [items, course?.subType]);

  return (
    <div>
      <Table className="table-campaign table-award-campaign header-light-gray" responsive>
        <thead>
          <tr>
            <th
              style={{
                width: `${getWidth}px`,
              }}
            >
              賞の名称
            </th>
            <th>種別</th>
            <th>当選数上限</th>
            {+course?.type === CourseTypes.REALTIME &&
              +course?.subType !== subTypeOptions[2].value && (
                <>
                  <th>当選確率</th>
                </>
              )}
            {+course?.type === CourseTypes.REALTIME && (
              <>
                <th>ハズレ賞</th>
              </>
            )}
            <th></th>
          </tr>
        </thead>
        <SortableCont
          axis="y"
          lockAxis="y"
          lockToContainerEdges={true}
          lockOffset={['30%', '50%']}
          helperClass="helperContainerClass"
          useDragHandle={true}
        >
          {items.map((value, index) => {
            const awardType = find(campaignOptionsType, (option) => option.value === value.type);
            const isEmptyFile = isEmpty(value.prizeDigitalTokutens)
              ? true
              : some(value.prizeDigitalTokutens, (prizeDT) => {
                  return !prizeDT.url;
                });
            return (
              <SortableItem
                key={`item-${index}`}
                index={index}
                awardLabel={awardType?.label}
                isDisabled
                isShowError={isEmptyFile && value.type === campaignOptionsType[3].value}
                courseIndex={courseIndex}
                prizeIndex={index}
                course={course}
              />
            );
          })}
          {+course?.type === CourseTypes.REALTIME &&
            +course?.subType !== subTypeOptions[2].value &&
            showSlipRate && (
              <tr>
                <td>
                  <div className="d-block winRate-column text-right">不当選</div>
                </td>
                <td></td>
                <td></td>
                <td>
                  <div className={`d-block winRate-column text-right`}>
                    {course?.slipRate && +course.slipRate > 0 ? `${course.slipRate}%` : '0%'}
                  </div>
                </td>
                <td></td>
                <td></td>
              </tr>
            )}
        </SortableCont>
      </Table>
    </div>
  );
};

export default React.memo(TableAwardView);
