import React from 'react';
import { Container } from 'react-bootstrap';
import { NFTFilter, TableList } from 'Components/Page/NFTList';

export default function index(): JSX.Element {
  return (
    <>
      <Container fluid>
        <NFTFilter />
        <TableList />
      </Container>
    </>
  );
}
