import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { DATE_FORMAT_4, DATE_FORMAT_5 } from 'Constant/Date';
import dayjs from 'dayjs';
import { Button, ButtonGroup } from 'react-bootstrap';

const INIT_QUERY = { page: 0, dateSearch: '', startDate: '', endDate: '' };
interface IButtonNextMonth {
  onClickResult: (e: any) => void;
  key?: string;
  queryDateSearch?: string;
}

export default function ButtonNextMonth({
  onClickResult,
  key,
  queryDateSearch,
}: PropsWithChildren<IButtonNextMonth>): JSX.Element {
  const [query, setQuery] = useState(INIT_QUERY);

  useEffect(() => {
    setQuery(INIT_QUERY);
  }, [key]);

  useEffect(() => {
    if (queryDateSearch) {
      setQuery({ ...query, dateSearch: queryDateSearch });
    }
  }, [queryDateSearch]);

  const changeMonth = useCallback(
    (type: string) => {
      const dateTime = query.dateSearch ? query.dateSearch : dayjs().format(DATE_FORMAT_4);
      let date = dateTime;
      if (type === 'next') {
        date = dayjs(dateTime).set('date', 1).add(1, 'month').format(DATE_FORMAT_4);
      } else {
        date = dayjs(dateTime).set('date', 1).subtract(1, 'month').format(DATE_FORMAT_4);
      }
      const startOfMonth = dayjs(date).startOf('month').format();
      const startDate = dayjs(startOfMonth).utc().format();

      const endOfMonth = dayjs(date).endOf('month').format();
      const endDate = dayjs(endOfMonth).utc().format();
      const param = {
        startDate,
        endDate,
        dateSearch: date,
        page: 1,
      };
      setQuery(param);
      onClickResult(param);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query.dateSearch],
  );

  return (
    <ButtonGroup>
      <Button onClick={() => changeMonth('prev')} className="bg-white text-dark custom-border">
        前月
      </Button>
      <Button variant="dark" className="bg-dark custom-border">
        {query.dateSearch
          ? dayjs(query.dateSearch).format(DATE_FORMAT_5)
          : dayjs().format(DATE_FORMAT_5)}
      </Button>
      <Button onClick={() => changeMonth('next')} className="bg-white text-dark custom-border">
        次月
      </Button>
    </ButtonGroup>
  );
}
