import React from 'react';

export const KanjiMsgUI = () => {
  return (
    <div className={`fs-11 mt-2 text-cherry px-3`}>
      <p>
        1.
        入力可能な文字は、日本語（ひらがな、カタカナ、漢字）、英数字、記号と全角スラッシュ「／」のみです。
      </p>
      <p>2. 名前は最大3つまでです。</p>
      <p>3. 全角スラッシュ「／」の前後に空白を含めることはできません。</p>
    </div>
  );
};
