import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
// import { InputEvent } from 'Components/Common/Form/form';
import MessageError from 'Components/Common/MessageError';
import { labelRequire } from 'Components/Common/Form/FormLabel';
import { InputProps } from './input';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';

export default function FormGroupFile(props: InputProps): JSX.Element {
  const {
    label,
    subLabel,
    innerText,
    fileId,
    filePreview,
    imgUploaded,
    previewClass,
    classForm,
    hintTextClass,
    errorMessage,
    placeholder,
    labelMd,
    colMd,
    value,
    register,
    onChange,
    hintText,
    disabled = false,
    isReview = false,
    type = 'file',
    messageWrapper,
    classCol,
  } = props;

  const handleOnchange = (e: any) => {
    if (onChange && !isReview) {
      onChange(e);
    }
  };

  return (
    <>
      <Form.Group as={Row} className={`${classForm}`}>
        <Form.Label column md={labelMd}>
          {labelRequire(label)}
          {<p className="fs-12">{subLabel}</p>}
        </Form.Label>
        <Col md={colMd} className={classCol}>
          <label htmlFor={fileId} className="custom-file-input">
            {innerText}
            <Form.Control
              id={fileId}
              {...register}
              name={fileId}
              value={value ?? ''}
              onChange={handleOnchange}
              type={type}
              placeholder={placeholder}
              disabled={disabled || isReview}
              className="d-none"
              autoComplete="off"
            />
          </label>
          {hintText && <div className={`fs-12 ${hintTextClass}`}>{hintText}</div>}
        </Col>
        <Col md="6">
          {imgUploaded && (
            <img src={handleConnectStaticEndPoint(imgUploaded)} className={previewClass} alt="" />
          )}
          {filePreview && <img src={filePreview} className={previewClass} alt="" />}
        </Col>
      </Form.Group>
      <Row className="mb-3">
        <Col md={labelMd}></Col>
        <Col md={9}>
          <MessageError classWrapper={messageWrapper} message={errorMessage} />
        </Col>
      </Row>
    </>
  );
}
