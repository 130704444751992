import { yupResolver } from '@hookform/resolvers/yup';
import CardContainer from 'Components/Common/CardContainer';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import ModalComponent from 'Components/Common/Modal';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { ActionType } from 'Constant';
import useMessage from 'Hooks/useMessage';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { stringRequiredTrim, englishAndDashValidation } from 'Utils/Validation';
import * as yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { CDApis } from 'Datasource/CD';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import { useDropzone } from 'react-dropzone';
import { checkIfFilesAreCorrectType, checkImgSquareAndDimension } from 'Utils/File';
import MessageError from 'Components/Common/MessageError';
import { isEmpty } from 'lodash';
import { getBool } from 'Utils/Permission';
import FormInputIME from 'Components/Common/Form/FormInputIME';
import FormSelectArtist from 'Components/Common/Select/FormSelectArtist';
import FormSelectTitle from 'Components/Common/Select/FormSelectTitle';

interface IFormInputs {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const schema = yup.object().shape({
  name: stringRequiredTrim({}).nullable(),
  titleId: stringRequiredTrim({}).nullable(),
  artistId: stringRequiredTrim({}).nullable(),
  price: stringRequiredTrim({})
    .test({
      name: 'price',
      message: '100,000円以下の値を入力してください。',
      test: (value) => {
        const price = value ? +value : 0;
        return price < 100000;
      },
    })
    .nullable(),
  productCode: englishAndDashValidation({}).nullable(),
});

export default function CreateCD(): JSX.Element {
  const history = useHistory();
  const { id }: { id: string } = useParams();
  const [cdId, setCDId] = useState(id);
  const { openMessageError } = useMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalConfirmInfo, setModalConfirmInfo] = useState<any>({
    show: false,
    type: null,
  });

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    getValues,
    watch,
    reset,
    setError,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      name: '',
      titleId: '',
      titleName: '',
      artistId: '',
      artistName: '',
      price: '',
      productCode: '',
      file: null,
      preview: '',
      canDelete: 1,
      isAuthor: 1,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (dataInput: IFormInputs) => {
    try {
      setModalConfirmInfo({ show: false, type: null });
      setIsLoading(true);

      const params = {
        titleId: +dataInput?.titleId,
        name: dataInput?.name,
        price: +dataInput?.price,
        productCode: dataInput?.productCode,
        file: dataInput?.file,
      };
      if (cdId) {
        await CDApis.updateCD({ params: { ...params, id: +cdId } });
      } else {
        const {
          data: { data },
        } = await CDApis.postCD({ params });
        setCDId(data);
      }
      history.push('/cd-master');
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onHandleDeleteCD = async () => {
    try {
      await CDApis.deleteCD({ id: cdId });
      history.push('/cd-master');
    } catch (error) {
      openMessageError(error);
    }
  };

  const handleImageToFile = (url: any, fileName: string) => {
    let resFile: File;
    fetch(handleConnectStaticEndPoint(url)).then(async (response: any) => {
      const blob = await response.blob();
      resFile = new File([blob], fileName, { type: 'image/png' });
      setValue('file', resFile);
    });
  };

  const handleGetDetailCD = async () => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await CDApis.getCD({ id: id });
      if (data?.coverURL) {
        handleImageToFile(data?.coverURL, 'image-cd');
      }
      reset({
        name: data?.name,
        titleId: data?.titleId,
        titleName: data?.titleName,
        artistId: data?.artistId,
        artistName: data?.artistName,
        price: data?.price,
        productCode: data?.productCode,
        preview: handleConnectStaticEndPoint(data?.coverURL),
        canDelete: data?.canDelete,
        isAuthor: data?.isAuthor,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleGetDetailCD();
    }
  }, [id]);

  const handleOnDrop = async (acceptedFiles: File[]) => {
    setError('file', {
      type: 'manual',
      message: '',
    });
    const file = acceptedFiles[0];
    if (!file) return;
    const isValidType = checkIfFilesAreCorrectType(file);
    if (!isValidType) {
      setError('file', {
        type: 'manual',
        message: '画像タイプはjpeg, pngまたはfgifの画像を登録してください。',
      });
      return;
    }
    const isSquareImg = await checkImgSquareAndDimension(file, 600);
    if (!isSquareImg) {
      setError('file', {
        type: 'manual',
        message: '画像サイズは縦横６００ｘ６００ピクセルの画像を登録してください。',
      });
      return;
    }
    setValue('file', file);
    setValue('preview', URL.createObjectURL(file));
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: 'image/jpeg,image/png,image/gif',
    maxFiles: 1,
    onDrop: handleOnDrop,
  });

  useEffect(() => {
    if (!isEmpty(fileRejections)) {
      setError('file', {
        type: 'manual',
        message: '画像タイプはjpeg, pngまたはgifの画像を登録してください。',
      });
    }
  }, [fileRejections]);

  return (
    <div className="mt-4 cd-register">
      <Container>
        <SpinnerComponent isLoading={isLoading} />
        <CardContainer title={cdId ? 'CDマスタ情報' : 'CDマスタ登録'} darkHeader>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            id="curatorManagement"
            className={
              !getBool(watch('isAuthor')) ? 'pe-none user-select-none' : 'pe-auto user-select-auto'
            }
          >
            <FormSelectArtist
              defaultValue={watch('artistName')}
              rowClass="mt-3"
              labelMd="2"
              colMd="9"
              label="アーティスト"
              placeholder="アーティストを選択してください。"
              inputSearchString={watch('artistName')}
              onSelect={(selectedItem) => {
                reset({
                  ...getValues(),
                  artistId: selectedItem.value,
                  artistName: selectedItem.label,
                });
              }}
              errorMessage={errors.artistId?.message}
              disabled={!!cdId}
              onInput={(text) => {
                if (!text) {
                  reset({
                    ...getValues(),
                    artistId: '',
                    artistName: '',
                    titleId: '',
                    titleName: '',
                  });
                }

                if (text?.trim() !== watch('artistName').trim()) {
                  reset({
                    ...getValues(),
                    artistId: '',
                    titleId: '',
                    titleName: '',
                  });
                }
              }}
              permission={cdId ? ActionType.EDIT : ActionType.CREATE}
            />
            <FormSelectTitle
              rowClass="my-3"
              labelMd="2"
              colMd="9"
              label="タイトル名"
              inputSearchString={watch('titleName')}
              placeholder="タイトルを選択してください。"
              disabled={!watch('artistId') || !!cdId}
              artistID={watch('artistId')}
              onSelect={(selectedItem: any) => {
                reset({
                  ...getValues(),
                  titleId: selectedItem.value,
                  titleName: selectedItem.label,
                });
              }}
              errorMessage={errors.titleId?.message}
              onInput={(text: string) => {
                if (!text) {
                  reset({
                    ...getValues(),
                    titleId: '',
                    titleName: '',
                  });
                }
                if (text?.trim() !== watch('titleName').trim()) {
                  reset({
                    ...getValues(),
                    titleId: '',
                  });
                }
              }}
            />

            <FormInputIME
              label="CD商品名 (バリエーション)"
              labelMd="2"
              colMd="9"
              register={{ ...register('name') }}
              value={watch('name')}
              onChange={(value) => {
                reset({
                  ...getValues(),
                  name: value,
                });
              }}
              errorMessage={errors.name?.message}
            />

            <FormInputNumber
              name="price"
              labelMd="2"
              colMd="3"
              label="販売価格"
              classCol="position-relative price-end"
              pureType={true}
              decimalScale={0}
              allowNegative={false}
              maxLength={10}
              thousandSeparator={true}
              value={watch('price')}
              onChange={(value) => {
                reset({
                  ...getValues(),
                  price: value,
                });
              }}
              errorMessage={errors.price?.message}
            />

            <FormInputIME
              label="品番"
              labelMd="2"
              colMd="3"
              maxLength={20}
              register={{ ...register('productCode') }}
              value={watch('productCode')}
              onChange={(value) => {
                reset({
                  ...getValues(),
                  productCode: value,
                });
              }}
              errorMessage={errors.productCode?.message}
              disabled={!!cdId}
            />
            <Row>
              <Col md={2}>
                ジャケット画像 <br />
                （600px X 600px)
              </Col>
              <Col md={3}>
                <section>
                  <div
                    {...getRootProps({
                      className:
                        'dropzone file-box d-flex align-items-center justify-content-center border rounded',
                    })}
                  >
                    {watch('file') ? (
                      <div>
                        <input {...getInputProps()} />
                        <img className="border rounded file-box" src={watch('preview')} alt="" />
                      </div>
                    ) : (
                      <>
                        <input {...getInputProps()} />
                        <p className="gray">画像ファイルをアップロードしてください</p>
                      </>
                    )}
                  </div>
                </section>
                <MessageError classWrapper="w-max-content" message={errors.file?.message} />
              </Col>
            </Row>
          </Form>
          <hr className="mt-5" />

          <div className="d-flex align-items-center justify-content-end mt-2">
            {cdId && (
              <Button
                variant="danger"
                className="me-4 btn-curator-register"
                onClick={() => setModalConfirmInfo({ show: true, type: 0 })}
                disabled={!watch('canDelete') || !getBool(watch('isAuthor'))}
              >
                削除する
              </Button>
            )}

            <Button
              className="btn-curator-register"
              onClick={async () => {
                const isValidForm = await trigger();
                if (isValidForm) {
                  setModalConfirmInfo({ show: true, type: 1 });
                }
              }}
              disabled={!getBool(watch('isAuthor'))}
            >
              保存する
            </Button>
          </div>
        </CardContainer>
      </Container>
      <ModalComponent
        title={modalConfirmInfo?.type === 0 ? '削除確認' : '登録確認'}
        show={modalConfirmInfo?.show}
        confirmText={modalConfirmInfo?.type === 0 ? '削除する' : '登録する'}
        onCloseModal={() => setModalConfirmInfo({ show: false, type: null })}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit={modalConfirmInfo?.type === 0 ? 'danger' : 'primary'}
        submitIdForm={modalConfirmInfo?.type === 0 ? undefined : 'curatorManagement'}
        onSubmitModal={modalConfirmInfo?.type === 0 ? onHandleDeleteCD : undefined}
      >
        {modalConfirmInfo?.type === 0
          ? 'CDマスタを削除します。よろしいですか。'
          : 'CDマスタを登録します。よろしいですか？'}
      </ModalComponent>
    </div>
  );
}
