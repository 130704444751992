import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import CardWrapper from 'Components/Common/Card/Card';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import CheckBoxs from 'Components/Common/Form/GroupCheckBox/CheckBoxs';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import { Row, Col, Button, Container, Form } from 'react-bootstrap';
import TableCampaign from 'Components/Page/Campaign/RegisterCampaign/Step2/TableCampaign';
import {
  awardSettings,
  methodOptions,
  distributionSettingOptions,
  attachedBonus,
  CheckboxOption,
  initialCourse,
  defaultConditions,
  TargetProduct,
  CampaignStatus,
  methodOptionTabs,
  subTypeOptions,
} from 'Constant/Campaign';
import ActionButtons from 'Components/Page/Campaign/RegisterCampaign/Common/ActionButtons';
import { useFieldArray, useForm } from 'react-hook-form';
import ModalComponent from 'Components/Common/Modal';
import { useAppDispatch, useAppSelector } from 'App/Store';
import { setErrorRealTime, updateStep2State } from 'App/Features/Campaign';
import { CampaignApis } from 'Datasource/Campaign';
import useCampaign from 'Hooks/useCampaign';
import { isEmpty, some, omit, filter } from 'lodash';
import { dateToISOString, normalizeDate, replaceTimeOfDate } from 'Utils/DateTime';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { CourseConditions } from 'Components/Page/Campaign/RegisterCampaign/Step2/ConditionRow';
import {
  Variantion,
  CourseIndexType,
  FormValues,
  Prize,
  CourseCampaign,
} from 'Types/Page/Campaign/RegisterCampaign';
import useMessage from 'Hooks/useMessage';
import dayjs from 'dayjs';
import BRFormGroupDate from 'Components/Common/Form/FormGroupDate/BRFormGroupDate';
import BRFormGroupDateRange from 'Components/Common/Form/FormGroupDateRange/BRFormGroupDateRange';
import TabCampaign from 'Components/Common/Tabs/TabCampaign';
import { getMsgExceed100 } from 'Utils/Campaign';

const StepCourseAndMethod = forwardRef((props: any, ref: any): JSX.Element => {
  const dispatch = useAppDispatch();
  const { openMessage, openMessageError } = useMessage();

  const {
    handleChangeStep,
    saveOrUpdateDraft,
    isLoading,
    handleDisableDataCampaign,
    handleSetOldTitleId,
  } = useCampaign();
  const { courses, step, overview, oldTitleId, errorRealTime } = useAppSelector(
    (state) => state.campaign,
  );

  const {
    control,
    register,
    getValues,
    setValue,
    reset,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      courses: [initialCourse],
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'courses',
  });

  const [courseInfos, setCourseInfos] = useState<any>([]);

  const [currentCourse, setCurrentCourse] = useState<{
    visible: boolean;
    courseIndex: CourseIndexType;
  }>({ visible: false, courseIndex: undefined });

  const [disabledNext, setDisableNext] = useState<boolean>(false);

  const handleAddCourse = () => {
    const courses = watch('courses');
    let course = { ...initialCourse, courseInfos };
    // * Only 1 course can exist when all win
    if (courses.length) {
      course = {
        ...course,
        receiveType: attachedBonus[1].value,
        prizeOption: awardSettings[1].value,
        subType: subTypeOptions[1].value,
      };
    }
    reset({
      courses: [...courses, course],
    });
  };

  const handleDeleteCourse = (index: CourseIndexType) => {
    if (getValues('courses').length > 1) {
      const remaningCourses = [...getValues('courses')];
      remaningCourses.splice(index as number, 1);
      setValue('courses', remaningCourses);
    }
    setCurrentCourse({ visible: false, courseIndex: undefined });
  };

  const openModalConfirm = (index: CourseIndexType) => {
    setCurrentCourse({ visible: true, courseIndex: index });
  };

  const handleUpdateAwardSettings = (
    key: number,
    isDisabled: boolean,
    options: CheckboxOption[],
  ) => {
    const tmpOption = { ...options[key], disabled: isDisabled };
    const newSetting = key ? [options[0], tmpOption] : [tmpOption, options[1]];
    return newSetting;
  };

  const handleTrackingAndSwitchMethod = (courseIndex: number) => {
    // * CASE: All win combined with Buy and win => Reward not specified
    if (
      watch(`courses.${courseIndex}.type`) === methodOptions[0].value &&
      watch(`courses.${courseIndex}.receiveType`) === attachedBonus[0].value
    ) {
      setValue(`courses.${courseIndex}.prizeOption`, awardSettings[0].value);
    }

    if (
      watch(`courses.${courseIndex}.type`) === methodOptions[0].value &&
      watch(`courses.${courseIndex}.receiveType`) === attachedBonus[1].value
    ) {
      setValue(`courses.${courseIndex}.prizeOption`, awardSettings[1].value);
      const prizes = watch(`courses.${courseIndex}`)?.prizes?.map((prize: any) => {
        return { ...prize, userQuantity: null };
      });
      setValue(`courses.${courseIndex}.prizes`, prizes);
    }

    if (
      watch(`courses.${courseIndex}.option`) === distributionSettingOptions[2].value &&
      watch(`courses.${courseIndex}.type`) === methodOptions[0].value &&
      watch(`courses.${courseIndex}.receiveType`) === attachedBonus[1].value
    ) {
      const prizes = watch(`courses.${courseIndex}`)?.prizes?.map((prize: any) => {
        return { ...prize, userQuantity: null };
      });
      setValue(`courses.${courseIndex}.prizes`, prizes);
    }
    // * CASE: Combined general ticket exchange with all wins => Redeem new winning ticket
    if (
      watch(`courses.${courseIndex}.option`) === distributionSettingOptions[2].value &&
      watch(`courses.${courseIndex}.type`) === methodOptions[0].value
    ) {
      setValue(`courses.${courseIndex}.receiveType`, attachedBonus[1].value);
      setValue(`courses.${courseIndex}.prizeOption`, awardSettings[1].value);
    }

    if (watch(`courses.${courseIndex}.type`) === methodOptions[2].value) {
      setValue(`courses.${courseIndex}.prizeOption`, awardSettings[0].value);
    }

    if (
      watch(`courses.${courseIndex}.type`) === methodOptions[1].value ||
      watch(`courses.${courseIndex}.type`) === methodOptions[2].value
    ) {
      const prizes = watch(`courses.${courseIndex}`)?.prizes?.map((prize: any) => {
        return {
          ...prize,
          seatPickerType: prize?.seatPickerType === 1 ? 0 : prize.seatPickerType,
          seatRow: prize?.seatPickerType === 1 ? null : prize.seatRow,
          seatNumber: prize?.seatPickerType === 1 ? null : prize.seatNumber,
        };
      });
      setValue(`courses.${courseIndex}.prizes`, prizes);
    }

    if (
      watch(`courses.${courseIndex}.type`) === methodOptions[0].value &&
      watch(`courses.${courseIndex}.prizeOption`) === awardSettings[1].value
    ) {
      const prizes = watch(`courses.${courseIndex}`)?.prizes?.map((prize: any) => {
        return { ...prize, userQuantity: null };
      });
      setValue(`courses.${courseIndex}.prizes`, prizes);
    }

    if (watch(`courses.${courseIndex}.type`) === methodOptions[0].value) {
      const prizes = watch(`courses.${courseIndex}`)?.prizes?.map((prize: any) => {
        return {
          ...prize,
          userQuantity: null,
          seatPickerType: 0,
          seatRow: null,
          seatNumber: null,
        };
      });
      setValue(`courses.${courseIndex}.prizes`, prizes);
    }
  };

  const renderMethodOptions = (courseIndex: number) => {
    let options: CheckboxOption[] = handleUpdateAwardSettings(0, false, attachedBonus);
    const courses = watch('courses');
    // * Only 1 course can exist
    if (courses.length > 1) {
      return (options = handleUpdateAwardSettings(0, true, attachedBonus));
    }
    // * Composite dial
    if (
      watch(`courses.${courseIndex}.option`) === distributionSettingOptions[2].value &&
      watch(`courses.${courseIndex}.type`) === methodOptions[0].value
    ) {
      return (options = handleUpdateAwardSettings(0, true, attachedBonus));
    }
    return options;
  };

  const renderPrizeOptions = (courseIndex: number) => {
    let options: CheckboxOption[] = awardSettings;
    // * CASE: ALL WON
    if (
      watch(`courses.${courseIndex}.type`) === methodOptions[0].value &&
      watch(`courses.${courseIndex}.receiveType`) === attachedBonus[1].value
    ) {
      return (options = handleUpdateAwardSettings(0, true, awardSettings));
    }
    // * CASE: Buy and win
    if (
      watch(`courses.${courseIndex}.option`) === distributionSettingOptions[2].value &&
      watch(`courses.${courseIndex}.type`) === methodOptions[0].value
    ) {
      return (options = handleUpdateAwardSettings(1, true, awardSettings));
    }
    // * !== Buy and win
    if (
      watch(`courses.${courseIndex}.type`) === methodOptions[0].value &&
      watch(`courses.${courseIndex}.receiveType`) === attachedBonus[0].value
    ) {
      return (options = handleUpdateAwardSettings(1, true, awardSettings));
    }

    //* case Realtime
    if (watch(`courses.${courseIndex}.type`) === methodOptions[2].value) {
      return (options = handleUpdateAwardSettings(1, true, awardSettings));
    }

    return options;
  };

  const handleNext = (back?: boolean) => {
    const formData = [...getValues('courses')].map((course: CourseCampaign) => ({
      ...course,
      slipRate: course?.prizes?.length > 0 ? course?.slipRate : 100,
    }));
    if (disabledNext) {
      return;
    }

    dispatch(updateStep2State({ courses: formData }));
    handleChangeStep(back ? step - 1 : step + 1);
  };

  useImperativeHandle(ref, () => ({
    handleNextStepFromParent(back?: boolean) {
      handleNext(back);
    },
  }));

  const handleSaveDraft = async () => {
    if (!props?.handleValidateDraft()) {
      handleSetOldTitleId();
      handleChangeStep(step - 1);
      props?.setBackValidate();
      return;
    }

    if (disabledNext) {
      return;
    }

    if (errorRealTime?.prizeQuantityTooSmall) {
      openMessage({
        variant: 'error',
        message: getMsgExceed100(courses, errorRealTime?.courseName),
      });
      return;
    }

    if (errorRealTime?.exceed100) {
      openMessage({
        variant: 'error',
        message: getMsgExceed100(courses, errorRealTime?.courseName),
      });
      return;
    }
    //* handle change course option select
    const tmpCourses = [...getValues('courses')];
    tmpCourses.forEach((_, courseIndex) => {
      if (watch(`courses.${courseIndex}.option`) !== distributionSettingOptions[1].value) {
        setValue(`courses.${courseIndex}.ticketPrice`, null);
      }

      if (watch(`courses.${courseIndex}.option`) !== distributionSettingOptions[2].value) {
        const tmpCourseInfos = [...watch(`courses.${courseIndex}.courseInfos`)];
        tmpCourseInfos.forEach((item: any) => {
          const optionKey = Object.keys(item).filter((obj) => obj.includes('option'));
          if (optionKey && optionKey.length) {
            optionKey.forEach((op: any) => {
              item[op] = false;
            });
          }
        });
        setValue(`courses.${courseIndex}.courseInfos`, tmpCourseInfos);
      }
    });

    saveOrUpdateDraft({
      overview,
      courses: tmpCourses,
      id: props?.campaignId,
      step: step,
    });
  };

  const handleGetVariations = async () => {
    if (!overview.titleId) return;
    try {
      const {
        data: { data },
      } = await CampaignApis.getVariations({ id: overview.titleId, campaignId: props?.campaignId });
      let infos: any = [];

      const standardizeCourses = [...getValues('courses')].map((course: any) => {
        let courseInfos: any = [];
        data.map((variation: Variantion, vIndex: number) => {
          const tmpVariation = omit(variation, 'id');
          if (!isEmpty(course?.courseInfos)) {
            //* CourseInfo already exists
            //FIXME: if (overview.titleId === oldTitleId) {
            if (overview.titleId === oldTitleId) {
              let infoExists: any = [];
              infoExists = filter(course?.courseInfos, (info) => {
                const sameType = info?.type === variation?.type;
                const sameId = info?.variationId === variation?.id || info?.cdId === variation?.id;
                return sameType && sameId;
              });
              const isExist = !isEmpty(infoExists);
              //* Already exist in the course

              const infoV = isExist
                ? {
                    ...tmpVariation,
                    ...infoExists[0],
                    index: vIndex + 1,
                    cdId: variation.type === TargetProduct.CD && variation.id ? variation.id : null,
                    cdStatus: variation.status ? variation.status : 0,
                    variationId:
                      variation.type === TargetProduct.DP && variation.id ? variation.id : null,
                    enabled:
                      variation.status && !infoExists[0]?.enabled ? false : infoExists[0]?.enabled,
                    option1: infoExists[0]?.option1 ?? false,
                    option2: infoExists[0]?.option2 ?? false,
                    option3: infoExists[0]?.option3 ?? false,
                    option4: infoExists[0]?.option4 ?? false,
                  }
                : {
                    ...tmpVariation,
                    index: vIndex + 1,
                    cdId: variation.type === TargetProduct.CD && variation.id ? variation.id : null,
                    cdStatus: variation.status ? variation.status : 0,
                    variationId:
                      variation.type === TargetProduct.DP && variation.id ? variation.id : null,
                    ...defaultConditions,
                    enabled: variation.status || props?.campaignId ? 0 : 1,
                  };

              courseInfos = [...courseInfos, infoV];
            } else {
              //* New info
              courseInfos = [
                ...courseInfos,
                {
                  ...tmpVariation,
                  cdId: variation.type === TargetProduct.CD && variation.id ? variation.id : null,
                  variationId:
                    variation.type === TargetProduct.DP && variation.id ? variation.id : null,
                  index: vIndex + 1,
                  cdStatus: variation.status ? variation.status : 0,
                  ...defaultConditions,
                  enabled: variation.status ? 0 : 1,
                },
              ];
            }
          } else {
            //* There are no variations yet
            courseInfos = [
              ...courseInfos,
              {
                ...tmpVariation,
                cdId: variation.type === TargetProduct.CD && variation.id ? variation.id : null,
                variationId:
                  variation.type === TargetProduct.DP && variation.id ? variation.id : null,
                index: vIndex + 1,
                cdStatus: variation.status ? variation.status : 0,
                ...defaultConditions,
                enabled: variation.status ? 0 : 1,
              },
            ];
          }

          // * SAVE CoureseInfos for add
          infos = courseInfos.map((courseInfo: any) => ({
            ...omit(courseInfo, 'id'),
            ...defaultConditions,
            enabled: courseInfo.status ? 0 : 1,
          }));
        });
        return {
          ...course,
          courseInfos,
        };
      });
      setCourseInfos(infos);
      reset({
        courses: standardizeCourses,
      });
    } catch (error) {
      openMessageError(error);
    }
  };
  //! START issue
  //! issue react-hook-form 6121 frozen form state so clone courses with JSON.stringify from tempCourse redux will be fix it
  //! https://github.com/react-hook-form/react-hook-form/issues/6121

  useEffect(() => {
    const tmpCourses = isEmpty(courses) ? [initialCourse] : courses;

    reset({
      courses: JSON.parse(JSON.stringify(tmpCourses)),
    });
  }, [reset, courses]);

  //! END issue

  useEffect(() => {
    handleGetVariations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overview.titleId, courses]);

  const isLastDP = (item: any, courseInfos: any) => {
    if (item?.type === TargetProduct.DP && item.enabled) {
      const isExistCD = some(
        courseInfos,
        (info) => info.enabled && info?.type === TargetProduct.CD,
      );
      if (isExistCD) {
        let isLast = false;
        const restEl = filter(
          courseInfos,
          (info) => info.index !== item.index && info?.type === TargetProduct.DP,
        );
        if (isEmpty(restEl)) {
          isLast = true;
        } else {
          isLast = some(restEl, (info: any) => info.enabled) ? false : true;
        }
        if (isLast) {
          openMessage({
            variant: 'error',
            message: 'DP種別の対象製品を1つ以上選択してください。',
          });
        }
      }

      if (
        item?.type === TargetProduct.DP &&
        item.enabled &&
        courseInfos.filter((v: any) => v.enabled).length === 1
      ) {
        openMessage({
          variant: 'error',
          message: 'DP種別の対象製品を1つ以上選択してください。',
        });
      }
    }
  };

  const minAndMaxDate = () => {
    let minDate = new Date();
    let maxDate = null;
    if (overview?.startDate && dayjs(overview?.startDate).isAfter(new Date())) {
      minDate = new Date(overview.startDate);
    }
    if (overview?.endDate && dayjs(overview?.endDate).isAfter(new Date())) {
      maxDate = new Date(overview.endDate);
    }
    return { minDate, maxDate };
  };

  const minMaxDateStandardTime = (courseIndex: number) => {
    let minDate = minAndMaxDate().minDate;
    const maxDate = minAndMaxDate().maxDate;
    if (watch(`courses.${courseIndex}.timeJoinEnd`)) {
      minDate = dayjs(watch(`courses.${courseIndex}.timeJoinEnd`)).toDate();
    }
    return { minDate, maxDate };
  };

  const minMaxTimeJoinStart = (courseIndex: number) => {
    const minDate = minAndMaxDate().minDate;
    let maxDate = null;
    if (watch(`courses.${courseIndex}.timeJoinEnd`)) {
      maxDate = dayjs(watch(`courses.${courseIndex}.timeJoinEnd`)).toDate();
    }
    if (
      watch(`courses.${courseIndex}.standardTime`) &&
      !watch(`courses.${courseIndex}.timeJoinEnd`)
    ) {
      maxDate = dayjs(watch(`courses.${courseIndex}.standardTime`)).toDate();
    }
    return { minDate, maxDate };
  };

  const minMaxTimeJoinEnd = (courseIndex: number) => {
    let minDate = minAndMaxDate().minDate;
    let maxDate = null;
    if (watch(`courses.${courseIndex}.timeJoinStart`)) {
      minDate = dayjs(watch(`courses.${courseIndex}.timeJoinStart`)).toDate();
    }

    //* maxDate <= standardTime

    if (watch(`courses.${courseIndex}.standardTime`)) {
      maxDate = dayjs(watch(`courses.${courseIndex}.standardTime`)).toDate();
    }
    return { minDate, maxDate };
  };

  const handleDisabledAddCourse = () => {
    let inValid = false;
    if (overview?.status && overview?.status !== CampaignStatus.Draft) {
      inValid = true;
    }
    if (!overview?.isDeletable) {
      inValid = true;
    }
    // * CASE: All win combined with Buy and win => Disabled add course
    if (
      watch(`courses.${0}.type`) === methodOptions[0].value &&
      watch(`courses.${0}.receiveType`) === attachedBonus[0].value
    ) {
      inValid = true;
    }

    if (
      watch(`courses.${0}.type`) === methodOptions[2].value &&
      watch(`courses.${0}.subType`) === subTypeOptions[0].value
    ) {
      inValid = true;
    }
    return inValid;
  };

  const hasCD = (courseIndex: number) => {
    return [...watch(`courses.${courseIndex}.courseInfos`)].find(
      (info: any) => info.cdId !== null && info.enabled,
    );
  };

  const renderSubTypeOptions = (courseIndex: number) => {
    let options: CheckboxOption[] = subTypeOptions;

    if (!isEmpty(watch(`courses.${courseIndex}.courseInfos`))) {
      if (hasCD(courseIndex)) {
        return (options = subTypeOptions.map((option: CheckboxOption) => ({
          ...option,
          checked: option.value === 1,
          disabled: option.value === 0,
        })));
      }
    }

    if ([...watch('courses')].length > 1) {
      return (options = subTypeOptions.map((option: CheckboxOption) => ({
        ...option,
        checked: option.value === 1 && courseIndex !== 0,
        disabled: option.value === 0 && courseIndex !== 0,
      })));
    }
    return options;
  };

  useEffect(() => {
    [...watch('courses')].forEach((course: CourseCampaign, courseIndex: number) => {
      if (hasCD(courseIndex) && course.subType === 0) {
        setValue(`courses.${courseIndex}.subType`, 1);
      }
    });
  }, [watch('courses')]);
  return (
    <>
      <SpinnerComponent isLoading={isLoading} />
      <Form className="register-campaign-step-2">
        {fields.map((course, courseIndex) => {
          return (
            <div key={courseIndex}>
              <CardWrapper title="応募コース設定" subTitle={`キャンペーン名：${overview?.name}`}>
                <FormGroupInput
                  label="応募コース名称"
                  labelMd="2"
                  colMd="10"
                  value={watch(`courses.${courseIndex}.name`)}
                  onChange={(text) => setValue(`courses.${courseIndex}.name`, text)}
                  errorMessage={errors?.courses && errors?.courses[courseIndex]?.name?.message}
                />
                <CheckBoxs
                  id={`course-method-type-${courseIndex}`}
                  name={`course-method-type-${courseIndex}`}
                  labelMd="2"
                  colMd="10"
                  classBoxErr="w-100"
                  checkboxClass="flex-nowrap py-2"
                  classOption="w-1-3 align-self-start"
                  classForm="d-flex mb-3"
                  labelForm="応募方法"
                  value={watch(`courses.${courseIndex}.option`)}
                  onChange={(option) => {
                    setValue(`courses.${courseIndex}.option`, +option.value);
                    handleTrackingAndSwitchMethod(courseIndex);
                  }}
                  options={distributionSettingOptions}
                  disabled={handleDisableDataCampaign(courses[courseIndex]?.id)}
                />
                <Row
                  className={handleDisableDataCampaign(courses[courseIndex]?.id) ? 'pe-none' : ''}
                >
                  <TableCampaign
                    dataSource={watch(`courses.${courseIndex}.courseInfos`)}
                    titleName={overview?.titleName}
                    isShowConditions={
                      watch(`courses.${courseIndex}.option`) === distributionSettingOptions[2].value
                    }
                    isLastDP={(item: any) => {
                      isLastDP(item, watch(`courses.${courseIndex}.courseInfos`));
                    }}
                    onDpClick={(item: any) => {
                      const tmpCourseInfos = [...watch(`courses.${courseIndex}.courseInfos`)];
                      //* if !item.enabled all option1,2,3,4 is blank
                      tmpCourseInfos.forEach((el: any, index: number) => {
                        if (index === item.rIndex) {
                          el.enabled = !el.enabled;
                          const optionKey = Object.keys(el).filter((obj) => obj.includes('option'));
                          if (optionKey && optionKey.length) {
                            optionKey.forEach((op: any) => {
                              if (!item.enabled) {
                                el[op] = false;
                              }
                            });
                          }
                        }
                      });
                      setValue(`courses.${courseIndex}.courseInfos`, tmpCourseInfos);
                      if (
                        item.cdID !== null &&
                        !item.enabled &&
                        watch(`courses.${courseIndex}.subType`) === subTypeOptions[0].value
                      ) {
                        setValue(`courses.${courseIndex}.subType`, subTypeOptions[1].value);
                        clearErrors('courses');
                      }
                    }}
                    disabled={handleDisableDataCampaign(courses[courseIndex]?.id)}
                    nestIndex={courseIndex}
                    {...{ control, register, setValue, getValues }}
                  />
                </Row>
                {watch(`courses.${courseIndex}.option`) === distributionSettingOptions[2].value && (
                  <Row className="mb-3">
                    <div>応募権利取得条件</div>
                    <div className="mt-2 ps-3  fs-12">
                      <p>条件1～条件4をすべて満たしている必要があります。</p>
                      <CourseConditions courseIndex={courseIndex} control={control} />
                    </div>
                  </Row>
                )}

                <Form.Group as={Row} className="flex-nowrap py-4">
                  <Form.Label column md="2">
                    抽選方法
                  </Form.Label>
                  <Col md="10">
                    <TabCampaign
                      groupClass="btn-group-campaign"
                      options={methodOptionTabs}
                      tabActive={watch(`courses.${courseIndex}.type`)}
                      disabled={handleDisableDataCampaign(courses[courseIndex]?.id)}
                      onChange={(option) => {
                        if (
                          watch(`courses.${courseIndex}.option`) ===
                          distributionSettingOptions[2].value
                        ) {
                          setValue(`courses.${courseIndex}.receiveType`, attachedBonus[1].value);
                        }

                        if (+option === 0 && [...watch('courses')].length > 1) {
                          setValue(`courses.${courseIndex}.receiveType`, attachedBonus[1].value);
                        }

                        if (hasCD(courseIndex)) {
                          setValue(`courses.${courseIndex}.subType`, 1);
                        }
                        // ! Comments for key clear data difference type
                        setValue(`courses.${courseIndex}.standardTime`, null);
                        setValue(`courses.${courseIndex}.timeJoinStart`, null);
                        setValue(`courses.${courseIndex}.timeJoinEnd`, null);
                        setValue(`courses.${courseIndex}.type`, +option);
                        if (+option !== 2 || watch(`courses.${courseIndex}.subType`) === 2) {
                          dispatch(
                            setErrorRealTime({
                              keys: ['exceed100', 'prizeQuantityTooSmall', 'max100'],
                              statuses: [false, false, false],
                              courseName: '',
                            }),
                          );
                        }
                        // ! End comments

                        handleTrackingAndSwitchMethod(courseIndex);
                      }}
                    />
                  </Col>
                </Form.Group>
                <Col md={{ span: 10, offset: 2 }}>
                  {watch(`courses.${courseIndex}.type`) === methodOptions[2].value && (
                    <CheckBoxs
                      id={`sub-type-${courseIndex}`}
                      name={`sub-type-${courseIndex}`}
                      labelMd="12"
                      colMd="12"
                      classBoxErr="w-100"
                      checkboxClass="flex-nowrap py-2"
                      classOption="align-self-start"
                      classForm="d-flex mb-3"
                      labelForm=""
                      value={watch(`courses.${courseIndex}.subType`)}
                      onChange={(option) => {
                        setValue(`courses.${courseIndex}.subType`, +option.value);
                        setValue(`courses.${courseIndex}.prizeQuantity`, null);
                        setValue(`courses.${courseIndex}.slipRate`, 100);
                        const tmpPrizes = [...watch(`courses.${courseIndex}.prizes`)].map(
                          (prize: Prize) => ({ ...prize, winRate: null }),
                        );
                        setValue(`courses.${courseIndex}.prizes`, tmpPrizes);

                        if (
                          watch(`courses.${0}.subType`) === subTypeOptions[0].value &&
                          [...watch(`courses`)].length > 1
                        ) {
                          setError(`courses.${0}.subType`, {
                            type: 'manual',
                            message: 'この抽選方法はひとつのコースのみ登録可能です。',
                          });
                          setDisableNext(true);
                        } else {
                          setDisableNext(false);
                          clearErrors('courses');
                        }
                      }}
                      options={renderSubTypeOptions(courseIndex)}
                      disabled={handleDisableDataCampaign(courses[courseIndex]?.id)}
                      errorMessage={errors?.courses?.[courseIndex]?.subType?.message}
                    />
                  )}
                </Col>

                {watch(`courses.${courseIndex}.type`) === methodOptions[0].value && (
                  <CheckBoxs
                    id={`attachedBonus-${courseIndex}`}
                    name={`attachedBonus-${courseIndex}`}
                    labelMd="2"
                    colMd="10"
                    classBoxErr="w-100"
                    checkboxClass="flex-nowrap py-2"
                    classOption="align-self-start"
                    classForm="d-flex mb-3"
                    labelForm="景品付与設定"
                    value={watch(`courses.${courseIndex}.receiveType`)}
                    onChange={(option) => {
                      setValue(`courses.${courseIndex}.receiveType`, +option.value);
                      handleTrackingAndSwitchMethod(courseIndex);
                    }}
                    options={renderMethodOptions(courseIndex)}
                    disabled={handleDisableDataCampaign(courses[courseIndex]?.id)}
                  />
                )}
                <Row>
                  <Col md="2" />
                  <Col md="10" className="px-0">
                    {watch(`courses.${courseIndex}.type`) === methodOptions[1].value && (
                      <Row>
                        <BRFormGroupDate
                          label="抽選日時"
                          labelMd="12"
                          colMd="5"
                          classForm="px-0 standard-time-des"
                          selected={normalizeDate(watch(`courses.${courseIndex}.standardTime`))}
                          onChange={(date: any) => {
                            setValue(`courses.${courseIndex}.standardTime`, dateToISOString(date));
                          }}
                          placeholder="日時を選択　(yyyy/mm/dd hh:mm)"
                          hintText="購入後日に抽選を行います。応募期間後の日付を設定してください。"
                          disabled={handleDisableDataCampaign(courses[courseIndex]?.id)}
                          minDate={minMaxDateStandardTime(courseIndex).minDate}
                          relationDate={dayjs(minMaxDateStandardTime(courseIndex).minDate).toDate()}
                        />
                        <BRFormGroupDateRange
                          label="応募期間"
                          labelMd="12"
                          colMd="10"
                          classForm="px-0"
                          placeholderStart="開始（yyyy/mm/dd/ hh:mm)"
                          placeholderEnd="終了（yyyy/mm/dd/ hh:mm)"
                          showTimeSelect
                          startDate={normalizeDate(watch(`courses.${courseIndex}.timeJoinStart`))}
                          endDate={normalizeDate(watch(`courses.${courseIndex}.timeJoinEnd`))}
                          onChangeStartDate={(date: any) => {
                            setValue(`courses.${courseIndex}.timeJoinStart`, dateToISOString(date));
                            if (!watch(`courses.${courseIndex}.timeJoinStart`)) {
                              setValue(`courses.${courseIndex}.timeJoinEnd`, null);
                            }
                          }}
                          onChangeEndDate={(date: any) => {
                            if (
                              dayjs(date).isBefore(watch(`courses.${courseIndex}.timeJoinStart`))
                            ) {
                              const replaceDate = replaceTimeOfDate(
                                date,
                                dayjs(watch(`courses.${courseIndex}.timeJoinStart`)).toDate(),
                              );
                              setValue(
                                `courses.${courseIndex}.timeJoinEnd`,
                                dateToISOString(replaceDate),
                              );
                              return;
                            }
                            setValue(`courses.${courseIndex}.timeJoinEnd`, dateToISOString(date));
                          }}
                          startDateDisabled={handleDisableDataCampaign(courses[courseIndex]?.id)}
                          endDateDisabled={
                            handleDisableDataCampaign(courses[courseIndex]?.id) ||
                            !watch(`courses.${courseIndex}.timeJoinStart`)
                          }
                          minStartDate={minMaxTimeJoinStart(courseIndex).minDate}
                          maxStartDate={minMaxTimeJoinStart(courseIndex).maxDate}
                          minEndDate={minMaxTimeJoinEnd(courseIndex).minDate}
                          maxEndDate={minMaxTimeJoinEnd(courseIndex).maxDate}
                          relationEndDate={dayjs(
                            watch(`courses.${courseIndex}.timeJoinStart`),
                          ).toDate()}
                          relationStartDate={minMaxTimeJoinStart(courseIndex).minDate}
                        />
                      </Row>
                    )}
                    {watch(`courses.${courseIndex}.type`) === methodOptions[2].value && (
                      <Row>
                        <BRFormGroupDateRange
                          label="応募期間"
                          labelMd="12"
                          colMd="10"
                          classForm="px-0"
                          placeholderStart="開始（yyyy/mm/dd/ hh:mm)"
                          placeholderEnd="終了（yyyy/mm/dd/ hh:mm)"
                          showTimeSelect
                          startDate={normalizeDate(watch(`courses.${courseIndex}.timeJoinStart`))}
                          endDate={normalizeDate(watch(`courses.${courseIndex}.timeJoinEnd`))}
                          onChangeStartDate={(date: any) => {
                            setValue(`courses.${courseIndex}.timeJoinStart`, dateToISOString(date));
                            if (!watch(`courses.${courseIndex}.timeJoinStart`)) {
                              setValue(`courses.${courseIndex}.timeJoinEnd`, null);
                            }
                          }}
                          onChangeEndDate={(date: any) => {
                            if (
                              dayjs(date).isBefore(watch(`courses.${courseIndex}.timeJoinStart`))
                            ) {
                              const replaceDate = replaceTimeOfDate(
                                date,
                                dayjs(watch(`courses.${courseIndex}.timeJoinStart`)).toDate(),
                              );
                              setValue(
                                `courses.${courseIndex}.timeJoinEnd`,
                                dateToISOString(replaceDate),
                              );
                              return;
                            }
                            setValue(`courses.${courseIndex}.timeJoinEnd`, dateToISOString(date));
                          }}
                          startDateDisabled={handleDisableDataCampaign(courses[courseIndex]?.id)}
                          endDateDisabled={
                            handleDisableDataCampaign(courses[courseIndex]?.id) ||
                            !watch(`courses.${courseIndex}.timeJoinStart`)
                          }
                          minStartDate={minMaxTimeJoinStart(courseIndex).minDate}
                          maxStartDate={minMaxTimeJoinStart(courseIndex).maxDate}
                          minEndDate={minMaxTimeJoinEnd(courseIndex).minDate}
                          relationEndDate={dayjs(
                            watch(`courses.${courseIndex}.timeJoinStart`),
                          ).toDate()}
                          relationStartDate={minMaxTimeJoinStart(courseIndex).minDate}
                        />
                        {watch(`courses.${courseIndex}.subType`) === subTypeOptions[2].value && (
                          <Col md="5" className="px-0">
                            <div className="d-flex justify-content-between">
                              <FormInputNumber
                                name="test-1"
                                label="初日の応募予想数"
                                labelMd="12"
                                colMd="12"
                                classCol="position-relative people-amount"
                                value={watch(`courses.${courseIndex}.countJoinStart`)}
                                onChange={(number) =>
                                  setValue(`courses.${courseIndex}.countJoinStart`, number)
                                }
                                decimalScale={0}
                                isReview={handleDisableDataCampaign(courses[courseIndex]?.id)}
                              />
                              <FormInputNumber
                                name="test-2"
                                label="最終日の応募予想数"
                                labelMd="12"
                                colMd="12"
                                classCol="position-relative people-amount"
                                value={watch(`courses.${courseIndex}.countJoinEnd`)}
                                onChange={(number) =>
                                  setValue(`courses.${courseIndex}.countJoinEnd`, number)
                                }
                                decimalScale={0}
                                isReview={handleDisableDataCampaign(courses[courseIndex]?.id)}
                                pureType={true}
                                allowNegative={false}
                                allowLeadingZeros={false}
                                minValue={1}
                              />
                            </div>
                          </Col>
                        )}
                      </Row>
                    )}
                  </Col>
                </Row>
                <CheckBoxs
                  id={`award-settings-${courseIndex}`}
                  name={`award-settings-${courseIndex}`}
                  labelMd="2"
                  colMd="10"
                  classBoxErr="w-100"
                  checkboxClass="flex-nowrap py-2"
                  classForm="d-flex mb-3"
                  classOption="w-1-3 align-self-start"
                  labelForm="賞の指定可否"
                  value={watch(`courses.${courseIndex}.prizeOption`)}
                  onChange={(option) =>
                    setValue(`courses.${courseIndex}.prizeOption`, +option.value)
                  }
                  options={renderPrizeOptions(courseIndex)}
                  disabled={handleDisableDataCampaign(courses[courseIndex]?.id)}
                />
                {watch(`courses.${courseIndex}.option`) === distributionSettingOptions[1].value && (
                  <FormInputNumber
                    name="ticketPrice"
                    label="応募一口金額"
                    labelMd="2"
                    colMd="2"
                    classForm="mb-5 price-setting-for-1-ticket"
                    value={watch(`courses.${courseIndex}.ticketPrice`)}
                    onChange={(number) => setValue(`courses.${courseIndex}.ticketPrice`, number)}
                    isReview={handleDisableDataCampaign(courses[courseIndex]?.id)}
                  />
                )}
                <Row className="justify-content-end">
                  <Button
                    variant="danger"
                    className="btn-action"
                    disabled={!overview?.isDeletable || getValues('courses').length === 1}
                    onClick={() => openModalConfirm(courseIndex)}
                  >
                    応募コースを削除する
                  </Button>
                </Row>
              </CardWrapper>
            </div>
          );
        })}
        <Container className="py-3">
          <Button
            className="btn-action"
            onClick={handleAddCourse}
            disabled={handleDisabledAddCourse()}
          >
            応募コースを追加する
          </Button>
        </Container>
        <ActionButtons
          handleBack={() => {
            handleSetOldTitleId();
            handleChangeStep(step - 1);
          }}
          handleNext={handleNext}
          handleSaveDraft={async () => handleSaveDraft()}
          courses={watch('courses')}
        />

        <ModalComponent
          show={currentCourse?.visible}
          onCloseModal={() => setCurrentCourse({ visible: false, courseIndex: undefined })}
          classNameContent="d-flex align-items-center content-body-modal justify-content-center"
          title="削除確認"
          confirmText="削除する"
          variantButtonSubmit="danger"
          onSubmitModal={() => {
            handleDeleteCourse(currentCourse?.courseIndex);
            setDisableNext(false);
            clearErrors('courses');
          }}
        >
          <div>
            <p className="mb-2"> 応募コースを削除します。よろしいですか。</p>
          </div>
        </ModalComponent>
      </Form>
    </>
  );
});

export default StepCourseAndMethod;
