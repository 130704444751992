import React from 'react';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';

export interface IMessageRequire {
  message?: string | null;
  classWrapper?: string;
  isWarning?: boolean;
  labelClass?: string;
}
export default function MessageErrorWebApp({
  message,
  classWrapper = 'ms-2',
  isWarning,
  labelClass = 'mt-1',
}: IMessageRequire): JSX.Element {
  if (message) {
    return (
      <div className={classWrapper}>
        <small
          className={`message-error-web-app d-flex align-items-center ${
            isWarning && 'text-dark-red'
          } ${labelClass ?? ''}`}
        >
          <ExclamationTriangleFill />
          <span className="d-block px-1 fs-11 fw-700">{message}</span>
        </small>
      </div>
    );
  }
  return <></>;
}
