import Search from 'Components/Common/Search/Search';
import React, { useEffect, useState } from 'react';
import { Col, Form, Button, Row, Image, Spinner } from 'react-bootstrap';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import searchIcon from 'Static/Images/search-icon.svg';
import useSuggestion from 'Hooks/useSuggestion';

export default function SearchProducts({
  exportExcel,
  loadingExcel,
}: {
  exportExcel?: () => void;
  loadingExcel?: boolean;
}): JSX.Element {
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistId: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
    saleIds: StringParam,
    querySale: StringParam,
    cardCd: StringParam,
    status: StringParam,
    isMaster: StringParam,
  });

  const {
    artistOptions,
    titleOptions,
    saleOptions,
    loadingArtist,
    loadingTitle,
    loadingSale,
    handleLoadMore,
    handleFocusInput,
    handleEnterPrimary,
    handleClickItemPrimary,
  } = useSuggestion({
    validQueryObj: {
      validArtist: {
        artistIds: undefined,
        artistId: undefined,
        queryArtist: undefined,
        queryTitle: undefined,
        titleIds: undefined,
        querySale: undefined,
        saleIds: undefined,
        status: undefined,
      },
    },
    fetchList: ['artist', 'title', 'sale'],
  });

  const [isShowOnlyMaster, setIsShowOnlyMaster] = useState<boolean>(false);
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [saleId, setSaleId] = useState<string>('');

  useEffect(() => {
    if (!query.queryArtist) {
      setSaleId('');
    }
  }, [query.queryArtist]);

  useEffect(() => {
    if (!query.querySale) {
      setSaleId('');
    }
  }, [query.querySale]);

  useEffect(() => {
    setQuery({ ...query, status: isDraft ? '0' : '', isMaster: isShowOnlyMaster ? '1' : null });
  }, [isDraft, isShowOnlyMaster]);

  return (
    <div className="w-100">
      <Row className="px-0">
        <Col md="3" className="pe-0">
          <Search
            labelMd="12"
            label="アーティスト"
            colMd="12"
            placeholder="検索"
            classLabel="px-0"
            rowClass="w-100"
            colClass="px-0"
            searchKey="queryArtist"
            onEnter={() => handleEnterPrimary('queryArtist')}
            onClickResult={handleClickItemPrimary}
            options={artistOptions.options || []}
            loading={loadingArtist}
            onLoadMore={() => handleLoadMore('pageArtist')}
            onFocusInput={() => {
              handleFocusInput('queryArtist', 'pageArtist');
            }}
          />
        </Col>
        <Col md="3" className="pe-0">
          <Search
            labelMd="12"
            colMd="12"
            placeholder="検索"
            label="タイトル"
            classLabel="px-0"
            rowClass="w-100"
            colClass="px-0"
            searchKey="queryTitle"
            onEnter={() => handleEnterPrimary('queryTitle')}
            onClickResult={handleClickItemPrimary}
            options={titleOptions.options || []}
            loading={loadingTitle}
            onLoadMore={() => handleLoadMore('pageTitle')}
            onFocusInput={() => {
              handleFocusInput('queryTitle', 'pageTitle');
            }}
          />
        </Col>
        <Col md="3" className="pe-0">
          <Search
            labelMd="12"
            colMd="12"
            placeholder="検索"
            label="販売チャネル"
            classLabel="px-0"
            rowClass="w-100"
            colClass="px-0"
            searchKey="querySale"
            onEnter={() => handleEnterPrimary('querySale')}
            onClickResult={handleClickItemPrimary}
            options={saleOptions.options || []}
            loading={loadingSale}
            onLoadMore={() => handleLoadMore('pageSale')}
            onFocusInput={() => {
              handleFocusInput('querySale', 'pageSale');
            }}
          />
        </Col>
        <Col md="3" className="position-relative input-search-wrapper pe-0">
          <Image src={searchIcon} className="icon-search position-absolute icon-searchId" />
          <Form.Label column>販売用ID</Form.Label>
          <Form.Control
            className="input-search"
            onBlur={(e) => setQuery({ ...query, cardCd: e.target.value })}
            value={saleId}
            onChange={(e) => setSaleId(e.target.value)}
            placeholder="検索"
            type="search"
          />
        </Col>
      </Row>
      <Col className="d-flex justify-content-end align-items-center mt-3">
        <Form.Check
          type="checkbox"
          id="official"
          label="マスタのみ表示"
          checked={isShowOnlyMaster}
          className="mb-0"
          onChange={(e) => setIsShowOnlyMaster(e.target.checked)}
        />

        <Form.Check
          type="checkbox"
          id="draft"
          label="一時保存含む"
          checked={isDraft}
          className="mb-0 ms-3"
          onChange={(e) => setIsDraft(e.target.checked)}
        />
        <Button
          variant="primary"
          className={`btn-excel w-btn btn-focus-none ms-4 ${loadingExcel ? 'pe-none' : ''}`}
          onClick={exportExcel}
        >
          {loadingExcel ? (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'Excel ダウンロード'
          )}
        </Button>
      </Col>
    </div>
  );
}
