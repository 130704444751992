import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination, Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import MonthCalendar from 'Components/Page/Campaign/RegisterCampaign/Common/Calendar';

const WrappedCalendarSwipper = ({ campaignRelations }: { campaignRelations: any }): JSX.Element => {
  const [keyRender, setKeyRender] = useState(Math.random());

  const renderSlideItem = (dates: any[]) => {
    return dates.map((date) => {
      return (
        <SwiperSlide key={date}>
          <MonthCalendar events={campaignRelations.events} month={date} />
        </SwiperSlide>
      );
    });
  };

  useEffect(() => {
    setKeyRender(campaignRelations.dates.length);
  }, [campaignRelations.dates]);

  return (
    <div className="position-relative">
      <Swiper
        key={keyRender}
        slidesPerView={3}
        spaceBetween={30}
        slidesPerGroup={3}
        allowTouchMove={false}
        loop={false}
        loopFillGroupWithBlank={true}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper w-90"
      >
        {renderSlideItem(campaignRelations.dates)}
      </Swiper>
      <div className="swiper-button-next" />
      <div className="swiper-button-prev" />
    </div>
  );
};

const CalendarSwipper = React.memo(WrappedCalendarSwipper);

export default CalendarSwipper;
