import React, { useCallback, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
// import useMessage from 'Hooks/useMessage';
// import { RecordCompanyApi } from 'Datasource/RecordCompany';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { Cell } from 'react-table';
// import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import { SortMethod } from 'Components/Common/Table/table';
import { Download } from 'react-bootstrap-icons';
import TableBT from 'Components/Common/Table/TableBT';
import { exportExcel } from 'Utils/File';
import { ECApis } from 'Datasource/EC';
import dayjs from 'dayjs';
import useErrorBlob from 'Hooks/useErrorBlob';
import { useAppSelector } from 'App/Store';
import { formatFixedDecimalAndNumb, formatPrice } from 'Utils/Numbers';
import { AppRouter } from 'Types/Router';

interface IValue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface PurchaseSummaryListData {
  orderNumber: string;
  name: string;
  licensePrice: number;
  curPrice: number;
  prevPrice: number;
  buyingRate: number;
  totalPrice: number;
  year: number;
  month: number;
  purchaseTotalPrice: number;
  totalPriceAfterApplyBuyingRate: number;
}
export default function PurchaseSummaryListTable({
  data,
  canDownload,
}: {
  data: Array<PurchaseSummaryListData>;
  canDownload?: boolean;
}): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { openErrorBlob } = useErrorBlob();
  const {
    hashPassword: { hashPassword },
  } = useAppSelector((state) => state);

  // const [page, setPage] = useState<any>(1);
  // const [listRecord, setListRecord] = useState<IValue>([]);
  // const [totalItems, setTotalItems] = useState(0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [query, setQuery] = useQueryParams({
    page: StringParam,
    sort: StringParam,
    order: StringParam,
  });

  const handleDownloadExcel = async (
    companyId: string,
    name: string,
    year: number,
    month: number,
  ) => {
    const formatDate = dayjs(`${year}-${month}`).format('YYMM');
    const fileName = `${formatDate}_miim仕入支払明細_${name.replaceAll(' ', '')}`;
    setIsLoading(true);
    try {
      await exportExcel(
        ECApis.purchaseSummaryExportExcel(companyId, year, month, hashPassword),
        fileName,
      );
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadPDF = async (
    companyId: string,
    name: string,
    year: number,
    month: number,
  ) => {
    const formatDate = dayjs(`${year}-${month}`).format('YYMM');
    const fileName = `${formatDate}_miim仕入支払通知書_${name.replaceAll(' ', '')}`;
    setIsLoading(true);
    try {
      await exportExcel(
        ECApis.purchaseSummaryExportPDF(companyId, year, month, hashPassword),
        fileName,
        'pdf',
      );
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'd-none',
        columns: [
          {
            Header: '伝票番号',
            accessor: 'orderNumber',
            width: '160',
            className: 'lg-fs-12px lg-header-table-padding-3px',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <Link
                  to={{
                    pathname: `/ec${AppRouter.SUMMARY_PURCHASE_DETAIL}/${row.original.companyId}`,
                    search: `?year=${row.original.year}&month=${row.original.month}&companyName=${row.original?.name}`,
                  }}
                >
                  <div className="text-truncate text-start payment-advice-padding lg-fs-10px">
                    {row.original?.orderNumber}
                  </div>
                </Link>
              );
            },
          },
          {
            Header: 'レコード会社',
            accessor: 'name',
            width: '225',
            className: 'lg-fs-12px lg-header-table-padding-3px',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-truncate payment-advice-padding lg-fs-10px">
                  {row.original?.name}
                </div>
              );
            },
          },
          {
            Header: '販売金額',
            accessor: 'purchaseTotalPrice',
            width: '120',
            className: 'lg-fs-12px lg-header-table-padding-3px',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-truncate text-right line-33px mr-6px lg-fs-10px">
                  {row.original.purchaseTotalPrice &&
                    formatPrice(formatFixedDecimalAndNumb(row.original.purchaseTotalPrice, 2))}
                </div>
              );
            },
          },
          {
            Header: '仕入率',
            accessor: 'buyingRate',
            width: '100',
            className: 'lg-fs-12px lg-header-table-padding-3px',
            disableSortBy: true,
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-truncate text-right line-33px mr-6px lg-fs-10px">
                  {row.original?.buyingRate && `${row.original?.buyingRate}%`}
                </div>
              );
            },
          },
          {
            Header: '仕入金額',
            accessor: 'totalPriceAfterApplyBuyingRate',
            width: '120',
            className: 'lg-fs-12px lg-header-table-padding-3px',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-truncate text-right line-33px mr-6px lg-fs-10px">
                  {row.original.totalPriceAfterApplyBuyingRate &&
                    formatPrice(
                      formatFixedDecimalAndNumb(row.original.totalPriceAfterApplyBuyingRate, 2),
                    )}
                </div>
              );
            },
          },
          {
            Header: '著作権使用料',
            accessor: 'licensePrice',
            width: '120',
            className: 'lg-fs-12px lg-header-table-padding-3px',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-truncate text-right line-33px mr-6px lg-fs-10px">
                  {row.original.licensePrice &&
                    formatPrice(formatFixedDecimalAndNumb(row.original.licensePrice, 2))}
                </div>
              );
            },
          },
          {
            Header: '前月繰越金',
            accessor: 'prevPrice',
            width: '120',
            className: 'lg-fs-12px lg-header-table-padding-3px',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-truncate text-right line-33px mr-6px lg-fs-10px">
                  {row.original.prevPrice &&
                    formatPrice(formatFixedDecimalAndNumb(row.original.prevPrice, 2))}
                </div>
              );
            },
          },
          {
            Header: '当月繰越金',
            accessor: 'curPrice',
            width: '120',
            className: 'lg-fs-12px lg-header-table-padding-3px',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-truncate text-right line-33px mr-6px lg-fs-10px">
                  {row.original.curPrice &&
                    formatPrice(formatFixedDecimalAndNumb(row.original.curPrice, 2))}
                </div>
              );
            },
          },
          {
            Header: '支払額',
            accessor: 'totalPrice',
            width: '120',
            className: 'lg-fs-12px lg-header-table-padding-3px',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-truncate text-right line-33px mr-6px lg-fs-10px">
                  {row.original.totalPrice &&
                    formatPrice(formatFixedDecimalAndNumb(row.original.totalPrice, 2))}
                </div>
              );
            },
          },
          {
            Header: '通知書PDF',
            accessor: 'notificationPDF',
            width: '120',
            className: 'lg-fs-12px lg-header-table-padding-3px',
            disableSortBy: true,
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div
                  className={`text-truncate text-center payment-advice-padding lg-fs-10px ${
                    canDownload === true ? '' : 'pe-none disable h-100'
                  } `}
                >
                  <Link
                    to="#"
                    onClick={() =>
                      handleDownloadPDF(
                        row.original?.companyId,
                        row.original?.name,
                        row.original?.year,
                        row.original?.month,
                      )
                    }
                  >
                    <Download className="mr-4px"></Download>DL
                  </Link>
                </div>
              );
            },
          },
          {
            Header: '明細EXCEL',
            accessor: 'detailExcel',
            width: '120',
            className: 'lg-fs-12px lg-header-table-padding-3px',
            disableSortBy: true,
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div
                  className={`text-truncate text-center payment-advice-padding lg-fs-10px h-100`}
                >
                  <Link
                    to="#"
                    onClick={() =>
                      handleDownloadExcel(
                        row.original?.companyId,
                        row.original?.name,
                        row.original?.year,
                        row.original?.month,
                      )
                    }
                  >
                    <Download className="mr-4px"></Download>DL
                  </Link>
                </div>
              );
            },
          },
        ],
      },
    ],
    [canDownload],
  );

  const handleManualSort = useCallback((sortBy: SortMethod[]) => {
    if (sortBy.length > 0) {
      const sortOption = sortBy[0];
      setQuery({ sort: sortOption.id, order: sortOption.desc ? 'DESC' : 'ASC' });
    }
  }, []);

  return (
    <div className="overflow-hidden mt-4">
      <Container fluid className="px-4">
        <SpinnerComponent isLoading={isLoading} />
        {data && (
          <TableBT
            heightBody="calc(100vh - 250px)"
            columns={columns}
            data={data}
            getCellProps={() => ({
              className: 'p-0',
            })}
            manualSortBy
            onSort={handleManualSort}
          />
        )}
      </Container>
    </div>
  );
}
