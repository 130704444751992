import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Tabs from 'Components/Common/Tabs/Tabs';
import Filter from 'Components/Common/Filter/Filter';
import WebSetting from './WebSetting';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import CommercialLaw from './CommercialLaw';

export default function WebSettingAndPolicy(): JSX.Element {
  const btnGroupOptions = [
    { name: 'サイト基本設定' },
    { name: '利用規約' },
    { name: 'プライバシーポリシー' },
    { name: '特定商取引法に基づく表示' },
  ];
  const [tabActive, setTabActive] = useState(btnGroupOptions[0].name);

  return (
    <Container fluid>
      <Filter filterClass={`shadow-sm `}>
        <Tabs
          options={btnGroupOptions}
          tabActive={tabActive}
          onChange={setTabActive}
          btnClass="web-setting-tab-width"
        />
      </Filter>
      <div>
        {tabActive === btnGroupOptions[0].name ? (
          <WebSetting />
        ) : tabActive === btnGroupOptions[1].name ? (
          <TermsOfService />
        ) : tabActive === btnGroupOptions[2].name ? (
          <PrivacyPolicy />
        ) : (
          <CommercialLaw />
        )}
      </div>
    </Container>
  );
}
