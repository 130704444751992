import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { COLOR_PIE_CHART } from 'Constant/Campaign';
import { truncateText } from 'Utils/Title';
import { Col, OverlayTrigger, Row, Tooltip as BootstrapTooltip } from 'react-bootstrap';

export default function PieChart({
  dataChart,
  onlyLimited,
}: {
  dataChart: { name: string; rate: number }[];
  onlyLimited: boolean;
}): JSX.Element {
  const getBackGroundColors = () => {
    const dataChartWins = onlyLimited ? dataChart : dataChart.slice(0, dataChart.length - 1);
    const colorWins = COLOR_PIE_CHART.slice(0, COLOR_PIE_CHART.length - 1);
    const backgrounds = dataChartWins.map((_, key: number) => {
      return colorWins[Number(key) % colorWins.length];
    });

    return [...backgrounds, ...[COLOR_PIE_CHART[COLOR_PIE_CHART.length - 1]]];
  };

  const data = {
    labels: dataChart.map((item) => truncateText(item.name, 32)),
    datasets: [
      {
        label: '# of Votes',
        data: dataChart.map((item) => item.rate),
        backgroundColor: getBackGroundColors(),
        borderColor: '#FFFFFF',
        borderWidth: 1,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem: any) {
            return tooltipItem.yLabel;
          },
        },
      },
    },
    responsive: true,
  };

  ChartJS.register(ArcElement, Tooltip, Legend);
  return (
    <div className="pie-chart-prize">
      <Row className="py-3">
        <Col
          md="12"
          style={{
            maxHeight: '200px', // Set a maximum height for the legend container
            overflowY: 'auto', // Enable vertical scrollbar when content overflows
          }}
          className="mb-3"
        >
          <div className="d-flex flex-wrap">
            {data.labels.map((label, index) => (
              <OverlayTrigger
                key={index}
                overlay={<BootstrapTooltip id={label}>{label}</BootstrapTooltip>}
              >
                <div
                  style={{
                    marginBottom: '5px',
                    display: 'inline-flex',
                    maxWidth: '110px',
                    width: '100%',
                  }}
                  className="align-items-center"
                >
                  <span
                    style={{
                      backgroundColor: data.datasets[0].backgroundColor[index],
                      width: '40px',
                      height: '12px',
                      marginRight: '5px',
                      display: 'block',
                      padding: '0 20px',
                    }}
                  />

                  <span className="text-truncate" style={{ width: 'calc(100% - 40px)px' }}>
                    {label}
                  </span>
                </div>
              </OverlayTrigger>
            ))}
          </div>
        </Col>
        <Col md="12">
          <Pie data={data} options={options as any} />
        </Col>
      </Row>
    </div>
  );
}
