/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';
import { omit } from 'lodash';

const getFormData = (object: any) => {
  if (object?.type === 1) {
    object = omit(object, [
      'cdId',
      'cdName',
      'quantity',
      'titleId',
      'titleName',
      'artistId',
      'artistName',
    ]);
  }
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
};

export const SerialApis = {
  getSerials: ({
    limit,
    page,
    artistId,
    type,
    query,
    useCampaign,
    sort,
    order,
  }: {
    limit?: number;
    page?: number | null;
    artistId?: null | string | undefined;
    type?: null | string | undefined;
    query?: string;
    useCampaign?: number;
    sort?: string;
    order?: string;
  }) =>
    brAxios.get(
      `/record-company/serial-number/sns?page=${page ?? 1}&limit=${limit ?? 20}${
        artistId ? `&artistIds=${artistId}` : ''
      }${type ? `&type=${type}` : ''}${query ? `&query=${query}` : ''}${
        useCampaign ? `&useCampaign=${useCampaign}` : ''
      }&sort=${sort || 'id'}&order=${order || 'DESC'}`,
    ),
  createSerial: ({ params }: { params: any }) =>
    brAxios.postFormData('record-company/serial-number/create-sn', getFormData(params)),

  updateSerial: ({ params }: { params: any }) =>
    brAxios.putFormData('record-company/serial-number/update-sn', params),

  getCdByTitle: ({
    titleId,
    page,
    limit,
    query,
  }: {
    titleId: null | number | string | undefined;
    limit?: number;
    page?: number | null;
    query?: string | null;
  }) =>
    brAxios.get(
      `/record-company/serial-number/cds?page=${page ?? 1}&limit=${limit ?? 20}${
        titleId ? `&titleId=${titleId}` : ''
      }${query ? `&query=${query}` : ''}`,
    ),
  exportCSV: ({ id }: { id: null | number | string | undefined }) =>
    brAxios.get(`record-company/serial-number/sn/${id}/export`),
  deleteSerialNumber: ({ id }: { id: string | number | undefined }) =>
    brAxios.delete(`record-company/serial-number/delete-sn/${id}`),

  getSerialNumber: ({ id }: { id: string | number | undefined }) =>
    brAxios.get(`record-company/serial-number/sn/${id}`),
};
