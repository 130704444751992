import brAxios from 'Api/Interceptors';
import { IDataFormTokutenUploads } from 'Types/Common';

interface IQueryParams {
  page?: number;
  limit?: number;
  artistId?: string;
  titleId?: string;
  variationId?: string;
  time?: string;
  gmt?: number;
  startDate?: string;
  endDate?: string;
}

export const PromotionMangagerApi = {
  createPromotion: (formData: IDataFormTokutenUploads) =>
    brAxios.post('/record-company/digital-tokutens', formData),

  getAllPromotions: (params: IQueryParams) => {
    const response = brAxios.get(`/record-company/digital-tokutens`, { params });
    return response;
  },
  getDetailPromotion: (digitalID: string) => {
    const responseDetail = brAxios.get(`/record-company/digital-tokutens/${digitalID}`);
    return responseDetail;
  },

  updatePromotion: (digitalID: string, formData: IDataFormTokutenUploads) => {
    const responseUpdate = brAxios.put(`/record-company/digital-tokutens/${digitalID}`, formData);
    return responseUpdate;
  },

  exportExcel: (params: IQueryParams) => {
    const response = brAxios.get(`/record-company/digital-tokutens/export/download`, {
      params,
      responseType: 'blob',
    });
    return response;
  },
};
