import React, { useMemo } from 'react';
import { SALES_STATUS } from 'Constant/EC';
interface ECListSaleStatusProps {
  status?: number | string;
}

export default function ECListSaleStatus({ status }: ECListSaleStatusProps): JSX.Element {
  const foundSaleSatus: any = useMemo(() => {
    return SALES_STATUS.find((s) => s.value == status) || {};
  }, [status]);

  return (
    <div
      className="ec-salestatus-tag"
      style={{
        backgroundColor: foundSaleSatus.bgColor,
        border: `1px solid ${foundSaleSatus.borderColor}`,
      }}
    >
      <span>{foundSaleSatus?.label}</span>
    </div>
  );
}
