/* eslint-disable @typescript-eslint/no-unused-vars */
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import _, { includes, ceil } from 'lodash';
import { getNameBrower } from './Brower';
import AudioSafari from 'Static/Images/audio-safari.png';
export const fileToURL = (file: File): Promise<string> =>
  new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target && typeof e.target.result === 'string') resolve(e.target.result);
    };
    reader.readAsDataURL(file);
  });

export function checkIfFilesAreTooBig(file?: File | string): boolean {
  if (typeof file === 'string') {
    return true;
  }
  let valid = true;
  if (file) {
    const size = file?.size / 1024 / 1024;

    if (size > 2) {
      valid = false;
    }
  }
  return valid;
}

export function checkIfFilesAreCorrectType(file?: File | string): boolean {
  if (typeof file === 'string') {
    return true;
  }
  let valid = true;
  if (file) {
    const type = file.type;
    if (!['image/jpeg', 'image/gif', 'image/png', 'application/octet-stream'].includes(type)) {
      valid = false;
    }
  }
  return valid;
}

interface GetSizeImageProps {
  width?: number;
  height?: number;
}

export const getSizeImage = (urlImg: string): Promise<GetSizeImageProps> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = urlImg;
    img.onload = function () {
      resolve({ width: img.width, height: img.height });
    };
  });
};

export const checkImgSquare = async (files: File | string): Promise<boolean> => {
  if (typeof files === 'string') {
    return true;
  }
  let valid = true;
  if (files && checkIfFilesAreCorrectType(files)) {
    const urlImg: string = URL.createObjectURL(files);
    const sizeImg: GetSizeImageProps = await getSizeImage(urlImg);
    valid = sizeImg.width === sizeImg.height;
  }
  return valid;
};

export const checkImgSquareAndDimension = async (
  files: File | string,
  dimension: number,
): Promise<boolean> => {
  if (typeof files === 'string') {
    return true;
  }
  let valid = true;
  if (files && checkIfFilesAreCorrectType(files)) {
    const urlImg: string = URL.createObjectURL(files);
    const sizeImg: GetSizeImageProps = await getSizeImage(urlImg);
    valid = sizeImg.width === sizeImg.height && sizeImg.width === dimension;
  }

  return valid;
};

export const checkTypeFile = (file: File, types: string[]): boolean => {
  let valid = false;
  if (_.includes(types, file.type)) {
    valid = true;
  }
  return valid;
};

export const checkFileSize = (files: File, minSize: number, maxSize: number): boolean => {
  let valid = true;
  if (files) {
    const size = files?.size / 1024 / 1024;
    if (size > maxSize || size < minSize) {
      valid = false;
    }
  }
  return valid;
};

export const exportExcel = async (api: any, nameFile: string, nameType = 'xlsx') => {
  const response = await api;

  if (response) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${nameFile}.${nameType}`);
    document.body.appendChild(link);
    link.click();
  }
};

export const calcMb = (bitSize: number): number => Number((bitSize / 1024 / 1024).toFixed(2));

export const isReviewTitle = (namefield: string, variation: any) => {
  const dateNow = new Date();
  const dateBooking = new Date(variation?.saleTokenDate);
  const dateDownload = new Date(variation?.downloadDate);
  if (!variation?.id || (variation?.id && variation?.id?.toString()?.includes('uuid')))
    return false;
  if (dateNow < dateBooking) {
    return false;
  }
  if (dateNow > dateBooking && dateNow < dateDownload) {
    if (
      includes(
        ['artistId', 'option', 'saleTokenDate', 'productCode', 'janCode', 'price', 'nameTitle'],
        namefield,
      )
    ) {
      return true;
    }
    return false;
  }
  if (dateNow > dateDownload) {
    return true;
  }
};

export const isAfterDownload = (variation: any): boolean => {
  if (!variation?.id || (variation?.id && variation?.id?.toString()?.includes('uuid')))
    return false;
  const dateNow = new Date();
  const dateDownload = new Date(variation?.downloadDate);
  return dateNow > dateDownload;
};

export const bytesToMb = (bytes: number | string): number | string => {
  return (Number(bytes) / 1000000).toFixed(2);
};

function getBase64Image(img: HTMLImageElement) {
  try {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d')!;
    ctx.drawImage(img, 0, 0, img.width, img.height);
    ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  } catch (err) {
    console.error(err);
    return '';
  }
}

const generateVideoThumbnail = (video: HTMLVideoElement) => {
  //TODO: EXPORT PDF
  let dataURL = '';
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  ctx!.filter = 'sepia(100%)';
  video.muted = true;
  video.play().then(anim);

  function anim() {
    requestAnimationFrame(anim);
    ctx?.drawImage(video, 0, 20, 400, 224);
  }

  dataURL = canvas.toDataURL('image/png');
  return dataURL;
};

const createImageFromVideo = (video: any) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  ctx?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  return canvas;
};

export const loadVideoEvent = (video: any) => {
  return new Promise((resolve) => {
    let ended = false;
    let url = '';
    function loadTime() {
      if (!ended) {
        video.currentTime = 2;
      }
    }
    video.addEventListener(
      'loadeddata',
      function () {
        loadTime();
      },
      false,
    );

    video.addEventListener(
      'seeked',
      function () {
        const canvas = createImageFromVideo(video);
        url = canvas.toDataURL('image/png');
        ended = true;
        resolve(url);
      },
      false,
    );
  });
};

export const drawnCanvas = (id: string, videoImgs: string[]) => {
  const reviewPage = document.getElementById(id);
  return html2canvas(reviewPage!, {
    foreignObjectRendering: true,
    scale: 1,
    scrollY: 0,
    scrollX: 0,
    // width: window.devicePixelRatio * reviewPage.clientWidth,
    // height: window.devicePixelRatio * reviewPage.clientHeight,
    // windowWidth: window.devicePixelRatio * reviewPage.clientWidth,
    // windowHeight: window.devicePixelRatio * reviewPage.clientHeight,
    logging: true,
    useCORS: true,
    allowTaint: true,
    ignoreElements: function (el) {
      //* safari not clone video
      return getNameBrower() === 'safari' && el.tagName.toLowerCase() === 'video';
    },
    onclone: (doc) => {
      const ele = doc.getElementById(id);
      const images: NodeListOf<HTMLImageElement> = doc.querySelectorAll('img.img-prize-base64');

      images.forEach((img) => {
        const img64 = getBase64Image(img);
        img.src = img64;
      });

      //* ignore loop videos safari
      if (getNameBrower() !== 'safari') {
        const videos: NodeListOf<HTMLVideoElement> = doc.querySelectorAll(
          'video.video-prize-base64',
        );
        videos.forEach((video, index) => {
          const newElement = doc.createElement('img');
          newElement.setAttribute('src', videoImgs[index]);
          newElement.style.maxWidth = '250px';
          newElement.style.maxHeight = '142px';
          newElement.style.display = 'block';
          newElement.setAttribute('crossOrigin', '');
          video.parentNode?.replaceChild(newElement, video);
        });
      } else {
        const audios: NodeListOf<HTMLVideoElement> = doc.querySelectorAll(
          'audio.audio-prize-base64',
        );
        audios.forEach((audio) => {
          const newElement = doc.createElement('img');
          newElement.src = AudioSafari;
          audio.parentNode?.replaceChild(newElement, audio);
        });
      }

      if (ele) {
        ele.style.setProperty('position', 'fixed', 'important');
        ele.style.setProperty('top', '0', 'important');
        ele.style.setProperty('left', '0', 'important');
        ele.style.setProperty('margin', '0', 'important');
        ele.style.setProperty('display', 'flex', 'important');
        ele.style.setProperty('flex-direction', 'column', 'important');
        ele.style.setProperty('justify-content', 'center', 'important');
        ele.style.setProperty('align-items', 'center', 'important');
      }
    },
  });
};
export const exportPDF = async (id: string, videoImgs: string[]) => {
  const reviewPage = document.getElementById(id);
  //* create box support height for pdf
  const supportDiv = document.getElementById('sp-div');
  const tableResponsives: NodeListOf<HTMLDivElement> =
    document.querySelectorAll('div.table-responsive');

  if (supportDiv) {
    supportDiv.setAttribute('class', 'd-block');
  }

  if (tableResponsives) {
    tableResponsives.forEach((item) => {
      item.style.overflowX = 'hidden';
    });
  }

  if (reviewPage) {
    await drawnCanvas(id, videoImgs).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const width = (window.devicePixelRatio * reviewPage.clientWidth * 9) / 16;
      const height = (window.devicePixelRatio * reviewPage.clientHeight * 9) / 16;
      const orientation = width > height ? 'landscape' : 'portrait';
      const pdf = new jsPDF(orientation, 'px', [
        reviewPage.clientWidth < 1300 ? width / 2 : width,
        reviewPage.clientWidth < 1300 ? height / 2 : height,
      ]);

      pdf.addImage(imgData, 'JPEG', 0, 0, 0, 0);
      pdf.save('download.pdf');
      //* hidden box support height for pdf
      if (supportDiv) {
        supportDiv.setAttribute('class', 'd-none');
      }

      if (tableResponsives) {
        tableResponsives.forEach((item) => {
          item.style.overflowX = 'auto';
        });
      }
    });
  }
};
