/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';
import { SectionItem } from 'Page/CompanyInfo/SessionManage';
import { transformQuery } from 'Utils/Params';

interface IQueryParams {
  startDate?: any;
  endDate?: any;
  titleId?: string | number;
  artistId?: string | number;
  variationId?: string | number;
  isOption?: string | number;
}

export const RecordCompanyApi = {
  getSections: () => brAxios.get('/record-company/sections'),

  addSections: (params: SectionItem) => brAxios.post('/record-company/sections', params),

  updateSections: (params: SectionItem) => brAxios.put('/record-company/sections', params),

  deleteSections: (params: SectionItem) =>
    brAxios.delete('/record-company/sections', { data: params }),

  getRecordCompany: () => brAxios.get('/record-company/information/get-config'),

  getAllReportPurchase: (params: any) => {
    const strParam = transformQuery(params);
    const response = brAxios.get(`/record-company/accounting-report/report-purchase${strParam}`);
    return response;
  },

  exportExcelPurchase: (params: IQueryParams) => {
    const response = brAxios.get('/record-company/accounting-report/report-purchase/export-excel', {
      params,
      responseType: 'blob',
    });
    return response;
  },

  getAllReportCost: (params: any) => {
    const strParam = transformQuery(params);
    const response = brAxios.get(`/record-company/accounting-report/report-cost${strParam}`);
    return response;
  },

  exportExcelCost: (params: IQueryParams) => {
    const response = brAxios.get('/record-company/accounting-report/report-cost/export-excel', {
      params,
      responseType: 'blob',
    });
    return response;
  },
};
