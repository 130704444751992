import React from 'react';
import Form from './FormResetPassword';
import { Container, Image } from 'react-bootstrap';
import BrLogo from 'Static/Images/br-logo.png';
import Logo from 'Static/Images/logo.png';

export default function ResetPassword(): JSX.Element {
  return (
    <div className="bg-public reset-password-wrapper">
      <Container className="d-flex flex-column justify-content-center align-items-center vh-100 text-white reset-password-content">
        <h1 className="text-center mb-5">
          <Image src={Logo} />
        </h1>
        <Form />
      </Container>
      <div className="w-100 text-center p-2">
        <img src={BrLogo} alt="bg logo" className="w-max-content" />
        <p className="my-2 fs-12">Copyright ©2019 BootRock Inc. All rights reserved.</p>
      </div>
    </div>
  );
}
