import { find } from 'lodash';
import { matchPath } from 'react-router-dom';
import * as H from 'history';

interface RouteInfo {
  name: string;
  title: string;
  parent: string;
}

const dynamicRoutes = [
  { name: '/digital-tokuten/:id', title: 'デジタル特典登録', parent: '/digital-tokuten' },
  { name: '/artist/detail-artist/:id', title: 'アーティスト詳細', parent: '/artist' },
  { name: '/title-detail/:id', title: 'タイトル登録', parent: '/title/list' },
  { name: '/register-campaign', title: 'キャンペーン登録', parent: '/campaign-list' },
  { name: '/campaigns/:id', title: 'キャンペーンを編集', parent: '/campaign-list' },
  {
    name: '/statistic-campaign/:campaignId',
    title: '応募状況',
    parent: '/campaign-list',
  },
  { name: '/create-serial-number', title: 'シリアルコード登録', parent: '/serial-numbers' },
  { name: '/serial-numbers/:id', title: 'シリアルコード詳細', parent: '/serial-numbers' },
  {
    name: '/create-cd-master',
    title: 'CDマスタ登録',
    parent: '/cd-master',
  },
  {
    name: '/cd-master/:id',
    title: 'CDマスタ詳細',
    parent: '/cd-master',
  },
  { name: '/products-detail/:id', title: '販売商品詳細', parent: '/products-detail' },
  { name: '/create-products/:id', title: 'キャンペーン商品を作成', parent: '/list-products' },

  //ec
  {
    name: '/ec/sale-product-detail/:id',
    title: 'EC販売商品詳細',
    parent: '/ec/list-sale-products',
  },
  {
    name: '/ec/purchase-detail/:id',
    title: 'EC購入データ詳細',
    parent: '/ec/list-purchase',
  },
  {
    name: '/ec/payment-advice-detail/:id',
    title: 'EC取引明細',
    parent: '/ec/payment-advice-list',
  },

  {
    name: '/ec/purchase-summary-detail/:id',
    title: 'EC仕入明細',
    parent: '/ec/purchase-summary-list',
  },
];

export const handleCheckDynamicRoutes = (location: H.Location): RouteInfo | undefined => {
  const matchRoute = find(dynamicRoutes, (rt) => {
    const match = matchPath(location.pathname, {
      path: rt.name,
      exact: true,
      strict: true,
    });
    return !!match;
  });
  return matchRoute;
};
