/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useTable, useSortBy, useBlockLayout } from 'react-table';
import { includes } from 'lodash';
import { SortMethod, TableProps } from 'Components/Common/Table/table';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { isEmpty } from 'lodash';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

const defaultPropGetter = () => ({});
const defaultGetHeaderProps = () => ({
  className: 'text-white d-flex align-item-center bg-dark text-center',
});
const defaultGetCellProps = () => ({
  className: 'text-center bg-white text-dark',
});

const TableEC = ({
  columns,
  data,
  heightBody,
  classTable,
  isNotShowSort,
  getHeaderProps = defaultGetHeaderProps,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultGetCellProps,
  bordered = true,
  messageNoData = '商品が登録されていません',
  manualSortBy = false,
  onSort,
}: TableProps): JSX.Element => {
  const [query] = useQueryParams({
    sort: StringParam,
    order: withDefault(StringParam, 'DESC', true),
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      manualSortBy: manualSortBy,
      initialState: {
        sortBy: query.sort ? [{ id: query.sort, desc: query.order === 'DESC' }] : [],
      },
    },
    useSortBy,
    useBlockLayout,
  );
  const __headerGroups = [...headerGroups];

  //* for manual sort from server side
  useEffect(() => {
    if (manualSortBy && onSort) {
      onSort(sortBy as SortMethod[]);
    }
  }, [manualSortBy, onSort, sortBy]);

  const handleRenderSortIcon = (sortby = '') => {
    return (
      <>
        <CaretUpFill className={sortby === 'asc' ? 'text-primary' : ''} />
        <CaretDownFill className={sortby === 'desc' ? 'text-primary' : ''} />
      </>
    );
  };

  const hideIconSort = (id = '') => {
    return includes(['empty', 'salesAmount', 'numberOfSheetsSold'], id);
  };

  return (
    <>
      {!isEmpty(data) ? (
        <Table
          className={`${classTable ?? ''} table-radius`}
          bordered={bordered}
          responsive
          {...getTableProps()}
        >
          <thead style={{ display: 'block' }}>
            {__headerGroups.map((headerGroup, headerGroupKey) => {
              return (
                <React.Fragment key={headerGroupKey}>
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any, columnKey) => {
                      return (
                        <React.Fragment key={columnKey}>
                          <th
                            {...column.getHeaderProps([
                              {
                                className: (column as any).className,
                                style: (column as any).style,
                              },
                              getColumnProps(column),
                              getHeaderProps(column),
                              !isNotShowSort && column.getSortByToggleProps(),
                            ])}
                            title={!isNotShowSort && 'Toggle SortBy'}
                          >
                            <div className="w-100">{column.render('Header')}</div>
                            {isNotShowSort || (
                              <div
                                className={`${
                                  column?.accessor || 'd-none'
                                } fs-12 d-flex flex-column`}
                              >
                                {column.isSorted || query.sort === column.id
                                  ? column.isSortedDesc ||
                                    (query.order === 'DESC' && query.sort === column.id)
                                    ? handleRenderSortIcon('desc')
                                    : handleRenderSortIcon('asc')
                                  : hideIconSort(column.originalId)
                                  ? ''
                                  : handleRenderSortIcon()}
                              </div>
                            )}
                          </th>
                        </React.Fragment>
                      );
                    })}
                  </tr>
                </React.Fragment>
              );
            })}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="hidden-scroll-nft-table"
            style={{
              display: 'block',
              overflowX: 'hidden',
              overflowY: 'auto',
              height: heightBody,
            }}
          >
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={i}>
                  <tr {...row.getRowProps(getRowProps(row))}>
                    {row.cells.map((cell, cellKey) => {
                      return (
                        <React.Fragment key={cellKey}>
                          <td
                            {...cell.getCellProps([
                              {
                                className: (cell.column as any).className,
                                style: (cell.column as any).style,
                              },
                              getColumnProps(cell.column),
                              getCellProps(cell),
                            ])}
                          >
                            {cell.render('Cell')}
                          </td>
                        </React.Fragment>
                      );
                    })}
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className="vh-100-final d-flex align-items-center justify-content-center">
          {messageNoData}
        </div>
      )}
    </>
  );
};

export default TableEC;
