import { useAppSelector } from 'App/Store';
import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { Table as TableContainer, TableProps as TablePropsContainer } from 'react-bootstrap';
import { TableColumns, TableDataSource } from 'Types/Common/Table';
// import MessageError from 'Components/Common/MessageError';

interface TableProps extends TablePropsContainer {
  tableKey: string;
  columns: TableColumns[];
  dataSource: TableDataSource[] | undefined;
  errors?: { message: string }[];
  onClickError?: () => void;
}

function Table({
  tableKey,
  columns,
  dataSource,
  errors = [],
  onClickError,
  ...props
}: PropsWithChildren<TableProps>): JSX.Element {
  const songsRef = useRef<any>([]);
  const { triggerMedia } = useAppSelector((state) => state.title);

  useEffect(() => {
    if (triggerMedia === 'songs' && errors.length > 0) {
      const newListError = errors
        .map((item: any, index: number) => ({ ...item, index: index }))
        .filter((item: any) => !!item);
      songsRef?.current[newListError[0].index]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
  }, [errors, triggerMedia]);

  return (
    <TableContainer {...props} key={tableKey}>
      <thead>
        <tr>
          {columns.map(({ title, key, headerClass }, index) => (
            <th key={key} className={`${index === 0 ? 'text-center' : ''} ${headerClass}`}>
              {title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataSource?.map((item, index) => (
          <>
            <tr key={index} ref={(el) => (songsRef.current[index] = el)}>
              {columns.map((col, k) => (
                <td
                  key={`${col.key}-${index}`}
                  className={`align-middle ${k === 0 ? 'text-center' : ''} ${
                    k === columns.length - 1 ? 'text-end' : ''
                  } ${col.rowClass}`}
                >
                  {col.render ? (
                    col.dataIndex === 'index' ? (
                      index + 1
                    ) : (
                      col.render(item[col.dataIndex], item, index)
                    )
                  ) : (
                    <p>{item[col.dataIndex]}</p>
                  )}
                </td>
              ))}
            </tr>
            {errors[index] && (
              <tr onClick={onClickError}>
                {/* <MessageError message={errors[index].message} classWrapper="ms-2 w-max-content" /> */}
              </tr>
            )}
          </>
        ))}
      </tbody>
    </TableContainer>
  );
}
export default Table;
