import ButtonNextMonth from 'Components/Common/ButtonNextMonth';
import DateSelector from 'Components/Common/DateSelector';
import Search from 'Components/Common/Search/Search';
// import { DATE_FORMAT_7 } from 'Constant/Date';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { IDatePicker } from 'Types/Common';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { ROLE_RECORD_MENU } from 'Constant/Roles';
import { useAppSelector } from 'App/Store';
import MessageError from 'Components/Common/MessageError';

import useSuggestion from 'Hooks/useSuggestion';
interface SearchRevenueProps {
  isMonth?: boolean;
  onExportExcel?: () => void;
  loadingExcel?: boolean;
}

export default function SearchRevenue({
  isMonth = true,
  onExportExcel,
  loadingExcel,
}: SearchRevenueProps): JSX.Element {
  const { role } = useAppSelector((state) => state.auth);

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    variationIds: StringParam,
    queryCompany: StringParam,
    companyId: StringParam,
    companyIds: StringParam,
    isOption: NumberParam,
    queryVariation: StringParam,
    artistId: StringParam,
    titleId: StringParam,
    variationId: StringParam,
  });

  const isMiimEcOrExternalEc = useMemo(() => {
    if (
      role &&
      (role === ROLE_RECORD_MENU.ECCompMaster ||
        role === ROLE_RECORD_MENU.ECCompManager ||
        role === ROLE_RECORD_MENU.SALEChanelMaster ||
        role === ROLE_RECORD_MENU.SALEChanelManager)
    ) {
      return true;
    }
    return false;
  }, [role]);

  const {
    companyOptions,
    artistOptions,
    titleOptions,
    variationOptions,
    loadingArtist,
    loadingTitle,
    loadingVariation,
    loadingCompany,
    handleLoadMore,
    handleFocusInput,
    handleEnterPrimary,
    handleClickItemPrimary,
  } = useSuggestion({
    fetchList: isMiimEcOrExternalEc
      ? ['company', 'artist', 'title', 'variation']
      : ['artist', 'title', 'variation'],
    showDraftVariation: true,
  });

  const onChangeDate = (date: any, type: string) => {
    if (!date) {
      setQuery({ [type]: '', page: 1 });
      return;
    }
    setQuery({ [type]: dayjs(date), page: 1 });
  };

  const onNextMonth = (e: any) => {
    setQuery({
      startDate: dayjs(e.startDate).toString(),
      endDate: dayjs(e.endDate).toString(),
    });
    // setQuery({
    //   startDate: `${dayjs(e.startDate).format(DATE_FORMAT_7)}`,
    //   endDate: `${dayjs(e.endDate).format(DATE_FORMAT_7)}`,
    // });
  };

  return (
    <>
      <Row className="align-items-end px-0">
        <Col md="10">
          <Row>
            {isMiimEcOrExternalEc && (
              <Col md="2" className="px-1 pe-3">
                <Search
                  label="レコード会社"
                  labelMd="12"
                  colMd="12"
                  placeholder="検索"
                  showIcon
                  classLabel="px-0"
                  rowClass="w-100"
                  colClass="px-0"
                  searchKey="queryCompany"
                  onEnter={() => handleEnterPrimary('queryCompany')}
                  onClickResult={handleClickItemPrimary}
                  options={companyOptions.options || []}
                  loading={loadingCompany}
                  onLoadMore={() => handleLoadMore('pageCompany')}
                  onFocusInput={() => {
                    handleFocusInput('queryCompany', 'pageCompany');
                  }}
                />
              </Col>
            )}
            <Col md="2" className="px-1 pe-3">
              <Search
                label="アーティスト"
                labelMd="12"
                colMd="12"
                placeholder="検索"
                showIcon
                classLabel="px-0"
                rowClass="w-100"
                colClass="px-0"
                searchKey="queryArtist"
                onEnter={() => handleEnterPrimary('queryArtist')}
                onClickResult={handleClickItemPrimary}
                options={artistOptions.options || []}
                loading={loadingArtist}
                onLoadMore={() => handleLoadMore('pageArtist')}
                onFocusInput={() => {
                  handleFocusInput('queryArtist', 'pageArtist');
                }}
              />
            </Col>
            <Col md="2" className="px-1 pe-3">
              <Search
                label="タイトル"
                labelMd="12"
                colMd="12"
                placeholder="検索"
                showIcon
                classLabel="px-0"
                rowClass="w-100"
                colClass="px-0"
                searchKey="queryTitle"
                onEnter={() => handleEnterPrimary('queryTitle')}
                onClickResult={handleClickItemPrimary}
                options={titleOptions.options || []}
                loading={loadingTitle}
                onLoadMore={() => handleLoadMore('pageTitle')}
                onFocusInput={() => {
                  handleFocusInput('queryTitle', 'pageTitle');
                }}
              />
            </Col>
            <Col md="2" className="px-1 pe-3">
              <Search
                label="バリエーション"
                labelMd="12"
                colMd="12"
                placeholder="検索"
                showIcon
                classLabel="px-0"
                rowClass="w-100"
                colClass="px-0"
                searchKey="queryVariation"
                onEnter={() => handleEnterPrimary('queryVariation')}
                onClickResult={handleClickItemPrimary}
                options={variationOptions.options || []}
                loading={loadingVariation}
                onLoadMore={() => handleLoadMore('pageVariation')}
                onFocusInput={() => {
                  handleFocusInput('queryVariation', 'pageVariation');
                }}
              />
            </Col>
            {isMonth ? (
              <Col md="4" className="revenue-tab px-1 pe-3">
                <ButtonNextMonth
                  onClickResult={onNextMonth}
                  queryDateSearch={query.startDate || ''}
                />
              </Col>
            ) : (
              <>
                <Col md="2" className="px-1 pe-3">
                  <DateSelector
                    label="集計開始日"
                    labelMd="12"
                    placeholder="開始日"
                    dateFormat="yyyy/MM/dd"
                    showTime={false}
                    maxDate={query.endDate ? new Date(query.endDate) : null}
                    onChange={(dateStart: IDatePicker) => onChangeDate(dateStart, 'startDate')}
                    selected={query.startDate ? new Date(query.startDate) : null}
                  />
                </Col>
                <Col md="2" className="px-1 pe-3">
                  <DateSelector
                    label="集計終了日"
                    labelMd="12"
                    placeholder="終了日"
                    dateFormat="yyyy/MM/dd"
                    showTime={false}
                    minDate={query.startDate ? new Date(query.startDate) : null}
                    onChange={(dateEnd: IDatePicker) => onChangeDate(dateEnd, 'endDate')}
                    selected={query.endDate ? new Date(query.endDate) : null}
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>
        <Col md="2">
          <Button
            className={`btn-focus-none prize-btn-excel float-right ${
              loadingExcel ? 'pe-none' : ''
            }`}
            onClick={onExportExcel}
          >
            {loadingExcel ? (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Excel ダウンロード'
            )}
          </Button>
        </Col>
      </Row>
      {!query.companyIds && isMiimEcOrExternalEc && (
        <MessageError
          classWrapper="search-company-error"
          isWarning={true}
          iconClass="text-dark"
          message="レコード会社を選択してください。"
          classMessage="text-dark"
        />
      )}
    </>
  );
}
