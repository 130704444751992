import TableBT from 'Components/Common/Table/TableBT';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, Button, Container } from 'react-bootstrap';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { DATE_FORMAT_1, DATE_FORMAT_2 } from 'Constant/Date';
import CampaignSearch from 'Components/Page/Campaign/List/FilterList';
import Filter from 'Components/Common/Filter/Filter';
import { CampaignApis } from 'Datasource/Campaign';
import PrizeRow from 'Components/Page/Campaign/List/PrizesRow';
import { handleParseDate } from 'Utils/ParseDate';
import { debounce, find } from 'lodash';
import { CampaignStatus, methodOptions, statisticTab } from 'Constant/Campaign';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useCampaign from 'Hooks/useCampaign';
import { PER_PAGE } from 'Constant';
import { updateMailList } from 'App/Features/MailRecipientList';
import { useAppDispatch } from 'App/Store';
import { transformQuery } from 'Utils/Params';
import { currentEnDMonth, currentStartMonth } from 'Utils/DateTime';
import useTitlePage from 'Hooks/useTitlePage';
import { resetData } from 'App/Features/Campaign';
import MessageError from 'Components/Common/MessageError';
import { SortMethod } from 'Components/Common/Table/table';
import useMessage from 'Hooks/useMessage';
import { AppRouter } from 'Types/Router';

export default function TableCampaign(): JSX.Element {
  const dispatch = useAppDispatch();
  const [campaigns, setCampaigns] = useState([]);
  const [campaignTotal, setCampaignTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const { handleCopyCampaign, isLoading } = useCampaign();

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
    sort: StringParam,
    order: StringParam,
  });

  const { setMenuTitle } = useTitlePage();

  const [activePage, setActivePage] = useState(query.page ?? 1);

  const { openMessageError } = useMessage();

  const columns = useMemo(
    () => [
      {
        Header: 'No.',
        className: 'd-flex align-items-center justify-content-center',
        accessor: 'id',
        width: 100,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center justify-content-center h-100">
              {row.original?.id}
            </div>
          );
        },
      },
      {
        Header: 'キャンペーン',
        accessor: 'name',
        width: 350,
        Cell: ({ row }: Cell<any>) => {
          const { isAuthor } = row?.original;

          const params = {
            startDate: currentStartMonth,
            endDate: currentEnDMonth,
            status: statisticTab.APPLY_STATUS,
          };
          const strParam = transformQuery(params);
          return (
            <div className="p-2">
              <Link
                to={`/campaigns/${row.original?.id}`}
                onClick={() => {
                  dispatch(updateMailList({ mailList: [], isSuccess: false }));
                }}
                className="d-block text-break"
              >
                <span className="text-start">{row.original?.name}</span>
              </Link>
              {row.original?.status === CampaignStatus.Draft && (
                <MessageError message=" 一時保存中です" />
              )}

              {row?.original?.isNoMedia && <MessageError message="未登録コンテンツあり" />}
              <div className="d-flex flex-wrap justify-content-between mt-2">
                <Button className="fs-12" variant="secondary">
                  <Link
                    to={`/statistic-campaign/${row.original?.id}${strParam}`}
                    className="text-white text-decoration-none w-100 h-100"
                  >
                    応募/抽選状況確認
                  </Link>
                </Button>
                <Button
                  className={`${isAuthor === 0 && 'd-none'} fs-12`}
                  variant="secondary"
                  onClick={() => {
                    const debounceClick = debounce(() => {
                      row?.original?.id && handleCopyCampaign(row?.original?.id);
                    }, 1000);
                    debounceClick();
                  }}
                >
                  このキャンペーンを複製
                </Button>
                <Link
                  to={{
                    pathname: `${AppRouter.PRODUCT_DETAILS}/${row.original?.title?.id}`,
                    state: {
                      titleName: row.original?.title?.name,
                    },
                  }}
                >
                  <Button className="mt-2 fs-12" variant="secondary">
                    コメントを確認
                  </Button>
                </Link>
              </div>
            </div>
          );
        },
      },
      {
        Header: '販売期間',
        accessor: 'occurrenceTime',
        width: 200,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="p-2 h-100 d-flex flex-column justify-content-center">
              <p>
                開始：
                {handleParseDate({
                  date: row.original?.startDate,
                  format: DATE_FORMAT_2,
                }) ?? '-'}
              </p>
              <p>
                終了：
                {handleParseDate({
                  date: row.original?.endDate,
                  format: DATE_FORMAT_2,
                }) ?? '-'}
              </p>
            </div>
          );
        },
      },
      {
        Header: '対象商品(タイトル/バリエーション)',
        accessor: 'artistTitle',
        width: 300,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="p-2 h-100 d-flex flex-column justify-content-center">
              <div className="text-break">
                <Link to={`${AppRouter.TITLE_DETAIL}/${row.original?.title?.id}`}>
                  <span className="text-start text-break d-block">{`${row.original?.title?.artist?.localName}/${row.original?.title?.name}`}</span>
                </Link>
              </div>
              {row.original?.title?.variations?.map((variation: any, vIndex: number) => (
                <div className="text-break d-block" key={vIndex}>
                  {variation?.name}
                </div>
              ))}
              {row.original?.title?.cds && row.original?.title?.cds.length > 0 ? (
                row.original?.title?.cds.map((cd: any) => (
                  <div style={{ maxWidth: 600 }} key={cd.id}>
                    {cd?.name}&nbsp;
                    <span className="badge bg-secondary">CD</span>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          );
        },
      },
      {
        Header: '応募コース',
        accessor: 'courses',
        disableSortBy: true,
        width: 300,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="h-100 d-flex flex-column justify-content-center">
              {row.original?.courses.length ? (
                row.original?.courses.map((course: any, cIndex: number) => {
                  return (
                    <div
                      key={cIndex}
                      className={`p-2 ${
                        cIndex === row.original?.courses.length - 1 ? '' : 'border-bottom'
                      } text-truncate`}
                      style={{ height: `calc(100%/${row.original?.courses.length})` }}
                    >
                      {course?.name}
                    </div>
                  );
                })
              ) : (
                <div className="text-center">-</div>
              )}
            </div>
          );
        },
      },
      {
        Header: '抽選方法 ',
        accessor: 'type',
        disableSortBy: true,
        width: 300,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div
              className={`h-100 ${
                !row.original?.courses.length && 'd-flex flex-column justify-content-center'
              }`}
            >
              {row.original?.courses.length ? (
                row.original?.courses.map((course: any, cIndex: number) => {
                  const typeLabel =
                    find(methodOptions, (option) => option.value === course.type)?.label ?? '';

                  return (
                    <div
                      key={cIndex}
                      className={`p-2 ${
                        cIndex === row.original?.courses.length - 1 ? '' : 'border-bottom'
                      }`}
                      style={{ height: `calc(100%/${row.original?.courses.length})` }}
                    >
                      {typeLabel}
                    </div>
                  );
                })
              ) : (
                <div className="text-center">-</div>
              )}
            </div>
          );
        },
      },
      {
        Header: '景品',
        accessor: 'prize',
        disableSortBy: true,
        width: 400,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div
              className={`h-100 ${
                !row.original?.courses.length && 'd-flex flex-column justify-content-center'
              }`}
            >
              {row.original?.courses.length ? (
                row.original?.courses.map((course: any, cIndex: number) => {
                  return (
                    <div
                      key={cIndex}
                      className={`p-2 ${
                        cIndex === row.original?.courses.length - 1 ? '' : 'border-bottom'
                      }`}
                      style={{ height: `calc(100%/${row.original?.courses.length})` }}
                    >
                      <PrizeRow course={course} />
                    </div>
                  );
                })
              ) : (
                <div className="text-center">-</div>
              )}
            </div>
          );
        },
      },
      {
        Header: '抽選日時',
        accessor: 'standardTime',
        disableSortBy: true,
        width: 200,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div
              className={`h-100 ${
                !row.original?.courses.length && 'd-flex flex-column justify-content-center'
              }`}
            >
              {row.original?.courses.length ? (
                row.original?.courses.map((course: any, cIndex: number) => {
                  return (
                    <div
                      key={cIndex}
                      className={`p-2 ${
                        cIndex === row.original?.courses.length - 1 ? '' : 'border-bottom'
                      } text-center d-flex justify-content-center flex-column`}
                      style={{ height: `calc(100%/${row.original?.courses.length})` }}
                    >
                      {handleParseDate({
                        date: course?.standardTime,
                        format: DATE_FORMAT_2,
                      }) ?? '-'}
                    </div>
                  );
                })
              ) : (
                <div className="text-center">-</div>
              )}
            </div>
          );
        },
      },
      {
        Header: '登録更新日',
        accessor: 'updatedAt',
        width: 150,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center justify-content-center p-2 h-100">
              {handleParseDate({
                date: row.original?.updatedAt,
                format: DATE_FORMAT_1,
              }) ?? '-'}
            </div>
          );
        },
      },
    ],
    [],
  );

  const handleGetCampaigns = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await CampaignApis.getCampaigns({
        limit: PER_PAGE,
        page: activePage,
        artistId: query.artistIds,
        titleId: query.titleIds,
        sort: query.sort || '',
        order: query.order || '',
      });
      setCampaigns(data.campaigns);
      setCampaignTotal(data.totalCount);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setMenuTitle('キャンペーンリスト');
  }, []);

  useEffect(() => {
    handleGetCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, query.artistIds, query.titleIds, query.sort, query.order]);

  //* handle sort from server side
  const handleManualSort = useCallback(
    (sortBy: SortMethod[]) => {
      if (sortBy.length > 0) {
        const sortOption = sortBy[0];
        let sortKey = sortOption.id;
        if (sortOption.id === 'occurrenceTime') {
          sortKey = 'startDate';
        }

        if (sortOption.id === 'artistTitle') {
          sortKey = 'titleName';
        }
        setQuery({ sort: sortKey, order: sortOption.desc ? 'DESC' : 'ASC' });
      } else {
        setQuery({ sort: '', order: '' });
      }
    },
    [setQuery],
  );

  return (
    <>
      <SpinnerComponent isLoading={loading || isLoading} />
      <Container fluid>
        <Filter filterClass="shadow-sm">
          <Row>
            <CampaignSearch />
            <Col md="4" className="text-end px-0">
              <Link
                to="/register-campaign"
                className="text-white text-decoration-none w-100 h-100"
                onClick={() => {
                  dispatch(updateMailList({ mailList: [], isSuccess: false }));
                  dispatch(resetData());
                }}
              >
                <Button className="btn-focus-none">新規キャンペーン登録</Button>
              </Link>
            </Col>
          </Row>
        </Filter>
        <Row className="mt-3">
          <Col md={12} className="d-flex justify-content-end p-0 px-4">
            <PaginationComponent
              activePage={activePage}
              total={campaignTotal}
              perPage={PER_PAGE}
              onClick={(newPage) => setActivePage(newPage)}
            />
          </Col>
        </Row>
        <div className="table-nft-container">
          <Row className="mt-3">
            <Col md={12} className="d-flex justify-content-center p-0">
              <TableBT
                heightBody="calc(100vh - 250px)"
                columns={columns}
                data={campaigns}
                getCellProps={() => ({
                  className: 'p-0',
                })}
                manualSortBy
                onSort={handleManualSort}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}
