/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useAppSelector } from 'App/Store';
import { RolesSystem } from 'Constant/Roles';
import { useMemo } from 'react';

export const useRole = () => {
  const { role } = useAppSelector((state) => state.auth);

  //====RC Company=====
  const isRCComp = useMemo(
    () =>
      [
        RolesSystem.RCCompManagerHigh,
        RolesSystem.RCCompManagerLow,
        RolesSystem.RCCompMaster,
      ].includes(role.toString()) ?? false,
    [role],
  );

  const isRCCompMaster = useMemo(
    () => RolesSystem.RCCompMaster === role.toString() ?? false,
    [role],
  );

  const isRCCompManagerLow = useMemo(
    () => RolesSystem.RCCompManagerLow === role.toString() ?? false,
    [role],
  );

  const isRCCompManagerHigh = useMemo(
    () => RolesSystem.RCCompManagerHigh === role.toString() ?? false,
    [role],
  );
  //====End RC Company=====

  //====EC=====
  const isEC = useMemo(
    () => [RolesSystem.ECCompManager, RolesSystem.ECCompMaster].includes(role.toString()) ?? false,
    [role],
  );

  const isECCompManager = useMemo(
    () => RolesSystem.ECCompManager === role.toString() ?? false,
    [role],
  );

  const isECCompMaster = useMemo(
    () => RolesSystem.ECCompMaster === role.toString() ?? false,
    [role],
  );

  const isECCompEventApp = useMemo(
    () => RolesSystem.ECCompEventApp === role.toString() ?? false,
    [role],
  );

  //====End EC=====

  //====BR=====
  const isBootRock = useMemo(
    () => [RolesSystem.BRManager, RolesSystem.BRMaster].includes(role.toString()) ?? false,
    [role],
  );

  const isBRManager = useMemo(() => RolesSystem.BRManager === role.toString() ?? false, [role]);

  const isBRMaster = useMemo(() => RolesSystem.BRMaster === role.toString() ?? false, [role]);
  //====End BR=====

  //====Sale=====
  const isSale = useMemo(
    () =>
      [RolesSystem.SALEChanelManager, RolesSystem.SALEChanelMaster].includes(role.toString()) ??
      false,
    [role],
  );

  const isSALEChanelManager = useMemo(
    () => RolesSystem.SALEChanelManager === role.toString() ?? false,
    [role],
  );

  const isSALEChanelMaster = useMemo(
    () => RolesSystem.SALEChanelMaster === role.toString() ?? false,
    [role],
  );
  //====End Sale=====

  return {
    isRCComp,
    isRCCompMaster,
    isRCCompManagerLow,
    isRCCompManagerHigh,
    isEC,
    isECCompManager,
    isECCompMaster,
    isBootRock,
    isBRManager,
    isBRMaster,
    isSale,
    isSALEChanelManager,
    isSALEChanelMaster,
    isECCompEventApp,
  };
};
