/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { formatCurrency } from 'Utils/Numbers';
import { IPurchase } from 'Types/Page/CompanyInformation/CompanyInformation';
import { COL_START_CLASS } from 'Constant';
import {
  renderArtistName,
  renderCardCd,
  renderDownloadDate,
  renderPrice,
  renderProductId,
  // renderProductName,
  renderTitleNameMiimEc,
  renderPurchaseDate,
  renderTitleName,
  renderVariationName,
  renderProductShop,
  renderLabelName,
  renderJanCode,
} from 'Components/Page/InformationAccountant/renderReportColumn';
import AliTable, { AliColumn, AliSortType } from 'Components/Common/Table/AliTable';
import { Row, Form } from 'react-bootstrap';
import { unionBy } from 'lodash';
import { useAppSelector } from 'App/Store';
import { ROLE_RECORD_MENU } from 'Constant/Roles';
interface Props {
  dataSource: IPurchase[];
  saleChannels: any[];
  filters?: any;
  option: number;
  activeColumns?: any;
  setActiveColumns?: any;
  onSort?: (sortingState: AliSortType[]) => void;
}

export default function TableSaleManagement({
  dataSource,
  saleChannels = [],
  filters,
  option,
  activeColumns,
  setActiveColumns,
  onSort,
}: Props): JSX.Element {
  const { role } = useAppSelector((state) => state.auth);

  const isMiimEcOrExternalEc = useMemo(() => {
    if (
      role &&
      (role === ROLE_RECORD_MENU.ECCompMaster ||
        role === ROLE_RECORD_MENU.ECCompManager ||
        role === ROLE_RECORD_MENU.SALEChanelMaster ||
        role === ROLE_RECORD_MENU.SALEChanelManager)
    ) {
      return true;
    }
    return false;
  }, [role]);

  const isAllChecked = useMemo(() => {
    if (option !== 2) {
      return false;
    }
    const activeColumnsId = activeColumns?.map((col: any) => col?.purchaseId);
    const dataColumnsId = dataSource
      ?.filter((col: any) => !col.deleted)
      .map((c: any) => c.purchaseId);

    return dataColumnsId.every((colId: any) => activeColumnsId?.includes(colId));
  }, [dataSource, activeColumns, option]);

  const addOrRemoveAllColumns = (e: any) => {
    const dataColumnsId = dataSource?.map((col: any) => col?.purchaseId);

    if (e.target.checked) {
      const newActiveColumns = unionBy(dataSource, activeColumns, 'purchaseId');
      setActiveColumns(newActiveColumns.filter((c: any) => !c.deleted));
    } else {
      const newActiveColumns = activeColumns?.filter(
        (col: any) => !dataColumnsId?.includes(col?.purchaseId),
      );
      setActiveColumns(newActiveColumns?.filter((c: any) => !c.deleted));
    }
  };

  const addOrRemoveColumns = (val: any) => {
    const foundSourceData = dataSource.find((col: any) => col?.purchaseId === val);
    const foundData = activeColumns.find((col: any) => col?.purchaseId === val);
    if (foundData) {
      const newActiveColumns = activeColumns.filter((col: any) => col?.purchaseId !== val);
      setActiveColumns(newActiveColumns);
    } else {
      const newActiveColumns = [...activeColumns, foundSourceData];
      setActiveColumns(newActiveColumns);
    }
  };

  //* salechannel columns
  const renderSaleChannelColumns = useCallback(() => {
    const columnTotal = saleChannels.map((col, index) => ({
      name: col?.channelName,
      code: `total${index}`,
      width: 160,
      align: 'center',
      render: (value: any) => (
        <div className={`text-truncate text-end ${(value || 0) < 0 ? 'text-danger' : ''}`}>
          {formatCurrency(value || 0)}
        </div>
      ),
    }));

    const columnTotalMoney = saleChannels.map((col, index) => ({
      name: col?.channelName,
      code: `totalMoney${index}`,
      width: 160,
      align: 'center',
      render: (value: any) => (
        <div className={`text-truncate text-end ${(value || 0) < 0 ? 'text-danger' : ''}`}>
          {formatCurrency(value || 0)}
        </div>
      ),
    }));

    return [
      {
        width: 160,
        align: 'center',
        name: '販売数',
        ...(isMiimEcOrExternalEc
          ? {
              render: (value: any) => (
                <div className={`text-truncate text-end ${(value || 0) < 0 ? 'text-danger' : ''}`}>
                  {formatCurrency(value || 0)}
                </div>
              ),
              code: 'totalAmount',
            }
          : {
              children: [
                ...columnTotal,
                {
                  name: '合計',
                  code: 'totalAmount',
                  width: 160,
                  align: 'center',
                  render: (value: any) => (
                    <div
                      className={`text-truncate text-end ${(value || 0) < 0 ? 'text-danger' : ''}`}
                    >
                      {formatCurrency(value || 0)}
                    </div>
                  ),
                },
              ],
            }),
      },

      {
        width: 160,
        name: '販売金額（税抜）',
        align: 'center',
        ...(isMiimEcOrExternalEc
          ? {
              render: (value: any) => (
                <div className={`text-truncate text-end ${(value || 0) < 0 ? 'text-danger' : ''}`}>
                  {formatCurrency(value || 0)}
                </div>
              ),
              code: 'totalMoney',
            }
          : {
              children: [
                ...columnTotalMoney,
                {
                  name: '合計',
                  code: 'totalMoney',
                  width: 160,
                  align: 'center',
                  render: (value: any) => (
                    <div
                      className={`text-truncate text-end ${(value || 0) < 0 ? 'text-danger' : ''}`}
                    >
                      {formatCurrency(value || 0)}
                    </div>
                  ),
                },
              ],
            }),
      },
    ] as AliColumn[];
  }, [saleChannels]);

  //* renderSellingPriceTaxColumn
  const renderSellingPriceTaxColumn = useCallback(() => {
    const columnTaxTotalMoney = saleChannels.map((col, index) => ({
      name: col?.channelName,
      code: `afterTaxTotalMoney${index}`,
      width: 160,
      align: 'center',
      render: (value: any) => (
        <div className={`text-truncate text-end ${(value || 0) < 0 ? 'text-danger' : ''}`}>
          {formatCurrency(value || 0)}
        </div>
      ),
    }));
    return [
      {
        width: 160,
        name: '販売金額（税込）',
        align: 'center',
        ...(isMiimEcOrExternalEc
          ? {
              render: (value: any) => (
                <div className={`text-truncate text-end ${(value || 0) < 0 ? 'text-danger' : ''}`}>
                  {formatCurrency(value || 0)}
                </div>
              ),
              code: 'afterTaxTotalMoney',
            }
          : {
              children: [
                ...columnTaxTotalMoney,
                {
                  name: '合計',
                  code: 'afterTaxTotalMoney',
                  width: 160,
                  align: 'center',
                  render: (value: any) => (
                    <div
                      className={`text-truncate text-end ${(value || 0) < 0 ? 'text-danger' : ''}`}
                    >
                      {formatCurrency(value || 0)}
                    </div>
                  ),
                },
              ],
            }),
      },
    ] as AliColumn[];
  }, [saleChannels]);

  //* column Month
  const columnMonth = useMemo(() => {
    const initColumnMonth = [
      renderProductId,
      renderProductShop,
      // renderProductName,
      renderLabelName,
      renderArtistName,
      isMiimEcOrExternalEc ? renderTitleNameMiimEc : renderTitleName,
      renderVariationName,
      renderCardCd,
      renderJanCode,
      renderDownloadDate,
      renderPrice,
    ];

    if (filters) {
      if (filters.isViewProductId) {
        renderProductId.hidden = false;
      }
      if (!filters.isViewProductId) {
        renderProductId.hidden = true;
      }

      if (filters.isViewArtistName) {
        renderArtistName.hidden = false;
      }

      if (!filters.isViewArtistName) {
        renderArtistName.hidden = true;
      }

      if (filters.isViewTitleName) {
        renderTitleName.hidden = false;
        renderTitleNameMiimEc.hidden = false;
      }

      if (!filters.isViewTitleName) {
        renderTitleName.hidden = true;
        renderTitleNameMiimEc.hidden = true;
      }

      if (filters.isViewVariationName) {
        renderVariationName.hidden = false;
      }

      if (!filters.isViewVariationName) {
        renderVariationName.hidden = true;
      }

      if (filters.isViewVariationProductCode) {
        renderCardCd.hidden = false;
      }

      if (!filters.isViewVariationProductCode) {
        renderCardCd.hidden = true;
      }

      if (filters.isViewDownloadDate) {
        renderDownloadDate.hidden = false;
      }

      if (!filters.isViewDownloadDate) {
        renderDownloadDate.hidden = true;
      }

      if (isMiimEcOrExternalEc === true && filters.isViewShop === true) {
        renderProductShop.hidden = false;
      }

      if (isMiimEcOrExternalEc === false || filters.isViewShop === false) {
        renderProductShop.hidden = true;
      }
      if (filters.isViewVariationJanCode) {
        renderJanCode.hidden = false;
      }
      if (!filters.isViewVariationJanCode) {
        renderJanCode.hidden = true;
      }
      if (filters.isViewTitleLabel) {
        renderLabelName.hidden = false;
      }
      if (!filters.isViewTitleLabel) {
        renderLabelName.hidden = true;
      }
    }

    return [
      ...initColumnMonth,
      ...renderSaleChannelColumns(),
      ...renderSellingPriceTaxColumn(),
    ] as AliColumn[];
  }, [isMiimEcOrExternalEc, filters, renderSaleChannelColumns, renderSellingPriceTaxColumn]);

  //* column daily
  const columnDaily = useMemo(() => {
    const initColumnDaily = [
      renderProductId,
      renderProductShop,
      renderPurchaseDate,
      // renderProductName,
      renderLabelName,
      renderArtistName,
      isMiimEcOrExternalEc ? renderTitleNameMiimEc : renderTitleName,
      renderVariationName,
      renderCardCd,
      renderJanCode,
      renderDownloadDate,
      renderPrice,
    ];

    if (filters) {
      if (filters.isViewProductId) {
        renderProductId.hidden = false;
      }
      if (!filters.isViewProductId) {
        renderProductId.hidden = true;
      }

      if (filters.isViewArtistName) {
        renderArtistName.hidden = false;
      }

      if (!filters.isViewArtistName) {
        renderArtistName.hidden = true;
      }

      if (filters.isViewTitleName) {
        renderTitleName.hidden = false;
        renderTitleNameMiimEc.hidden = false;
      }

      if (!filters.isViewTitleName) {
        renderTitleName.hidden = true;
        renderTitleNameMiimEc.hidden = true;
      }

      if (filters.isViewVariationName) {
        renderVariationName.hidden = false;
      }

      if (!filters.isViewVariationName) {
        renderVariationName.hidden = true;
      }

      if (filters.isViewVariationProductCode) {
        renderCardCd.hidden = false;
      }

      if (!filters.isViewVariationProductCode) {
        renderCardCd.hidden = true;
      }

      if (filters.isViewDownloadDate) {
        renderDownloadDate.hidden = false;
      }

      if (!filters.isViewDownloadDate) {
        renderDownloadDate.hidden = true;
      }

      if (isMiimEcOrExternalEc === true && filters.isViewShop === true) {
        renderProductShop.hidden = false;
      }

      if (isMiimEcOrExternalEc === false || filters.isViewShop === false) {
        renderProductShop.hidden = true;
      }
      if (filters.isViewVariationJanCode) {
        renderJanCode.hidden = false;
      }
      if (!filters.isViewVariationJanCode) {
        renderJanCode.hidden = true;
      }
      if (filters.isViewTitleLabel) {
        renderLabelName.hidden = false;
      }
      if (!filters.isViewTitleLabel) {
        renderLabelName.hidden = true;
      }
    }
    return [
      ...initColumnDaily,
      ...renderSaleChannelColumns(),
      ...renderSellingPriceTaxColumn(),
    ] as AliColumn[];
  }, [isMiimEcOrExternalEc, filters, renderSaleChannelColumns, renderSellingPriceTaxColumn]);

  //* column Detail
  const columnDetail = useMemo(() => {
    const initColumnDetail = [
      {
        name: (
          <Form.Check
            type="checkbox"
            id="isCheckAll"
            name="isCheckAll"
            // checked={false}
            className="ec_custom-checkbox"
            checked={isAllChecked}
            // onChange={() => setValue('isPublic', !watch('isPublic'))}
            onChange={addOrRemoveAllColumns}
          />
        ),
        lock: true,
        className: 'd-flex align-items-center justify-content-center',
        width: 50,
        code: 'purchaseId',
        render: (value: any, r: any) => {
          const foundCol = activeColumns?.find((col: any) => col?.purchaseId == value);
          return (
            <div className="d-flex align-items-center justify-content-center h-100 p-2">
              <Form.Check
                type="checkbox"
                id={value}
                name={value}
                // checked={false}
                className="ec_custom-checkbox"
                checked={!!foundCol}
                disabled={r?.deleted}
                // onChange={() => setValue('isPublic', !watch('isPublic'))}
                // onChange={(e) => setIsShowOnlyMaster(e.target.checked)}
                onChange={() => addOrRemoveColumns(value)}
              />
            </div>
          );
        },
      },
      renderProductId,
      renderProductShop,
      renderPurchaseDate,
      {
        name: '購入者',
        width: 160,
        lock: true,
        code: 'userName',
        align: 'center',
        features: { sortable: true },
        render: (value: IPurchase) => {
          return <div className={`${COL_START_CLASS}`}>{value}</div>;
        },
      },
      // renderProductName,
      renderLabelName,
      renderArtistName,
      isMiimEcOrExternalEc ? renderTitleNameMiimEc : renderTitleName,
      renderVariationName,
      renderCardCd,
      renderJanCode,
      renderDownloadDate,
      renderPrice,
    ];
    if (filters) {
      if (filters.isViewProductId) {
        renderProductId.hidden = false;
      }
      if (!filters.isViewProductId) {
        renderProductId.hidden = true;
      }

      if (filters.isViewArtistName) {
        renderArtistName.hidden = false;
      }

      if (!filters.isViewArtistName) {
        renderArtistName.hidden = true;
      }

      if (filters.isViewTitleName) {
        renderTitleName.hidden = false;
        renderTitleNameMiimEc.hidden = false;
      }

      if (!filters.isViewTitleName) {
        renderTitleName.hidden = true;
        renderTitleNameMiimEc.hidden = true;
      }

      if (filters.isViewVariationName) {
        renderVariationName.hidden = false;
      }

      if (!filters.isViewVariationName) {
        renderVariationName.hidden = true;
      }

      if (filters.isViewVariationProductCode) {
        renderCardCd.hidden = false;
      }

      if (!filters.isViewVariationProductCode) {
        renderCardCd.hidden = true;
      }

      if (filters.isViewDownloadDate) {
        renderDownloadDate.hidden = false;
      }

      if (!filters.isViewDownloadDate) {
        renderDownloadDate.hidden = true;
      }

      if (isMiimEcOrExternalEc === true && filters.isViewShop === true) {
        renderProductShop.hidden = false;
      }

      if (isMiimEcOrExternalEc === false || filters.isViewShop === false) {
        renderProductShop.hidden = true;
      }
      if (filters.isViewVariationJanCode) {
        renderJanCode.hidden = false;
      }
      if (!filters.isViewVariationJanCode) {
        renderJanCode.hidden = true;
      }
      if (filters.isViewTitleLabel) {
        renderLabelName.hidden = false;
      }
      if (!filters.isViewTitleLabel) {
        renderLabelName.hidden = true;
      }
    }

    return [
      ...initColumnDetail,
      ...renderSaleChannelColumns(),
      ...renderSellingPriceTaxColumn(),
    ] as AliColumn[];
  }, [
    isAllChecked,
    addOrRemoveAllColumns,
    isMiimEcOrExternalEc,
    filters,
    renderSaleChannelColumns,
    renderSellingPriceTaxColumn,
    activeColumns,
    addOrRemoveColumns,
  ]);

  const renderColumn =
    option === 0 ? columnMonth : option === 1 ? columnDaily : option === 2 ? columnDetail : [];
  return (
    <Row className="mt-3">
      <AliTable
        wrapperClass="ali_table-revenue_management"
        dataSource={dataSource}
        columns={renderColumn}
        heightBody="calc(100vh - 450px)"
        onSort={onSort}
      />
    </Row>
  );
}
