import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Filter from 'Components/Common/Filter/Filter';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import SerialNumberTable from 'Components/Page/ManagerSeries/SerialNumberTable';
import { useHistory } from 'react-router-dom';
import SearchSerialNumber from 'Page/ManagerSerial/SearchSerialNumber';
import { SerialApis } from 'Datasource/Serial';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import useMessage from 'Hooks/useMessage';
import { PER_PAGE } from 'Constant';
import { SortMethod } from 'Components/Common/Table/table';

export default function SerialNumberManagement(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const history = useHistory();
  const { openMessageError } = useMessage();
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    artistIds: StringParam,
    sort: StringParam,
    order: StringParam,
  });

  const convertDataSerila = (data: any) => {
    return data.map((el: any) => {
      const nameCd = el.cd ? el.cd.name : null;
      const codeCd = el.cd ? el.cd.productCode : null;
      return { ...el, nameCd, codeCd };
    });
  };

  const handleGetSerial = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await SerialApis.getSerials({
        limit: PER_PAGE,
        page: query?.page,
        artistId: query?.artistIds || '',
        type: '',
        sort: query.sort || '',
        order: query.order || '',
      });

      setData(data);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetSerial();
  }, [query]);

  //* handle sort from server side
  const handleManualSort = useCallback(
    (sortBy: SortMethod[]) => {
      if (sortBy.length > 0) {
        const sortOption = sortBy[0];
        let sortKey = sortOption.id;
        if (sortOption.id === 'nameCd') {
          sortKey = 'cdName';
        }

        if (sortOption.id === 'codeCd') {
          sortKey = 'productCode';
        }
        setQuery({ sort: sortKey, order: sortOption.desc ? 'DESC' : 'ASC' });
      } else {
        setQuery({ sort: '', order: '' });
      }
    },
    [setQuery],
  );

  return (
    <>
      <Container fluid>
        <Filter filterClass="shadow-sm">
          <Row>
            <SearchSerialNumber />
            <Col md="8" className="d-flex justify-content-end px-0">
              <Button
                onClick={() => history.push(`/create-serial-number`)}
                className="btn-focus-none px-3button-submit"
              >
                新規マスタ登録
              </Button>
            </Col>
          </Row>
        </Filter>
        <div className="px-4">
          <SpinnerComponent isLoading={loading} />
          <Row className="my-3">
            <Col md={12} className="d-flex justify-content-end p-0">
              <PaginationComponent
                activePage={query?.page ?? 1}
                total={data?.meta?.totalItems ?? 0}
                perPage={PER_PAGE}
              />
            </Col>
          </Row>
          <SerialNumberTable
            dataSource={convertDataSerila(data?.items ?? [])}
            onSort={handleManualSort}
          />
        </div>
      </Container>
    </>
  );
}
