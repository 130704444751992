import FormGroupDateRange from 'Components/Common/Form/FormGroupDateRange';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import FormGroupTextArea from 'Components/Common/Form/FormGroupTextArea';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import CheckBoxs from 'Components/Common/Form/GroupCheckBox/CheckBoxs';
import { AnnouncementTimeOptions } from 'Constant/Campaign';
import { DATE_FORMAT_FOR_DATE_PICKER_2, TIME_FORMAT } from 'Constant/Date';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { isFutureTime, normalizeDate, replaceGMTToUTC, replaceTimeOfDate } from 'Utils/DateTime';
import useCampaign from 'Hooks/useCampaign';
import dayjs from 'dayjs';
import TimeSelector from 'Components/Common/DateSelector/TimeSelector';
import { setMinutes, setHours } from 'date-fns';
import BRFormGroupTime from 'Components/Common/Form/FormGroupDate/BRFormGroupTime';
import { toNumber } from 'lodash';

const BonusTicket = (): JSX.Element => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext(); // retrieve all hook methods
  const { handleDisableDataCampaign } = useCampaign();
  const MIN_TIME = setHours(setMinutes(new Date(), 0), 0);
  const startDateSameToday = () => {
    return dayjs(watch('type2.startDate')).isSame(new Date(), 'date');
  };

  const minNotiTime = () => {
    if (startDateSameToday()) {
      return new Date();
    }
    return MIN_TIME;
  };

  const minOpenTime = () => {
    if (startDateSameToday()) {
      return new Date();
    }
    return MIN_TIME;
  };

  const handleChangeStartDate = (date: Date | null) => {
    const dateSameToDay = dayjs(date).isSame(new Date(), 'date');
    setValue('type2.startDate', replaceGMTToUTC(date));
    if (!watch('type2.startDate')) {
      setValue('type2.endDate', null);
    }

    const selectedNotiDate = replaceTimeOfDate(date, watch('type2.notificationDate'));
    const startTimeSelected = replaceTimeOfDate(date, watch('type2.startTime'));
    if (watch('type2.notificationDate') && dateSameToDay) {
      if (!isFutureTime(selectedNotiDate)) {
        setValue('type2.notificationDate', null);
      }
    }

    if (dateSameToDay) {
      if (!isFutureTime(startTimeSelected)) {
        setValue('type2.startTime', null);
      }
    }

    if (watch('type2.notificationDate')) {
      setValue('type2.notificationDate', selectedNotiDate);
    }
  };
  return (
    <Row>
      <hr className="my-3" />
      <Col className="mx-5">
        <FormGroupInput
          label="表示名称"
          labelMd="2"
          colMd="10"
          value={watch('type2.eventName')}
          register={register('type2.eventName')}
          onChange={(value) => setValue('type2.eventName', value)}
          classForm="form-with-label-nowrap"
          errorMessage={errors.type2?.eventName?.message}
          hintText="当選したユーザーのアプリに表示される名称を設定します。"
        />

        <FormInputNumber
          name="productQuantity"
          labelMd="2"
          colMd="3"
          label="付与数"
          pureType={true}
          decimalScale={0}
          maxLength={10}
          allowLeadingZeros={true}
          classForm="number-grants-end"
          allowNegative={false}
          value={watch('type2.productQuantity')}
          onChange={(value: any) => setValue('type2.productQuantity', value)}
          errorMessage={errors.type2?.productQuantity?.message}
          isReview={handleDisableDataCampaign(watch('type2.id'))}
          suffix={
            watch('userQuantity') && watch('type2.productQuantity')
              ? `枚付与　(最大付与数 ${
                  toNumber(watch('userQuantity')) * toNumber(watch('type2.productQuantity'))
                } 枚)`
              : '枚付与'
          }
        />

        <BRFormGroupTime
          label="開始時刻"
          labelAfter="(WEBアプリ表示用)"
          labelMd="2"
          colMd="4"
          currentDate={
            watch('type2.openTime') ? normalizeDate(watch('type2.openTime')) : new Date()
          }
          selected={normalizeDate(watch('type2.startTime'))}
          onChange={(date: any) => {
            if (watch('type2.startDate')) {
              const validDate = replaceTimeOfDate(watch('type2.startDate'), date);
              setValue('type2.startTime', validDate);
            } else {
              setValue('type2.startTime', date);
            }
          }}
          placeholder="時刻を選択　(hh:mm)"
          dateFormat={TIME_FORMAT}
          errorMessage={errors.type2?.startTime?.message}
          minDate={minOpenTime()}
        />

        <FormGroupInput
          label="会場名"
          labelMd="2"
          colMd="10"
          value={watch('type2.placeName')}
          register={register('type2.placeName')}
          onChange={(value) => setValue('type2.placeName', value)}
          classForm="form-with-label-nowrap"
          errorMessage={errors.type2?.placeName?.message}
        />

        <FormGroupDateRange
          checkUpdateRange={true}
          label="日付"
          labelMd="2"
          colMd="6"
          classForm="px-0"
          placeholderStart="開始日（yyyy/mm/dd)"
          placeholderEnd="終了日（yyyy/mm/dd)"
          minDate={new Date()}
          maxStartDate={normalizeDate(watch('type2.endDate'))}
          endDateDisabled={!normalizeDate(watch('type2.startDate'))}
          dateFormat={DATE_FORMAT_FOR_DATE_PICKER_2}
          startDate={normalizeDate(watch('type2.startDate'))}
          endDate={normalizeDate(watch('type2.endDate'))}
          onChangeStartDate={(date: Date | null) => {
            handleChangeStartDate(date);
            if (watch('type2.startTime')) {
              const validDate = replaceTimeOfDate(watch('type2.startDate'), date);
              setValue('type2.startTime', validDate);
            }
          }}
          onChangeEndDate={(date: Date | null) => setValue('type2.endDate', replaceGMTToUTC(date))}
          errorStartDate={errors.type2?.startDate?.message}
          errorEndDate={errors.type2?.endDate?.message}
        />
        <Row className="p-0">
          <Col className="p-0" md={6}>
            <CheckBoxs
              id="eventDateAnnouncementTime"
              labelForm="当日通知時刻"
              name="eventDateAnnouncementTime"
              options={AnnouncementTimeOptions}
              labelMd="4"
              colMd="8"
              value={watch('type2.isSendNoti')}
              onChange={({ value }) => {
                setValue('type2.isSendNoti', value);
                if (value === 0) {
                  setValue('type2.notificationDate', null);
                }
              }}
              classForm="form-with-label-nowrap mb-3"
              errorMessage={errors?.type2?.isSendNoti?.message}
              classOption="pe-4"
            />
          </Col>
          <Col md={3}>
            <TimeSelector
              currentDate={
                watch('type2.startDate') ? normalizeDate(watch('type2.startDate')) : new Date()
              }
              selected={normalizeDate(watch('type2.notificationDate'))}
              onChange={(date: any) => {
                if (watch('type2.startDate')) {
                  const validDate = replaceTimeOfDate(watch('type2.startDate'), date);
                  setValue('type2.notificationDate', validDate);
                } else {
                  setValue('type2.notificationDate', date);
                }
              }}
              placeholder="時刻を選択　(hh:mm)"
              dateFormat={TIME_FORMAT}
              errorMessage={errors.type2?.notificationDate?.message}
              minDate={minNotiTime()}
              disabled={watch(`type2.isSendNoti`) === 0}
            />
          </Col>
        </Row>
        <FormGroupTextArea
          labelMd="2"
          colMd="6"
          label="ユーザー表示用・備考注意事項"
          register={register('type2.description')}
          classInput="mt-2"
          rows={4}
          value={watch('type2.description')}
          onChange={(value) => setValue('type2.description', value)}
          errorMessage={errors?.type2?.description?.message}
          onKeyPress={(e) => {
            e.stopPropagation();
          }}
        />
      </Col>
    </Row>
  );
};
export default BonusTicket;
