import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BoxForm from 'Components/Common/Form/BoxForm';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { BootRockInfoApi } from 'Datasource/OperatingCompany';
import useMessage from 'Hooks/useMessage';
import BRLogo from 'Static/Images/br-logo.png';
import { formatPhoneNumber } from 'Utils/Numbers';

interface Manager {
  managerEmail?: string;
  managerPhone?: string;
  managerName?: string;
}
interface BootRockInfo {
  name?: string;
  homePage?: string;
  limistaPage?: string;
  manager?: Manager[];
  zipCode?: string;
  address?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  hotline?: string;
}

export default function OperatingCompanyInfo(): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [bootRockInfo, setBootRockInfo] = useState<BootRockInfo>({});

  const { openMessageError } = useMessage();

  const fetchBootRockInfo = async () => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await BootRockInfoApi.fetchBootRockInfoApi();
      setBootRockInfo(data);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBootRockInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid className="p-2">
      <SpinnerComponent isLoading={isLoading} />

      <Row>
        <Col md="9" className="m-auto mt-5 mb-5">
          <BoxForm title="運営会社情報">
            <div className="bg-white flex">
              <Col md={{ span: 6, offset: 3 }}>
                <Row className="mb-5">
                  <Col md={{ span: 8, offset: 4 }}>
                    <img src={BRLogo} alt="br logo" className="w-max-content" />
                  </Col>
                </Row>
              </Col>

              <Col md={{ span: 6, offset: 3 }}>
                <Row className="mt-2">
                  <Col md="4">運営会社</Col>
                  <Col md="8">{bootRockInfo.name}</Col>
                </Row>

                <Row className="mt-4 mb-1">
                  <Col md="4">運営会社URL</Col>
                  <Col md="8">
                    <a
                      className="d-block"
                      href={bootRockInfo.homePage}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {bootRockInfo.homePage}
                    </a>
                  </Col>
                </Row>
                <Row className="mt-4 mb-1">
                  <Col md="4">リミスタURL</Col>
                  <Col md="8">
                    <a
                      className="d-block"
                      href={bootRockInfo.limistaPage}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {bootRockInfo.limistaPage}
                    </a>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md="4">
                    <div className="m-auto mt-3 mb-3">所在地</div>
                  </Col>
                  <Col md="8">
                    <div className="mb-1">〒{bootRockInfo.zipCode}</div>
                    <div>{bootRockInfo.address1}</div>
                    <div>{bootRockInfo.address2}</div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md="4">代表電話番号</Col>
                  <Col md="8">{formatPhoneNumber(bootRockInfo.phone)}</Col>
                </Row>
                <Row className="mt-4 mb-4">
                  <Col md="4">緊急電話番号</Col>
                  <Col md="8">{formatPhoneNumber(bootRockInfo.hotline)}</Col>
                </Row>
              </Col>
              <hr className="mt-4" />
              <Col md={{ span: 6, offset: 3 }}>
                <Row className="mt-4">
                  <Col md="4">御社担当者</Col>
                  <Col md="8">
                    {bootRockInfo?.manager?.map((item) => {
                      return (
                        <>
                          <div className="text-truncate">{item.managerName}</div>
                          <a
                            href={`mailto:${item.managerEmail}`}
                            target="_blank"
                            rel="noreferrer"
                            className="text-truncate"
                          >
                            {item.managerEmail}
                          </a>
                          <div className="mb-2 text-truncate">
                            {formatPhoneNumber(item.managerPhone)}
                          </div>
                        </>
                      );
                    })}
                  </Col>
                </Row>
              </Col>
            </div>
          </BoxForm>
        </Col>
      </Row>
    </Container>
  );
}
