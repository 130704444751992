import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import * as yup from 'yup';
import { checkAutoFormatJasrac, padNum } from 'Utils/Title';
import {
  englishValidationNotRequired,
  jasracCodeValidation,
  katakanaFullSizeValidation,
  katakanaFullSizeValidationNotRequired,
  numberRequired,
  otherJasracCodeEnglishValidation,
  songTimeValidation,
  stringRequired,
} from './Validation';
import { includes } from 'lodash';
import { Variation } from 'Types/App/title';
import { INCLUDE_JARSAC_CONTENT } from 'Constant/Title';

export function exportToExcel(data: any) {
  const fileName = 'miim_DL_曲_export.xlsx';
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet');

  XLSX.writeFile(wb, fileName);
}

export async function sheetToJson(file: any) {
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = (e) => {
    const bstr = e?.target?.result;
    const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws);
    return data;
  };
  try {
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  } catch (error) {
    console.log(error);
  }
}

export const convertJsonSong = async (array: any, files: File[], variation: Variation) => {
  const schema = yup.array().of(
    yup.object().shape({
      name: stringRequired({}).trim(),
      furiganaName: katakanaFullSizeValidation({}).trim().nullable(),
      musicianName: yup
        .string()
        .when('ivtType', {
          is: 0,
          then: yup.string().notRequired().nullable().trim(),
          otherwise: yup.string().required('入力してください。').nullable().trim(),
        })
        .nullable(),
      composerName: yup
        .string()
        .when('ivtType', {
          is: 2,
          then: yup.string().notRequired().nullable().trim(),
          otherwise: yup.string().required('入力してください。').nullable().trim(),
        })
        .nullable(),
      showTime: songTimeValidation({}).trim().nullable(),
      versionIdentificationType: yup.number().required('入力してください。').nullable(),
      ivtType: yup.number().required('入力してください。').nullable(),
      liveType: yup.number().required('入力してください。').nullable(),
      lyricsClassifyType: yup.number().when('ivtType', {
        is: (val: number) => val === 1 || val === 2,
        then: yup.number().required('入力してください。').nullable(),
        otherwise: yup.number().notRequired().nullable(),
      }),
      authorizedGroupType: yup
        .number()
        .when('includeJasracContent', {
          is: (includeJasracContent: number | null) =>
            includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE,
          then: numberRequired({}),
        })
        .test({
          name: 'authorizedGroupType',
          message: '100,000円以下の値を入力してください。',
          test: (value, context: any) => {
            const { createError, from, path } = context;

            const includeJasracContent = from[0].value.includeJasracContent;
            const authorizedGroupType = value;

            if (
              +includeJasracContent !== INCLUDE_JARSAC_CONTENT.INCLUDE &&
              authorizedGroupType &&
              [1, 2].includes(+authorizedGroupType)
            ) {
              return createError({
                message: 'error',
                path,
              });
            } else {
              return true;
            }
          },
        })
        .nullable(),
      authorizedGroup: yup
        .string()
        .when('authorizedGroupType', {
          is: 4,
          then: yup.string().required('入力してください。').nullable().trim(),
          // otherwise: yup
          //   .string()
          //   .test({
          //     name: 'authorizedGroupType',
          //     message: '無効なエントリ',
          //     test: (value = '', context: any) => {
          //       const { createError, path } = context;
          //       const isResult = value?.toString().length > 0;
          //       return (
          //         !isResult ||
          //         createError({
          //           message: `無効なエントリ`,
          //           path,
          //         })
          //       );
          //     },
          //   })
          //   .nullable(),
        })
        .trim()
        .nullable(),
      englishName: englishValidationNotRequired().trim().nullable(),
      musicianFuriganaName: katakanaFullSizeValidationNotRequired().trim().nullable(),
      musicianEnglishName: englishValidationNotRequired().trim().nullable(),
      composerFuriganaName: katakanaFullSizeValidationNotRequired().trim().nullable(),
      composerEnglishName: englishValidationNotRequired().trim().nullable(),
      arrangerFuriganaName: katakanaFullSizeValidationNotRequired().trim().nullable(),
      arrangerEnglishName: englishValidationNotRequired().trim().nullable(),
      jasracCode: yup.string().when('authorizedGroupType', {
        is: (val: number) => val === 1,
        then: jasracCodeValidation().trim().nullable(),
        // otherwise: yup.string().max(0, '空白にする必要があります'),
      }),
      otherJasracCode: yup.string().when('authorizedGroupType', {
        is: (val: number) => includes([2], val),
        then: otherJasracCodeEnglishValidation().nullable(),
        otherwise: yup.string().when('authorizedGroupType', {
          is: (val: number) => includes([1], val),
          then: yup.string().max(0, '空白にする必要があります'),
        }),
      }),
      informationFee: yup.number().when(['includeJasracContent'], {
        is: (includeJasracContent: number) =>
          includeJasracContent !== INCLUDE_JARSAC_CONTENT.INCLUDE,
        then: yup
          .number()
          .test('', '10文字以内で入力してください。', (val: any) => val?.toString().length <= 10)
          .nullable(),
      }),
    }),
  );

  const convertIvtType = (key: string) => {
    switch (key) {
      case 'I':
        return 0;
      case 'V':
        return 1;
      case 'T':
        return 2;
      default:
        return '';
    }
  };

  const convertLiveType = (key: string) => {
    switch (key) {
      case 'スタジオ':
        return 0;
      case 'ライブ':
        return 1;
      default:
        return '';
    }
  };

  const convertVersionIdentificationType = (key: string) => {
    switch (key) {
      case 'L':
        return 0;
      case 'I':
        return 1;
      default:
        return '';
    }
  };

  const convertAuthorizedGroupType = (key: string) => {
    switch (key) {
      case 'JASRAC':
        return 1;
      case 'NexTone':
        return 2;
      case '自己管理':
        return 3;
      case 'その他':
        return 4;
      default:
        return '';
    }
  };

  const convertShowTime = (decimalTimeString: number | string) => {
    if (!Number.isFinite(decimalTimeString)) return '';

    const hours = +decimalTimeString * 24;

    const minutes = (+hours - Math.floor(+hours)) * 60;

    const seconds = (+minutes - Math.floor(+minutes)) * 60;

    const time = `${padNum(hours | 0, 2)}:${padNum(minutes | 0, 2)}:${padNum(seconds | 0, 2)}`;

    return time;
  };

  const dataConvert = array?.map((i: any) => {
    const groupType = convertAuthorizedGroupType(i['JASRAC_NexTone_自己管理_その他']);
    const jasracCode = i['JASRAC 作品コード'];

    return {
      name: i['原題名'],
      furiganaName: i['曲名_フリガナ'],
      englishName: i['曲名_英語表記'],
      musicianName: i['作詞者名'],
      musicianFuriganaName: i['作詞者名_フリガナ'],
      musicianEnglishName: i['作詞者名_英語表記']?.toString(),
      composerName: i['作曲者名'],
      composerFuriganaName: i['作曲者名_フリガナ'],
      composerEnglishName: i['作曲者名_英語表記']?.toString(),
      arrangerName: i['編曲者名']?.toString(),
      arrangerFuriganaName: i['編曲者名_フリガナ'],
      arrangerEnglishName: i['編曲者名_英語表記']?.toString(),
      showTime: convertShowTime(i['演奏時間']),
      versionIdentificationType: convertVersionIdentificationType(i['原盤識別']),
      ivtType: convertIvtType(i['IVT識別']),
      liveType: convertLiveType(i['ライブ区分']),
      authorizedGroupType: groupType,
      jasracCode: jasracCode
        ? checkAutoFormatJasrac(
            jasracCode
              .toString()
              .replace(/[^\w\s]/gi, '')
              .slice(0, 10),
          )
        : '',
      authorizedGroup: i['その他の場合 入力'],
      otherJasracCode: i['JASRAC以外の作品コード']?.toString(),
      isrcCode: i['ISRC'],
      lyricsClassifyType: i['原詞訳詞区分'],
      informationFee: i['情報料（税抜）'] ? i['情報料（税抜）']?.toString() : 0,
      lyricInformationFee: 0,
      includeJasracContent: variation?.includeJasracContent,
    };
  });

  let result = [];

  result = dataConvert.filter((el: any) => el?.name || el.composerName);

  const validationResult = await schema.validate(result, { abortEarly: false }).catch((err) => {
    console.log(err);
    return err;
  });

  const isErr = validationResult?.inner || null;

  result = validationResult?.length > 0 ? validationResult : [];

  if (isErr || result?.length === 0) {
    toast(`${files[0]?.name} ファイルのアップロードに失敗しました。`, { type: 'error' });
  }

  return isErr ? null : result;
};
