import React, { useEffect, useState } from 'react';
import { Button, Col, Container } from 'react-bootstrap';
import SearchProductsDetail from './SearchProductsDetail';
import Filter from 'Components/Common/Filter/Filter';
import Tabs from 'Components/Common/Tabs/Tabs';
import ConfirmationComment from './TabProducts/ConfirmationComment';
import CampaignInformation from './TabProducts/CampaignInformation';
import TitleReview from './TabProducts/TitleReview';
import { StringParam, useQueryParams } from 'use-query-params';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import SampleMasterDp from './TabProducts/SampleMasterDp';

const btnGroupOptions = [
  { name: 'タイトル情報', value: 0 },
  { name: 'キャンペーン情報', value: 1 },
  { name: '確認コメント', value: 2 },
  { name: 'サンプル配信', value: 3 },
];

export default function ProductsDetail(): JSX.Element {
  const [indexTabDefault, setIndexTabDefault] = useState<number>(2);
  const [tabActive, setTabActive] = useState(btnGroupOptions[2].name);
  const { id }: { id: string } = useParams();
  const location = useLocation<any>();

  const history = useHistory();
  const handleChangeTabs = (tab: string) => {
    if (!tab) return;
    setQuery({ ...query });
    setTabActive(tab);
  };

  const [query, setQuery] = useQueryParams({
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistId: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
  });

  useEffect(() => {
    id && setQuery({ titleIds: id, queryTitle: query?.queryTitle });
    if (location?.state?.isSaleId) {
      setIndexTabDefault(0);
    }
  }, [id]);

  const renderTab = () => {
    switch (tabActive) {
      case btnGroupOptions[0].name:
        return <TitleReview />;

      case btnGroupOptions[1].name:
        return <CampaignInformation />;

      default:
        return <ConfirmationComment />;
    }
  };

  return (
    <>
      <Filter filterClass="d-flex align-items-end shadow-sm">
        {tabActive !== btnGroupOptions[3].name ? (
          <SearchProductsDetail titleIdTransfer={id} />
        ) : (
          <></>
        )}

        <Col className="px-1 d-flex justify-content-end">
          <Button
            hidden={tabActive !== btnGroupOptions[0].name}
            onClick={() => history.push(`/title-detail/${query.titleIds}`, { isEdit: true })}
            className="me-3"
            disabled={!query.titleIds}
          >
            タイトルを編集
          </Button>
          <Tabs
            options={btnGroupOptions}
            tabActive={tabActive}
            onChange={handleChangeTabs}
            indexDefault={indexTabDefault}
          />
        </Col>
      </Filter>
      <Container fluid className="mt-4 px-4">
        {query?.titleIds && tabActive !== btnGroupOptions[3].name ? renderTab() : <></>}
        {tabActive === btnGroupOptions[3].name && <SampleMasterDp />}
      </Container>
    </>
  );
}
