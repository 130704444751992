import React, { PropsWithChildren, useState, useEffect } from 'react';
import { useAppSelector } from 'App/Store';
import { RoleNames } from 'Constant/Roles';

export type ROLES = 'MASTER' | 'PIC' | 'COMMON' | 'EC_COMPANY_MASTER';

interface PermissionProps {
  pic?: boolean;
}

function PermissionWrapper({ children, pic }: PropsWithChildren<PermissionProps>): JSX.Element {
  const { role } = useAppSelector((state) => state.auth);
  const [isDisabled, setIsDisabled] = useState(
    // role !== RoleNames[0] && role !== RoleNames[4] && role !== RoleNames[6],
    role !== RoleNames[0] && role !== RoleNames[4] && role !== RoleNames[5],
  );

  useEffect(() => {
    if (pic) setIsDisabled(false);
  }, [pic]);

  return <div className={isDisabled ? 'pe-none' : 'pe-auto'}>{children}</div>;
}
export default PermissionWrapper;
