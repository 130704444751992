import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Container, Row, Spinner } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import ActionButtons from 'Components/Page/Campaign/RegisterCampaign/Common/ActionButtons';
import { useAppSelector } from 'App/Store';
import StepConfirmView from 'Components/Page/Campaign/RegisterCampaign/Step5/StepConfirmView';
import { CampaignStatus } from 'Constant/Campaign';
import useCampaign from 'Hooks/useCampaign';
import CampaginOfficialSchema from 'Components/Page/Campaign/RegisterCampaign/Schemas/CampaginOfficialSchema';
import ModalComponent from 'Components/Common/Modal';
import { exportPDF, loadVideoEvent, calcMb, drawnCanvas } from 'Utils/File';
import { CampaignApis } from 'Datasource/Campaign';
import { filter } from 'lodash';
import { CampaignSteps } from 'App/Features/Campaign';
import { getNameBrower } from 'Utils/Brower';
import { useOnLoadImages } from 'Hooks/useOnLoadImages';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';
import { updateQuery } from 'Utils/Search';
import { getMsgExceed100 } from 'Utils/Campaign';

const StepConfirm = (props: any): JSX.Element => {
  const { overview, courses, step, id, errorRealTime } = useAppSelector((state) => state.campaign);
  const {
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CampaginOfficialSchema),
  });

  const confirmViewRef = useRef<HTMLDivElement>(null);
  const imagesLoaded = useOnLoadImages(confirmViewRef);
  const { openMessage } = useMessage();

  const imgTag = document.querySelector('img');
  const {
    saveOrUpdateOfficial,
    saveOrUpdateDraft,
    handleChangeStep,
    disabledCourseRealtime1,
    disabledCourseRealtime3,
    isLoading,
  } = useCampaign();
  const [visible, setVisible] = useState<boolean>(false);
  const [outOfMemory, setOutOfMemory] = useState<boolean>(false);
  const [videoImgs, setVideoImgs] = useState<string[]>([]);
  const [exporting, setExporting] = useState<boolean>(false);
  const [disabledBtnSave, setDisabledBtnSave] = useState<boolean>(false);
  useEffect(() => {
    const videos: NodeListOf<HTMLVideoElement> = document.querySelectorAll(
      'video.video-prize-base64',
    );
    videoLoop(videos);
  }, []);

  const videoLoop = async (videos: any) => {
    const urlVideos: string[] = [];
    for (const video of videos as any) {
      const url = await loadVideoEvent(video);
      urlVideos.push(`${url}`);
    }
    setVideoImgs(urlVideos);
  };

  const handleSaveOfficial = useCallback(() => {
    updateQuery('', async () => {
      if (!overview?.status && (props?.campaignId || id)) {
        await saveOrUpdateDraft({
          courses,
          overview,
          id: props?.campaignId || id,
          status: CampaignStatus.Official,
          step: step,
        });
      } else {
        await saveOrUpdateOfficial({ courses, overview, id: props?.campaignId });
      }
    });
    setVisible(isLoading);
  }, [
    courses,
    id,
    overview,
    props?.campaignId,
    saveOrUpdateDraft,
    saveOrUpdateOfficial,
    step,
    isLoading,
  ]);

  const handleSaveDraft = useCallback(() => {
    updateQuery('', () => {
      if (!props?.handleValidateDraft()) {
        handleChangeStep(CampaignSteps.Overview);
        props?.setBackValidate();
        return;
      }

      if (outOfMemory) {
        openMessage({
          variant: 'error',
          message: '容量に達したため登録できません。',
        });
        return;
      }

      if (errorRealTime?.prizeQuantityTooSmall) {
        openMessage({
          variant: 'error',
          message: getMsgExceed100(courses, errorRealTime?.courseName),
        });
        return;
      }

      if (errorRealTime?.exceed100) {
        openMessage({
          variant: 'error',
          message: getMsgExceed100(courses, errorRealTime?.courseName),
        });
        return;
      }

      saveOrUpdateDraft({
        overview,
        courses,
        id: props?.campaignId,
      });
    });
  }, []);

  useEffect(() => {
    reset({
      ...overview,
      courses: courses,
    });
  }, []);

  const [overCapacityMessage, setOverCapacityMessage] = useState<string>('');

  const handleGetCapacityUsed = async () => {
    const variationIds: string = courses
      ?.map((course: any) => {
        const ids = filter(course.courseInfos, (info) => info.enabled && info.variationId)
          ?.map((variation) => variation.variationId)
          .toString();
        return ids;
      })
      .toString();

    if (!variationIds) return;
    const {
      data: { data },
    } = await CampaignApis.getDigitalConfig({
      vIds: variationIds,
      titleId: overview?.titleId,
    });

    const used = data?.campaigns.reduce((total: number, item: any) => {
      const usedSize = item.campaignId === +props?.campaignId ? 0 : Number(item.usedSize);
      return usedSize + total;
    }, 0);

    if (data?.limitStorage < overview?.dataSize + used) {
      setOverCapacityMessage(
        `${calcMb(data?.limitStorage - overview?.dataSize)}MB以内のファイルを登録してください。`,
      );
      setOutOfMemory(true);
    }
  };

  useEffect(() => {
    handleGetCapacityUsed();
  }, []);

  return (
    <div>
      <SpinnerComponent isLoading={(imgTag && !imagesLoaded) || isLoading ? true : false} />
      <Container>
        <Row className="justify-content-end my-3">
          <Button
            variant="primary"
            className="btn-excel bg-red-orange btn-focus-none"
            onClick={async () => {
              setExporting(true);

              const prmCanvas = drawnCanvas('campaign-detail', videoImgs);
              const prmPdf = exportPDF('campaign-detail', videoImgs);
              if (getNameBrower() === 'safari') {
                await Promise.all([prmCanvas, prmCanvas, prmCanvas, prmPdf]);
              } else {
                await prmPdf;
              }

              setExporting(false);
            }}
            disabled={exporting}
          >
            {exporting ? (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'PDFダウンロード'
            )}
          </Button>
        </Row>
      </Container>
      <div id="campaign-detail" ref={confirmViewRef}>
        <StepConfirmView
          overview={overview}
          courses={courses}
          errors={errors}
          overCapacityMessage={overCapacityMessage}
        />
        <div id="sp-div" className="d-none" style={{ height: '40px' }}></div>
      </div>
      <ActionButtons
        handleBack={() => handleChangeStep(step - 1)}
        handleSaveDraft={handleSaveDraft}
        handleSave={async () => {
          if (outOfMemory) {
            openMessage({
              variant: 'error',
              message: '容量に達したため登録できません。',
            });
            return;
          }
          const isValid = await trigger();
          if (isValid) {
            setVisible(true);
          } else {
            setDisabledBtnSave(true);
          }
        }}
        disabledSave={disabledBtnSave}
        courses={courses}
      />
      <ModalComponent
        show={visible}
        onCloseModal={() => setVisible(false)}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="primary"
        onSubmitModal={() => {
          if (disabledCourseRealtime1(courses)) {
            openMessage({
              variant: 'error',
              message: '上限ありの賞を追加してください',
            });
            setVisible(false);
            return;
          }

          if (disabledCourseRealtime3(courses)) {
            openMessage({
              variant: 'error',
              message: 'キャンペーンパターンは正しくないので登録できません。',
            });
            setVisible(false);
            return;
          }

          if (errorRealTime?.prizeQuantityTooSmall) {
            openMessage({
              variant: 'error',
              message: getMsgExceed100(courses, errorRealTime?.courseName),
            });
            setVisible(false);
            return;
          }

          if (errorRealTime?.exceed100) {
            openMessage({
              variant: 'error',
              message: getMsgExceed100(courses, errorRealTime?.courseName),
            });
            setVisible(false);
            return;
          }

          if (errorRealTime?.max100) {
            openMessage({
              variant: 'error',
              message: getMsgExceed100(courses, errorRealTime?.courseName),
            });
            setVisible(false);
            return;
          }
          handleSaveOfficial();
        }}
      >
        <div>
          <p className="mb-2">キャンペーンを登録します。よろしいですか？</p>
        </div>
      </ModalComponent>
    </div>
  );
};

export default StepConfirm;
