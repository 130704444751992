import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  CartesianGrid,
  LabelList,
} from 'recharts';

const data = [
  { name: '80', pv: [1000, 1000], color: '#9E480E' },
  { name: '70', pv: [1000, 1000], color: '#264478' },
  { name: '60', pv: [940, 970], color: '#71AD49' },
  { name: '40', pv: [390, 550], color: '#FFC004' },
  { name: '50', pv: [550, 940], color: '#5B9BD5' },
  { name: '30', pv: [160, 390], color: '#A5A5A5' },
  { name: '20', pv: [0, 160], color: '#F8CBAD' },
  { name: '10', pv: [0, 160], color: '#BDD7EE' },
];

const LegendCustom = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '30px 0 10px',
      }}
    >
      {data.map((item, index) => (
        <div key={index} style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '10px',
              height: '10px',
              marginRight: '5px',

              background: item.color,
            }}
          />
          {item.name}
          <span style={{ fontSize: '12px' }}>代</span>
        </div>
      ))}
    </div>
  );
};
const AgeChart = (): JSX.Element => {
  return (
    <>
      <LegendCustom />
      <ResponsiveContainer width={'100%'} height={'80%'} debounce={50}>
        <BarChart
          data={data}
          layout="vertical"
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid horizontal={false} />
          <XAxis
            axisLine={false}
            tickLine={false}
            type="number"
            dataKey="pv"
            tickCount={6}
            tickFormatter={(value) => (Number(value) ? `${(value * 10) / 100}%` : `0%`)}
          />
          <YAxis yAxisId={0} hide dataKey="pv" type="category" />
          {/* <YAxis
            hide
            // orientation="right"
            yAxisId={1}
            dataKey="name"
            type="category"
            axisLine={false}
            tickLine={false}
            mirror
          /> */}
          <Bar dataKey="pv" minPointSize={2} barSize={32}>
            {data.map((item) => {
              return (
                <>
                  <LabelList
                    className="text-white"
                    dataKey="name"
                    position="insideRight"
                    style={{
                      fontSize: '80%',
                      fill: '#000',
                    }}
                    formatter={(value: string) => `${value}%`}
                  />
                  <Cell key="pv" fill={item.color} />;
                </>
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default AgeChart;
