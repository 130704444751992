import { setCapacity, setOption, setStatusFetchOption } from 'App/Features/Title';
import { useAppDispatch } from 'App/Store';
import CapacityField from 'Components/Page/Title/Variation/CapacityField';
import { TitleApi } from 'Datasource/TitleApi';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { calcMb, isReviewTitle } from 'Utils/File';
// import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { toast } from 'react-toastify';
import { formatCurrency } from 'Utils/Numbers';

interface CardCapacityProps {
  variation: any;
  titleId?: string;
  getValueOption: (option: any) => void;
}

export default function CardCapacity({
  variation,
  titleId,
  getValueOption,
}: PropsWithChildren<CardCapacityProps>): JSX.Element {
  const dispatch = useAppDispatch();
  const [capacityNumber, setCapacityNumber] = useState<number>(0);
  const [totalSong, setTotalSong] = useState<number>(0);
  // const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([]);
  const [chooseOption, setChooseOption] = useState<any>({});
  const [usedTokutenCampaign, setUsedTokutenCampaign] = useState<number>(0);

  const getOptions = async () => {
    try {
      // setLoading(true);
      dispatch(setStatusFetchOption({ id: variation.id, status: true }));
      const {
        data: { data },
      } = await TitleApi.getOptions();
      setOptions(data);
      setChooseOption(variation?.option.id ? variation?.option : data[0]);
      if (!variation?.option?.id) {
        dispatch(setOption({ option: data[0], variationIndex: variation?.index }));
        getValueOption(data[0]);
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      dispatch(setStatusFetchOption({ id: variation.id, status: false }));
      // setLoading(false);
    }
  };

  const getUsedCapacityOfCampaign = async (id: string) => {
    try {
      // setLoading(true);
      dispatch(setStatusFetchOption({ id: variation.id, status: true }));
      const {
        data: { data },
      } = await TitleApi.getUsedCapacityOfCampaign({ titleId: id });
      const campaignSizeArr = data.map((item: any) => {
        return Number(item.totalSize);
      });
      const highestCampaignSize = Math.max(...campaignSizeArr);
      if (data?.length > 0) {
        setUsedTokutenCampaign(calcMb(highestCampaignSize));
      } else {
        setUsedTokutenCampaign(0);
      }
      // if (data?.length > 0) {
      //   const sizeCampaign = data.reduce(
      //     (total: number, value: any) => (total = total + (value?.totalSize || 0)),
      //     0,
      //   );
      //   setUsedTokutenCampaign(calcMb(sizeCampaign));
      // }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      // setLoading(false);
      dispatch(setStatusFetchOption({ id: variation.id, status: false }));
    }
  };

  useEffect(() => {
    getOptions();
    titleId && getUsedCapacityOfCampaign(titleId);
  }, []);

  useEffect(() => {
    variation?.option.id && setChooseOption(variation?.option);
  }, [variation]);

  const sumCount = (array: any) => {
    return array?.reduce((total: number, value: any) => (total = total + value?.size || 0), 0);
  };

  // HANDLE: Calculate capacity
  useEffect(() => {
    // CAPACITY
    const { images, videos, booklets, songs, index } = variation;
    const capacityVideo = sumCount(videos);

    const capacityImage = sumCount(images);

    const capacityBooklets = sumCount(booklets);

    const capacitySong = sumCount(songs);

    const totalCapacity = capacityVideo + capacityImage + capacityBooklets + capacitySong;
    setCapacityNumber(calcMb(totalCapacity));

    // MAX SONG
    const numberOfSong = songs.length || 0;
    let numberOfSongVideo = 0;
    videos?.forEach((el: any) => {
      numberOfSongVideo = el?.songs?.length + numberOfSongVideo;
    });

    const currentCountSong = numberOfSong + numberOfSongVideo;
    setTotalSong(currentCountSong);
    dispatch(setCapacity({ capacity: totalCapacity, variationIndex: index }));
  }, [variation]);

  // HANDLE: CHOOSE OPTION CAPACITY
  // useEffect(() => {
  //   DATA.forEach((el: any, index: number) => {
  //     if (
  //       (capacityNumber < el.maxVariationStorage && capacityNumber > DATA[index - 1]?.maxVariationStorage) ||
  //       (totalSong < el.maxSongCount && totalSong > DATA[index - 1]?.maxSongCount)
  //     ) {
  //       setChooseOption(DATA[index]);
  //     }
  //   });
  // }, [capacityNumber, totalSong]);

  // HANDLE CHOOSE OPTION
  const handleOnClickOption = (index: number) => {
    if (isReviewTitle('option', variation)) return;
    if (
      capacityNumber <= options[index].maxVariationStorage &&
      totalSong <= options[index].maxSongCount &&
      usedTokutenCampaign <= options[index].limitTokutenStorage
    ) {
      setChooseOption(options[index]);
      dispatch(setOption({ option: options[index], variationIndex: variation?.index }));
      getValueOption(options[index]);
      return;
    }
    let message = '';
    if (capacityNumber >= options[index].maxVariationStorage) {
      message = `登録されたDPデータ容量は${options[index]?.maxVariationStorage}MBを超えているため、選択できません。`;
    }
    if (totalSong >= options[index].maxSongCount) {
      message = `登録された楽曲数は${options[index].maxSongCount}楽曲を超えているため、選択できません。`;
    }
    if (usedTokutenCampaign >= options[index].limitTokutenStorage) {
      message = `登録された特典データ容量は${options[index].limitTokutenStorage}MBを超えているため、選択できません。`;
    }

    toast(message, {
      type: 'error',
    });
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3">
        {/* <SpinnerComponent isLoading={loading} /> */}
        <Form.Label column md="2">
          データ使用量
        </Form.Label>
        <Col md="10">
          {options.length > 0 && (
            <CapacityField
              data={{
                used: capacityNumber,
                notUsedYet: chooseOption?.maxVariationStorage - capacityNumber,
                typeInfo: chooseOption,
              }}
            />
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column md="8">
          収録タイプ<span className="txt-require">*</span>
          <span className="ps-4 ms-4 fs-12">
            ※ 楽曲登録数は「動画コンテンツ」の収録曲数を含みます
          </span>
        </Form.Label>
      </Form.Group>

      <Row className="ms-4 row overflow-x-auto flex-nowrap mb-4 mw-capacity">
        {options.map((element: any, index: number) => {
          return (
            <Card
              onClick={() => handleOnClickOption(index)}
              key={index}
              className="shadow-sm p-0 w-19 mx-1 mb-2 card"
            >
              <Card.Header className="bg-dark white p-0 text-center">
                <p className="text-truncate p-1">
                  <span>{element?.titleType}</span>
                </p>
              </Card.Header>
              <Card.Body className="p-0 pt-2">
                <Row>
                  <Col>DPデータ容量</Col>
                  <Col className="fw-bolder">{`${element?.maxVariationStorage}MB以内`}</Col>
                </Row>
                <Row>
                  <Col>特典データ容量</Col>
                  <Col className="fw-bolder">{`${element?.limitTokutenStorage}MB以内`}</Col>
                </Row>
                <Row>
                  <Col>楽曲登録数</Col>
                  <Col className="fw-bolder">{`${element?.maxSongCount}曲以内`}</Col>
                </Row>
                <Row>
                  <Col>販売最低金額（税込）</Col>
                  <Col className="fw-bolder">{`¥${formatCurrency(+element?.minPrice)}以上`}</Col>
                </Row>
                <div
                  className={`text-center h-30 rounded-bottom border-top mt-2 ${
                    chooseOption.id === element.id && 'bg-primary'
                  }`}
                >
                  <p className={`pt-1 ${chooseOption.id === element.id && 'text-light'}`}>選択</p>
                </div>
              </Card.Body>
            </Card>
          );
        })}
      </Row>
    </>
  );
}
