import PaginationComponent from 'Components/Common/Pagination/Pagination';
import React, { useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Table from 'Components/Page/PaymentNotice/Table';

export default function TableList(): JSX.Element {
  const columns = useMemo(
    () => [
      {
        Header: 'カードID',
        accessor: 'id',
        maxWidth: 100,
        width: 100,
      },
      {
        Header: 'タイトル',
        accessor: 'title',
        maxWidth: 500,
        width: 400,
      },
      {
        Header: 'バリエーション',
        accessor: 'variation',
        maxWidth: 500,
        width: 400,
      },
      {
        Header: '特典名称',
        accessor: 'benefitName',
        maxWidth: 500,
        width: 400,
      },
      {
        Header: 'アーティスト',
        accessor: 'artist',
        maxWidth: 300,
        width: 200,
      },
      {
        Header: 'タイプ',
        accessor: 'type',
        maxWidth: 300,
        width: 200,
      },
      {
        Header: '発売日',
        accessor: 'releaseDate',
        maxWidth: 200,
        width: 150,
      },
      {
        Header: '販売価格',
        accessor: 'sellingPrice',
        maxWidth: 200,
        width: 150,
      },
      {
        Header: '総数',
        accessor: 'totalNumber',
        maxWidth: 200,
        width: 150,
      },
      {
        Header: '販売金額',
        accessor: 'price',
        maxWidth: 200,
        width: 150,
      },
      {
        Header: '生成費',
        accessor: 'generationCost',
        maxWidth: 200,
        width: 150,
      },
      {
        Header: '運営費',
        accessor: 'operatingExpenses',
        maxWidth: 200,
        width: 150,
      },
      {
        Header: '手数料',
        accessor: 'commission',
        maxWidth: 200,
        width: 150,
      },
      {
        Header: '支払金額',
        accessor: 'payment',
        maxWidth: 200,
        width: 150,
      },
    ],
    [],
  );

  const data = useMemo(
    () => [
      {
        id: 1,
        title: '夏なんだよ',
        variation: '初回限定バージョン',
        benefitName: 'ひなこ限定チェキ画像付き+オリジナルボイス',
        artist: 'Artist name',
        type: 'ミニアルバム',
        releaseDate: '2021/11/10',
        sellingPrice: '1,000',
        totalNumber: '3,000',
        price: '3,000',
        generationCost: '1,000',
        operatingExpenses: '3,000',
        commission: '3,000',
        payment: '3,000',
      },
      {
        id: 2,
        title: '夏なんだよ夏なんだよ夏なんだよ',
        variation: '初回限定バージョン',
        benefitName: 'ひなこ限定チェキ画像付き+オリジナルボイス',
        artist: 'Artist name',
        type: 'ミニアルバム',
        releaseDate: '2021/11/10',
        sellingPrice: '1,000',
        totalNumber: '3,000',
        price: '3,000',
        generationCost: '1,000',
        operatingExpenses: '3,000',
        commission: '3,000',
        payment: '3,000',
      },
      {
        id: 3,
        title: '夏なんだよ',
        variation: '初回限定バージョン',
        benefitName: 'ひなこ限定チェキ画像付き+オリジナルボイス',
        artist: 'Artist name',
        type: 'ミニアルバム',
        releaseDate: '2021/11/10',
        sellingPrice: '1,000',
        totalNumber: '3,000',
        price: '3,000',
        generationCost: '1,000',
        operatingExpenses: '3,000',
        commission: '3,000',
        payment: '3,000',
      },
      {
        id: 4,
        title: '夏なんだよ',
        variation: '初回限定バージョン',
        benefitName: 'ひなこ限定チェキ画像付き+オリジナルボイス',
        artist: 'Artist name',
        type: 'ミニアルバム',
        releaseDate: '2021/11/10',
        sellingPrice: '1,000',
        totalNumber: '3,000',
        price: '3,000',
        generationCost: '1,000',
        operatingExpenses: '3,000',
        commission: '3,000',
        payment: '3,000',
      },
    ],
    [],
  );
  return (
    <div>
      <Container fluid className="px-4">
        <Row className="mt-2">
          <Col md={12} className="d-flex justify-content-end p-0">
            <PaginationComponent activePage={1} total={75} perPage={15} />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12} className="d-flex justify-content-end p-0">
            <Table
              columns={columns}
              data={data}
              getHeaderProps={() => {
                return {
                  className: `text-white bg-dark d-flex`,
                  style: { cursor: 'default' },
                };
              }}
              getColumnProps={() => {
                return {
                  className: 'text-center',
                };
              }}
              getRowProps={() => {
                return {
                  style: {},
                };
              }}
              getCellProps={(cellInfo) => {
                return {
                  className: `bg-white ${
                    cellInfo.column.id === 'benefitName'
                      ? 'text-primary text-decoration-underline'
                      : 'text-dark'
                  }`,
                };
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
