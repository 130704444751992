import React, { useState } from 'react';
import { filter, map } from 'lodash';
import { Song } from 'Types/App/title';
import BoxForm from 'Components/Common/Form/BoxForm';
import SongTable from 'Components/Page/Title/Variation/SongTable';
import { useAppDispatch, useAppSelector } from 'App/Store';
import { setCaculateFee, setVariationSongs } from 'App/Features/Title';
import { getNumberRemainSong } from 'Utils/Title';
import { INCLUDE_JARSAC_CONTENT, TYPE_MOVE } from 'Constant/Title';
import ModalComponent from 'Components/Common/Modal';
import useMessage from 'Hooks/useMessage';

interface BoxSongTypes {
  isReview?: boolean;
  variationName?: string;
  variationIndex: number;
  errors?: any[];
  setSongsToForm?: (songs: Song[]) => void;
  onClickError?: () => void;
  isDiabledEditSong?: boolean;
}

const BoxSong = (props: BoxSongTypes): JSX.Element => {
  const dispatch = useAppDispatch();
  const [visableModalDelete, setVisibleModalDelete] = useState<any>(null);
  const { titleForm } = useAppSelector((state) => state.title);
  const variation = titleForm.variations[props.variationIndex];
  const songList = variation?.songs;
  const numberRemainSong = getNumberRemainSong(titleForm, props.variationIndex);
  const { openMessage } = useMessage();

  const handleImportSong = async (data: any) => {
    const currentSongs: Song[] = songList ? [...songList] : [];
    const addIndexData = data.map((el: any, index: number) => {
      const obj = { ...el, index: index };
      return obj;
    });

    const numberOfSong = numberRemainSong + +currentSongs?.length;

    const dataAdd = await addIndexData.slice(0, numberOfSong);

    if (dataAdd?.length === 0 && numberOfSong === 0 && data.length > 0) {
      openMessage({
        variant: 'error',
        message: `楽曲登録数は${variation?.option?.maxSongCount}楽曲を超えているため、アップロードできません。`,
      });
    }

    dispatch(
      setVariationSongs({
        songs: dataAdd,
        variationIndex: props.variationIndex,
      }),
    );
    //* after import songs => caculate informationFee
    if (
      dataAdd?.length > 0 &&
      variation?.includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE &&
      variation?.price
    ) {
      dispatch(setCaculateFee(true));
    }
  };

  const handleAddSong = () => {
    const newSongs: Song[] = songList ? [...songList] : [];
    const maxIndex = newSongs.length;
    if (numberRemainSong === 0) return;
    newSongs.push({
      index: maxIndex,
    });
    dispatch(setVariationSongs({ songs: newSongs, variationIndex: props.variationIndex }));
    if (variation?.includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE && variation.price) {
      dispatch(setCaculateFee(true));
    }
  };

  const handleDeteleSong = () => {
    const song = visableModalDelete;
    let songs: Song[] = songList ? [...songList] : [];
    songs = filter(songs, (itemSong: Song) => itemSong.index !== song.index);
    songs = map(songs, (song: Song, index: number) => ({ ...song, index: index }));
    dispatch(setVariationSongs({ songs, variationIndex: props.variationIndex }));
    if (variation?.includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE && variation.price) {
      dispatch(setCaculateFee(true));
    }
    setVisibleModalDelete(null);
  };

  const handleMoveItemSong = (item: Song, type: string) => {
    const indexChange = item.index;
    let songs: Song[] = songList ? [...songList] : [];
    if (type === TYPE_MOVE[0]) {
      if (indexChange === 0) return;
      const tmp = songs[indexChange - 1];
      songs[indexChange - 1] = songs[indexChange];
      songs[indexChange] = tmp;
      songs = map(songs, (song: Song, index: number) => ({ ...song, index }));
    }
    if (type === TYPE_MOVE[1]) {
      if (indexChange === songs.length - 1) return;
      const tmp = songs[indexChange + 1];
      songs[indexChange + 1] = songs[indexChange];
      songs[indexChange] = tmp;
      songs = map(songs, (song: Song, index: number) => ({ ...song, index }));
    }
    dispatch(setVariationSongs({ songs, variationIndex: props.variationIndex }));
  };

  return (
    <BoxForm
      title="楽曲コンテンツ"
      classBox="box-form-no-shadow"
      isGray={props.variationIndex % 2 ? true : false}
    >
      <SongTable
        {...props}
        handleAddSong={handleAddSong}
        songs={songList ?? []}
        handleDeteleSong={(song: Song) => setVisibleModalDelete(song)}
        handleImportSong={handleImportSong}
        numberRemainSong={numberRemainSong}
        handleMoveItemSong={handleMoveItemSong}
        variation={variation}
        variationName={variation?.name || ''}
        titleName={titleForm?.name}
      />
      <ModalComponent
        show={visableModalDelete}
        onCloseModal={() => setVisibleModalDelete(null)}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="danger"
        title="削除確認"
        onSubmitModal={() => handleDeteleSong()}
        confirmText="削除する"
      >
        <div>
          <p className="mb-2">楽曲を削除します。よろしいですか。</p>
        </div>
      </ModalComponent>
    </BoxForm>
  );
};

export default BoxSong;
