import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Nav, NavItem, NavLink, Container } from 'react-bootstrap';
import { useParams, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import TitleInformation from 'Components/Page/Title/TitleInfo';
import Variations from 'Components/Page/Title/Variations';
import Review from 'Components/Page/Title/Review';
import { useAppDispatch, useAppSelector } from 'App/Store';
import { Step } from 'Types/App/title';
import { STEPS, TITLE_INIT, VARIATION_INIT } from 'Constant/Title';
import { setTitleForm, setForm, addVariationAction, setFetchFail } from 'App/Features/Title';
import { TitleApi } from 'Datasource/TitleApi';
import { setCurrentStep } from 'App/Features/Title';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { getBool } from 'Utils/Permission';
import { handleConvertTitleInfo } from 'Utils/Title';
import useMessage from 'Hooks/useMessage';
import Final from 'Components/Page/Title/Final';
import { RolesRecord } from 'Constant/Roles';

interface ParamsURL {
  id?: string;
}

export default function TitleRegister(): JSX.Element {
  const { openMessageError } = useMessage();
  const { id }: ParamsURL = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation<any>();
  const titleState = useAppSelector((state) => state.title);
  const authState = useAppSelector((state) => state.auth);
  const callbackRef = useRef<{ handleNextStepFromParent: () => void }>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isRoleManager, setIsRoleManager] = useState<boolean>(true);
  const {
    titleForm: { managerList },
    fetchTitleFail,
  } = useAppSelector((state) => state.title);

  const tabSteps: { label: string; eventKey: Step }[] = [
    {
      label: 'マスタタイトル作成',
      eventKey: STEPS.CREATE_TITLE_INFO,
    },
    {
      label: 'バリエーション作成',
      eventKey: STEPS.CREATE_VARIATION,
    },
    {
      label: '登録内容確認',
      eventKey: STEPS.REVIEW,
    },
  ];

  const handleGetTitle = async (id: string) => {
    if (!id) return;
    try {
      setLoading(true);
      const {
        data: { data },
      } = await TitleApi.getTitle({ id });

      const titleInfo = handleConvertTitleInfo(data);
      dispatch(setTitleForm(titleInfo));
      dispatch(setFetchFail(false));
      if (isEmpty(titleInfo.variations)) {
        dispatch(addVariationAction({ variation: { ...VARIATION_INIT } }));
      }

      // GET ROLE USER
      const { role } = authState;
      if (role === RolesRecord[0]) {
        setIsRoleManager(true);
      } else {
        const response = await TitleApi.isRoleArtistManager({ artistId: data.artistId });
        setIsRoleManager(response?.data?.data);
      }
    } catch (error) {
      dispatch(setFetchFail(true));
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleGetTitle(id);
      if (location?.state?.isEdit) return;
      dispatch(setCurrentStep(STEPS.REVIEW));
      return;
    }

    return () => {
      dispatch(setForm(TITLE_INIT));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * set form to init when out /title-detail/:id
   */
  useEffect(() => {
    if (!id) {
      return;
    }

    return () => {
      dispatch(setForm(TITLE_INIT));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (location.pathname === '/title/register') {
      dispatch(setForm(TITLE_INIT));
      dispatch(setCurrentStep(STEPS.CREATE_TITLE_INFO));
      setIsRoleManager(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const getFetchData = async () => {
    if (!id) return;
    try {
      setLoading(true);
      const {
        data: { data },
      } = await TitleApi.getTitle({ id });

      const dataPairing = { ...data, managerList: managerList };

      const titleInfo = handleConvertTitleInfo(dataPairing);
      dispatch(setTitleForm(titleInfo));
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    // FIX only safari
    if (titleState?.currentStep === STEPS.FINAL) {
      getFetchData();
    }
  }, [titleState.currentStep]);

  const handleDisableNextStep = () => {
    let isDisabled = false;
    if (id && !getBool(titleState?.titleForm?.isAuthor)) isDisabled = true;
    return isDisabled;
  };

  const handleNextStep = (step: number) => {
    if (step === titleState.currentStep || handleDisableNextStep()) return;
    if (step === STEPS.FINAL) {
      dispatch(setCurrentStep(step));
    }
    if (step < titleState.currentStep) {
      dispatch(setCurrentStep(step));
    } else if (titleState.currentStep < STEPS.REVIEW) {
      callbackRef && callbackRef?.current?.handleNextStepFromParent();
    }
  };

  const renderTabs = (tab: Step) => {
    switch (tab) {
      case STEPS.CREATE_TITLE_INFO:
        return <TitleInformation ref={callbackRef} />;
      case STEPS.CREATE_VARIATION:
        return <Variations ref={callbackRef} />;
      case STEPS.REVIEW:
        return <Review ref={callbackRef} isRoleManager={isRoleManager} />;
      default:
        return <Final />;
    }
  };

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <div className="title-register">
        <Container fluid className="shadow-sm">
          <div className="py-4 d-flex align-items-center justify-content-center">
            <Nav
              variant="pills"
              defaultActiveKey={titleState.currentStep}
              activeKey={titleState.currentStep}
            >
              {tabSteps.map((item, index) => (
                <NavItem
                  key={index}
                  className={`title-item-step position-relative bg-gray ${
                    isRoleManager || 'pe-none opacity-75'
                  } ${!fetchTitleFail || 'pe-none opacity-75'}`}
                >
                  <NavLink
                    className="h-100 w-100 d-flex align-items-center justify-content-center"
                    eventKey={item.eventKey}
                    onClick={() => handleNextStep(item.eventKey)}
                  >
                    {item.label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </div>
        </Container>
        {renderTabs(titleState.currentStep)}
      </div>
    </>
  );
}
