import React from 'react';

const EnglishMsgUI = () => {
  return (
    <div className={`fs-11 mt-2 text-cherry px-3`}>
      <p>1. 入力可能な文字は、英数字、記号、全角スラッシュ「／」のみです。</p>
      <p>2. 名前は最大3つまでです。</p>
      <p>3. 全角スラッシュ「／」の前後に空白を含めることはできません。</p>
    </div>
  );
};

export default EnglishMsgUI;
