import React, { useEffect, useMemo, useState } from 'react';
import { Navbar, Container, Button, Image } from 'react-bootstrap';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { flatMap, map, find, some } from 'lodash';
import { BoxArrowRight } from 'react-bootstrap-icons';
import { MENUS } from 'Constant/Menu';
import { useAppDispatch, useAppSelector } from 'App/Store';
import { logout } from 'App/Features/Auth';
import { handleCheckDynamicRoutes } from 'Utils/Route';
import { default as LogoutConfirmationModal } from 'Components/Common/Modal';
import Logo from 'Static/Images/logo.png';

import { setTitlePage } from 'App/Features/Layout';
import { setHashPassword } from 'App/Features/HashPassword';
import { resetRevenueFilters } from 'App/Features/Revenue';
import { ROLE_RECORD_HEADER } from 'Constant/Roles';

const Header = (): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    auth: { email, company, role },
    layout: { titlePage },
  } = useAppSelector((state) => state);

  const [showLogoutConfirmationModal, setShowLogoutConfirmationModal] = useState(false);

  const handleCheckMatchLink = () => {
    let title = '';
    const flatMenus = flatMap(MENUS, (menu) => map(menu.children, (sub) => ({ ...sub })));
    const onSideBarCondition = some(flatMenus, (menu) => menu.slug === location.pathname);
    if (onSideBarCondition) {
      title = find(flatMenus, (menu) => menu.slug === location.pathname)?.title ?? '';
    } else {
      title = handleCheckDynamicRoutes(location)?.title ?? '';
    }

    dispatch(setTitlePage(title));
  };

  useEffect(() => {
    handleCheckMatchLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleLogout = () => {
    setShowLogoutConfirmationModal(false);
    dispatch(setHashPassword(''));
    dispatch(resetRevenueFilters());
    dispatch(logout());
    history.push('/login');
  };

  const headerRole = useMemo(() => {
    if (
      [
        ROLE_RECORD_HEADER.RCCompMaster,
        ROLE_RECORD_HEADER.RCCompManager,
        ROLE_RECORD_HEADER.RCCompManagerHigh,
        ROLE_RECORD_HEADER.RCCompManagerLow,
      ].includes(role)
    ) {
      //record company account
      return {
        title: 'レコード会社管理画面',
        bgColor: 'orange-brown',
      };
    } else if ([ROLE_RECORD_HEADER.ECCompMaster, ROLE_RECORD_HEADER.ECCompManager].includes(role)) {
      //miimEC account
      return {
        title: 'miim-EC管理画面',
        bgColor: 'blue',
      };
    } else if (
      [ROLE_RECORD_HEADER.SALEChanelMaster, ROLE_RECORD_HEADER.SALEChanelManager].includes(role)
    ) {
      //external EC account
      return {
        title: '外部EC管理画面',
        bgColor: 'gray',
      };
    } else {
      // admin account
      return {
        title: 'BootRock管理画面',
        bgColor: 'black',
      };
    }
  }, [role]);

  return (
    <>
      <LogoutConfirmationModal
        show={showLogoutConfirmationModal}
        onCloseModal={() => setShowLogoutConfirmationModal(false)}
        onSubmitModal={handleLogout}
        cancelText="キャンセル"
        confirmText="ログアウトする"
        title="ログアウト確認"
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
      >
        <div>ログアウトします。よろしいですか。</div>
      </LogoutConfirmationModal>
      <Navbar className={`header-top header-bg-${headerRole.bgColor}`}>
        <Container fluid>
          <div className="d-flex align-items-center w-100">
            <div className="px-sm-2 px-0 sidebar">
              <Link
                to="/"
                className="w-100 d-flex align-items-center text-white text-decoration-none fw-bold"
              >
                <span className="fs-14 d-none d-sm-inline">
                  <Image src={Logo} className="img-logo ps-4" />
                </span>
              </Link>
            </div>
            <div className="p-0 d-flex w-100">
              <Navbar.Brand href="" className="text-white ps-3 fs-16 fw-bold">
                {titlePage}
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
                <Navbar.Text className="text-white">
                  <span className="ms-2 me-3">{headerRole.title}</span>
                </Navbar.Text>
              </Navbar.Collapse>
              <Navbar.Collapse className="justify-content-end">
                <div className="text-white">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="me-2 text-truncate company-name">{company}</div>|
                    <div className="ms-2 me-3 text-truncate email">{email}</div>
                  </div>
                </div>
                <Button
                  variant="link"
                  onClick={() => setShowLogoutConfirmationModal(true)}
                  className="text-white me-3 logout"
                >
                  <BoxArrowRight /> ログアウト
                </Button>
              </Navbar.Collapse>
            </div>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
