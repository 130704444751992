import React, { FC, PropsWithChildren } from 'react';
import { Col } from 'react-bootstrap';
import { IPropDynamic } from 'Types/Common';

interface ColumnNoSpacingProps {
  rest?: IPropDynamic | undefined;
  md: number | string;
  colClass?: string;
}

const ColumnNoSpacing: FC<PropsWithChildren<ColumnNoSpacingProps>> = ({
  colClass,
  children,
  md,
  ...rest
}): JSX.Element => {
  return (
    <>
      <Col md={+md} className={`px-0 ${colClass ?? ''}`} {...rest}>
        {children}
      </Col>
    </>
  );
};

export default ColumnNoSpacing;
