/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import TableCommon from 'Components/Common/Table/TableCommon';
import { formatCurrency } from 'Utils/Numbers';
import { Cell } from 'react-table';
import { formatDecimal, getSum } from 'Utils/Numbers';
import AliTable, { AliColumn } from 'Components/Common/Table/AliTable';

//* TAX = 1 + 10%
const TAX = 1.1;
interface TableCostMiniProps {
  dataSource: any[];
  title?: string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TableCdDailyReport = ({ dataSource, title }: TableCostMiniProps): JSX.Element => {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'index',
        className: 'pe-none',
        columns: [
          {
            Header: '数量',
            width: '80',
            accessor: 'monthUnitAmount',
            className: 'hidden-sort',
            Cell: ({ row }: Cell<any>) => {
              return (
                <div>
                  {row?.original?.monthUnitAmount
                    ? formatCurrency(row?.original?.monthUnitAmount)
                    : ''}
                </div>
              );
            },
          },
          {
            Header: '単価',
            width: '80',
            accessor: 'averageUnitPrice',
            className: 'hidden-sort',
            Cell: ({ row }: Cell<any>) => {
              return (
                <div>
                  {row.original.averageUnitPrice
                    ? formatCurrency(row?.original?.averageUnitPrice)
                    : ''}
                </div>
              );
            },
          },
          {
            Header: '金額',
            width: '80',
            accessor: 'monthTotalCost',
            className: 'hidden-sort',
            Cell: ({ row }: Cell<any>) => {
              return (
                <div>
                  {row.original.monthTotalCost ? formatCurrency(row?.original?.monthTotalCost) : ''}
                </div>
              );
            },
          },
        ],
      },
    ],
    [],
  );

  const aliColumns = useMemo(
    () =>
      [
        {
          name: '数量',
          width: 80,
          code: 'amount',
          features: { sortable: false },
          align: 'center',
          render: (value: any) => {
            return <div className={`text-end`}>{value ? formatCurrency(value) : ''}</div>;
          },
        },
        {
          name: 'オプション料金',
          align: 'center',
          width: 160,
          children: [
            {
              name: '単価',
              width: 80,
              code: 'averageUnitPrice',
              align: 'center',
              render: (value: any) => {
                return <div className={`text-end`}>{value ? formatCurrency(value) : ''}</div>;
              },
            },
            {
              name: '金額',
              width: 80,
              code: 'dayTotalCost',
              align: 'center',
              render: (value: any) => {
                return <div className={`text-end`}>{value ? formatCurrency(value) : ''}</div>;
              },
            },
          ],
        },
      ] as AliColumn[],
    [],
  );

  if (!dataSource || (Array.isArray(dataSource) && dataSource.length === 0)) {
    return <></>;
  }

  return (
    <div className="d-flex flex-column me-3 w-fit">
      <div className="text-center d-flex align-items-center fw-bold">{title}</div>
      <TableCommon
        data={dataSource || []}
        columns={columns}
        messageNoData={null}
        getHeaderProps={() => {
          return {
            className: 'text-white d-flex align-items-center bg-dark text-center',
          };
        }}
        getColumnProps={() => {
          return {
            className: 'text-end',
          };
        }}
        getRowProps={() => {
          return {
            style: { minHeight: '32px' },
          };
        }}
        getCellProps={() => {
          return {
            className: 'bg-white text-dark',
          };
        }}
      />
      <AliTable
        wrapperClass="max-w-240px daily-report-table"
        heightBody="auto"
        dataSource={dataSource[0]?.days || []}
        columns={aliColumns}
        noDataText={null}
      ></AliTable>
    </div>
  );
};

export default TableCdDailyReport;
