import CardContainer from 'Components/Common/CardContainer';
import MessageError from 'Components/Common/MessageError';
import GroupTextProps from 'Components/Page/Title/FormTextGroup';
import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { formatPrice } from 'Utils/Numbers';
import { convertToSeconds } from 'Utils/Title';
import AudioCustom from 'Components/Common/AudioCustom/Audio';
import { useAppSelector } from 'App/Store';
import { findLastIndex } from 'lodash';
import { LINK_JMD_TYPE_CONTENT } from 'Constant/Title';

const LABLE = 5;
interface DigitalSongProps {
  data?: any;
  errors?: any;
  riajGenreData?: any[];
  onClickError?: () => void;
}

export default function DigitalSong(props: DigitalSongProps): JSX.Element {
  const [currentTimeSong, setCurrentTimeSong] = useState<any>({});

  const { data, errors = [], onClickError, riajGenreData } = props;
  const { songs } = data;
  const numberMax = songs?.length || 0;
  const songsRef = useRef<any>([]);
  const { triggerMedia } = useAppSelector((state) => state.title);
  const audioPlayer = useRef<any>([]);
  audioPlayer.current = songs.map((_: any, i: number) => audioPlayer.current[i] ?? createRef());

  const riajGenreNameJp = useCallback(
    (riajGenreId: number) => {
      return riajGenreData?.find((item) => item.value === riajGenreId)?.label ?? '';
    },
    [riajGenreData, data],
  );

  useEffect(() => {
    if (triggerMedia === 'songs' && errors.length > 0) {
      const newListError = errors
        .map((item: any, index: number) => ({ ...item, index: index }))
        .filter((item: any) => !!item);
      songsRef?.current[newListError[0].index]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
  }, [errors, triggerMedia]);

  const convertAuthorizedType = (type: number, songDesc?: string) => {
    switch (type) {
      case 1:
        return 'JASRAC';
      case 2:
        return 'NexTone';
      case 3:
        return '自己管理';
      case 4:
        return songDesc ?? '';

      default:
        return '';
    }
  };

  const convertLyricsClassifyType = (type: number) => {
    switch (type) {
      case 1:
        return '１（原詞）';
      case 2:
        return '２（訳詞）';
      case 3:
        return '３（不明）';
      case 4:
        return ' ブランク（Vocalなし）';

      default:
        return '';
    }
  };

  const convertVersionIdentificationType = (type: number) => {
    switch (type) {
      case 0:
        return 'L（国内原版）';
      case 1:
        return 'I（外国原版）';
      default:
        return '';
    }
  };

  const convertIvtType = (type: number) => {
    switch (type) {
      case 0:
        return 'I（Vocalなし）';
      case 1:
        return 'V（Vocalあり）';
      case 2:
        return 'T（詞のみ）';
      default:
        return '';
    }
  };

  const convertLiveType = (type: number) => {
    switch (type) {
      case 0:
        return 'スタジオ';
      case 1:
        return 'ライブ';
      default:
        return '';
    }
  };

  const renderMessageError = (index: number) => {
    return (
      <div onKeyDown={onClickError} onClick={onClickError}>
        <MessageError message={errors[index]?.sourceAudioUrl.message} />
      </div>
    );
  };

  return (
    <CardContainer title="楽曲コンテンツ" className="mb-3 mt-4 p-0">
      {songs.length === 0 &&
        (data?.id ? (
          <p>登録なし</p>
        ) : (
          <p className="text-cherry d-flex align-items-center">【登録されていません】</p>
        ))}
      {songs?.map((song: any, indexSong: number) => {
        const lyrics = song?.lyrics && JSON.parse(song?.lyrics);

        const isCurrentTimeSong = (item: any, index: number) => {
          if (item.time === '') return false;

          const currentTime = +currentTimeSong[indexSong];
          const greaterTime = +currentTime >= convertToSeconds(item?.time);

          let indexTime = 1;
          for (let i = 1; i < lyrics.length; i++) {
            if (lyrics[index + i]?.time) {
              indexTime = i;
              break;
            }
          }
          const lastIndex = findLastIndex(lyrics, function (el: any) {
            return el.time;
          });
          const thisTime = +currentTime < convertToSeconds(lyrics[index + indexTime]?.time);

          // CHECK LAST LYRIC
          if (lastIndex == index && greaterTime) return true;

          // CHECK LYRIC CURRENT
          return greaterTime && thisTime;
        };

        return (
          <div
            key={indexSong + 1}
            className="mb-4"
            ref={(el) => (songsRef.current[indexSong] = el)}
          >
            <p className="fw-bold fs-18">
              {`${song.name || ''}`}
              <span className="fs-14 fw-normal">
                【曲順{song.index + 1}/{numberMax}】
                {!song.name ? renderMessageError(indexSong) : ''}
              </span>
            </p>
            <hr className="my-2" />
            <Row className={`${!song.name && 'd-none'}`}>
              <Col>
                <GroupTextProps
                  mdLable={LABLE}
                  label="曲名（フリガナ）"
                  value={song?.furiganaName}
                />
                <GroupTextProps
                  mdLable={LABLE}
                  label="曲名（英語表記）"
                  value={song?.englishName}
                />
                {data?.jmdCooperation === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER && (
                  <GroupTextProps
                    mdLable={LABLE}
                    label="日本レコード協会ジャンル"
                    value={song?.riajGenre?.nameJp ?? riajGenreNameJp(song?.riajGenreId)}
                  />
                )}
                <GroupTextProps
                  mdLable={LABLE}
                  label="IVT識別"
                  value={convertIvtType(song?.ivtType)}
                />
                <GroupTextProps mdLable={LABLE} label="作詞者名" value={song?.musicianName} />
                <GroupTextProps
                  mdLable={LABLE}
                  label="作詞者名（フリガナ）"
                  value={song?.musicianFuriganaName}
                />
                <GroupTextProps
                  mdLable={LABLE}
                  label="作詞者名（英語表記）"
                  value={song?.musicianEnglishName}
                />
                <GroupTextProps mdLable={LABLE} label="作曲者名" value={song?.composerName} />
                <GroupTextProps
                  mdLable={LABLE}
                  label="作曲者名（フリガナ）"
                  value={song?.composerFuriganaName}
                />
                <GroupTextProps
                  mdLable={LABLE}
                  label="作曲者名（英語表記）"
                  value={song?.composerEnglishName}
                />

                <GroupTextProps mdLable={LABLE} label="編曲者名" value={song?.arrangerName} />
                <GroupTextProps
                  mdLable={LABLE}
                  label="編曲者名(フリガナ)"
                  value={song?.arrangerFuriganaName}
                />
                <GroupTextProps
                  mdLable={LABLE}
                  label="編曲者名(英語表記)"
                  value={song?.arrangerEnglishName}
                />

                <GroupTextProps mdLable={LABLE} label="演奏時間" value={song?.showTime} />
                <GroupTextProps
                  mdLable={LABLE}
                  label="原盤識別"
                  value={convertVersionIdentificationType(song?.versionIdentificationType)}
                />

                <GroupTextProps
                  mdLable={LABLE}
                  label="ライブ区分"
                  value={convertLiveType(song?.liveType)}
                />
                <GroupTextProps
                  mdLable={LABLE}
                  label="著作権信託先"
                  value={convertAuthorizedType(song?.authorizedGroupType, song?.authorizedGroup)}
                />
                <GroupTextProps mdLable={LABLE} label="ISRC番号" value={song?.isrcCode} />
                <GroupTextProps mdLable={LABLE} label="JASRAC作品コード" value={song?.jasracCode} />
                <GroupTextProps
                  mdLable={LABLE}
                  label="JASRAC以外の作品コード"
                  value={song?.otherJasracCode}
                />
                <GroupTextProps
                  mdLable={LABLE}
                  label="情報料(みなし上代)"
                  value={`楽曲: ${
                    song?.informationFee ? formatPrice(song?.informationFee) : 0
                  }円(税抜)　歌詞: ${
                    song?.lyricInformationFee ? formatPrice(song?.lyricInformationFee) : 0
                  }円(税抜)`}
                />
                <GroupTextProps
                  mdLable={LABLE}
                  label="原詞訳詞区分"
                  value={convertLyricsClassifyType(song?.lyricsClassifyType)}
                />
              </Col>
              <Col>
                <Row hidden={!song?.sourceAudioUrl} className="mt-2">
                  <AudioCustom
                    classAudio="mt-4"
                    ref={audioPlayer.current[indexSong]}
                    audioPlayer={audioPlayer.current[indexSong]}
                    src={song?.sourceAudioUrl}
                    onTimeAudioUpdate={(e: any) => {
                      setCurrentTimeSong({
                        ...currentTimeSong,
                        [indexSong]: e?.target?.currentTime + 0.05 || 0,
                      });
                    }}
                    key={song?.sourceAudioUrl}
                  />
                  <div className="mt-1 mw-600">
                    {song?.fileName && <p className="text-truncate">{song?.fileName}</p>}
                    {renderMessageError(indexSong)}
                  </div>
                  {Array.isArray(lyrics) && (
                    <div
                      className={`lyric-item mt-3 py-2 px-0 rounded ${
                        lyrics?.length > 0 || 'd-none'
                      }`}
                    >
                      {lyrics?.map((item: any, index: number) => (
                        <Row
                          key={index}
                          className={`${
                            isCurrentTimeSong(item, index) && 'border border-primary item-choose'
                          } mh-25 p-0`}
                        >
                          <Col md={2}>{item?.time}</Col>
                          <Col>{item.content}</Col>
                        </Row>
                      ))}
                    </div>
                  )}
                </Row>
                {!song?.sourceAudioUrl && renderMessageError(indexSong)}
              </Col>
            </Row>
          </div>
        );
      })}
    </CardContainer>
  );
}
