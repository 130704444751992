import { REGEX_DECIMAL, REGEX_NUMBER, REGEX_PHONE_NUMBER } from 'Utils/Validation';

const dotRegex = /\./g;

export const formatPhoneNumber = (value: string | undefined) => {
  if (!value) return value;

  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)} - ${phoneNumber.slice(3)}`;
  }
  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7, 11)}`;
};

export const handleEnteringPhoneNumber = (phoneNumber: string): string => {
  return phoneNumber.replace(REGEX_PHONE_NUMBER, '');
};

export const handleEnteringNumber = (number: string): string => {
  return number.replace(REGEX_NUMBER, '');
};

export const handleEnteringDecimal = (number: string | any): number => {
  const arrValue = number.split('.');
  let numberBeforeDot = arrValue[1];
  if (numberBeforeDot) {
    const arrNumberBeforeDot = Array.from(numberBeforeDot);
    if (arrNumberBeforeDot.length > 2) {
      arrNumberBeforeDot.pop();
      numberBeforeDot = arrNumberBeforeDot.join('');
      arrValue[1] = numberBeforeDot;
      // eslint-disable-next-line prefer-template
      const newValueNum = Number(arrValue[0] + '.' + arrValue[1]);
      return newValueNum;
    }
  }
  if (number.match(dotRegex)?.length > 1) {
    return number.replace('.', '');
  } else {
    return number.replace(REGEX_DECIMAL, '');
  }
};

export const checkEmptyString = (value: string): string | null => {
  if (!value) return null;
  return `${value.trim()}` ? value.trim() : null;
};

export const formatCurrency = (value: string | number): string => {
  if (!value || value === 0 || value === '0') return '';
  return new Intl.NumberFormat('ja-JP', { currency: 'JPY' }).format(
    typeof value === 'string' ? Number(value) : value,
  );
};

export const formatCurrency2 = (value: string | number): string => {
  if (!value || value === 0 || value === '0') return '0';
  return new Intl.NumberFormat('ja-JP', { currency: 'JPY' }).format(
    typeof value === 'string' ? Number(value) : value,
  );
};

export const formatCurrencyFloat = (value: number | string, fixed?: number) => {
  let number = typeof value === 'string' ? parseFloat(value) : value;

  if (number === 0) {
    return value;
  }

  if (fixed) {
    number = parseFloat(number.toFixed(fixed));
  }

  const formattedNumber = number.toLocaleString('ja-JP', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  });

  return formattedNumber;
};

export const formatDecimal = (value: string | number, fixed?: number): string => {
  if (!value || value === 0) return '';
  return String(
    typeof value === 'string' ? parseFloat(value).toFixed(fixed) : value.toFixed(fixed),
  );
};

export const formatFixedDecimalAndNumb = (value: string | number, fixed?: number): string => {
  if (value && Number(value) % 1 !== 0) {
    return String(
      typeof value === 'string' ? parseFloat(value)?.toFixed(fixed) : value?.toFixed(fixed),
    );
  }

  return String(value);
};

export const getSum = (data: any[], key: string): number => {
  const sum = data.reduce((total, currentValue) => {
    const fee = Object.keys(currentValue).includes(key) ? +(currentValue[key] as number) : 0;
    return total + fee;
  }, 0);
  return sum;
};

export const formatPrice = (value: number | string) => {
  return value && value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const formatPriceFloat = (value: number | string, fixed?: number) => {
  let number = typeof value === 'string' ? parseFloat(value) : value;

  if (number === 0) {
    return value;
  }

  if (fixed) {
    number = parseFloat(number.toFixed(fixed));
  }

  const formattedNumber = number.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedNumber;
};

export const formatCurrencyUS = (value: string | number): string => {
  if (!value || value === 0 || value === '0') return '';
  return new Intl.NumberFormat('en-US', { currency: 'USD' }).format(
    typeof value === 'string' ? Number(value) : value,
  );
};
