import React from 'react';

import ModalComponent from 'Components/Common/Modal';

interface confirmModalStatusProps {
  content: string;
  visible: boolean;
  handleCloseModal: () => void;
}

const ConfirmModalStatus = ({ content, visible, handleCloseModal }: confirmModalStatusProps) => {
  return (
    <div className="d-flex justify-content-center">
      <ModalComponent
        show={visible}
        onCloseModal={handleCloseModal}
        classNameContent="d-flex align-items-center justify-content-center"
        footerClassName="d-flex text-nowrap modal-appmobile"
        dialogClassName="p-usingstatus-mapp"
        headerClassName="d-none"
        variantButtonSubmit="success"
        onSubmitModal={handleCloseModal}
        showCancelButton={false}
        title={''}
        isReviewModal={false}
        confirmText="OK"
      >
        <div className="mt-3 text-center">{content}</div>
      </ModalComponent>
    </div>
  );
};

export default ConfirmModalStatus;
