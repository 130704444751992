/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/no-unused-vars */
import TableBT from 'Components/Common/Table/TableBT';
import React, { useCallback, useEffect, useState } from 'react';
import TableCommon from 'Components/Common/Table/TableCommon';
import { Col, Row, Button, Container, Form } from 'react-bootstrap';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { DATE_FORMAT_1, DATE_FORMAT_6 } from 'Constant/Date';
import Tabs from 'Components/Page/EC/ECListSaleProductTabs';
import ECListPurchaseSearch from 'Components/Page/EC/ECListPurchaseSearch';
import Filter from 'Components/Common/Filter/Filter';
import { ECApis, QueryPurchaseParams } from 'Datasource/EC';
import PrizeRow from 'Components/Page/Campaign/List/PrizesRow';
import { handleParseDate } from 'Utils/ParseDate';
import { find, unionBy } from 'lodash';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { PER_PAGE_EC } from 'Constant';
import { updateMailList } from 'App/Features/MailRecipientList';
import { useAppDispatch } from 'App/Store';
import { transformQuery } from 'Utils/Params';
import { startOfDayToGMT, endOfDayToGMT } from 'Utils/DateTime';
import useErrorBlob from 'Hooks/useErrorBlob';
import { exportExcel } from 'Utils/File';
import useTitlePage from 'Hooks/useTitlePage';
// import { resetData } from 'App/Features/Campaign';
import MessageError from 'Components/Common/MessageError';
import { SortMethod } from 'Components/Common/Table/table';
import ECPurchaseStatus from 'Components/Page/EC/ECPurchaseStatus';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import dayjs from 'dayjs';
import Modal from 'Components/Common/Modal';
import ECSendBulkEmailForm from 'Components/Page/EC/ECSendBulkEmailForm';
import useMessage from 'Hooks/useMessage';
import { formatPrice } from 'Utils/Numbers';
import { AppRouter } from 'Types/Router';

export default function ECListPurchase(): JSX.Element {
  const dispatch = useAppDispatch();
  const [purchase, setPurchase] = useState([]);
  const [purchaseTotal, setPurchaseTotal] = useState(0);
  const [totalPurchases, setTotalPurchases] = useState(0);
  const [totalSettlement, setTotalSettlement] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isShowBlukEmailModal, setIsShowBlukEmailModal] = useState<boolean>(false);

  const [dataQuery, setDataQuery] = useState<QueryPurchaseParams>({});

  const [activeColumns, setActiveColumns] = useState<any>([]);

  const { openErrorBlob } = useErrorBlob();

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    status: StringParam,
    orderNumber: StringParam,
    miimId: StringParam,
    realName: StringParam,
    artistId: StringParam,
    titleId: StringParam,
    variationId: StringParam,
    campaignId: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    sort: StringParam,
    order: StringParam,
  });

  const { setMenuTitle } = useTitlePage();

  const [activePage, setActivePage] = useState(query.page ?? 1);

  const { openMessageError } = useMessage();

  const addOrRemoveAllColumns = (e: any) => {
    const purchaseColumnsId = purchase.filter((c: any) => !c.deleted).map((col: any) => col.id);

    if (e.target.checked) {
      const newActiveColumns = unionBy(purchase, activeColumns, 'id');
      setActiveColumns(newActiveColumns.filter((c: any) => !c.deleted));
    } else {
      const newActiveColumns = activeColumns.filter(
        (col: any) => !purchaseColumnsId.includes(col.id),
      );
      setActiveColumns(newActiveColumns);
    }
  };

  const addOrRemoveColumns = (colData: any) => {
    const foundData = activeColumns.find((col: any) => col?.id === colData?.id);
    if (foundData) {
      const newActiveColumns = activeColumns.filter((col: any) => col?.id !== colData?.id);
      setActiveColumns(newActiveColumns);
    } else {
      const newActiveColumns = [...activeColumns, colData];
      setActiveColumns(newActiveColumns);
    }
  };

  const isAllChecked = () => {
    const activeColumnsId = activeColumns.map((col: any) => col.id);
    const purchaseColumnsId = purchase.filter((col: any) => !col.deleted).map((col: any) => col.id);

    return purchaseColumnsId.every((colId: any) => activeColumnsId.includes(colId));
  };

  const columnsPurchase = [
    {
      Header: '',
      accessor: 'empty',
      className: 'pe-none',
      columns: [
        {
          id: 'none',
          Header: (
            <Form.Check
              type="checkbox"
              id="isCheckAll"
              name="isCheckAll"
              // checked={false}
              className="ec_custom-checkbox"
              checked={isAllChecked()}
              // onChange={() => setValue('isPublic', !watch('isPublic'))}
              onChange={addOrRemoveAllColumns}
            />
          ),
          className: 'd-flex align-items-center justify-content-center',
          width: 50,
          Cell: ({ row }: Cell<any>) => {
            const foundCol = activeColumns.find(
              (col: any) => col?.id === row?.original?.id && !row?.original?.deleted,
            );
            return (
              <div className="d-flex align-items-center justify-content-center h-100 p-2">
                <Form.Check
                  type="checkbox"
                  id={row?.original?.id}
                  name={row?.original?.id}
                  disabled={row?.original?.deleted}
                  // checked={false}
                  className="ec_custom-checkbox"
                  checked={!!foundCol}
                  // onChange={() => setValue('isPublic', !watch('isPublic'))}
                  // onChange={(e) => setIsShowOnlyMaster(e.target.checked)}
                  onChange={() => addOrRemoveColumns(row?.original)}
                />
              </div>
            );
          },
        },
        {
          Header: 'ID',
          className: 'd-flex align-items-center justify-content-center',
          accessor: 'id',
          width: 80,
          Cell: ({ row }: Cell<any>) => {
            return (
              <div className="d-flex align-items-center justify-content-center h-100 p-2">
                <span className="text-start">{row.original?.id}</span>
              </div>
            );
          },
        },
        // TODO: Temporarily hidden this column
        // {
        //   Header: '店舗名',
        //   className: '',
        //   accessor: 'shopName',
        //   width: 300,
        //   Cell: () => {
        //     return (
        //       <div style={{ lineHeight: '40px' }}>
        //         <TooltipsWithTruncate
        //           tooltips="Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo fugiat
        //         debitis magni molestiae obcaecati autem id cum corporis! Ipsam, maxime!"
        //         >
        //           <div className="text-break text-truncate px-2">
        //             Lorem ipsum dolor sit amet consectetur adipisicing elit
        //           </div>
        //         </TooltipsWithTruncate>
        //       </div>
        //     );
        //   },
        // },
        {
          Header: '注文番号',
          className: 'd-flex align-items-center justify-content-center',
          accessor: 'channelOrderId',
          width: 171,
          Cell: ({ row }: Cell<any>) => {
            return (
              <div className="d-flex align-items-center h-100 p-2 mw-100">
                <Link
                  to={`/ec${AppRouter.PURCHASE_DETAIL}/${row.original?.ecOrderId}`}
                  // to={`/campaigns/${row.original?.id}`}
                  // onClick={() => {
                  //   dispatch(updateMailList({ mailList: [], isSuccess: false }));
                  // }}
                  className="d-block text-break text-truncate"
                >
                  <span className="text-center">{row.original?.ecOrderId}</span>
                </Link>
              </div>
            );
          },
        },
        {
          Header: '注文日時',
          className: 'd-flex align-items-center',
          accessor: 'createdAt',
          width: 157,
          Cell: ({ row }: Cell<any>) => {
            return (
              <div className="d-flex align-items-center h-100 p-2 justify-content-center">
                <span className="text-start">
                  {dayjs(row.original?.createdAt).format(DATE_FORMAT_6)}
                </span>
              </div>
            );
          },
        },
        {
          Header: 'ユーザーID',
          className: 'd-flex align-items-center',
          accessor: 'userMiimId',
          width: 209,
          Cell: ({ row }: Cell<any>) => {
            return (
              <div className="d-flex align-items-center h-100 p-2">
                <span className="text-start">{row.original?.userMiimId}</span>
              </div>
            );
          },
        },
        {
          Header: '本名',
          className: 'align-items-center',
          accessor: 'realName',
          width: 144,
          Cell: ({ row }: Cell<any>) => {
            return (
              <div className="d-flex align-items-center h-100 p-2">
                <span className="text-start text-truncate">{row.original?.realName}</span>
              </div>
            );
          },
        },
        {
          Header: 'ステータス',
          className: 'd-flex align-items-center',
          accessor: 'status',
          width: 140,
          Cell: ({ row }: Cell<any>) => {
            return (
              <div className="d-flex align-items-center h-100 p-2 justify-content-center">
                <span className="text-start">
                  <ECPurchaseStatus status={row.original?.status} />
                </span>
              </div>
            );
          },
        },
        {
          Header: '支払い方法',
          className: 'd-flex align-items-center',
          accessor: 'paymentCard',
          width: 137,
          Cell: ({ row }: Cell<any>) => {
            return (
              <div className="d-flex align-items-center w-100 h-100 p-2 justify-content-center">
                <span className="text-center">{row.original?.paymentCard}</span>
              </div>
            );
          },
        },
        {
          Header: '決済金額',
          className: 'd-flex align-items-center',
          // accessor: 'totalPrice',
          width: 130,
          Cell: ({ row }: Cell<any>) => {
            return (
              <div className="d-flex w-100 align-items-center h-100 p-2 justify-content-end">
                <span className="text-start">{formatPrice(row.original?.totalPrice)}</span>
              </div>
            );
          },
        },
      ],
    },
  ];

  const handleGetPurchase = async (queries: QueryPurchaseParams) => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await ECApis.getPurchase(queries);

      setPurchase(data?.items);
      setTotalPurchases(data?.totalPurchases);
      setTotalSettlement(data?.totalSettlement);
      setPurchaseTotal(data?.meta?.itemCount);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleExportExcel = async () => {
    try {
      const newDataQuery = { ...dataQuery };

      if (newDataQuery?.page) {
        delete newDataQuery.page;
      }

      if (newDataQuery?.sort) {
        delete newDataQuery.sort;
      }
      await exportExcel(
        ECApis.purchaseDownloadExcel(dataQuery),
        `EC購入リスト_${dayjs().format('YYYYMMDDhhmmss')}`,
      );
    } catch (error) {
      // console.log(error, 'heressss');
      openErrorBlob(error);
    }
  };

  useEffect(() => {
    setMenuTitle('EC購入リスト');
  }, []);

  useEffect(() => {
    const queries = {
      limit: PER_PAGE_EC,
      page: activePage,
      orderNumber: query.orderNumber,
      miimId: query.miimId,
      realName: query.realName,
      artistId: query.artistId,
      titleId: query.titleId,
      variationId: query.variationId,
      campaignId: query.campaignId,
      startDate: query.startDate ? startOfDayToGMT(query.startDate) : null,
      endDate: query.endDate ? endOfDayToGMT(query.endDate) : null,
      sort: query.sort || '',
      order: query.order || '',
    };
    setDataQuery(queries);
    handleGetPurchase(queries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activePage,
    query.orderNumber,
    query.miimId,
    query.realName,
    query.artistId,
    query.titleId,
    query.variationId,
    query.campaignId,
    query.startDate,
    query.endDate,
    query.sort,
    query.order,
  ]);

  //* handle sort from server side
  const handleManualSort = useCallback((sortBy: SortMethod[]) => {
    if (sortBy.length > 0) {
      const sortOption = sortBy[0];
      const sortKey = sortOption.id;
      setQuery({ sort: sortKey, order: sortOption.desc ? 'DESC' : 'ASC' });
    } else {
      setQuery({ sort: '', order: '' });
    }
  }, []);

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <Container fluid>
        <Filter filterClass="shadow-sm">
          <Row className="align-items-start justify-content-between">
            <ECListPurchaseSearch
              setActiveColumns={setActiveColumns}
              handleExportExcel={handleExportExcel}
            />
          </Row>
        </Filter>
        <Row className="mt-3">
          <Col md={6} className="d-flex align-items-center p-0">
            <Button
              variant="primary"
              className="w-btn btn-focus-none ms-4 me-3"
              disabled={!activeColumns.length}
              onClick={() => {
                setIsShowBlukEmailModal(true);
              }}
            >
              チェックしたユーザへメールを送信
            </Button>

            <div className="d-flex align-items-end">
              <span className="me-3 ec_fs-12 fw-bold">合計件数</span>
              <span className="ec_fs-24 ec_lh-24 fw-bold">{totalPurchases}</span>
            </div>

            <div className="d-flex align-items-end">
              <span className="ms-5 ec_fs-12 fw-bold me-3">合計決済金額</span>
              <span className="ec_fs-24 ec_lh-24 fw-bold">{`¥${formatPrice(
                totalSettlement,
              )}`}</span>
            </div>
          </Col>
          <Col md={6} className="d-flex justify-content-end p-0 px-4">
            <PaginationComponent
              activePage={activePage}
              total={purchaseTotal}
              perPage={PER_PAGE_EC}
              onClick={(newPage) => setActivePage(newPage)}
            />
          </Col>
        </Row>
        <div className="table-nft-container/*">
          <Row className="mt-3 px-4">
            <Col
              md={12}
              className={
                purchase.length > 0
                  ? `d-flex justify-content-start p-0`
                  : `d-flex justify-content-center p-0`
              }
            >
              <TableCommon
                heightBody="calc(100vh - 250px)"
                columns={columnsPurchase}
                data={purchase}
                getCellProps={() => {
                  return {
                    className: 'p-0',
                  };
                }}
                messageNoData="検索結果がありません。"
                manualSortBy
                onSort={handleManualSort}
              />
            </Col>
          </Row>
        </div>
        <Modal
          show={isShowBlukEmailModal}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onCloseModal={() => {
            setIsShowBlukEmailModal(false);
          }}
          dialogClassName="w-900px"
          footerClassName="d-none"
          title="一括メール送信"
          // title={
          //   isReview
          //     ? `${
          //         (songList && songList.find((item: Song) => item.index === currentSong)?.name) ??
          //         '【楽曲が登録されていません】'
          //       }の詳細`
          //     : `${
          //         variationName ?? '名称未設定バリエーション'
          //       }の${currentSong}曲⽬のデータを登録します`
          // }
        >
          <ECSendBulkEmailForm
            onCloseModal={() => {
              setIsShowBlukEmailModal(false);
            }}
            callback={() => {
              setActiveColumns([]);
            }}
            setLoading={setLoading}
            data={activeColumns}
          />
        </Modal>
      </Container>
    </>
  );
}
