import TableCommon from 'Components/Common/Table/TableCommon';
import React, { useMemo } from 'react';
import { Cell } from 'react-table';
import { formatFixedDecimalAndNumb, formatPrice } from 'Utils/Numbers';

export default function PurchaseAmountTable({
  totalPurchaseTotalPrice,
}: {
  totalPurchaseTotalPrice: number;
}): JSX.Element {
  const purchaseAmountData = [{ totalPurchaseTotalPrice }];
  const column = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: '販売金額',
            accessor: 'totalPurchaseTotalPrice',
            width: '120',
            disableSortBy: true,
            className: 'lg-fs-12px lg-header-table-padding-3px',
            Cell: ({ row }: Cell<any>) => {
              return (
                <div
                  className="text-truncate text-start payment-advice-padding text-right lg-fs-10px"
                  title="false"
                >
                  {row.original.totalPurchaseTotalPrice
                    ? formatPrice(
                        formatFixedDecimalAndNumb(row.original.totalPurchaseTotalPrice, 2),
                      )
                    : 0}
                </div>
              );
            },
          },
        ],
      },
    ],
    [],
  );

  return (
    <>
      <TableCommon
        // heightBody="calc(100vh - 250px)"
        columns={column}
        data={purchaseAmountData}
        getCellProps={() => ({
          className: 'p-0',
        })}
        manualSortBy
        isNotShowSort={true}
      />
    </>
  );
}
