import React, { useCallback, useEffect, useState } from 'react';
import { FormGroupSearchProps } from 'Components/Common/Form/form';
import FormGroupSearch from 'Components/Common/Form/FormGroupSearch';
import { SearchResponse } from 'Hooks/useSuggestion';
import useMessage from 'Hooks/useMessage';
import { PER_PAGE } from 'Constant';
import { updateQuery } from 'Utils/Search';
import { ParamsGetShops, ShopAPIs } from 'Datasource/Shop';
import { SEARCH_SHOP_CLIENT, UNDEFINED_SHOP_NAME } from 'Constant/Shop';

export interface SelectShopProps extends Omit<FormGroupSearchProps, 'onInputChange' | 'onSearch'> {
  onInput?: (keyword: string) => void;
  onClear?: () => void;
  emptyLine?: boolean;
}

const UndefinedShop = {
  value: 0,
  label: UNDEFINED_SHOP_NAME,
};

export default function FormSelectShopEC(props: SelectShopProps): JSX.Element {
  const [page, setPage] = useState<number>(1);
  const [shops, setShops] = useState<SearchResponse>({ options: [], totalPages: 0 });
  const [loadingShop, setLoadingShop] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>(props.defaultValue ?? '');
  const { openMessageError } = useMessage();

  const { onInput, onClear } = props;

  const handleSearchArtist = async () => {
    try {
      setLoadingShop(true);

      const params: ParamsGetShops = {
        page: page,
        limit: PER_PAGE,
        name: searchKey,
      };

      const {
        data: { data },
      } = await ShopAPIs.getShops(params);

      handleSetShop(data, searchKey);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingShop(false);
    }
  };

  const handleLoadmore = () => {
    if (page < shops.totalPages) {
      let p = page;
      p += 1;
      setPage(p);
    }
  };

  const handleSetShop = (data: any, searchValue: string) => {
    const options = data?.items?.map((item: { id: number; name: string }) => ({
      label: item.name,
      value: item.id,
    }));

    const isSearchUndefinedShop = SEARCH_SHOP_CLIENT.includes(searchValue);

    /**
     * search shop in client
     * add default "undefined shop in list shop"
     */
    if (searchValue === '' || (searchValue !== '' && isSearchUndefinedShop === true)) {
      setShops({
        options: page === 1 ? [UndefinedShop, ...options] : [...shops.options, ...options],
        totalPages: data?.meta?.totalPages,
      });

      return;
    }

    if (searchValue !== '' && isSearchUndefinedShop === false) {
      setShops({
        options: page === 1 ? options : [...shops.options, ...options],
        totalPages: data?.meta?.totalPages,
      });

      return;
    }
  };

  useEffect(() => {
    handleSearchArtist();
  }, [searchKey, page]);

  useEffect(() => {
    setSearchKey(props.defaultValue ?? '');
  }, [props.defaultValue]);

  const debouceRequest = useCallback(
    (value: string) =>
      updateQuery(value, () => {
        setSearchKey(value);
        setPage(1);
        onInput && onInput(value);
      }),
    [onInput],
  );

  return (
    <FormGroupSearch
      onLoadMore={() => handleLoadmore()}
      onMenuClose={() => {
        setPage(1);
      }}
      isLoading={loadingShop}
      options={shops.options || []}
      onInputChange={(keyword: string) => debouceRequest(keyword)}
      onMenuFocus={() => {
        setShops({ options: [], totalPages: 0 });
        handleSearchArtist();
      }}
      onClear={onClear}
      showIconClear
      {...props}
    />
  );
}
