import React from 'react';
import FilterFanProfile from 'Components/Page/FanProfile/Filter';
import MainFanProfile from 'Components/Page/FanProfile/Main';
const FanProfile = (): JSX.Element => {
  return (
    <div>
      <FilterFanProfile />
      <MainFanProfile />
    </div>
  );
};

export default FanProfile;
