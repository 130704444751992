export const getNameBrower = (): string => {
  let browerName = '';
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('safari') != -1) {
    if (ua.indexOf('chrome') > -1) {
      browerName = 'chome';
    } else {
      browerName = 'safari';
    }
  }
  return browerName;
};
