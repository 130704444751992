import React from 'react';
import { Container } from 'react-bootstrap';
import { PaymentPrint, TableList } from 'Components/Page/PaymentNotice';

export default function index(): JSX.Element {
  return (
    <>
      <Container fluid className="d-none">
        <PaymentPrint />
        <TableList />
      </Container>
    </>
  );
}
