import React from 'react';
import { ArtColumn, BaseTable, useTablePipeline, features, BaseTableProps } from 'ali-react-table';
import { isEmpty } from 'lodash';
import { StringParam, useQueryParams } from 'use-query-params';
export interface AliTableProps extends BaseTableProps {
  heightBody?: string;
  wrapperClass?: string;
  noDataText?: string | null;
  keepSortState?: boolean;
  onSort?: (sortingState: AliSortType[]) => void;
}
export type AliColumn = ArtColumn;

export interface AliSortType {
  code: string;
  order: string;
}
const AliTable = (props: AliTableProps): JSX.Element => {
  const {
    columns,
    dataSource,
    heightBody,
    wrapperClass,
    defaultColumnWidth,
    noDataText = '該当のデータはありません',
    keepSortState,
    onSort,
  } = props;

  const [query] = useQueryParams({
    sort: StringParam,
    order: StringParam,
  });

  const pipeline = useTablePipeline()
    .input({ columns, dataSource })
    .use(
      features.sort({
        mode: 'single',
        //* if keepDataSource false => keep current sort state when change page
        keepDataSource: !keepSortState,

        //* update dataSource after custom sort change
        onChangeSorts: (nextSort) => {
          onSort && onSort(nextSort);
        },
        defaultSorts: query.sort
          ? [
              {
                code: query.sort,
                order: !query.order ? 'none' : query.order === 'DESC' ? 'desc' : 'asc',
              },
            ]
          : [],
        orders: ['asc', 'desc', 'none'],
        sorts: query.sort
          ? [
              {
                code: query.sort,
                order: !query.order ? 'none' : query.order === 'DESC' ? 'desc' : 'asc',
              },
            ]
          : [],
      }),
    );

  return (
    <>
      {!isEmpty(dataSource) ? (
        <div
          style={{ maxHeight: heightBody || 400 }}
          className={`${wrapperClass ? wrapperClass : ''}`}
        >
          <BaseTable
            {...pipeline.getProps()}
            // className="alitb-mb-1"
            defaultColumnWidth={defaultColumnWidth || 80}
            stickyBottom={0}
          />
        </div>
      ) : (
        <>
          {noDataText && (
            <div className="vh-100-final d-flex align-items-center justify-content-center">
              {noDataText}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AliTable;
