import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { IPullDownItem, SelectValue, IPullDown } from 'Types/Common';
import FormGroupSearch from 'Components/Common/Form/FormGroupSearch';
import MessageError from 'Components/Common/MessageError';
import {
  handleSearchArtists,
  handleSearchTitle,
  handleSearchVariation,
} from 'Components/Common/PullDownSearch/handleFunc';

export default function PullDownSearch(props: IPullDown): JSX.Element {
  const {
    onEnterFilter,
    errorMessage,
    register,
    label,
    placeholder,
    onChange,
    colClass,
    colMd,
    defaultValue,
    labelMd,
    inputSearchString,
    classLabel,
    onChangeSearchKey,
    showIcon = false,
    type,
    artistID,
    titleID,
    disabled,
  } = props;

  const [labelCurrent, setLabelCurrent] = useState<string | undefined>(defaultValue);
  const handleSearch = async (query: string) => {
    switch (type) {
      case 'ARTIST':
        return handleSearchArtists(query);
      case 'TITEL':
        return handleSearchTitle(query, artistID);
      case 'VARIATION':
        return handleSearchVariation(query, titleID);
      case 'SERIAL':
        return [];

      default:
        break;
    }
  };

  const onChangeSelected = (selected: SelectValue) => {
    if (isEmpty(selected)) {
      return '';
    }
    const itemSelect: IPullDownItem = {
      label: selected.label,
      value: selected.value,
    };
    if (type === 'VARIATION') {
      onChange(selected.value, itemSelect, selected.tokutenCommons);
    } else {
      onChange(selected.value, itemSelect);
    }
    setLabelCurrent(itemSelect.label);
  };

  useEffect(() => {
    if (!inputSearchString) {
      setLabelCurrent(defaultValue);
    }
  }, [defaultValue, inputSearchString, artistID, titleID]);

  return (
    <div className={`wrap-aritist-list  ${disabled && 'disabled'}`}>
      <FormGroupSearch
        {...register}
        rowClass="w-100"
        label={label}
        colMd={colMd ?? '10'}
        labelMd={labelMd ?? '2'}
        colClass={colClass}
        onSearch={handleSearch}
        onSelect={onChangeSelected}
        placeholder={placeholder}
        defaultValue={labelCurrent ? labelCurrent : defaultValue}
        inputSearchString={inputSearchString ? inputSearchString : defaultValue}
        onEnter={onEnterFilter}
        showIcon={showIcon}
        classLabel={classLabel}
        onInputChange={onChangeSearchKey}
      />
      <Row>
        <Col md={{ offset: 2 }}>
          <MessageError message={errorMessage} />
        </Col>
      </Row>
    </div>
  );
}
