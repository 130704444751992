import brAxios from 'Api/Interceptors';
import { AxiosResponse } from 'axios';

export interface IDataSaleQuantityItem {
  artistName: string;
  cardCd: string;
  titleName: string;
  variationName: string;
  variationProductCode: string;
  variationJanCode: string;
  variationSaleTokenDate: string;
  variationDownloadDate: string;
  variationNotiDownloadTime: string;
  variationPrice: string;
  purchasesQuantity: number;
  totalCardPurchasePrice: number;
  saleEndDate: string | null;
  status: number | null;
  campaigns: string[] | any[];
}

export interface IDataSalesQuantity {
  id?: null | string | number;
  items: IDataSaleQuantityItem[];
  submitDate: string | null;
  totalPurchasePrice: number;
  totalPurchaseQuantity: number;
}

interface QueryParams {
  artistId?: string;
  saleEndDate?: string;
  shopId: string;
}

interface ParamsUpdateSupportRevenue {
  artistId: string;
  saleEndDate: string;
  shopId: string;
}

interface QueryParamsArtistByShop {
  artistName?: string;
  shopId: string;
}

const CancelTokenGetShop = brAxios.CancelToken;
let sourceGetShops: any;

export const SupportRevenueAPIs = {
  getShopsRoleStaff: (): Promise<AxiosResponse> => {
    // cancel axios request previous
    if (typeof sourceGetShops != typeof undefined) {
      sourceGetShops.cancel('Operation canceled due to new request.');
    }

    sourceGetShops = CancelTokenGetShop.source();

    return brAxios.get(`/staff/shops`, { cancelToken: sourceGetShops.token });
  },

  getListSupportRevenue: ({ artistId, saleEndDate, shopId }: QueryParams) =>
    brAxios.get(
      `/staff/sales-quantity?artistId=${artistId ?? ''}&saleEndDate=${saleEndDate ?? ''}&shopId=${
        shopId ?? ''
      }`,
    ),

  getListArtistByShop: ({ shopId, artistName }: QueryParamsArtistByShop) =>
    brAxios.get(`/staff/artists-by-shop?shopId=${shopId ?? ''}&artistName=${artistName || ''}`),

  updateSupportRevenues: (data: ParamsUpdateSupportRevenue): Promise<AxiosResponse> => {
    return brAxios.post('/staff/sales-quantity', data);
  },
};
