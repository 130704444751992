import React, { useMemo } from 'react';
import { Cell } from 'react-table';
import TableCommonV2 from 'Components/Common/Table/TableCommonV2';
import MessageError from 'Components/Common/MessageError';
import TooltipsWithTruncate from 'Components/Common/Tooltips/TooltipsWithTruncate';
// import { DeleteType } from 'Site/RecordCompany/Page/ListProducts/SearchUsers';

export default function TableSampleMasterDp({ dataSource }: { dataSource: any[] }): JSX.Element {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: 'miim ID',
            accessor: 'miimID',
            width: '100',
            Cell: ({ row }: Cell<any>) => (
              <div className="text-truncate">{row?.original?.miimID}</div>
            ),
          },
          {
            Header: 'ユーザ名',
            accessor: 'userName',
            Cell: ({ row }: Cell<any>) => {
              // [MIIM_EC-144] - Bug 90979
              if (row?.original?.id === null) {
                return (
                  <MessageError
                    message="miim IDが存在しません。"
                    classWrapper="ms-0 text-truncate"
                  />
                );
              }

              return (
                <>
                  {row?.original?.realName.trim() !== '' ? (
                    <TooltipsWithTruncate
                      tooltips={row?.original?.realName}
                      id={`userName-${row.index}`}
                    >
                      <div className="text-truncate">{row?.original?.realName}</div>
                    </TooltipsWithTruncate>
                  ) : (
                    <MessageError
                      message="miim IDが存在しません。"
                      classWrapper="ms-0 text-truncate"
                    />
                  )}
                </>
              );
            },
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
              const defaultVal = desc ? 'A' : 'Z';
              return (rowA?.original?.realName ?? defaultVal).localeCompare(
                rowB?.original?.realName ?? defaultVal,
              );
            },
          },
          {
            Header: 'メールアドレス',
            accessor: 'email',
            Cell: ({ row }: Cell<any>) => {
              // [MIIM_EC-144] - Bug 90979
              if (row?.original?.id === null) {
                return <></>;
              }

              if (row?.original?.email.trim() !== '') {
                return (
                  <TooltipsWithTruncate tooltips={row?.original?.email} id={`email-${row.index}`}>
                    <div className="text-truncate">{row?.original?.email}</div>
                  </TooltipsWithTruncate>
                );
              }

              return <></>;
            },
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
              const defaultVal = desc ? 'A' : 'Z';
              return (rowA?.original?.email ?? defaultVal).localeCompare(
                rowB?.original?.email ?? defaultVal,
              );
            },
          },
        ],
      },
    ],
    [],
  );
  return (
    <div className="sample-master-table mt-3 mb-0">
      <TableCommonV2
        data={dataSource}
        columns={columns}
        isNotShowSort={true}
        getCellProps={() => {
          return {
            className: 'text-start',
          };
        }}
      />
    </div>
  );
}
