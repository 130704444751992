import React from 'react';
import ModalComponent from 'Components/Common/Modal';

interface ModalProps {
  isShow: boolean;
  onClose: () => void;
  onSubmitDelete: () => void;
  type: string;
}

export default function ModalConfirm({
  isShow,
  onClose,
  onSubmitDelete,
  type,
}: ModalProps): JSX.Element {
  const isDelete = type === 'delete';
  return (
    <>
      <ModalComponent
        show={isShow}
        onCloseModal={onClose}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="danger"
        onSubmitModal={onSubmitDelete}
        title={isDelete ? '削除確認' : '変更確認'}
        confirmText={isDelete ? '削除する' : '変更する'}
      >
        <div>
          {isDelete
            ? 'バリエーションを削除します。よろしいですか。'
            : '楽曲数を変更すると入力した楽曲データが削除されます。よろしいですか。'}
        </div>
      </ModalComponent>
    </>
  );
}
