import React from 'react';
import { OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';

const CapacityField = ({ data }: { data: any }): JSX.Element => {
  const handleCalculatePercent = (value: number) => {
    return (value / data?.typeInfo?.maxVariationStorage) * 100;
  };
  const renderTooltip = (props: any, text: string, id: string) => {
    return (
      <Tooltip id={id} {...props}>
        {text}
      </Tooltip>
    );
  };

  return (
    <>
      {data?.used > data.typeInfo?.maxVariationStorage ? (
        <ProgressBar
          className="custom-progress-bar text-white danger border rounded-start"
          variant="danger"
          now={100}
          key={1}
          label={`DPコンテンツ ${data.used.toFixed(2)}MB`}
        />
      ) : (
        <ProgressBar className="custom-progress-bar fs-14">
          <OverlayTrigger
            overlay={(props) =>
              renderTooltip(props, `DPコンテンツ ${data?.used.toFixed(2)}MB`, 'progress-bar-1')
            }
          >
            <ProgressBar
              className="text-white danger border rounded-start"
              variant="danger"
              now={handleCalculatePercent(data?.used.toFixed(2))}
              key={1}
              label={`DPコンテンツ ${data?.used.toFixed(2)}MB`}
              isChild={true}
            />
          </OverlayTrigger>
          <OverlayTrigger
            overlay={(props) =>
              renderTooltip(
                props,
                `残りデータ容量 ${data?.notUsedYet.toFixed(2)}MB`,
                'progress-bar-2',
              )
            }
          >
            <ProgressBar
              className="text-dark border"
              variant="white"
              now={handleCalculatePercent(data?.notUsedYet.toFixed(2))}
              key={2}
              label={`残りDPデータ容量 ${data?.notUsedYet.toFixed(2)}MB`}
              isChild={true}
            />
          </OverlayTrigger>
        </ProgressBar>
      )}
      <div className="mt-2 fs-12">
        タイトルタイプは楽曲数、動画内楽曲数及びDPコンテンツのデータ登録容量により自動的に確定します。
      </div>
    </>
  );
};

export default CapacityField;
