import React from 'react';
import { Button, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import useCampaign from 'Hooks/useCampaign';
import { useAppSelector } from 'App/Store';
import { debounce } from 'lodash';

const SuccessActionButtons = (): JSX.Element => {
  const { overview } = useAppSelector((state) => state.campaign);
  const { handleCopyCampaign } = useCampaign();
  const { id }: { id: string } = useParams();
  return (
    <Row className="d-flex justify-content-center align-items-center py-3">
      <Link to="/campaign-list" className="text-white text-decoration-none btn-action h-100 p-0">
        <Button className="btn-action">キャンペーンリストへ戻る</Button>
      </Link>
      <Link
        to={`/create-products/${overview?.titleId}`}
        className="text-white text-decoration-none btn-action h-100 p-0 mx-3"
      >
        <Button className="btn-action">このキャンペーン商品を作成する</Button>
      </Link>
      <Button
        className="btn-action-big"
        onClick={() => {
          const debounceClick = debounce(() => {
            handleCopyCampaign(id);
          }, 1000);
          debounceClick();
        }}
      >
        このキャンペーンをコピーして新たに作成
      </Button>
    </Row>
  );
};

export default SuccessActionButtons;
