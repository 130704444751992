/*eslint @typescript-eslint/explicit-module-boundary-types: 0*/
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { createTransform, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import Auth from 'App/Features/Auth';
import Title from 'App/Features/Title';
import PullDown from 'App/Features/PullDown';
import Promotion from 'App/Features/PromotionManger';
import NFT from 'App/Features/NFT';
import Campaign from 'App/Features/Campaign';
import MailRecipientList from 'App/Features/MailRecipientList';
import Layout from 'App/Features/Layout';
import HashPassword from 'App/Features/HashPassword';
import Revenue, { initialRevenueState } from 'App/Features/Revenue';
import { fillValueObject } from 'Utils/Validation';
import Product from 'App/Features/Product';

const reducers = combineReducers({
  auth: Auth,
  title: Title,
  pullDown: PullDown,
  promotion: Promotion,
  nft: NFT,
  campaign: Campaign,
  mailRecipientList: MailRecipientList,
  layout: Layout,
  hashPassword: HashPassword,
  revenue: Revenue,
  product: Product,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['title'],
  // blacklist: ['title', 'campaign'],
  // whitelist: ['auth'],
  transforms: [
    createTransform(
      (inboundState) => inboundState,
      (outboundState: any) => {
        const saleConfigState = initialRevenueState.filters.sale;
        const costConfigState = initialRevenueState.filters.cost;
        return {
          filters: {
            cost: {
              daily: fillValueObject(costConfigState.daily, outboundState.filters.cost.daily, true),
              monthly: fillValueObject(
                costConfigState.monthly,
                outboundState.filters.cost.monthly,
                true,
              ),
              detail: fillValueObject(
                costConfigState.detail,
                outboundState.filters.cost.detail,
                true,
              ),
            },
            sale: {
              daily: fillValueObject(saleConfigState.daily, outboundState.filters.sale.daily, true),
              monthly: fillValueObject(
                saleConfigState.monthly,
                outboundState.filters.sale.monthly,
                true,
              ),
              detail: fillValueObject(
                saleConfigState.detail,
                outboundState.filters.sale.detail,
                true,
              ),
            },
          },
        };
      },
      { whitelist: ['revenue'] },
    ),
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
