export const ERROR = {
  'this field is required': 'この項目は必須です',
  'no fill out of email field': 'メールは必須フィールドです',
  'no fill out of password field': 'ログインメールアドレスまたはパスワードが一致しません。',
  'valid email address': 'ログインメールアドレスまたはパスワードが一致しません。',
  'password is not match': 'ログインメールアドレスまたはパスワードが一致しません。',
  'this field is only allowed number': 'このフィールドは許可されている数値のみです',
  'at least 6 numbers': '少なくとも6つの数字',
  'phone number is not in the correct format': '電話番号が正しい形式ではありません',
  'bank branch number has least 4 numbers': '銀行の支店番号には少なくとも4つの番号があります',
  'bank account number has least 7 numbers': '銀行口座番号には7つ以上の番号があります',
  'password with at least 8 characters': '8文字以上のパスワード',
  'password with at least 1 digit': '1桁以上のパスワード',
  'password contains upper, lower, number':
    'パスワードには、大文字、小文字、数字が含まれます。 特殊文字はありません',
  'no whitespace': '空白は許可されていません',
  'password only 8 character': 'パスワードは8文字まで使用できます',
  'cannot more than 8 character': '8文字を超えて入力することはできません',
};
