/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react';
import ECFormGroupTextArea from 'Components/Page/EC/Form/FormGroupTextArea';
import { ECApis } from 'Datasource/EC';
import { toast } from 'react-toastify';
import { useForm, FormProvider } from 'react-hook-form';
import useMessage from 'Hooks/useMessage';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { Col, Row, Form, Button } from 'react-bootstrap';
import BoxForm from 'Components/Common/Form/BoxForm';
import GroupTextProps from 'Components/Page/Title/FormTextGroup';
import CardContainer from 'Components/Common/CardContainer';
import dayjs from 'dayjs';
import { DATE_FORMAT_6 } from 'Constant/Date';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import { Link } from 'react-router-dom';
import Modal from 'Components/Common/Modal';
import ECPurchaseCancelAll from 'Components/Page/EC/ECPurchaseCancelAll';
import ECPurchaseCancel from 'Components/Page/EC/ECPurchaseCancel';
import { formatPrice } from 'Utils/Numbers';
import { PURCHASE_DETAIL_STATUS, PAYMENT_CARD } from 'Constant/EC';
import useTitlePage from 'Hooks/useTitlePage';
import ECPurchaseStatus from 'Components/Page/EC/ECPurchaseStatus';
import { useHistory, useParams } from 'react-router-dom';
import { renderShopName } from 'Constant/Shop';

export default function ECPurchaseDetail(): JSX.Element | null {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ECPurchaseDetailData, setECPurchaseDetailData] = useState<any>(null);

  const [isShowCancelAllModal, setIsShowCancelAllModal] = useState<boolean>(false);
  const [isShowCancelModal, setIsShowCancelModal] = useState<boolean>(false);

  const [selectedCancelItem, setSelectedCancelItem] = useState<any>(null);

  const history = useHistory();
  const { id }: { id: string } = useParams();

  const { openMessageError } = useMessage();

  const { setMenuTitle } = useTitlePage();

  const methods = useForm<any>({
    defaultValues: {
      description: null,
    },
    // resolver: yupResolver(ECSaleProductDetailSchema),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const isAllItemCancel = useMemo(() => {
    if (Array.isArray(ECPurchaseDetailData?.purchaseItems?.items)) {
      return ECPurchaseDetailData?.purchaseItems?.items.every(
        (item: any) => item.paymentStatus === PURCHASE_DETAIL_STATUS.CANCEL,
      );
    }

    return false;
  }, [ECPurchaseDetailData]);

  const handleGetPurchaseDetail = async (ids: any) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await ECApis.getPurchaseDetail(ids);

      setECPurchaseDetailData(data);
      if (data.description) {
        setValue('description', data.description);
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdatePurchaseDescription = async (value: any) => {
    try {
      if (!value.description) {
        return;
      }
      setIsLoading(true);

      const params = {
        description: value.description,
      };

      const {
        data: { statusCode },
      } = await ECApis.purchaseUpdateDescription({ id, params });

      if (statusCode === 200) {
        toast('管理者備考を更新しました。', { type: 'success' });
        handleGetPurchaseDetail(id);
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleGetPurchaseDetail(id);
    }
  }, [id]);

  useEffect(() => {
    setMenuTitle('EC購入データ詳細');
  }, []);

  return (
    <div className="pt-4 px-4 position-relative">
      <SpinnerComponent isLoading={isLoading} />
      <div className="d-flex">
        <div className="position-fixed ec_purchase_detail-left">
          <CardContainer title="購入情報" darkHeader className="mb-4">
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="ステータス"
              value={<ECPurchaseStatus status={ECPurchaseDetailData?.purchaseInfo?.status} />}
            />
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="注文番号"
              value={ECPurchaseDetailData?.purchaseInfo?.ecOrderId}
            />
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="注文日時"
              value={dayjs(ECPurchaseDetailData?.purchaseInfo?.createdAt).format(DATE_FORMAT_6)}
            />
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="ユーザーID"
              value={ECPurchaseDetailData?.purchaseInfo?.userMiimId}
            />
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="ニックネーム"
              value={ECPurchaseDetailData?.purchaseInfo?.userName}
            />
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="本名"
              value={ECPurchaseDetailData?.purchaseInfo?.userRealName}
            />
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="メールアドレス"
              value={ECPurchaseDetailData?.purchaseInfo?.email}
            />
          </CardContainer>
          <CardContainer title="支払い方法" darkHeader className="mb-4">
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="支払い金額"
              value={`¥ ${formatPrice(ECPurchaseDetailData?.paymentMethod?.total)}`}
            />
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="クレジット決済"
              value={
                <div className="d-flex align-items-center">
                  <div className="ec_purchase_detail-payment_card_logo">
                    {
                      PAYMENT_CARD[
                        ECPurchaseDetailData?.paymentMethod?.cardPayment?.replace(/\s/g, '')
                      ]?.logo
                    }
                  </div>
                  <span className="ms-2">{ECPurchaseDetailData?.paymentMethod?.numCard}</span>
                </div>
              }
            />
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="決済日時"
              value={dayjs(ECPurchaseDetailData?.paymentMethod?.createdAt).format(DATE_FORMAT_6)}
            />
          </CardContainer>

          <div className="d-flex justify-content-center mt-4">
            <Button
              className="w-80"
              onClick={() => {
                history.goBack();
              }}
            >
              もどる
            </Button>
          </div>
        </div>
        <div className="ec_purchase_detail-right">
          <BoxForm title="購入商品明細">
            {Array.isArray(ECPurchaseDetailData?.purchaseItems?.items) &&
              ECPurchaseDetailData?.purchaseItems?.items?.map((item: any) => {
                return (
                  <div
                    className={
                      item.paymentStatus === PURCHASE_DETAIL_STATUS.CANCEL ||
                      item.paymentStatus === PURCHASE_DETAIL_STATUS.ERROR
                        ? 'ec_purchase_detail-item ec_purchase_detail-item_disabled mt-1'
                        : 'ec_purchase_detail-item mt-1'
                    }
                    key={item?.coverImageUrl}
                  >
                    <Row>
                      <Col md="5">
                        <div className="ec_purchase_detail-item_infor break-word d-flex">
                          <div>
                            {item?.coverImageUrl ? (
                              <img
                                className="ec_purchase_detail-image border"
                                alt="preview"
                                src={handleConnectStaticEndPoint(item?.coverImageUrl)}
                              />
                            ) : (
                              <div className="ec_purchase_detail-image bg-gray avatar-bank d-flex justify-content-center align-items-center">
                                <p className="gray-dark">No Image</p>
                              </div>
                            )}
                          </div>
                          <div className="d-flex flex-column ms-3">
                            <div className="mb-1 d-flex flex-column">
                              <span className="ec_fs-10">タイトル</span>
                              <span className="fw-bold ec_fs-18 lg-fs-16px line-break-anywhere">
                                {item?.titleName}
                              </span>
                              <span className="ec_fs-12 ms-3">{item?.artistName}</span>
                            </div>
                            <div className="mb-1 d-flex flex-column">
                              <span className="ec_fs-10 mt-1">バリエーション</span>
                              <span className="fw-bold ec_fs-18 lg-fs-16px">
                                {item?.variationName}
                              </span>
                            </div>
                            <div className="mb-1">
                              <div>
                                <span className="ec_fs-10">販売用ID</span>
                              </div>
                              <div>
                                <Link
                                  to={`/ec/sale-product-detail/${item?.ecProductsChannelDetailId}`}
                                >
                                  <span className="ec_fs-14">{item?.cardCd}</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md="7">
                        <div className="d-flex flex-column justify-content-between h-100">
                          <div>
                            <div className="ec_purchase_detail-item_price">
                              <Row className="row-dpadding">
                                <Col md="4">
                                  <div className="d-flex flex-column">
                                    <span className="ec_fs-10">品番</span>
                                    <span className="fw-bold ec_fs-18 lg-fs-16px line-break-anywhere">
                                      {item?.productCode}
                                    </span>
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="d-flex flex-column">
                                    <span className="ec_fs-10">販売価格</span>
                                    <span className="fw-bold ec_fs-18 lg-fs-16px">{`¥${formatPrice(
                                      item?.purchasePrice,
                                    )}`}</span>
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="d-flex flex-column">
                                    <span className="ec_fs-10">数量</span>
                                    <span className="fw-bold ec_fs-18 lg-fs-16px">
                                      {item?.amount}点
                                    </span>
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="d-flex flex-column">
                                    <span className="ec_fs-10">小計</span>
                                    <span className="fw-bold ec_fs-18 lg-fs-16px">{`¥${formatPrice(
                                      item?.totalPrice,
                                    )}`}</span>
                                  </div>
                                </Col>
                              </Row>
                            </div>

                            <Row>
                              <Col md={7} className="px-0">
                                <div className="d-flex flex-column mt-1 lg-fs-12px">
                                  購入時キャンペーン
                                  {Array.isArray(item?.campaigns) && item?.campaigns.length > 0 ? (
                                    item?.campaigns?.map((campaign: any) => (
                                      <div
                                        key={campaign.id}
                                        className="ec_purchase_detail-campaign_link"
                                      >
                                        {campaign?.name}
                                      </div>
                                    ))
                                  ) : (
                                    <div className="ec_purchase_detail-campaign_link">なし</div>
                                  )}
                                </div>
                              </Col>

                              {item?.shopId !== undefined && item?.shopId !== null && (
                                <Col md={5} className="px-0 mt-1 lg-fs-12px">
                                  <div>
                                    <div>店舗名</div>
                                    <div className="ps-2" style={{ wordBreak: 'break-word' }}>
                                      {renderShopName(item)}
                                    </div>
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </div>
                          {item.paymentStatus === PURCHASE_DETAIL_STATUS.PURCHASED ? (
                            <div className="d-flex justify-content-end">
                              <Button
                                onClick={() => {
                                  setSelectedCancelItem(item);
                                  setIsShowCancelModal(true);
                                }}
                                className="btn btn-danger"
                              >
                                キャンセル
                              </Button>
                            </div>
                          ) : null}
                          {item.paymentStatus === PURCHASE_DETAIL_STATUS.CANCEL ? (
                            <div className="d-flex justify-content-end">
                              <Button className="btn btn-secondary" disabled>
                                キャンセル済み
                              </Button>
                            </div>
                          ) : null}
                          {item.paymentStatus === PURCHASE_DETAIL_STATUS.ERROR ? (
                            <div className="d-flex justify-content-end">
                              <Button disabled className="btn btn-secondary">
                                キャンセル
                              </Button>
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            <div className="d-flex mt-5">
              <Row className="w-100 align-items-center ">
                <Col md="5">
                  {isAllItemCancel ? (
                    <Button className="btn btn-secondary" disabled>
                      全キャンセル済み
                    </Button>
                  ) : ECPurchaseDetailData?.purchaseInfo?.status === '決済エラー' ? (
                    <Button className="btn btn-secondary" disabled>
                      オーダー全キャンセル
                    </Button>
                  ) : (
                    <Button
                      className="btn btn-danger"
                      onClick={() => setIsShowCancelAllModal(true)}
                    >
                      オーダー全キャンセル
                    </Button>
                  )}
                </Col>
                <Col md="7">
                  <div className="ec_purchase_detail-item_price">
                    <Row className="align-items-end row-dpadding">
                      <Col md="7">
                        <div className="d-flex flex-column">
                          <span className="fw-bold ec_fs-18">合計</span>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="d-flex flex-column">
                          <span className="ec_fs-10">数量</span>
                          <span className="fw-bold ec_fs-18">
                            {ECPurchaseDetailData?.purchaseItems?.amount}点
                          </span>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="d-flex flex-column">
                          <span className="ec_fs-10">合計</span>
                          <span className="fw-bold ec_fs-18">{`¥${formatPrice(
                            ECPurchaseDetailData?.purchaseItems?.totalPrice,
                          )}`}</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </BoxForm>

          <BoxForm title="管理者備考" classBox="mt-4">
            <Form>
              <div>
                <ECFormGroupTextArea
                  labelMd="2"
                  colMd="12"
                  label={''}
                  // register={register('type1.description')}
                  classForm="row-dpadding"
                  rows={10}
                  maxLength={2000}
                  value={watch('description')}
                  onChange={(value) => setValue('description', value)}
                  // errorMessage={errors.type1?.description?.message}
                />
                <div className="d-flex justify-content-end">
                  <Button onClick={handleSubmit(handleUpdatePurchaseDescription)}>保存</Button>
                </div>
              </div>
            </Form>
          </BoxForm>
        </div>
      </div>

      <Modal
        show={isShowCancelAllModal}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCloseModal={() => {
          setIsShowCancelAllModal(false);
        }}
        dialogClassName="w-1200px"
        footerClassName="d-none"
        title="注文キャンセル"
        // title={
        //   isReview
        //     ? `${
        //         (songList && songList.find((item: Song) => item.index === currentSong)?.name) ??
        //         '【楽曲が登録されていません】'
        //       }の詳細`
        //     : `${
        //         variationName ?? '名称未設定バリエーション'
        //       }の${currentSong}曲⽬のデータを登録します`
        // }
      >
        <ECPurchaseCancelAll
          onCloseModal={() => {
            setIsShowCancelAllModal(false);
          }}
          info={ECPurchaseDetailData?.purchaseInfo}
          data={ECPurchaseDetailData?.purchaseItems?.items?.filter(
            (item: any) => item.paymentStatus === PURCHASE_DETAIL_STATUS.PURCHASED,
          )}
          callback={() => handleGetPurchaseDetail(id)}
          setLoading={setIsLoading}
        />
      </Modal>
      <Modal
        show={selectedCancelItem && isShowCancelModal}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCloseModal={() => {
          setIsShowCancelModal(false);
        }}
        dialogClassName="w-1200px"
        footerClassName="d-none"
        title="注文キャンセル"
        // title={
        //   isReview
        //     ? `${
        //         (songList && songList.find((item: Song) => item.index === currentSong)?.name) ??
        //         '【楽曲が登録されていません】'
        //       }の詳細`
        //     : `${
        //         variationName ?? '名称未設定バリエーション'
        //       }の${currentSong}曲⽬のデータを登録します`
        // }
      >
        <ECPurchaseCancel
          onCloseModal={() => {
            setSelectedCancelItem(null);
            setIsShowCancelModal(false);
          }}
          info={ECPurchaseDetailData?.purchaseInfo}
          item={selectedCancelItem}
          callback={() => handleGetPurchaseDetail(id)}
          setLoading={setIsLoading}
        />
      </Modal>
    </div>
  );
}
