import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import StepRegister from 'Components/Page/Campaign/RegisterCampaign/Common/Step';
import { useAppSelector, useAppDispatch } from 'App/Store';
import {
  CampaignSteps,
  changeStep,
  resetData,
  updateStep1State,
  updateStep2State,
} from 'App/Features/Campaign';
import StepOverview from 'Components/Page/Campaign/RegisterCampaign/StepOverview';
import StepCourseAndMethod from 'Components/Page/Campaign/RegisterCampaign/StepCourseAndMethod';
import StepSignUpRewards from 'Components/Page/Campaign/RegisterCampaign/StepSignUpRewards';
import StepWinningConditions from 'Components/Page/Campaign/RegisterCampaign/StepWinningConditions';
import StepConfirm from 'Components/Page/Campaign/RegisterCampaign/StepConfirm';
import StepSuccess from 'Components/Page/Campaign/RegisterCampaign/StepSuccess';
import { withRouter, RouteComponentProps, useParams, useLocation } from 'react-router-dom';
import { CampaignApis } from 'Datasource/Campaign';
import useCampaign from 'Hooks/useCampaign';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';
import { isEmpty, omit } from 'lodash';
import { initialCourse } from 'Constant/Campaign';

const RegisterCampaign: React.FunctionComponent<RouteComponentProps> = ({
  history,
}): JSX.Element => {
  const { step, overview } = useAppSelector((state) => state.campaign);
  const { isSuccess } = useAppSelector((state) => state.mailRecipientList);
  const dispatch = useAppDispatch();
  const { id }: { id: string } = useParams();
  const { handleDecodeMatrixPrizes, handleSetOldTitleId } = useCampaign();
  const { openMessageError } = useMessage();
  const [loading, setLoading] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const location = useLocation<any>();

  const callbackRef = useRef<{ handleNextStepFromParent: (back?: boolean) => void }>(null);
  const handleNextStep = (newStep: CampaignSteps) => {
    if (newStep < step) {
      dispatch(changeStep({ step: newStep }));
      callbackRef && callbackRef?.current?.handleNextStepFromParent(true);
    }
    if (newStep > step) {
      callbackRef && callbackRef?.current?.handleNextStepFromParent(false);
    }
  };

  useEffect(
    () => {
      history?.listen(() => {
        dispatch(resetData());
        handleSetOldTitleId(true);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (location.pathname !== '/register-campaign') {
      dispatch(resetData());
      handleSetOldTitleId(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleGetCampaign = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await CampaignApis.getCampaign({ id: id || location?.state?.campaignId });
      const overview = {
        artistId: data?.artistId,
        artistName: data?.artistName,
        titleId: data?.titleId,
        titleName: data?.titleName,
        name: data?.name,
        startDate: data?.startDate,
        endDate: data?.endDate,
        description: data?.description,
        dataSize: +data?.dataSize ?? 0,
        status: data?.status,
        isDeletable: data?.isDeletable,
        earliestSaleDate: data?.earliestSaleDate,
        isAuthor: data?.isAuthor,
      };
      dispatch(
        updateStep1State({
          overview,
        }),
      );
      let courses = handleDecodeMatrixPrizes(data?.courses);
      if (location.state && location.state.campaignId) {
        courses = courses?.map((course: any) => {
          const courseInfos = course.courseInfos.map((info: any) => omit(info, 'id'));
          const blackListUsers = course?.blackListUsers?.map((blackListUser: any) =>
            omit(blackListUser, 'id'),
          );
          const prizes = course.prizes.map((prize: any) => {
            const prizeDigitalTokutens = prize?.prizeDigitalTokutens?.map((dGT: any) => {
              dGT = omit(dGT, 'id');
              return dGT?.percentage
                ? { ...dGT, percentage: `${dGT.percentage}` }
                : { ...dGT, percentage: dGT.percentage ? dGT.percentage : null };
            });
            prize = {
              ...prize,
              prizeDigitalTokutens,
            };
            const parseField = {
              productQuantity: prize?.productQuantity
                ? `${prize?.productQuantity}`
                : prize?.productQuantity,
              seatNumber: prize?.seatNumber ? `${prize?.seatNumber}` : prize?.seatNumber,
              prizeDigitalTokutens,
            };
            if (Number(prize?.type) === 2) {
              prize = {
                ...prize,
                seriesNumberMasterId: null,
                seriesNumberMasterName: null,
                seriesNumberMasterTotal: 0,
              };
            }
            return { ...omit(prize, 'id'), ...parseField };
          });
          return { ...omit(course, 'id'), courseInfos, prizes, blackListUsers };
        });
      }
      if (isEmpty(courses)) {
        courses = [initialCourse];
      }
      dispatch(
        updateStep2State({
          courses,
        }),
      );
      if (isSuccess) {
        dispatch(changeStep({ step: CampaignSteps.Success }));
        return;
      }
      const { state } = location;
      if (location.state && state.campaignId) {
        dispatch(
          changeStep({
            step: CampaignSteps.Overview,
          }),
        );
        return;
      }
      dispatch(
        changeStep({
          step: state?.isSaveDraft || CampaignSteps.Confirm,
        }),
      );
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleValidateDraft = () => {
    let valid = true;
    if (
      !overview?.artistId ||
      !overview?.titleId ||
      !overview?.name ||
      !overview?.startDate ||
      !overview?.endDate
    ) {
      valid = false;
    }
    return valid;
  };

  useEffect(() => {
    if (id) {
      handleGetCampaign();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useLayoutEffect(() => {
    if (step === CampaignSteps.Success) {
      handleGetCampaign();
    }
  }, [step]);

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <StepRegister handleNextStep={handleNextStep} />
      {step === CampaignSteps.Overview && (
        <StepOverview
          ref={callbackRef}
          campaignId={id}
          isValidate={isValidate}
          clearBackValidate={() => setIsValidate(false)}
        />
      )}
      {step === CampaignSteps.CourseAndMethod && (
        <StepCourseAndMethod
          ref={callbackRef}
          campaignId={id}
          handleValidateDraft={handleValidateDraft}
          setBackValidate={() => setIsValidate(true)}
        />
      )}
      {step === CampaignSteps.SignUpRewards && (
        <StepSignUpRewards
          ref={callbackRef}
          campaignId={id}
          handleValidateDraft={handleValidateDraft}
          setBackValidate={() => setIsValidate(true)}
        />
      )}
      {step === CampaignSteps.WinningConditions && (
        <StepWinningConditions
          ref={callbackRef}
          campaignId={id}
          handleValidateDraft={handleValidateDraft}
          setBackValidate={() => setIsValidate(true)}
        />
      )}
      {step === CampaignSteps.Confirm && (
        <StepConfirm
          campaignId={id}
          handleValidateDraft={handleValidateDraft}
          setBackValidate={() => setIsValidate(true)}
        />
      )}
      {step === CampaignSteps.Success && <StepSuccess />}
    </>
  );
};

export default withRouter(RegisterCampaign);
