import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LayoutState {
  hashPassword: string;
}

const initialState: LayoutState = {
  hashPassword: '',
};

export const hashPasswordSlice = createSlice({
  name: 'hashPassword',
  initialState,
  reducers: {
    setHashPassword: (state, data: PayloadAction<string>) => {
      const { payload } = data;
      state.hashPassword = payload;
    },
    removeHashPassword: (state) => {
      state = { ...initialState };
      return state;
    },
  },
});

export const { setHashPassword, removeHashPassword } = hashPasswordSlice.actions;
export default hashPasswordSlice.reducer;
