import React from 'react';

import { PAYMENT_CARD, PAYMENT_CARD_STATUS } from 'Constant/EC';
import { DATE_FORMAT_1 } from 'Constant/Date';

import dayjs from 'dayjs';

interface PaymentInfoData {
  available_date: number;
  brand: string;
  status: string;
}

const ECPaymentInfo = ({ data }: { data: PaymentInfoData }): JSX.Element | null => {
  const foundPaymentCard = PAYMENT_CARD[data?.brand] || null;

  //   const foundPaymentCard = PAYMENT_CARD[data?.brand as keyof typeof PAYMENT_CARD] || null;

  if (!foundPaymentCard) {
    return null;
  }

  return (
    <div className="d-flex flex-column align-items-center me-3 ec_paymentinfo_status">
      <div className="mb-1 d-flex align-items-center ec_paymentinfo_logo">
        {foundPaymentCard.logo}
      </div>
      <div
        className={`mb-1 ec_fs-12 d-flex justify-content-center ec_paymentinfo_status ${data?.status}`}
      >
        {PAYMENT_CARD_STATUS[data?.status]}
      </div>
      {data?.available_date && (
        <div className="d-flex flex-column ec_fs-12 align-items-center">
          <span>利用開始</span>
          <span>{dayjs(data?.available_date * 1000).format(DATE_FORMAT_1)}</span>
        </div>
      )}
    </div>
  );
};

export default ECPaymentInfo;
