import React, { useState, useEffect, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { parseUrl, stringify } from 'query-string';
// import Search from 'Components/Common/Search/Search';
import { use100vh } from 'react-div-100vh';
import SelectComponent from 'Components/Page/WebApp/Select';
import useMessage from 'Hooks/useMessage';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import ItemsEvent from './ItemsEvent';
import { useHistory } from 'react-router-dom';
import DatePicker from '../../../Components/Page/WebApp/DatePicker';
import { findIndex, pick } from 'lodash';
import MessageError from 'Components/Common/MessageError';
import { DATE_FORMAT_20 } from 'Constant/Date';
import dayjs from 'dayjs';
import { UsingStatusApi } from 'Datasource/UsingStatus';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { PREFIX_WEB_APP } from 'Constant';

interface IDataTickets {
  endDate: Date | null;
  eventName: string;
  id: number;
  placeName: string;
  startDate: Date | null;
  startTime: Date | null;
  type: number;
  isUnSelected: boolean;
}

interface IDataArtist {
  label: string;
  value: number;
}

interface IDataPlaces {
  label: string;
  value: number;
}

const resetPlaces: IDataPlaces = {
  label: '指定しない',
  value: -1,
};

export enum KeyLocalStorage {
  DataTickets = 'DataTickets',
}

const SearchWebApp = (): JSX.Element => {
  const height = use100vh();

  const responsiveHeight = height ? height - 74 : 'calc(100vh - 74px)';

  const history = useHistory();
  const { openMessageError } = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [isShowInfoSearch, setIsShowInfoSearch] = useState<boolean>(false);
  const [dataArtist, setDataArtist] = useState<IDataArtist[]>([]);
  const [dataPlaces, setDataPlaces] = useState<IDataPlaces[]>([]);
  const [dataTickets, setDataTickets] = useState<IDataTickets[] | null>(null);
  const [query, setQuery] = useQueryParams({
    date: StringParam,
    artist: StringParam,
    artistId: StringParam,
    place: StringParam,
    page: NumberParam,
  });

  const isAvaiableCheckedTicket = useMemo(() => {
    if (Array.isArray(dataTickets)) {
      const countChecktedTicket = dataTickets.reduce((count, ticket) => {
        if (!ticket?.isUnSelected) {
          return ++count;
        }
        return count;
      }, 0);

      return countChecktedTicket > 0;
    }
  }, [dataTickets]);

  const fetchDataArtist = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await UsingStatusApi.getListArtist();
      setDataArtist(
        data?.map((i: any) => {
          return { label: i.localName, value: i.id };
        }),
      );
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataPlace = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await UsingStatusApi.getListPlaces({
        artistId: query.artistId || '',
        date: query.date || '',
      });
      const places =
        data?.listPlaceName?.map((i: any) => ({ label: i?.placeName, value: i?.id })) || [];
      if (places?.length > 0) {
        setDataPlaces([resetPlaces, ...places]);
      } else {
        setDataPlaces(places);
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataTickets = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await UsingStatusApi.getListTickets({
        artistId: query.artistId || '',
        date: query.date || '',
        place: query.place && query.place != resetPlaces.label ? query.place : '',
      });
      /**
       * Handle bug #79890
       * Mr DungNX refer solution because no time
       */
      const dataTicketsLocalStorage = (JSON.parse(
        window?.localStorage?.getItem(KeyLocalStorage.DataTickets) || '[]',
      ) || []) as IDataTickets[];
      const mappingDataTickets = data?.prizes?.map((prize: IDataTickets) => {
        const prizeLocalStorage = dataTicketsLocalStorage?.find(
          (item: IDataTickets) => item?.id === prize?.id,
        );
        if (prizeLocalStorage && prizeLocalStorage['isUnSelected']) {
          return { ...prize, isUnSelected: prizeLocalStorage['isUnSelected'] };
        }
        return prize;
      });
      setDataTickets(mappingDataTickets || null);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataArtist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!query.artistId || !query.date) return;
    fetchDataPlace();
  }, [query.artistId, query.date]);

  useEffect(() => {
    if (!query.artistId || !query.date) return;
    fetchDataTickets();
  }, [query.artistId, query.date, query.place]);

  const handleSelectItems = (key: number) => {
    if (dataTickets) {
      const idx = findIndex(dataTickets, (item) => item?.id === key);
      const newItem = {
        ...dataTickets[idx],
        isUnSelected: !dataTickets[idx]['isUnSelected'],
      };
      const newDataTickets = [
        ...dataTickets?.slice(0, idx),
        newItem,
        ...dataTickets?.slice(idx + 1),
      ];
      window?.localStorage?.setItem(
        KeyLocalStorage.DataTickets,
        JSON.stringify(
          newDataTickets?.map((item) => ({ id: item?.id, isUnSelected: item['isUnSelected'] })),
        ),
      );
      setDataTickets(newDataTickets);
    }
  };

  const handleGoStatisPage = () => {
    const dataNext = [];
    if (Array.isArray(dataTickets)) {
      for (let i = 0; i < dataTickets?.length; i++) {
        if (!dataTickets[i]?.isUnSelected) dataNext.push(pick(dataTickets[i], 'id', 'type'));
      }
    }

    history.push({
      search: stringify(parseUrl(window?.location?.href)?.query),
      pathname: `${PREFIX_WEB_APP}/using-status`,
      state: { listId: dataNext },
    });
  };

  // const handleScroll = () => {
  //   const e = document?.getElementById('id-list-scroll');

  //   if ((e?.scrollTop ?? 0) >= 220) {
  //     setIsShowInfoSearch(true);
  //   } else {
  //     setIsShowInfoSearch(false);
  //   }
  // };

  useEffect(() => {
    window.onscroll = () => {
      const scrolled = document?.documentElement?.scrollTop;
      if ((scrolled ?? 0) >= 220) {
        setIsShowInfoSearch(true);
      } else {
        setIsShowInfoSearch(false);
      }
    };

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      window.onscroll = () => {};
    };
  }, []);

  const hanldeChangePlace = (place: IDataPlaces) => {
    window?.localStorage?.removeItem(KeyLocalStorage.DataTickets);
    setQuery({ place: place?.label });
  };

  return (
    <>
      <div className="d-flex flex-column">
        <SpinnerComponent isLoading={loading} />
        {dataTickets && dataTickets.length > 0 && query?.artist && isShowInfoSearch ? (
          <div className="d-flex align-items-center text-white px-3 nav-info-search">
            <div className="nav-info-search-artist fs-16">
              {`${query.date ? dayjs(query.date).format(DATE_FORMAT_20) : ''}`} &nbsp;
              {query.artist}
              <br />
              <div className="nav-info-search-place fs-16">{query.place}</div>
            </div>
          </div>
        ) : null}
        <div
          className="px-2 mx-1 py-2 d-flex flex-column search-result-main"
          style={{ minHeight: responsiveHeight }}
          id="id-list-scroll"
          // onScroll={handleScroll}
        >
          <div style={{ height: 'fit-content' }}>
            <SelectComponent
              label="アーティスト"
              labelMd="4"
              colMd="8"
              placeholder="アーティストを選択してください"
              // showIcon={false}
              classLabel="px-0 fs-16"
              rowClass="w-100 fs-16"
              colClass="px-0 fs-16"
              // searchKey="artist"
              // onEnter={() => {
              //   return;
              // }}
              value={
                query?.artistId && query?.artist
                  ? { value: Number(query?.artistId), label: query?.artist }
                  : null
              }
              isSearchable={true}
              onChange={(artist: any) => {
                window?.localStorage?.removeItem(KeyLocalStorage.DataTickets);
                setQuery({
                  artistId: artist?.value,
                  artist: artist?.label,
                  place: null,
                  date: null,
                  page: 1,
                });
              }}
              onMenuOpen={() =>
                setQuery({
                  artistId: null,
                  artist: null,
                  place: null,
                  date: null,
                  page: 1,
                })
              }
              options={dataArtist}
              // loading={false}
            />
            <DatePicker
              label="イベント開催日"
              placeholder="日付を選択してください"
              isDisabled={!query.artist}
            />
            <SelectComponent
              label="会場名"
              labelMd="4"
              colMd="8"
              placeholder="会場名を選択してください"
              // showIcon={false}
              classLabel="px-0 fs-16"
              rowClass="w-100 fs-16"
              colClass="px-0 fs-16"
              // searchKey="place"
              // onEnter={() => {
              //   return;
              // }}
              // onClickResult={() => {
              //   return;
              // }}
              value={
                query?.place ? dataPlaces?.find((place) => place.label === query?.place) : null
              }
              onChange={hanldeChangePlace}
              onMenuOpen={() =>
                setQuery({
                  place: null,
                  page: 1,
                })
              }
              isSearchable={true}
              options={dataPlaces}
              // loading={false}
              isDisabled={!query?.artist || !query?.date}
            />
          </div>

          {Array.isArray(dataTickets) && dataTickets.length > 0 && (
            <div style={{ marginBottom: '70px' }}>
              {!isAvaiableCheckedTicket && (
                <div className="d-flex justify-content-center">
                  <MessageError
                    classWrapper="ms-0"
                    message="表示するチケットを選択してください"
                    classMessage="fs-11 fw-700"
                  />
                </div>
              )}
              {dataTickets.map((i: IDataTickets) => (
                <ItemsEvent
                  key={i.id}
                  eventName={i?.eventName}
                  typeTicket={i?.type}
                  startDate={i?.startDate}
                  endDate={i?.endDate}
                  time={i?.startTime}
                  place={i?.placeName}
                  isUnSelected={i?.isUnSelected}
                  onClick={() => handleSelectItems(i.id)}
                />
              ))}
            </div>
          )}
          {Array.isArray(dataTickets) && dataTickets.length === 0 && (
            <div className="d-flex justify-content-center align-items-center flex-fill fs-16">
              該当イベントはありません。
            </div>
          )}
        </div>
        {Array.isArray(dataTickets) && dataTickets.length > 0 && (
          <div className="d-flex align-items-center justify-content-center btn-sticky-bottom">
            <Button
              className="btn-sticky-bottom-1 fs-16"
              onClick={handleGoStatisPage}
              disabled={!isAvaiableCheckedTicket}
            >
              チケット利用状況を表示
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchWebApp;
