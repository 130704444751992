import React, { useState, useEffect } from 'react';
import { includes, map } from 'lodash';
import CuratorLists, { Curator } from 'Page/CuratorManagement/List';
import CuratorRegister from 'Page/CuratorManagement/Register';
import Tabs from 'Components/Common/Tabs/Tabs';
import Filter from 'Components/Common/Filter/Filter';

import { useAppSelector } from 'App/Store';
import { getRoleName } from 'Utils/Permission';
import { RolesRecord, ROLE_RECORD_MENU } from 'Constant/Roles';
import { Col, Row } from 'react-bootstrap';
import Search from 'Components/Common/Search/Search';
import useSuggestion from 'Hooks/useSuggestion';
const defaultValue = {
  artists: [],
  furiganaManagerName: '',
  id: null,
  managerEmail: '',
  managerName: '',
  managerPermisson: '',
  managerPhone: '',
  session: '',
};

export default function CuratorManagement(): JSX.Element {
  const { role } = useAppSelector((state) => state.auth);
  const [btnGroupOptions, setBtnGroupOptions] = useState([
    { name: '担当者リスト', disabled: false },
    {
      name: '担当者登録',
      disabled:
        !includes(RolesRecord[0], role) &&
        !includes(RolesRecord[4], role) &&
        !includes(RolesRecord[6], role),
    },
  ]);
  const [curatorTabActivated, setCuratorTabActivated] = useState<string>(btnGroupOptions[0].name);
  const [selectedItem, setSelectedItem] = useState<Curator>(defaultValue);
  const ecRole = [
    ROLE_RECORD_MENU.ECCompMaster,
    ROLE_RECORD_MENU.ECCompManager,
    ROLE_RECORD_MENU.SALEChanelMaster,
    ROLE_RECORD_MENU.SALEChanelManager,
  ];

  const onChangeTab = (tab: string) => {
    setCuratorTabActivated(tab);
    if (tab === btnGroupOptions[0].name) {
      setSelectedItem(defaultValue);
    }
  };

  const handleClickItem = (data: Curator) => {
    setCuratorTabActivated(btnGroupOptions[1].name);
    setSelectedItem(data);
  };

  useEffect(() => {
    if (role && getRoleName(role) === 'COMMON') {
      const newOptions = map(btnGroupOptions, (option) => ({
        ...option,
        disabled: option.name === btnGroupOptions[1].name,
      }));
      setBtnGroupOptions(newOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const {
    artistOptions,
    loadingArtist,
    handleLoadMore,
    handleFocusInput,
    handleEnterPrimary,
    handleClickItemPrimary,
  } = useSuggestion({
    fetchList: ['artist'],
    showDraftVariation: true,
  });

  return (
    <div style={{ marginTop: '-10px' }}>
      <Filter filterClass="pt-0 shadow-sm py-0">
        <Row className="align-items-end px-0">
          <Col md="10">
            <Row className="px-0 d-flex flex-row align-items-end">
              <Col md="4" className=" pe-0">
                <Tabs
                  heightBtn="43px"
                  btnClass="py-2 w-btn-tab"
                  defaultActive={btnGroupOptions[0].name}
                  tabActive={curatorTabActivated}
                  options={btnGroupOptions}
                  onChange={onChangeTab}
                />
              </Col>
              {curatorTabActivated === btnGroupOptions[0].name && !ecRole.includes(role ?? '') && (
                <Col md="4" className="px-4">
                  <Search
                    label="アーティスト"
                    labelMd="12"
                    colMd="12"
                    placeholder="アーティスト"
                    showIcon
                    classLabel="px-0"
                    rowClass="w-100"
                    colClass="px-0"
                    searchKey="queryArtist"
                    onEnter={() => handleEnterPrimary('queryArtist')}
                    onClickResult={handleClickItemPrimary}
                    options={artistOptions.options || []}
                    loading={loadingArtist}
                    onLoadMore={() => handleLoadMore('pageArtist')}
                    onFocusInput={() => {
                      handleFocusInput('queryArtist', 'pageArtist');
                    }}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Filter>
      <div>
        {curatorTabActivated === btnGroupOptions[0].name ? (
          <CuratorLists clickDetail={handleClickItem} />
        ) : (
          <CuratorRegister
            data={selectedItem}
            onChangeTab={() => onChangeTab(btnGroupOptions[0].name)}
          />
        )}
      </div>
    </div>
  );
}
