import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { AuthApi } from 'Datasource/Auth';
import { useHistory, useParams } from 'react-router-dom';
import useMessage from 'Hooks/useMessage';
import { useAppDispatch } from 'App/Store';
import { login, logout } from 'App/Features/Auth';

export default function AccessCode(): JSX.Element {
  const [isLoading] = useState<boolean>(true);
  const history = useHistory();
  const { accessCode } = useParams<{ accessCode: string }>();
  const dispatch = useAppDispatch();
  const { openMessageError } = useMessage();

  const getAccessToken = async () => {
    try {
      dispatch(logout());
      const res = await AuthApi.getAccess({ accessCode });
      const { data } = res.data;
      dispatch(login(data));
      history.push('/');
    } catch (error) {
      openMessageError(error);
      history.push('/login');
    }
  };

  useEffect(() => {
    getAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessCode]);

  return (
    <div className="position-relative">
      <Container className="d-flex flex-column justify-content-center align-items-center vh-100 text-white">
        <SpinnerComponent isLoading={isLoading} />
      </Container>
    </div>
  );
}
