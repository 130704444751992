import React, { useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup, Col, Container, Row, Spinner } from 'react-bootstrap';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import dayjs from 'dayjs';
import Filter from 'Components/Common/Filter/Filter';
import { TableReport } from 'Components/Page/InformationAccountant/index';
import { jasracApi } from 'Datasource/JasracApi';
import { exportExcel } from 'Utils/File';
import { DATE_FORMAT_5, DATE_FORMAT_7, DATE_FORMAT_YM } from 'Constant/Date';
import useMessage from 'Hooks/useMessage';
import Tabs from 'Components/Common/Tabs/Tabs';
import 'Static/Styles/report.scss';
import TableReportNxt from 'Components/Page/InformationAccountant/TableReportNxt';
import TableReportVideo from 'Components/Page/InformationAccountant/TableReportVideo';
import TableReportNxtVideo from 'Components/Page/InformationAccountant/TableReportNxtVideo';
import useErrorBlob from 'Hooks/useErrorBlob';
import {
  btnGroupOptionsSong,
  btnGroupOptionsType,
  convertValueMusicType,
  convertValueSongType,
  DATA_REPORT_TYPE,
  SONG_TYPE,
} from 'Utils/Jasrac';

export default function Jasrac(): JSX.Element {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    type: StringParam,
    musicType: StringParam,
    status: StringParam,
    groupDate: StringParam,
  });
  const page = query.page ?? 1;
  const [type, setType] = useState(1);
  const [musicType, setMusicType] = useState(query.musicType || btnGroupOptionsType[0].value);

  const [groupDate, setGroupDate] = useState<any>(query.groupDate || new Date());

  const { openMessageError } = useMessage();
  const { openErrorBlob } = useErrorBlob();

  const [tabActiveSong, setTabActiveSong] = useState(query.type || btnGroupOptionsSong[0].name);
  const [tabActiveType, setTabActiveType] = useState(
    query.musicType || btnGroupOptionsType[0].value,
  );
  const [exporting, setExporting] = useState<boolean>(false);

  const handleChangeTabSong = (tab: string) => {
    if (!tab) return;
    setTabActiveSong(tab);
    setQuery({ type: tab });
    if (tab === SONG_TYPE.JASRAC) {
      setType(1);
    }
    if (tab === SONG_TYPE.NEXTONE) {
      setType(2);
    }
    if (tab === SONG_TYPE.MANA_IT_YOURSELF) {
      setType(3);
    }
    if (tab === SONG_TYPE.OTHER) {
      setType(4);
    }
  };

  const handleChangeTabType = (tab: string) => {
    if (!tab) return;
    setTabActiveType(tab);
    setMusicType(tab);
    setQuery({ musicType: tab });
  };

  const handleGetJasrac = async () => {
    try {
      setLoading(true);
      const startDate = dayjs(groupDate).startOf('month').format(DATE_FORMAT_7);
      const endDate = dayjs(groupDate).endOf('month').format(DATE_FORMAT_7);
      const authorizedType = type ?? 1;
      let categoryType;
      if (musicType === DATA_REPORT_TYPE.MUSIC) {
        categoryType = 2;
      }
      if (musicType === DATA_REPORT_TYPE.VIDEO) {
        categoryType = 1;
      }
      if (musicType === DATA_REPORT_TYPE.LYRIC) {
        categoryType = 3;
      }

      const {
        data: { data },
      } = await jasracApi.getList({
        startDate: startDate,
        endDate: endDate,
        type: type,
        page: page,
        limit: 100,
        authorizedType: authorizedType,
        categoryType: categoryType,
      });
      if (musicType === DATA_REPORT_TYPE.MUSIC) {
        setDataTable(data.dataMusicReport);
      }
      if (musicType === DATA_REPORT_TYPE.VIDEO) {
        setDataTable(data.dataVideoReport);
      }
      if (musicType === DATA_REPORT_TYPE.LYRIC) {
        setDataTable(data.dataLyricReport);
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleExport = async (btnType: string) => {
    const startDate = dayjs(groupDate).startOf('month').format(DATE_FORMAT_7);
    const endDate = dayjs(groupDate).endOf('month').format(DATE_FORMAT_7);
    const page = query?.page ?? 1;
    const limit = 100;
    let outputOption;
    if (musicType === DATA_REPORT_TYPE.MUSIC) {
      outputOption = 'dataMusicReport';
    }
    if (musicType === DATA_REPORT_TYPE.VIDEO) {
      outputOption = 'dataVideoReport';
    }
    if (musicType === DATA_REPORT_TYPE.LYRIC) {
      outputOption = 'dataLyricReport';
    }
    const authorizedType = type ?? 1;
    let categoryType;
    if (musicType === DATA_REPORT_TYPE.MUSIC) {
      categoryType = 2;
    }
    if (musicType === DATA_REPORT_TYPE.VIDEO) {
      categoryType = 1;
    }
    if (musicType === DATA_REPORT_TYPE.LYRIC) {
      categoryType = 3;
    }

    try {
      setExporting(true);
      if (btnType === 'tsv') {
        exportExcel(
          jasracApi.exportTSV({
            startDate,
            endDate,
            authorizedType,
            categoryType,
            page,
            limit,
            outputOption,
          }),
          `著作権報告書_${convertValueSongType(type)}_${convertValueMusicType(musicType)}_${dayjs(
            startDate,
          ).format(DATE_FORMAT_YM)}`,
          'tsv',
        );
      }
      if (btnType === 'excel') {
        exportExcel(
          jasracApi.exportExcel({
            startDate,
            endDate,
            authorizedType,
            categoryType,
            page,
            limit,
            outputOption,
          }),
          `著作権報告書_${convertValueSongType(type)}_${convertValueMusicType(musicType)}_${dayjs(
            startDate,
          ).format(DATE_FORMAT_YM)}`,
        );
      }
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setExporting(false);
    }
  };
  const handleChangeDate = (isAdd = false) => {
    const date = isAdd ? dayjs(groupDate).add(1, 'month') : dayjs(groupDate).subtract(1, 'month');
    setQuery({ groupDate: dayjs(date).format(DATE_FORMAT_7) });
    setGroupDate(date);
  };

  useEffect(() => {
    handleGetJasrac();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, groupDate, type, musicType]);

  /**
   * save query params
   */
  useEffect(() => {
    if (query.musicType) {
      setTabActiveType(query.musicType);
      setMusicType(query.musicType);
    }

    if (query.type) {
      setTabActiveSong(query.type);
      if (query.type === 'JASRAC') {
        setType(1);
      }
      if (query.type === 'NexTone') {
        setType(2);
      }
      if (query.type === '自己管理') {
        setType(3);
      }
      if (query.type === 'その他') {
        setType(4);
      }
    }

    if (query.groupDate) {
      setGroupDate(dayjs(query.groupDate));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTable = useMemo(() => {
    if (tabActiveSong !== 'NexTone') {
      if (musicType !== '動画') {
        return <TableReport dataTable={dataTable} loading={loading} mediaType={tabActiveType} />;
      } else {
        return (
          <TableReportVideo dataTable={dataTable} loading={loading} mediaType={tabActiveType} />
        );
      }
    } else {
      if (musicType !== '動画') {
        return <TableReportNxt dataTable={dataTable} loading={loading} mediaType={tabActiveType} />;
      } else {
        return (
          <TableReportNxtVideo dataTable={dataTable} loading={loading} mediaType={tabActiveType} />
        );
      }
    }
  }, [dataTable, loading, musicType, tabActiveSong, tabActiveType]);

  return (
    <>
      <Container fluid className="always-show-scrollbar">
        <Filter filterClass="shadow-sm">
          <Row>
            <div className="display-content p-0">
              <Col md="6" className="width-content">
                <Row>
                  <Col className="display-tab-content p-0">
                    <div className="align-self-center">
                      <Tabs
                        groupClass="btn-group-statistic mt-1"
                        options={btnGroupOptionsSong}
                        tabActive={tabActiveSong}
                        onChange={handleChangeTabSong}
                        clickOnly
                        disabledSetQuery
                      />
                    </div>
                    <div className="align-self-center padding-tab-content margin-top-1rem">
                      <Tabs
                        groupClass="btn-group-statistic mt-1"
                        options={btnGroupOptionsType}
                        tabActive={tabActiveType}
                        onChange={handleChangeTabType}
                        clickOnly
                        disabledSetQuery
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md="6" className="width-content">
                <div className="display-btn-content">
                  <div className="btn-content">
                    <div className="margin-top-1rem">
                      <Button
                        className={`mt-1 d-flex prize-btn-excel justify-content-center align-items-center me-3 button-submit ${
                          exporting ? 'pe-none' : ''
                        }`}
                        onClick={() => handleExport('tsv')}
                      >
                        {exporting ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          'TSVダウンロード'
                        )}
                      </Button>
                    </div>
                    <div className="margin-top-1rem">
                      <Button
                        className={`mt-1 prize-btn-excel d-flex justify-content-center align-items-center me-3 button-submit ${
                          exporting ? 'pe-none' : ''
                        }`}
                        onClick={() => handleExport('excel')}
                      >
                        {exporting ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          'Excelダウンロード'
                        )}
                      </Button>
                    </div>
                    <ButtonGroup className="btn-group-date">
                      <Button
                        className="bg-white text-dark custom-border mt-1 d-flex justify-content-center align-items-center"
                        onClick={() => handleChangeDate(false)}
                      >
                        前月
                      </Button>
                      <Button
                        variant="dark"
                        className="bg-dark custom-border mt-1 d-flex justify-content-center align-items-center"
                      >
                        {dayjs(groupDate).format(DATE_FORMAT_5)}
                      </Button>
                      <Button
                        className="bg-white text-dark custom-border mt-1 d-flex justify-content-center align-items-center"
                        onClick={() => handleChangeDate(true)}
                      >
                        次月
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              </Col>
            </div>
          </Row>
        </Filter>
        <Container fluid>{renderTable}</Container>
      </Container>
    </>
  );
}
