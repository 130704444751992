import React from 'react';

function HintTextField({
  hintText,
  classNames,
}: {
  hintText: string;
  classNames?: string;
}): JSX.Element {
  return (
    <>
      {hintText && (
        <p style={{ whiteSpace: 'pre-line' }} className={`hint-text-fs-12 px-0 ${classNames}`}>
          {hintText}
        </p>
      )}
    </>
  );
}

export default HintTextField;
