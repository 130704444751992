import React from 'react';
import { Form } from 'react-bootstrap';
import TableStep2 from 'Components/Page/Campaign/RegisterCampaign/Common/TableStep2';
import { CDStatus, TargetProduct } from 'Constant/Campaign';
import { CampaignObject } from 'Types/Page/Campaign/RegisterCampaign';
import { filter, isEmpty, some } from 'lodash';
import { formatCurrency } from 'Utils/Numbers';
interface ColumnsTypes {
  className?: string;
  width?: number;
  text: string;
  dataIndex: string;
  rowSpan?: number;
  colSpan?: number;
  render?: (data: any) => void;
}

interface TableColumns {
  columns: ColumnsTypes[];
}

interface TableProps {
  dataSource: CampaignObject[];
  nestIndex: number;
  titleName: string;
  isShowConditions?: boolean;
  control: any;
  register: any;
  setValue: any;
  getValues: any;
  isLastDP?: any;
  disabled?: boolean;
  onDpClick?: (item: any) => void;
}

interface CampaignObjectOpen extends CampaignObject {
  rIndex: number;
}

interface CheckboxType
  extends Omit<TableProps, 'titleName' | 'control' | 'dataSource' | 'setValue' | 'getValues'> {
  item: CampaignObjectOpen;
  className: string;
  nestIndex: number;
  dataIndex: string;
}

const checkClassName = 'checkbox-no-label check-box-size check-box-ct';

const CheckBoxFalse = ({ className }: { className: string }) => {
  return (
    <div className="text-center">
      <Form.Check type="checkbox" defaultChecked={false} className={className} disabled={true} />
    </div>
  );
};

const CheckBox = ({ item, dataIndex, className, nestIndex, register }: CheckboxType) => {
  return (
    <div className="text-center">
      <Form.Check
        type="checkbox"
        defaultChecked={(item as any)[dataIndex]}
        className={className}
        disabled={Number(item?.status || item?.cdStatus) === CDStatus.Used && !item.enabled}
        {...register(`courses.${nestIndex}.courseInfos.${item.rIndex}.${dataIndex}`)}
      />
    </div>
  );
};

const handleRenderCheckBox = ({
  item,
  dataIndex,
  className,
  nestIndex,
  register,
}: CheckboxType) => {
  return (
    <>
      {item?.enabled ? (
        <CheckBox
          item={item}
          dataIndex={dataIndex}
          className={className}
          nestIndex={nestIndex}
          register={register}
        />
      ) : (
        <CheckBoxFalse className={className} />
      )}
    </>
  );
};

const TableCampaign = ({
  dataSource,
  nestIndex,
  register,
  isShowConditions,
  titleName,
  isLastDP,
  disabled,
  onDpClick,
}: TableProps): JSX.Element => {
  let headerTables = [
    {
      columns: [
        {
          className: 'text-center align-middle',
          text: 'No',
          rowSpan: 2,
          width: 50,
          dataIndex: 'rIndex',
          render: ({ item }) => {
            return <div className="text-center">{item.rIndex + 1}</div>;
          },
        },
        {
          className: 'text-center align-middle',
          text: '対象商品',
          rowSpan: 2,
          width: 500,
          dataIndex: 'variations',
          render: ({ item }) => {
            return (
              <div style={{ maxWidth: 600 }}>
                {titleName} {(item?.name || item?.variationName) ?? item?.cdName}&nbsp;
                {item?.type === TargetProduct.CD ? (
                  <span className="badge bg-secondary">CD</span>
                ) : (
                  ''
                )}
              </div>
            );
          },
        },
        {
          className: 'text-center align-middle',
          text: '対象選択',
          rowSpan: 2,
          width: 50,
          dataIndex: 'enabled',
          render: ({ item, dataIndex }) => {
            const isExistCD = some(
              dataSource,
              (info) => info.enabled && info?.type === TargetProduct.CD,
            );
            const restEl = filter(
              dataSource,
              (info: any) =>
                info.index !== item.index && info.enabled && info?.type === TargetProduct.DP,
            );
            let isLast = item.enabled && isEmpty(restEl) && isExistCD;
            if (disabled) {
              isLast = disabled;
            }

            if (
              item?.type === TargetProduct.DP &&
              item.enabled &&
              dataSource.filter((v) => v.enabled).length === 1
            ) {
              isLast = true;
            }
            return (
              <div
                onClick={() => {
                  isLastDP(item);
                  const disableCheck =
                    (Number(item?.status || item?.cdStatus) === CDStatus.Used && !item.enabled) ||
                    isLast;
                  if (!disableCheck && onDpClick) {
                    onDpClick(item);
                  }
                }}
                aria-hidden="true"
              >
                <div
                  className={
                    isLast ? 'pe-none user-select-none opacity-50' : 'pe-auto user-select-auto'
                  }
                >
                  <CheckBox
                    item={item}
                    dataIndex={dataIndex}
                    className={checkClassName}
                    nestIndex={nestIndex}
                    register={register}
                    disabled={isLast}
                  />
                </div>
              </div>
            );
          },
        },
        {
          className: 'text-center align-middle',
          text: '種別',
          rowSpan: 2,
          width: 100,
          dataIndex: 'type',
          render: ({ item }) => {
            return (
              <div className="text-center">{item?.type === TargetProduct.CD ? 'CD' : 'DP'}</div>
            );
          },
        },
        {
          className: 'text-center align-middle',
          text: '品番',
          rowSpan: 2,
          width: 150,
          dataIndex: 'productCode',
          render: ({ item, dataIndex }) => {
            return <div className="text-center text-break">{item[dataIndex]}</div>;
          },
        },
        {
          className: 'text-center align-middle',
          text: '価格',
          rowSpan: 2,
          width: 100,
          dataIndex: 'price',
          render: ({ item, dataIndex }) => {
            return <div className="text-end">{`¥ ${formatCurrency(item[dataIndex])}`}</div>;
          },
        },
      ],
    },
  ] as TableColumns[];

  if (isShowConditions) {
    headerTables = [
      {
        columns: [
          ...headerTables[0].columns,
          {
            className: 'text-center align-middle',
            text: '条件マッピング',
            colSpan: 5,
            dataIndex: '',
          },
        ],
      },
      {
        columns: [
          {
            className: 'text-center align-middle',
            text: '条件1',
            width: 80,
            dataIndex: 'option1',
            render: ({ item, dataIndex }) => {
              return handleRenderCheckBox({
                item,
                dataIndex,
                className: checkClassName,
                nestIndex,
                register,
              });
            },
          },
          {
            className: 'text-center align-middle',
            text: '条件2',
            width: 80,
            dataIndex: 'option2',
            render: ({ item, dataIndex }) => {
              return handleRenderCheckBox({
                item,
                dataIndex,
                className: checkClassName,
                nestIndex,
                register,
              });
            },
          },
          {
            className: 'text-center align-middle',
            text: '条件3',
            width: 80,
            dataIndex: 'option3',
            render: ({ item, dataIndex }) => {
              return handleRenderCheckBox({
                item,
                dataIndex,
                className: checkClassName,
                nestIndex,
                register,
              });
            },
          },
          {
            className: 'text-center align-middle',
            text: '条件4',
            width: 80,
            dataIndex: 'option4',
            render: ({ item, dataIndex }) => {
              return handleRenderCheckBox({
                item,
                dataIndex,
                className: checkClassName,
                nestIndex,
                register,
              });
            },
          },
        ],
      },
    ];
  }

  return (
    <div>
      <TableStep2 headerTables={headerTables} dataSource={dataSource} />
    </div>
  );
};

export default TableCampaign;
