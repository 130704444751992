import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { CHART_BLUE, CHART_RED } from 'Constant/Colors';
import { DATE_FORMAT_12, DATE_FORMAT_17 } from 'Constant/Date';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';

import { Bar } from 'react-chartjs-2';
import { RealTimeInit, RealTimeActual } from 'Types/Page/Campaign';

interface Props {
  realtimeInit: RealTimeInit[];
  realtimeActual: RealTimeActual[];
}

export default function RealTimeChart({ realtimeInit, realtimeActual }: Props): JSX.Element {
  const data1 = useMemo(() => {
    return realtimeInit.map((item) => (item.applyNumber ? Math.round(item.applyNumber) : 0));
  }, [realtimeInit]);

  const data2 = useMemo(() => {
    return realtimeActual.map((item) => (item.applyNumber ? Math.round(item.applyNumber) : 0));
  }, [realtimeActual]);

  const labels = useMemo(() => {
    return realtimeInit.map((item) => dayjs(item.date).locale('ja').format(DATE_FORMAT_12));
  }, [realtimeInit]);

  const subLabels = useMemo(() => {
    return realtimeInit.map((item) => dayjs(item.date).format(DATE_FORMAT_17));
  }, [realtimeInit]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: { boxWidth: 10 },
        fullSize: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      secondXAxis: {
        axis: 'x',
        labels: subLabels,
        grid: {
          drawOnChartArea: false,
          display: false,
          drawBorder: false,
        },
      },
    },
    maintainAspectRatio: realtimeInit.length < 50,
  };

  const data = {
    labels,
    datasets: [
      {
        label: '応募予想',
        data: data1,
        backgroundColor: CHART_BLUE,
      },
      {
        label: '応募実績',
        data: data2,
        backgroundColor: CHART_RED,
      },
    ],
  };

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
  return (
    <div className="chartWrapper p-2">
      <div
        className="chartAreaWrapper"
        style={{ width: realtimeInit.length > 50 ? `${realtimeInit.length * 30}px` : '1500px' }}
      >
        <Bar options={options as any} data={data} />
      </div>
    </div>
  );
}
