/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ISelect } from 'Types/Common';
import { isEmpty } from 'lodash';
import { LoadingIcon } from 'Components/Common/Select/CustomSelect/LoadingIcon';
import TooltipsWithTruncate from 'Components/Common/Tooltips/TooltipsWithTruncate';
interface ResultsProps {
  results: ISelect[];
  onClick: (result: ISelect) => void;
  setSearchString: (searchString: string) => void;
  maxResults?: number;
  resultStringKeyName?: string;
  formatResult?: (val: string | undefined) => React.ReactNode;
  isShowResult?: boolean;
  isLoading?: boolean;
  onLoadMore?: (event: any) => void;
  isTooltipsResults?: boolean;
  stylesListResult?: React.CSSProperties;
}

const Results = (props: ResultsProps): JSX.Element => {
  const {
    results,
    onClick,
    setSearchString,
    resultStringKeyName = 'label',
    formatResult = (val: string | undefined) => val,
    isShowResult = false,
    isLoading = false,
    onLoadMore,
    isTooltipsResults = false,
    stylesListResult = {},
  } = props;

  const handleClick = (result: any) => {
    onClick(result);
    setSearchString(result[resultStringKeyName]);
  };

  if (!isShowResult) return <div />;

  return (
    <>
      <div className="search-results">
        {isEmpty(results) && !isLoading ? (
          <ul>
            <li>
              <div className="text-center w-100">検索結果がありません。</div>
            </li>
          </ul>
        ) : (
          <ul
            onScroll={(event: any) => {
              const { scrollTop, clientHeight, scrollHeight } = event?.target;
              const bottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 2;
              if (bottom) {
                onLoadMore && onLoadMore(event);
              }
            }}
            style={{ ...stylesListResult }}
          >
            {results.map((result, index) => {
              if (isTooltipsResults) {
                return (
                  <TooltipsWithTruncate
                    key={`rsa-result-${index}`}
                    id={`rsa-result-${index}`}
                    tooltips={result.label ? result.label : ''}
                    extraClientWidth={20}
                  >
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                    <li
                      data-test="result"
                      key={`rsa-result-${index}`}
                      onMouseDown={() => handleClick(result)}
                      onClick={() => handleClick(result)}
                      className="result-option"
                    >
                      {/* whiteSpace: 'pre' => no data compression, keep enough white space */}
                      <div className="text-truncate" style={{ whiteSpace: 'pre' }}>
                        {formatResult(result.label)}
                      </div>
                    </li>
                  </TooltipsWithTruncate>
                );
              }

              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <li
                  data-test="result"
                  key={`rsa-result-${index}`}
                  onMouseDown={() => handleClick(result)}
                  onClick={() => handleClick(result)}
                  className="result-option"
                >
                  <div className="text-truncate">{formatResult(result.label)}</div>
                </li>
              );
            })}
            {isLoading && (
              <li className="result-option d-flex flex-column">
                <div className="align-items-center">
                  <LoadingIcon />
                </div>
              </li>
            )}
          </ul>
        )}
      </div>
    </>
  );
};
export default Results;
