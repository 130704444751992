import React, { useMemo, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { CaretUpFill, CaretDownFill, CloudDownload, Trash } from 'react-bootstrap-icons';
import Table from 'Components/Common/Table';
import Modal from 'Components/Common/Modal';
import SongInformation from 'Components/Page/Title/Variation/SongInfo';
import { Song, Variation } from 'Types/App/title';
import { useAppDispatch, useAppSelector } from 'App/Store';
import { convertJsonSong } from 'Utils/SheetJs';
import * as XLSX from 'xlsx';
import { useDropzone } from 'react-dropzone';
import { setCaculateFee, setVariationSongs, setVariationVideoSongs } from 'App/Features/Title';
import {
  DEFAULT_VARIATION_NAME,
  INCLUDE_JARSAC_CONTENT,
  TYPE_MOVE,
  VIDEO_TYPES,
} from 'Constant/Title';
import { calcMb, isAfterDownload } from 'Utils/File';
import MessageError from 'Components/Common/MessageError';
import { isEmpty } from 'lodash';
import useMessage from 'Hooks/useMessage';
import { updateQuery } from 'Utils/Search';

interface BoxSongTypes {
  isReview?: boolean;
  variationName?: string;
  variationIndex: number;
  errors?: any[];
  setSongsToForm?: (songs: Song[]) => void;
  onClickError?: () => void;
  isDiabledEditSong?: boolean;
  isSong?: boolean;
  handleAddSong?: () => void;
  handleImportSong?: (song: any) => void;
  handleDeteleSong: (song: Song) => void;
  handleMoveItemSong: (song: Song, type: string) => void;
  songs: Song[];
  numberRemainSong?: number;
  isVideo?: boolean;
  indexVideo?: number;
  variation?: Variation;
  titleName?: string | undefined | null;
}

const SongTable = ({
  isReview = false,
  variationName,
  variationIndex,
  errors = [],
  onClickError,
  isDiabledEditSong,
  handleAddSong,
  handleDeteleSong,
  handleImportSong,
  handleMoveItemSong,
  songs,
  numberRemainSong = 0,
  isVideo = false,
  indexVideo = 0,
  variation,
  titleName,
}: BoxSongTypes): JSX.Element => {
  const dispatch = useAppDispatch();

  const { titleForm } = useAppSelector((state) => state.title);
  const [visible, setVisible] = useState<boolean>(false);
  const [currentSong, setCurrentSong] = useState<number>();
  const { openMessage } = useMessage();

  const handleImportFile = (files: File[]) => {
    try {
      const reader = new FileReader();
      const isRenderFile = !!reader.readAsBinaryString;
      reader.onload = async (e) => {
        const bstr = e?.target?.result;
        const wb = XLSX.read(bstr, { type: isRenderFile ? 'binary' : 'array' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        const result = await convertJsonSong(data, files, variation as Variation);
        if (result) handleImportSong?.(result);
      };
      if (isRenderFile) reader.readAsBinaryString(files[0]);
      else reader.readAsArrayBuffer(files[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
    onDrop: handleImportFile,
  });

  const handleSaveSongInfo = (songInfo: Song) => {
    let dataSource = songs ? [...songs] : [];
    dataSource = dataSource.map((song) =>
      song.index === currentSong ? { ...songInfo, index: currentSong } : { ...song },
    );

    dispatch(setVariationSongs({ songs: dataSource, variationIndex: variationIndex }));
    if (
      variation?.includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE &&
      !isEmpty(titleForm.variations[variationIndex]?.songs && variation.price)
    ) {
      dispatch(setCaculateFee(true));
    }
  };

  const handleSaveSongVideoInfo = (songInfo: Song) => {
    let dataSource = songs ? [...songs] : [];
    dataSource = dataSource.map((song) =>
      song.index === currentSong ? { ...songInfo, index: currentSong } : { ...song },
    );

    dispatch(
      setVariationVideoSongs({
        songs: dataSource,
        variationIndex: variationIndex,
        videoIndex: indexVideo,
      }),
    );

    if (
      variation?.includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE &&
      !isEmpty(titleForm.variations[variationIndex]?.songs && variation.price)
    ) {
      dispatch(setCaculateFee(true));
    }
  };

  const columns = useMemo(
    () => [
      {
        title: '曲順',
        headerClass: 'w-15',
        key: 'index',
        dataIndex: 'stt',
        render: (value: string, item: Song) => (
          <div className="d-flex">
            <Row>
              <CaretUpFill size={10} onClick={() => handleMoveItemSong(item, TYPE_MOVE[0])} />
              <CaretDownFill size={10} onClick={() => handleMoveItemSong(item, TYPE_MOVE[1])} />
            </Row>
            <div>
              <p>{item.index + 1}</p>
            </div>
          </div>
        ),
      },
      {
        title: `${isVideo ? '動画内収録楽曲名' : '曲名'}`,
        key: 'name',
        dataIndex: 'name',
        render: (value: string) => (
          <div className="d-flex mw-25">
            <span className={`text-start text-truncate ${!value ? 'gray' : ''}`}>
              {value ? value : '【楽曲が登録されていません】'}
            </span>
          </div>
        ),
      },
      {
        title: <></>,
        key: 'sourceAudioUrl',
        dataIndex: 'sourceAudioUrl',
        render: (link: string, item: Song, index: number) => (
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center justify-content-end">
              {link && (
                <>
                  <audio controls className="me-3 h-35px" key={link}>
                    <track kind="captions" />
                    <source src={process.env.REACT_APP_STATIC_URL + link} type="audio/mpeg" />
                  </audio>
                  <span>{`${calcMb(item?.size || 0)}MB`}</span>
                </>
              )}
            </div>
            <div className="ms-2 mt-1 mw-300">
              {item?.fileName && <p className="text-truncate">{item?.fileName}</p>}
              {errors.length > 0 && !isVideo && (
                <MessageError
                  message={
                    !isEmpty(errors[index]) && Object.keys(errors[index]).includes('sourceAudioUrl')
                      ? errors[index]['sourceAudioUrl']?.message
                      : errors[index]?.message
                  }
                  classWrapper="ms-2 w-max-content"
                />
              )}
            </div>
          </div>
        ),
      },
      {
        title: '',
        key: 'action',
        dataIndex: 'item',
        render: (value: any, item: Song) => {
          return (
            <div className="">
              <Button
                variant="primary"
                className="px-4 pe-4 text-keep-all me-2"
                onClick={() => {
                  if (isEmpty(variation?.price)) {
                    openMessage({
                      variant: 'error',
                      message:
                        '販売価格を設定してください。販売価格が設定されていないため編集できません',
                    });
                    return;
                  }
                  setVisible(true);
                  setCurrentSong(item.index);
                }}
              >
                {isReview ? '詳細' : '編集'}
              </Button>
              <Button
                hidden={isAfterDownload(variation) && !isVideo}
                variant="secondary"
                onClick={() => handleDeteleSong(item)}
              >
                <Trash size="18" />
              </Button>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [songs, errors, isVideo],
  );

  const disableAddVideo = () => {
    //* if isVideo and songs.length = 1 and videoType is MusicVdieo only 1 song
    return (
      isVideo &&
      variation?.videos[indexVideo]?.videoType === VIDEO_TYPES.MUSIC_VIDEO &&
      !isEmpty(variation?.videos[indexVideo]?.songs)
    );
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-between py-1">
        <div className={`mt-3 ${isAfterDownload(variation) && !isVideo && 'd-none'}`}>
          <div className="d-flex flex-row">
            <Button className="h-35px" {...getRootProps()}>
              一括登録ファイルインポート
              <input {...getInputProps()} />
            </Button>

            <a
              href={
                isVideo
                  ? '/assets/miim_DL_動画_収録曲一括登録_import_video.xlsx'
                  : '/assets/miim_DL_曲一括登録_import_song.xlsx'
              }
              className="text-primary pt-2 ms-3"
              download={
                isVideo ? 'miim_DL_動画_収録曲一括登録_import' : 'miim_DL_曲一括登録_import.xlsx'
              }
            >
              <CloudDownload size={25} className="me-2" />
              一括登録テンプレートをダウンロード
            </a>
          </div>
          {isVideo && <MessageError message={errors[indexVideo]?.songs?.message} />}
        </div>

        <div className="d-flex flex-column align-items-end">
          <Button
            hidden={isAfterDownload(variation) && !isVideo}
            disabled={numberRemainSong === 0 || disableAddVideo()}
            className="px-4"
            onClick={() => {
              updateQuery('', () => {
                handleAddSong && handleAddSong();
              });
            }}
          >
            {isVideo ? '管理楽曲の登録・追加' : '楽曲を追加'}
          </Button>
          {isVideo && (
            <p className="mt-2">※「著作権管理楽曲」を含む動画は、曲情報を登録してください</p>
          )}
        </div>
      </div>
      {songs.length > 0 && (
        <Table
          tableKey="table-box-song"
          className="table-box-song"
          columns={columns}
          dataSource={songs}
          errors={errors}
          onClickError={onClickError}
        />
      )}

      <Modal
        show={visible}
        onCloseModal={() => setVisible(false)}
        submitIdForm={`Variation-${variationIndex}-song-${currentSong}`}
        dialogClassName="max-w-85"
        footerClassName="justify-content-end"
        // isReviewModal={isReview || isAfterDownload(variation)}
        title={
          isReview
            ? `${
                (songs && songs.find((item: Song) => item.index === currentSong)?.name) ??
                '【楽曲が登録されていません】'
              }の詳細`
            : `${titleName || ''}/${
                variation?.type === 0 ? DEFAULT_VARIATION_NAME : variationName
              }の${currentSong ? currentSong + 1 : '1'}曲⽬のデータを登録します。`
        }
      >
        <SongInformation
          currentSong={currentSong}
          isDiabled={isDiabledEditSong}
          id={`Variation-${variationIndex}-song-${currentSong}`}
          onCloseModal={() => setVisible(false)}
          isReview={(isReview || isAfterDownload(variation)) && !isVideo}
          submitSongInfo={handleSaveSongInfo}
          submitSongVideoInfo={handleSaveSongVideoInfo}
          songInfo={(songs && songs.find((item: Song) => item.index === currentSong)) || {}}
          isVideo={isVideo}
          variation={titleForm.variations[variationIndex]}
        />
      </Modal>
    </>
  );
};

export default SongTable;
