import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import DigitalPrivilege from 'Page/PromotionManager/DigitalPrivilege';
import SaleSchedule from 'Page/PromotionManager/SaleSchedule';
import Tabs from 'Components/Common/Tabs/Tabs';
import Filter from 'Components/Common/Filter/Filter';
import FilterPromotion from 'Page/PromotionManager/FilterPromotion';
import 'Page/PromotionManager/index.scss';
import { StringParam, useQueryParam } from 'use-query-params';

export default function PromotionManager(): JSX.Element {
  const btnGroupOptions = [{ name: 'デジタル特典登録' }, { name: '販売スケジュール' }];
  const [tab, setTab] = useState<string | null>(btnGroupOptions[0].name);
  const [status] = useQueryParam('status', StringParam);

  useEffect(() => {
    if (status) {
      setTab(status);
    }
  }, [status]);

  return (
    <>
      <Container fluid className={`${tab === btnGroupOptions[0].name && 'shadow-none'}`}>
        <Filter filterClass="shadow-sm">
          <Row>
            <Col className="d-flex align-items-center justify-content-between px-0">
              <Tabs heightBtn="43px" btnClass="py-2" options={btnGroupOptions} tabActive={tab} />
            </Col>
          </Row>
          {tab === btnGroupOptions[1].name ? <FilterPromotion /> : <></>}
        </Filter>
      </Container>
      <Container fluid>
        {tab === btnGroupOptions[1].name ? (
          <SaleSchedule />
        ) : (
          <DigitalPrivilege onChangeTabs={setTab} />
        )}
      </Container>
    </>
  );
}
