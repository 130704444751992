const audioTypes = [
  'audio/mpeg',
  'audio/wav',
  'audio/x-wav',
  'audio/vnd.wave',
  'audio/wave',
  'audio/flac',
  'audio/x-m4a',
];

const defaultValueState = {
  name: '',
  furiganaName: '',
  englishName: '',
  musicianName: '',
  musicianFuriganaName: null,
  musicianEnglishName: '',
  composerName: '',
  composerFuriganaName: null,
  composerEnglishName: '',
  arrangerName: '',
  arrangerFuriganaName: null,
  arrangerEnglishName: '',
  showTime: '',
  isrcCode: '',
  jasracCode: '',
  otherJasracCode: '',
  informationFee: '',
  lyricInformationFee: '',
};

const formListSongDefault = [
  {
    arrangerEnglishName: '',
    arrangerFuriganaName: '',
    arrangerName: '',
    authorizedGroup: '',
    authorizedGroupType: '',
    collectCode: '',
    composerEnglishName: '',
    composerFuriganaName: '',
    composerName: '',
    englishName: '',
    furiganaName: '',
    id: '',
    index: '',
    informationFee: '',
    isrcCode: '',
    ivtType: '',
    jasracCode: '',
    liveType: '',
    lyricsClassifyType: '',
    musicianEnglishName: '',
    musicianFuriganaName: '',
    musicianName: '',
    name: '',
    otherJasracCode: '',
    showTime: '',
    sourceAudioUrl: '',
    versionIdentificationType: '',
    fileName: '',
    lyricInformationFee: '',
    riajGenreId: null,
  },
];

const MusicFormat = {
  MP3: 0,
  WAV: 1,
};

const MaxLength = {
  musicianName: 302,
  musicianEnglishName: 302,
  musicianFuriganaName: 452,
  composerName: 302,
  composerFuriganaName: 452,
  composerEnglishName: 302,
  arrangerName: 302,
  arrangerFuriganaName: 452,
  arrangerEnglishName: 302,
};

export { audioTypes, defaultValueState, formListSongDefault, MusicFormat, MaxLength };
