import CheckBoxs from 'Components/Common/Form/GroupCheckBox/CheckBoxs';
import { distributionOptions } from 'Constant/Campaign';
import { DATE_FORMAT_FOR_DATE_PICKER } from 'Constant/Date';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import CapacityField from 'Components/Page/Campaign/OptionScreen/CapacityField';
import DigitalFields from 'Components/Page/Campaign/OptionScreen/DigitalFields';
import { CampaignApis } from 'Datasource/Campaign';
import { useAppSelector } from 'App/Store';
import { filter } from 'lodash';
import { countTimeMonthStartToEnd, dateToISOString, normalizeDate } from 'Utils/DateTime';
import { useParams } from 'react-router-dom';
import useCampaign from 'Hooks/useCampaign';
import MessageError from 'Components/Common/MessageError';
import BRFormGroupDate from 'Components/Common/Form/FormGroupDate/BRFormGroupDate';

const DigitalTokutenGift = ({
  handleAddDeleteItem,
  prizeDigitalTokutens,
}: {
  handleAddDeleteItem: (item: any) => void;
  prizeDigitalTokutens: any[];
}): JSX.Element => {
  const { courses, overview } = useAppSelector((state) => state.campaign);
  const { id }: { id: string } = useParams();
  const { handleDisableDataCampaign } = useCampaign();

  const [sizeInfo, setSizeInfo] = useState({
    used: 0,
    using: 0,
    notUsedYet: 0,
    totalCapacity: 0,
  });

  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const handleGetCapacityUsed = async () => {
    const variationIds = courses
      .map((course: any) => {
        const courseInfoIds = filter(course.courseInfos, (info) => info.enabled)
          ?.map((variation) => variation.variationId)
          ?.toString();
        return courseInfoIds;
      })
      ?.toString();

    const {
      data: { data },
    } = await CampaignApis.getDigitalConfig({
      vIds: variationIds,
      titleId: overview?.titleId,
    });
    const used = data?.campaigns.reduce((total: number, item: any) => {
      const usedSize = item.campaignId === +id ? 0 : Number(item.usedSize);
      return usedSize + total;
    }, 0);
    const totalCapacity = data?.limitStorage;
    const notUsedYet =
      overview?.dataSize + used > totalCapacity
        ? 0
        : data?.limitStorage - overview?.dataSize - used ?? 0;
    setSizeInfo({
      used: used ?? 0,
      using: overview?.dataSize ?? 0,
      notUsedYet,
      totalCapacity: totalCapacity ?? 0,
    });
  };

  useEffect(() => {
    handleGetCapacityUsed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const notUsedYet: number =
      Number(sizeInfo.totalCapacity) - Number(overview?.dataSize) - Number(sizeInfo.used);
    setSizeInfo({
      ...sizeInfo,
      notUsedYet: notUsedYet < 0 ? 0 : notUsedYet,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overview?.dataSize]);

  const downloadTimeResult = countTimeMonthStartToEnd(
    watch('type4.digitalTokutenDistributionDate'),
    6,
  );
  return (
    <Row className="digital-tokuten-gift">
      <hr className="my-3" />
      <Col className="mx-5">
        <Row className="mb-1 fs-12">
          <Col md="3" />
          <Col md="9">基本データ容量150MB（超過分は１MBにつきXXXにYYY円加算されます）</Col>
        </Row>
        <Row className="mb-3">
          <Col md="3">データ使用量</Col>
          <Col md="9">
            <CapacityField data={sizeInfo} />
          </Col>
        </Row>
        <BRFormGroupDate
          label="特典配布日時"
          labelMd="3"
          colMd="6"
          placeholder="日時　(yyyy/mm/dd hh:mm)"
          hintText={`※特典配布日の3日前までに特典を登録してください\n※配布日時から6ヶ月後に自動削除されます`}
          selected={normalizeDate(watch('type4.digitalTokutenDistributionDate'))}
          dateFormat={DATE_FORMAT_FOR_DATE_PICKER}
          onChange={(date: any) =>
            setValue('type4.digitalTokutenDistributionDate', dateToISOString(date))
          }
          errorMessage={errors.type4?.digitalTokutenDistributionDate?.message}
          minDate={new Date()}
          downloadTime={downloadTimeResult}
        />

        <CheckBoxs
          id="distribution-setting-type"
          labelMd="3"
          colMd="9"
          classBoxErr="w-100"
          checkboxClass="flex-nowrap"
          classOption="w-50"
          classForm="d-flex mb-3"
          labelForm="配布設定"
          options={distributionOptions}
          value={watch('type4.digitalTokutenPrizeType')}
          onChange={({ value }) => setValue('type4.digitalTokutenPrizeType', value)}
          errorMessage={errors.type4?.distributionSetting?.message}
          disabled={handleDisableDataCampaign(watch('type4.id'))}
        />
        <div>
          <DigitalFields
            dataSizeLimit={sizeInfo.totalCapacity}
            remainingCapacity={sizeInfo.notUsedYet}
            dataSizeUsed={sizeInfo.used}
            digitalTokutenPrizeType={watch('type4.digitalTokutenPrizeType')}
            handleAddDeleteItem={handleAddDeleteItem}
            prizeDigitalTokutens={prizeDigitalTokutens}
          />
        </div>
        <MessageError classWrapper="ms-3" message={errors.type4?.prizeDigitalTokutens?.message} />
      </Col>
    </Row>
  );
};
export default DigitalTokutenGift;
