import React, { useState, forwardRef, useImperativeHandle, useEffect, useCallback } from 'react';
import CardWrapper from 'Components/Common/Card/Card';
import { Button, Col, Row, Form } from 'react-bootstrap';
import TableAward from 'Components/Page/Campaign/RegisterCampaign/Step3/TableAward';
import BonusPopup from 'Components/Page/Campaign/BonusPopup';
import ActionButtons from 'Components/Page/Campaign/RegisterCampaign/Common/ActionButtons';
import { useAppSelector, useAppDispatch } from 'App/Store';
import {
  awardSettings,
  distributionSettingOptions,
  methodOptions,
  attachedBonus,
  CampaignStatus,
  initialCourse,
  CourseTypes,
  subTypeOptions,
} from 'Constant/Campaign';
import { find, isEmpty, some, toNumber } from 'lodash';
import useCampaign from 'Hooks/useCampaign';
import {
  updateStep2State,
  CampaignSteps,
  setErrorRealTime,
  setTriggerCaculateRate,
} from 'App/Features/Campaign';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { CourseCampaign, FormValues, Prize } from 'Types/Page/Campaign/RegisterCampaign';
import { useForm, useFieldArray } from 'react-hook-form';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import PieChart from 'Components/Page/Campaign/Charts/PieChart';
import { updateQuery } from 'Utils/Search';
import useMessage from 'Hooks/useMessage';
import { getMsgExceed100 } from 'Utils/Campaign';
import { getSum } from 'Utils/Numbers';
interface courseType {
  visible: boolean;
  name?: string | undefined;
  courseIndex?: number | undefined;
  prize?: any;
  type?: number | undefined;
  prizeOption?: number | undefined;
  subType?: number | undefined;
}

const StepSignUpRewards = forwardRef((props: any, ref: any): JSX.Element => {
  const { courses, step, overview, triggerCaculateRate, currentCourseIndex, errorRealTime } =
    useAppSelector((state) => state.campaign);
  const {
    control,
    register,
    getValues,
    setValue,
    watch,
    reset,
    setError,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      courses: [initialCourse],
    },
  });

  const { fields, update } = useFieldArray({
    control,
    name: 'courses',
  });

  const dispatch = useAppDispatch();
  const { handleChangeStep, saveOrUpdateDraft, isLoading, handleDisableDataCampaign } =
    useCampaign();
  const { openMessage } = useMessage();

  const [currentCourse, setCurrentCourse] = useState<courseType>({
    name: '',
    courseIndex: undefined,
    type: undefined,
    prizeOption: undefined,
    visible: false,
    prize: {},
    subType: undefined,
  });

  const handleShowBonusModal = ({
    visible,
    name,
    courseIndex,
    prize,
    type,
    prizeOption,
    subType,
  }: courseType) => {
    visible
      ? setCurrentCourse({ visible, courseIndex, name, prize, type, prizeOption, subType })
      : setCurrentCourse({
          visible: false,
          courseIndex: undefined,
          type: undefined,
          prizeOption: undefined,
          name: '',
          prize: {},
          subType: undefined,
        });
  };

  const checkingUnlimitDisabled = () => {
    const checking = some(
      courses[currentCourse?.courseIndex ?? 0].prizes,
      (prize) => !prize?.userQuantity,
    );
    return checking;
  };

  const handleAddCondition = () => {
    const tmpCourses = courses.map((course: CourseCampaign) => {
      const prizes = course.prizes.map((prize: any, prizeIndex: number) => {
        const conditionArr = [...Array(course.prizes.length)].map((n, i) => ({
          [`condition${i}`]: prize[`condition${i}`] ? prize[`condition${i}`] : 0,
        }));
        const conditionObj = conditionArr.reduce((map, item) => {
          return { ...map, ...item };
        }, {});

        return {
          ...prize,
          index: prizeIndex,
          abandoned: prize.abandoned ? prize.abandoned : 0,
          onlyOne: prize.onlyOne ? prize.onlyOne : 0,
          ...conditionObj,
        };
      });
      return { ...course, prizes };
    });
    dispatch(updateStep2State({ courses: tmpCourses }));
  };

  const handleNext = (back?: boolean) => {
    const courseValue = watch('courses');
    handleAddCondition();

    dispatch(
      updateStep2State({
        courses: courseValue,
      }),
    );
    handleChangeStep(back ? step - 1 : step + 1);
    if (errorRealTime?.exceed100) {
      openMessage({
        variant: 'error',
        message: getMsgExceed100(courseValue, errorRealTime?.courseName),
      });
      return;
    }
    if (errorRealTime?.max100) {
      openMessage({
        variant: 'error',
        message: getMsgExceed100(courseValue, errorRealTime?.courseName),
      });
      return;
    }
    if (errorRealTime?.prizeQuantityTooSmall) {
      openMessage({
        variant: 'error',
        message: getMsgExceed100(courseValue, errorRealTime?.courseName),
      });
      return;
    }
  };

  useImperativeHandle(ref, () => ({
    handleNextStepFromParent(back?: boolean) {
      handleNext(back);
    },
  }));

  const handleSaveDraft = () => {
    if (!props?.handleValidateDraft()) {
      handleChangeStep(CampaignSteps.Overview);
      props?.setBackValidate();
      return;
    }

    saveOrUpdateDraft({
      overview,
      courses,
      id: props?.campaignId,
      step: step,
    });
  };

  const handleDisabledAddPrize = (course: any) => {
    let inValid = false;
    if (overview?.status === CampaignStatus.Official) {
      inValid = true;
    }
    if (!overview?.isDeletable) {
      inValid = true;
    }
    if (
      course?.type === methodOptions[0].value &&
      course?.prizeOption === awardSettings[0].value &&
      course?.prizes.length === 1
    ) {
      inValid = true;
    }
    if (handleDisableDataCampaign(course?.id)) {
      inValid = true;
    }
    return inValid;
  };

  const renderSubType = (courseIndex: number) => {
    let subType = 3;
    if (watch(`courses.${courseIndex}.type`) === CourseTypes.REALTIME) {
      if (watch(`courses.${courseIndex}.subType`) === subTypeOptions[0].value) {
        subType = subTypeOptions[0].value;
      }

      if (watch(`courses.${courseIndex}.subType`) === subTypeOptions[1].value) {
        subType = subTypeOptions[1].value;
      }
    }

    return subType;
  };

  const handleChangePrizeQuantity = useCallback((number: number | string, courseIndex: number) => {
    if (!number) {
      const newCourses = [...watch('courses')].map((course: CourseCampaign, index: number) => {
        const tmpPrizes = [...watch(`courses.${courseIndex}.prizes`)].map((prize: Prize) => {
          return {
            ...prize,
            winRate: '',
          };
        });
        return {
          ...course,
          prizes: index === courseIndex ? tmpPrizes : course.prizes,
          slipRate: 100,
        };
      });

      dispatch(
        updateStep2State({
          courses: newCourses,
        }),
      );
      return;
    }

    if (number) {
      const prizeLimited = [...watch(`courses.${courseIndex}.prizes`)].filter(
        (item: Prize) => !!item.userQuantity,
      );

      const totalRateLimited = prizeLimited
        .reduce((total, currentValue: Prize) => {
          return total + (toNumber(currentValue.userQuantity) / toNumber(number)) * 100;
        }, 0)
        .toFixed(2);

      const totalPrizeLimited = prizeLimited.reduce((total, currentValue: Prize) => {
        return total + toNumber(currentValue.userQuantity);
      }, 0);
      const hasIncentive = [...watch(`courses.${courseIndex}.prizes`)].some(
        (prize: Prize) => !prize?.userQuantity && prize.isIncentive,
      );

      //* disabled save if prizeQuantity < total userQuantity or prizeQuantity = total userQuantity and has prize unlimted

      const exceed100 =
        (toNumber(number) < toNumber(totalPrizeLimited) ||
          (toNumber(number) === toNumber(totalPrizeLimited) && hasIncentive)) &&
        step === CampaignSteps.SignUpRewards
          ? true
          : false;
      const prizeQuantityTooSmall = toNumber(number) < toNumber(totalPrizeLimited) ? true : false;
      dispatch(
        setErrorRealTime({
          keys: ['exceed100', 'prizeQuantityTooSmall'],
          statuses: [exceed100, prizeQuantityTooSmall],
          courseName: watch(`courses.${courseIndex}.name`),
        }),
      );

      const tmpPrizes = [...watch(`courses.${courseIndex}.prizes`)].map((prize: Prize) => {
        let winRateLimited = 0;

        const sumQuantity = [...watch(`courses.${courseIndex}.prizes`)].reduce(
          (total: number, currentValue: Prize) => {
            const currentPrize = currentValue.userQuantity
              ? toNumber(currentValue.userQuantity)
              : 0;
            return total + currentPrize;
          },
          0,
        );
        if (prize?.userQuantity) {
          winRateLimited =
            sumQuantity > +number ||
            toNumber(((toNumber(prize.userQuantity) / toNumber(number)) * 100).toFixed(2)) < 0
              ? 0
              : toNumber(((toNumber(prize.userQuantity) / toNumber(number)) * 100).toFixed(2));
        } else {
          winRateLimited =
            100 - toNumber(totalRateLimited) > 0
              ? toNumber((100 - toNumber(totalRateLimited)).toFixed(2))
              : 0;
        }

        return {
          ...prize,
          winRate: winRateLimited,
          isIncentive:
            !prize.userQuantity &&
            toNumber(courses[courseIndex]?.subType) !== subTypeOptions[1].value
              ? true
              : prize.isIncentive,
        };
      });
      const newCourses = [...watch('courses')].map((course: CourseCampaign, index: number) => {
        let slipRate = 0;
        if (!course?.prizes?.length) {
          slipRate = 100;
        }

        if (100 - toNumber(totalRateLimited) > 0) {
          slipRate = toNumber((100 - +totalRateLimited).toFixed(2));
        } else {
          slipRate = 0;
        }

        if (hasIncentive) {
          slipRate = 0;
        }
        return {
          ...course,
          prizeQuantity: index === courseIndex ? toNumber(number) : course?.prizeQuantity,
          prizes: index === courseIndex ? tmpPrizes : course.prizes,
          slipRate: slipRate,
        };
      });

      dispatch(
        updateStep2State({
          courses: newCourses,
        }),
      );
    }
  }, []);

  const renderChartData = useCallback(
    (courseIndex: number): { data: { name: string; rate: number }[]; onlyLimited: boolean } => {
      const array = !isEmpty(fields) ? ([...fields] as any[]) : [];
      const winRates = [...array[courseIndex]?.prizes].map((prize: Prize) => ({
        name: prize?.name || '',
        rate: prize?.winRate && toNumber(prize?.winRate) !== 0 ? toNumber(prize?.winRate) : 0,
      }));
      const totalWinRate = [...array[courseIndex]?.prizes]?.reduce(
        (total: number, currentValue: Prize) => {
          const currentWinRate = currentValue.winRate ? toNumber(currentValue.winRate) : 0;
          return total + currentWinRate;
        },
        0,
      );

      const hasIncentive = [...array[courseIndex]?.prizes].some(
        (prize: Prize) => !prize?.userQuantity && prize.isIncentive,
      );

      const data = [
        ...winRates,
        ...[
          {
            name: '不当選',
            rate:
              toNumber(array[courseIndex]?.slipRate) > 0
                ? toNumber(array[courseIndex]?.slipRate)
                : 0,
          },
        ],
      ];

      const type1Disabled =
        toNumber(array[courseIndex]?.subType) === subTypeOptions[0].value && hasIncentive;

      const type2Disabled =
        toNumber(array[courseIndex]?.subType) === subTypeOptions[1].value && hasIncentive;
      if ((type1Disabled || type2Disabled) && totalWinRate > 0 && !array[courseIndex]?.slipRate) {
        data.pop();
      }
      return {
        data: data,
        onlyLimited:
          (type1Disabled || type2Disabled) && totalWinRate > 0 && !array[courseIndex]?.slipRate,
      };
    },
    [fields],
  );

  const handleErrorRealtime = () => {
    const hasMax100 = [...watch('courses')].some((course: CourseCampaign) => {
      const totalWinRate = course?.prizes.reduce((total, currentValue: Prize) => {
        return total + (currentValue?.winRate ? toNumber(currentValue?.winRate) : 0);
      }, 0);
      return (
        course?.type === 2 &&
        course?.subType !== 2 &&
        (totalWinRate > 100 || totalWinRate <= 0) &&
        !course?.prizeQuantity
      );
    });

    const courseError = [...watch('courses')].find(
      (course: CourseCampaign) =>
        course.type === CourseTypes.REALTIME &&
        course.subType !== subTypeOptions[2].value &&
        !course?.prizeQuantity &&
        (hasMax100 || getSum(course.prizes, 'winRate') > 100),
    );

    dispatch(
      setErrorRealTime({
        keys: ['max100'],
        statuses: [hasMax100 || courseError ? true : false],
        courseName: courseError?.name || '',
      }),
    );
  };

  useEffect(() => {
    const tmpCourses = isEmpty(courses) ? [initialCourse] : courses;

    const newCourses = tmpCourses.map((course: CourseCampaign, courseIndex: number) => {
      const tmpPrizes = course.prizes.map((prize: Prize) => {
        return {
          ...prize,
          isIncentive:
            !prize.userQuantity && +courses[courseIndex]?.subType !== subTypeOptions[1].value
              ? true
              : prize.isIncentive,
        };
      });
      return { ...course, tmpPrizes };
    });

    reset({
      courses: newCourses,
    });
  }, [reset, courses]);

  useEffect(() => {
    if (triggerCaculateRate && courses[currentCourseIndex]?.prizeQuantity !== null) {
      handleChangePrizeQuantity(courses[+currentCourseIndex]?.prizeQuantity, currentCourseIndex);

      dispatch(setTriggerCaculateRate({ status: false, courseIndex: 0 }));
    }
  }, [triggerCaculateRate]);

  useEffect(() => {
    handleErrorRealtime();
  }, [watch('courses')]);

  useEffect(() => {
    [...watch('courses')].forEach((course: CourseCampaign, courseIndex: number) => {
      const prizeLimited = [...watch(`courses.${courseIndex}.prizes`)].filter(
        (item: Prize) => !!item.userQuantity,
      );

      const totalPrizeLimited = prizeLimited.reduce((total, currentValue: Prize) => {
        return total + toNumber(currentValue.userQuantity);
      }, 0);
      if (course.prizeQuantity && toNumber(course.prizeQuantity) < toNumber(totalPrizeLimited)) {
        return setError(`courses.${courseIndex}.prizeQuantity`, {
          type: 'manual',
          message: '景品合計数が販売数上限を超えています。',
        });
      }
    });
  }, [watch('courses')]);

  return (
    <>
      <SpinnerComponent isLoading={isLoading} />
      <Form
        className="register-campaign-step-3"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {fields.map((course: any, courseIndex: number) => {
          const optionLabel =
            find(distributionSettingOptions, (option) => option.value === course.option)?.label ??
            '';
          const typeLabel =
            find(methodOptions, (option) => option.value === course.type)?.label ?? '';
          return (
            <CardWrapper
              title={course.name ?? '景品いろいろ！よくばりコース！'}
              key={courseIndex}
              subTitle={`キャンペーン名：${overview?.name}`}
              className="px-4"
            >
              <Row className="align-items-baseline mb-3">
                <div className="d-flex align-items-end col-md-5">
                  <div className="me-4 align-self-end">
                    <Button
                      className="btn-action w-160"
                      onClick={() =>
                        handleShowBonusModal({
                          visible: true,
                          courseIndex,
                          name: course?.name,
                          type: course?.type,
                          prizeOption: course?.prizeOption,
                          subType: course?.subType,
                        })
                      }
                      disabled={handleDisabledAddPrize(course)}
                    >
                      賞を登録する
                    </Button>
                  </div>
                  <div>
                    <div>抽選方法：{optionLabel}</div>
                    <div>応募方法： {typeLabel}</div>
                  </div>
                </div>
                <Col md="3">
                  {renderSubType(courseIndex) === subTypeOptions[0].value && (
                    <FormInputNumber
                      name={`prizeQuantity-${courseIndex}`}
                      label="販売数上限"
                      labelMd="5"
                      colMd="5"
                      classCol="position-relative prize-amount"
                      value={watch(`courses.${courseIndex}.prizeQuantity`)}
                      onChange={(number) => {
                        const tmpCourse = { ...course, prizeQuantity: number };
                        update(courseIndex, JSON.parse(JSON.stringify(tmpCourse)) as any);
                        updateQuery(number, () => {
                          handleChangePrizeQuantity(number, courseIndex);
                        });
                      }}
                      decimalScale={0}
                      isReview={handleDisableDataCampaign(courses[courseIndex]?.id)}
                      errorMessage={
                        errors?.courses && errors?.courses[courseIndex]?.prizeQuantity?.message
                      }
                      parentClass=""
                      allowNegative={false}
                      allowLeadingZeros={false}
                      minValue={1}
                      maxLength={9}
                    />
                  )}

                  {renderSubType(courseIndex) === subTypeOptions[1].value && (
                    <Row className="px-0">
                      <Col md="5">販売数上限</Col>
                      <Col md="5">上限なし</Col>
                    </Row>
                  )}
                </Col>
              </Row>
              <Row>
                <Col
                  md={
                    getValues(`courses.${courseIndex}.type`) === CourseTypes.REALTIME &&
                    getValues(`courses.${courseIndex}.subType`) !== subTypeOptions[2].value
                      ? 9
                      : 12
                  }
                >
                  <TableAward
                    handleShow={(prize: any) => {
                      handleShowBonusModal({
                        visible: true,
                        courseIndex,
                        prize,
                        name: course?.name,
                        type: course?.type,
                        prizeOption: course?.prizeOption,
                        subType: course?.subType,
                      });
                    }}
                    isDisabledItem={
                      course?.type === methodOptions[0].value &&
                      course?.receiveType === attachedBonus[1].value
                    }
                    courseIndex={courseIndex}
                    prizes={course.prizes}
                    {...{ control, register, setValue, getValues, watch }}
                  />
                </Col>
                {getValues(`courses.${courseIndex}.type`) === CourseTypes.REALTIME &&
                getValues(`courses.${courseIndex}.subType`) !== subTypeOptions[2].value ? (
                  <Col md="3">
                    <PieChart
                      dataChart={renderChartData(courseIndex).data}
                      onlyLimited={renderChartData(courseIndex).onlyLimited}
                    />
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </CardWrapper>
          );
        })}

        <ActionButtons
          handleBack={() => handleChangeStep(step - 1)}
          handleNext={handleNext}
          handleSaveDraft={handleSaveDraft}
          courses={watch('courses')}
        />

        {currentCourse.visible && (
          <BonusPopup
            course={currentCourse}
            unlimitDisabled={checkingUnlimitDisabled()}
            handleShow={() => {
              handleShowBonusModal({ visible: false });
            }}
          />
        )}
      </Form>
    </>
  );
});

export default StepSignUpRewards;
