import React from 'react';
import { COMPANY_REGISTER_FORM_RULES } from 'Constant/FormRules';
import {
  Button,
  Col,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { FormCheckType } from 'react-bootstrap/esm/FormCheck';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';

export default function CompanyRegister(): JSX.Element {
  const {
    register,
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({ mode: 'onSubmit' });

  const onSubmit = handleSubmit((values) => {
    if (isValid) {
      console.log(values);
    }
  });

  const checkField = (
    type: FormCheckType,
    name: string,
    value: string,
    field: any,
    defaultChecked?: boolean,
  ) => (
    <FormCheck
      type={type}
      name={name}
      label={value}
      value={value}
      defaultChecked={defaultChecked}
      onChange={(e) => field?.onChange(e.currentTarget.value)}
    />
  );

  const textField = (name: string, rules: any, options?: any) => (
    <>
      <FormControl className="rounded-0" {...register(name, rules)} {...options} />
      <small className="red-500">{errors[name] && errors[name].message}</small>
    </>
  );

  const numberField = (name: string, rules: any, max: number) => (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <NumberFormat
            onChange={(e) => field.onChange(e.currentTarget.value)}
            maxLength={max}
            className="form-control rounded-0"
          />
        )}
      />
      <small className="red-500">{errors[name] && errors[name].message}</small>
    </>
  );

  const inputFields = [
    {
      label: '法人名', // 1 company name
      required: true,
      render: textField('companyName', COMPANY_REGISTER_FORM_RULES.companyName),
    },
    {
      label: '代表者名', // 2 Representative name
      required: true,
      render: textField('rpsttName', COMPANY_REGISTER_FORM_RULES.rpsttName),
    },
    {
      label: '郵便番号', // 3 ZIP code
      required: true,
      render: numberField('zipCode', COMPANY_REGISTER_FORM_RULES.zipCode, 6),
    },
    {
      label: '住所1', // 4 address 1
      required: true,
      render: textField('address1', COMPANY_REGISTER_FORM_RULES.address1, {
        placeholder: '東京都港区南青山3-2-2',
      }),
    },
    {
      label: '住所2', // 5 address 2
      required: true,
      render: textField('address2', COMPANY_REGISTER_FORM_RULES.address2, {
        placeholder: '南青山MRビル5F',
      }),
    },
    {
      label: '電話番号', // 6 Phone number
      required: true,
      render: numberField('phoneNumber', COMPANY_REGISTER_FORM_RULES.phoneNumber, 10),
    },
    {
      label: '銀行名', // 7 Bank name
      required: true,
      render: textField('bankName', COMPANY_REGISTER_FORM_RULES.bankName),
    },
    {
      label: '支店番号', // 8 Bank branch number
      required: true,
      render: numberField('bankBranchNumber', COMPANY_REGISTER_FORM_RULES.bankBranchNumber, 4),
    },
    {
      label: '支店名', // 9 Bank branch name
      required: true,
      render: textField('bankBranchName', COMPANY_REGISTER_FORM_RULES.bankBranchName),
    },
    {
      label: '口座種別', // 10 Account type
      required: true,
      render: (
        <Controller
          name="accountType"
          control={control}
          defaultValue="普通"
          render={({ field }) => (
            <div className="d-flex">
              <InputGroup className="w-auto me-4">
                {checkField('radio', 'accountType', '普通', field, true)}
              </InputGroup>
              <InputGroup className="w-auto">
                {checkField('radio', 'accountType', '当座', field)}
              </InputGroup>
            </div>
          )}
        />
      ),
    },
    {
      label: '口座番号', // 11 Account number
      required: true,
      render: numberField('accountNumber', COMPANY_REGISTER_FORM_RULES.accountNumber, 7),
    },
    {
      label: '口座名義（カタカナ）', // 12 Account name
      required: true,
      render: textField('accountName', COMPANY_REGISTER_FORM_RULES.accountName),
    },
    {
      label: 'マスター担当者', // 13 Curator (readonly)
      render: <FormControl className="rounded-0" disabled readOnly />,
    },
    {
      label: 'マスター直通電話番号', // 14 Phone number (readonly)
      render: <FormControl className="rounded-0" disabled readOnly />,
    },
    {
      label: 'メールアドレス', // 15 Email (readonly)
      render: <FormControl className="rounded-0" disabled readOnly />,
    },
  ];

  return (
    <form className="pt-4 pb-4" onSubmit={onSubmit}>
      <Row className="justify-content-center">
        <Col xs="12" sm="12" md="8">
          <Row>
            {inputFields.map((item, index) => (
              <Col sm="12" key={index} className="mb-3">
                <FormGroup as={Row} className="w-100">
                  <FormLabel column sm="4">
                    {item.label}
                    {item.required && <sup className="red-500 required-star">(*)</sup>}
                  </FormLabel>
                  <Col sm="8">{item.render}</Col>
                </FormGroup>
              </Col>
            ))}
            <Col sm="12" className="mb-4 mt-5">
              <FormGroup as={Row} className="w-100">
                <p>※メールアドレスがログインIDとなります</p>
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup as={Row} className="w-100">
                <Col sm="8" className="offset-sm-4">
                  <Button className="w-100 rounded-0 mb-2 bg-dark border-0">プレビュー</Button>
                  <Button type="submit" className="w-100 rounded-0 bg-red-500 border-0">
                    保存
                  </Button>
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </form>
  );
}
