export const SONG_TYPE = {
  JASRAC: 'JASRAC',
  NEXTONE: 'NexTone',
  MANA_IT_YOURSELF: '自己管理',
  OTHER: 'その他',
};

export const DATA_REPORT_TYPE = {
  MUSIC: '楽曲',
  VIDEO: '動画',
  LYRIC: '歌詞',
};

export const btnGroupOptionsSong = [
  { name: SONG_TYPE.JASRAC, value: '1' },
  { name: SONG_TYPE.NEXTONE, value: '2' },
  { name: SONG_TYPE.MANA_IT_YOURSELF, value: '3' },
  { name: SONG_TYPE.OTHER, value: '4' },
];

export const btnGroupOptionsType = [
  { name: DATA_REPORT_TYPE.MUSIC, value: DATA_REPORT_TYPE.MUSIC },
  { name: DATA_REPORT_TYPE.VIDEO, value: DATA_REPORT_TYPE.VIDEO },
  { name: DATA_REPORT_TYPE.LYRIC, value: DATA_REPORT_TYPE.LYRIC },
];

export const convertValueSongType = (value: number) => {
  if (value === 1) {
    return SONG_TYPE.JASRAC;
  }
  if (value === 2) {
    return SONG_TYPE.NEXTONE;
  }
  if (value === 3) {
    return SONG_TYPE.MANA_IT_YOURSELF;
  }
  if (value === 4) {
    return SONG_TYPE.OTHER;
  }
};

export const convertValueMusicType = (value: string) => {
  if (value === DATA_REPORT_TYPE.MUSIC) {
    return DATA_REPORT_TYPE.MUSIC;
  }
  if (value === DATA_REPORT_TYPE.VIDEO) {
    return DATA_REPORT_TYPE.VIDEO;
  }
  if (value === DATA_REPORT_TYPE.LYRIC) {
    return DATA_REPORT_TYPE.LYRIC;
  }
};
