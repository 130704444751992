import { stringRequired, numberRequired } from 'Utils/Validation';
import * as yup from 'yup';

const schema = yup.object().shape({
  name: stringRequired({}).trim().nullable(),
  type: numberRequired({}).nullable(),
  userQuantity: yup
    .string()
    .when(['isLimited', 'type'], {
      is: (isLimited: boolean) => isLimited === true,
      then: yup.string().required('入力してください。').nullable().trim(),
    })
    .test({
      name: 'quantity-serial-number',
      message: 'シリアルコードマスタは発行数が足りません。',
      test: (value, { parent }) => {
        let valid = true;
        if (parent?.type === 2) {
          if (value && parent?.type3?.seriesNumberMasterTotal && parent?.type3?.productQuantity) {
            valid =
              Number(value) * Number(parent?.type3?.productQuantity) <=
              Number(parent?.type3?.seriesNumberMasterTotal);
          }
        }
        return valid;
      },
    })
    .nullable(),
  type1: yup.object().when('type', {
    is: 0,
    then: yup.object({
      eventName: stringRequired({}).trim().nullable(),
      placeName: stringRequired({}).trim().nullable(),
      description: stringRequired({}).trim().nullable(),
      startDate: stringRequired({}).trim().nullable(),
      endDate: stringRequired({}).trim().nullable(),
      openTime: stringRequired({}).trim().nullable(),
      startTime: stringRequired({}).trim().nullable(),
      seatPickerType: numberRequired({}).nullable(),
      seatRow: yup
        .string()
        .when('seatPickerType', {
          is: (seatPickerType: number) => /^(1|2)$/.test(`${seatPickerType}`),
          then: stringRequired({}).trim().nullable(),
        })
        .nullable(),
      seatNumber: yup
        .string()
        .when('seatPickerType', {
          is: (seatPickerType: number) => /^(1|2)$/.test(`${seatPickerType}`),
          then: stringRequired({}).trim().nullable(),
        })
        .nullable(),
      notificationDate: yup
        .string()
        .when('isSendNoti', {
          is: 1,
          then: stringRequired({}).trim().nullable(),
        })
        .nullable(),
    }),
  }),
  type2: yup.object().when('type', {
    is: 1,
    then: yup.object({
      eventName: stringRequired({}).trim().nullable(),
      placeName: stringRequired({}).trim().nullable(),
      description: stringRequired({}).trim().nullable(),
      startDate: stringRequired({}).trim().nullable(),
      endDate: stringRequired({}).trim().nullable(),
      startTime: stringRequired({}).trim().nullable(),
      productQuantity: stringRequired({}).trim().nullable(),
      notificationDate: yup
        .string()
        .when('isSendNoti', {
          is: 1,
          then: stringRequired({}).trim().nullable(),
        })
        .nullable(),
    }),
  }),
  type3: yup.object().when('type', {
    is: 2,
    then: yup.object({
      eventName: stringRequired({}).trim().nullable(),
      productQuantity: stringRequired({}).trim().nullable(),
      seriesNumberMasterId: stringRequired({}).trim().nullable(),
      url: stringRequired({}).trim().nullable(),
      startDate: stringRequired({}).trim().nullable(),
      endDate: stringRequired({}).trim().nullable(),
      description: stringRequired({}).trim().nullable(),
    }),
  }),
  type4: yup.object().when('type', {
    is: 3,
    then: yup.object({
      digitalTokutenDistributionDate: stringRequired({}).trim().nullable(),
      digitalTokutenPrizeType: numberRequired({}).nullable(),
      prizeDigitalTokutens: yup
        .array()
        .of(
          yup.object().shape({
            name: stringRequired({}).trim().nullable(),
          }),
        )
        .when('digitalTokutenPrizeType', {
          is: (digitalTokutenPrizeType: any) => {
            return Number(digitalTokutenPrizeType) === 1;
          },
          then: yup
            .array()
            .of(
              yup.object().shape({
                name: stringRequired({}).trim().nullable(),
                percentage: stringRequired({}).trim().nullable(),
              }),
            )
            .min(1, '入力してください。'),
        })
        .min(1, '入力してください。'),
    }),
  }),
  type5: yup.object().when('type', {
    is: 4,
    then: yup.object({
      eventName: stringRequired({}).trim().nullable(),
      imageUrl: stringRequired({}).nullable(),
    }),
  }),
});

export default schema;
