import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { sectionRequiredName } from 'Utils/Validation';
import { SectionItem } from 'Page/CompanyInfo/SessionManage';
import MessageError from 'Components/Common/MessageError';
import FormLabel from 'Components/Common/Form/FormLabel';

interface FormAddProps {
  onAddSection: (section: string) => void;
  listSection: SectionItem[];
}

const createSchema = (data: SectionItem[]) => {
  return yup.object().shape({
    section: sectionRequiredName({}, data),
  });
};

export default function FormAddSection({ onAddSection, listSection }: FormAddProps): JSX.Element {
  const [section, setSection] = useState('');

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createSchema(listSection)),
  });

  const onSubmit = (data: { section: string }) => {
    onAddSection(data.section);
    setSection('');
    reset();
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} id="section-add">
        <Row className="d-flex justify-content-center mb-2">
          <FormLabel colMd="9" label="新しいセクションを追加します" />
        </Row>
        <Row className="d-flex justify-content-center">
          <Col md={8}>
            <Form.Control
              {...register('section')}
              value={section}
              type="text"
              onChange={(e) => setSection(e.target.value)}
            />
            <MessageError message={errors.section?.message} />
          </Col>
          <Col md={1} className="d-flex justify-content-end">
            <Button className="btn h-max-content btn-focus-none" type="submit">
              追加
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
