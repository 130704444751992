export const PAGE_SIZE = 20;
export const PAGE_SIZE_ARTIST = 15;
export const PAGE_SIZE_APPROVAL = 10;
export const EMAIL_REGEX = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g;
export const NUMBER_ONLY_REGEX = /^\d+$/;
export const NUMBER_REGEX = /\d/;
export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
export const WHITESPACE = /^\S*$/;

export const TYPE_MEDIA = {
  VARIATIONS: 0,
  SONGS: 1,
  DIGITAL_TOKUTEN: 2,
  CAMPAIGN: 3,
};

export const TOKUTEN_TYPE_MEDIA = {
  IMAGE: 0,
  VIDEO: 1,
  AUDIO: 2,
};

export const PER_PAGE = 20;
export const PER_PAGE_EC = 50;
export const PER_PAGE_ARTIST = 15;
export const BIG_PER_PAGE = 10000;

export const ACCOUNT_STATUS_ACTIVE = 'ACTIVE';
export const ACCOUNT_STATUS_INACTIVE = 'INACTIVE';

export const TABLE_PRODUCTS_STATUS = ['variation', 'course', 'target-campaign', 'products-applied'];

export const COL_START_CLASS = 'text-truncate text-start mw-250';
export const COL_END_CLASS = 'text-truncate text-end mw-250';
export const COL_CENTER_CLASS = 'text-truncate text-center mw-250';

export const ONLY_TEXT_ALPHABET = /^[A-Za-z]+$/;

export const MAX_INPUT = 255;

export const ActionType = {
  VIEW: 0,
  CREATE: 1,
  EDIT: 2,
};

export const PREFIX_WEB_APP = '/staff';
