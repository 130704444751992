import CardContainer from 'Components/Common/CardContainer';
import React, { useEffect, useRef } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import { calcMb } from 'Utils/File';
import GroupTextProps from 'Components/Page/Title/FormTextGroup';
import { useAppSelector } from 'App/Store';
interface DigitalImageProps {
  data?: any;
  errors?: any;
  onClickError?: () => void;
}

export default function DigitalImage(props: DigitalImageProps): JSX.Element {
  const { data, errors = [], onClickError } = props;
  const { images } = data;
  const imagesRef = useRef<any>([]);
  const { triggerMedia } = useAppSelector((state) => state.title);

  useEffect(() => {
    if (triggerMedia === 'images' && errors.length > 0) {
      const newListError = errors
        .map((item: any, index: number) => ({ ...item, index: index }))
        .filter((item: any) => !!item);
      imagesRef?.current[newListError[0].index]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
  }, [errors, triggerMedia]);

  return (
    <CardContainer title="画像コンテンツ" className="mb-3 mt-4 p-0">
      {images?.length === 0 &&
        (data?.id ? (
          <p>登録なし</p>
        ) : (
          <p className="text-cherry d-flex align-items-center">【登録されていません】</p>
        ))}
      {images?.map((image: any, index: number) => {
        return (
          <div key={index} ref={(el) => (imagesRef.current[index] = el)}>
            <Row className="w-100">
              <Col md="3" className="ps-0">
                <Image src={handleConnectStaticEndPoint(image?.url)} className="rounded" />
              </Col>
              <Col md={9} className="mt-4">
                <Col md={12} className="mb-2">
                  <GroupTextProps
                    label="タイトル名:"
                    value={image?.name || ''}
                    errorContent={errors[index]?.name}
                    onClickError={onClickError}
                    mdLable={2}
                    mdValue={10}
                  />
                </Col>
                <Col md={12} className="mb-2">
                  <GroupTextProps
                    label="EC説明⽂:"
                    value={image?.introEc || ''}
                    errorContent={errors[index]?.introEc}
                    onClickError={onClickError}
                    mdLable={2}
                    mdValue={10}
                  />
                </Col>
                <Col md={7}>
                  <GroupTextProps label={`${calcMb(image?.size)}MB`} value={'  '} mdLable={8} />
                </Col>
              </Col>
              <hr className="my-4" />
            </Row>
          </div>
        );
      })}
    </CardContainer>
  );
}
