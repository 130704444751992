/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';
import { ActionType, PER_PAGE } from '../Constant';
interface ParamsArtist {
  localName: string;
  furiganaName: string;
  globalName: string;
  officialSite: string;
  image: File;
}

interface QueryParams {
  page?: number;
  perPage?: number;
  query?: string | number;
  type?: string;
  screen?: string;
  artistIds?: string;
  action?: number;
  companyId?: string;
}
interface QueryParamsAll {
  page?: number;
  perPage?: number;
  queryArtist?: string;
  type?: string;
  companyId?: string;
}

const formDataRegister = (params: ParamsArtist) => {
  const { furiganaName, globalName, image, localName, officialSite } = params;
  const dataArray = new FormData();
  dataArray.append('furiganaName', furiganaName);
  dataArray.append('globalName', globalName);
  dataArray.append('localName', localName);
  dataArray.append('officialSite', officialSite);

  if (image.size > 0) {
    dataArray.append('file', image);
  }
  return dataArray;
};

export const ArtistApi = {
  getAllSuggestionArtistNew: ({ page, perPage, queryArtist }: QueryParamsAll) => {
    const response = brAxios.get(
      `/suggestion/artists?page=${page ?? ''}&limit=${perPage ?? ''}&query=${queryArtist ?? ''}`,
    );
    return response;
  },
  getAllSuggestionArtist: ({ page, perPage, query, screen, action, companyId }: QueryParams) => {
    const response = brAxios.get(
      `/suggestion/artists?page=${page ?? ''}&limit=${perPage ?? ''}&query=${query ?? ''}&screen=${
        screen ?? ''
      }&action=${action || ActionType.VIEW} ${companyId ? `&companyId=${companyId}` : ''}`,
    );
    return response;
  },

  getAllRecordArtist: ({ page, perPage, query, artistIds, screen }: QueryParams) => {
    const response = brAxios.get(
      `/record-company/artists?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }&artistId=${artistIds ?? ''}&screen=${screen ?? ''}`,
    );
    return response;
  },
  getArtistById: (id: number | string) => {
    const response = brAxios.get(`/record-company/artists/${id}`);
    return response;
  },
  handlerRegisterArtist: (params: ParamsArtist) => {
    const formData = formDataRegister(params);
    const response = brAxios.postFormData('record-company/artists', formData);
    return response;
  },
  handlerEditArtist: (params: ParamsArtist, id: string) => {
    const { furiganaName, globalName, localName, officialSite, image } = params;
    const newParams = {
      furiganaName,
      globalName,
      localName,
      officialSite,
      image,
    };

    const formData = formDataRegister(newParams);
    const response = brAxios.putFormData(`record-company/artists/${id}`, formData);
    return response;
  },

  handlerDeleteArtist: (id: string) => brAxios.delete(`record-company/artists/${id}`),
};

export const TitleApi = {
  getAllTitles: ({ page, perPage, query }: QueryParams) => {
    const response = brAxios.get(
      `/record-company/titles?page=${page ?? 1}&limit=${perPage ?? PER_PAGE}&query=${query ?? ''}`,
    );
    return response;
  },
};
