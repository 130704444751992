import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  isSuccess: false,
  mailList: [],
};

export const mailSlice = createSlice({
  name: 'mailRecipientList',
  initialState,
  reducers: {
    updateMailList: (state, data: PayloadAction<{ mailList: any; isSuccess: boolean }>) => {
      const { mailList, isSuccess } = data.payload;
      state.mailList = mailList;
      state.isSuccess = isSuccess;
    },
  },
});

export const { updateMailList } = mailSlice.actions;
export default mailSlice.reducer;
