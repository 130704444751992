import React from 'react';
import { Row, Container } from 'react-bootstrap';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import { PAGE_SIZE } from 'Constant';
import TableCostManagement from 'Components/Page/InformationAccountant/TableCostManagement';
import TableCostMonth from 'Components/Page/InformationAccountant/TableCostMonth';
import TablechargeType from 'Components/Page/InformationAccountant/TableChargeType';
import { CostResponse } from 'Types/Page/CompanyInformation/CompanyInformation';
import { getCostRight } from 'Utils/RecordCompany';
import { AliSortType } from 'Components/Common/Table/AliTable';
import ECSwitch from 'Components/Page/EC/Form/ECSwitch';
import TableDailyReport from 'Components/Page/InformationAccountant/TableGroupDailyReport';
import TableCdDailyReport from 'Components/Page/InformationAccountant/TableCdDailyReport';
import TableCdTotalReport from 'Components/Page/InformationAccountant/TableCdTotalReport';

import { useAppDispatch } from 'App/Store';
import {
  setCostMonthlyFilters,
  setCostDetailFilters,
  setCostDailyFilters,
} from 'App/Features/Revenue';

import { useAppSelector } from 'App/Store';
interface ManagementTabProps {
  page?: number | null;
  costData?: CostResponse;
  option: number;
  activeColumns?: any;
  setActiveColumns?: any;
  onSort?: (sortingState: AliSortType[]) => void;
}

export default function CostManagement({
  page,
  costData,
  option,
  activeColumns,
  setActiveColumns,
  onSort,
}: ManagementTabProps): JSX.Element {
  const dispatch = useAppDispatch();

  const revenueFilters: any = useAppSelector((state) => {
    switch (option) {
      case 0:
        return state?.revenue?.filters?.cost?.monthly || {};
      case 1:
        return state?.revenue?.filters?.cost?.daily || {};
      case 2:
        return state?.revenue?.filters?.cost?.detail || {};
      default:
        return {};
    }
  });

  const handleChangeFilterOption = (isSwitch: boolean, field: string) => {
    const newRevenueFilters = { ...revenueFilters, [field]: isSwitch };

    if (option == 0) {
      dispatch(setCostMonthlyFilters({ filters: newRevenueFilters }));
    }
    if (option == 1) {
      dispatch(setCostDailyFilters({ filters: newRevenueFilters }));
    }

    if (option == 2) {
      dispatch(setCostDetailFilters({ filters: newRevenueFilters }));
    }
  };

  const costRight = getCostRight({
    costData,
    isDeafault: !costData?.items.length ? true : false,
    amountTotal: costData?.amountTotal,
    amountTaxFeeMoneyTotal: costData?.amountTaxFeeMoneyTotal,
  });

  // const data = [1];

  if (option == 3) {
    return (
      <Container fluid className="px-4">
        <Row className="d-flex align-items-start w-100">
          <div className="mt-4 pb-20px d-flex overflow-auto cost_table-dailyAccount">
            {costData?.items &&
            Array.isArray(costData?.items) &&
            costData?.items?.filter((item: any) => !!item.type).length > 0 ? (
              <>
                <TableDailyReport
                  onSort={onSort}
                  dataDays={costData?.items[0]?.days || []}
                  dataSource={
                    costData?.items?.filter(
                      (item: any) =>
                        !!item.type &&
                        item.type !== 'CDシリアル出力' &&
                        item.type !== 'CDシリアル読取り',
                    ) || []
                  }
                ></TableDailyReport>
                <TableCdDailyReport
                  dataSource={
                    costData?.items?.filter((item: any) => item.type === 'CDシリアル出力') || []
                  }
                  title={'CDシリアル出力'}
                ></TableCdDailyReport>
                <TableCdDailyReport
                  dataSource={
                    costData?.items?.filter((item: any) => item.type === 'CDシリアル読取り') || []
                  }
                  title={'CDシリアル読取り'}
                ></TableCdDailyReport>
                <TableCdTotalReport
                  dataSource={costData?.items?.filter((item: any) => !item.type) || []}
                  title={'原価総合計'}
                ></TableCdTotalReport>
              </>
            ) : (
              <div className="vh-100-final d-flex align-items-center justify-content-center w-100">
                該当のデータはありません
              </div>
            )}
          </div>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid className="px-4">
      <Row className="my-4 d-flex align-items-start w-100">
        {costData?.items.length ? (
          <>
            <div className="d-flex w-70">
              <TableCostMonth dataSource={costData.miimCostTotal} title="販売金額合計" />
              <TablechargeType
                dataSource={costRight}
                miimCostTotal={costData.miimCostTotal}
                title="販売数"
              />
            </div>
            <div className="w-auto d-flex">
              <div>
                <ECSwitch
                  id="productId"
                  checked={revenueFilters?.isViewProductId}
                  labelForm="商品No."
                  onChange={(isSwitch) => handleChangeFilterOption(isSwitch, 'isViewProductId')}
                />
                <ECSwitch
                  id="artistName"
                  checked={revenueFilters?.isViewArtistName}
                  labelForm="アーティスト"
                  onChange={(isSwitch) => handleChangeFilterOption(isSwitch, 'isViewArtistName')}
                />
                <ECSwitch
                  id="titleName"
                  checked={revenueFilters?.isViewTitleName}
                  labelForm="タイトル"
                  onChange={(isSwitch) => handleChangeFilterOption(isSwitch, 'isViewTitleName')}
                />
                <ECSwitch
                  id="variationName"
                  checked={revenueFilters?.isViewVariationName}
                  labelForm="バリエーション"
                  onChange={(isSwitch) => handleChangeFilterOption(isSwitch, 'isViewVariationName')}
                />
              </div>
              <div>
                <ECSwitch
                  id="variationProductCode"
                  checked={revenueFilters?.isViewVariationProductCode}
                  labelForm="品番"
                  onChange={(isSwitch) =>
                    handleChangeFilterOption(isSwitch, 'isViewVariationProductCode')
                  }
                />
                <ECSwitch
                  id="downloadDate"
                  checked={revenueFilters?.isViewDownloadDate}
                  labelForm="DL開始日"
                  onChange={(isSwitch) => handleChangeFilterOption(isSwitch, 'isViewDownloadDate')}
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </Row>

      <div className="px-2 mx-1">
        <PaginationComponent
          activePage={page || 1}
          total={costData?.meta.totalCount || 0}
          perPage={PAGE_SIZE}
        />
      </div>
      <TableCostManagement
        dataSource={costData?.items || []}
        option={option}
        onSort={onSort}
        activeColumns={activeColumns}
        setActiveColumns={setActiveColumns}
        filters={revenueFilters}
      />
    </Container>
  );
}
