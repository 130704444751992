import brAxios from 'Api/Interceptors';
// import { transformQuery } from 'Utils/Params';
// import { ActionType } from '../Constant';

interface EmailTemplate {
  type: string;
  subject: string;
  body: string;
}

interface EmailTemplateTest {
  subject: string;
  body: string;
  operators: string;
  isTest: boolean;
}

export const EmailManagementApi = {
  getEmailTemplate: (type: string) => brAxios.get(`/ec-site/mail-template/?type=${type}`),

  updateEmailTemplate: (params: EmailTemplate) => brAxios.put(`/ec-site/mail-template`, params),

  sendEmailTest: (params: EmailTemplateTest) =>
    brAxios.post('/ec-site/mail-template/send-mail-to-operator', params),
};
