import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';

import useMessage from 'Hooks/useMessage';
// import { RecordCompanyApi } from 'Datasource/RecordCompany';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { Cell } from 'react-table';
// import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import { SortMethod } from 'Components/Common/Table/table';
import { ECApis } from 'Datasource/EC';
import { Download, QuestionCircleFill } from 'react-bootstrap-icons';
import dayjs from 'dayjs';
import { DATE_FORMAT_8 } from 'Constant/Date';
import TableBT from 'Components/Common/Table/TableBT';
import { exportExcel } from 'Utils/File';
import useErrorBlob from 'Hooks/useErrorBlob';
import { useAppSelector } from 'App/Store';
import { formatFixedDecimalAndNumb, formatPrice } from 'Utils/Numbers';
import TooltipsWithTruncate from 'Components/Common/Tooltips/TooltipsWithTruncate';

interface IValue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export default function TableList({ year }: { year: string }): JSX.Element {
  const { openMessageError } = useMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [setPage] = useState<any>(1);
  const [listRecord, setListRecord] = useState<IValue>([]);
  const [setTotalItems] = useState<any>(0);
  const { openErrorBlob } = useErrorBlob();
  const { auth: auth } = useAppSelector((state) => state);

  const [query, setQuery] = useQueryParams({
    page: StringParam,
    sort: StringParam,
    order: StringParam,
  });

  const handleDownloadExcel = async (tranferId: string, paymentDate: string) => {
    const formatDate = dayjs(paymentDate).format('YYMMDD');
    setIsLoading(true);
    try {
      await exportExcel(
        ECApis.tranferDetailExportExcel(tranferId),
        `${formatDate}_miimEC取引明細_${auth.company?.replaceAll(' ', '')}`,
      );
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadPDF = async (tranferId: string, paymentDate: string) => {
    const formatDate = dayjs(paymentDate).format('YYMMDD');
    const fileName = `${formatDate}_miimEC取引明細_${auth.company?.replaceAll(' ', '')}`;
    setIsLoading(true);
    try {
      await exportExcel(ECApis.tranferDetailExportPDF(tranferId), fileName, 'pdf');
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenBillingPDF = async (statementUrl: string) => {
    window.open(statementUrl, '_blank');
  };

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'd-none',
        columns: [
          {
            Header: '入金ID',
            accessor: 'transferId',
            width: '300',
            disableSortBy: true,
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <Link to={{ pathname: `/ec/payment-advice-detail/${row.original.transferId}` }}>
                  <div
                    className="text-truncate text-start payment-advice-padding"
                    title={row.original.transferId}
                  >
                    {row.original?.transferId}
                  </div>
                </Link>
              );
            },
          },
          {
            Header: '支払日',
            accessor: 'paymentDate',
            width: '150',
            Cell: ({ row }: Cell<IValue>) => {
              const paymentDate = dayjs(row.original?.paymentDate).format(DATE_FORMAT_8);
              return <div className="text-truncate payment-advice-padding">{paymentDate}</div>;
            },
          },
          {
            Header: '支払額',
            accessor: 'transferAmount',
            width: '100',
            disableSortBy: true,
            Cell: ({ row }: Cell<IValue>) => {
              const totalPrice = row.original?.transferAmount + row.original?.carriedAmount;
              return (
                <div className="text-truncate text-right line-33px mr-6px">
                  {totalPrice < 3000
                    ? 0
                    : formatPrice(
                        formatFixedDecimalAndNumb(
                          row.original?.transferAmount + row.original?.carriedAmount,
                          2,
                        ),
                      )}
                </div>
              );
            },
          },
          {
            Header: '明細PDF',
            accessor: 'notificationPDF',
            width: '100',
            disableSortBy: true,
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div
                  className="text-truncate text-center payment-advice-padding"
                  title={row.original?.notificationPDF}
                >
                  <Link
                    to="#"
                    onClick={() =>
                      handleDownloadPDF(row.original?.transferId, row.original?.paymentDate)
                    }
                  >
                    <Download className="mr-4px"></Download>DL
                  </Link>
                </div>
              );
            },
          },
          {
            Header: '明細EXCEL',
            accessor: 'detailExcel',
            width: '100',
            disableSortBy: true,
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div
                  className="text-truncate text-center payment-advice-padding"
                  title={row.original?.detailExcel}
                >
                  <Link
                    to="#"
                    onClick={() =>
                      handleDownloadExcel(row.original?.transferId, row.original?.paymentDate)
                    }
                  >
                    <Download className="mr-4px"></Download>DL
                  </Link>
                </div>
              );
            },
          },
          {
            Header: (
              <div className="d-flex justify-content-center">
                <span>インボイスPDF</span>
                <div style={{ marginTop: '-2px', marginLeft: '5px' }}>
                  <TooltipsWithTruncate
                    tooltips={
                      <span>
                        出力ボタンをクリックすると、PAY.JPが提供する入金明細・インボイス画面に遷移します。
                        <br />
                        ブラウザの「印刷」→送信先「PDFに保存」にて保存してください。
                        <br />
                        ※保存時の名称はご利用の端末等によって異なる場合がございます。
                      </span>
                    }
                    classCustom="tooltipsWithTruncateBgGray"
                  >
                    <QuestionCircleFill />
                  </TooltipsWithTruncate>
                </div>
              </div>
            ),
            accessor: 'billingPDF',
            width: '320',
            disableSortBy: true,
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-truncate w-100 mx-auto d-flex justify-content-center align-items-center">
                  <Link
                    to="#"
                    className="text-truncate text-center payment-advice-padding d-flex"
                    onClick={() => handleOpenBillingPDF(row.original.statementUrl)}
                  >
                    <div>
                      <svg
                        style={{ marginRight: '5px', cursor: 'pointer' }}
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 32 32"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            fill="#0d6efd"
                            fillRule="nonzero"
                            d="M4,8 L4,28 L24,28 L24,8 L4,8 Z M4,6 L24,6 C25.1045695,6 26,6.8954305 26,8 L26,28 C26,29.1045695 25.1045695,30 24,30 L4,30 C2.8954305,30 2,29.1045695 2,28 L2,8 C2,6.8954305 2.8954305,6 4,6 Z"
                          />
                          <path
                            fill="#0d6efd"
                            fillRule="nonzero"
                            d="M8,6 L6,6 L6,4 C6,2.8954305 6.8954305,2 8,2 L28,2 C29.1045695,2 30,2.8954305 30,4 L30,24 C30,25.1045695 29.1045695,26 28,26 L26,26 L26,24 L28,24 L28,4 L8,4 L8,6 Z"
                          />
                        </g>
                      </svg>
                    </div>
                    取引明細
                  </Link>
                  <span>｜</span>
                  <Link
                    to="#"
                    className="text-truncate text-center payment-advice-padding d-flex"
                    onClick={() => handleOpenBillingPDF(row.original.statementUrlWithPlatformer)}
                  >
                    <div>
                      <svg
                        style={{ marginRight: '5px', cursor: 'pointer' }}
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 32 32"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            fill="#0d6efd"
                            fillRule="nonzero"
                            d="M4,8 L4,28 L24,28 L24,8 L4,8 Z M4,6 L24,6 C25.1045695,6 26,6.8954305 26,8 L26,28 C26,29.1045695 25.1045695,30 24,30 L4,30 C2.8954305,30 2,29.1045695 2,28 L2,8 C2,6.8954305 2.8954305,6 4,6 Z"
                          />
                          <path
                            fill="#0d6efd"
                            fillRule="nonzero"
                            d="M8,6 L6,6 L6,4 C6,2.8954305 6.8954305,2 8,2 L28,2 C29.1045695,2 30,2.8954305 30,4 L30,24 C30,25.1045695 29.1045695,26 28,26 L26,26 L26,24 L28,24 L28,4 L8,4 L8,6 Z"
                          />
                        </g>
                      </svg>
                    </div>
                    プラットフォーム利用料
                  </Link>
                </div>
              );
            },
          },
        ],
      },
    ],
    [],
  );

  useEffect(() => {
    getListRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.page, query.sort, query.order, year]);

  const getListRecord = async () => {
    setIsLoading(true);
    try {
      const formatYear = year.substring(0, 4);
      const res = await ECApis.getTranferList(formatYear, query?.order, query?.sort);
      const { data } = res.data;
      setListRecord(data);
      setTotalItems(data.meta.itemCount);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChangePage = async (pageChange: number) => {
    setPage(pageChange);
  };

  //* handle sort from server side
  const handleManualSort = useCallback(
    (sortBy: SortMethod[]) => {
      if (sortBy.length > 0) {
        const sortOption = sortBy[0];
        setQuery({ sort: sortOption.id, order: sortOption.desc ? 'DESC' : 'ASC' });
      } else {
        setQuery({ sort: undefined, order: undefined });
      }
    },
    [setQuery],
  );

  return (
    <div className="overflow-hidden mt-4">
      <Container fluid className="px-4 d-flex justify-content-center">
        <SpinnerComponent isLoading={isLoading} />
        {/* <Row>
          <Col md={12} className="d-flex justify-content-end p-0">
            <PaginationComponent
              activePage={page}
              total={totalItems}
              perPage={PAGE_SIZE}
              onClick={(pageChange) => onChangePage(pageChange)}
            />
          </Col>
        </Row> */}
        <TableBT
          heightBody="calc(100vh - 250px)"
          columns={columns}
          data={listRecord}
          getCellProps={() => ({
            className: 'p-0',
          })}
          manualSortBy
          onSort={handleManualSort}
          textNote="2024年5月以前のデータが必要な場合は、大変お手数ですがブートロック営業担当者までご連絡ください。"
        />
      </Container>
    </div>
  );
}
