/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import CardWrapper from 'Components/Common/Card/Card';
import TableWinningConditions from 'Components/Page/Campaign/RegisterCampaign/Step4/TableWinningConditions';
import FormGroupSearch from 'Components/Common/Form/FormGroupSearch';
import ActionButtons from 'Components/Page/Campaign/RegisterCampaign/Common/ActionButtons';
import { useAppSelector, useAppDispatch } from 'App/Store';
import {
  changeStep,
  deleteBlacklist,
  updateBlacklists,
  CampaignSteps,
} from 'App/Features/Campaign';
import { CampaignApis } from 'Datasource/Campaign';
import useCampaign from 'Hooks/useCampaign';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import TableUserResult from 'Components/Page/Campaign/RegisterCampaign/Step4/TableUserResult';
import { UserSearchCampaign } from 'Types/Page/Campaign';
import useMessage from 'Hooks/useMessage';
import { methodOptions } from 'Constant/Campaign';
import { updateQuery } from 'Utils/Search';
import { PER_PAGE } from 'Constant';
import { getMsgExceed100 } from 'Utils/Campaign';

const StepWinningConditions = forwardRef((props: any, ref: any): JSX.Element => {
  const { courses, step, overview, errorRealTime } = useAppSelector((state) => state.campaign);
  const dispatch = useAppDispatch();
  const previews: any[] = Array(courses.length).fill([]);
  const userSelectCourse = courses?.map((item: any, index: number) => ({
    id: undefined,
    name: '',
    index: index,
  }));
  const [userInfo, setUserInfo] =
    useState<Array<{ id: number | undefined; name: string; index: number }>>(userSelectCourse);

  const { openMessageError, openMessage } = useMessage();
  const inputRef = useRef([]) as any;
  const [previewList, setPreviewList] = useState<any[]>(previews);
  const [options, setOptions] = useState<any[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const { saveOrUpdateDraft, handleChangeStep, isLoading, handleDisableDataCampaign } =
    useCampaign();

  const handleNext = async (back?: boolean) => {
    dispatch(changeStep({ step: back ? step - 1 : step + 1 }));
  };

  useImperativeHandle(ref, () => ({
    handleNextStepFromParent(back?: boolean) {
      handleNext(back);
    },
  }));

  //* search users for blacklist
  const handleSearchUsers = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await CampaignApis.getUsers({
        page: page,
        perPage: PER_PAGE,
        query: encodeURIComponent(searchKey),
      });

      const result = data?.items?.map(
        (item: {
          id: number;
          name: string;
          address: string;
          userMiim: string;
          userName: string;
          userId: number;
          email: string;
          realName: string;
        }) => ({
          label: item.realName,
          value: item.id,
          email: item.email,
          address: item.address,
          userMiim: item.userId,
          userName: item.name,
          userId: item.id,
          realName: item.realName,
        }),
      );
      setOptions(page === 1 ? result : [...options, ...result]);
      setTotalPages(data?.meta?.totalPages);
    } catch (error) {
      openMessageError(error);
    }
  }, [page, searchKey]);

  const debouceRequest = useCallback(
    (value: string) =>
      updateQuery(value, () => {
        setSearchKey(value);
        setPage(1);
      }),
    [],
  );

  const saveDraft = () => {
    if (!props?.handleValidateDraft()) {
      handleChangeStep(CampaignSteps.Overview);
      props?.setBackValidate();
      return;
    }

    if (errorRealTime?.prizeQuantityTooSmall) {
      openMessage({
        variant: 'error',
        message: '景品合計数が販売数上限を超えています。',
      });
      return;
    }

    if (errorRealTime?.exceed100) {
      openMessage({
        variant: 'error',
        message: getMsgExceed100(courses, errorRealTime?.courseName),
      });
      return;
    }
    saveOrUpdateDraft({ courses, overview, id: props?.campaignId });
  };

  const handleSelectItem = (
    selectedItem: any,
    blackListUsers: UserSearchCampaign[],
    courseIndex: number,
  ) => {
    const tmpSelect = [...userInfo];
    let currentSelect = userInfo[courseIndex];
    currentSelect = {
      id: selectedItem.value,
      name: selectedItem.label,
      index: courseIndex,
    };
    tmpSelect[courseIndex] = currentSelect;
    setUserInfo(tmpSelect);

    const tmpPreview = [...previewList];
    const currentPreview = previewList[courseIndex];
    const checkList = [...currentPreview, ...blackListUsers].map((item) => item.userId);
    if (checkList.indexOf(selectedItem.userId) < 0) {
      const userList: UserSearchCampaign[] = [
        ...currentPreview,
        {
          userId: selectedItem.userId,
          userName: selectedItem.userName,
          userMiim: selectedItem.userMiim,
          email: selectedItem.email,
          address: selectedItem.address,
          realName: selectedItem.realName,
        },
      ];
      tmpPreview[courseIndex] = userList;
      setPreviewList(tmpPreview);
    }
  };

  const handleAddBlackList = (user: UserSearchCampaign, courseIndex: number) => {
    if (!user.userId) return;
    dispatch(
      updateBlacklists({
        userInfo: {
          userId: user.userId,
          userName: user.userName,
          email: user.email,
          userMiim: user.userMiim,
          address: user.address,
          realName: user.realName,
        },
        courseIndex,
      }),
    );
    const tmpPreview = [...previewList];
    const currentPreview = previewList[courseIndex];
    const newSearchResult = currentPreview.filter(
      (item: UserSearchCampaign) => item.userId !== user.userId,
    );
    tmpPreview[courseIndex] = newSearchResult;
    setPreviewList(tmpPreview);
  };

  const handleRemoveBlackList = (user: UserSearchCampaign, courseIndex: number) => {
    dispatch(
      deleteBlacklist({
        userId: user?.userId,
        courseIndex,
      }),
    );
  };

  const handleImportFile = async (
    e: React.ChangeEvent<HTMLInputElement>,
    blacklist: UserSearchCampaign[],
    courseIndex: number,
  ) => {
    const target = e.target as HTMLInputElement;
    const selectedFile: File = (target.files as FileList)[0];

    if (selectedFile.name.split('.').pop() !== 'csv') {
      openMessage({
        variant: 'error',
        message: `${selectedFile.name} ファイルのアップロードに失敗しました。`,
      });
      inputRef.current[courseIndex].value = '';
      return;
    }

    try {
      const formData: FormData = new FormData();
      formData.append('file', selectedFile);
      const {
        data: { data },
      } = await CampaignApis.getCSV(formData);
      let csvData = data || [];
      const tmpPreview = [...previewList];
      const currentPreview = previewList[courseIndex];
      const checkList = [...currentPreview, ...blacklist].map((item) => item.userId);
      csvData = csvData.filter((item: UserSearchCampaign) => checkList.indexOf(item.userId) < 0);
      tmpPreview[courseIndex] = [...currentPreview, ...csvData];
      if (csvData.length) {
        setPreviewList(tmpPreview);
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      inputRef.current[courseIndex].value = '';
    }
  };

  useEffect(() => {
    handleSearchUsers();
  }, [searchKey, page]);

  const handleLoadmore = () => {
    if (page < totalPages) {
      let p = page;
      p += 1;
      setPage(p);
    }
  };

  return (
    <>
      <SpinnerComponent isLoading={isLoading} />
      <div className="register-campaign-step-4">
        {courses.map((course: any, courseIndex: number) => {
          return (
            <CardWrapper title={course?.name} className={`mb-3 `} key={courseIndex}>
              {course?.type === methodOptions[0].value ? (
                <Row className="text-center py-5">
                  <div>抽選方法：「全員配布」の場合は当選条件を設定しません。</div>
                </Row>
              ) : (
                <>
                  <Row>
                    <TableWinningConditions
                      prizes={course.prizes}
                      courseIndex={courseIndex}
                      isReview={handleDisableDataCampaign(course?.id)}
                    />
                  </Row>
                  <Row>
                    <CardWrapper
                      title="ブラックリスト"
                      className={`px-0 card-wrapper-gray ${
                        handleDisableDataCampaign(course?.id) ? 'pe-none' : ''
                      }`}
                    >
                      <Row className="mb-3">
                        <Col md="9">
                          <div className="d-flex align-items-center mb-3">
                            <FormGroupSearch
                              colClass="px-0"
                              rowClass="flex-grow-1 me-3"
                              labelMd="3"
                              colMd="9"
                              label="ユーザー検索"
                              inputSearchString={
                                userInfo[courseIndex]?.name &&
                                userInfo[courseIndex].index === courseIndex
                                  ? userInfo[courseIndex]?.name
                                  : ''
                              }
                              onSelect={(selectedItem) =>
                                handleSelectItem(
                                  selectedItem,
                                  course?.blackListUsers || [],
                                  courseIndex,
                                )
                              }
                              placeholder="ユーザーID　本名　メールアドレス"
                              onMenuFocus={() => handleSearchUsers()}
                              options={options}
                              onMenuClose={() => {
                                //* clear options when close menu
                                setSearchKey('');
                                setOptions([]);
                                setPage(1);
                                setTotalPages(0);
                              }}
                              onInputChange={(keyword: string) => debouceRequest(keyword)}
                              onLoadMore={() => handleLoadmore()}
                            />
                            <p>リストに追加されたユーザーを抽選から排除します。</p>
                          </div>
                          <Row>
                            <CardWrapper
                              title="検索結果"
                              className={`card-wrapper-gray card-blacklist ${
                                handleDisableDataCampaign(course?.id) ? 'pe-none' : ''
                              }`}
                            >
                              <TableUserResult
                                dataSource={previewList[courseIndex]}
                                onAdd={(item) => handleAddBlackList(item, courseIndex)}
                              />
                            </CardWrapper>
                            <CardWrapper
                              title="ブラックリスト"
                              className="card-wrapper-gray card-blacklist"
                            >
                              <TableUserResult
                                dataSource={course?.blackListUsers || []}
                                isBlackList={true}
                                onDelete={(item) => handleRemoveBlackList(item, courseIndex)}
                              />
                            </CardWrapper>
                          </Row>
                        </Col>
                        <Col md="3" className="d-flex flex-column align-items-end">
                          <Button
                            onClick={() => {
                              inputRef.current && inputRef.current[courseIndex].click();
                            }}
                          >
                            CSV読み込み
                          </Button>
                          <input
                            ref={(el) => (inputRef.current[courseIndex] = el)}
                            className="hidden-file"
                            type="file"
                            onChange={(e) =>
                              handleImportFile(e, course?.blackListUsers || [], courseIndex)
                            }
                            accept=".csv"
                          />
                        </Col>
                      </Row>
                    </CardWrapper>
                  </Row>
                </>
              )}
            </CardWrapper>
          );
        })}
        <ActionButtons
          handleBack={() => handleChangeStep(step - 1)}
          handleNext={handleNext}
          handleSaveDraft={saveDraft}
          courses={courses}
        />
      </div>
    </>
  );
});

export default StepWinningConditions;
