import React, { useEffect, useState } from 'react';
import { Form, Modal as FormModal, Button, Row, Col } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import Modal from 'Components/Common/Modal';
import AdmissionTicket from 'Components/Page/Campaign/OptionScreen/AdmissionTicket';
import BonusTicket from 'Components/Page/Campaign/OptionScreen/BonusTicket';
import SerialNumberGift from 'Components/Page/Campaign/OptionScreen/SerialNumberGift';
import DigitalTokutenGift from 'Components/Page/Campaign/OptionScreen/DigitalTokutenGift';
import ArtifactsGift from 'Components/Page/Campaign/OptionScreen/ArtifactsGift';
import NumberFormat from 'react-number-format';
import CheckBoxs from 'Components/Common/Form/GroupCheckBox/CheckBoxs';
import {
  awardSettings,
  bonusPopupInitialState,
  campaignOptionsType,
  methodOptions,
  NOTE_CAMPAIGN_TYPE,
  CampaignStatus,
  subTypeOptions,
} from 'Constant/Campaign';
import {
  updatePrizes,
  deletePrize,
  setDataSize,
  sortPrizes,
  updateStep1State,
  setTriggerCaculateRate,
} from 'App/Features/Campaign';
import { useAppDispatch, useAppSelector } from 'App/Store';
import MessageError from 'Components/Common/MessageError';
import schema from 'Components/Page/Campaign/RegisterCampaign/Schemas/RegisterSchema';
import { omit, reduce, find, isEmpty, differenceBy, toNumber } from 'lodash';
import useCampaign from 'Hooks/useCampaign';
import ModalComponent from 'Components/Common/Modal';
import dayjs from 'dayjs';
import { DATE_FORMAT_7 } from 'Constant/Date';
import { Prize } from 'Types/Page/Campaign/RegisterCampaign';
interface BonusPopupProps {
  isDiabled?: boolean;
  unlimitDisabled?: boolean;
  course: any;
  onCloseModal?: any;
  handleShow?: () => void;
}

const BonusPopup = ({
  isDiabled,
  unlimitDisabled,
  onCloseModal,
  handleShow,
  course,
}: BonusPopupProps): JSX.Element => {
  const { overview, courses } = useAppSelector((state) => state.campaign);
  const dispatch = useAppDispatch();
  const { handleDisableDataCampaign } = useCampaign();
  const [visible, setVisible] = useState<boolean>(false);
  const [mediaDeleted, setMediaDeleted] = useState<any>([]);

  const methods = useForm<any>({
    defaultValues: bonusPopupInitialState,
    resolver: yupResolver(schema),
  });

  const handleAddCondition = (prize: any) => {
    const conditionArr = [...Array(courses[course.courseIndex]?.prizes.length + 1)].map((n, i) => ({
      [`condition${i}`]: prize[`condition${i}`] ? prize[`condition${i}`] : 0,
    }));
    const conditionObj = conditionArr.reduce((map, item) => {
      return { ...map, ...item };
    }, {});

    return {
      ...prize,
      ...conditionObj,
    };
  };

  const onSubmit = async (values: any) => {
    const { type, name, userQuantity } = values;

    const tmpPrize = {
      prize: {} as any,
      courseIndex: course.courseIndex,
    };
    if (type === 0) {
      const { type1 } = values;
      tmpPrize.prize = { ...type1, name, type, userQuantity };
    }
    if (type === 1) {
      const { type2 } = values;
      tmpPrize.prize = { ...type2, name, type, userQuantity };
    }
    if (type === 2) {
      const { type3 } = values;
      tmpPrize.prize = { ...type3, name, type, userQuantity };
    }
    if (type === 3) {
      const { type4 } = values;
      const prizeDigitalTokutens = type4.prizeDigitalTokutens.map((dGT: any, idx: number) => {
        return {
          ...dGT,
          id: dGT.dgId || dGT.id || type4.prizeDigitalTokutens[idx]?.id,
          label: 1,
        };
      });
      tmpPrize.prize = { ...type4, prizeDigitalTokutens, name, type, userQuantity };

      if (course?.prize?.prizeDigitalTokutens) {
        const diffFromPrizeDigitalTokutensUrl = [
          ...differenceBy(course?.prize?.prizeDigitalTokutens, prizeDigitalTokutens, 'url'),
          ...differenceBy(prizeDigitalTokutens, course?.prize?.prizeDigitalTokutens, 'url'),
        ];

        if (
          Array.isArray(diffFromPrizeDigitalTokutensUrl) &&
          diffFromPrizeDigitalTokutensUrl.length > 0
        ) {
          if (
            type4.digitalTokutenDistributionDate &&
            dayjs(type4.digitalTokutenDistributionDate).valueOf() < dayjs().valueOf()
          ) {
            methods.setError('type4.digitalTokutenDistributionDate', {
              type: 'manual',
              message: '過去の日付のため登録できません。',
            });
            return;
          }
        }
      }
    }
    if (type === 4) {
      const { type5 } = values;
      tmpPrize.prize = { ...type5, name, type, userQuantity };
    }

    if (type !== 3) {
      const { type4 } = values;
      if (type4?.prizeDigitalTokutens && type4?.prizeDigitalTokutens.length > 0) {
        const totalUsed = type4?.prizeDigitalTokutens?.reduce((total: number, item: any) => {
          return +item.size + total;
        }, 0);

        const sizeRemaning = +overview.dataSize - +totalUsed;
        dispatch(updateStep1State({ overview: { ...overview, dataSize: sizeRemaning } }));
      }
    }
    const index = course?.prize?.index;
    if (index !== undefined) {
      tmpPrize.prize = { ...tmpPrize.prize, index };
    }
    if (!userQuantity) {
      if (course?.prize !== undefined) {
        const conditionArr = Object.keys(course?.prize)
          .filter((obj) => obj.includes('condition'))
          .map((n, i) => ({
            [`condition${i}`]: 0,
          }));
        const conditionObj = conditionArr.reduce((map, item) => {
          return { ...map, ...item };
        }, {});
        tmpPrize.prize = { ...tmpPrize.prize, abandoned: 0, onlyOne: 0, ...conditionObj };
      }
    }
    if (!isEmpty(tmpPrize.prize)) {
      const sumQuantity = [...courses[course.courseIndex]?.prizes].reduce(
        (total: number, currentValue: Prize, currentIndex: number) => {
          const currentPrize =
            currentValue.userQuantity && currentIndex !== index ? +currentValue.userQuantity : 0;
          return total + currentPrize;
        },
        0,
      );

      let isIncentive = false;
      if (watch('userQuantity') !== null) {
        isIncentive = false;
      }

      if (
        !watch('userQuantity') &&
        +courses[course.courseIndex]?.subType !== subTypeOptions[1].value
      ) {
        isIncentive = true;
      }

      if (tmpPrize?.prize?.isIncentive && !watch('userQuantity')) {
        isIncentive = true;
      }
      let currentWinRate = 0;
      if (course?.subType === subTypeOptions[0].value) {
        currentWinRate =
          sumQuantity + +watch('userQuantity') > +courses[course.courseIndex].prizeQuantity
            ? 0
            : tmpPrize?.prize?.winRate;
      }

      if (course?.subType === subTypeOptions[1].value) {
        currentWinRate = courses[course.courseIndex]?.prizes[tmpPrize?.prize?.index]?.winRate;
      }

      if (course?.subType === subTypeOptions[2].value) {
        currentWinRate = 0;
      }

      tmpPrize.prize = {
        ...tmpPrize.prize,
        startDate: tmpPrize?.prize?.startDate
          ? dayjs(tmpPrize?.prize?.startDate).startOf('day').format(DATE_FORMAT_7)
          : null,
        endDate: tmpPrize?.prize?.endDate ? dayjs(tmpPrize?.prize?.endDate).toISOString() : null,
        openTime: tmpPrize?.prize?.openTime ? dayjs(tmpPrize?.prize?.openTime).toISOString() : null,
        startTime: tmpPrize?.prize?.startTime
          ? dayjs(tmpPrize?.prize?.startTime).toISOString()
          : null,
        notificationDate: tmpPrize?.prize?.notificationDate
          ? dayjs(tmpPrize?.prize?.notificationDate).toISOString()
          : null,
        winRate: currentWinRate,
        isIncentive: isIncentive,
      };
    }

    tmpPrize.prize = handleAddCondition(tmpPrize.prize);

    dispatch(updatePrizes(tmpPrize));
    dispatch(sortPrizes({ courseIndex: course.courseIndex }));
    if (
      courses[course.courseIndex]?.subType === subTypeOptions[0].value &&
      courses[course.courseIndex]?.prizeQuantity
    ) {
      dispatch(setTriggerCaculateRate({ status: true, courseIndex: course.courseIndex }));
    }

    handleShow && handleShow();
  };

  const isLimitDisabled = (course: any) => {
    return (
      course?.type === methodOptions[0].value && course?.prizeOption === awardSettings[1].value
    );
  };

  const hasPrizeUnlimited = () => {
    const prizes = courses[course?.courseIndex]?.prizes || [];
    const arrPrizeUnlimited = prizes.filter((item: any) => !item.userQuantity) || [];
    return arrPrizeUnlimited.length > 0;
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    const { prize } = course;
    let tmpPrize: any = {
      name: prize?.name,
      type: prize?.type ?? 0,
      userQuantity: prize?.userQuantity,
      isLimited: unlimitDisabled && prize?.index === undefined ? true : !!prize?.userQuantity,
    };
    if (course?.type === methodOptions[0].value && course?.prizeOption === awardSettings[1].value) {
      tmpPrize.isLimited = false;
    }
    const typeValue = omit(prize, ['type', 'name', 'userQuantity']);
    if (tmpPrize?.type === 0 || tmpPrize?.type === 1) {
      typeValue.isSendNoti = typeValue?.notificationDate ? 1 : 0;
      tmpPrize = {
        ...bonusPopupInitialState,
        ...tmpPrize,
        type1: { ...bonusPopupInitialState?.type1, ...typeValue },
      };
    }
    if (tmpPrize?.type === 1) {
      tmpPrize = { ...bonusPopupInitialState, ...tmpPrize, type2: typeValue };
    }
    if (tmpPrize?.type === 2) {
      tmpPrize = { ...bonusPopupInitialState, ...tmpPrize, type3: typeValue };
    }
    if (tmpPrize?.type === 3) {
      tmpPrize = { ...bonusPopupInitialState, ...tmpPrize, type4: typeValue };
    }
    if (tmpPrize?.type === 4) {
      tmpPrize = { ...bonusPopupInitialState, ...tmpPrize, type5: typeValue };
    }
    reset(tmpPrize);
  }, [reset, course, unlimitDisabled]);

  const handleCancel = () => {
    if (watch('type') === 3 && course?.prize?.index === undefined) {
      const prizeSize: number = reduce(
        watch('type4.prizeDigitalTokutens'),
        (sum, field) => {
          return sum + Number(field?.size ?? 0);
        },
        0,
      );
      const dataSize = overview?.dataSize - prizeSize;
      dispatch(setDataSize({ dataSize }));
    }

    if (watch('type') === 3) {
      let dataSize = overview?.dataSize;
      //* DELETE PRIZE MEDIA ITEM
      if (!isEmpty(mediaDeleted)) {
        const prizeSize: number = reduce(
          mediaDeleted,
          (sum, field) => {
            if (field?.label !== 0) {
              sum = sum + Number(field?.size ?? 0);
            }
            return sum;
          },
          0,
        );
        dataSize = dataSize + prizeSize;
      }

      //* CLEAR NEW PRIZE MEDIA ITEM
      const prizeSize1: number = reduce(
        watch('type4.prizeDigitalTokutens'),
        (sum, field) => {
          if (field?.label === 0) {
            sum = sum + Number(field?.size ?? 0);
          }
          return sum;
        },
        0,
      );
      dataSize = dataSize - prizeSize1;
      dispatch(setDataSize({ dataSize }));
    }

    handleShow && handleShow();
    setMediaDeleted([]);
  };

  const handleAddDeleteItem = (item: any) => {
    setMediaDeleted([...mediaDeleted, item]);
  };

  const handleDisableDelete = () => {
    let inValid = false;
    if (handleDisableDataCampaign(course?.prize?.id)) {
      inValid = true;
    }
    if (overview?.status === CampaignStatus.Official) {
      inValid = true;
    }
    return inValid;
  };

  useEffect(() => {
    const disableSeat1 =
      watch('type1.seatPickerType') === 1 && course?.type !== methodOptions[0].value;
    const disableSeat2 = watch('type1.seatPickerType') === 2 && !watch('isLimited');
    if (disableSeat1 || disableSeat2) {
      setValue('type1.seatPickerType', 0);
      setValue('type1.seatRow', null);
      setValue('type1.seatNumber', null);
    }
  }, [course?.prize?.id, course.type, watch('type1.seatPickerType'), watch('isLimited')]);

  return (
    <>
      <Modal
        show={course?.visible}
        onCloseModal={onCloseModal}
        dialogClassName="modal-award"
        footerClassName="d-none"
        title={`「${course?.name}」の賞を設定します`}
        showCancelButton={false}
        isReviewModal
        size="xl"
      >
        <FormProvider {...methods}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            className={isDiabled ? 'pe-none user-select-none' : 'pe-auto user-select-auto'}
          >
            <FormGroupInput
              label="賞の名称"
              labelMd="2"
              colMd="10"
              value={watch('name')}
              register={register('name')}
              onChange={(value) => setValue('name', value)}
              classForm="form-with-label-nowrap"
              errorMessage={errors.name?.message}
            />
            <CheckBoxs
              id="version-indent"
              labelForm="景品種別"
              name="versionIndent"
              labelMd="2"
              colMd="10"
              classForm="form-with-label-nowrap mb-3"
              errorMessage={errors.type?.message}
              options={campaignOptionsType}
              value={watch(`type`)}
              onChange={({ value }) => setValue('type', value)}
              hintText={NOTE_CAMPAIGN_TYPE[watch('type')]}
              classOption="pe-4"
              disabled={handleDisableDataCampaign(course?.prize?.id)}
            />
            <Row>
              <Col md="2">当選数上限</Col>
              <Col md="10">
                <div className="d-flex align-items-center">
                  <Form.Check
                    className="w-10 check-box-ct mb-0"
                    type="radio"
                    inline
                    name="limited"
                    id="limited"
                    label="上限あり"
                    disabled={
                      handleDisableDataCampaign(course?.prize?.id) || isLimitDisabled(course)
                    }
                    checked={watch('isLimited')}
                    onChange={() => setValue('isLimited', !watch('isLimited'))}
                  />
                  <div className="d-flex align-items-center w-10 me-3">
                    <NumberFormat
                      className="form-control rounded position-relative me-2"
                      value={watch('userQuantity') ? watch('userQuantity') : ''}
                      disabled={handleDisableDataCampaign(course?.prize?.id) || !watch('isLimited')}
                      onValueChange={({ value }) => {
                        if (+value > 0) {
                          setValue('userQuantity', +value);
                        } else {
                          setValue('userQuantity', '');
                        }
                      }}
                    />
                    点
                  </div>
                  <Form.Check
                    className="w-25 check-box-ct mb-0"
                    type="radio"
                    inline
                    name="unlimited"
                    id="unlimited"
                    label="上限なし"
                    checked={!watch('isLimited')}
                    disabled={
                      courses[course.courseIndex]?.subType === 1 &&
                      courses[course.courseIndex].type === 2
                        ? false
                        : handleDisableDataCampaign(course?.prize?.id) ||
                          (course?.prize?.index !== undefined
                            ? false
                            : !isLimitDisabled(course) && unlimitDisabled) ||
                          (watch('userQuantity') && hasPrizeUnlimited())
                    }
                    onChange={() => {
                      setValue('userQuantity', '');
                      setValue('isLimited', !watch('isLimited'));
                    }}
                  />
                </div>
                <MessageError message={errors.userQuantity?.message} />
              </Col>
            </Row>

            <div className="mt-5">
              <div className="d-flex align-items-center">
                <span className="fw-bold">
                  {find(campaignOptionsType, (option) => option.value === watch(`type`))?.label}
                </span>
                {watch('type') === 3 && (
                  <span className="ms-3 fs-12 flex-end">
                    JASRAC登録コンテンツは使用できません。
                  </span>
                )}
              </div>

              {watch('type') === 0 && <AdmissionTicket courseIndex={course?.courseIndex} />}
              {watch('type') === 1 && <BonusTicket />}
              {watch('type') === 2 && <SerialNumberGift />}
              {watch('type') === 3 && (
                <DigitalTokutenGift
                  handleAddDeleteItem={handleAddDeleteItem}
                  prizeDigitalTokutens={course?.prize?.prizeDigitalTokutens || []}
                />
              )}
              {watch('type') === 4 && <ArtifactsGift />}
            </div>
          </Form>
        </FormProvider>

        <FormModal.Footer>
          {course?.prize?.index !== undefined ? (
            <Button
              variant="danger"
              className="btn-action"
              disabled={handleDisableDelete()}
              onClick={() => {
                setVisible(true);
              }}
            >
              削除する
            </Button>
          ) : (
            <></>
          )}
          <Button variant="secondary" className="btn-action" onClick={() => handleCancel()}>
            キャンセル
          </Button>
          <Button
            variant="primary"
            className="btn-action"
            type="submit"
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
          >
            保存する
          </Button>
        </FormModal.Footer>
      </Modal>
      <ModalComponent
        show={visible}
        onCloseModal={() => setVisible(false)}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="danger"
        onSubmitModal={() => {
          dispatch(
            deletePrize({
              prize: course.prize,
              courseIndex: course.courseIndex,
            }),
          );
          handleShow && handleShow();
          setVisible(false);

          if (toNumber(courses[course.courseIndex]?.subType) === subTypeOptions[0].value) {
            dispatch(setTriggerCaculateRate({ status: true, courseIndex: course.courseIndex }));
          }
        }}
        title="削除確認"
        confirmText="削除する"
      >
        <div>
          <p className="mb-2">景品を削除します。よろしいですか。</p>
        </div>
      </ModalComponent>
    </>
  );
};
export default BonusPopup;
