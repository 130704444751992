import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { IPullDownItem } from 'Types/Common';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import {
  handleGetIds,
  handleSearchArtistSample,
  handleSearchTitleSample,
  handleSearchVariationSample,
  PageQuery,
  updateQuery,
} from 'Utils/Search';
import useMessage from './useMessage';

export interface SearchResponse {
  options: IPullDownItem[];
  totalPages: number;
}

interface ValidQueryCommon {
  [key: string]: string | number | undefined;
}
interface Props {
  validQueryObj?: {
    validArtist?: ValidQueryCommon;
    validTitle?: ValidQueryCommon;
    validVariation?: ValidQueryCommon;
  };

  fetchList: string[];
}

export default function useSuggestionSample({
  validQueryObj = {
    validArtist: { artistIds: undefined, queryArtist: undefined, artistId: undefined },
    validTitle: { queryTitle: undefined, titleIds: undefined, titleId: undefined },
    validVariation: { queryVariation: undefined, variationIds: undefined, variationId: undefined },
  },
  fetchList,
}: Props): {
  artistOptions: SearchResponse;
  titleOptions: SearchResponse;
  variationOptions: SearchResponse;
  pageObj: PageQuery;
  loadingArtist: boolean;
  loadingTitle: boolean;
  loadingVariation: boolean;
  handleLoadMore: (pageKey: string) => void;
  handleFocusInput: (queryKey: string, pageKey: string) => void;
  handleEnterPrimary: (type: string) => void;
  handleClickItemPrimary: (id: string, type: string) => void;
} {
  //* some query clear with search key clear
  const currentValidQuery = {
    validArtist: {
      ...validQueryObj?.validArtist,
      artistIds: undefined,
      queryArtist: undefined,
      artistId: undefined,
    },
    validTitle: {
      ...validQueryObj?.validTitle,
      queryTitle: undefined,
      titleIds: undefined,
      titleId: undefined,
    },
    validVariation: {
      ...validQueryObj?.validVariation,
      queryVariation: undefined,
      variationIds: undefined,
      variationId: undefined,
    },
  };

  const [query, setQuery] = useQueryParams({
    companyId: StringParam,
    companyIds: StringParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
    variationIds: StringParam,
    queryVariation: StringParam,
    artistId: StringParam,
    titleId: StringParam,
    variationId: StringParam,
    page: NumberParam,
  });

  const { openMessageError } = useMessage();

  const [queryObj, setQueryObj] = useState({
    queryArtist: query.queryArtist,
    queryTitle: query.queryTitle,
    queryVariation: query.queryVariation,
  });

  const [pageObj, setPageObj] = useState<PageQuery>({
    pageArtist: 1,
    pageTitle: 1,
    pageVariation: 1,
    pageSale: 1,
    pageCompany: 1,
    pageCampaign: 1,
  });

  const [artists, setArtists] = useState<SearchResponse>({ options: [], totalPages: 0 });
  const [titles, setTitles] = useState<SearchResponse>({ options: [], totalPages: 0 });
  const [variations, setVariations] = useState<SearchResponse>({ options: [], totalPages: 0 });

  const [artistTmp, setArtistTmp] = useState<IPullDownItem[]>([]);
  const [titleTmp, setTitleTmp] = useState<IPullDownItem[]>([]);
  const [variationTmp, setVariationTmp] = useState<IPullDownItem[]>([]);

  const [loadingArtist, setLoadingArtist] = useState<boolean>(false);
  const [loadingTitle, setLoadingTitle] = useState<boolean>(false);
  const [loadingVariation, setLoadingVariation] = useState<boolean>(false);

  const handleFetchArtists = async () => {
    try {
      setLoadingArtist(true);
      const {
        data: { data },
      } = await handleSearchArtistSample({
        queryArtist: queryObj.queryArtist || '',
        page: pageObj.pageArtist,
      });

      const options = data?.items?.map((artist: IPullDownItem) => ({
        label: artist.localName,
        value: artist.id,
      }));
      setArtists({
        options: pageObj.pageArtist === 1 ? options : [...artists.options, ...options],
        totalPages: data?.meta?.totalPages,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingArtist(false);
    }
  };

  const handleFetchTitles = async () => {
    try {
      setLoadingTitle(true);
      const {
        data: { data },
      } = await handleSearchTitleSample({
        queryTitle: queryObj.queryTitle || '',
        artistId: query.artistId || '',
        companyId: query.companyId || '',
        page: pageObj.pageTitle,
      });

      const options = data?.items?.map((title: IPullDownItem) => ({
        label: title.name,
        value: title.id,
      }));
      setTitles({
        options: pageObj.pageTitle === 1 ? options : [...titles.options, ...options],
        totalPages: data?.meta?.totalPages,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingTitle(false);
    }
  };

  const handleFetchVariations = async () => {
    try {
      setLoadingVariation(true);
      const {
        data: { data },
      } = await handleSearchVariationSample({
        queryVariation: queryObj.queryVariation || '',
        artistId: query.artistId || '',
        titleId: query.titleId || '',
        companyId: query.companyId || '',
        page: pageObj.pageVariation,
      });

      const options = data?.items?.map((item: IPullDownItem) => ({
        label: item.variations_name,
        value: item.variations_id,
      }));
      setVariations({
        options: pageObj.pageVariation === 1 ? options : [...variations.options, ...options],
        totalPages: data?.meta?.totalPages,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingVariation(false);
    }
  };

  const debouceRequest = useCallback((value) => updateQuery(value, setQueryObj), []);

  //* debouce typing text 300ms
  useEffect(() => {
    debouceRequest({
      queryArtist: query.queryArtist,
      queryTitle: query.queryTitle,
      queryVariation: query.queryVariation,
    });
  }, [query.queryArtist, query.queryTitle, query.queryVariation]);

  //* only call api when match key search

  useEffect(() => {
    if (fetchList.includes('artist')) handleFetchArtists();
  }, [queryObj, pageObj.pageArtist]);

  useEffect(() => {
    if (fetchList.includes('title')) handleFetchTitles();
  }, [queryObj, pageObj.pageTitle]);

  useEffect(() => {
    if (fetchList.includes('variation')) handleFetchVariations();
  }, [queryObj, pageObj.pageVariation]);

  //* clear some query if text search clear

  useEffect(() => {
    let validQuery = { ...query } as any;

    if (!query.queryArtist && !isEmpty(currentValidQuery.validArtist)) {
      validQuery = currentValidQuery.validArtist;
      setQuery(validQuery);
    }

    setPageObj({ pageArtist: 1, pageTitle: 1, pageVariation: 1 });
  }, [query.queryArtist]);

  useEffect(() => {
    let validQuery = { ...query } as any;
    if (!query.queryTitle && !isEmpty(currentValidQuery.validTitle)) {
      validQuery = currentValidQuery.validTitle;
      setQuery(validQuery);
    }

    setPageObj({ ...pageObj, pageTitle: 1, pageVariation: 1 });
  }, [query.queryTitle]);

  useEffect(() => {
    let validQuery = { ...query } as any;
    if (!query.queryVariation && !isEmpty(currentValidQuery.validVariation)) {
      validQuery = currentValidQuery.validVariation;
      setQuery(validQuery);
    }

    setPageObj({ ...pageObj, pageVariation: 1 });
  }, [query.queryVariation]);

  const handleLoadMore = (pageKey: string) => {
    if (pageKey === 'pageArtist' && pageObj.pageArtist < artists.totalPages)
      setPageObj({ ...pageObj, pageArtist: (pageObj.pageArtist += 1) });

    if (pageKey === 'pageTitle' && pageObj.pageTitle < titles.totalPages)
      setPageObj({ ...pageObj, pageTitle: (pageObj.pageTitle += 1) });

    if (pageKey === 'pageVariation' && pageObj.pageVariation < variations.totalPages)
      setPageObj({ ...pageObj, pageVariation: (pageObj.pageVariation += 1) });
  };

  useEffect(() => {
    if (queryObj.queryArtist && query.queryArtist) {
      setArtistTmp(artists.options);
    }

    if (!query.queryArtist) {
      setArtistTmp([]);
    }
  }, [artists.options, query.queryArtist, queryObj.queryArtist]);

  useEffect(() => {
    if (queryObj.queryTitle && query.queryTitle) {
      setTitleTmp(titles.options);
    }

    if (!query.queryTitle) {
      setTitleTmp([]);
    }
  }, [query.queryTitle, queryObj.queryTitle, titles.options]);

  useEffect(() => {
    if (queryObj.queryVariation && query.queryVariation) {
      setVariationTmp(variations.options);
    }

    if (!query.queryVariation) {
      setVariationTmp([]);
    }
  }, [query.queryTitle, query.queryVariation, queryObj.queryVariation, variations.options]);

  //* when open menu, reset list menu all data

  const handleFocusInput = useCallback(
    (queryKey: string, pageKey: string) => {
      if (queryKey === 'queryArtist') {
        setArtists({ options: [], totalPages: 0 });
      }

      if (queryKey === 'queryTitle') {
        setTitles({ options: [], totalPages: 0 });
      }

      if (queryKey === 'queryVariation') {
        setVariations({ options: [], totalPages: 0 });
      }

      setQueryObj({ ...queryObj, [queryKey]: '' });
      setPageObj({ ...pageObj, [pageKey]: 1 });
    },
    [pageObj, queryObj],
  );

  const handleEnterPrimary = useCallback(
    (type: string) => {
      const res = [artistTmp, titleTmp, variationTmp];
      const ids = res.map((item: any) => handleGetIds(item).split(','));
      if (type === 'queryArtist') {
        const artistIds = query[type] ? (ids[0] ? ids[0] : '') : '';
        setQuery({
          artistIds: artistIds as string,
          page: 1,
        });
      }

      if (type === 'queryTitle') {
        const titleIds = query[type] ? (ids[1] ? ids[1] : '') : '';
        setQuery({
          titleIds: titleIds as string,
          page: 1,
        });
      }

      if (type === 'queryVariation') {
        const variationIds = query[type] ? (ids[2] ? ids[2] : '') : '';
        setQuery({
          variationIds: variationIds as string,
          page: 1,
        });
      }
    },
    [artistTmp, query, setQuery, titleTmp, variationTmp],
  );

  const handleClickItemPrimary = useCallback(
    (id: string, type: string) => {
      if (type === 'queryArtist') {
        setQuery({ artistId: id, artistIds: id, page: 1 });
      }

      if (type === 'queryTitle') {
        setQuery({ titleId: id, titleIds: id, page: 1 });
      }

      if (type === 'queryVariation') {
        setQuery({ variationId: id, variationIds: id, page: 1 });
      }
    },
    [setQuery],
  );

  return {
    artistOptions: artists,
    titleOptions: titles,
    variationOptions: variations,
    pageObj,
    loadingArtist,
    loadingTitle,
    loadingVariation,
    handleLoadMore,
    handleFocusInput,
    handleEnterPrimary,
    handleClickItemPrimary,
  };
}
