import React from 'react';
import Select, { ActionMeta, GroupTypeBase } from 'react-select';
import { SelectComponents } from 'react-select/src/components';
import { ISelect, SelectValue } from 'Types/Common';
import { ColSpec } from 'Components/Common/Form/form';

import { Form, Row, Col } from 'react-bootstrap';

interface ISelectValue {
  id?: number;
  value: string | number;
  label: string;
}
interface Props {
  options: ISelect[];
  className?: string;
  placeholder?: string;
  components?: Partial<SelectComponents<ISelect, boolean, GroupTypeBase<ISelect>>>;
  onChange: (value: SelectValue, actionMeta: ActionMeta<ISelect>) => void;
  defaultValue?: SelectValue;
  value?: ISelectValue | null;
  isSearchable?: boolean;
  label?: string;
  rowClass?: string;
  classLabel?: string;
  labelLg?: ColSpec | undefined;
  labelMd: ColSpec | undefined;
  colMd: ColSpec | undefined;
  colLg?: ColSpec | undefined;
  colClass?: string;
  isDisabled?: boolean;
  onSearch?: (value: string) => void;
  onMenuOpen?: () => void;
}

const customStyles = {
  option: (provided: any) => ({
    ...provided,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  control: (provided: any) => ({
    ...provided,
    height: 35,
    minHeight: 35,
    borderColor: 'hsl(0, 0%, 80%)',
    // This line disable the blue border
    boxShadow: 'none',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: 33,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '0.375rem 0.75rem',
    height: '100%',
  }),
};

export default function SelectComponent(props: Props): JSX.Element {
  const {
    options,
    className,
    placeholder,
    onChange,
    value,
    onSearch,
    label,
    rowClass,
    labelMd,
    labelLg,
    classLabel,
    colMd,
    colLg,
    colClass,
    isDisabled,
    onMenuOpen,
    ...rest
  } = props;
  return (
    <>
      <Form.Group as={Row} className={rowClass}>
        {label && (
          <Form.Label className={classLabel} column md={labelMd} lg={labelLg}>
            {label}
          </Form.Label>
        )}
        <Col md={colMd} lg={colLg} className={`${colClass} position-relative`}>
          <Select
            isDisabled={isDisabled}
            options={options}
            className={className}
            placeholder={placeholder}
            onChange={onChange}
            components={{ IndicatorSeparator: () => null }}
            value={value}
            onInputChange={onSearch}
            styles={customStyles}
            noOptionsMessage={() => '該当のデータはありません'}
            onMenuOpen={() => onMenuOpen?.()}
            {...rest}
          />
        </Col>
      </Form.Group>
    </>
  );
}
