import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';

interface CostCardProps {
  unitPrice: string;
  className?: string;
  text?: string;
  cdLabel?: string;
}
const CostCDCard = ({ unitPrice, className, text, cdLabel }: CostCardProps): JSX.Element => {
  const fixedText = useMemo(() => {
    return String(parseFloat(unitPrice).toFixed(1)) || '';
  }, [unitPrice]);
  return (
    <Card className={`w-100 p-4 border-transparent text-center bg-smoke ${className}`}>
      <p className="fw-bold text-break fs-12">{cdLabel}</p>
      <p className="fw-bold fs-16 py-4 my-3">{`単価  ${fixedText}円`}</p>
      <p className="text-break fs-11 pt-2">{text}</p>
    </Card>
  );
};

export default CostCDCard;
