export const UNDEFINED_SHOP_NAME = '（指定しない）';
export const NOT_CHOOSE_SHOP_NAME = '未選択';
export const VALUES_UNDEFINED = '0';
export const SEARCH_SHOP_CLIENT = '（指定しない）()';

interface RenderShopNameParams {
  shopId: number | string | null | undefined;
  shopName: string | null | undefined;
  [key: string]: any;
}

/**
 * - Not display `shopId: null` case on table view
 * - Check null before rendering this function on table view
 */
export const renderShopName = (dataShop: RenderShopNameParams): string => {
  if (dataShop?.shopId?.toString() === VALUES_UNDEFINED) {
    return UNDEFINED_SHOP_NAME;
  }

  if (dataShop?.shopId === null || dataShop?.shopId === undefined) {
    return NOT_CHOOSE_SHOP_NAME;
  }

  return dataShop.shopName ? dataShop.shopName : '';
};
