/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';
import { toast } from 'react-toastify';
import { UploadMediaApi } from 'Datasource/UploadMedia';
import axios from 'axios';

const uploadMedia = async (file: File, type: string): Promise<string> => {
  let resURL = '';
  try {
    const formData: FormData = new FormData();
    formData.append('type', type);
    formData.append('file', file);
    const {
      data: { url },
    } = await UploadMediaApi.upLoadMedia(formData);
    resURL = url;
  } catch (error: any) {
    error?.data?.message?.map((mes: string) => {
      toast(mes, { type: 'error' });
    });
  }
  return resURL ?? '';
};

export default uploadMedia;

// ----Upload with CHUNK file
export const FILE_CHUNK_SIZE = 100 * 1024 * 1024; // 100MB
enum STATUS {
  SUCCESS = 1,
  FAIL = 0,
}
export const MESSAGE_UPLOAD_FAIL = 'ファイルのアップロードに失敗しました。';

export const getNumChunk = (fileSize: number) => {
  return Math.floor(fileSize / FILE_CHUNK_SIZE) + 1;
};

const uploadChunkMedia = async (url: string, blob: Blob, retries = 3): Promise<any> => {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const response = await axios.put(url, blob, {
        headers: {
          'Content-Type': '',
        },
      });
      return response;
    } catch (error) {
      console.log(`Retrying upload for blob. Attempt ${attempt} of ${retries}`);
      if (attempt === retries) {
        throw error;
      }
    }
  }
};

export const completeUploadRespAPI = async (
  fileName: string,
  uploadPartsArray: { ETag: string; PartNumber: number }[],
  uploadId: string,
  status: STATUS,
  duration: string,
) => {
  const res = await brAxios.post('/record-company/complete-upload-file-presign-url', {
    status: status,
    fileName: fileName,
    parts: uploadPartsArray,
    uploadId: uploadId,
    duration: duration,
  });
  return res.data;
};
export const uploadMultipartFile = async (
  file: Blob,
  presignedUrl: { url: string }[],
  uploadId: string,
  fileName: string,
) => {
  // Global const
  const uploadPartsArray: { ETag: string; PartNumber: number }[] = [];

  const duration = await getVideoDurationFormatted(file as File);

  try {
    const fileSize = file.size;
    const NUM_CHUNKS = getNumChunk(fileSize);
    const promisesArray: any[] = [];
    let start, end, blob;

    for (let index = 0; index < NUM_CHUNKS; index++) {
      start = index * FILE_CHUNK_SIZE;
      end = (index + 1) * FILE_CHUNK_SIZE > file.size ? file.size : (index + 1) * FILE_CHUNK_SIZE;
      blob = index < NUM_CHUNKS ? file.slice(start, end) : file.slice(start);

      // Upload Chunk
      const uploadResp = uploadChunkMedia(presignedUrl[index].url, blob);

      promisesArray.push(uploadResp);
    }

    const resolvedArray = await Promise.all(promisesArray);

    resolvedArray.forEach((resolvedPromise, index) => {
      uploadPartsArray.push({
        ETag: resolvedPromise.headers.etag.replace(/"/g, ''),
        PartNumber: index + 1,
      });
    });

    // Calls the CompleteMultipartUpload endpoint in the backend server
    const { data } = await completeUploadRespAPI(
      fileName,
      uploadPartsArray,
      uploadId,
      STATUS.SUCCESS,
      duration,
    );
    return data.url ?? '';
  } catch (err) {
    const { data } = await completeUploadRespAPI(
      fileName,
      uploadPartsArray,
      uploadId,
      STATUS.FAIL,
      duration,
    );
    if (err || !data.url) {
      return data.url ?? err;
    }
  }
};

export const getVideoDurationFormatted = (video: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement('video');
    videoElement.preload = 'metadata';
    videoElement.onloadedmetadata = () => {
      window.URL.revokeObjectURL(videoElement.src);
      const durationInSeconds = videoElement.duration;
      const formattedDuration = new Date(durationInSeconds * 1000).toISOString().slice(11, 19);
      resolve(formattedDuration);
    };
    videoElement.onerror = reject;

    const url = window.URL.createObjectURL(video);
    videoElement.src = url;
  });
};
