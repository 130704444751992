import React, { memo, useMemo } from 'react';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import TableCommon from 'Components/Common/Table/TableCommon';
import { SortMethod } from 'Components/Common/Table/table';

interface IListCD {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const TableList = ({
  dataSource,
  onSort,
}: {
  dataSource: any;
  onSort?: (sortingState: SortMethod[]) => void;
}): JSX.Element => {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: 'ID',
            accessor: 'id',
          },
          {
            Header: 'アーティスト',
            accessor: 'artistName',
            Cell: ({ row }: Cell<IListCD>) => {
              return (
                <span className="d-block text-truncate text-start">{row.original.artistName}</span>
              );
            },
          },
          {
            Header: 'タイトル名',
            accessor: 'titleName',
            Cell: ({ row }: Cell<IListCD>) => {
              return (
                <span className="d-block text-truncate text-start">{row.original.titleName}</span>
              );
            },
          },
          {
            Header: 'CD商品名 (バリエーション)',
            accessor: 'name',
            Cell: ({ row }: Cell<IListCD>) => {
              return (
                <Link
                  to={{
                    pathname: `/cd-master/${row.original.id}`,
                    state: { id: row.original.id },
                  }}
                  className={`${!row.original.id && 'text-decoration-none text-black pe-none'}`}
                >
                  <span className="d-block text-truncate text-start">{row.original.name}</span>
                </Link>
              );
            },
          },
          {
            Header: '品番',
            accessor: 'productCode',
            Cell: ({ row }: Cell<IListCD>) => {
              return <span className="d-block text-break">{row?.original?.productCode}</span>;
            },
          },
          {
            Header: '販売価格',
            accessor: 'price',
            Cell: ({ row }: Cell<IListCD>) => {
              return (
                <span className="d-block text-truncate text-end">
                  {row.original.price?.toLocaleString()}
                </span>
              );
            },
          },
        ],
      },
    ],
    [],
  );

  return (
    <Row className="px-3 mt-3 pb-3">
      <SpinnerComponent isLoading={false} />
      <TableCommon
        classTable="table-list-cd"
        heightBody="calc(100vh - 200px)"
        data={dataSource}
        columns={columns}
        messageNoData="検索結果がありません。"
        manualSortBy
        onSort={onSort}
      />
    </Row>
  );
};

export default memo(TableList);
