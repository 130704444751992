/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';

interface QueryParams {
  artistId?: string;
  date?: string;
  place?: string;
}

export const UsingStatusApi = {
  getListArtist: () => brAxios.get(`/staff/artists`),
  getListPlaces: ({ artistId, date }: QueryParams) =>
    brAxios.get(`/staff/search-ticket?artistId=${artistId ?? ''}&date=${date ?? ''}`),
  getListTickets: ({ artistId, date, place }: QueryParams) =>
    brAxios.get(
      `/staff/search-ticket?artistId=${artistId ?? ''}&date=${date ?? ''}&place=${place ?? ''}`,
    ),
  getDataUsingStatusTicketIn: (entrancePrizeId?: number | null) =>
    brAxios.get(`staff/statistical-entrance-tickets?entrancePrizeId=${entrancePrizeId ?? ''}`),
  getDataUsingStatusTokuten: (entrancePrizeId?: number | null, tokutenPrizeId?: number | null) =>
    brAxios.get(
      `/staff/statistical-tokuten-tickets?tokutenPrizeId=${entrancePrizeId ?? ''}&entrancePrizeId=${
        tokutenPrizeId ?? ''
      }`,
    ),
  stopOrStartSell: (prizedId?: number | null, isStop?: boolean) =>
    brAxios.put(`/staff/tokuten/${prizedId ?? ''}`, { isStop }),
  checkRoleUpdatePrize: (prizedId?: number | null) =>
    brAxios.get(`/staff/check-access/${prizedId ?? ''}`),
  checkSoldOut: (prizedId?: number | null) =>
    brAxios.get(`/staff/check-sold-out/${prizedId ?? ''}`),
};
