import React, { useState, useEffect } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';

interface Options {
  name: string;
  disabled?: boolean;
  value: number | string;
  description?: string;
}

interface TabCampaignProps {
  tabActive?: string | null | number;
  onChange?: (event: string | number) => void;
  defaultActive?: string | null | number;
  groupClass?: string;
  btnClass?: string;
  options: Options[];
  disabled?: boolean;
  redirect?: string;
  heightBtn?: string;
  isDetail?: boolean;
  disabledSetQuery?: boolean;
  disabledSetPage?: boolean;
  indexDefault?: number;
  clickOnly?: boolean;
}

export default function TabCampaign({
  tabActive = 0,
  groupClass,
  btnClass,
  options,
  onChange,
  disabled,
  clickOnly = false,
}: TabCampaignProps): JSX.Element {
  const [active, setActive] = useState(tabActive);
  const activeClass = 'bg-dark text-white pe-none opacity-100';

  const onchangeTab = (tab: string | number) => {
    setActive(tab);
    if (onChange) onChange(tab);
  };

  useEffect(() => {
    if (tabActive && !clickOnly) onchangeTab(tabActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabActive, clickOnly]);

  return (
    <>
      <ButtonGroup
        className={`${groupClass} ${disabled && 'pe-none opacity-50'}`}
        aria-label="basic-tab"
      >
        {options.map((option, index) => (
          <>
            <Button
              key={index}
              variant="light"
              className={`text-truncate border ${btnClass ?? ''} ${
                active === option.value ? activeClass : ''
              }`}
              onClick={() => {
                onchangeTab(option.value);
              }}
              disabled={option.disabled}
            >
              {option.name}
            </Button>
          </>
        ))}
      </ButtonGroup>
      <p className={`text-prev-wrap mt-3 ${disabled && 'pe-none opacity-50'}`}>
        {options[tabActive ? +tabActive : 0].description}
      </p>
    </>
  );
}
