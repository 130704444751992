import { PurchaseResponse } from 'Types/Page/CompanyInformation/CompanyInformation';
import { getSum } from 'Utils/Numbers';
import { costInformationList } from 'Constant/Mock';
export interface AmountTotal {
  [key: string]: any;
}
const getCostPurchase = (purchaseData?: PurchaseResponse) => {
  const totalMoney2 = getSum(purchaseData?.items || [], 'totalMoney');
  const totalMoney1 =
    totalMoney2 * (purchaseData?.meta.feePurchase ? purchaseData?.meta.feePurchase + 1 : 1);
  return [{ totalMoney1: totalMoney1, totalMoney2: totalMoney2 }];
};

const getPricePurchase = (purchaseData?: PurchaseResponse) => {
  const keyArrs = [
    'ecAmount',
    'limistaAmount',
    'troAmount',
    'totalAmount',
    'ecTotalMoney',
    'limistaTotalMoney',
    'troMoney',
    'totalMoney',
  ];
  const item: any = {};
  keyArrs.forEach((key) => {
    item[key] = getSum(purchaseData?.items || [], key);
  });

  return [item];
};

const defaultData = costInformationList.map((item) => ({
  feeName: item?.titleType?.trim(),
  totalAmount: 0,
  optionCardBuyFee: 0,
  buyFeeMoney: 0,
  taxFee: 0,
  taxFeeMoney: 0,
  snFee: 0,
  snFeeMoney: 0,
  totalMoney: 0,
}));
const getCostRight = ({ costData, isDeafault, amountTotal, amountTaxFeeMoneyTotal }: any) => {
  let arr = [];
  const defaultOptions = costData?.companyOptions ? [...costData?.companyOptions] : [];
  arr = defaultOptions.map((item) => ({
    feeName: item?.titleType?.trim(),
    totalAmount: amountTotal[item?.optionId] || 0,
    optionCardBuyFee: item.cardBuyFee || 0,
    buyFeeMoney: costData.amountCardBuyFeeTotal[item?.optionId] || 0,
    taxFee: item.taxFee || 0,
    taxFeeMoney: amountTaxFeeMoneyTotal[item?.optionId] || 0,
    snFee: 0,
    snFeeMoney: 0,
    totalMoney: 0,
  }));

  if (isDeafault) {
    arr = [...defaultData];
  }
  arr = [
    ...arr,
    {
      feeName: 'CDシリアル出力',
      totalAmount: amountTotal?.CDシリアル出力 || 0,
      optionCardBuyFee: 0,
      buyFeeMoney: 0,
      taxFee: 0,
      taxFeeMoney: 0,
      snFee: costData?.meta.snFee || 0,
      snFeeMoney: costData?.amountOptionFeeTotal?.['CDシリアル出力'] || 0,
      totalMoney: 0,
    },
    {
      feeName: 'CDシリアル読取り',
      totalAmount: amountTotal?.CDシリアル読取り || 0,
      optionCardBuyFee: 0,
      buyFeeMoney: 0,
      taxFee: 0,
      taxFeeMoney: 0,
      snFee: costData?.meta.snImportFee || 0,
      snFeeMoney: costData?.amountOptionFeeTotal?.['CDシリアル読取り'] || 0,
      totalMoney: 0,
    },
  ];
  arr.forEach((item) => {
    item.totalMoney = +item.buyFeeMoney + +item.taxFeeMoney + +item.snFeeMoney;
  });
  return arr;
};

const getCostLeft = (costRight?: any) => {
  const totalMoney = getSum(costRight || [], 'totalMoney');

  return [{ totalMoney }];
};
export { getCostPurchase, getPricePurchase, getCostRight, getCostLeft };
