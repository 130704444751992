import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import Dropzone from 'react-dropzone';
import { useFieldArray, useFormContext } from 'react-hook-form';
import MessageError from 'Components/Common/MessageError';
import NumberFormat from 'react-number-format';
import { reduce } from 'lodash';
import uploadMedia, {
  MESSAGE_UPLOAD_FAIL,
  getNumChunk,
  uploadMultipartFile,
} from 'Utils/UploadFile';
import { TYPE_MEDIA } from 'Constant';
import { useAppSelector, useAppDispatch } from 'App/Store';
import { updateStep1State } from 'App/Features/Campaign';
import { CAMPAIGN_MEDIA_TYPE } from 'Constant/Campaign';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import { calcMb } from 'Utils/File';
import useCampaign from 'Hooks/useCampaign';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';
import { UploadMediaApi } from 'Datasource/UploadMedia';
interface fieldTypes extends Record<'id', string> {
  type?: number;
  url?: string;
}

const getFileType = (file: File) => {
  if (file.type.match('image.*')) return CAMPAIGN_MEDIA_TYPE.Image;
  if (file.type.match('video.*')) return CAMPAIGN_MEDIA_TYPE.Video;
  if (file.type.match('audio.*')) return CAMPAIGN_MEDIA_TYPE.Audio;
  return 'other';
};
const LABEL_MEDIA = {
  new: 0,
  old: 1,
};

const initialFileState = {
  name: '',
  percentage: null,
  type: null,
  url: '',
  size: 0,
  id: null,
  label: LABEL_MEDIA.new,
};

const DigitalFields = ({
  dataSizeLimit,
  digitalTokutenPrizeType,
  remainingCapacity,
  dataSizeUsed,
  handleAddDeleteItem,
  prizeDigitalTokutens,
}: {
  dataSizeLimit: number;
  remainingCapacity: number;
  digitalTokutenPrizeType: number;
  dataSizeUsed: number;
  handleAddDeleteItem: (item: any) => void;
  prizeDigitalTokutens: any[];
}): JSX.Element => {
  const { overview } = useAppSelector((state) => state.campaign);
  const dispatch = useAppDispatch();
  const { handleDisableDataCampaign } = useCampaign();
  const [loading, setLoading] = useState(false);
  const { openMessage } = useMessage();
  const {
    register,
    getValues,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove, update } = useFieldArray({
    name: 'type4.prizeDigitalTokutens',
  });

  const handleOnDrop = async (files: File[], index: number) => {
    clearErrors(`type4.prizeDigitalTokutens.${index}.url`);
    const name = watch(`type4.prizeDigitalTokutens.${index}.name`);
    const percentage = watch(`type4.prizeDigitalTokutens.${index}.percentage`);
    const id = watch(`type4.prizeDigitalTokutens.${index}.id`) || prizeDigitalTokutens[index]?.id;
    const label = watch(`type4.prizeDigitalTokutens.${index}.label`);

    const type = getFileType(files[0]) ?? 0;
    if (type === 'other') {
      openMessage({
        variant: 'error',
        message: '画像タイプはjpeg, pngまたはgifの画像を登録してください。',
      });
      return;
    }

    //* valid file type media
    const mediaFeature = ['mp4', 'mp3', 'jpeg', 'png', 'gif', 'jpg'];
    const fileFeature = files[0].name.split('.').pop()?.toLowerCase();

    if (fileFeature && !mediaFeature.includes(fileFeature)) {
      let msg = '';
      if (type === CAMPAIGN_MEDIA_TYPE.Image) {
        msg = '画像タイプはjpeg, pngまたはgifの画像を登録してください。';
      }
      if (type === CAMPAIGN_MEDIA_TYPE.Audio) {
        msg = '音声ファイルはmp3の音声を登録してください。';
      }
      if (type === CAMPAIGN_MEDIA_TYPE.Video) {
        msg = '動画ファイルはmp4の動画を登録してください。';
      }
      openMessage({
        variant: 'error',
        message: msg,
      });

      return;
    }
    const dataSize = overview?.dataSize + files[0].size;
    if (dataSize + dataSizeUsed > dataSizeLimit) {
      setError(`type4.prizeDigitalTokutens.${index}.url`, {
        type: 'custom',
        message: `${calcMb(remainingCapacity)}MB以内のファイルを登録してください。`,
      });
      return;
    }
    try {
      setLoading(true);
      let url;
      if (type === CAMPAIGN_MEDIA_TYPE.Video) {
        const { size, type: mimeType } = files[0];
        const NUM_CHUNKS = getNumChunk(size);
        const form = {
          fileSize: Number(size),
          mimeType: mimeType,
          partNumber: NUM_CHUNKS,
          type: TYPE_MEDIA.DIGITAL_TOKUTEN,
          tokutenType: 1,
        };

        const urlMedia = await UploadMediaApi.upLoadMediaV2(form).then(async (res: any) => {
          const { listPart, uploadId, fileName } = res.data;
          return await uploadMultipartFile(files[0], listPart, uploadId, fileName);
        });

        if (!urlMedia) {
          openMessage({
            variant: 'error',
            message: MESSAGE_UPLOAD_FAIL,
          });
        } else {
          url = urlMedia;
        }
      } else {
        url = await uploadMedia(files[0], `${TYPE_MEDIA.CAMPAIGN}`);
      }
      const media = {
        type,
        url,
        size: files[0].size,
        name,
        percentage,
        dgId: id,
        label: LABEL_MEDIA.old,
      };
      if (label === LABEL_MEDIA.new) {
        media.label = label;
      }
      dispatch(updateStep1State({ overview: { ...overview, dataSize } }));
      update(index, media);
    } finally {
      setLoading(false);
    }
  };

  const totalCoefficient: number = reduce(
    getValues('type4.prizeDigitalTokutens'),
    (sum, field) => {
      return sum + Number(field.percentage ?? 0);
    },
    0,
  );

  const handleCalculatePercent = (coefficient: number) => {
    if (!coefficient || !+coefficient) return 0;
    const percent = (Number(coefficient) / totalCoefficient) * 100;
    return Math.round(percent);
  };

  useEffect(() => {
    if (digitalTokutenPrizeType === 0) {
      fields.forEach((_, index) => {
        setValue(`type4.prizeDigitalTokutens.${index}.percentage`, null);
      });
    }
  }, [digitalTokutenPrizeType]);

  const handleDisabledAddMedia = () => {
    let inValid = false;
    if (!overview?.isDeletable || !overview?.titleId) {
      inValid = true;
    }
    if (handleDisableDataCampaign(watch('type4.id'))) {
      inValid = true;
    }
    return inValid;
  };

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <Row className="mb-3">
        <Col md="10">
          <div className="d-flex align-center">
            <Button onClick={() => append(initialFileState)} disabled={handleDisabledAddMedia()}>
              コンテンツを追加する
            </Button>
            <div className="ms-3 fs-12 align-self-center">
              登録可能なデジタルコンテンツ（画像：jpeg, png, gif, 動画：mp4、音声：mp3）
            </div>
          </div>
        </Col>
      </Row>
      {fields.map((field: fieldTypes, index: number) => {
        return (
          <Row key={index}>
            <Col>
              <div className="card mb-3 border-0">
                <hr className="mt-0 mb-3" />
                <div className="row g-0">
                  <div className="col-md-4 border rounded">
                    {field.type === CAMPAIGN_MEDIA_TYPE.Video && field.url && (
                      <video className="w-100" controls>
                        <track kind="captions" />
                        <source src={handleConnectStaticEndPoint(field.url)} type="video/mp4" />
                        Your browser does not support HTML video.
                      </video>
                    )}
                    {field.type === CAMPAIGN_MEDIA_TYPE.Image && field.url && (
                      <img
                        src={handleConnectStaticEndPoint(field.url)}
                        className="img-fluid rounded-start"
                        style={{ maxWidth: 250 }}
                        alt="..."
                      />
                    )}
                    {field.type === CAMPAIGN_MEDIA_TYPE.Audio && field.url && (
                      <audio controls className="mt-4 h-35px">
                        <track kind="captions" />
                        <source src={handleConnectStaticEndPoint(field.url)} type="audio/mpeg" />
                      </audio>
                    )}
                    {!field.url && (
                      <Dropzone onDropAccepted={(files) => handleOnDrop(files, index)} maxFiles={1}>
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps({
                              className: 'd-flex align-items-center justify-content-center h-100',
                            })}
                          >
                            <input {...getInputProps()} />
                            <div>
                              <div>コンテンツファイル未登録</div>
                              <Button>ファイルアップロード</Button>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    )}
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <Form.Group as={Row} className="mb-3">
                        <Form.Label column md="12">
                          <p className="fs-12">タイトル名</p>
                        </Form.Label>
                        <Col md="12">
                          <Form.Control
                            {...register(
                              `type4.prizeDigitalTokutens.${index}.name` as 'type4.prizeDigitalTokutens.0.name',
                            )}
                            autoComplete="off"
                            disabled={handleDisableDataCampaign(field.id)}
                          />
                          <MessageError
                            message={errors?.type4?.prizeDigitalTokutens?.[index]?.name?.message}
                          />
                        </Col>
                      </Form.Group>

                      {digitalTokutenPrizeType === 1 && (
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column md="12">
                            <p className="fs-12">当選係数</p>
                          </Form.Label>
                          <Col md="4">
                            <div className="d-flex align-items-center">
                              <NumberFormat
                                className="form-control rounded position-relative me-2 w-75"
                                value={watch(`type4.prizeDigitalTokutens.${index}.percentage`)}
                                onValueChange={({ value }) => {
                                  setValue(`type4.prizeDigitalTokutens.${index}.percentage`, value);
                                }}
                                disabled={handleDisableDataCampaign(field.id)}
                              />
                              <div>
                                {handleCalculatePercent(
                                  watch(`type4.prizeDigitalTokutens.${index}.percentage`),
                                )}
                                %
                              </div>
                            </div>
                            <MessageError
                              message={
                                errors.type4?.prizeDigitalTokutens?.[index]?.percentage?.message
                              }
                            />
                          </Col>
                        </Form.Group>
                      )}

                      <Row>
                        <Col className="text-end">
                          <Button
                            variant="secondary w-btn-delete"
                            disabled={handleDisableDataCampaign(field.id)}
                            onClick={() => {
                              handleAddDeleteItem(watch(`type4.prizeDigitalTokutens.${index}`));
                              const reducedCapacity =
                                overview.dataSize -
                                watch(`type4.prizeDigitalTokutens.${index}.size`);
                              dispatch(
                                updateStep1State({
                                  overview: {
                                    ...overview,
                                    dataSize: reducedCapacity < 0 ? 0 : reducedCapacity,
                                  },
                                }),
                              );
                              remove(index);
                            }}
                          >
                            <Trash size="14" className="me-2" />
                            削除する
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md="4">
                    <MessageError
                      message={errors.type4?.prizeDigitalTokutens?.[index]?.url?.message}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default DigitalFields;
