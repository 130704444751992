/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';
interface QueryParams {
  page?: number;
  limit?: number;
  query?: string;
  titleId?: string;
  artistId?: string;
  channelId?: string;
  status?: number | null;
  cardCd?: string;
  productId?: string;
  companyId?: string;
  isMaster?: string;
  gmt?: number;
  sort?: string;
  order?: string;
  showAll?: number;
}

export const ProductsApi = {
  getCards: ({
    page,
    limit,
    query,
    titleId,
    artistId,
    channelId,
    status,
    cardCd,
    isMaster,
    sort,
    order,
  }: QueryParams) => {
    const response = brAxios.get(
      `/record-company/cards?page=${page ?? ''}&limit=${limit ?? ''}&query=${query ?? ''}&titleId=${
        titleId ?? ''
      }&artistId=${artistId ?? ''}&channelId=${channelId ?? ''}&status=${status ?? ''}&cardCD=${
        cardCd ?? ''
      }${isMaster ? `&isMaster=${isMaster}` : ''}&sort=${sort || ''}&order=${order || 'DESC'}`,
    );
    return response;
  },
  getSaleChannels: ({
    titleId,
    artistId,
    productId,
    page,
    limit,
    query,
    showAll,
    companyId,
  }: QueryParams) => {
    const response = brAxios.get(
      `/suggestion/sale-channels-record?titleId=${titleId ?? ''}&artistId=${artistId ?? ''}${
        productId ? `&productId=${productId}` : ''
      }${companyId ? `&companyId=${companyId}` : ''}&page=${page ?? ''}&limit=${
        limit ?? ''
      }&query=${query ?? ''}&showAll=${showAll ?? 0}`,
    );
    return response;
  },
  downloadExcel: ({ titleId, artistId, channelId, status, cardCd, gmt, isMaster }: QueryParams) => {
    const response = brAxios.get(
      `/record-company/cards/export/download?titleId=${titleId ?? ''}&artistId=${
        artistId ?? ''
      }&channelId=${channelId ?? ''}&status=${status ?? ''}${
        isMaster ? `&isMaster=${isMaster}` : ''
      }&cardCd=${cardCd ?? ''}&gmt=${gmt ?? ''}`,
      { responseType: 'arraybuffer' },
    );
    return response;
  },
  createCard: (params: any) => brAxios.post('/record-company/cards', params),
  updateCard: (params: any) => brAxios.put('/record-company/cards', params),
  getProcessManagement: ({ titleId, artistId }: QueryParams) => {
    const response = brAxios.get(
      `/record-company/process-management?titleId=${titleId ?? ''}&artistId=${artistId ?? ''}`,
    );
    return response;
  },
  createComment: (params: any) => brAxios.post('/record-company/process-management', params),
  getCampaignTitle: ({ titleId }: QueryParams) => {
    const response = brAxios.get(`/record-company/campaigns/title/${titleId ?? ''}`);
    return response;
  },
  getCampaignProducts: ({ titleId, productId }: QueryParams) => {
    const response = brAxios.get(
      `/record-company/campaigns/product-info?titleId=${titleId ?? ''}${
        productId ? `&productId=${productId}` : ''
      }`,
    );
    return response;
  },
};
