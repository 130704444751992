import React from 'react';
import { Container, Image } from 'react-bootstrap';
import BrLogo from 'Static/Images/br-logo.png';
import Logo from 'Static/Images/logo.png';
import WebAppLoginForm from 'Components/Page/Login/WebApploginForm';
const LoginWebApp = (): JSX.Element => {
  return (
    <div className="bg-public login-wrapper">
      <Container className="d-flex flex-column justify-content-start align-items-center text-white pt-5 login-content">
        <div className="text-center pt-5">
          <h1>
            <Image src={Logo} />
          </h1>
          <h6 className="ff-arial fs-16 fw-700 pt-3">Event Staff Application</h6>
        </div>
        <WebAppLoginForm />
      </Container>
      <div className="w-100 text-center p-2">
        <img src={BrLogo} alt="bg logo" className="w-max-content" />
        <p className="my-2 fs-12">Copyright ©2019 BootRock Inc. All rights reserved.</p>
      </div>
    </div>
  );
};

export default LoginWebApp;
