import React, { useState, useEffect } from 'react';
import { Col, Row, Container, Button, Spinner } from 'react-bootstrap';
import { isEmpty, find } from 'lodash';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { TitleApi } from 'Datasource/TitleApi';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import Filter from 'Components/Common/Filter/Filter';
import Tabs from 'Components/Common/Tabs/Tabs';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { PAGE_SIZE } from 'Constant';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import { DATE_FORMAT_3, DATE_FORMAT_EXPORT_EXCEL } from 'Constant/Date';
import { handleConvertToDash } from 'Utils/IsDash';
import TitleSearch from 'Page/Title/TitleSearch';
import { getUserGMT } from 'Utils/Params';
import { exportExcel } from 'Utils/File';
import useMessage from 'Hooks/useMessage';
import useErrorBlob from 'Hooks/useErrorBlob';

interface TitleInterface {
  approveType: number;
  artistName: string;
  coverUrl: string;
  id: number;
  label: string | null;
  releaseDate: string;
  titleName: string;
  variationName: string;
  status: number;
  isAuthor: number;
}

interface BtnOptionType {
  name: string;
  value: string | number;
}

export default function TitleList(): JSX.Element {
  const history = useHistory();
  const [query] = useQueryParams({
    page: NumberParam,
    status: StringParam,
    queryCompany: StringParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    companyIds: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
    isSetEmpty: NumberParam,
  });

  const btnGroupOptions = [
    { name: 'すべて', value: '' },
    { name: '一時保存 ', value: 0 },
    { name: '登録済', value: 1 },
  ];

  const [tabActive, setTabActive] = useState(btnGroupOptions[0].name);
  const [titles, setTitles] = useState<TitleInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [activePage, setActivePage] = useState(query.page ?? 1);
  const [status, setStatus] = useState<number | string>(
    find(btnGroupOptions, (option: BtnOptionType) => option.name === query.status)?.value ?? '',
  );
  const [paramsQuery, setparamsQuery] = useState({});
  const { openMessageError } = useMessage();
  const { openErrorBlob } = useErrorBlob();

  const [exporting, setexporting] = useState<boolean>(false);

  const handleProductsDetailClick = (
    e: { stopPropagation: () => void },
    id: number,
    name: string,
  ) => {
    e.stopPropagation();
    history.push({
      pathname: `/products-detail/${id}`,
      state: { titleName: name },
    });
  };

  const handleDetailClickDuplicate = (e: { stopPropagation: () => void }, id: number) => {
    e.stopPropagation();
    history.push({
      pathname: '/title/register',
      state: { idDuplicate: id },
    });
  };

  const handleChangeTabs = (tab: string) => {
    if (!tab) return;
    const value = find(btnGroupOptions, (option: BtnOptionType) => option.name === tab)?.value;
    setTabActive(tab);
    setStatus(value ?? '');
  };

  const handleConvertReleaseDate = (date: string) => {
    return date ? dayjs(date).format(DATE_FORMAT_3) : '';
  };

  const handleOnchangeList = async (ids: any) => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await TitleApi.getAllTitles({
        page: activePage,
        limit: PAGE_SIZE,
        query: '',
        status,
        companyId: ids.companyIds,
        artistId: ids.artistIds,
        titleId: ids.titleIds,
      });
      setTitles(data.titles);
      setNumberOfPages(data.totalCount);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const ids = {
      artistIds: query.artistIds ?? '',
      titleIds: query.titleIds ?? '',
    };

    handleOnchangeList(ids);
    setparamsQuery({
      artistId: ids.artistIds,
      titleId: ids.titleIds,
      status,
      gmt: getUserGMT(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, status, query.companyIds, query.artistIds, query.titleIds]);

  const handleExportExcel = async () => {
    try {
      setexporting(true);
      await exportExcel(
        TitleApi.exportExcel(paramsQuery),
        `マスタタイトルリスト_${dayjs().format(DATE_FORMAT_EXPORT_EXCEL).toString()}`,
      );
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setexporting(false);
    }
  };

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <Container fluid className="pb-3">
        <Filter filterClass="shadow-sm">
          <Row>
            <TitleSearch />
            <Col md="4" className="ps-3 px-0 align-self-end">
              <div className="d-flex">
                <Tabs options={btnGroupOptions} tabActive={tabActive} onChange={handleChangeTabs} />
                <Button className="ms-4 btn-focus-none">
                  <Link
                    to="/artist/register-artist"
                    className="text-truncate text-white text-decoration-none"
                  >
                    アーティスト登録
                  </Link>
                </Button>
              </div>
            </Col>
            <Col md="2" className="px-0 d-flex justify-content-end">
              <Button
                variant="primary"
                className={`ms-1 btn-focus-none prize-btn-excel ${exporting ? 'pe-none' : ''}`}
                onClick={handleExportExcel}
              >
                {exporting ? (
                  <Spinner
                    animation="border"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Excelダウンロード'
                )}
              </Button>
            </Col>
          </Row>
        </Filter>
        <Container className="my-2 d-flex justify-content-end px-4">
          <PaginationComponent
            activePage={activePage}
            total={numberOfPages}
            perPage={PAGE_SIZE}
            onClick={(newPage) => setActivePage(newPage)}
          />
        </Container>
        <Container>
          {!isEmpty(titles) ? (
            <>
              <Row className="title-lists">
                {titles.map((item: TitleInterface, index: number) => {
                  return (
                    <Col
                      onClick={() => history.push(`/title-detail/${item.id}`)}
                      key={index}
                      xs="12"
                      className="title-item mb-3 cursor-pointer"
                    >
                      <div className="d-flex border shadow text-truncate">
                        <div className="avatar-title">
                          {item.coverUrl ? (
                            <img
                              loading="lazy"
                              key={`${item.coverUrl}-${index}`}
                              src={handleConnectStaticEndPoint(item?.coverUrl)}
                              className="avatar-title"
                              alt="img-g"
                              // crossOrigin="anonymous"
                            />
                          ) : (
                            <div className="avatar-title bg-gray avatar-bank h-100 d-flex justify-content-center align-items-center">
                              <p className="gray-dark">No Image</p>
                            </div>
                          )}
                        </div>
                        <div className="p-2 flex-fill text-truncate">
                          <p className="pb-1 fs-12 text-truncate">{`${
                            item.artistName
                          } (${handleConvertToDash(item.label)})`}</p>
                          <div className="ps-3 pt-1 text-truncate">
                            <p className="fw-bold text-truncate">
                              {handleConvertToDash(item.titleName)}
                            </p>
                            <div className="fs-12 mt-1 text-truncate">
                              {handleConvertReleaseDate(item.releaseDate)}
                            </div>
                            <div className="fs-12 mt-1 text-truncate">
                              バリエーション : &nbsp;&nbsp;
                              {`${handleConvertToDash(
                                item.variationName
                                  ? item.variationName
                                      .split('],')
                                      .map((value: string) => {
                                        return `${value}`;
                                      })
                                      .join('] ')
                                  : '',
                              )}`}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center fs-12">
                          <div className="flex-column me-3 mt-4 pt-4">
                            <Button
                              className="btn-focus-none btn-gray-dark me-3"
                              onClick={(e) => {
                                handleProductsDetailClick(e, item?.id, item?.titleName);
                              }}
                            >
                              コメントを確認
                            </Button>
                            <Button
                              className={`${
                                item?.isAuthor === 0 && 'd-none'
                              } btn-focus-none btn-gray-dark`}
                              onClick={(e) => {
                                handleDetailClickDuplicate(e, item?.id);
                              }}
                            >
                              このタイトルを複製
                            </Button>
                          </div>
                          <div
                            className={`w-130 h-100 d-flex align-items-center justify-content-center px-5 ${
                              item.status ? 'bg-title-registered' : 'bg-title-draft'
                            }`}
                          >
                            {item.status ? '登録済' : '一時保存 '}
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </>
          ) : !loading ? (
            <div className="vh-100-final d-flex align-items-center justify-content-center">
              タイトルが登録されていません
            </div>
          ) : null}
        </Container>
      </Container>
    </>
  );
}
